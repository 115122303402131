const fulfillmentErrors = [
  { code: '2001', error: 'msg_codes_orderList_voucherProdNotFound_tooltip' },
  { code: '2002', error: 'msg_codes_invalidVoucher_error' },
  { code: '2004', error: 'msg_codes_batchNotValid_error' },
  { code: '2008', error: 'msg_codes_voucherBatchActive_error' },
  { code: '2009', error: 'msg_codes_voucherBatchCanceled_error' },
  { code: '2011', error: 'msg_codes_orderContents_error_tooltip' },
  { code: '2020', error: 'msg_codes_orderContents_error_tooltip' },
];

const activationErrors = [
  { code: '2006', error: 'msg_codes_cantActivateBatch_tooltip' },
  { code: '2019', error: 'msg_codes_codes_tooltip_activationAlreadyInProgress' },
];

export default [
  ...fulfillmentErrors,
  ...activationErrors,
];
