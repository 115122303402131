import i18n from 'i18next';
import { autoHide, timeout } from '../constants/notifications.constant';

export default (notificationContext, error, Messages) => {
  if (!error.detailMessages || error.detailMessages.length === 0) {
    notificationContext.dispatch({
      type: 'add',
      payload: {
        status: 'error',
        autoHide,
        timeout,
        message: Messages.ERROR,
        testId: 'error',
      },
    });
  } else if (error.detailMessages[0].includes('could not execute statement')) {
    notificationContext.dispatch({
      type: 'add',
      payload: {
        status: 'error',
        autoHide,
        timeout,
        message: Messages.NO_SAVE,
        testId: 'error',
      },
    });
  } else if (
    error.detailMessages[0].includes('End index is greater than voucher batch size for partial cancel')
    || error.detailMessages[0].includes('Start index must be greater than 0 for partial cancel')
  ) {
    const parsedMessage = JSON.parse(error.detailMessages[0]);

    notificationContext.dispatch({
      type: 'add',
      payload: {
        status: 'error',
        autoHide,
        timeout,
        message: parsedMessage.messages[0],
        testId: 'error',
      },
    });
  } else if (
    error.detailMessages[0].includes('endDate must be later than the startDate')
  ) {
    notificationContext.dispatch({
      type: 'add',
      payload: {
        status: 'error',
        autoHide,
        timeout,
        message: i18n.t('msg_codes_endDate_error'),
        testId: 'error',
      },
    });
  } else {
    notificationContext.dispatch({
      type: 'add',
      payload: {
        status: 'error',
        autoHide,
        timeout,
        message: error.detailMessages[0],
        testId: 'error',
      },
    });
  }
};
