export default [{
  value: 1,
  label: 'msg_codes_orderPurpose_internal',
}, {
  value: 2,
  label: 'msg_codes_marketing_purpose',
}, {
  value: 3,
  label: 'msg_codes_media',
}, {
  value: 4,
  label: 'msg_codes_posa_point_of_sale',
}, {
  value: 5,
  label: 'msg_codes_retailer',
}, {
  value: 6,
  label: 'msg_codes_test',
}, {
  value: 7,
  label: 'msg_codes_other',
}, {
  value: 8,
  label: 'msg_codes_marketing_packin',
}, {
  value: 9,
  label: 'msg_codes_live',
}];
