import i18n from 'i18next';
import { autoHide, timeout } from '../constants/notifications.constant';

const copyToClipboard = (value, notificationContext, successMessage) => {
  return (event) => {
    event.stopPropagation();
    try {
      navigator.clipboard.writeText(value);
      notificationContext.dispatch({
        type: 'add',
        payload: {
          status: 'success',
          autoHide,
          timeout,
          message: i18n.t(successMessage),
          testId: 'success',
        },
      });
    } catch (error) {
      notificationContext.dispatch({
        type: 'add',
        payload: {
          status: 'error',
          autoHide,
          timeout,
          message: i18n.t('msg_codes_error_header'),
          testId: 'error',
        },
      });
      console.error(error);
    }
  };
};


export default copyToClipboard;
