/**
 * Constant to map application pages to Gensen string ids for setting page titles.
 *
 * A few notes on object key formatting:
 *   - Each key aligns with the page's first path value per App.js.
 *   - If the second path parameter is "create" (ex. /order/create),
 *     the key is the path name with "create" appended.
 *   - If the second path parameter is an id (ex. /order/123),
 *     the key is the path name with "WithId" appended.
 */

export default {
  'add-lines': 'msg_codes_orders',
  agreement: 'msg_codes_new_agreement',
  agreementcreate: 'msg_codes_new_agreement',
  agreementWithId: 'msg_codes_agreements_agreementIDnumber',
  agreements: 'msg_codes_agreements',
  canceledtos: 'msg_codes_termsOfService',
  cart: 'msg_codes_common_cart',
  codeproduct: 'msg_codes_newCodeProduct',
  codeproductWithId: 'msg_codes_codeProduct_productIDNumber',
  codeproducts: 'msg_codes_codeProductCatalog',
  needpgpsetup: 'msg_codes_PGPKey',
  ordercreate: 'msg_codes_NewOrder_createOrder',
  order: 'msg_codes_NewOrder_createOrder',
  orderWithId: 'msg_codes_order_orderIDNumber',
  orders: 'msg_codes_orders',
  orderhistory: 'msg_codes_orders',
  ordersuccess: 'msg_codes_orders',
  partner: 'msg_codes_partners_partnerProfile',
  partners: 'msg_codes_partners',
  thirdPartyCatalog: 'msg_codes_codes',
  tos: 'msg_codes_termsOfService',
  user: 'msg_codes_users_userProfile',
  userWithId: 'msg_codes_users_userProfile',
  users: 'msg_codes_users',
  voucherbatch: 'msg_codes_codeBatch_codeBatchIDnumber',
  voucherbatchWithId: 'msg_codes_codeBatch_codeBatchIDnumber',
  voucherbatches: 'msg_codes_voucherBatchCatalog',
  vouchertype: 'msg_codes_codeType_codeTypeIDNumber',
  vouchertypeWithId: 'msg_codes_codeType_codeTypeIDNumber',
  vouchertypes: 'msg_codes_voucherTypeCatalog',
};
