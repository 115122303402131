import React from 'react';
import { useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  TableCell, TableRow,
} from '@partner-global-ui/components';

import hasPermission from '../../../utils/accessControl/hasPermission';
import roleKeys from '../../../utils/accessControl/roleKeys';
import * as userRoles from '../../../constants/userRoles.constants';
import './UsersPartner.scss';

const userStatusMap = {
  INACTIVE: 'msg_codes_agreementStatus_inactive',
  ACTIVE: 'msg_codes_agreementStatus_active',
  DELETED: 'msg_codes_sftpStatus_deleted',
};

const User = (props) => {
  const { t } = useTranslation();
  const {
    location,
    user: {
      userId,
      firstName,
      lastName,
      email,
      creationDate,
      hasActiveFingerprint,
      roleCode,
      status,
    },
  } = props;
  const superAdmin = userRoles.VouchersSieSuperAdmin.roleCode;
  const userURL = roleCode === superAdmin ? `/user/${userId}` : `/user/${userId}?return=${location.pathname}`;

  const role = userRoles.getAllRoles().find(r => r.roleCode === roleCode);

  const activeFingerprintExists = hasActiveFingerprint;
  let showPgpKeysColumn = false;
  showPgpKeysColumn = hasPermission(useSelector(state => state), roleKeys.user.canSeePgpKeys);
  const localState = useSelector(state => state);
  let sameUser = false;
  sameUser = localState.user.email === email;

  const renderPGPKeyColumn = () => {
    if (showPgpKeysColumn) {
      if (sameUser) {
        if (activeFingerprintExists) {
          return (
            <TableCell id="PartnerDetailUsersPgpKeys">
              <Link className="partner-detail-users-pgp-keys" to={userURL} role="presentation">
                <span data-testid="pgp-key-column-view" className="pgp-button-label">View</span>
              </Link>
            </TableCell>
          );
        }
        return (
          <TableCell id="PartnerDetailUsersPgpKeys" sm={2}>
            <Link className="partner-detail-users-pgp-keys" to={`${userURL}&focus=pgpkey`} role="presentation">
              <span data-testid="pgp-key-column-add" name="pgpButtonLabel" className="pgp-button-label">
                <span name="pgpButtonPlus" className="pgp-key-add-plus-button">+</span>
                <span name="pgpButtonText">Add</span>
              </span>
            </Link>
          </TableCell>
        );
      }
      if (activeFingerprintExists) {
        return (
          <TableCell name="PartnerDetailUsersPgpKeys" sm={2}>
            <span data-testid="pgp-key-configured">{t('msg_codes_pgpKeys_configured')}</span>
          </TableCell>
        );
      }
      return (
        <TableCell name="PartnerDetailUsersPgpKeys" sm={2}>
          <span data-testid="pgp-key-not-configured">{t('msg_codes_pgpKeys_notConfigured')}</span>
        </TableCell>
      );
    }
    return (<></>);
  };

  return (
    <TableRow name="PartnerDetailUsersContentRow" id={`user-${userId}`}>
      <TableCell className="partner-user-name" id="PartnerDetailUsersContentname">
        <Link to={userURL} role="presentation">
          <div>{firstName} {lastName}</div>
        </Link>
      </TableCell>
      <TableCell id="PartnerDetailUsersContentEmail">
        {email}
      </TableCell>
      <TableCell id="PartnerDetailUsersContentRole">
        {t(role.translationStringId)}
      </TableCell>
      <TableCell id="PartnerDetailUsersContentDate">
        {moment(creationDate).format('MM/DD/YYYY')}
      </TableCell>
      {renderPGPKeyColumn()}
      <TableCell id="PartnerDetailUsersContentStatus" className={`user-status ${status}`}>
        <span />
        {t(userStatusMap[status])}
      </TableCell>
    </TableRow>
  );
};

User.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
};

User.defaultProps = {
  user: {},
  location: {},
};

export default withRouter(User);
