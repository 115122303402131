import axios from 'axios';

export default async function downloadPoFile(orderId) {
  const orderWithFile = await axios.post(`/venom/api/downloadPoFile/${orderId}`)
    .then(response => response.data);
  const { poFileUrl } = orderWithFile;
  if (orderWithFile) {
    window.open(poFileUrl);
  }
  return poFileUrl;
}
