import React from 'react';
import {
  Collapsible,
  DataTable,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableCell,
  Tooltip,
  NoResults,
} from '@partner-global-ui/components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import './voucherType.scss';


const AssociatedCodeProduct = ({
  voucherCatalogId,
  codeProductName,
  codeProductType,
  codeProductCountries,
  codeProductDateCreated,
  status,
  loadingVoucherType,
}) => {
  const { t } = useTranslation();

  const productTypes = {
    PSPLUS: 'msg_codes_voucherType_psPlus_lowerCase',
    PSNOW: 'msg_codes_voucherType_psNow_lowerCase',
    MONEY: 'msg_codes_voucherType_money_lowerCase',
    THIRDPARTY: 'msg_codes_voucherType_product_lowerCase',
  };

  const statusMap = {
    INACTIVE: 'msg_codes_agreementStatus_inactive',
    ACTIVE: 'msg_codes_agreementStatus_active',
  };

  const renderCountries = () => {
    const countriesOverlay = (
      <div data-testid="countries-tooltip-content" className="countries-tooltip-content">
        {`${codeProductCountries.join(', ')}.`}
      </div>
    );

    if (codeProductCountries.length > 1) {
      return (
        <div data-testid="countries-tooltip-container" className="countries-tooltip">
          <Tooltip
            id="countries-tooltip"
            placement="top"
            content={countriesOverlay}
            strategy="fixed"
          >
            <span className="multiple">{t('Multiple')}</span>
          </Tooltip>
        </div>
      );
    }

    if (codeProductCountries.length === 1) {
      return `${codeProductCountries[0]}.`;
    }

    return '-';
  };

  const renderHeaderCells = () => {
    return (
      <TableHeader id="table-header">
        <TableHeaderCell
          id="productName"
          key="productName"
          value="productName"
          className="productName"
        >
          {t('msg_codes_voucherProductName')}
        </TableHeaderCell>
        <TableHeaderCell
          id="productType"
          key="productType"
          value="productType"
          className="productType"
        >
          {t('msg_codes_orderList_codeProductType')}
        </TableHeaderCell>
        <TableHeaderCell
          id="country"
          key="country"
          value="country"
          className="country"
        >
          {t('msg_codes_orderList_countryHeader')}
        </TableHeaderCell>
        <TableHeaderCell
          id="date-created"
          key="date-created"
          value="date-created"
          className="date-created"
        >
          {t('msg_codes_agreements_dateCreated')}
        </TableHeaderCell>
        <TableHeaderCell
          id="status"
          key="status"
          value="status"
          className="status"
        >
          {t('msg_codes_agreements_agreementStatus')}
        </TableHeaderCell>
      </TableHeader>
    );
  };

  const renderCodeProduct = () => {
    return (
      <TableRow id="code-product-table-parent-row" className="code-product-table-parent-row">
        <TableCell id="code-product-name" className="code-product-name">
          <Link
            data-tesid="code-product-name-link"
            to={`/codeproduct/${voucherCatalogId}`}
          >
            {codeProductName}
          </Link>
        </TableCell>
        <TableCell id="code-product-type" className="code-product-type">
          {t(productTypes[codeProductType])}
        </TableCell>
        <TableCell id="code-product-country" className="code-product-country">
          {renderCountries()}
        </TableCell>
        <TableCell id="code-product-created-date" className="code-product-created-date">
          {moment(codeProductDateCreated).format('MM/DD/YYYY')}
        </TableCell>
        <TableCell id="code-product-status" className="code-product-status">
          {t(statusMap[status])}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <div data-testid="associated-code-product-section" className="associated-code-product-section">
      <Collapsible
        id="associated-code-product-section-container"
        defaultIsOpen
        heading={t('msg_codes_codes_codeProduct')}
        loading={loadingVoucherType}
      >
        <DataTable
          id="code-product-table"
          data-testid="code-product-table"
          columnTemplate="repeat(5, 1fr)"
        >
          {renderHeaderCells()}
          {voucherCatalogId
            ? renderCodeProduct()
            : (
              <NoResults
                id="no-results"
                title=""
                message={t('There is no code product linked to this Voucher Type ID.')}
              />
            )
          }
        </DataTable>
      </Collapsible>
    </div>
  );
};

AssociatedCodeProduct.propTypes = {
  voucherCatalogId: PropTypes.string,
  codeProductName: PropTypes.string,
  codeProductType: PropTypes.string,
  codeProductCountries: PropTypes.array,
  codeProductDateCreated: PropTypes.string,
  status: PropTypes.string,
  loadingVoucherType: PropTypes.bool,
};

AssociatedCodeProduct.defaultProps = {
  voucherCatalogId: '',
  codeProductName: '',
  codeProductType: '',
  codeProductCountries: [],
  codeProductDateCreated: '',
  status: '',
  loadingVoucherType: false,
};

export default AssociatedCodeProduct;
