import React from 'react';
import axios from 'axios';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as types from './actionTypes';
import * as CodeProductTypes from '../constants/codeProductTypes.constants';
import displayErrorMessage from '../utils/errorMessageHandler';
import formatResponseError from '../utils/formatResponseError';
import { autoHide, timeout } from '../constants/notifications.constant';

const isSuccess = statusCode => statusCode >= 200 && statusCode < 300;

export function changeVoucherTypeProp(name, value) {
  return { type: types.CHANGE_VOUCHER_TYPE_PROP, name, value };
}

export function loadNewVoucherType() {
  return { type: types.LOAD_NEW_VOUCHER_TYPE };
}

export function beginLoadingVoucherType() {
  return { type: types.BEGIN_LOAD_VOUCHER_TYPE };
}

export function loadVoucherTypeSuccess(voucherType) {
  return { type: types.LOAD_VOUCHER_TYPE_SUCCESS, voucherType };
}

export function saveVoucherTypeSuccess(voucherType) {
  return { type: types.SAVE_VOUCHER_TYPE_SUCCESS, voucherType };
}

export function resetVoucherType() {
  return { type: types.RESET_VOUCHER_TYPE };
}

export function setVoucherTypeBatchPage(page) {
  return { type: types.SET_VOUCHER_TYPE_BATCH_PAGE, page };
}

export function beginLoadVoucherTypeBatches() {
  return { type: types.BEGIN_LOAD_VOUCHER_TYPE_BATCHES };
}

export function finishLoadVoucherTypeBatches(voucherBatches) {
  return { type: types.FINISH_LOAD_VOUCHER_TYPE_BATCHES, voucherBatches };
}

export function resetVoucherTypeBatchPage() {
  return { type: types.RESET_VOUCHER_TYPE_BATCH_PAGE };
}

export const isVoucherTypeSkuConfigurationError = (message) => {
  const skuConfigurationMessageMatch = 'A voucher type ID already exists with this combination of SKUs:';
  return message.indexOf(skuConfigurationMessageMatch) === 0;
};

// Dispatching functions

export function changeProp(name, value) {
  return (dispatch => dispatch(changeVoucherTypeProp(name, value)));
}

export function loadVoucherType(id, notificationContext) {
  return (async (dispatch) => {
    dispatch({ type: types.BEGIN_LOAD_VOUCHER_TYPE });
    await axios.get(`/venom/api/voucher-types/${id}`).then((response) => {
      const voucherType = response.data;
      dispatch({ type: types.LOAD_VOUCHER_TYPE_SUCCESS, voucherType });
    }).catch(() => {
      dispatch({ type: types.LOAD_VOUCHER_TYPE_FAILURE });
      notificationContext.dispatch({
        type: 'add',
        payload: {
          status: 'error',
          autoHide,
          timeout,
          message: 'There was an error loading the Voucher Type.',
          testId: 'error',
        },
      });
    });
  });
}

export function createNewVoucherType() {
  return (dispatch => dispatch(loadNewVoucherType()));
}

export const saveVoucherType = ({ voucherType, navigate, notificationContext }) => (dispatch) => {
  const { skus = [] } = voucherType;
  const copy = {
    ...voucherType,
    skuIds: skus.map(sku => sku.skuId),
  };
  return axios(
    `/venom/api/voucher-types/${voucherType.id ? voucherType.id : ''}`,
    {
      method: voucherType.id ? 'put' : 'post',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': localStorage.getItem('csrfToken'),
      },
      data: copy,
    },
  ).then((response) => {
    const voucherTypeResponse = response.data;
    dispatch(saveVoucherTypeSuccess(voucherTypeResponse));
    navigate(voucherTypeResponse.id);
    notificationContext.dispatch({
      type: 'add',
      payload: {
        status: 'success',
        autoHide,
        timeout,
        message: i18n.t('msg_codes_voucher_type_saved'),
        testId: 'success',
      },
    });
  }, (error) => {
    const voucherTypeResponse = error.response.data;
    if (voucherTypeResponse.statusCode === 400) {
      voucherTypeResponse.detailMessages.forEach((message) => {
        if (isVoucherTypeSkuConfigurationError(message)) {
          const typeId = message.split(':')[1].trim();
          notificationContext.dispatch({
            type: 'add',
            payload: {
              status: 'error',
              autoHide,
              timeout,
              message: <Trans
                i18nKey="msg_codes_sku_configuration_unique_error"
                defaults={message}
                values={{ voucherTypeIDNameAndLink: typeId }}
                components={[
                  <Link
                    to={`/vouchertype/${typeId}`}
                    className="voucher-typeId-name-link"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {{ voucherTypeIDNameAndLink: voucherTypeResponse.voucherTypeId }}
                  </Link>,
                ]}
              />,
              testId: 'sku-unique-error',
            },
          });
        } else {
          notificationContext.dispatch({
            type: 'add',
            payload: {
              status: 'error',
              autoHide,
              timeout,
              message,
              testId: 'money-unique-error',
            },
          });
        }
      });
    } else {
      displayErrorMessage(notificationContext, voucherTypeResponse, {});
    }
  });
};

export function loadVoucherBatches(
  size = 10,
  page = 0,
  sort = { name: 'modifyDate', order: 'desc' },
  filters = {},
) {
  const sortParam = `?sort=${sort.name},${sort.order}`;
  const pageParam = `page=${page}`;
  const sizeParam = `size=${size}`;
  const filtersParams = Object.keys(filters)
    .filter(key => filters[key] !== '')
    .map(key => `${key}=${filters[key]}`).join('&');
  const url = `/venom/api/voucherBatchDetails${sortParam}&${pageParam}&${sizeParam}&${filtersParams}`;
  const { name: sortName, order: sortOrder } = sort;
  return (dispatch) => {
    dispatch(beginLoadVoucherTypeBatches());
    return axios.get(url)
      .then((response) => {
        if (response.status >= 300) {
          return Promise.reject(new Error('Failed to load voucher batches'));
        }
        return response.data;
      }).then((batches) => {
        dispatch(finishLoadVoucherTypeBatches(batches.content));
        dispatch(setVoucherTypeBatchPage({
          number: batches.number,
          size: batches.size,
          totalElements: batches.totalElements,
          sort: sortName,
          sortOrder,
        }));
      }).catch((error) => {
        console.error(error);
      });
  };
}

const handleMultiResponse = (
  endpoint, action, responseCallback, notificationContext,
) => async (responses) => {
  const success = responses.filter(response => response && isSuccess(response.status)).length;
  if (success > 0) {
    responseCallback();
    if (success === 1) {
      notificationContext.dispatch({
        type: 'add',
        payload: {
          status: 'success',
          autoHide,
          timeout,
          message: `Voucher batch ${action} successfully!`,
          testId: 'success',
        },
      });
    } else {
      notificationContext.dispatch({
        type: 'add',
        payload: {
          status: 'success',
          autoHide,
          timeout,
          message: `${success} voucher batches ${action} successfully!`,
          testId: 'success',
        },
      });
    }
  }
  const errors = responses.filter(resp => resp && !isSuccess(resp.status));
  if (errors && errors.length > 0) {
    let message = errors[0];
    if (!errors[0].detailMessages) {
      message = { detailMessages: [formatResponseError(errors[0])] };
    }
    displayErrorMessage(notificationContext, message, {});
  }
};
const performAction = (batchId, endpoint, hideBulkModal) => {
  return axios.put(`/venom/api/voucherBatchDetails/${batchId}/${endpoint}`)
    .catch(() => {
      // eslint-disable-next-line no-unused-expressions
      endpoint === 'deactivated' && hideBulkModal();
    });
};

const performActionOnBatches = (
  batchIds, fetchVoucherBatches, endpoint, action, notificationContext, hideBulkModal,
) => {
  return Promise.all(batchIds.map(batchId => performAction(batchId, endpoint, hideBulkModal)))
    .then(handleMultiResponse(endpoint, action, fetchVoucherBatches, notificationContext));
};

export const deactivateVoucherBatches = (
  batchIds,
  fetchVoucherBatches,
  notificationContext,
  hideBulkModal,
) => () => performActionOnBatches(batchIds, fetchVoucherBatches, 'cancel', 'deactivated', notificationContext, hideBulkModal);

export const activateVoucherBatches = (
  notificationContext, batchIds, fetchVoucherBatches,
) => () => performActionOnBatches(batchIds, fetchVoucherBatches, 'activate', 'activated', notificationContext);

export const downloadVoucherBatches = (
  notificationContext, batchIds, fetchVoucherBatches,
) => () => {
  const errors = [];
  const promises = [];
  batchIds.forEach(batchId => promises.push(axios.put(`/venom/api/voucherBatchDetails/${batchId}/download`)
    .then(async (response) => {
      const batch = response.data;
      if (batch.downloadUrl) {
        window.open(batch.downloadUrl);
      }
    }, (response) => {
      errors.push(` ${batchId} (${response.status})`);
    })));
  return Promise.all(promises).then(() => {
    fetchVoucherBatches();
    if (errors.length > 0) {
      notificationContext.dispatch({
        type: 'add',
        payload: {
          status: 'error',
          autoHide,
          timeout,
          message: `Download errors occurred:${errors}`,
          testId: 'error',
        },
      });
    }
  });
};

export const generateVoucherBatches = (
  notificationContext, batchIds, fetchVoucherBatches,
) => () => performActionOnBatches(batchIds, fetchVoucherBatches, 'generateFile', 'submitted for file generation', notificationContext);

export const getFormattedFields = (product) => {
  const { faceValue, country } = product;
  const isMoney = product.npType === CodeProductTypes.MONEY.code;

  if (isMoney) {
    return {
      npType: product.npType,
      faceValue,
      country,
    };
  }

  return {
    npType: product.npType,
    skuIds: product.skuIds,
  };
};

export const checkExistingForConfiguration = () => {
  return async (dispatch, getState) => {
    const { voucherType } = getState();
    const formattedFields = getFormattedFields(voucherType);

    const url = '/venom/api/voucher-types/existing-for-configuration';

    return axios.put(url, {
      ...formattedFields,
    }).then(async (response) => {
      if (response.status !== 200) {
        await response.data;
        return Promise.reject();
      }
      return response.data;
    }).then((alreadyExistsForConfiguration) => {
      dispatch({
        type: types.EXISTING_FOR_CONFIGURATION,
        payload: alreadyExistsForConfiguration,
      });
      return alreadyExistsForConfiguration;
    }).catch(() => {
      // TODO: error handling
    });
  };
};
