import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function sftpListReducer(state = initialState.sftpList, action) {
  switch (action.type) {
    case types.LOAD_SFTP_LIST_SUCCESS:
      return action.payload;
    case types.CLEAR_PARTNER_SFTPS:
      return [];

    default:
      return state;
  }
}
