import axios from 'axios';
import * as types from './actionTypes';
import filtersBuilder from '../helpers/FilterParamBuilder';

export function changePropSuccess(name, value) {
  return { type: types.CHANGE_PARTNER_PROP_SUCCESS, name, value };
}

export function beginLoadPartners() {
  return { type: types.BEGIN_LOAD_PARTNERS };
}

export function loadPartnersSuccess(partners) {
  return { type: types.LOAD_PARTNERS_SUCCESS, partners };
}

export function changeProp(name, value) {
  return (dispatch => dispatch(changePropSuccess(name, value)));
}

export function versionMismatch() {
  return { type: types.VERSION_MISMATCH };
}

export function loadPartner(id, sftpStatus) {
  let version = null;

  return (async (dispatch) => {
    dispatch({ type: types.LOAD_PARTNER_PENDING });

    const statusQuery = typeof sftpStatus !== 'undefined' ? `?status=${sftpStatus}` : '';
    const response = await axios.get(`/venom/api/partners/${id}/partnerSftps${statusQuery}`);
    version = response.headers.ETag;

    let partner;
    if (response.status === 404) {
      const partnerResponse = await axios.get(`/venom/api/partners/${id}`);
      if (partnerResponse.status >= 300) {
        dispatch({ type: types.LOAD_PARTNER_REJECTED });
        throw Error(partnerResponse.statusText);
      }
      partner = partnerResponse.data;
      dispatch({
        type: types.LOAD_PARTNER_SUCCESS,
        partner: {
          partner: { ...partner },
          partnerSftpMapList: [],
          version,
        },
      });
    } else {
      if (response >= 300) {
        dispatch({ type: types.LOAD_PARTNER_REJECTED });
        throw Error(response.statusText);
      }

      partner = response.data;
      dispatch({ type: types.LOAD_PARTNER_SUCCESS, partner: { ...partner, version } });
    }

    return partner;
  });
}

export function resetFilters() {
  return { type: types.RESET_PARTNERS_PAGE_FILTERING };
}

export function setFilter(filterType, filterItems) {
  return {
    type: types.SET_PARTNERS_FILTER,
    filterType,
    filterItems,
  };
}

export function loadPartners(
  size = 10,
  page = 0,
  sort = { name: 'name', order: 'asc' },
  filters = {},
  dispatch,
) {
  const sortParam = `?sort=${sort.name},${sort.order}`;
  const pageParam = `page=${page}`;
  const sizeParam = `size=${size}`;
  const filtersParams = filtersBuilder(filters);
  const url = `/venom/api/partners${sortParam}&${pageParam}&${sizeParam}${filtersParams}`;

  dispatch({ type: types.BEGIN_LOAD_PARTNERS });
  return axios.get(url)
    .then(response => response.data)
    .then((partners) => {
      dispatch({
        type: types.SET_PARTNERS_PAGE,
        partnersPage: {
          page: {
            ...partners.page,
            totalElements: partners.totalElements,
          },
        },
      });
      dispatch({ type: types.LOAD_PARTNERS_SUCCESS, partners: partners.content });
    });
}
