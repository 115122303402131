import React from 'react';
import PropTypes from 'prop-types';
import {
  Layout,
  Flex,
  Button,
} from '@partner-global-ui/components';
import { useTranslation } from 'react-i18next';

const VoucherBatchHeader = ({
  isEdited,
  isVoucherBatchValid,
  voucherBatch,
  canEditVoucherBatch,
  readOnlyBatch,
  handleCancelClick,
  handleVoucherBatchSave,
  isSaving,
}) => {
  const { t } = useTranslation();
  const disableButton = !isEdited || !isVoucherBatchValid(voucherBatch);
  return (
    <Layout id="voucher-batch-header" className="voucher-batch-header">
      <div data-testid="voucher-batch-header-content" className="voucher-batch-header-content">
        <Flex colSpan={6} id="voucher-batch-header-left" className="voucher-batch-header-left">
          <h3 data-testid="title" className="title">
            {t('msg_codes_voucher_batch_admin')}
          </h3>
        </Flex>
        <Flex colSpan={6} id="voucher-batch-header-right" className="voucher-batch-header-right">
          <Button
            className="cancel-button"
            id="voucher-batch-detail-cancel-button"
            onClick={handleCancelClick}
          >
            {t('msg_codes_cta_cancel')}
          </Button>
          {
            canEditVoucherBatch && !readOnlyBatch && (
              <Button
                primary
                className="save-button"
                id="voucher-batch-detail-save-button"
                disabled={disableButton}
                loading={isSaving}
                onClick={handleVoucherBatchSave}
              >
                {t('msg_codes_cta_save')}
              </Button>
            )
          }
        </Flex>
      </div>
    </Layout>
  );
};

VoucherBatchHeader.propTypes = {
  isEdited: PropTypes.bool,
  isVoucherBatchValid: PropTypes.func,
  voucherBatch: PropTypes.object,
  canEditVoucherBatch: PropTypes.bool,
  readOnlyBatch: PropTypes.bool,
  handleCancelClick: PropTypes.func,
  handleVoucherBatchSave: PropTypes.func,
  isSaving: PropTypes.bool,
};

VoucherBatchHeader.defaultProps = {
  isEdited: false,
  isVoucherBatchValid: () => { },
  voucherBatch: {},
  canEditVoucherBatch: false,
  readOnlyBatch: false,
  handleCancelClick: () => { },
  handleVoucherBatchSave: () => { },
  isSaving: false,
};

export default VoucherBatchHeader;
