import React from 'react';
import { Layout, Button } from '@partner-global-ui/components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './orderEmailActionPage.scss';

const OrderEmailActionContent = ({
  orderNumber,
  image,
  title,
  description,
  showButton = false,
  buttonText,
  buttonAction,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {orderNumber && (
        <Layout className="header hide-on-mobile">
          <h3 className="title" data-testid="tos-header-title">
            {t('msg_codes_order_number_variable', { orderNumber })}
          </h3>
        </Layout>
      )}

      <Layout className="body">
        <div className="content">
          <div className="left-content">
            <img src={image} alt="astro-sitting-playing-360" className="image" />
          </div>
          <div className="right-content">
            <h2
              className="title"
              name="title"
            >
              {title}
            </h2>

            {/* eslint-disable-next-line react/no-danger */}
            <p className="description" dangerouslySetInnerHTML={{ __html: description }} />
            { showButton && (
              <div className="button-container">
                <Button className="confirmation-button" primary onClick={buttonAction}>{buttonText}</Button>
              </div>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};

OrderEmailActionContent.propTypes = {
  orderNumber: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  showButton: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonAction: PropTypes.func,
};

OrderEmailActionContent.defaultProps = {
  orderNumber: '',
  image: '',
  title: '',
  description: '',
  showButton: false,
  buttonText: '',
  buttonAction: () => {},
};


export default OrderEmailActionContent;
