export const AGENCY = {
  value: 'AGENCY',
  label: 'Agency',
  translationStringId: 'msg_codes_agreementType_agency',
};
export const FIRST_PARTY_PUBLISHER = {
  value: 'FIRST_PARTY_PUBLISHER',
  label: '1st Party Publisher',
  translationStringId: 'msg_codes_agreementType_firstPartyPublisher',
};
export const THIRD_PARTY_CONTRACTUAL = {
  value: 'THIRD_PARTY_CONTRACTUAL',
  label: '3rd Party Publisher - Free Allocation',
  translationStringId: 'msg_codes_agreementType_thirdPartyFreeAllocation',
};
export const THIRD_PARTY_RATECARD = {
  value: 'THIRD_PARTY_RATECARD',
  label: '3rd Party Publisher - Permitted Uses',
  translationStringId: 'msg_codes_agreementType_thirdPartyPublisher',
};
