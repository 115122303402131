import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function voucherBatchReducer(state = initialState.voucherBatch, action) {
  switch (action.type) {
    case types.CHANGE_VOUCHER_BATCH_PROP:
      return {
        ...state,
        [action.name]: action.value,
      };
    case types.LOAD_NEW_VOUCHER_BATCH:
      return {
        ...initialState.voucherBatch.voucherBatch,
        voucherTypeId: action.voucherTypeId,
      };
    case types.LOAD_VOUCHER_BATCH_SUCCESS:
      return action.voucherBatch;
    case types.SAVE_VOUCHER_BATCH_SUCCESS:
      return action.voucherBatch;
    case types.RESET_VOUCHER_BATCH:
      return initialState.voucherBatch.voucherBatch;
    case types.VOUCHER_BATCH_ACTION_SUCCESS:
      return action.voucherBatch;
    case types.VOUCHER_BATCH_POLLING_SUCCESS:
      if (state.voucherBatchId === action.voucherBatch.voucherBatchId) {
        return action.voucherBatch;
      }
      return state;
    default:
      return state;
  }
}
