import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Layout,
  Flex,
  Select,
  Button,
} from '@partner-global-ui/components';
import { isEmpty } from 'lodash';
import './BulkActionSelect.scss';

export default function BulkActionSelect({
  disabled, onChange, onGo, selectedBatches,
}) {
  const { t } = useTranslation();

  const options = [
    { label: t('Activate'), value: 'activate' },
    { label: t('Deactivate'), value: 'deactivate' },
    { label: t('Generate'), value: 'generate' },
    { label: t('Download'), value: 'download' },
  ];

  const [bulkAction, setBulkAction] = useState();

  const onActionChange = (event) => {
    setBulkAction(event.target.value);
    onChange(event.target.value.value);
  };

  return (
    <Layout className="bulk-action-row" id="bulk-action-row">
      <Flex name="bulkActions" colSpan={2} id="bulk-action-actions">
        <Select
          componentClass="select"
          id="bulk-action-select"
          value={bulkAction}
          onChange={onActionChange}
          name="bulk-action-select"
          disabled={disabled}
          options={options}
          placeholder="Select Bulk Action"
        />
      </Flex>
      <Flex name="bulkActionsButton" colSpan={1} id="bulk-action-buttons">
        <Button
          primary
          className="bulk-action-go-btn"
          disabled={!(!isEmpty(bulkAction) && selectedBatches.length)}
          onClick={() => onGo()}
          id="bulk-action-go-btn"
        >
          {t('Go')}
        </Button>
      </Flex>
    </Layout>
  );
}

BulkActionSelect.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onGo: PropTypes.func,
  selectedBatches: PropTypes.array,
};

BulkActionSelect.defaultProps = {
  disabled: false,
  onChange: () => null,
  onGo: () => null,
  selectedBatches: [],
};
