import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useReducer,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useHistory,
  useLocation,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  ActionBar,
  Button,
  Container,
  Layout,
  Flex,
  DataTable,
  TableHeader,
  TableHeaderCell,
  RowDisplay,
  Pagination,
  NoResults,
} from '@partner-global-ui/components';
import SearchHeader from '../common/SearchHeader/SearchHeader';
import FiltersContainer from '../common/Filters/FiltersContainer';
import getCountries from '../../actions/countryActions';
import hasPermission from '../../utils/accessControl/hasPermission';
import roles from '../../utils/accessControl/roleKeys';
import { filterCounter } from '../../helpers/filterMethods';
import AgreementsColumns from './AgreementsColumns';
import RowDisplayLocalization from '../../helpers/RowDisplayLocalization';
import AgreementRow from './Agreement';
import {
  loadAgreementsList,
  applyFilters,
  clearFilters,
  createFilters,
  applySearch,
} from './agreementsMethods';
import './Agreements.scss';

export const initialState = {
  currentSort: {
    sortBy: 'agreementId',
    sortDir: 'desc',
  },
  currentPageNumber: 0,
  currentPageSize: 50,
};

export function localStateReducer(
  state,
  {
    type = '',
    name,
    value,
    ...payload
  },
) {
  switch (type) {
    case 'sortAgreements':
      return {
        ...state,
        currentSort: payload.currentSort,
      };
    case 'changePageNumber':
      return {
        ...state,
        currentPageNumber: payload.page,
      };
    case 'updatePageSize':
      localStorage.setItem(`agreementsPageSize-${payload.userId}`, payload.pageSize);
      return {
        ...state,
        currentPageSize: payload.pageSize,
      };
    default:
      return state;
  }
}


const AgreementsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userId } = useSelector(state => state.user);
  const search = useSelector(state => state.agreementsPage.search);

  let updatedInitialState = initialState;
  const hasPageSize = localStorage.getItem(`agreementsPageSize-${userId}`) !== null;
  updatedInitialState = {
    ...updatedInitialState,
    currentPageSize: hasPageSize
      ? Number(localStorage.getItem(`agreementsPageSize-${userId}`))
      : updatedInitialState.currentPageSize,
  };

  const [localState, localDispatch] = useReducer(localStateReducer, updatedInitialState);
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const tableRef = useRef();
  const agreements = useSelector(state => state.agreements);
  const isLoadingAgreements = useSelector(state => state.loadingAgreements);
  const canCreateAgreement = useSelector(
    state => hasPermission(state, roles.agreement.create),
  );
  const countries = useSelector(state => state.countries);
  const {
    filters,
    number,
    totalElements,
    size,
  } = useSelector(state => state.agreementsPage);
  const {
    currentSort,
    currentPageSize,
    currentPageNumber,
  } = localState;

  useEffect(() => {
    loadAgreementsList({
      dispatch,
      size: updatedInitialState.currentPageSize,
    });
    getCountries()(dispatch);
  }, []);

  const columnTemplate = useMemo(() => {
    return AgreementsColumns
      .reduce((acc, curr) => acc.concat(`${curr.width} `), '')
      .trim();
  }, [AgreementsColumns]);

  const sortAgreements = useCallback((name) => {
    const { sortBy, sortDir } = currentSort;
    const newSortDir = {
      [true]: () => 'asc',
      [name === sortBy && sortDir === 'asc']: () => 'desc',
      [name === sortBy && sortDir !== 'asc']: () => 'asc',
    }.true();
    const newSort = { sortBy: name, sortDir: newSortDir };
    localDispatch({ type: 'sortAgreements', currentSort: newSort });
    loadAgreementsList({
      dispatch,
      size: localStorage.getItem(`agreementsPageSize-${userId}`) !== null
        ? Number(localStorage.getItem(`agreementsPageSize-${userId}`))
        : currentPageSize,
      page: currentPageNumber,
      sort: newSort,
      filters: { ...filters, search },
    });
  }, [currentSort.sortBy, currentSort.sortDir]);

  const actionBarFilters = useMemo(() => {
    return createFilters(filters, dispatch, countries);
  }, [
    filters,
    countries,
  ]);

  const handleNewAgreementButtonClick = () => {
    history.push(`/agreement/create?return=${pathname}`);
  };

  const changePage = useCallback((page) => {
    localDispatch({ type: 'changePageNumber', page });
    loadAgreementsList({
      dispatch,
      size: currentPageSize,
      page,
      sort: currentSort,
      filters: { ...filters, search },
    });
  });

  const changeRowAmount = useCallback((pageSize, id) => {
    localDispatch({ type: 'updatePageSize', pageSize, userId: id });
    loadAgreementsList({
      dispatch,
      size: pageSize,
      page: currentPageNumber,
      sort: currentSort,
      filters: { ...filters, search },
    });
  });

  const searchAgreements = (term) => {
    applySearch({
      dispatch,
      size: localStorage.getItem(`agreementsPageSize-${userId}`) !== null
        ? Number(localStorage.getItem(`agreementsPageSize-${userId}`))
        : currentPageSize,
      page: 0,
      sort: currentSort,
      filters: { ...filters, search: term },
      search: term,
    });
  };

  const actionBar = () => {
    const handleAppleyFilters = () => {
      applyFilters({
        dispatch,
        size: localStorage.getItem(`agreementsPageSize-${userId}`) !== null
          ? Number(localStorage.getItem(`agreementsPageSize-${userId}`))
          : currentPageSize,
        page: 0,
        sort: currentSort,
        filters: { ...filters, search },
      });
    };

    const handleClearFilters = () => {
      clearFilters({
        dispatch,
        size: localStorage.getItem(`agreementsPageSize-${userId}`) !== null
          ? Number(localStorage.getItem(`agreementsPageSize-${userId}`))
          : currentPageSize,
        page: currentPageNumber,
        sort: currentSort,
      });
    };

    const filterCount = filterCounter(filters);

    return (
      <ActionBar
        id="agreements-list-action-bar"
        {
        ...(
          countries.length
            ? { filters: <FiltersContainer filters={actionBarFilters} /> }
            : {}
          )
        }
        onFilterClear={handleClearFilters}
        filterCount={filterCount}
        onFilterApply={handleAppleyFilters}
        filterClearLabel={t('msg_codes_cta_clear')}
        filterPrimaryLabel={t('msg_codes_cta_apply')}
        filterSecondaryLabel={t('msg_codes_cta_cancel')}
        filterToolTipText={t('msg_codes_filter')}
        filterTitle={t('msg_codes_filter')}
        right={canCreateAgreement
          ? (
            <Button
              id="new-agreement"
              className="new-agreement"
              icon="ico-plus"
              onClick={handleNewAgreementButtonClick}
              primary
            >
              {t('msg_codes_new_agreement')}
            </Button>
          ) : ''}
      />
    );
  };

  const renderAgreementsRows = useCallback(() => {
    if (!agreements.length) {
      return (
        <NoResults
          id="no-results"
          title={t('msg_codes_codes_noResultsFound')}
          message={t('msg_codes_search_again_error')}
        />
      );
    }

    return agreements.map((agreement, index) => (
      <AgreementRow
        key={agreement.agreementId}
        rowindex={index + 1}
        agreement={agreement}
        tableRef={tableRef}
      />
    ));
  }, [agreements]);

  // Render
  return (
    <Container id="agreements-page" className="agreements-page">
      <Layout
        id="agreements-header"
        className="agreements-header"
      >
        <SearchHeader
          title={t('msg_codes_agreements')}
          name="agreements"
          canSearch
          hideSecondaryNav
          changeSearch={searchAgreements}
          clearIcon
          hideCategories
        />
      </Layout>
      <Layout
        id="agreements-list"
        className="agreements-list"
      >
        <Flex id="agreements-list-table">
          <DataTable
            id="agreements-list"
            columnTemplate={columnTemplate}
            actionBar={actionBar()}
            loading={isLoadingAgreements}
            ref={tableRef}
          >
            <TableHeader>
              {AgreementsColumns.map(column => (
                <TableHeaderCell
                  key={column.id}
                  value={column.value}
                  id={column.id}
                  sortable={column.sortable}
                  sort={localState.currentSort}
                  onClick={sortAgreements}
                >
                  {t(column.stringId)}
                </TableHeaderCell>
              ))}
            </TableHeader>
            {renderAgreementsRows()}
          </DataTable>
          {totalElements > 10 && (
            <div data-testid="agreements-paginator" className="agreements-paginator">
              <RowDisplay
                id="agreements-paginator-selector"
                currentPage={number + 1}
                totalItems={totalElements}
                pageSizes={[10, 25, 50, 100]}
                onPageSizeChange={pageRowAmount => changeRowAmount(pageRowAmount, userId)}
                initialPageSize={size}
                showingOfText={RowDisplayLocalization('msg_codes_pagination_showing')(t)}
              />
              {totalElements > size && (
                <Pagination
                  id="agreements-paginator-navigator"
                  totalRecords={totalElements}
                  currentPage={number + 1}
                  pageLimit={size}
                  onPageItemClick={pageNum => changePage(pageNum - 1)}
                />
              )}
            </div>
          )}
        </Flex>
      </Layout>
    </Container>
  );
};

export default AgreementsPage;
