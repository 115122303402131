import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Flex,
  Layout,
  Collapsible,
  DataTable,
  TableHeader,
  TableHeaderCell,
  Tooltip,
  Icon,
  GroupState,
  NoResults,
} from '@partner-global-ui/components';
import _ from 'lodash';
import OrderContentRow from './orderContentRow';
import * as lineActions from '../../../actions/lineActions';
import * as orderActions from '../../../actions/orderActions';
import { usePermission } from '../../../utils/accessControl/hasPermission';
import roleKeys from '../../../utils/accessControl/roleKeys';
import OrderStatuses from '../../../constants/order.constants';
import * as UserRoles from '../../../constants/userRoles.constants';


const isCheckboxEnabled = (line, orderStatus) => {
  const canDownloadLine = line.downloadable;
  const canCancelLine = line.cancelable;

  return (canDownloadLine || canCancelLine)
    && orderStatus === 'APPROVED_ORDER'
    && !line.processingOrderLine;
};

export const checkLineCanceled = orderLine => orderLine.autoCanceled || orderLine.canceled;

export const checkLineHasActions = orderLine => !checkLineCanceled(orderLine)
  && (orderLine.downloadable || orderLine.retryable || orderLine.cancelable);

export const shouldHideKebobRow = (orderLines) => {
  const someLinesHaveActions = orderLines
    && orderLines.some(checkLineHasActions);
  const someLinesProcessing = orderLines
    && orderLines.some(orderLine => orderLine.processingOrderLine);
  return !someLinesHaveActions && !someLinesProcessing;
};

function OrderContents({
  order,
  roles,
  changeProp,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(state => state.user);
  const tableRef = useRef();
  const [isChecked, setIsChecked] = useState(GroupState.NONE);
  const [selectedLines, setSelectedLines] = useState([]);
  const [showCancelButton, setShowCancelButton] = useState(false);
  const [showDownloadButton, setShowDownloadButton] = useState(false);

  // Set up
  const isSonyUser = !roles.includes(
    UserRoles.VouchersPartnerRequester.roleCode,
  );
  const canRemoveItem = usePermission(roleKeys.orderContent.removeLineItem);
  const isDisabled = order.orderLines
    && order.orderLines.some(orderLine => orderLine.autoCanceled && orderLine.activationStatus === 'Inactive');

  const permissions = {
    canViewStartDate: usePermission(roleKeys.orderContent.viewStartDate),
    canViewEndDate: usePermission(roleKeys.orderContent.viewEndDate),
    canRemoveLineItem: !user ? false : canRemoveItem && !isDisabled,
    canChangeBranded: usePermission(roleKeys.orderContent.editBranded),
    canEditQuantity: usePermission(roleKeys.orderContent.editQuantity),
    canEditCost: usePermission(roleKeys.orderContent.editManufactureCost),
    canEditTax: usePermission(roleKeys.orderContent.editTax),
    canEditItemReference: usePermission(roleKeys.orderContent.editItemReference),
    canDownloadLineItem: usePermission(roleKeys.orderContent.downloadLineItem),
  };

  const isDraft = typeof order.orderStatus !== 'undefined' && order.orderStatus === 'DRAFT_ORDER';
  const isPendingDraftAmendment = typeof order.orderStatus !== 'undefined' && order.orderStatus === 'PENDING_DRAFT_AMENDMENT';
  const hasPermissionToAddItem = usePermission(roleKeys.orderContent.addLineItem);
  const canAddItems = (isDraft || isPendingDraftAmendment)
  && hasPermissionToAddItem;
  const canCancel = order.orderLines
    && order.orderLines.some(orderLine => orderLine.cancelable);
  const canDownload = order.orderLines
    && order.orderLines.some(orderLine => orderLine.downloadable)
    && permissions.canDownloadLineItem;

  // Local variables
  const isApproved = typeof order.orderStatus !== 'undefined' && order.orderStatus === OrderStatuses.APPROVED_ORDER;
  const hideAllLineKabobs = useMemo(() => {
    return shouldHideKebobRow(order.orderLines);
  }, [order.orderLines]);

  const handleBlur = useCallback((e) => {
    if (e.currentTarget.value === '0') e.currentTarget.value = '1';
  });

  const handleKeypress = useCallback((e) => {
    const characterCode = e.key;
    const alphanumericRegex = /^[0-9a-zA-Z]+$/;
    if (characterCode === 'Backspace') return;

    const characterNumber = characterCode;
    if (characterCode.match(alphanumericRegex)) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      }
      if (characterNumber === 0) {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  });


  const showDeleteIcon = () => {
    const isPending = typeof order.orderStatus !== 'undefined' && order.orderStatus === 'PENDING_DRAFT_AMENDMENT';

    return (isDraft || isPending) && permissions.canRemoveLineItem;
  };

  const resetLines = () => {
    setSelectedLines([]);
    setShowCancelButton(false);
    setShowDownloadButton(false);
  };

  // Set show button states on selectedLines change
  useEffect(() => {
    const enabledLines = order.orderLines
      && order.orderLines.filter(orderLine => isCheckboxEnabled(orderLine, order.orderStatus));
    setIsChecked(
      enabledLines && (selectedLines.length === enabledLines.length) && (selectedLines.length > 0)
        ? GroupState.ALL
        : GroupState.NONE,
    );

    const completeSelectedLines = order.orderLines && order.orderLines
      .filter(line => selectedLines.includes(line.orderLineId));
    const shouldShowCancelButton = completeSelectedLines && completeSelectedLines.length > 0
      && completeSelectedLines.filter(selectedLine => selectedLine.cancelable)
        .length === completeSelectedLines.length;
    const shouldShowDownloadButton = completeSelectedLines && completeSelectedLines.length > 0
      && completeSelectedLines.filter(selectedLine => selectedLine.downloadable)
        .length === completeSelectedLines.length;

    setShowCancelButton(shouldShowCancelButton);
    setShowDownloadButton(shouldShowDownloadButton);
  }, [selectedLines]);

  const cancelSelected = () => {
    selectedLines.forEach((line) => {
      lineActions.cancelItem(order, line, dispatch);
    });
    resetLines();
  };

  const downloadSelected = () => {
    const completeSelectedLines = order.orderLines
      .filter(line => selectedLines.includes(line.orderLineId));

    const promises = [];
    completeSelectedLines.forEach((selectedLine) => {
      promises.push(lineActions.deliverOrderLine(selectedLine.orderLineId, dispatch)
        .then((res) => {
          if (res.downloadUrl !== null) {
            window.open(res.downloadUrl);
          }
        }));
    });

    Promise.all(promises).then(() => {
      resetLines();
      orderActions.loadOrder(order.orderNumber)(dispatch);
    });
  };

  const toggleAll = () => {
    const { orderLines, orderStatus } = order;
    const updatedLines = orderLines
      .filter(orderLine => isCheckboxEnabled(orderLine, orderStatus))
      .length === selectedLines.length
      ? []
      : orderLines.filter(orderLine => isCheckboxEnabled(orderLine, orderStatus))
        .map(line => line.orderLineId);

    setSelectedLines(updatedLines);
  };

  const selectLine = (id) => {
    const updatedLines = selectedLines.includes(id)
      ? selectedLines.filter(item => item !== id)
      : [...selectedLines, id];

    setSelectedLines(updatedLines);
  };

  const addItems = () => {
    window.scrollTo(0, 0);
    history.push(`/add-lines/${order.orderNumber}`);
  };

  const tableHeaders = [
    { label: t('msg_codes_voucherProductName'), name: 'voucherProductName' },
    { label: t('msg_codes_orderList_currencyHeader'), name: 'currency' },
    { label: t('msg_codes_agreements_country'), name: 'country' },
    { label: t('msg_codes_orderList_codeProductType'), name: 'codeProductType' },
    {
      label: (
        <>
          <span data-testid="required" className="required">*</span>
          {t('msg_codes_orderDetails_branded')}
        </>
      ),
      name: 'branded',
    },
    { label: t('msg_codes_codes_quantity'), name: 'quantity' },
    { label: t('msg_codes_orderDetails_manufactureCost'), name: 'manufactureCost' },
    { label: t('msg_codes_orderDetails_taxPercentage'), name: 'taxPercentage' },
    { label: t('msg_codes_orderDetails_totalExcludingTax'), name: 'totalExcludingTax' },
    { label: t('msg_codes_orderDetails_tax'), name: 'tax' },
    ...(isApproved ? [{ label: t('msg_codes_codes_batchID'), name: 'batchID' }] : []),
    ...(isApproved ? [{ label: t('msg_codes_orderDetailsAdmin_batchFileName_label'), name: 'batchFileName' }] : []),
    ...(permissions.canViewStartDate && isApproved && isSonyUser ? [{ label: t('msg_codes_orderDetails_startDate'), name: 'startDate' }] : []),
    ...(permissions.canViewEndDate && isApproved && isSonyUser ? [{ label: t('msg_codes_orderDetails_endDate'), name: 'endDate' }] : []),
    { label: t('msg_codes_custom_id'), name: 'customId' },
    { label: t('msg_codes_orderDetails_itemReference'), name: 'itemReference' },
    ...(isApproved ? [{ label: t('msg_codes_orderDetailsAdmin_fulfillment_label'), name: 'fulfillment' }] : []),
    ...(isApproved ? [{ label: t('msg_codes_orderDetails_delivery'), name: 'delivery' }] : []),
    ...(isApproved ? [{ label: t('msg_codes_orderDetailsAdmin_Activation_label'), name: 'activation' }] : []),
    ...(showDeleteIcon() ? [{ label: 'Remove', name: 'remove' }] : []),
    ...((isApproved && !hideAllLineKabobs) ? [{ label: '', name: '' }] : []),
  ];

  function renderHeaderCells() {
    return tableHeaders.map(({ label, name }, index) => {
      const canRemove = showDeleteIcon() && (tableHeaders.length - 1) === index
        ? 'can-remove last' : 'last';
      const tooltipContent = (<div className="info-content">{t('msg_codes_custom_id_tooltip')}</div>);

      return (
        <TableHeaderCell
          id={name}
          key={name}
          value={label}
          className={canRemove}
        >
          {label}
          {name === 'customId' && (
          <Tooltip
            content={tooltipContent}
            id={`${name}-tooltip`}
            position="top"
          >
            <Icon
              name={`${name}-info-icon`}
              size={16}
              className="custom-id-info-icon"
              id="custom-id-info-icon"
            >
              info
            </Icon>
          </Tooltip>
          )}
        </TableHeaderCell>
      );
    });
  }

  function renderOrderLines() {
    if (order.orderLines.length > 0) {
      return (
        _.orderBy(order.orderLines, 'orderLineId').map((line) => {
          const { orderLineId } = line;
          const checkboxState = selectedLines.includes(orderLineId)
            ? GroupState.ALL
            : GroupState.NONE;
          return (
            <OrderContentRow
              order={order}
              line={line}
              changeProp={changeProp}
              handleBlur={handleBlur}
              handleKeypress={handleKeypress}
              selectLine={selectLine}
              permissions={permissions}
              tableRef={tableRef}
              isDisabled={isDisabled}
              isApproved={isApproved}
              canDownload={canDownload}
              canCancel={canCancel}
              isSonyUser={isSonyUser}
              hideAllLineKabobs={hideAllLineKabobs}
              isCheckboxEnabled={isCheckboxEnabled(line, order.orderStatus)}
              checkboxState={checkboxState}
              showDeleteIcon={showDeleteIcon()}
            />
          );
        })
      );
    }
    return (
      <NoResults
        id="no-results"
        title={t('msg_codes_nothing_here_yet')}
        message={t('msg_codes_orderContents_noneFound')}
      />
    );
  }

  const columnTemplate = `repeat(${tableHeaders.length}, minmax(min-content, 400px))`;

  return (
    <Layout id="agency-order" className="agency-order-contents">
      <Flex colSpan="12">
        <Collapsible
          id="agency-order-contents"
          heading={t('msg_codes_orderContents_header')}
          rightContent={
            canAddItems
              ? [{
                actionCallback: () => addItems(),
                actionText: t('msg_codes_order_addItem'),
              }]
              : (showCancelButton || showDownloadButton)
                ? [{
                  actionCallback: () => cancelSelected(),
                  actionText: t('Cancel Codes'),
                },
                {
                  actionCallback: () => downloadSelected(),
                  actionText: t('Download Codes'),
                }]
                : []
          }
          defaultIsOpen
        >
          <DataTable
            id="order-contents-table"
            data-testid="order-contents-table"
            columnTemplate={columnTemplate}
            scrollHeight={400}
            maxHeight={400}
            isFirstColumnSticky
            isLastColumnSticky={!hideAllLineKabobs}
            ref={tableRef}
            hasCheckbox={isApproved && (canCancel || canDownload)}
          >
            {order.orderLines.length ? (
              <TableHeader
                hasCheckbox={isApproved && (canCancel || canDownload)}
                onCheckboxClick={toggleAll}
                checkboxState={isChecked}
                checkboxProps={{ disabled: isDisabled }}
              >
                {renderHeaderCells()}
              </TableHeader>
            ) : <></>}
            { renderOrderLines() }
          </DataTable>
        </Collapsible>
      </Flex>
    </Layout>
  );
}

OrderContents.propTypes = {
  order: PropTypes.object,
  roles: PropTypes.array,
  changeProp: PropTypes.func,
};

OrderContents.defaultProps = {
  order: {},
  roles: [],
  changeProp: () => {},
};

export default OrderContents;
