import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Flex,
  Layout,
  Collapsible,
  DataTable,
  TableHeader,
  TableHeaderCell,
  NoResults,
} from '@partner-global-ui/components';
import OrderHistoryLine from './OrderHistoryLine';

function OrderHistoryNew({ order }) {
  const { t } = useTranslation();
  const [showComment, setShowComment] = useState({});

  // Keep track of table's comment display toggling. Managed here
  // to prevent React "Invalid hook call" error in OrderHistoryLine.
  const toggleComment = (index) => {
    setShowComment({
      ...showComment,
      // If index does not exist, set to false to close the comment
      //  since OrderHistoryLine displays comment as true by default.
      [index]: typeof showComment[index] !== 'undefined'
        ? !showComment[index]
        : false,
    });
  };

  // labels is used for table headers
  const tableData = [
    { label: t('msg_codes_common_date'), name: 'date' },
    { label: t('msg_codes_agreements_agreementStatus'), name: 'status' },
    { label: t('msg_codes_orderDetails_by'), name: 'orderDetailsBy' },
  ];

  function renderHeaderCells() {
    return tableData.map(({ label, name }) => (
      <TableHeaderCell id={name} key={label} value={label}>{label}</TableHeaderCell>
    ));
  }

  const renderTableRows = useCallback(() => {
    // Spread in a new array to prevent state mutation error
    const histories = [...order.orderHistory]
      .sort((a, b) => (a.creationDate >= b.creationDate ? 1 : -1));
    return histories.map((historyLine, rowIndex) => {
      const rowKey = rowIndex + 1;
      return (
        <OrderHistoryLine
          line={historyLine}
          rowKey={rowKey}
          toggleComment={toggleComment}
          showComment={showComment}
        />
      );
    });
  }, [showComment, order.orderHistory.length]);

  return (
    <Layout className="third-party-order-history">
      <Flex colSpan="12">
        <Collapsible
          id="third-party-order-history"
          heading={t('msg_order_history')}
          defaultIsOpen
        >
          <DataTable
            id="order-history-table"
            data-testid="order-history-table "
            columnTemplate="repeat(3, 1fr)"
            scrollHeight={400}
          >
            <TableHeader>
              {renderHeaderCells()}
            </TableHeader>
            {
              order.orderHistory.length
                ? renderTableRows()
                : (
                  <NoResults
                    id="no-results"
                    title={t('msg_codes_nothing_here_yet')}
                    message={t('msg_codes_orderHistory_noneFound')}
                  />
                )
            }
          </DataTable>
        </Collapsible>
      </Flex>
    </Layout>
  );
}

OrderHistoryNew.propTypes = {
  order: PropTypes.object,
};

OrderHistoryNew.defaultProps = {
  order: {},
};

export default OrderHistoryNew;
