import React, {
  useCallback,
  useEffect,
  useReducer,
  useContext,
  useRef,
} from 'react';
import {
  uniq, difference, isEqual, isNaN, isFinite,
} from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import NavigationPrompt from 'react-router-navigation-prompt';
import {
  RowDisplay, Pagination, TableHeader, NotificationContext, TableHeaderCell,
  DataTable, NoResults, ActionBar, Layout, Select, Loader, Flex, Container,
  Modal, ModalContext, MODAL_ACTIONS, ModalContainer, Button,
} from '@partner-global-ui/components';

import ThirdPartyColumns from '../ThirdPartyColumns';
import {
  loadThirdPartyCatalog,
  getThirdPartyCatalogStatus,
  getOrderActivityRules,
  setThirdPartyCatalogPartner,
} from '../../../actions/thirdPartyCatalogActions';
import { emptyCart } from '../../../actions/cartProductsActions';
import { loadPartners } from '../../../actions/partnerActions';
import ThirdPartyCatalogParentRowComponent from './ThirdPartyCatalogParentRow';
import SearchHeader from '../../common/SearchHeader/SearchHeader';
import FiltersContainer from '../../common/Filters/FiltersContainer';
import '../ThirdPartyCatalog.scss';
import * as filterOptions from '../../../constants/filterOptions.constants';
import { VouchersSieSuperAdmin } from '../../../constants/userRoles.constants';
import { filterCounter } from '../../../helpers/filterMethods';
import { thirdPartyCatalogSearchOptions } from '../../../constants/searchOptions.constants';
import * as methods from '../shared/thirdPartyCatalogPageMethods';
import RowDisplayLocalization from '../../../helpers/RowDisplayLocalization';
import { usePermission } from '../../../utils/accessControl/hasPermission';
import roleKeys from '../../../utils/accessControl/roleKeys';
import getFeature from '../../../utils/accessControl/getFeature';

// Use custom polling interval for this page
const pollingInterval = 10000;

const defaultFilters = {
  productType: [],
  platform: [],
  targetRegions: [],
  version: [],
  releaseDate: [],
};

const defaultSearch = {
  category: '',
  term: '',
};

const pollingResponse = (products) => {
  const pollingProductIds = products.reduce((productIds, product) => {
    if (product.processing || product.status === 'Fulfilled') {
      productIds.push(product.voucherCatalogId);
    }
    return productIds;
  }, []);
  return pollingProductIds;
};

// eslint-disable-next-line react/prop-types
export const WarningModalContainer = ({ onConfirm, onCancel }) => {
  const modalContext = useContext(ModalContext);
  const { t } = useTranslation();

  useEffect(() => {
    const modalProps = {
      id: 'third-party-warning-modal',
      name: 'ThirdPartyWarningModal',
      title: t('msg_codes_confirmation_leave'),
      content: t('msg_codes_confirmation_leave_body'),
      primaryLabel: t('msg_codes_confirmation_leave_cta_yesLeave'),
      secondaryLabel: t('msg_codes_confirmation_leave_cta_no'),
      onPrimary: onConfirm,
      onSecondary: onCancel,
      hideCancelButton: true,
    };

    modalContext.dispatch({
      type: MODAL_ACTIONS.SHOW,
      payload: <Modal {...modalProps} />,
    });

    return () => {
      modalContext.dispatch({ type: MODAL_ACTIONS.HIDE });
    };
  }, []);

  return null;
};

export const initialState = {
  header: 'Third Party Catalog',
  filters: defaultFilters,
  filtered: false,
  filterCount: 0,
  search: defaultSearch,
  sort: {
    sortBy: 'codeProduct',
    sortDir: 'asc',
  },
  pollingQueue: [],
  downloadQueue: [],
  pageSize: 10,
  initialLoad: true,
};

export const localStateReducer = (
  state,
  {
    type = '',
    name,
    value,
    voucherCatalogIds,
    voucherCatalogId,
    pageSize = 10,
  },
) => {
  const { filters } = state;
  const filteredState = filters.productType.length > 0
  || filters.platform.length > 0
  || filters.targetRegions.length > 0
  || filters.version.length > 0
  || filters.releaseDate.length > 0;

  switch (type) {
    case 'setState':
      return { ...state, [name]: value };
    case 'setSort':
      return { ...state, sort: value };
    case 'setFilter':
      if (isEqual(state.filters, { ...state.filters, [name]: value })) {
        return state;
      }
      return {
        ...state,
        filters: { ...state.filters, [name]: value },
        filtered: !filteredState,
        filterCount: filterCounter({ ...state.filters, [name]: value }),
      };
    case 'clearFilters':
      return {
        ...state,
        filters: defaultFilters,
        filtered: false,
        filterCount: 0,
      };
    case 'setSearch':
      return { ...state, search: { category: value.category, term: value.term } };
    case 'clearSearch':
      return { ...state, search: defaultSearch };
    case 'setPollingForPage':
      return { ...state, pollingQueue: pollingResponse(value) };
    case 'addProductToDownloadQueue':
      return { ...state, downloadQueue: uniq([voucherCatalogId, ...state.downloadQueue]) };
    case 'removeProductsFromDownloadQueue':
      return {
        ...state, downloadQueue: difference(state.downloadQueue, voucherCatalogIds),
      };
    case 'clearDownloadQueue':
      return { ...state, downloadQueue: [] };
    case 'clearPollingQueue':
      return { ...state, pollingQueue: [] };
    case 'setPageSize':
      return { ...state, pageSize };
    case 'initialLoad':
      return { ...state, initialLoad: value };
    default:
      return state;
  }
};

const addProductToDownloadQueue = (dispatch) => {
  return (voucherCatalogId) => {
    dispatch({ type: 'addProductToDownloadQueue', voucherCatalogId });
  };
};

const removeProductsFromDownloadQueue = (dispatch) => {
  return (voucherCatalogIds) => {
    dispatch({ type: 'removeProductsFromDownloadQueue', voucherCatalogIds });
  };
};

export default function ThirdPartyCatalogPage() {
  const notificationCont = useContext(NotificationContext);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const tableRef = useRef();
  const selectedPartner = useSelector((state) => {
    const partnerInSession = methods.getPartnerInSession();
    const partnerId = isFinite(state.thirdPartyCatalogPage.selectedPartner)
      ? state.thirdPartyCatalogPage.selectedPartner
      : partnerInSession;
    return partnerId;
  });
  const partners = useSelector(state => state.partners);
  const user = useSelector(state => state.user);

  const canSearch = usePermission(roleKeys.thirdParty.searchCatalog);

  const [localState, localDispatch] = useReducer(localStateReducer, initialState);
  const {
    search,
    filters,
    pollingQueue,
    downloadQueue,
    pageSize,
    filterCount,
    sort: {
      sortDir,
      sortBy,
    },
    sort,
  } = localState;
  const voucherTypes = useSelector(state => state.voucherTypes);
  const { userId, roleCode } = useSelector(state => state.user);
  const thirdPartyCatalogPage = useSelector(state => state.thirdPartyCatalogPage);
  const isLoading = useSelector(state => state.loadingThirdPartyCatalog);
  const {
    productTypeOptions, registrationRegionOptions, platformOptions,
    versionOptions, optionsTranslationCreator,
  } = filterOptions;

  const changeRowAmount = (size) => {
    localStorage.setItem(`thirdPartyCatalogPageSize-${userId}`, size);
    localDispatch({ type: 'setPageSize', pageSize: size });
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const searchParam = queryParams.toString().split('=');
    const category = searchParam[0];
    const term = searchParam[1];

    const isSuperAdminWithoutPartner = (
      roleCode === VouchersSieSuperAdmin.roleCode && isNaN(selectedPartner)
    );

    if (category && term) {
      methods.setSearch(localDispatch, category, term);
    } else {
      localDispatch({ type: 'clearSearch' });
    }

    //  skip loading catalog for superadmin if there is no selectedPartner
    if (!isSuperAdminWithoutPartner) {
      methods.applySearch(loadThirdPartyCatalog,
        sortBy, sortDir, thirdPartyCatalogPage, filters,
        { category, term }, dispatch, selectedPartner, notificationCont);
    }
  }, [location, selectedPartner]);

  const { t } = useTranslation();

  useEffect(() => {
    getOrderActivityRules(dispatch);
    if (localStorage.getItem(`thirdPartyCatalogPageSize-${userId}`) !== null) {
      localDispatch({
        type: 'setPageSize',
        pageSize: Number(localStorage.getItem(`thirdPartyCatalogPageSize-${userId}`)),
      });
    }
  }, []);

  useEffect(() => {
    localDispatch({ type: 'setPollingForPage', value: thirdPartyCatalogPage.content });
  }, [thirdPartyCatalogPage.content, localDispatch]);

  useEffect(() => {
    if (pollingQueue.length > 0 || downloadQueue.length > 0) {
      const tick = () => {
        getThirdPartyCatalogStatus(
          pollingQueue,
          downloadQueue,
          notificationCont,
          selectedPartner,
        )(dispatch)
          .then((products) => {
            const voucherCatalogIds = products.map(product => product.voucherCatalogId);
            localDispatch({
              type: 'removeProductsFromDownloadQueue',
              voucherCatalogIds,
            });
          });
      };
      const id = setInterval(tick, pollingInterval);
      return () => clearInterval(id);
    }
    return () => {};
  }, [pollingQueue, downloadQueue, dispatch, localDispatch]);

  useEffect(() => {
    const {
      initialLoad,
    } = localState;
    const queryParams = new URLSearchParams(location.search);
    const searchParam = queryParams.toString().split('=');
    const category = searchParam[0];
    const term = searchParam[1];

    // Only call changeRowAmount if not on the initial render of the page.
    //  This is to prevent loadThirdPartyCatalog being called multiple times
    //  on initial render since it's already called in an above useEffect.
    if (initialLoad) {
      localDispatch({ type: 'initialLoad', value: false });
    } else {
      methods.changeRowAmount(
        loadThirdPartyCatalog, pageSize, sortBy, sortDir,
        filters, { category, term }, dispatch, selectedPartner,
        notificationCont,
      );
      changeRowAmount(pageSize);
    }
  }, [pageSize]);

  const captureSearchParams = () => {
    const queryParams = new URLSearchParams(location.search);

    return methods.captureSearchParams(queryParams);
  };

  const changeSearch = useCallback((term, category) => {
    const queryParams = new URLSearchParams();
    if (category && term) {
      queryParams.set(category, term);
      history.push(`${location.pathname}?${queryParams}`);
    } else {
      history.push(`${location.pathname}`);
    }
  }, [filters, sortBy, sortDir, thirdPartyCatalogPage.size]);

  const handleFilterChange = useCallback((e) => {
    methods.handleFilterChange(e, localDispatch);
  }, [methods, filters]);

  const clearFilters = useCallback(() => {
    methods.clearFilters(localDispatch);
  }, [methods]);

  const checkFilters = () => {
    const {
      productType, platform, releaseDate,
      targetRegions, version,
    } = filters;

    return {
      productType: productTypeOptions.length === productType.length ? [] : [...productType],
      platform: platformOptions.length === platform.length ? [] : [...platform],
      targetRegions,
      version: versionOptions.length === version.length ? [] : [...version],
      releaseDate,
    };
  };

  const handleApplyFilters = useCallback(() => {
    const newFilters = checkFilters();
    const searchParam = captureSearchParams();

    methods.applyFilters(loadThirdPartyCatalog,
      sortBy, sortDir, thirdPartyCatalogPage,
      newFilters, searchParam, dispatch,
      selectedPartner, notificationCont);
  }, [filters, sortBy, sortDir, search, thirdPartyCatalogPage.size]);

  const changePage = useCallback((page) => {
    const searchParam = captureSearchParams();
    methods.changePage(loadThirdPartyCatalog, page - 1,
      sortBy, sortDir, thirdPartyCatalogPage,
      filters, searchParam, dispatch, selectedPartner,
      notificationCont);
  }, [
    loadThirdPartyCatalog, sortBy, sortDir, search,
    thirdPartyCatalogPage.size, filters, selectedPartner,
  ]);

  const noResultMessage = () => {
    const contentPipelineUrl = getFeature('contentPipelineUrl');
    return (
      <>
        <div className="empty-table-message">{t('msg_codes_search_help')}</div>
        <a
          data-testid="content-pipeline-link"
          href={contentPipelineUrl}
          className="content-pipeline-link"
          target="_blank"
          rel="noreferrer noopener"
        >
          <Button
            primary
            id="content-pipeline-button"
            className="content-pipeline-button"
          >
            {t('msg_codes_content_pipeline_cta')}
          </Button>
        </a>
      </>
    );
  };

  const createFilters = useCallback(() => {
    const typesOptions = optionsTranslationCreator(productTypeOptions, t);
    const regionsOptions = optionsTranslationCreator(registrationRegionOptions, t);
    const platformsOptions = optionsTranslationCreator(platformOptions, t);
    const versionsOptions = optionsTranslationCreator(versionOptions, t);

    const {
      productType, platform, releaseDate,
      targetRegions, version,
    } = filters;

    return [
      {
        label: t('msg_codes_codes_productType'),
        name: 'productType',
        placeholder: t('msg_codes_filter_all'),
        multiple: true,
        value: productType,
        options: typesOptions,
        handleChange: handleFilterChange,
      },
      {
        label: t('msg_codes_target_regions'),
        name: 'targetRegions',
        placeholder: t('msg_codes_filter_all'),
        value: targetRegions,
        options: regionsOptions,
        handleChange: handleFilterChange,
      },
      {
        label: t('msg_codes_codes_platform'),
        name: 'platform',
        placeholder: t('msg_codes_filter_all'),
        multiple: true,
        value: platform,
        options: platformsOptions,
        handleChange: handleFilterChange,
      },
      {
        label: t('msg_codes_codes_version'),
        name: 'version',
        placeholder: t('msg_codes_filter_all'),
        multiple: true,
        value: version,
        options: versionsOptions,
        handleChange: handleFilterChange,
      },
      {
        label: t('msg_codes_codes_releaseDate'),
        type: 'dateRange',
        name: 'releaseDate',
        placeholder: t('msg_codes_codes_releaseDate_prompt'),
        value: releaseDate,
        handleChange: handleFilterChange,
      },
    ];
  }, [filters, voucherTypes]);

  const generateRows = (thirdPartyProductList) => {
    return (
      thirdPartyProductList.map((product) => {
        return (
          <ThirdPartyCatalogParentRowComponent
            key={product.productId}
            productVariant={product}
            addProductToDownloadQueue={addProductToDownloadQueue}
            removeProductsFromDownloadQueue={removeProductsFromDownloadQueue}
            catalogPageDispatch={localDispatch}
            globalDisptach={dispatch}
            tableRef={tableRef}
          />
        );
      })
    );
  };
  const renderThirdPartyCatalogRows = useCallback(() => {
    return Array.isArray(thirdPartyCatalogPage.content)
      ? thirdPartyCatalogPage.content.length === 0
        ? <NoResults title={t('msg_codes_codes_noResultsFound')} message={noResultMessage()} />
        : generateRows(thirdPartyCatalogPage.content)
      : null;
  }, [thirdPartyCatalogPage]);

  const sortThirdPartyCatalog = useCallback((newSortBy) => {
    const searchParam = captureSearchParams();
    methods.sortThirdPartyCatalog(
      newSortBy, sortBy, sortDir, thirdPartyCatalogPage, filters, searchParam,
      localDispatch, loadThirdPartyCatalog, dispatch, selectedPartner,
      notificationCont,
    );
  }, [methods, thirdPartyCatalogPage, sortBy, sortDir, search, filters]);

  const renderLoadingMessage = () => {
    return <Loader full size="lg" />;
  };

  const actionBar = () => {
    return (
      <ActionBar
        filters={
          <FiltersContainer filters={createFilters()} />
        }
        onFilterClear={clearFilters}
        filterCount={filterCount}
        onFilterApply={handleApplyFilters}
        filterClearLabel={t('msg_codes_cta_clear')}
        filterPrimaryLabel={t('msg_codes_cta_apply')}
        filterSecondaryLabel={t('msg_codes_cta_cancel')}
        filterToolTipText={t('msg_codes_filter')}
        filterTitle={t('msg_codes_filter')}
      />
    );
  };

  /**
   * always fetch partners for super admin
   * (other usages of the partner list do not fetch the full list)
   */
  useEffect(() => {
    if (user.roleCode === 'SUPER_ADMIN') {
      dispatch(loadPartners(20000, 0, undefined, {}, 'THIRDPARTY'));
    }
  }, []);

  const setPartner = useCallback((e) => {
    methods.setPartner(
      dispatch, e, setThirdPartyCatalogPartner,
      emptyCart,
    );
  }, [dispatch, setThirdPartyCatalogPartner, emptyCart, methods]);

  const renderPartnerSelect = useCallback((isTitle = true) => {
    const partnerOptions = partners
      .filter(partner => partner.partnerType === 'THIRDPARTY')
      .map(partner => ({
        label: partner.name,
        value: partner.partnerId,
      }));
    const partnerValue = partnerOptions.find(partner => partner.value === selectedPartner);

    return (
      <Flex className={`partner-select-group-${isTitle}`} colSpan={8}>
        {isTitle && <h2 className="partner-select-header">{t('msg_codes_codeProdCat_title')}</h2>}
        <Flex colSpan={6} className={`partner-select-subheader-${isTitle}`}>
          {t('msg_codes_codeProdCat_subheader')}
        </Flex>
        <Flex colSpan={4} className="partner-select-container">
          <Select
            id="partner-select"
            value={partnerValue}
            options={partnerOptions}
            onChange={setPartner}
            placeholder={t('msg_codes_orders_selectPartner_dropdown')}
          />
        </Flex>
      </Flex>
    );
  }, [partners, selectedPartner]);

  /* render partner selector for super admin */
  if (!selectedPartner && user.roleCode === 'SUPER_ADMIN') {
    return (
      <Container>
        <Layout className="thirdPartyCatalog thirdPartyProduct-header thirdPartyCatalog-init-partner-select">
          {renderPartnerSelect()}
        </Layout>
      </Container>
    );
  }

  return (
    <ModalContainer>
      <NavigationPrompt when={downloadQueue.length > 0}>
        {({ onConfirm, onCancel }) => (
          <WarningModalContainer onConfirm={onConfirm} onCancel={onCancel} />
        )}
      </NavigationPrompt>
      <Container>
        <Layout className="thirdPartyCatalog thirdPartyProduct-header">
          <SearchHeader
            name="thirdPartyCatalog"
            title={t('msg_codes_codeProdCat_title')}
            subTitle={user.roleCode === 'SUPER_ADMIN'
              ? renderPartnerSelect(false)
              : t('msg_codes_codes_body')
            }
            options={thirdPartyCatalogSearchOptions}
            placeholder={t('msg_codes_codeProdCat_typeSomething_dropdown')}
            changeSearch={changeSearch}
            canSearch={canSearch}
            clearIcon
          />
        </Layout>
        <Layout className="thirdPartyCatalog thirdPartyProduct-list">
          {isLoading
            ? renderLoadingMessage()
            : (
              <Flex
                colSpan={12}
                className="thirdPartyCatalogPageTableContainer"
                data-testid="coverageTable"
              >
                <DataTable
                  actionBar={actionBar()}
                  columnTemplate="309px 309px 150px 150px 250px 200px"
                  ref={tableRef}
                >
                  <TableHeader>
                    {ThirdPartyColumns.map((column) => {
                      return (
                        <TableHeaderCell
                          key={column.id}
                          value={column.value}
                          id={column.id}
                          sortable={column.sortable}
                          sort={sort}
                          onClick={column.sortable && sortThirdPartyCatalog}
                          hasExpandable
                        >
                          {t(column.stringId)}
                        </TableHeaderCell>
                      );
                    })}
                  </TableHeader>
                  {renderThirdPartyCatalogRows()}
                </DataTable>
                {thirdPartyCatalogPage.totalElements > 10 && (
                  <div className="thirdPartyCatalogPagePaginator">
                    <RowDisplay
                      currentPage={thirdPartyCatalogPage.number + 1}
                      totalItems={thirdPartyCatalogPage.totalElements}
                      pageSizes={[10, 25, 50, 100]}
                      onPageSizeChange={changeRowAmount}
                      initialPageSize={pageSize}
                      showingOfText={RowDisplayLocalization('msg_codes_pagination_showing')(t)}
                    />
                    {thirdPartyCatalogPage.totalElements > pageSize && (
                      <Pagination
                        totalRecords={thirdPartyCatalogPage.totalElements}
                        currentPage={thirdPartyCatalogPage.number + 1}
                        pageLimit={pageSize}
                        onPageItemClick={changePage}
                      />
                    )}
                  </div>
                )}
              </Flex>
            )
          }
        </Layout>
      </Container>
    </ModalContainer>
  );
}
