import React, {
  useCallback,
} from 'react';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import {
  Select,
} from '@partner-global-ui/components';
import { orderBy, padStart } from 'lodash';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import '../Agreements.scss';
import * as agreementFormActions from '../../../actions/agreementActions';

const AgreementVersionSelector = ({
  searchValue,
  canEdit,
}) => {
  //  Setup
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    agreementForm,
    agreementForm: {
      hasBeenModified,
      agreement: { status, agreementVersions },
      isNewAgreement,
    },
  } = useSelector(state => state);

  const agreementVersionOptions = orderBy(agreementVersions, 'agreementVersionId', ['asc'])
    .map((agreementVersion, index) => {
      return {
        versionNumber: padStart(index + 1, 3, '0'),
        version: agreementVersion,
      };
    });

  const updateVersion = (updatedVersion, selectedVersionId) => {
    const shouldChange = (hasBeenModified.agreement || hasBeenModified.version)
      ? window.confirm(t('msg_codes_modal_unsavedChanges_body'))
      : true;

    if (shouldChange) {
      dispatch(
        agreementFormActions.setAgreementVersion(
          updatedVersion, selectedVersionId, status, canEdit,
        ),
      );
    }
  };

  const createNavItems = useCallback(() => {
    const items = agreementVersionOptions.map((option) => {
      const versionTitle = `${t('msg_codes_codes_version')} ${option.versionNumber}`;
      if (versionTitle.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1) {
        return (
          {
            label: `${t('msg_codes_codes_version')} ${option.versionNumber}`,
            value: option.version,
          }
        );
      }
      return null;
    });
    return items.reverse();
  }, []);

  const handleDropdownOnChange = ({ target: { value } }) => {
    if (value) {
      const { agreementVersionId } = value.value;
      updateVersion(value.value, agreementVersionId);
    }
  };

  const renderAgreementSelector = useCallback(() => {
    const selectedVersion = isNewAgreement
      ? createNavItems()
      : createNavItems()
        .filter(({ value }) => value.agreementVersionId === agreementForm.agreementVersionId);

    return (
      <Select
        id="version-select"
        options={createNavItems}
        onChange={e => handleDropdownOnChange(e)}
        value={selectedVersion[0]}
        filterable
      />
    );
  }, [agreementForm.agreementVersionId]);

  return (
    renderAgreementSelector()
  );
};

AgreementVersionSelector.propTypes = {
  searchValue: PropTypes.string,
  canEdit: PropTypes.bool,
};

AgreementVersionSelector.defaultProps = {
  searchValue: '',
  canEdit: false,
};

export default AgreementVersionSelector;
