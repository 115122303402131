import React from 'react';
import PropTypes from 'prop-types';
import './staticText.scss';

const StaticText = ({
  label,
  value,
  testId,
  required,
  ...rest
}) => {
  const isSafari = window.safari !== undefined ? 'isSafari' : '';

  const formattedTestId = testId
    .trim()
    .replace(/\s+/g, ' ')
    .replace(/\s/g, '-');

  return (
    <div
      className="static-text"
      data-testid={formattedTestId}
      {...rest}
    >
      <div className={`label ${isSafari}`} data-testid={`${formattedTestId}-label`}>
        {label}
        {required ? (
          <span
            className="required-symbol"
            data-testid={`${formattedTestId}-required-symbol`}
          >
            {' '}*
          </span>
        ) : ''}
      </div>
      <div className={`value ${isSafari}`} data-testid={`${formattedTestId}-value`}>
        {value}
      </div>
    </div>
  );
};

StaticText.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  testId: PropTypes.string,
  required: PropTypes.bool,
};

StaticText.defaultProps = {
  label: '',
  value: '',
  testId: '',
  required: false,
};

export default StaticText;
