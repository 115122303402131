import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import { useDispatch, useStore } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Container,
  Layout,
  Flex,
  Icon,
  MessageBoard,
  NotificationContext,
  useClickOutside,
} from '@partner-global-ui/components';
import PropTypes from 'prop-types';
import moment from 'moment';
import { usePermission } from '../../../utils/accessControl/hasPermission';
import roleKeys from '../../../utils/accessControl/roleKeys';
import orderStatuses, { displayStatuses } from '../../../constants/order.constants';
import waiverReasons from '../../../constants/waiverReasons.constants';
import * as scanStatuses from '../../../constants/scanStatus.constants';
import * as orderActions from '../../../actions/orderActions';
import * as trackingActions from '../../../actions/trackingActions';
import { autoHide, timeout } from '../../../constants/notifications.constant';

const isMissingWaiverReason = (areChangesMade, order) => {
  if (!areChangesMade) {
    return false;
  }
  // has fee waiver
  if (order.applyFeeWaiver) {
    // check to see if the reason is set
    return !(order.feeWaiverReason === waiverReasons.BUSINESS_APPROVED.value
      || order.feeWaiverReason === waiverReasons.CONTRACTUAL.value);
  }
  return false;
};

const isInvalidUseCount = (useCount = '1') => {
  return Number(useCount) < 1 || Number(useCount) > 1000000;
};
function Nav({
  order,
  hasError,
  hasOrderFeeActivity,
  areChangesMade,
  updateOriginalOrderValues,
  updatedOrderValues,
}) {
  const dispatch = useDispatch();
  const store = useStore();
  const history = useHistory();
  const params = useParams();
  const { t } = useTranslation();
  const notificationCont = useContext(NotificationContext);
  const [saveDisabled, setSaveDisabled] = useState(true);

  const [saving, setSaving] = useState(false);
  const [requestingAmendment, setRequestingAmendment] = useState(false);
  const [rejecting, setRejecting] = useState(false);
  const [approving, setApproving] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [showMessageBoard, setShowMessageBoard] = useState('');
  const [disableApproveButton, setDisableApproveButton] = useState(false);

  // Only one Message Board is rendered on the page at a time,
  //  so both the comment board and Approver CTA board can
  //  share the same ref without any conflicts.
  const messageBoardRef = useRef(null);

  const canSave = usePermission(roleKeys.permittedUsesOrder.canSave);
  const canApprove = usePermission(roleKeys.permittedUsesOrder.canApprove);
  const canViewComment = usePermission(roleKeys.permittedUsesOrder.viewComment);

  const isApproved = order.orderStatus === orderStatuses.APPROVED_ORDER;
  const canBeApproved = order.orderStatus === orderStatuses.SUBMITTED_PENDING_APPROVAL;

  const showSave = canSave && isApproved;
  const showApprove = canApprove && canBeApproved;

  const status = displayStatuses.find(displayStatus => displayStatus.value === order.orderStatus);

  const notifyPayload = { autoHide, timeout };
  const notify = {
    success: message => notificationCont.dispatch({
      type: 'add',
      payload: { ...notifyPayload, message, status: 'success' },
    }),
    error: message => notificationCont.dispatch({
      type: 'add',
      payload: { ...notifyPayload, message, status: 'error' },
    }),
  };

  useEffect(() => {
    if (saving || approving || rejecting || requestingAmendment) {
      setWaiting(true);
    } else {
      setWaiting(false);
    }
  }, [approving, rejecting, requestingAmendment]);

  useEffect(() => {
    const invalidPoFile = order.poFileScanStatus === scanStatuses.FAILED
      || order.poFileScanStatus === scanStatuses.PENDING;
    if (
      invalidPoFile
      || isMissingWaiverReason(areChangesMade, updatedOrderValues)
      || isInvalidUseCount(updatedOrderValues.useCount)
    ) {
      setDisableApproveButton(true);
    } else {
      setDisableApproveButton(order.orderActivity === 'DISC_SUBSTITUTE' ? !hasOrderFeeActivity : waiting);
    }
  }, [
    order.poFileScanStatus, order.orderActivity, hasOrderFeeActivity,
    updatedOrderValues.applyFeeWaiver, updatedOrderValues.feeWaiverReason,
    areChangesMade, updatedOrderValues.useCount,
  ]);

  useEffect(() => {
    if (hasError || !areChangesMade || isMissingWaiverReason(areChangesMade, updatedOrderValues)) {
      setSaveDisabled(true);
    } else {
      setSaveDisabled(false);
    }
  }, [
    hasError, areChangesMade,
    updatedOrderValues.applyFeeWaiver, updatedOrderValues.feeWaiverReason,
  ]);

  const openMessageBoard = useCallback((actionType) => {
    setShowMessageBoard(actionType);
    setWaiting(true);
  }, []);

  const closeMessageBoard = useCallback(() => {
    setShowMessageBoard('');
    setWaiting(false);
  }, []);

  useClickOutside(messageBoardRef, () => {
    closeMessageBoard();
  });

  const handleSave = useCallback(async () => {
    setSaving(true);

    try {
      const orderExists = typeof params.id !== 'undefined';
      const updatedOrder = await orderActions
        .saveOrder(order, updatedOrderValues, notificationCont)(dispatch, store.getState);
      if (!orderExists) {
        history.push(`/order/${updatedOrder.orderNumber}`);
      }
      updateOriginalOrderValues(updatedOrder);
      notify.success(
        orderExists
          ? t('msg_codes_orderDetailsAdmin_orderUpdated_toast')
          : t('msg_codes_orderDetailsAdmin_orderCreated_toast'),
      );
    } catch (error) {
      notify.error(t('msg_codes_codes_tooltip_somethingWentWrong'));
      setSaving(false);
      return new Error(error);
    }

    return setSaving(false);
  }, [order, updatedOrderValues]);

  const handleRequestAmendment = useCallback(async (message) => {
    const { orderNumber, applyFeeWaiver } = order;
    setRequestingAmendment(true);

    try {
      const result = await orderActions.amendOrder(orderNumber, message, applyFeeWaiver)(dispatch);
      if (
        typeof result !== 'undefined'
        && result.response.data.detailMessages[0].includes('comment: must not be blank')
      ) {
        notify.error(t('msg_codes_codes_tooltip_somethingWentWrong'));
        closeMessageBoard();
      } else {
        closeMessageBoard();
        notify.success(t('msg_codes_orderDetailsAdmin_requestSent_toast'));
        history.push('/orders');
        dispatch(
          trackingActions.fireEvent('order-click', 'click', 'empty', 'reject', 'order-submission', '1', 'button'),
        );
      }
    } catch (error) {
      notify.error(t('msg_codes_codes_tooltip_somethingWentWrong'));
    }

    setRequestingAmendment(false);
  }, [order]);

  const handleReject = useCallback(async (message) => {
    const { orderNumber, applyFeeWaiver } = order;
    setRejecting(true);

    try {
      const result = await orderActions.rejectOrder(orderNumber, message, applyFeeWaiver)(dispatch);
      if (
        typeof result !== 'undefined'
        && result.response.data.detailMessages[0].includes('comment: must not be blank')
      ) {
        notify.error(t('msg_codes_codes_tooltip_somethingWentWrong'));
        closeMessageBoard();
      } else {
        closeMessageBoard();
        notify.success(t('msg_codes_orderDetailsAdmin_orderRejected_toast'));
        history.push('/orders');
        dispatch(
          trackingActions.fireEvent('order-click', 'click', 'empty', 'reject', 'order-submission', '1', 'button'),
        );
      }
    } catch (error) {
      notify.error(t('msg_codes_codes_tooltip_somethingWentWrong'));
    }

    setRejecting(false);
  }, [order]);

  const handleApprove = useCallback(async () => {
    setApproving(true);
    const newOrder = {
      ...order,
      applyFeeWaiver: updatedOrderValues.applyFeeWaiver,
      feeWaiverReason: updatedOrderValues.feeWaiverReason,
    };
    try {
      await orderActions.approveOrder(newOrder)(dispatch);
      notify.success(t('msg_codes_orderDetailsAdmin_orderApproved_toast'));
      history.push('/orders');
      dispatch(
        trackingActions.fireEvent('order-click', 'click', 'empty', 'approve', 'order-submission', '1', 'button'),
      );
    } catch (error) {
      notify.error(t('msg_codes_codes_tooltip_somethingWentWrong'));
    }

    setApproving(false);
  }, [order, updatedOrderValues]);

  const toggleCommentDisplay = useCallback(() => {
    if (showMessageBoard === 'comment') {
      setShowMessageBoard('');
    } else {
      setShowMessageBoard('comment');
    }
  }, [showMessageBoard]);

  function renderActionWithMessage(actionType, button) {
    const action = actionType === 'amend' ? handleRequestAmendment
      : actionType === 'reject' && handleReject;

    return (
      <div className="action-button">
        {button}
        {showMessageBoard === actionType && (
          <div
            ref={messageBoardRef}
            className={`message-board-container ${actionType}`}
          >
            <MessageBoard
              id="message-board"
              title={t('msg_codes_orderDetailsAdmin_msgboard_message')}
              statusText={t('msg_codes_orderDetailsAdmin_msgboard_note')}
              statusIcon="ico-info"
              statusIconColor="info"
              placeholder={t(
                'msg_codes_orderDetailsAdmin_msgboard_enterComments',
              )}
              onClose={closeMessageBoard}
              onCancel={closeMessageBoard}
              onSend={action}
              cancelButtonText={t('msg_codes_cta_cancel')}
              sendButtonText={t(
                'msg_codes_orderDetailsAdmin_msgboard_send_cta',
              )}
            />
          </div>
        )}
      </div>
    );
  }

  return (
    <div data-testid="permitted-uses-order-nav" className="permitted-uses-order-nav">
      <Container>
        <Layout>
          <Flex colSpan="6" className="order-title">
            <h3 data-testid="order-number">{t('msg_codes_ordernum')}: {order.orderNumber}</h3>
            <div className="divider" />
            <div className="status">
              <div className="status-label" data-testid="order-status-label">{t('msg_codes_agreements_agreementStatus')}:</div>
              <div className="value">
                <div data-testid="order-status">{t(status.translationStringId)}</div>
                {(canViewComment && (order.rejectedComments || order.amendmentComments)) && (
                  <div className="comment-icon">
                    <span
                      id="message-icon-clickbox"
                      data-testid="message-icon-clickbox"
                      onClick={toggleCommentDisplay}
                      // required for aria
                      onKeyDown={toggleCommentDisplay}
                      role="button"
                      tabIndex="-1"
                    >
                      <Icon size={15}>chat</Icon>
                    </span>
                    {showMessageBoard === 'comment' && (
                      <div
                        ref={messageBoardRef}
                        className="comment-message-container"
                      >
                        <MessageBoard
                          id="current-comment"
                          title={t('msg_codes_orderListAdmin_comment_label')}
                          message={t(order.rejectedComments || t(order.amendmentComments))}
                          onClose={closeMessageBoard}
                          name={t('msg_codes_accountType_sony')}
                          date={moment(order.modifyDate).format('MM/DD/YY hh:mm a')}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Flex>
          <Flex colSpan="6">
            {showSave && (
              <div className="buttons save" data-testid="save-button">
                <Button
                  id="save-button"
                  loading={saving}
                  onClick={handleSave}
                  disabled={saveDisabled}
                >
                  {t('msg_codes_cta_save')}
                </Button>
              </div>
            )}

            {showApprove && (
              <div className="buttons approve" data-testid="approve-buttons">
                {renderActionWithMessage(
                  'amend',
                  <Button
                    id="request-amendment-button"
                    icon="chat"
                    disabled={waiting}
                    loading={requestingAmendment}
                    onClick={() => openMessageBoard('amend')}
                  >
                    {t('msg_codes_orderDetailsAdmin_reqAmendment_cta')}
                  </Button>,
                )}
                {renderActionWithMessage(
                  'reject',
                  <Button
                    id="cancel-button"
                    icon="ico-remove_cancel"
                    disabled={waiting}
                    loading={rejecting}
                    onClick={() => openMessageBoard('reject')}
                  >
                    {t('msg_codes_cta_common_reject')}
                  </Button>,
                )}
                <Button
                  id="approve-button"
                  icon="ico-checkmark-circle"
                  disabled={disableApproveButton}
                  loading={approving}
                  onClick={handleApprove}
                >
                  {t('msg_codes_cta_order_approve')}
                </Button>
              </div>
            )}
          </Flex>
        </Layout>
      </Container>
    </div>
  );
}

Nav.propTypes = {
  order: PropTypes.object,
  hasError: PropTypes.bool,
  hasOrderFeeActivity: PropTypes.bool,
  areChangesMade: PropTypes.bool,
  updateOriginalOrderValues: PropTypes.func,
  updatedOrderValues: PropTypes.object,
};

Nav.defaultProps = {
  order: {},
  hasError: false,
  hasOrderFeeActivity: false,
  areChangesMade: false,
  updateOriginalOrderValues: () => {},
  updatedOrderValues: {},
};

export default Nav;
