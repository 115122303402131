import moment from 'moment';
import { changePropOnLine } from '../../../actions/orderActions';

const checkStartDate = (start, end, today) => {
  if (start === 'Invalid date') {
    return { valid: false, error: 'invalid' };
  }

  if (moment(start).isBefore(today)) {
    return { valid: false, error: 'before today' };
  }

  if (moment(start).isAfter(end)) {
    return { valid: false, error: 'after end' };
  }

  return { valid: true };
};

const checkEndDate = (start, end) => {
  if (end === 'Invalid date') {
    return { valid: false, error: 'invalid' };
  }

  if (moment(end).isBefore(start)) {
    return { valid: false, error: 'before start' };
  }

  return { valid: true };
};

/* eslint-disable import/prefer-default-export */
export const handleSetStartDate = ({
  date,
  line,
  localEndDate,
  updatedOrderValues,
  localStartTime,
  setStartDateError,
  setEndDateError,
  setLocalStartDate,
  setUpdatedOrderValues,
  dispatch,
  t,
}) => {
  // Set today's date for validation
  const today = moment().format('YYYY/MM/DD');
  // Format date
  const start = moment(date).format('YYYY/MM/DD');
  const end = moment(localEndDate).format('YYYY/MM/DD');

  if (checkEndDate(start, end).valid) {
    setEndDateError('');
  }

  if (date.length === 0) {
    setStartDateError('');
    setLocalStartDate('');

    setUpdatedOrderValues({
      ...updatedOrderValues,
      startDate: null,
    });

    changePropOnLine(line, 'scheduledActivationDate', null)(dispatch);
    return;
  }

  // handle start/end/today errors
  const startDateValidation = checkStartDate(start, end, today);
  if (!startDateValidation.valid) {
    if (startDateValidation.error === 'invalid') {
      setStartDateError(t('msg_codes_invalidInput_tooltip'));
    } else if (startDateValidation.error === 'before today') {
      setStartDateError(t('msg_codes_orders_cart_startDateTodayOrLater_tooltip'));
    } if (startDateValidation.error === 'after end') {
      setStartDateError(t('msg_codes_orders_cart_startDateTodayOrLater_tooltip'));
    }

    setLocalStartDate(date);
    return;
  }

  setLocalStartDate(date);

  // Format date and time into one timestamp and set scheduledActivationDate on order.
  const formattedStartDate = moment(start, 'YYYY/MM/DD').format('YYYY-MM-DD');
  const formattedStartTime = localStartTime
    ? moment(localStartTime, 'hh:mm a').format('HH:mm:ss')
    : '00:00:00';
  const startDateAndTime = `${formattedStartDate}T${formattedStartTime}.000Z`;

  setUpdatedOrderValues({
    ...updatedOrderValues,
    startDate: startDateAndTime,
  });
  changePropOnLine(line, 'scheduledActivationDate', startDateAndTime)(dispatch);
};

export const handleSetStartTime = ({
  time,
  line,
  localStartDate,
  updatedOrderValues,
  setStartDateError,
  setUpdatedOrderValues,
  setLocalStartTime,
  dispatch,
  t,
}) => {
  if (time.length === 0) {
    setStartDateError('');

    // If start date exists, set time to midnight. Else clear scheduledActivationDate.
    if (localStartDate) {
      const formattedStartDate = moment(localStartDate, 'YYYY/MM/DD').format('YYYY-MM-DD');
      setUpdatedOrderValues({
        ...updatedOrderValues,
        startDate: `${formattedStartDate}T00:00:00.000Z`,
      });
      changePropOnLine(line, 'scheduledActivationDate', `${formattedStartDate}T00:00:00.000Z`)(dispatch);
    } else {
      setUpdatedOrderValues({
        ...updatedOrderValues,
        startDate: null,
      });
      changePropOnLine(line, 'scheduledActivationDate', null)(dispatch);
    }
    return;
  }

  const start = moment(time, 'hh:mm A').format('HH:mm:ss');

  if (start === 'Invalid date') {
    setStartDateError(t('msg_codes_invalidInput_tooltip'));
    return;
  }

  setLocalStartTime(start);

  const formattedStartDate = localStartDate
    ? moment(localStartDate, 'YYYY/MM/DD').format('YYYY-MM-DD')
    : moment().format('YYYY-MM-DD');
  const startDateAndTime = `${formattedStartDate}T${start}.000Z`;

  setUpdatedOrderValues({
    ...updatedOrderValues,
    startDate: startDateAndTime,
  });
  changePropOnLine(line, 'scheduledActivationDate', startDateAndTime)(dispatch);
};

export const handleSetEndDate = ({
  date,
  line,
  updatedOrderValues,
  localStartDate,
  localEndTime,
  setStartDateError,
  setEndDateError,
  setLocalEndDate,
  setUpdatedOrderValues,
  dispatch,
  t,
}) => {
  const today = moment().format('YYYY/MM/DD');
  const start = moment(localStartDate).format('YYYY/MM/DD');
  const end = moment(date).format('YYYY/MM/DD');

  if (checkStartDate(start, end, today).valid) {
    setStartDateError('');
  }

  if (date.length === 0) {
    setEndDateError('');
    setUpdatedOrderValues({
      ...updatedOrderValues,
      endDate: null,
    });
    changePropOnLine(line, 'scheduledBatchExpirationDate', null)(dispatch);
    return;
  }

  // handle start/end errors
  const endDateValidation = checkEndDate(start, end);
  if (!endDateValidation.valid) {
    if (endDateValidation.error === 'invalid') {
      setEndDateError(t('msg_codes_invalidInput_tooltip'));
    } else {
      setEndDateError(t('msg_codes_endDate_error'));
    }

    setLocalEndDate(date);
    return;
  }

  setLocalEndDate(date);

  const formattedEndDate = moment(end, 'YYYY/MM/DD').format('YYYY-MM-DD');
  const formattedEndTime = localEndTime
    ? moment(localEndTime, 'hh:mm a').format('HH:mm:ss')
    : '00:00:00';
  const endDateAndTime = `${formattedEndDate}T${formattedEndTime}.000Z`;

  setUpdatedOrderValues({
    ...updatedOrderValues,
    endDate: endDateAndTime,
  });
  changePropOnLine(line, 'scheduledBatchExpirationDate', endDateAndTime)(dispatch);
};

export const handleSetEndTime = ({
  time,
  line,
  localEndDate,
  updatedOrderValues,
  setEndDateError,
  setUpdatedOrderValues,
  setLocalEndTime,
  dispatch,
  t,
}) => {
  if (time.length === 0) {
    setEndDateError('');

    // If end date exists, set time to midnight. Else clear scheduledBatchExpirationDate.
    if (localEndDate) {
      const formattedEndDate = moment(localEndDate, 'YYYY/MM/DD').format('YYYY-MM-DD');
      setUpdatedOrderValues({
        ...updatedOrderValues,
        endDate: `${formattedEndDate}T00:00:00.000Z`,
      });
      changePropOnLine(line, 'scheduledBatchExpirationDate', `${formattedEndDate}T00:00:00.000Z`)(dispatch);
    } else {
      setUpdatedOrderValues({
        ...updatedOrderValues,
        endDate: null,
      });
      changePropOnLine(line, 'scheduledBatchExpirationDate', null)(dispatch);
    }
    return;
  }

  const end = moment(time, 'hh:mm A').format('HH:mm:ss');

  if (end === 'Invalid date') {
    setEndDateError(t('msg_codes_invalidInput_tooltip'));
    return;
  }

  setLocalEndTime(end);

  const formattedEndDate = localEndDate
    ? moment(localEndDate, 'YYYY/MM/DD').format('YYYY-MM-DD')
    : moment().format('YYYY-MM-DD');
  const endDateAndTime = `${formattedEndDate}T${end}.000Z`;

  setUpdatedOrderValues({
    ...updatedOrderValues,
    endDate: endDateAndTime,
  });
  changePropOnLine(line, 'scheduledBatchExpirationDate', endDateAndTime)(dispatch);
};

export const handleUseCount = ({
  useCount,
  line,
  updatedOrderValues,
  setUpdatedOrderValues,
  dispatch,
}) => {
  setUpdatedOrderValues({
    ...updatedOrderValues,
    useCount,
  });
  changePropOnLine(line, 'useCount', useCount)(dispatch);
};
