import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Container,
  Layout,
  Flex,
  Collapsible,
  Divider,
  TextInput,
  Tooltip,
  Icon,
  iconSizes,
  Checkbox,
  ModalContext,
  MODAL_ACTIONS,
  Modal,
  Select,
  Loader,
  TextArea,
} from '@partner-global-ui/components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import StaticText from '../../common/StaticText';
import { changeProp } from '../../../actions/orderActions';
import * as agreementTypes from '../../../constants/agreementType.constants';
import OrderStatusTypes from '../../../constants/order.constants';
import { activitySelectTypes, calculateFee } from '../../../constants/activityType.constants';
import { orderFeeCurrencies } from '../../../constants/currencies.constants';
import * as scanStatuses from '../../../constants/scanStatus.constants';
import waiverReasons from '../../../constants/waiverReasons.constants';
import downloadPoFile from '../../../actions/thirdPartyOrderActions';
import { usePermission } from '../../../utils/accessControl/hasPermission';
import roleKeys from '../../../utils/accessControl/roleKeys';

const calculatePlaceholder = (currencyCode) => {
  if (currencyCode === 'JPY') {
    return '0';
  }
  return '0.00';
};

function OrderInfo({
  order,
  updatedOrderValues,
  setUpdatedOrderValues,
  setHasOrderFeeActivity,
}) {
  const {
    orderStatus, poFileScanStatus, poFileOriginalFileName, partnerComments = '',
  } = order;
  const { t } = useTranslation();
  const modalContext = useContext(ModalContext);
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [selectCurrency, setSelectCurrency] = useState(orderFeeCurrencies
    .find((currency) => {
      return order.codeFeeCurrency === currency.value;
    }));
  const [orderFeeValue, setorderFeeValue] = useState(order.codeFee ? Number(order.codeFee).toFixed(2) : '0.00');

  const permissions = {
    downloadPO: usePermission(roleKeys.permittedUsesOrder.downloadPO),
    editApplyFeeWaiver: usePermission(roleKeys.permittedUsesOrder.editApplyFeeWaiver),
    editOrderFee: usePermission(roleKeys.order.editOrderFee),
  };

  const trimCurrency = (amount, currencyCode) => {
    if (currencyCode === 'JPY') {
      return amount.split('.')[0];
    }

    const cursorPosition = inputRef.current.selectionStart;
    const decimalValidationRegex = /^[0-9]*\.?[0-9]{0,2}$/;
    const newAmount = decimalValidationRegex.test(amount) ? amount : amount.slice(0, -1);

    /**
     * Use requestAnimationFrame to delay setting the cursor position
     * until after the state has been updated and the input field has been re-rendered.
     * This prevents the cursor from jumping to the end of the input when the value changes.
    */
    requestAnimationFrame(() => {
      inputRef.current.selectionStart = cursorPosition;
      inputRef.current.selectionEnd = cursorPosition;
    });

    return newAmount;
  };

  const handleSelectCurrency = useCallback((e) => {
    if (typeof e.persist === 'function') {
      e.persist();
    }
    const trimmedCurrency = trimCurrency(orderFeeValue, e.target.value.value);
    changeProp('codeFee', trimmedCurrency)(dispatch);
    setUpdatedOrderValues({ ...updatedOrderValues, codeFee: trimmedCurrency });
    setorderFeeValue(trimmedCurrency);

    setUpdatedOrderValues({ ...updatedOrderValues, codeFeeCurrency: e.target.value.value });
    changeProp('codeFeeCurrency', e.target.value.label)(dispatch);
    setSelectCurrency(e.target.value);
  }, [orderFeeValue]);

  const handleCodeFee = useCallback((e) => {
    if (typeof e.persist === 'function') {
      e.persist();
    }
    const newCodeFee = e.target.value;
    setUpdatedOrderValues({ ...updatedOrderValues, codeFee: newCodeFee });
    changeProp('codeFee', newCodeFee)(dispatch);
    setorderFeeValue(newCodeFee);
  }, [selectCurrency]);


  const editableCodeFeeStatuses = [
    OrderStatusTypes.APPROVED_ORDER,
    OrderStatusTypes.SUBMITTED_PENDING_APPROVAL,
    OrderStatusTypes.PENDING_DRAFT_AMENDMENT,
  ];

  const renderOrderFee = useCallback(() => {
    const { codeFee, codeFeeCurrency } = order;
    const disabledOrderFee = !(editableCodeFeeStatuses.includes(orderStatus)
      && (permissions.editOrderFee));

    return (
      <div data-testId="discSubstitute-order-fee">
        {t('msgid_codes_3pagreement_orderFee')}
        <section className="disc-substitute">
          <Select
            name="codeFeeCurrency"
            placeholder={t('msg_codes_common_select')}
            options={orderFeeCurrencies}
            onChange={handleSelectCurrency}
            value={selectCurrency}
            disabled={disabledOrderFee}
          />
          <span className="currency-value">
            <TextInput
              name="codeFee"
              placeholder={calculatePlaceholder(codeFeeCurrency)}
              type="text"
              value={codeFee}
              onChange={handleCodeFee}
              inputFilter={(input) => { return trimCurrency(input, codeFeeCurrency); }}
              inputRef={inputRef}
              width={80}
              disabled={disabledOrderFee}
            />
          </span>
        </section>
      </div>
    );
  }, [order.codeFee, order.codeFeeCurrency, orderStatus]);

  const getOrderFee = useCallback(() => {
    const { region, orderActivity } = order;

    return typeof activitySelectTypes[orderActivity] !== 'undefined'
      ? activitySelectTypes[orderActivity].orderFee(region, true)
      : '';
  }, [order.region]);

  const orderFee = getOrderFee();

  useEffect(() => {
    const { orderActivity } = order;
    if (orderActivity === activitySelectTypes.DISC_SUBSTITUTE.value && Number(orderFeeValue) > 0) {
      setHasOrderFeeActivity(true);
    } else if (Number(orderFeeValue) <= 0 && activitySelectTypes.DISC_SUBSTITUTE) {
      setHasOrderFeeActivity(false);
    }
  }, [selectCurrency, orderFeeValue]);


  const getRedemptionFee = useCallback(() => {
    const { region, orderActivity } = order;

    return typeof activitySelectTypes[orderActivity] !== 'undefined'
      ? activitySelectTypes[orderActivity].redemptionFee(region, true)
      : '';
  }, [order.region]);

  const handlePoFileClick = () => {
    const { orderNumber } = order;
    if (orderNumber) {
      downloadPoFile(orderNumber);
    }
  };

  const hideFeeWaiverModal = () => {
    modalContext.dispatch({
      type: MODAL_ACTIONS.HIDE,
    });
  };

  const setWaiverReason = (reason) => {
    return () => {
      setUpdatedOrderValues({ ...updatedOrderValues, feeWaiverReason: reason });
      hideFeeWaiverModal();
    };
  };
  const resetFeeWaiverReason = () => {
    setUpdatedOrderValues({ ...updatedOrderValues, applyFeeWaiver: order.applyFeeWaiver });
    setUpdatedOrderValues({ ...updatedOrderValues, feeWaiverReason: order.feeWaiverReason });
    hideFeeWaiverModal();
  };

  const feeWaiverModalProps = {
    id: 'permitted-uses-fee-waiver-modal',
    name: 'permittedUsesFeeWaiverModal',
    title: t('msg_codes_orderList_warning'),
    content: <div className="fee-waiver-modal-message">{t('msg_codes_feeWaiver_modal_body')}</div>,
    primaryLabel: t('msg_codes_common_yes'),
    secondaryLabel: t('msg_codes_cta_cancel'),
    onSecondary: resetFeeWaiverReason,
    onClose: resetFeeWaiverReason,
    hideCancelButton: true,
  };

  const toggleFeeWaiver = () => {
    const newFeeWaiver = {
      ...updatedOrderValues,
      applyFeeWaiver: !updatedOrderValues.applyFeeWaiver,
    };
    if (!newFeeWaiver.applyFeeWaiver) {
      modalContext.dispatch({
        type: MODAL_ACTIONS.SHOW,
        payload: <Modal
          {...feeWaiverModalProps}
          onPrimary={() => {
            newFeeWaiver.feeWaiverReason = undefined;
            hideFeeWaiverModal();
            setUpdatedOrderValues(newFeeWaiver);
          }}
          onSecondary={() => hideFeeWaiverModal()}
          onClose={() => hideFeeWaiverModal()}
        />,
      });
    } else {
      setUpdatedOrderValues(newFeeWaiver);
    }
  };

  const waiverReasonOptions = [
    {
      label: t(waiverReasons.CONTRACTUAL.label),
      value: waiverReasons.CONTRACTUAL.value,
    },
    {
      label: t(waiverReasons.BUSINESS_APPROVED.label),
      value: waiverReasons.BUSINESS_APPROVED.value,
    },
  ];

  const getWaiverReasonOption = (originalReason, reason) => {
    if (reason) {
      return waiverReasonOptions.find(option => option.value === reason);
    }
    return waiverReasonOptions.find(option => option.value === originalReason);
  };

  const handleFeeWaiverClick = () => {
    toggleFeeWaiver();
  };

  const handleWaiverReason = (event) => {
    modalContext.dispatch({
      type: MODAL_ACTIONS.SHOW,
      payload: <Modal
        {...feeWaiverModalProps}
        onPrimary={setWaiverReason(event.target.value.value)}
      />,
    });
  };
  // find most recent submission and retrieve user name
  const mostRecentSubmission = order.orderHistory.find(historyLine => historyLine.status.includes('SUBMITTED'));
  const submittedBy = mostRecentSubmission ? mostRecentSubmission.byUser : '';

  const agreementLink = (
    <Link to={`/agreement/${order.agreementId}`} className="link">
      {t(agreementTypes[order.agreementType])}
    </Link>
  );

  const canEditFeeWaiver = permissions.editApplyFeeWaiver && (
    order.orderStatus === OrderStatusTypes.APPROVED_ORDER
    || order.orderStatus === OrderStatusTypes.SUBMITTED_PENDING_APPROVAL
  );

  const renderPoFileDownload = () => {
    if (permissions.downloadPO) {
      switch (poFileScanStatus) {
        case scanStatuses.PASSED:
          return (
            <div
              className="po-download-icon"
              tabIndex="0"
              onKeyPress={handlePoFileClick}
              onClick={handlePoFileClick}
              role="button"
            >
              <Tooltip
                id={`${order.orderNumber}-poDownload`}
                content={t('msg_codes_cta_download')}
                position="top"
                shortCopy
              >
                <Icon id="download-po" size={iconSizes.Large}>file_download</Icon>
              </Tooltip>
            </div>
          );
        case scanStatuses.PENDING:
          return (
            <div className="po-pending">
              <Tooltip
                id={`${order.orderNumber}-poDownload`}
                content={t('msg_codes_virusScan_inProgress_scanning_tooltip')}
                position="top"
                shortCopy
              >
                <Loader sm />
              </Tooltip>
            </div>
          );
        case scanStatuses.FAILED:
        default:
          return (
            <div
              className="po-error-icon"
              tabIndex="0"
              role="button"
            >
              <Tooltip
                id={`${order.orderNumber}-poDownload`}
                content={
                  t(
                    'msg_codes_orderInfo_virusScan_filenameFailed_error_tooltip',
                    {
                      fileName: poFileOriginalFileName,
                      interpolation: {
                        escapeValue: false,
                      },
                    },
                  )}
                position="top"
                shortCopy
              >
                <Icon id="error-po" size={iconSizes.Large}>ico-alert</Icon>
              </Tooltip>
            </div>
          );
      }
    }
    return <></>;
  };

  return (
    <Layout>
      <Flex colSpan="12">
        <Collapsible
          id="third-party-order-details"
          heading={
            `${t('msg_codes_NewOrder_orderInformation')} ${order.partnerName}`
          }
          rightContent={`${t('msg_codes_agreements_createdBy')} ${submittedBy} on ${moment(order.creationDate).format('MM/DD/YYYY')}`}
          defaultIsOpen
        >
          <Container className="info-container">
            <Layout className="layout">
              <Flex colSpan="12" className="flex-wrapper">
                <Flex colSpan={4}>
                  <StaticText
                    testId="agreement-type"
                    label={t('msg_codes_agreements_agreementType')}
                    value={agreementLink}
                  />
                  <StaticText
                    testId="activity"
                    label={t('msg_codes_common_activity')}
                    value={t(activitySelectTypes[order.orderActivity].translationStringId)}
                  />
                  {order.poNumber && (
                    <div data-testid="po-number-label" className="po-number">
                      <TextInput
                        id="po-number-input"
                        label={t('msg_codes_NewOrder_PurchaseOrder')}
                        value={order.poNumber}
                        required={false}
                        disabled
                      />
                      {renderPoFileDownload()}
                    </div>
                  )
                  }
                  <div data-testid="order-comments" className="order-comments">
                    <TextArea
                      id="order-comments-text-area"
                      label={t('msg_codes_order_comment_box')}
                      value={partnerComments}
                      placeholder=""
                      required={false}
                      disabled
                      displayCount
                      charCountMax={500}
                      resize="both"
                    />
                  </div>
                </Flex>
                <Flex colSpan={1} offset={1}>
                  <Divider style={{ border: 'none' }} secondary vertical />
                </Flex>

                <Flex colSpan={4}>
                  <Checkbox
                    id="apply-fee-waiver"
                    label={t('msg_codes_order_applyFeeWaiver')}
                    checked={updatedOrderValues.applyFeeWaiver}
                    disabled={!canEditFeeWaiver}
                    onClick={handleFeeWaiverClick}
                    attr={{ id: 'apply-fee-waiver-checkbox' }}
                  />
                  { updatedOrderValues.applyFeeWaiver && (
                    <>
                      <StaticText
                        testId="waiver-reason"
                        label={t('msg_codes_fee_waiver_reason')}
                        required
                        value={(
                          <Select
                            id="waiverReason"
                            className="waiver-reason"
                            name="waiverReason"
                            placeholder={t('msg_codes_common_select')}
                            options={waiverReasonOptions}
                            onChange={handleWaiverReason}
                            value={getWaiverReasonOption(
                              order.feeWaiverReason, updatedOrderValues.feeWaiverReason,
                            )}
                            disabled={!canEditFeeWaiver}
                          />
                        )}
                      />
                    </>
                  ) }
                  {orderFee !== calculateFee()
                    ? (
                      <StaticText
                        testId="order-fee"
                        label={t('msgid_codes_3pagreement_orderFee')}
                        value={orderFee}
                      />
                    ) : (renderOrderFee())}
                  <StaticText
                    testId="redemption-fee"
                    label={t('msg_codes_orders_redemptionFee')}
                    value={getRedemptionFee()}
                  />
                </Flex>

              </Flex>
            </Layout>
          </Container>
        </Collapsible>
      </Flex>
    </Layout>
  );
}

OrderInfo.propTypes = {
  order: PropTypes.object,
  updatedOrderValues: PropTypes.object,
  setUpdatedOrderValues: PropTypes.func,
  setHasOrderFeeActivity: PropTypes.func,
};

OrderInfo.defaultProps = {
  order: {},
  updatedOrderValues: {},
  setUpdatedOrderValues: () => { },
  setHasOrderFeeActivity: () => { },
};

export default OrderInfo;
