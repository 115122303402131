import axios from 'axios';
import download from 'downloadjs';
import i18next from 'i18next';
import * as types from './actionTypes';
import { getUser } from './userFormActionsNew';
import { autoHide, timeout } from '../constants/notifications.constant';

export function resetPgpKey(dispatch) {
  dispatch({ type: types.RESET_PGP_KEY });
}

export function updatePgpKey(dispatch, key) {
  dispatch({ type: types.UPDATE_PGP_KEY, value: key });
}

export function validatePgpKey(key) {
  return async (dispatch) => {
    dispatch({ type: `${types.VALIDATE_PGP_KEY}_PENDING` });

    await axios.post('/venom/api/pgpkeys/validate', { publicKeyData: key }).then(() => {
      dispatch({ type: `${types.VALIDATE_PGP_KEY}_FULFILLED` });
    }).catch(() => {
      dispatch({ type: `${types.VALIDATE_PGP_KEY}_REJECTED` });
    });
  };
}

export function addPgpKey(notificationContext) {
  return async (dispatch, getState) => {
    const {
      user: { userId },
      pgpKey: { key },
      user: {
        roleCode,
      },
      userPage: { changesMade },
    } = getState();

    const isSuperAdmin = roleCode === 'SUPER_ADMIN';

    dispatch({ type: `${types.ADD_PGP_KEY}_PENDING` });
    await axios.post(`/venom/api/users/${userId}/pgpkeys`, { publicKeyData: key })
      .then(() => {
        dispatch({ type: `${types.ADD_PGP_KEY}_FULFILLED` });
        if (!changesMade) {
          notificationContext.dispatch({
            type: 'add',
            payload: {
              status: 'success',
              autoHide,
              timeout,
              message: isSuperAdmin ? i18next.t('msg_codes_banner_informationSaved') : i18next.t('msg_codes_banner_PGPKeySaved'),
              testId: 'success',
            },
          });
          getUser(dispatch, userId);
        }
      })
      .catch((error) => {
        const { response: { detailMessages, message } } = error;
        if (detailMessages && detailMessages.length > 0) {
          notificationContext.dispatch({
            type: 'add',
            payload: {
              status: 'error',
              autoHide,
              timeout,
              message: detailMessages,
              testId: 'error',
            },
          });
        } else {
          notificationContext.dispatch({
            type: 'add',
            payload: {
              status: 'error',
              autoHide,
              timeout,
              message,
              testId: 'error',
            },
          });
        }
        dispatch({ type: `${types.ADD_PGP_KEY}_REJECTED` });
      });
  };
}

export function editPgpKey(notificationContext) {
  return async (dispatch, getState) => {
    const {
      user: { userId },
      pgpKey: { key, activeFingerprint },
      userPage: { changesMade },
    } = getState();

    dispatch({ type: `${types.EDIT_PGP_KEY}_PENDING` });

    await axios.put(`/venom/api/users/${userId}/pgpkeys/${activeFingerprint}`, { publicKeyData: key })
      .then(() => {
        notificationContext.dispatch({
          type: 'add',
          payload: {
            status: 'success',
            autoHide,
            timeout,
            message: i18next.t('msg_codes_banner_PGPKeySaved'),
            testId: 'success',
          },
        });
        dispatch({ type: `${types.EDIT_PGP_KEY}_FULFILLED` });
        if (!changesMade) {
          getUser(dispatch, userId);
        }
      })
      .catch((error) => {
        const { response: { detailMessages, message } } = error;
        if (detailMessages && detailMessages.length > 0) {
          notificationContext.dispatch({
            type: 'add',
            payload: {
              status: 'error',
              autoHide,
              timeout,
              message: detailMessages,
              testId: 'error',
            },
          });
        } else {
          notificationContext.dispatch({
            type: 'add',
            payload: {
              status: 'error',
              autoHide,
              timeout,
              message,
              testId: 'error',
            },
          });
        }
        dispatch({ type: `${types.EDIT_PGP_KEY}_REJECTED` });
      });
  };
}

export function removePgpKey({ userId, activeFingerprint }) {
  return async (dispatch) => {
    localStorage.removeItem(`needsPGPSetup-${userId}`);
    dispatch({ type: `${types.REMOVE_PGP_KEY}_PENDING` });
    await axios.delete(`/venom/api/users/${userId}/pgpkeys/${activeFingerprint}`)
      .then(() => {
        dispatch({ type: `${types.REMOVE_PGP_KEY}_FULFILLED` });
      }).catch(() => {
        dispatch({ type: `${types.REMOVE_PGP_KEY}_REJECTED` });
      });
  };
}

export function downloadPgpKey({ userId, activeFingerprint }) {
  return async (dispatch) => {
    dispatch({ type: `${types.DOWNLOAD_PGP_KEY}_PENDING` });
    await axios.get(`/venom/api/users/${userId}/pgpkeys/${activeFingerprint}/download`)
      .then(async (response) => {
        const blob = response.data;
        dispatch({ type: `${types.DOWNLOAD_PGP_KEY}_FULFILLED` });
        download(blob, `${activeFingerprint}.asc`, 'text/plain');
      })
      .catch(() => {
        dispatch({ type: `${types.DOWNLOAD_PGP_KEY}_REJECTED` });
      });
  };
}

export function acceptTerms({ userId, hasActiveFingerprint }, history) {
  return (dispatch) => {
    return axios.post(`/venom/api/users/${userId}/acceptTerms`)
      .then(() => {
        dispatch({ type: types.ACCEPT_TERMS_SUCCESS });
        if (hasActiveFingerprint) {
          history.push('/');
        }
      })
      .catch(() => {
        dispatch({ type: types.ACCEPT_TERMS_ERROR });
      });
  };
}

export function viewedPGPSetupPage(isViewed) {
  return (dispatch) => {
    dispatch({ type: types.VIEWED_PGP_SETUP_PAGE, isViewed });
  };
}

export function removePGPKeyFromUser() {
  return (dispatch) => {
    dispatch({ type: types.REMOVE_PGP_KEY_FROM_USER });
  };
}

export function canceledPGPSetup() {
  return (dispatch) => {
    dispatch({ type: types.CANCELED_PGP_SETUP });
  };
}
