import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Collapsible,
  RowDisplay,
  Pagination,
  TableHeader,
  TableHeaderCell,
  DataTable,
  NoResults,
  Loader,
  Flex,
} from '@partner-global-ui/components';
import history from '../../../history';
import * as agreementActions from '../../../actions/agreementActions';
import * as AgreementTypes from '../../../constants/agreementType.constants';
import * as partnerStatus from '../../../constants/partnerState.constants';
import PartnerTypes from '../../../constants/integratorPartnerTypes.constants';
import hasPermission from '../../../utils/accessControl/hasPermission';
import roleKeys from '../../../utils/accessControl/roleKeys';
import AgreementsHeaderColumns from './TableHeaderColumns';
import RowDisplayLocalization from '../../../helpers/RowDisplayLocalization';
import Agreement from './Agreement';
import './AgreementsPartner.scss';

const PartnerDetailAgreements = (props) => {
  const { t } = useTranslation();
  const initialSort = {
    sortBy: 'agreementId',
    sortDir: 'desc',
  };
  const { userId } = props;
  const [sort, setSort] = useState(initialSort);
  const initialRowAmount = localStorage.getItem(`partnerAgreementsPageSize-${userId}`) !== null
    ? Number(localStorage.getItem(`partnerAgreementsPageSize-${userId}`))
    : 50;
  const [pageRowAmount, setPageRowAmount] = useState(initialRowAmount);

  useEffect(() => {
    const { actions, match } = props;
    const { sortBy, sortDir } = sort;
    const { loadPartnerAgreements } = actions;
    if (localStorage.getItem(`partnerAgreementsPageSize-${userId}` !== null)) {
      setPageRowAmount(Number(localStorage.setItem(`partnerAgreementsPageSize-${userId}`)));
    }
    loadPartnerAgreements(match.params.id, 0, pageRowAmount, { name: sortBy, order: sortDir });
  }, []);

  const changePageRowAmount = (size) => {
    const { sortBy, sortDir } = sort;
    const { match, actions: { loadPartnerAgreements } } = props;
    localStorage.setItem(`partnerAgreementsPageSize-${userId}`, size);
    setPageRowAmount(size);
    loadPartnerAgreements(match.params.id, 0, size, { name: sortBy, order: sortDir });
  };

  const changePage = (page) => {
    const { match, actions: { loadPartnerAgreements } } = props;
    loadPartnerAgreements(
      match.params.id, page - 1, pageRowAmount, { name: sort.sortBy, order: sort.sortDir },
    );
  };

  const newAgreement = () => {
    const { location, match } = props;
    history.push(`/agreement/create?return=${location.pathname}&partner=${match.params.id}`);
  };

  const sortAgreements = (name) => {
    let { sortDir } = sort;
    const { sortBy } = sort;

    const {
      match,
      actions: { loadPartnerAgreements },
      agreementsPage: { page },
    } = props;
    // eslint-disable-next-line no-nested-ternary
    sortDir = name === sortBy
      ? sortDir === 'asc'
        ? 'desc'
        : 'asc'
      : 'asc';
    setSort({
      sortBy: name,
      sortDir,
    });
    loadPartnerAgreements(match.params.id, page.number, page.size, { name, order: sortDir });
  };

  const renderLoadingMessage = () => {
    return (
      <div
        name="partner-detail-agreements-grid"
        className="partner-detail-agreements-grid"
      >
        <div
          name="partner-detail-agreements-row"
          className="partner-detail-agreements-row"
        >
          <div
            name="partner-detail-agreements-col"
            className="partner-detail-agreements-col"
          >
            <Loader message="" className="agreements-loader" id="agreements-loading" />
          </div>
        </div>
      </div>
    );
  };

  const {
    agreements, loadingAgreements, canAddAgreement, partner, agreementsPage: { page },
  } = props;


  const formatList = agreements.map((agreement) => {
    return {
      ...agreement,
      agreementId: agreement.agreementId,
      partnerName: agreement.partner ? agreement.partner.name : partner.name,
      agreementTypeName: t(AgreementTypes[agreement.agreementType]),
      agreementStatus: agreement.status,
      partner: agreement.partner ? agreement.partner : partner,
    };
  });

  const mappedAgreements = formatList.map(agreement => (
    <Agreement name="partnerDetailAgreementsRow" key={agreement.agreementId} {...props} agreement={agreement} />
  ));

  return (
    <Flex className={page.totalElements > 10 ? 'agreements-wrapper' : ''}>
      <Collapsible
        heading={t('msg_codes_agreements')}
        defaultIsOpen
        id="agreements-table"
        scrollHeight={368}
        rightContent={[
          canAddAgreement
            ? { actionCallback: newAgreement, actionText: t('msg_codes_cta_createAnAgreement') }
            : {},
        ]}
      >
        <div
          name="partnerDetailAgreementGrid"
          className="partner-detail-agreements-grid"
        >
          <div
            name="partnerDetailAgreementGridRow"
            className="partner-detail-agreements-row"
          >
            <div
              name="partnerDetailAgreementCol"
              className="partner-detail-agreements-col"
            >
              {loadingAgreements
                ? renderLoadingMessage()
                : (
                  <div style={{ flexDirection: 'column', width: '100%', padding: '0px' }}>
                    <DataTable
                      columnTemplate="repeat(6, 1fr)"
                      scrollHeight={368}
                    >
                      <TableHeader className="table-header">
                        {AgreementsHeaderColumns.map((column) => {
                          return (
                            <TableHeaderCell
                              key={column.id}
                              id={column.id}
                              value={column.value}
                              sortable={column.sortable}
                              sort={sort}
                              onClick={sortAgreements}
                              hasExpandable
                              className={`header-cell-${column.value}`}
                            >
                              {t(column.translationStringId)}
                            </TableHeaderCell>
                          );
                        })}
                      </TableHeader>
                      {page.totalElements === 0
                        ? (
                          <NoResults
                            id="no-users"
                            title={t('msg_codes_codes_noResultsFound')}
                            message=""
                          />
                        )
                        : mappedAgreements
                      }
                    </DataTable>
                    {page.totalElements > 10 && (
                      <div className="partner-detail-agreements-paginator">
                        <RowDisplay
                          currentPage={page.number + 1}
                          totalItems={page.totalElements}
                          pageSizes={[10, 25, 50, 100]}
                          onPageSizeChange={changePageRowAmount}
                          initialPageSize={pageRowAmount}
                          showingOfText={RowDisplayLocalization('msg_codes_pagination_showing')(t)}
                        />
                        {page.totalPages > 1
                          && (
                            <Pagination
                              totalRecords={page.totalElements}
                              currentPage={page.number + 1}
                              pageLimit={pageRowAmount}
                              onPageItemClick={changePage}
                            />
                          )}
                      </div>
                    )}
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </Collapsible>
    </Flex>
  );
};

PartnerDetailAgreements.propTypes = {
  loadingAgreements: PropTypes.bool,
  partner: PropTypes.object,
  agreements: PropTypes.array,
  agreementsPage: PropTypes.object,
  actions: PropTypes.objectOf(PropTypes.func),
  canAddAgreement: PropTypes.bool,
  location: PropTypes.object,
  userId: PropTypes.number,
};

PartnerDetailAgreements.defaultProps = {
  loadingAgreements: false,
  partner: {},
  agreements: [],
  agreementsPage: {
    page: {
      size: 50,
      totalElements: 0,
      totalPages: 0,
      number: 0,
    },
  },
  actions: {
    loadAgreements: () => { },
  },
  canAddAgreement: false,
  location: {},
  userId: 0,
};

export function mapStateToProps(state) {
  const {
    user: {
      userId,
    },
    loadingAgreements,
    agreementsPage,
    partner: {
      partner: {
        status,
        partnerType,
      },
    },
  } = state;

  const isPartnerActive = status === partnerStatus.ACTIVE;
  const canAddAgreement = partnerType === PartnerTypes.THIRD_PARTY
    ? isPartnerActive && hasPermission(state, roleKeys.agreement.createThirdParty)
    : isPartnerActive && hasPermission(state, roleKeys.agreement.create);

  return {
    loadingAgreements,
    agreements: state.agreements,
    agreementsPage,
    canAddAgreement,
    userId,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(agreementActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnerDetailAgreements);
