import React from 'react';
import {
  SonyAmerica,
  SonyAsia,
  SonyEurope,
  SonyJapan,
} from '../constants/contractingSieRegion.constants';

const regions = [
  {
    ...SonyAmerica,
    stringId: 'msg_codes_sieRegion_siea',
  },
  {
    ...SonyAsia,
    stringId: 'msg_codes_sieRegion_siejaAsia',
  },
  {
    ...SonyAsia,
    code: 'SIEH_CN',
    stringId: 'msg_codes_sieRegion_siejaAsia',
  },
  {
    ...SonyAsia,
    code: 'SIEH_KR',
    stringId: 'msg_codes_sieRegion_siejaAsia',
  },
  {
    ...SonyAsia,
    code: 'SIEH_OTHER',
    stringId: 'msg_codes_sieRegion_siejaAsia',
  },
  {
    ...SonyEurope,
    stringId: 'msg_codes_sieRegion_siee',
  },
  {
    ...SonyJapan,
    stringId: 'msg_codes_sieRegion_siejaJapan',
  },
];

export const getTargetRegionNames = (codes, t) => {
  const regionLabels = regions
    .filter(option => codes.includes(option.code))
    .map(option => t(option.stringId));

  const labelsWithDots = regionLabels.map((label, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <React.Fragment key={index}>
      {label}
      {
        index < regionLabels.length - 1
        && (
          <span style={{
            display: 'inline-block',
            height: '5px',
            width: '5px',
            backgroundColor: '#767676',
            borderRadius: '50%',
            margin: '2px 5px',
          }}
          />
        )
      }
    </React.Fragment>
  ));

  return labelsWithDots.length > 0 ? labelsWithDots : codes && codes[0];
};

const getRegionName = (code) => {
  const foundRegion = regions.find((region) => {
    return region.code === code;
  });
  return foundRegion ? foundRegion.stringId : code;
};

export default getRegionName;
