import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Layout,
  Flex,
  Search,
} from '@partner-global-ui/components';
import * as usersActions from '../../actions/userListActions';

const Nav = ({ setPageNumber }) => {
  const search = useSelector(state => state.userList.search.value) || '';
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const searchUsers = useCallback((term) => {
    setPageNumber(0);
    dispatch(usersActions.setUsersSearch(term));
  }, [dispatch, search]);

  const clearSearch = useCallback(() => {
    dispatch(usersActions.clearUsersSearch());
  }, [dispatch, search]);

  return (
    <Layout id="users-nav" name="usersNav" className="users-nav">
      <span className="users-nav-content">
        <Flex colSpan={3}>
          <h3 name="users-title" className="users-title">
            {t('msg_codes_users')}
          </h3>
        </Flex>
        <Flex offset={5} colSpan={3}>
          <Search
            search={searchUsers}
            onClearSearch={clearSearch}
            id="users-searchInput"
            width={300}
            useClearIcon
            hideCategories
          />
        </Flex>
      </span>
    </Layout>
  );
};

Nav.propTypes = {
  setPageNumber: PropTypes.func,
};

Nav.defaultProps = {
  setPageNumber: () => {},
};

export default Nav;
