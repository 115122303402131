import React from 'react';
import il8n from 'i18next';
import { feesUrl } from '../utils/thirdPartyDownloadStatus';

const SiehRegions = {
  SIEH_CN: 'SIEH',
  SIEH_OTHER: 'SIEH',
  SIEH_KR: 'SIEH',
  SIEH: 'SIEH',
};

export const regionalCurrency = (region, useCurrencySign = false) => {
  if (region === 'SIEA' || SiehRegions[region] === 'SIEH') {
    return useCurrencySign ? '$' : 'USD';
  }
  if (region === 'SIEE') {
    return useCurrencySign ? '€' : 'EUR';
  }
  return useCurrencySign ? '¥' : 'JPY';
};

export const defaultFeeTemplate = (region, useCurrencySign = false) => {
  return useCurrencySign
    ? `${regionalCurrency(region, true)}0.00`
    : `0 ${regionalCurrency(region)}`;
};

export const calculatedFeeTemplate = () => {
  return <a className="code-product-link" href={feesUrl.toString()} rel="noopener noreferrer" target="_blank">{ il8n.t('msg_codes_3pagreements_storeMargin') }</a>;
};

export const paidAllocationOrderFeeTemplate = (region, useCurrencySign = false) => {
  if (useCurrencySign) {
    const currency = regionalCurrency(region, true);
    const amount = currency === '¥' ? '¥100,000' : `${currency}1000`;
    return (<a className="code-product-link" href={feesUrl.toString()} rel="noopener noreferrer" target="_blank">{amount}</a>);
  }

  const currency = regionalCurrency(region);
  const amount = currency === 'JPY' ? '100,000 JPY' : `1000 ${currency}`;
  return (<a className="code-product-link" href={feesUrl.toString()} rel="noopener noreferrer" target="_blank">{amount}</a>);
};

export const calculateFee = () => 'Order Fee';

export const activitySelectTypes = {
  SELECT: {
    name: 'Select',
    translationStringId: 'msg_codes_common_select',
    value: 'SELECT',
    orderFee: defaultFeeTemplate,
    redemptionFee: defaultFeeTemplate,
  },
  DISC_SUBSTITUTE: {
    name: 'Disc Substitute (Physical Special Editions only)',
    translationStringId: 'msg_codes_orderDetailsAdmin_activity_discSubstitute',
    value: 'DISC_SUBSTITUTE',
    orderFee: calculateFee,
    redemptionFee: defaultFeeTemplate,
  },
  RETAIL_PROMOTION: {
    name: 'In-box/Pre-order Promotion',
    translationStringId: 'msg_codes_inboxPreorderPromotion',
    value: 'RETAIL_PROMOTION',
    orderFee: defaultFeeTemplate,
    redemptionFee: defaultFeeTemplate,
  },
  CONSUMER_PROMOTION: {
    name: 'Consumer Promotion',
    translationStringId: 'msg_codes_assignments_consumerPromo',
    value: 'CONSUMER_PROMOTION',
    orderFee: defaultFeeTemplate,
    redemptionFee: calculatedFeeTemplate,
  },
  BETA_TESTING: {
    name: 'Beta Testing',
    translationStringId: 'msg_code_assignments_betaTesting',
    value: 'BETA_TESTING',
    orderFee: defaultFeeTemplate,
    redemptionFee: defaultFeeTemplate,
  },
  PUBLISHER_FREE_ALLOCATION: {
    name: 'Publisher Internal (Free Allocation)',
    translationStringId: 'msg_codes_orderDetailsAdmin_activity_pubFreeAllo',
    value: 'PUBLISHER_FREE_ALLOCATION',
    orderFee: defaultFeeTemplate,
    redemptionFee: defaultFeeTemplate,
  },
  PUBLISHER_PAID_ALLOCATION: {
    name: 'Publisher Internal (Additional)',
    translationStringId: 'msg_codes_publisherPaidAllo',
    value: 'PUBLISHER_PAID_ALLOCATION',
    orderFee: paidAllocationOrderFeeTemplate,
    redemptionFee: defaultFeeTemplate,
  },
};

export const RETAIL_PROMOTION = 'msg_codes_inboxPreorderPromotion';
export const CONSUMER_PROMOTION = 'msg_codes_assignments_consumerPromo';
export const DISC_SUBSTITUTE = 'msg_codes_orderDetailsAdmin_activity_discSubstitute';
export const BETA_TESTING = 'msg_code_assignments_betaTesting';
export const PUBLISHER_FREE_ALLOCATION = 'msg_codes_orderDetailsAdmin_activity_pubFreeAllo';
export const PUBLISHER_PAID_ALLOCATION = 'msg_codes_publisherPaidAllo';
