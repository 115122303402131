import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tooltip, Icon, iconSizes } from '@partner-global-ui/components';

const TertiaryRowHeader = ({
  page,
  tableRef,
  showCTA,
  name,
}) => {
  const { t } = useTranslation();

  const orderCommentsTooltip = (
    <div className="tooltipDefaultStyles orderComments">
      { t('msg_codes_order_comment_box_tooltip') }
    </div>
  );

  return (
    <div className={`tertiary-headers-row ${page}`}>
      <div
        key="comments"
        value="comments"
        className="tertiary-header comments"
        data-testid={`${name}-comment-header`}
      >
        <div>
          {t('msg_codes_order_comment_box')}
        </div>
        <div className="tooltip-wrapper" data-testid={`${name}-comment-header-tooltip`}>
          <Tooltip
            id="activity-tooltip"
            content={orderCommentsTooltip}
            position="top"
            shortCopy
            strategy="fixed"
            anchor={tableRef}
          >
            <Icon className="info-icon" size={iconSizes.Small}>info</Icon>
          </Tooltip>
        </div>
      </div>
      {showCTA
        && (
          <div
            key="cta"
            id="cta"
            value="cta"
            className="lastColumn tertiary-header cta"
            data-testid={`${name}-tertiary-header-cta`}
          >
            {t('msg_codes_orders_downloadPurchase_header')}
          </div>
        )}
    </div>
  );
};

TertiaryRowHeader.propTypes = {
  name: PropTypes.string, // value used to prefix datatestIds in row
  showCTA: PropTypes.bool,
  page: PropTypes.string,
  tableRef: PropTypes.object,
};

TertiaryRowHeader.defaultProps = {
  name: '', // value used to prefix datatestIds in row
  showCTA: false,
  page: '',
  tableRef: { },
};

export default TertiaryRowHeader;
