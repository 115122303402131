
export default function (voucherTypeCoverageSet) {
  const newVoucherTypeCoverageSet = voucherTypeCoverageSet.map(({
    agreementVersionId,
    voucherProductTypeCoverageId,
    feeStructure,
    ...coverageSet
  }) => ({
    ...coverageSet,
    feeStructure: feeStructure.map(({
      feeStructureId, voucherProductTypeCoverageId: vptcId, ...rest
    }) => ({ ...rest })),
  }));
  return newVoucherTypeCoverageSet;
}
