export const searchThirdPartyCatalog = (localDispatch) => {
  localDispatch({ type: 'setState', name: 'searched', value: true });
};

export const handleKeyPress = (e, searchVouchers) => {
  if (e.key === 'Enter') {
    searchVouchers();
  }
};

export const changePage = (
  loadThirdPartyCatalog, page, sort, sortOrder, thirdPartyCatalogPage,
  filters, search, dispatch, partnerId, notificationCont,
) => {
  loadThirdPartyCatalog(dispatch, thirdPartyCatalogPage.size,
    page, search, { name: sort, order: sortOrder },
    filters, partnerId, notificationCont);
};

export const changeRowAmount = (
  loadThirdPartyCatalog, size, sort, sortOrder, filters, search, dispatch, partnerId,
) => {
  loadThirdPartyCatalog(
    dispatch, size, 0, search, { name: sort, order: sortOrder },
    filters, partnerId,
  );
};

export const sortThirdPartyCatalog = (
  newSortBy, oldSortby, sortOrder, thirdPartyCatalogPage, filters, search,
  localDispatch, loadThirdPartyCatalog, dispatch, partnerId, notificationCont,
) => {
  let newSortOrder = sortOrder;
  newSortOrder = newSortBy === oldSortby ? sortOrder === 'asc' ? 'desc' : 'asc' : 'asc';
  localDispatch({
    type: 'setState',
    name: 'sort',
    value: {
      sortBy: newSortBy,
      sortDir: newSortOrder,
    },
  });
  localDispatch({ type: 'setState', name: 'pageCurrent', value: 0 });
  loadThirdPartyCatalog(
    dispatch,
    thirdPartyCatalogPage.size,
    thirdPartyCatalogPage.number,
    search,
    { name: newSortBy, order: newSortOrder },
    filters,
    partnerId,
    notificationCont,
  );
};

export const setState = (localDispatch, name, value) => {
  localDispatch({ type: 'setState', name, value });
};

export const setSearch = (localDispatch, category, term) => {
  localDispatch({ type: 'setSearch', value: { category, term } });
};

export const applySearch = (
  loadThirdPartyCatalog, sort, sortOrder, thirdPartyCatalogPage, filters,
  search, dispatch, partnerId, notificationCont,
) => {
  const {
    productType, version, region, releaseDate, platform,
  } = filters;
  loadThirdPartyCatalog(dispatch, thirdPartyCatalogPage.size,
    thirdPartyCatalogPage.page, search, { name: sort, order: sortOrder },
    {
      productType, version, region, releaseDate, platform,
    }, partnerId, notificationCont);
};

export const handleFilterChange = (e, localDispatch) => {
  const { name, value } = e.target;
  localDispatch({ type: 'setFilter', name, value });
};

export const clearFilters = (localDispatch) => {
  localDispatch({ type: 'clearFilters' });
};

export const applyFilters = (
  loadThirdPartyCatalog, sort, sortOrder, thirdPartyCatalogPage,
  filters, search, dispatch, partnerId, notificationCont,
) => {
  const {
    productType, version, targetRegions, releaseDate, platform,
  } = filters;
  loadThirdPartyCatalog(dispatch, thirdPartyCatalogPage.size,
    thirdPartyCatalogPage.page, search, { name: sort, order: sortOrder },
    {
      productType, version, targetRegions, releaseDate, platform,
    }, partnerId, notificationCont);
};

export const captureSearchParams = (queryParams) => {
  if (queryParams.get('all')) {
    return { category: 'all', term: queryParams.get('all') };
  }
  if (queryParams.get('conceptName')) {
    return { category: 'conceptName', term: queryParams.get('conceptName') };
  }
  if (queryParams.get('productGroupName')) {
    return { category: 'productGroupName', term: queryParams.get('productGroupName') };
  }
  if (queryParams.get('productId')) {
    return { category: 'productId', term: queryParams.get('productId') };
  }
  return {};
};

export const downloadProduct = async (
  voucherCatalogId,
  downloadThirdPartyProduct,
  notificationCont,
  addProductToDownloadQueue,
  removeProductsFromDownloadQueue,
  localDispatch,
  dispatch,
  partnerId,
) => {
  return downloadThirdPartyProduct(
    voucherCatalogId,
    notificationCont,
    addProductToDownloadQueue(localDispatch),
    removeProductsFromDownloadQueue(localDispatch),
    partnerId,
  )(dispatch);
};

export const thirdPartyProductRetry = (
  voucherCatalogId, retryThirdPartyProduct, notificationCont,
  addProductToDownloadQueue, localDispatch, dispatch,
) => {
  return retryThirdPartyProduct(
    voucherCatalogId,
    notificationCont,
    addProductToDownloadQueue(localDispatch),
  )(dispatch);
};

export const getPartnerInSession = () => {
  return parseInt(sessionStorage.getItem('selectedPartner'), 10);
};

export const setPartner = (
  dispatch, e, setThirdPartyCatalogPartner,
  emptyCart,
) => {
  dispatch(setThirdPartyCatalogPartner(e.target.value.value));

  sessionStorage.setItem('selectedPartner', e.target.value.value);

  /* update cart */
  dispatch(emptyCart());
};
