import * as types from '../actions/actionTypes';
import initialState from './initialState';

export function actionTypeEndsInSuccess(type) {
  return type.substring(type.length - 8) === '_SUCCESS' || type.indexOf('FINISH_') !== -1;
}

export default function ajaxStatusReducer(state = initialState.numAjaxCallsInProgress, action) {
  if (action.type === types.BEGIN_AJAX_CALL || action.type.indexOf('BEGIN_') !== -1) {
    return state + 1;
  } if (action.type === types.AJAX_CALL_ERROR
    || actionTypeEndsInSuccess(action.type)) {
    return state - 1;
  }

  return state;
}
