const formatHistoryStatus = (auditEventDetail, status, t) => {
  if (auditEventDetail.targetPartnerGPID) {
    return t('msg_codes_merged_status_gpids', {
      sourcePartnerGPID: auditEventDetail.sourcePartnerGpid,
      targetPartnerGPID: auditEventDetail.targetPartnerGpid,
      interpolation: { escapeValue: false },
    });
  }
  return status;
};

export const formatOrderHistoryComment = (
  t, status, comment, commentVariables,
) => {
  if (status === 'PARTNER_MERGE') {
    const { targetPartnerGPID, sourcePartnerGPID } = commentVariables;
    try {
      return t(comment, {
        sourcePartnerGPID,
        targetPartnerGPID,
        interpolation: { escapeValue: false },
      });
    } catch (error) {
      return 'unable to parse partner gpids';
    }
  }
  return t(comment);
};

export default formatHistoryStatus;
