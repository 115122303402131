import React from 'react';
import { Tag } from '@partner-global-ui/components';
import i18n from 'i18next';
import { displayStatuses } from '../constants/order.constants';


export const orderStatuses = {
  DRAFT_ORDER: 'new',
  CANCELLED_ORDER: 'inactive',
  SUBMITTED_PENDING_APPROVAL: 'in-progress',
  APPROVED_ORDER: 'valid',
  PENDING_DRAFT_AMENDMENT: 'warning',
  REJECTED: 'error',
};

export const getLabel = (orderStatus) => {
  const label = displayStatuses.find(displayLabel => displayLabel.value === orderStatus);
  return typeof label !== 'undefined'
    ? `${i18n.t(label.translationStringId)}`
    : orderStatus;
};

const submissionStatus = (status, label) => {
  const tagStatus = orderStatuses[status];
  return <Tag id={`${status}-tag`} status={tagStatus} label={label} />;
};

export default submissionStatus;
