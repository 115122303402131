import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Layout,
  Flex,
  Button,
} from '@partner-global-ui/components';
import PropTypes from 'prop-types';

function VoucherTypePageHeader({
  disableButton,
  canSaveAndCancel,
  voucherType,
  handleVoucherTypeSave,
}) {
  const { t } = useTranslation();

  return (
    <Layout id="header" className="header">
      <Flex id="header-container" colSpan="6">
        <div className="header-title-container" data-testid="header-container">
          <h3
            id={`${voucherType.id}-header`}
            name={`${voucherType.id}-header`}
            data-testid="title"
            className="title"
          >
            {voucherType.id === '' ? t('msg_codes_add_new_voucher_type') : t('msg_codes_voucher_type_admin')}
          </h3>
        </div>
      </Flex>
      {canSaveAndCancel && (
        <Flex id="buttons-container" className="buttons-container" colSpan="6">
          <span data-testid="buttons-wrapper" className="buttons-wrapper">
            <Link data-testid="cancel-button-link" to="/vouchertypes">
              <Button
                className="cancel-button"
                id="cancel-button"
                secondary
              >
                {t('msg_codes_cta_cancel')}
              </Button>
            </Link>
            <Button
              id="save-button"
              primary
              className="save-button"
              disabled={disableButton}
              onClick={() => handleVoucherTypeSave()}
            >
              {t('msg_codes_cta_save')}
            </Button>
          </span>
        </Flex>
      )}
      {((voucherType.npType === 'MONEY' && voucherType.id !== '') || !canSaveAndCancel) && (
        <Flex id="buttons-container" className="buttons-container" colSpan="6">
          <span data-testid="cancel-button-wrapper" className="cancel-button-wrapper">
            <Link data-testid="cancel-button-link" to="/vouchertypes">
              <Button
                className="cancel-button"
                id="cancel-button"
                secondary
              >
                {t('msg_codes_cta_cancel')}
              </Button>
            </Link>
          </span>
        </Flex>
      )}
    </Layout>
  );
}

VoucherTypePageHeader.propTypes = {
  disableButton: PropTypes.bool,
  canSaveAndCancel: PropTypes.bool,
  voucherType: PropTypes.object,
  handleVoucherTypeSave: PropTypes.func,
};

VoucherTypePageHeader.defaultProps = {
  disableButton: false,
  canSaveAndCancel: false,
  voucherType: {},
  handleVoucherTypeSave: () => null,
};

export default VoucherTypePageHeader;
