import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function loadingSftpsReducer(state = initialState.loadingSftps, action) {
  if (action.type === types.BEGIN_LOAD_SFTPS) {
    return true;
  }
  if (action.type === types.LOAD_SFTP_LIST_SUCCESS) {
    return false;
  }

  return state;
}
