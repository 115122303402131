import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function skusReducer(state = initialState.skus, action) {
  switch (action.type) {
    case types.FINISH_LOAD_SKUS:
      return [...action.skus];
    default:
      return state;
  }
}
