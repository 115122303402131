import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function ordersReducer(state = initialState.orders, action) {
  switch (action.type) {
    case types.FINISH_LOAD_ORDERS:
      return [...action.orders];
    case types.SUBMIT_ORDER_SUCCESS:
      return [
        ...state.filter(order => order.orderNumber !== action.order.orderNumber),
        Object.assign({}, action.order),
      ].sort((a, b) => b.orderNumber - a.orderNumber);
    case types.CANCEL_ORDER_SUCCESS:
      return [
        ...state.filter(order => order.orderNumber !== action.orderNumber),
      ].sort((a, b) => b.orderNumber - a.orderNumber);
    case types.ORDERS_LINES_STATUS:
      return state.map((order) => {
        if (action.orderIds.includes(order.orderNumber)) {
        // repeat for order lines
          return {
            ...order,
            orderLines: order.orderLines.map((orderLine) => {
              // try to find updated order line. returns undefined otherwise
              const orderLineOrUndefined = action.orderLines
                .find(updatedOrderLine => orderLine.orderLineId === updatedOrderLine.orderLineId);
              return typeof orderLineOrUndefined === 'undefined'
                ? orderLine : orderLineOrUndefined;
            }),
          };
        }
        // This isn't the order we care about - keep it as-is
        return order;
      });
    default:
      return state;
  }
}
