/* eslint-disable */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Collapsible,
  Select,
  DateInput,
  Layout,
  Flex,
} from '@partner-global-ui/components';
import { useSelector, useDispatch } from 'react-redux';
import '../Agreements.scss';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as AgreementStates from '../../../constants/agreementState.constants';
import { updateAgreementProp } from '../../../actions/agreementFormActions';
import ThirdPartyContractualCoverageTable from '../thirdPartyPartnerCoverageTables/ThirdPartyContractualCoverageTable';

const FreeAllocationAgreementForm = ({ location, editable }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Global State
  const agreementForm = useSelector(state => state.agreementForm);
  const countries = useSelector(state => state.countries);
  /* eslint-disable no-shadow */
  const state = useSelector(state => state);
  /* eslint-enable no-shadow */

  // Local State
  const [selectedAgreementVersion, setSelectedAgreementVersion] = useState({});
  const [coverages, setCoverages] = useState({});

  // Destructure from global state
  const {
    agreement,
  } = agreementForm;
  const {
    creationDate = '',
  } = agreement;
  const placeholderPartnerName = 'PLACE_HOLDER_PARTNER_NAME';
  const canSeePartnerName = agreement.partner.name !== placeholderPartnerName;
  const partnerOptions = [
    {
      value: agreement.partner.partnerId,
      label: agreement.partner.name,
    }
  ];

  const agreementOptions = [
    {
      value: agreement.agreementType,
      label: t('msg_codes_agreementType_thirdPartyFreeAllocation'),
    }
  ];

  const agreementStatusOptions = [
    {
      value: AgreementStates.ACTIVE,
      label: t('msg_codes_agreementStatus_active'),
    },
    {
      value: AgreementStates.SUSPENDED,
      label: t('msg_codes_agreementStatus_suspended'),
    }
  ]

  const agreementVersionStatusOptions = [
    {
      value: AgreementStates.FINAL,
      label: t('msg_codes_agreementVersionStatus_final'),
    }
  ]

  const getAgreementStatusOption = (status) => {
    return agreementStatusOptions.find((option) => {
      return option.value === status;
    });
  };

  useEffect(() => {
    setCoverages(
      countries
      .map(country => (
        {
          ...country,
          country: country.code,
          settlementCurrency: country.currency.code,
          commissionRate: 0,
        }
      ))
    )
  }, [countries]);

  useEffect(() => {
    setSelectedAgreementVersion(agreementForm.draftAgreementVersion);
  }, [agreementForm]);

  const handleStatus = (e) => {
    const { name, value } = e.target;
    updateAgreementProp(name, value.value)(dispatch);
  };

  const renderCoverageTable = () => {
    if (selectedAgreementVersion && selectedAgreementVersion.voucherProductTypeCoverageSet) {
      return selectedAgreementVersion.voucherProductTypeCoverageSet.map(
        product => (
          <ThirdPartyContractualCoverageTable
            key={product.voucherProductType}
            product={product}
            voucherCoverageSet={agreement.agreementVersions[0].voucherProductTypeCoverageSet}
            coverages={_.orderBy(coverages, 'name')}
            canEdit={editable}
          />
        ),
      );
    }
    return null;
  };

  return (
    <>
      <Layout>
        <Flex colSpan="12">
          <Collapsible
            heading={t('msg_codes_agreements_agreementDetails')}
            data-testid="third-party-agreement-form"
            rightContent={`${t('msg_codes_agreements_dateCreated')} ${moment(creationDate).format('MM/DD/YYYY')}`}
            defaultIsOpen
          >
            <Layout>
                <Flex colSpan="3" className="content-details">
                  {canSeePartnerName && (
                    <Select
                      name="partnerId"
                      id="partnerId"
                      value={partnerOptions[0]}
                      options={partnerOptions}
                      label={t('msg_codes_agreements_contractingPartner')}
                      disabled
                      required
                    />
                  )}
                  <Select
                    name="agreementType"
                    id="agreementType"
                    value={agreementOptions[0]}
                    options={agreementOptions}
                    label={t('msg_codes_agreements_agreementType_label')}
                    disabled
                    required
                  />
                  <Select
                    name="status"
                    id="status"
                    value={getAgreementStatusOption(agreement.status)}
                    options={agreementStatusOptions}
                    label={t('msg_codes_agreements_agreementStatus_label')}
                    disabled={!editable}
                    required
                    onChange={handleStatus}
                  />
                  <Select
                    name="versionStatus"
                    id="versionStatus"
                    value={agreementVersionStatusOptions[0]}
                    options={agreementVersionStatusOptions}
                    label={t('msg_codes_agreements_agreementVersionStatus')}
                    disabled
                    required
                  />
                  <DateInput
                    label={t('msg_codes_agreements_versionEffectiveDate')}
                    name="agreementVersionEffectiveDate"
                    value={moment(selectedAgreementVersion.agreementVersionEffectiveDate).utc().format('MM/DD/YYYY')}
                    disabled
                  />
                </Flex>
              </Layout>
          </Collapsible>
        </Flex>
      </Layout>
      <Layout>
        <Flex colSpan="12">
          {renderCoverageTable()}
        </Flex>
      </Layout>
    </>

  );
};

FreeAllocationAgreementForm.propTypes = {
  location: PropTypes.object,
  editable: PropTypes.bool,
};

FreeAllocationAgreementForm.defaultProps = {
  location: {},
  editable: false,
};

export default FreeAllocationAgreementForm;
