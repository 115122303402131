import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function loadingCodeProducts(
  state = initialState.loadingCodeProducts,
  action,
) {
  switch (action.type) {
    case types.BEGIN_LOAD_CODE_PRODUCTS:
      return true;
    case types.LOAD_CODE_PRODUCTS_SUCCESS:
      return false;
    default:
      return state;
  }
}
