import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function rejectingOrderReducer(state = initialState.rejectingOrder, action) {
  if (action.type === types.BEGIN_REJECT_ORDER) {
    return true;
  } if (action.type === types.REJECT_ORDER_SUCCESS) {
    return false;
  }

  return state;
}
