import React from 'react';
import {
  Container,
  Layout,
  Flex,
} from '@partner-global-ui/components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function Nav({ order: { orderNumber } }) {
  const { t } = useTranslation();

  return (
    <div data-testid="order-header" className="order-header">
      <Container id="order-header-container" className="order-container">
        <Layout id="order-container-header-layout">
          <Flex id="order-container-header-flex" colSpan="6" className="order-title">
            <h3 data-testid="order-number">{t('msg_codes_ordernum')}: {orderNumber}</h3>
          </Flex>
        </Layout>
      </Container>
    </div>
  );
}

Nav.propTypes = {
  order: PropTypes.object,
};

Nav.defaultProps = {
  order: {},
};

export default Nav;
