import { combineReducers } from 'redux';
import voucher from './thirdPartyCatalogReducer';
import vouchers from './catalogReducer';
import thirdPartyCatalogPage from './thirdPartyCatalogPageReducer';
import loadingThirdPartyCatalog from './loadingThirdPartyCatalogReducer';
import loadingVouchers from './loadingVouchersReducer';
import orders from './ordersReducer';
import order from './orderReducer';
import initialOrder from './initialOrderReducer';
import numAjaxCallsInProgress from './ajaxStatusReducer';
import loadingOrders from './loadingOrdersReducer';
import loading from './loadingReducer';
import savingOrder from './savingOrderReducer';
import submittingOrder from './submittingOrderReducer';
import approvingOrder from './approvingOrderReducer';
import rejectingOrder from './rejectingOrderReducer';
import requestingAmendment from './requestingAmendmentReducer';
import partners from './partnersReducer';
import partner from './partnerReducer';
import loadingPartner from './loadingPartnerReducer';
import agreements from './agreementsReducer';
import agreementForm from './agreementFormReducer';
import user from './userReducer';
import loadingOrder from './loadingOrderReducer';
import tracking from './trackingReducer';
import app from './appReducer';
import appConfig from './appConfigReducer';
import currencies from './currencyReducer';
import countries from './countryReducer';
import userList from './userListReducer';
import usersPage from './usersPageReducer';
import userPage from './userPageReducer';
import sftpsPage from './sftpPageReducer';
import loadingUsers from './loadingUsersReducer';
import loadingAgreements from './loadingAgreementsReducer';
import agreementsPage from './agreementsPageReducer';
import partnersPage from './partnersPageReducer';
import loadUserReducer from './loadUserReducer';
import loadingSftps from './loadingSftpsReducer';
import sftpList from './sftpListReducer';
import removedOrderLines from './removedOrderLinesReducer';
import orderForm from './orderFormReducer';
import voucherTypes from './voucherProductReducer';
import pgpKey from './pgpReducer';
import callbackGateways from './callbackGatewaysReducer';
import voucherTypeCatalogs from './voucherTypesReducer';
import loadingVoucherTypes from './loadingVoucherTypesReducer';
import voucherTypesPage from './voucherTypesPageReducer';
import voucherType from './voucherTypeReducer';
import loadingVoucherType from './loadingVoucherTypeReducer';
import loadingSkus from './loadingSkusReducer';
import skus from './skusReducer';
import addSkuPage from './addSkuPageReducer';
import selectedSkus from './selectedSkusReducer';
import codeProduct from './codeProductReducer';
import codeProductsCatalog from './codeProductCatalogReducer';
import loadingCodeProducts from './loadingCodeProductsReducer';
import codeProductsPage from './codeProductsPageReducer';
import voucherBatchCatalogs from './voucherBatchesReducer';
import loadingVoucherBatches from './loadingVoucherBatchesReducer';
import loadingSelectVoucherBatches from './loadingSelectVoucherBatchesReducer';
import voucherBatchesPage from './voucherBatchesPageReducer';
import loadingVoucherBatch from './loadingVoucherBatchReducer';
import voucherBatch from './voucherBatchReducer';
import voucherTypeBatchPage from './voucherTypeBatchPageReducer';
import voucherBatchAuditHistory from './voucherBatcheAuditHistoryReducer';
import orderActivityRules from './orderActivityRulesReducer';
import orderHistory from './orderHistoryReducer';
import cartPage from './cartPageReducer';
import cronJobs from './venomCronJobsReducer';

const rootReducer = combineReducers({
  voucher,
  vouchers,
  thirdPartyCatalogPage,
  loadingThirdPartyCatalog,
  loadingVouchers,
  orders,
  app,
  numAjaxCallsInProgress,
  loadingOrders,
  loadingOrder,
  savingOrder,
  submittingOrder,
  approvingOrder,
  rejectingOrder,
  requestingAmendment,
  partners,
  partner,
  loadingPartner,
  agreements,
  agreementForm,
  order,
  initialOrder,
  tracking,
  user,
  usersPage,
  sftpsPage,
  currencies,
  countries,
  userList,
  userPage,
  loadingUsers,
  loadingAgreements,
  agreementsPage,
  partnersPage,
  loadUserReducer,
  loadingSftps,
  sftpList,
  removedOrderLines,
  orderForm,
  voucherTypes,
  pgpKey,
  callbackGateways,
  voucherTypeCatalogs,
  loadingVoucherTypes,
  voucherTypesPage,
  loadingVoucherType,
  voucherType,
  loadingSkus,
  skus,
  addSkuPage,
  selectedSkus,
  codeProduct,
  codeProductsCatalog,
  loadingCodeProducts,
  codeProductsPage,
  voucherBatchCatalogs,
  loadingVoucherBatches,
  loadingSelectVoucherBatches,
  voucherBatchesPage,
  loadingVoucherBatch,
  voucherBatch,
  voucherTypeBatchPage,
  voucherBatchAuditHistory,
  orderActivityRules,
  orderHistory,
  cartPage,
  loading,
  cronJobs,
  appConfig,
});

export default rootReducer;
