import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function loadingOrdersReducer(
  state = initialState.loadingThirdPartyCatalog, action,
) {
  if (action.type === types.BEGIN_LOAD_THIRD_PARTY_CATALOG) {
    return true;
  } if (action.type === types.FINISH_LOAD_THIRD_PARTY_CATALOG) {
    return false;
  }

  return state;
}
