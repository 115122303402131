import * as UserRoles from '../constants/userRoles.constants';

export default function (user) {
  if (typeof user.userRoles !== 'undefined') {
    return user.userRoles
      .some(role => role.role.roleCode === UserRoles.VouchersPartnerRequester.roleCode);
  }

  return false;
}
