import il8n from 'i18next';
import { autoHide, timeout } from '../../../constants/notifications.constant';

const orderHistoryNotifications = (orderStatus, notificationContext) => {
  if (orderStatus === 'DOWNLOAD' || orderStatus === 'REDOWNLOAD') {
    notificationContext.dispatch({
      type: 'add',
      payload: {
        status: 'success',
        autoHide,
        timeout,
        message: il8n.t('msg_codes_codes_banner_codesReady'),
        testId: 'error',
      },
    });
  }

  if (orderStatus === 'ERROR') {
    notificationContext.dispatch({
      type: 'add',
      payload: {
        status: 'error',
        autoHide,
        timeout,
        message: il8n.t('msg_codes_codes_banner_somethingWentWrong'),
        testId: 'error',
      },
    });
  }
};

export default orderHistoryNotifications;
