import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function loadingOrdersReducer(state = initialState.loadingOrders, action) {
  if (action.type === types.BEGIN_LOAD_ORDERS) {
    return true;
  } if (action.type === types.FINISH_LOAD_ORDERS) {
    return false;
  }

  return state;
}
