import axios from 'axios';

export const validateGPID = (source, target) => {
  return (() => {
    return axios.post('/venom/api/validatePartnerMerge', {
      fromGpid: source,
      toGpid: target,
    })
      .then(response => response.data)
      .then(() => {
        return {
          validated: true,
        };
      })
      .catch((error) => {
        const [sourceErrorMessage, targetErrorMessage] = error.response.data.detailMessages;
        return {
          sourceErrorMessage: sourceErrorMessage.replace('partnerMergeRequest: ', ''),
          targetErrorMessage: targetErrorMessage.replace('partnerMergeRequest: ', ''),
        };
      });
  });
};

export const mergePartner = (source, target) => {
  return (() => {
    return axios.post('/venom/api/performPartnerMerge', {
      fromGpid: source,
      toGpid: target,
    })
      .then(response => response.data)
      .then(() => {
        return {
          newGPID: target,
        };
      })
      .catch((error) => {
        const serviceErrorMessage = error.response.data.detailMessages[0];
        return { serviceErrorMessage };
      });
  });
};
