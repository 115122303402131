import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  TableRow,
  TableCell,
  Tag,
} from '@partner-global-ui/components';
import {
  StatusStringMapping,
  partnerTagStatuses,
} from '../../constants/partnerState.constants';


const Partner = ({
  partner: {
    partnerId,
    name,
    numberOfUsers,
    numberOfAgreements,
    creationDate,
    status,
  },
  rowIndex,
}) => {
  const { t } = useTranslation();

  const displayStatus = (
    <Tag
      id={`status-tag-${partnerId}`}
      label={t(StatusStringMapping[status])}
      status={partnerTagStatuses[status]}
    />
  );

  // Render
  return (
    <TableRow id={rowIndex}>
      <TableCell id="partner-id" className="partner-id first-column">
        <Link data-testid="partner-link" to={`/partner/${partnerId}`}>
          {name}
        </Link>
      </TableCell>
      <TableCell id="number-of-users" className="number-of-users">
        {numberOfUsers}
      </TableCell>
      <TableCell id="number-of-agreements" className="number-of-agreements">
        {numberOfAgreements}
      </TableCell>
      <TableCell id="creation-date" className="creation-date">
        {creationDate}
      </TableCell>
      <TableCell id="status" className="status">
        {displayStatus}
      </TableCell>
    </TableRow>
  );
};

Partner.propTypes = {
  partner: PropTypes.object,
  rowIndex: PropTypes.number,
};

Partner.defaultProps = {
  partner: {},
  rowIndex: undefined,
};

export default Partner;
