
import React, {
  useState, useEffect, useCallback, useReducer,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Container,
  DataTable,
  ActionBar,
  Pagination,
  RowDisplay,
  TableHeader,
  TableHeaderCell,
  NoResults,
  Button,
} from '@partner-global-ui/components';
import { sortBy } from 'lodash';

import RowDisplayLocalization from '../../helpers/RowDisplayLocalization';
import { filterCounter } from '../../helpers/filterMethods';
import hasPermission from '../../utils/accessControl/hasPermission';
import roles from '../../utils/accessControl/roleKeys';
import * as voucherTypeActions from '../../actions/voucherTypeActions';
import * as filterOptions from '../../constants/filterOptions.constants';
import FiltersContainer from '../common/Filters/FiltersContainer';
import Nav from './Nav';
import VoucherType from './VoucherType';
import './VoucherTypes.scss';


export const defaultFilters = {
  npType: '',
  npRegion: [],
};

export const initialState = {
  filters: defaultFilters,
  filtered: false,
  filterCount: 0,
  pageSize: 10,
};

export const componentActions = {
  setFilter: 1,
  clearFilters: 2,
};

export function localStateReducer(
  state,
  {
    type = '',
    name,
    value,
    filters = defaultFilters,
  },
) {
  const filteredState = filters && (
    filters.npType.length > 0
    || filters.npRegion.length > 0
  );

  let newState;
  switch (type) {
    case componentActions.setFilter:
      newState = {
        ...state,
        filters: { ...state.filters, [name]: value },
        filtered: !filteredState,
      };
      return { ...newState, filterCount: filterCounter(newState.filters) };
    case componentActions.clearFilters:
      return {
        ...state,
        filters: defaultFilters,
        filtered: false,
        filterCount: 0,
      };
    default:
      return state;
  }
}

export const VoucherTypesPage = ({ ...props }) => {
  // Setup
  const { history } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const updatedInitialState = initialState;
  // Setup useReducers
  const [localState, localDispatch] = useReducer(localStateReducer, updatedInitialState);
  // Setup UseSelectors
  const { userId } = useSelector(state => state.user);
  const loadingVoucherTypes = useSelector(state => state.loadingVoucherTypes);
  const search = useSelector(state => state.voucherTypesPage.search);
  const voucherTypesPage = useSelector(state => state.voucherTypesPage.page);
  const voucherTypes = useSelector(state => state.voucherTypeCatalogs);

  const canViewVoucherTypes = useSelector(
    state => hasPermission(state, roles.voucherTypes.viewList),
  );
  const canCreateVoucherType = useSelector(
    state => hasPermission(state, roles.voucherTypes.create),
  );

  // Setup State
  const [sort, setSort] = useState('modifyDate');
  const [sortOrder, setSortOrder] = useState('desc');
  const [pageNumber, setPageNumber] = useState(1);
  // Setup Local Storage
  const initialPageSize = localStorage.getItem(`voucherTypesPageSize-${userId}`) !== null
    ? Number(localStorage.getItem(`voucherTypesPageSize-${userId}`))
    : 50;
  const [pageSize, setPageSize] = useState(initialPageSize);
  const { filters, filterCount } = localState;

  const countries = filterOptions.voucherTypesRegionOptions;
  const vouchers = filterOptions.voucherTypesOptions;


  useEffect(() => {
    return () => {
      dispatch(voucherTypeActions.resetVoucherTypesPage());
    };
  }, []);

  useEffect(() => {
    voucherTypeActions.loadVoucherTypes(
      pageSize,
      pageNumber - 1,
      { name: sort, order: sortOrder },
      {
        ...filters,
        search,
      },
    )(dispatch);
  }, [pageSize, pageNumber, sort, sortOrder, search]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    localDispatch({
      type: componentActions.setFilter,
      name,
      value: name === 'npType' && value.label === t('msg_codes_filter_all') ? '' : value,
    });
  };

  const typesOptions = filterOptions.optionsTranslationCreator(vouchers, t);
  const createFilters = useCallback((localCountries) => {
    const {
      npType,
      npRegion,
    } = filters;

    return [
      {
        label: t('msg_codes_voucherProductType'),
        name: 'npType',
        placeholder: t('msg_codes_filter_all'),
        multiple: false,
        value: npType.length === 0 ? typesOptions[0] : npType,
        options: typesOptions,
        handleChange: handleFilterChange,
      },

      {
        label: t('msg_codes_orderList_countryHeader'),
        name: 'npRegion',
        placeholder: t('msg_codes_filter_all'),
        value: npRegion,
        multiple: true,
        options: sortBy(localCountries, ['label']),
        handleChange: handleFilterChange,
      },
    ];
  });

  const clearFilters = () => {
    localDispatch({ type: componentActions.clearFilters });
    dispatch(voucherTypeActions.resetFilters());
  };

  const changePage = (newPageNumber) => {
    setPageNumber(newPageNumber);
  };

  const changeRowAmount = (newPageSize) => {
    localStorage.setItem(`voucherTypesPageSize-${userId}`, newPageSize);
    setPageSize(newPageSize);
    setPageNumber(1);
  };

  const newVoucherType = () => {
    history.push('/vouchertype/create');
  };

  const createNewVoucherType = () => {
    return (
      <Button
        id="voucher-types-new-voucher-type"
        data-testid="voucher-types-new-voucher-type"
        onClick={newVoucherType}
        primary
        type="button"
        icon="ico-action-add"
      >
        {t('msg_codes_add_new_voucher_type')}
      </Button>
    );
  };

  const actionBar = (localCountries) => {
    const handleApplyFilters = () => {
      voucherTypeActions.loadVoucherTypes(
        pageSize,
        pageNumber - 1,
        { name: sort, order: sortOrder },
        {
          ...filters,
          search,
        },
      )(dispatch);
      setPageNumber(1);
    };

    return (
      <ActionBar
        filters={(
          <FiltersContainer
            filters={createFilters(localCountries)}
            name="voucher-types-filters"
          />
)}
        right={canCreateVoucherType && createNewVoucherType()}
        filterCount={filterCount}
        onFilterClear={clearFilters}
        onFilterApply={handleApplyFilters}
        filterClearLabel={t('msg_codes_cta_clear')}
        filterPrimaryLabel={t('msg_codes_cta_apply')}
        filterSecondaryLabel={t('msg_codes_cta_cancel')}
        filterToolTipText={t('msg_codes_filter')}
        filterTitle={t('msg_codes_filter')}
        id="voucher-types-action-bar"
      />
    );
  };


  const sortVoucherTypes = (e, name) => {
    if (e) {
      if (typeof e.preventDefault === 'function') {
        e.preventDefault();
      }
      // eslint-disable-next-line no-nested-ternary
      const newSortOrder = name === sort ? sortOrder === 'asc' ? 'desc' : 'asc' : 'asc';
      setSort(name);
      setSortOrder(newSortOrder);
    }
  };

  // labels for table headers
  const tableHeaders = [
    { label: t('msg_codes_voucher_type_name'), value: 'name' },
    { label: t('msg_codes_voucherProductType'), value: 'npTypeCode' },
    { label: t('msg_codes_NP_region'), value: 'npRegion' },
    { label: t('msg_codes_voucher_type_id'), value: 'npVoucherTypeId' },
    { label: t('msg_codes_last_updated'), value: 'modifyDate' },
  ];

  const renderHeaderCells = () => {
    return tableHeaders.map(({ label, value }, headerCellIndex) => {
      const headerKey = headerCellIndex + 1;

      return (
        <TableHeaderCell
          sortable={value}
          onClick={e => sortVoucherTypes(e, value)}
          sort={{ sortBy: sort, sortDir: sortOrder }}
          id={`voucherTypes-${headerKey}`}
          key={headerKey}
          value={value}
          className={value ? `${value}` : 'remove'}
        >
          {label}
        </TableHeaderCell>
      );
    });
  };

  const renderTableRows = () => {
    return voucherTypes.length > 0 ? (
      voucherTypes.map((voucherType) => {
        return (
          <VoucherType
            key={voucherType.id}
            history={history}
            voucherType={voucherType}
            name="voucherTypes"
          />
        );
      })
    ) : (
      <NoResults
        title={t('msg_codes_codes_noResultsFound')}
        message={t('msg_codes_pucl_search_again')}
      />
    );
  };

  // Renders both the HEADERS and the ROWS
  const renderDataTable = () => {
    return (
      <DataTable
        columnTemplate="repeat(5, 1fr)"
        scrollHeight={400}
        actionBar={actionBar(countries)}
        id="voucher-types-table"
        loading={loadingVoucherTypes}
      >
        <TableHeader>
          {renderHeaderCells()}
        </TableHeader>
        {renderTableRows()}
      </DataTable>
    );
  };


  const renderPagination = () => {
    const currentPage = voucherTypesPage.number ? voucherTypesPage.number : 1;
    return (
      <>
        {voucherTypesPage.totalElements > 10 && (
          <div className="voucherTypes-paginator">
            <RowDisplay
              currentPage={voucherTypesPage.number}
              totalItems={voucherTypesPage.totalElements}
              pageSizes={[10, 25, 50, 100]}
              onPageSizeChange={changeRowAmount}
              initialPageSize={pageSize}
              showingOfText={RowDisplayLocalization(
                'msg_codes_pagination_showing',
              )(t)}
              id="voucherTypes-rows"
            />
            {voucherTypesPage.totalElements > pageSize && (
              <Pagination
                totalRecords={voucherTypesPage.totalElements}
                currentPage={currentPage}
                pageLimit={pageSize}
                onPageItemClick={changePage}
                id="voucherTypes-pagination"
              />
            )}
          </div>
        )}
      </>
    );
  };
  //

  if (!canViewVoucherTypes) {
    return null;
  }

  return (
    <Container className="voucher-types voucher-types-list">
      {/* page TITLE and SEARCH functionality */}
      <Nav setPageNumber={setPageNumber} />
      {/* Renders the TABLE HEADERS and TABLE DATA */}
      {renderDataTable()}
      {/* Renders both the ROW DISPLAY and the PAGINATION  */}
      {renderPagination()}
    </Container>
  );
};

export default VoucherTypesPage;
