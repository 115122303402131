import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';
import {
  Modal,
  ModalContext,
  MODAL_ACTIONS,
  ModalContainer,
} from '@partner-global-ui/components';

const OnNavigateAway = ({
  isBlocked, history, location, message, onNavigateFunction,
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const warningModalContext = useContext(ModalContext);
  const [lastLocation, setLastLocation] = useState(location);
  const [shouldUnload, setShouldUnload] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const closeModal = () => {
    setIsModalOpen(false);
    setShouldUnload(false);
  };

  const hideWarningModalCancel = () => {
    closeModal();
    warningModalContext.dispatch({
      type: MODAL_ACTIONS.HIDE,
    });
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const showModal = (nextLocation) => {
    openModal();
    setLastLocation(nextLocation);
  };

  const handleBlockedRoute = (nextLocation) => {
    if (!confirmedNavigation && isBlocked) {
      showModal(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    hideWarningModalCancel();
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      setShouldUnload(true);
      onNavigateFunction(lastLocation);
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, history]);

  const warningModalProps = {
    title: t('msg_codes_orderList_warning'),
    content: t(message),
    primaryLabel: t('msg_codes_common_yes'),
    onPrimary: handleConfirmNavigationClick,
    onClose: hideWarningModalCancel,
  };
  useEffect(() => {
    if (isModalOpen) {
      warningModalContext.dispatch({
        type: MODAL_ACTIONS.SHOW,
        payload: <Modal {...warningModalProps} />,
      });
    }
  }, [isModalOpen]);
  // TODO: uncomment if alert on reload with react-router prompt
  useEffect(() => {
    const unload = (event) => {
      if (isBlocked && !shouldUnload) {
        event.returnValue = t(message);
      }
      if (shouldUnload) {
        onNavigateFunction();
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', unload);

    return () => window.removeEventListener('beforeunload', unload);
  }, [isBlocked, message, shouldUnload]);

  return (
    <ModalContainer backdrop={false} onClose={closeModal}>
      <Prompt when message={handleBlockedRoute} />
    </ModalContainer>
  );
};

OnNavigateAway.propTypes = {
  isBlocked: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  message: PropTypes.object,
  onNavigateFunction: PropTypes.func,
};

OnNavigateAway.defaultProps = {
  message: 'msg_codes_modal_unsavedChanges_body',
  onNavigateFunction: () => {},
};

export default OnNavigateAway;
