import React, { useEffect, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationContext } from '@partner-global-ui/components';

import JobsTable from './cronJobsTable/JobsTable';
import { loadVenomJobs, changeVenomJobStatus } from '../../actions/venomJobsActions';

const VenomJobsTable = () => {
  // Setup
  const title = 'Venom Jobs Table';
  const dispatch = useDispatch();
  const notificationContext = useContext(NotificationContext);
  const { venomCronJobs, loadingVenomCronJobs } = useSelector(state => state.cronJobs);
  const [currentSort, setCurrentSort] = useState({
    sortBy: 'jobCode', sortDir: 'asc',
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    dispatch(loadVenomJobs());
  }, []);

  const searchJobs = (term) => {
    setSearchTerm(term);
    dispatch(loadVenomJobs(currentSort, term));
  };

  const setJobStatus = (id, status, revision) => {
    setIsOpen(true);
    dispatch(changeVenomJobStatus(id, status, revision, notificationContext));
  };

  const sortJobs = (sortBy) => {
    const sortDir = currentSort.sortDir === 'asc' ? 'desc' : 'asc';
    setCurrentSort({
      sortBy, sortDir,
    });
    dispatch(loadVenomJobs({ sortBy, sortDir }, searchTerm));
  };

  return (
    <JobsTable
      loading={loadingVenomCronJobs}
      title={title}
      jobs={venomCronJobs}
      setJobStatus={setJobStatus}
      currentSort={currentSort}
      sortJobs={sortJobs}
      searchTerm={searchTerm}
      searchJobs={searchJobs}
      isOpen={isOpen}
    />
  );
};

export default VenomJobsTable;
