import React, {
  useState,
  useRef,
  useEffect,
  useContext,
} from 'react';
import {
  Icon,
  Modal,
  ModalContext,
  MODAL_ACTIONS,
  TableCell,
  TableRow,
  Truncate,
} from '@partner-global-ui/components';
import { Link } from 'react-router-dom';
import moment from 'moment';
import find from 'lodash/find';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import OrderSelector from './OrderSelector';
import CommentTooltip from '../common/CommentTooltipNewUX';
import * as Regions from '../../constants/contractingSieRegion.constants';
import * as AgreementTypes from '../../constants/agreementType.constants';
import submissionStatus, { getLabel } from '../../utils/renderSubmissionStatus';
import isInactiveOrder, { getWarningMessage } from '../../utils/isInactiveOrder';

const isApproved = (order = { orderStatus: '' }) => {
  return order.orderStatus === 'APPROVED_ORDER';
};

const isDelivered = (line) => {
  return line.sftpVoucherDeliveryStatus === 'DELIVERED'
    || line.userVoucherDeliveryStatus === 'DELIVERED';
};

const getRowClass = (order) => {
  if (isInactiveOrder(order)) {
    return 'disabled';
  }
  return '';
};

const Order = ({
  order,
  order: {
    amendmentComments,
    agreementStatus,
    orderNumber,
    orderStatus,
  },
  navigate,
  showAgreementColumn,
}) => {
  const [isNonActiveAgreement, setIsNonActiveAgreement] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const commentRef = useRef(null);
  const { t } = useTranslation();
  const modalContext = useContext(ModalContext);

  const isAmendmentRequested = orderStatus === 'PENDING_DRAFT_AMENDMENT';
  const isPendingApproval = orderStatus === 'SUBMITTED_PENDING_APPROVAL';
  const isRejected = orderStatus === 'REJECTED';
  const isInactive = orderStatus === 'INACTIVE';
  const isExpandable = order.orderLines.length;

  const toggleExpandState = () => {
    if (isExpandable) {
      setIsExpanded(!isExpanded);
    }
  };

  const handleClick = (e) => {
    if (isInactiveOrder(order)) {
      e.preventDefault();
    }
  };

  // Modal Functions
  const showNonActiveAgreementModal = () => {
    if (isInactive) {
      modalContext.dispatch({
        type: MODAL_ACTIONS.SHOW,
      });
      setIsNonActiveAgreement(true);
    }
  };

  const hideNonActiveAgreementModal = () => {
    if (isNonActiveAgreement) {
      modalContext.dispatch({
        type: MODAL_ACTIONS.HIDE,
      });
      setIsNonActiveAgreement(false);
    }
  };
    // Modal Content
  const messageBody = (
    <div className="modal-body-content">
      {getWarningMessage(order)}
    </div>
  );

  // Modal Props
  const modalProps = {
    id: 'non-active-agreement-modal',
    name: `${agreementStatus}-Modal`,
    title: t('msg_codes_orderList_warning'),
    content: messageBody,
    primaryLabel: t('msgid_codes_orderList_OK'),
    secondaryLabel: t('msg_codes_cta_cancel'),
    onPrimary: showNonActiveAgreementModal,
    onSecondary: hideNonActiveAgreementModal,
    onClose: hideNonActiveAgreementModal,
    hideCancelButton: true,
  };

  useEffect(() => {
    if (!isNonActiveAgreement && isInactive) {
      modalContext.dispatch({
        type: MODAL_ACTIONS.SHOW,
        payload: <Modal {...modalProps} />,
      });
    }
  }, [isInactive, isNonActiveAgreement]);

  const handleLinkEnter = (e) => {
    if (e.key === 'Enter') {
      navigate(`/order/${orderNumber}`);
    }
  };

  const handleNavigate = () => {
    navigate(`/order/${orderNumber}`);
  };

  // TODO: Tooltip logic in getComment and renderCommentOverLay fn
  // will need to implemented in common component for future 3P ordering flow
  const getComment = () => {
    return (
      <CommentTooltip
        order={order}
        commentRef={commentRef}
      />
    );
  };

  const roleName = find(Regions, r => r.code === order.region).name;
  const arrowDown = 'ico-download';
  return showAgreementColumn ? (
    <TableRow
      name="orderRow"
      className={getRowClass(order)}
      id="orderListTableRow"
      pad
      onClick={toggleExpandState}
      expanded={isExpanded}
      expandable={order.orderLines.length}
      expandedContent={(
        <OrderSelector order={order} isApproved={isApproved} />
      )}
    >
      <TableCell id="orderName">
        {
          isInactive
            ? (
              <span
                data-testid="inactive-link"
                className="inactiveOrder-link"
                onClick={() => {
                  setIsNonActiveAgreement(true);
                }}
                onKeyDown={() => { }}
                role="presentation"
                name="inactiveOrder"
              >
                {order.orderReference}
              </span>
            )
            : (
              <span
                data-testid="active-link"
                className="activeOrder-link"
                onClick={handleNavigate}
                name="orderLink"
                role="link"
                onKeyDown={handleLinkEnter}
                tabIndex="0"
              >
                <Link
                  to={`/order/${orderNumber}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={handleClick}
                >
                  <Truncate
                    id="orderReference-truncate"
                    tooltipContent={order.orderReference}
                    height={20}
                  >
                    {order.orderReference}
                  </Truncate>
                </Link>
              </span>
            )
        }
      </TableCell>
      <TableCell
        className={`${getRowClass(order)} ${'partnerName'}`}
        name="partnerName"
        id="partnerName"
      >
        <Truncate
          id="partnerName-truncate"
          tooltipContent={order.orderReference}
        >
          {order.partnerName}
        </Truncate>
      </TableCell>
      <TableCell className={getRowClass(order)} id="orderNumber">{order.orderNumber}</TableCell>
      <TableCell className={getRowClass(order)} id="roleName">{roleName}</TableCell>
      <TableCell className={`${getRowClass(order)} agreementType`} id="agreementType" name="agreementType">{t(AgreementTypes[order.agreementType])}</TableCell>
      <TableCell className={getRowClass(order)} id="creationDate">{moment(order.creationDate).utc().format('MM/DD/YYYY')}</TableCell>
      <TableCell className={getRowClass(order)} name="status" id="status">
        {submissionStatus(orderStatus, getLabel(orderStatus))}
        {isRejected || isAmendmentRequested || (isPendingApproval && typeof amendmentComments === 'string') ? getComment() : null}
        {order.orderLines.filter(line => isDelivered(line)).length > 0
          ? <Icon id="arrow-down" size={12}>{arrowDown}</Icon> : <span />}
      </TableCell>
    </TableRow>
  )
  // forced to duplicate component because any form of conditional
  // rendering gets counted when determining number of columns
    : (
      <TableRow
        name="orderRow"
        className={getRowClass(order)}
        id="orderListTableRow"
        pad
        onClick={toggleExpandState}
        expanded={isExpanded}
        expandable={order.orderLines.length}
        expandedContent={(
          <OrderSelector order={order} isApproved={isApproved} />
        )}
      >
        <TableCell id="orderName">
          {
            isInactive
              ? (
                <span
                  data-testid="inactive-link"
                  className="inactiveOrder-link"
                  onClick={() => {
                    setIsNonActiveAgreement(true);
                  }}
                  onKeyDown={() => { }}
                  role="presentation"
                  name="inactiveOrder"
                >
                  {order.orderReference}
                </span>
              )
              : (
                <span
                  data-testid="active-link"
                  className="activeOrder-link"
                  onClick={handleNavigate}
                  name="orderLink"
                  role="link"
                  onKeyDown={handleLinkEnter}
                  tabIndex="0"
                >
                  <Link
                    to={`/order/${orderNumber}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleClick}
                  >
                    <Truncate
                      id="orderReference-truncate"
                      tooltipContent={order.orderReference}
                      height={20}
                    >
                      {order.orderReference}
                    </Truncate>
                  </Link>
                </span>
              )
          }
        </TableCell>
        <TableCell name="partnerName" id="partnerName">{order.partnerName}</TableCell>
        <TableCell id="orderNumber">{order.orderNumber}</TableCell>
        <TableCell id="roleName">{roleName}</TableCell>
        <TableCell id="creationDate">{moment(order.creationDate).utc().format('MM/DD/YYYY')}</TableCell>
        <TableCell name="status" id="status">
          {submissionStatus(orderStatus, getLabel(orderStatus))}
          {isRejected || isAmendmentRequested || (isPendingApproval && typeof amendmentComments === 'string') ? getComment() : null}
          {order.orderLines.filter(line => isDelivered(line)).length > 0
            ? <Icon id="arrow-down" size={12}>{arrowDown}</Icon> : <span />}
        </TableCell>
      </TableRow>
    );
};

Order.propTypes = {
  order: PropTypes.object,
  navigate: PropTypes.func,
  showAgreementColumn: PropTypes.bool,
};

Order.defaultProps = {
  order: {},
  navigate: () => {},
  showAgreementColumn: true,
};

export default Order;
