import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Layout,
  Flex,
  Button,
} from '@partner-global-ui/components';
import { useTranslation } from 'react-i18next';


const SkuCountryAvailability = (props) => {
  const { t } = useTranslation();
  const { skus } = props;
  const [message, setMessage] = useState('');
  const noSkus = !skus || skus.length === 0;

  const handleButtonClick = () => {
    const countriesArray = skus.map((sku) => {
      const { skuAvailabilities } = sku;
      return skuAvailabilities.map(skuAvailability => skuAvailability.country.code);
    });
    let difference = [];
    countriesArray.forEach((value1, index1) => {
      countriesArray.forEach((value2, index2) => {
        if (index1 !== index2) {
          difference = _.union(difference, _.difference(value1, value2));
        }
      });
    });
    const newMessage = difference.length === 0
      ? `${t('msg_codes_skus_valid_country_region')} ${countriesArray[0].join(', ')}`
      : `${t('msg_codes_skus_not_valid_country_region')} ${difference.sort().join(', ')}`;
    setMessage(newMessage);
  };

  return (
    <>
      <hr data-testid="divider" />
      <Layout
        className="sku-country-availability-container"
        id="sku-country-availability-container"
      >
        <Flex
          className="sku-country-availability-flex"
          id="sku-country-availability-flex"
        >
          <div
            className="sku-country-availability-header"
            data-testid="sku-country-availability-header"
          >
            {t('msg_codes_countries_regions')}
          </div>
          {noSkus && (
            <div
              className="sku-country-availability-description"
              data-testid="sku-country-availability-description"
            >
              {t('Currently there are no SKUs associated with this batch.')}
            </div>
          )}
          {!noSkus && (
            <>
              <div
                className="sku-country-availability-description"
                data-testid="sku-country-availability-description-no-sku"
              >
                {t('msg_codes_link_countries_regions_available_skus')}
              </div>
              <div
                className="check-button-container"
                data-testid="check-button-container"
              >
                <Button
                  className="check-button"
                  id="check-button"
                  onClick={handleButtonClick}
                  link
                >
                  {t('msg_codes_check_country_region_availability')}
                </Button>
              </div>
              {
                message && (
                  <div
                    className="country-availability-message"
                    data-testid="country-availability-message"
                  >
                    {message}
                  </div>
                )
              }
            </>
          )}
        </Flex>
      </Layout>
    </>
  );
};

SkuCountryAvailability.propTypes = {
  skus: PropTypes.array,
};

SkuCountryAvailability.defaultProps = {
  skus: [],
};

export default SkuCountryAvailability;
