import React, {
  useState, useEffect, useReducer, useCallback, useRef, useContext,
} from 'react';
import {
  ActionBar,
  Container,
  DataTable,
  NoResults,
  Pagination,
  GroupState,
  RowDisplay,
  TableHeader,
  TableHeaderCell,
  Select,
  Button,
  Modal,
  ModalContext,
  MODAL_ACTIONS,
  NotificationContext,
} from '@partner-global-ui/components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { xor, sortBy } from 'lodash';
import * as voucherBatchActions from '../../actions/voucherBatchActions';
import VoucherBatch from './VoucherBatch';
import './VoucherBatches.scss';
import hasPermission from '../../utils/accessControl/hasPermission';
import roles from '../../utils/accessControl/roleKeys';
import * as voucherTypeDetailActions from '../../actions/voucherTypeDetailActions';
import * as voucherBatchDetailActions from '../../actions/voucherBatchDetailActions';
import Nav from './Nav';
import RowDisplayLocalization from '../../helpers/RowDisplayLocalization';
import FiltersContainer from '../common/Filters/FiltersContainer';
import { filterCounter } from '../../helpers/filterMethods';
import * as filterOptions from '../../constants/filterOptions.constants';
import { timeout } from '../../constants/notifications.constant';
import { isActionAllowed } from '../../utils/voucherBatchStatus';
import RenderBulkWarningModal from './BulkCancelingWarningModal';

export const defaultFilters = {
  voucherPurpose: [],
  npRegion: [],
  status: [],
  startDate: '',
  endDate: '',
};

export const initialState = {
  filters: defaultFilters,
  filtered: false,
  filterCount: 0,
  selectedRows: [],
  groupCheckboxState: GroupState.NONE,
};

export const componentActions = {
  setFilter: 2,
  clearFilters: 3,
  toggleRow: 4,
  togglePage: 5,
  resetCheckBoxes: 6,
};

export const localStateReducer = (
  state,
  {
    type = '',
    name,
    value,
    voucherBatchId,
    voucherBatchIds,
    filters = defaultFilters,
  },
) => {
  const filteredState = filters
    && (
      filters.voucherPurpose.length > 0
      || filters.npRegion.length > 0
      || filters.status.length > 0
      || filters.startDate.length > 0
      || filters.endDate.length > 0
    );


  let newState;
  let selectedRows = [];
  switch (type) {
    case componentActions.setFilter:
      newState = {
        ...state,
        filters: { ...state.filters, [name]: value },
        filtered: !filteredState,
      };
      return { ...newState, filterCount: filterCounter(newState.filters) };
    case componentActions.clearFilters:
      return {
        ...state,
        filters: defaultFilters,
        filtered: false,
        filterCount: 0,
      };
    case componentActions.toggleRow:
      selectedRows = xor(state.selectedRows, [voucherBatchId]);
      return {
        ...state,
        selectedRows,
        groupCheckboxState: selectedRows.length > 0 ? GroupState.SOME : GroupState.NONE,
      };
    case componentActions.togglePage:
      selectedRows = xor(state.selectedRows, voucherBatchIds);
      return {
        ...state,
        selectedRows,
        groupCheckboxState: selectedRows.length > 0 ? GroupState.SOME : GroupState.NONE,
      };
    case componentActions.resetCheckBoxes:
      return {
        ...state,
        selectedRows: initialState.selectedRows,
        groupCheckboxState: GroupState.NONE,
      };
    default:
      return state;
  }
};


const VoucherBatchesPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const tableRef = useRef();
  const notificationContext = useContext(NotificationContext);
  const modalContext = useContext(ModalContext);

  const { userId } = useSelector(state => state.user);
  const initialPageSize = localStorage.getItem(`voucherBatchListPageSize-${userId}`) !== null
    ? Number(localStorage.getItem(`voucherBatchListPageSize-${userId}`))
    : 10;
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [sort, setSort] = useState('modifyDate');
  const [sortOrder, setSortOrder] = useState('desc');
  const [bulkAction, setBulkAction] = useState('');
  const [bulkActionLabel, setBulkActionLabel] = useState('');
  const [refreshHandle, setRefreshHandle] = useState(-1);

  const loadingVoucherBatches = useSelector(
    state => state.loadingVoucherBatches,
  );
  const loadingSelectVoucherBatches = useSelector(
    state => state.loadingSelectVoucherBatches,
  );
  const search = useSelector(state => state.voucherBatchesPage.search);
  const voucherBatchesPage = useSelector(
    state => state.voucherBatchesPage.page,
    shallowEqual,
  );
  const voucherBatches = useSelector(state => state.voucherBatchCatalogs);
  const canViewVoucherBatches = useSelector(state => hasPermission(
    state, roles.voucherBatches.viewList,
  ));
  const canEditVoucherBatches = useSelector(state => hasPermission(
    state, roles.voucherBatches.edit,
  ));

  const [localState, localDispatch] = useReducer(
    localStateReducer,
    initialState,
  );

  const {
    size,
    number,
  } = voucherBatchesPage;

  const {
    selectedRows,
    filterCount,
    filters,
    groupCheckboxState,
  } = localState;

  useEffect(() => {
    return () => {
      dispatch(voucherBatchActions.resetVoucherBatchesPage());
    };
  }, []);

  useEffect(() => {
    localDispatch({ type: componentActions.resetCheckBoxes });
    voucherBatchActions.loadVoucherBatches(
      pageSize,
      pageNumber,
      { name: sort, order: sortOrder },
      {
        ...filters,
        search,
      },
    )(dispatch);
  }, [pageSize, pageNumber, sort, sortOrder, search]);

  // refresh page every 5 seconds for any voucher batches that are pending file generation
  useEffect(() => {
    if (
      voucherBatches
      && (!loadingVoucherBatches || !loadingSelectVoucherBatches)
    ) {
      const pendingBatches = voucherBatches
        .filter(
          batch => batch.state === 'FILE_GENERATION_IN_PROGRESS'
            || batch.state === 'CREATION_IN_PROGRESS',
        )
        .map(batch => batch.voucherBatchId);
      if (pendingBatches && pendingBatches.length > 0) {
        setRefreshHandle(setTimeout(() => voucherBatchActions
          .fetchSelectVoucherBatches(
            notificationContext, pendingBatches, false,
          )(dispatch), timeout));
      }
    }
    return () => {
      if (refreshHandle !== -1) {
        clearTimeout(refreshHandle);
      }
    };
  }, [loadingVoucherBatches, loadingSelectVoucherBatches]);

  const fetchVoucherBatches = () => {
    const pageSizeLocal = localStorage.getItem(`voucherBatchListPageSize-${userId}`) !== null
      ? Number(localStorage.getItem(`voucherBatchListPageSize-${userId}`))
      : size;
    voucherBatchActions.loadVoucherBatches(
      pageSizeLocal,
      number,
      { name: sort, order: sortOrder },
      {
        ...filters,
        search,
      },
    )(dispatch);
  };

  const fetchSelectBatches = batchIds => () => voucherBatchActions
    .fetchSelectVoucherBatches(
      notificationContext, batchIds, false,
    )(dispatch);

  const cancelBatch = (batchId, isEntireBatch, startIndex, endIndex) => {
    if (isEntireBatch) {
      voucherBatchDetailActions.deactivateVoucherBatch(
        notificationContext, batchId, {}, fetchVoucherBatches,
      )(dispatch);
    } else {
      voucherBatchDetailActions.deactivateVoucherBatch(
        notificationContext, batchId, { startIndex, endIndex }, fetchVoucherBatches,
      )(dispatch);
    }
  };

  const hideBulkModal = () => {
    modalContext.dispatch({
      type: MODAL_ACTIONS.HIDE,
    });
  };

  const handleBatchAction = (action, batchIds) => {
    setBulkAction('');
    localDispatch({ type: componentActions.resetCheckBoxes });
    if (batchIds && batchIds.length > 0) {
      const title = batchIds.length > 1 ? t('msg_codes_deactivate_multiple_batches_header')
        : t('msg_codes_deactivate_batch_header');
      const modalMessage = batchIds.length > 1 ? t('msg_codes_deactivate_multiple_batches')
        : t('msg_codes_deactivate_batch_warning', { batchID: batchIds[0] });
      const modalQuestion = t('msg_codes_deactivated_warning');
      const modalProps = {
        id: 'bulk-warning-modal',
        name: 'BulkWarningModal',
        title,
        content: RenderBulkWarningModal(batchIds, modalMessage, modalQuestion),
        onSecondary: hideBulkModal,
        primaryLabel: t('msg_codes_common_yes'),
        secondaryLabel: t('msg_codes_cta_no'),
        hideCancelButton: true,
      };

      const handlePrimaryBulkModalClick = () => {
        voucherTypeDetailActions.deactivateVoucherBatches(
          batchIds,
          fetchSelectBatches(batchIds),
          notificationContext,
          hideBulkModal,
        )().then(() => {
          hideBulkModal();
        }).catch(error => console.error(error));
      };

      switch (action) {
        case 'activate':
          voucherTypeDetailActions.activateVoucherBatches(
            notificationContext,
            batchIds,
            fetchSelectBatches(batchIds),
          )();
          break;
        case 'generate':
          voucherTypeDetailActions.generateVoucherBatches(
            notificationContext,
            batchIds,
            fetchSelectBatches(batchIds),
          )();
          break;
        case 'download':
          voucherTypeDetailActions.downloadVoucherBatches(
            notificationContext,
            batchIds,
            fetchSelectBatches(batchIds),
          )();
          break;
        case 'deactivate':
          modalProps.onPrimary = handlePrimaryBulkModalClick;
          modalContext.dispatch({
            type: MODAL_ACTIONS.SHOW,
            payload: <Modal {...modalProps} />,
          });
          break;
        default:
          break;
      }
    }
  };

  const batchActionHandler = async () => {
    handleBatchAction(`${bulkAction}`, selectedRows);
  };

  const renderBulkActions = () => {
    const bulkActionOptions = [
      {
        label: t('msg_codes_activate_cta'),
        value: 'activate',
      },
      {
        label: t('msg_codes_cta_generate'),
        value: 'generate',
      },
      {
        label: t('msg_codes_cta_download'),
        value: 'download',
      },
      {
        label: t('msg_codes_deactivate_cta'),
        value: 'deactivate',
      },
    ];
    return (
      <div className="bulk-action">
        <div className="bulk-action-selector">
          <Select
            disabled={
          !canEditVoucherBatches
          || !voucherBatches
          || voucherBatches.length === 0
        }
            name="voucherBatchBulkAction-selector"
            id="voucherBatchBulkAction-selector"
            placeholder={bulkAction ? bulkActionLabel : t('msg_codes_select_bulk_action')}
            size={('lg', false)}
            options={bulkActionOptions}
            onChange={(e) => {
              setBulkAction(e.target.value.value);
              setBulkActionLabel(e.target.value.label);
            }}
          />
        </div>
        <Button
          disabled={
            !canEditVoucherBatches
            || !voucherBatches
            || voucherBatches.length === 0
          }
          id="voucherBatchBulkAction-button"
          className="voucherBatchBulkAction-button"
          primary
          onClick={() => {
            batchActionHandler();
          }}
        >
          {t('msg_codes_go_cta')}
        </Button>
      </div>
    );
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    localDispatch({ type: componentActions.setFilter, name, value });
  };

  const regionOptions = filterOptions.optionsTranslationCreator(
    filterOptions.voucherTypesRegionOptions, t,
  );
  const purposeOptions = filterOptions.optionsTranslationCreator(
    filterOptions.voucherBatchPurposeOptions, t,
  );
  const statusOptions = filterOptions.optionsTranslationCreator(
    filterOptions.voucherBatchStatusOptions, t,
  );

  const createFilters = useCallback(() => {
    const {
      npRegion,
      voucherPurpose,
      status,
      startDate,
      endDate,
    } = filters;
    return [
      {
        label: t('msg_codes_voucher_purpose'),
        name: 'voucherPurpose',
        placeholder: t('msg_codes_filter_all'),
        value: voucherPurpose,
        multiple: true,
        options: purposeOptions,
        handleChange: handleFilterChange,
      },
      {
        label: t('msg_codes_orderList_countryHeader'),
        name: 'npRegion',
        placeholder: t('msg_codes_filter_all'),
        value: npRegion,
        multiple: true,
        options: sortBy(regionOptions, 'label'),
        handleChange: handleFilterChange,
      },
      {
        label: t('msg_codes_agreements_agreementStatus'),
        name: 'status',
        placeholder: t('msg_codes_filter_all'),
        value: status,
        multiple: true,
        options: statusOptions,
        handleChange: handleFilterChange,
      },
      {
        label: t('msg_codes_orderDetails_startDate'),
        name: 'startDate',
        type: 'date',
        placeholder: t('msg_codes_filter_all'),
        value: startDate,
        options: statusOptions,
        handleChange: handleFilterChange,
      },
      {
        label: t('msg_codes_orderDetails_endDate'),
        name: 'endDate',
        type: 'date',
        placeholder: t('msg_codes_filter_all'),
        value: endDate,
        options: statusOptions,
        handleChange: handleFilterChange,
      },
    ];
  }, [filters]);


  const clearFilters = () => {
    localDispatch({ type: componentActions.clearFilters });
    dispatch(voucherBatchActions.resetFilters());
  };

  const actionBar = () => {
    const handleApplyFilters = () => {
      voucherBatchActions.loadVoucherBatches(
        pageSize,
        pageNumber,
        { name: sort, order: sortOrder },
        {
          ...filters,
          search,
        },
      )(dispatch);
      setPageNumber(0);
    };
    return (
      <ActionBar
        filters={(
          <FiltersContainer
            filters={createFilters()}
            id="voucherBatches-filters"
          />
)}
        onFilterClear={clearFilters}
        filterCount={filterCount}
        onFilterApply={handleApplyFilters}
        filterClearLabel={t('msg_codes_cta_clear')}
        filterPrimaryLabel={t('msg_codes_cta_apply')}
        filterSecondaryLabel={t('msg_codes_cta_cancel')}
        filterToolTipText={t('msg_codes_filter')}
        id="voucherBatches-action-bar"
      />
    );
  };

  const canCheckRow = (row, action) => {
    const readOnlyBatch = typeof row.partnerId === 'number';
    return isActionAllowed(
      action, row, readOnlyBatch, row.npType, row.skusCount, canEditVoucherBatches,
    );
  };

  const toggleGroupCheckbox = useCallback((rows) => {
    let voucherBatchIds;
    return () => {
      if (canEditVoucherBatches) {
        // eslint-disable-next-line no-unused-expressions
        selectedRows.length > 0
          ? selectedRows.length === pageSize
            ? localDispatch({ type: componentActions.resetCheckBoxes })
            : ((voucherBatchIds = rows
              .filter(row => !selectedRows
                .includes(row.voucherBatchId) && canCheckRow(row, bulkAction))
              .map(row => row.voucherBatchId)),
            localDispatch({
              type: componentActions.togglePage,
              voucherBatchIds,
            }))
          : ((voucherBatchIds = rows
            .filter(row => !selectedRows
              .includes(row.voucherBatchId) && canCheckRow(row, bulkAction))
            .map(row => row.voucherBatchId)),
          localDispatch({
            type: componentActions.togglePage,
            voucherBatchIds,
          }));
      }
    };
  }, [bulkAction]);

  const sortVoucherBatches = (e, name) => {
    if (typeof e.preventDefault === 'function') {
      e.preventDefault();
    }
    // eslint-disable-next-line no-nested-ternary
    const newSortOrder = name === sort ? sortOrder === 'asc' ? 'desc' : 'asc' : 'asc';
    setSort(name);
    setSortOrder(newSortOrder);
  };

  const tableHeaders = [
    { label: t('msg_codes_codes_batchID'), value: 'voucherBatchId' },
    { label: t('msg_codes_orderListAdmin_batchControlLabel_dropdown'), value: 'controlLabel' },
    { label: t('msg_codes_voucher_type_id'), value: 'voucherTypeId' },
    { label: t('msg_codes_voucher_purpose'), value: 'voucherPurpose' },
    { label: t('msg_codes_orderDetails_startDate'), value: 'startDate' },
    { label: t('msg_codes_orderDetails_endDate'), value: 'endDate' },
    { label: t('msg_codes_last_updated'), value: 'modifyDate' },
    { label: t('msg_codes_agreements_agreementStatus'), value: 'voucherStatus' },
  ];

  const renderHeaderCells = () => {
    return tableHeaders.map(({ label, value }, headerCellIndex) => {
      const headerKey = headerCellIndex + 1;
      return (
        <TableHeaderCell
          sortable={value}
          onClick={e => sortVoucherBatches(e, value)}
          sort={{ sortBy: sort, sortDir: sortOrder }}
          id={`voucherBatches-${label}-header`}
          key={headerKey}
          value={value}
          className={value ? `${label}` : 'remove'}

        >
          {label}
        </TableHeaderCell>
      );
    });
  };

  const handleCheckboxClick = (voucherbatchId) => {
    localDispatch({
      type: componentActions.toggleRow,
      voucherBatchId: voucherbatchId,
    });
  };

  const renderTableRows = useCallback(() => {
    return voucherBatches && voucherBatches.length > 0 ? (
      voucherBatches.map((voucherBatch, index) => {
        const checkboxState = selectedRows.includes(voucherBatch.voucherBatchId)
          ? GroupState.ALL : GroupState.NONE;
        return (
          <VoucherBatch
            key={voucherBatch.voucherBatchId}
            id={`voucherBatches-${voucherBatch.voucherBatchId}`}
            voucherBatch={voucherBatch}
            name="voucherBatches"
            onCheckboxClick={() => handleCheckboxClick(voucherBatch.voucherBatchId)}
            checkboxState={checkboxState}
            history={history}
            cancelBatch={cancelBatch}
            bulkAction={bulkAction}
            onBatchAction={handleBatchAction}
            isEditable={canEditVoucherBatches}
            index={index}
            tableRef={tableRef}
          />
        );
      })
    ) : (
      <NoResults />
    );
  });

  const renderDataTable = () => {
    const columnTemplate = '15% 16% 13% 12% 12% 11% 11% 10%';
    return (
      <DataTable
        columnTemplate={columnTemplate}
        scrollHeight={400}
        actionBar={actionBar()}
        id="voucherBatches-table"
        loading={loadingVoucherBatches}
        hasCheckbox
        ref={tableRef}
      >
        <TableHeader
          onCheckboxClick={toggleGroupCheckbox(voucherBatches)}
          checkboxState={groupCheckboxState}
        >
          {renderHeaderCells()}
        </TableHeader>
        {renderTableRows()}
      </DataTable>
    );
  };

  const renderPagination = () => {
    const changeRowAmount = (pageSizeUpdated) => {
      localStorage.setItem(`voucherBatchListPageSize-${userId}`, pageSizeUpdated);
      setPageSize(pageSizeUpdated);
      setPageNumber(0);
    };

    const changePage = (pageNumberUpdated) => {
      setPageNumber(pageNumberUpdated - 1);
      dispatch(
        voucherBatchActions.setVoucherBatchesPage({
          ...voucherBatchesPage,
          number: pageNumberUpdated - 1,
        }),
      );
    };

    return (
      <>
        {voucherBatchesPage.totalElements > 10 && (
          <div className="voucherBatches-paginator">
            <RowDisplay
              id="voucherBatches-rows"
              currentPage={voucherBatchesPage.number + 1}
              totalItems={voucherBatchesPage.totalElements}
              pageSizes={[10, 25, 50, 100]}
              onPageSizeChange={changeRowAmount}
              initialPageSize={pageSize}
              showingOfText={RowDisplayLocalization(
                'msg_codes_pagination_showing',
              )(t)}
            />
            {voucherBatchesPage.totalElements > pageSize && (
              <Pagination
                totalRecords={voucherBatchesPage.totalElements}
                currentPage={voucherBatchesPage.number + 1}
                pageLimit={pageSize}
                onPageItemClick={changePage}
                id="voucherBatches-pagination"
              />
            )}
          </div>
        )}
      </>
    );
  };

  if (!canViewVoucherBatches) {
    return null;
  }

  return (
    <Container
      className="voucher-batches voucher-batches-list"
      id="voucherBatches-container"
      name="voucherBatches-container"
    >
      <Nav setPageNumber={setPageNumber} />
      {renderBulkActions()}
      {renderDataTable()}
      {renderPagination()}
    </Container>
  );
};

export default VoucherBatchesPage;
