import React from 'react';
import PropTypes from 'prop-types';
import {
  TableRow,
  TableCell,
  Tooltip,
  Truncate,
} from '@partner-global-ui/components';
import { useTranslation } from 'react-i18next';
import { formatDateTimeInLocal } from '../../../utils/formatDateTime';

const Sku = ({ ...props }) => {
  const { t } = useTranslation();

  const {
    sku,
    sku: {
      contentType,
      skuId,
      parentProductName,
      skuType,
      skuName,
      skuAvailabilities,
      lastUpdatedDate,
      status,
    },
    handleSkuCheckboxChange,
    isDisabled,
    checkboxState,
    countries,
    contentTypes,
    skuTypes,
    ...rest
  } = props;

  const getCountryStringId = (ctyName) => {
    const selectedCountry = countries.filter(country => country.name === ctyName)[0];
    const countryStringNameId = selectedCountry ? selectedCountry.nameStringId : ctyName;
    return t(countryStringNameId);
  };

  const renderSkuCountries = (availabilities = []) => {
    if (!availabilities && !availabilities.length) {
      return null;
    } if (availabilities.length === 1) {
      return getCountryStringId(availabilities[0].country.name);
    }
    return (
      <Tooltip
        id="countries-tooltip"
        content={availabilities.map(s => getCountryStringId(s.country.name)).join(', ')}
        strategy="fixed"
        placement="top"
      >
        {t('msg_codes_num_countries_regions', {
          num: availabilities.length,
        })}
      </Tooltip>
    );
  };

  const getContentTypeStringId = (type) => {
    const selectedType = contentTypes.filter(cType => cType.value === type)[0];
    return selectedType && selectedType.nameStringId;
  };

  const getSkuTypeStringId = (type) => {
    const selectedType = skuTypes.filter(sType => sType.value === type.slice(0, -1))[0];
    return selectedType && selectedType.nameStringId;
  };

  return (
    <TableRow
      className="sku-row"
      key={skuId}
      id={`sku-row-${skuId}`}
      pad
      value={sku}
      hasCheckbox
      checkboxState={checkboxState}
      checkboxProps={{
        disabled: isDisabled,
      }}
      onCheckboxClick={() => !isDisabled && handleSkuCheckboxChange(sku)}
      {...rest}
    >
      <TableCell id={`sku-contentType-${skuId}`} key={`sku-contentType-${skuId}`} className="sku-contentType">
        {getContentTypeStringId(contentType)}
      </TableCell>
      <TableCell id={`sku-parentProductName-${skuId}`} key={`sku-parentProductName-${skuId}`} className="sku-parentProductName">
        <Truncate
          id="parentProductName-truncate"
          content={parentProductName}
          height={20}
        >
          {parentProductName}
        </Truncate>
      </TableCell>
      <TableCell id={`sku-skuId-${skuId}`} key={`sku-skuId-${skuId}`} className="sku-skuId">
        <Truncate
          id="skuId-truncate"
          content={skuId}
          height={20}
        >
          {skuId}
        </Truncate>
      </TableCell>
      <TableCell id={`sku-skuType-${skuId}`} key={`sku-skuType-${skuId}`} className="sku-skuType">
        {getSkuTypeStringId(skuType)}
      </TableCell>
      <TableCell id={`sku-skuName-${skuId}`} key={`sku-skuName-${skuId}`} className="sku-skuName">
        <Truncate
          id="skuName-truncate"
          content={skuName}
          height={20}
        >
          {skuName}
        </Truncate>
      </TableCell>
      <TableCell id={`sku-skuAvailabilities-${skuId}`} key={`sku-skuAvailabilities-${skuId}`} className="sku-skuAvailabilities">
        {renderSkuCountries(skuAvailabilities)}
      </TableCell>
      <TableCell id={`sku-lastUpdatedDate-${skuId}`} key={`sku-lastUpdatedDate-${skuId}`} className="sku-lastUpdatedDate">
        {formatDateTimeInLocal(lastUpdatedDate)}
      </TableCell>
      <TableCell id={`sku-status-${skuId}`} key={`sku-status-${skuId}`} className="sku-status">
        {
          status === 'active'
            ? t('msg_codes_partnerStatus_active')
            : t('msg_codes_partnerStatus_inactive')
        }
      </TableCell>
    </TableRow>
  );
};

Sku.propTypes = {
  sku: PropTypes.object,
  handleSkuCheckboxChange: PropTypes.func,
  isSkuSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  checkboxState: PropTypes.number,
  countries: PropTypes.array,
  contentTypes: PropTypes.array,
  skuTypes: PropTypes.array,
};

Sku.defaultProps = {
  sku: {},
  handleSkuCheckboxChange: () => null,
  checkboxState: 1,
  isSkuSelected: false,
  isDisabled: false,
  countries: [],
  contentTypes: [],
  skuTypes: [],
};

export default Sku;
