import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default (state = initialState.loadingVoucherBatch, { type }) => {
  switch (type) {
    case types.BEGIN_LOAD_VOUCHER_BATCH:
      return true;
    case types.LOAD_VOUCHER_BATCH_SUCCESS:
    case types.LOAD_VOUCHER_BATCH_FAILURE:
      return false;

    default:
      return state;
  }
};
