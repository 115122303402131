import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Prompt } from 'react-router-dom';
import './Agreements.scss';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Container,
  Layout,
  Flex,
  Button,
  NotificationContext,
  Modal,
  ModalContext,
  MODAL_ACTIONS,
  ScrollArea,
} from '@partner-global-ui/components';
import { useTranslation } from 'react-i18next';
import * as AgreementFormActions from '../../actions/agreementFormActions';
import handleSaveClick from '../../actions/agreementFormHandleSave';
import checkSaveClick from '../../actions/agreementFormBeforeSave';
import AgreementFormComponent from './AgencyAgreementForm';
import AgreementChangeHistoryComponent from './AgreementChangeHistory';
import {
  canCreateNewVersion,
  canDeleteFinalVersion,
  canSaveNewAgreement,
  canSaveCreatingNewVersion,
  canUpdateExistingAgreement,
  canUpdateFirstDraftAgreement,
} from '../../utils/isValidAgreementForm';
import AgreementVersionSelector from './AgreementVersionSelector/AgreementVersionSelector';
import {
  DRAFT, SUSPENDED, INACTIVE, ACTIVE,
} from '../../constants/agreementState.constants';
import { displayStatuses } from '../../constants/order.constants';
import handleUnsavedChanges, { cleanupHandleUnsavedChanges } from '../../utils/handleUnsavedChanges';
import hasPermission from '../../utils/accessControl/hasPermission';
import roleKeys from '../../utils/accessControl/roleKeys';


const AgreementPage = ({ location, match, history }) => {
  /* eslint-disable no-restricted-globals */
  const { push } = history;
  /* eslint-enable no-restricted-globals */

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modalContext = useContext(ModalContext);
  // Global State
  const agreementForm = useSelector(state => state.agreementForm);
  const currencies = useSelector(state => state.currencies);
  const countries = useSelector(state => state.countries);
  const notificationCont = useContext(NotificationContext);

  /* eslint-disable no-shadow */
  const state = useSelector(state => state);
  /* eslint-enable no-shadow */

  // Destructure from Global State
  const {
    isNewAgreement, isNewVersion, initialStatuses, isSaving,
    agreement, agreement: { agreementVersions },
    hasBeenModified, removedCountries, orderLines, draftAgreementForm,
  } = agreementForm;
  const isFirstVersion = agreementVersions.length === 1 && !isNewVersion;

  // Local State
  const [searchIsOpen, setSearchIsOpen] = useState(false);
  const [versionSearchValue, setVersionSearchValue] = useState('');
  const [warnOrderAffected, setWarnOrderAffected] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canCreate, setCanCreate] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [buttonState, setButtonState] = useState({ label: 'msg_codes_cta_save', disabled: true });

  // Lifecycle Hooks
  useEffect(() => {
    // otherwise validate based on state of agreement form
    let formValidator;
    if (canDeleteFinalVersion(agreementForm)) {
      // allow FINAL agreement to be changed to DELETED
      setButtonState({
        label: 'msg_codes_cta_save',
        disabled: isSaving,
      });
    } else if (canCreateNewVersion(agreementForm)) {
      // if agreement version can create a new version change mode to create new version
      setButtonState({
        name: 'createNewVersionButton',
        label: 'Create New Version',
        disabled: isSaving,
      });
    } else {
      if (isNewAgreement) {
        formValidator = canSaveNewAgreement;
      } else if (isNewVersion) {
        formValidator = canSaveCreatingNewVersion;
      } else if (isFirstVersion && initialStatuses.status === DRAFT) {
        formValidator = canUpdateFirstDraftAgreement;
      } else {
        formValidator = canUpdateExistingAgreement;
      }
      setButtonState({
        ...buttonState,
        label: 'msg_codes_cta_save',
        name: 'saveAgreementButton',
        disabled: {
          [true]: () => !formValidator(agreementForm),
          [!hasBeenModified.agreement && !hasBeenModified.version]: () => true,
          [isSaving]: () => true,
        }.true(),
      });
    }
  }, [agreementForm, hasBeenModified, draftAgreementForm]);

  const scrollAreaHeight = (orderLines.length * 24) + 24 > 400
    ? 400
    : (orderLines.length * 24) + 24;

  const orderLineStatusValueMap = displayStatuses.reduce((acc, curr) => ({
    ...acc,
    [curr.value]: curr.translationStringId,
  }), {});

  const outroMessage = warnOrderAffected
    ? (
      <div name="WarningModalOutro" data-testid="warning-modal-outro">
        {t('msg_codes_modal_ordersCanceled_body')}
      </div>
    )
    : (
      <div name="WarningModalOutro" data-testid="warning-modal-outro">
        {t('msg_codes_modal_effectiveDate_body')}<br />
      </div>
    );

  const warningModalBody = (
    <div
      name="WarningModalText"
      className="modal-body-content"
    >
      <div
        name="WarningModalIntro"
        className="warning-modal-intro"
        data-testid="warning-modal-intro"
      >
        <div name="WarningModalIntro" data-testid="warning-modal-intro">
          The changes to this agreement{!warnOrderAffected ? ' version ' : ' '} will affect the following orders, <br />
          details shown here in a list.<br />
        </div>
        <br />
      </div>
      <br />
      <div style={{ height: `${scrollAreaHeight}px` }}>
        <ScrollArea overlay>
          <table>
            <tbody>
              <tr>
                <th className="tableCell">Order ID</th>
                <th className="tableCell">Order Reference</th>
                <th className="tableCell">Submission Status</th>
              </tr>
              {
                orderLines.map((orderLine, index) => {
                  const key = index + 1;
                  return (
                    <tr key={key}>
                      <td className="tableCell">{orderLine.orderNumber}</td>
                      <td className="tableCell">{orderLine.orderReference}</td>
                      <td className="tableCell">{t(orderLineStatusValueMap[orderLine.orderStatus])}</td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </ScrollArea>
      </div>
      <br />
      {outroMessage}
    </div>
  );

  const hideWarningModalCancel = () => {
    modalContext.dispatch({
      type: MODAL_ACTIONS.HIDE,
    });

    const { resetRemovedCountries } = AgreementFormActions;

    if (warnOrderAffected) {
      setWarnOrderAffected(false);
    }
    dispatch(resetRemovedCountries());
    setShowWarningModal(false);
  };

  const hideWarningModalYes = () => {
    const { resetRemovedCountries } = AgreementFormActions;

    if (removedCountries) {
      dispatch(resetRemovedCountries());
    }
    if (warnOrderAffected) {
      setWarnOrderAffected(false);
    }

    const shouldSave = true;
    const queryParams = new URLSearchParams(location.search);
    const returnPath = queryParams.has('return') ? queryParams.get('return') : '/agreements';
    handleSaveClick(
      notificationCont,
      agreementForm,
      () => push(returnPath),
      shouldSave,
      hideWarningModalCancel,
    )(dispatch);
  };

  const modalProps = {
    id: 'warning-modal',
    name: 'WarningModal',
    title: t('Warning'),
    content: warningModalBody,
    primaryLabel: t('msg_codes_confirmation_saveChangesAgreement_cta_yesSave'),
    secondaryLabel: t('msg_codes_confirmation_leave_cta_no'),
    onPrimary: hideWarningModalYes,
    onSecondary: hideWarningModalCancel,
    onClose: hideWarningModalCancel,
    hideCancelButton: true,
  };

  useEffect(() => {
    if (showWarningModal) {
      modalContext.dispatch({
        type: MODAL_ACTIONS.SHOW,
        payload: <Modal {...modalProps} />,
      });
    }
  }, [showWarningModal]);

  useEffect(() => {
    if (state && state.user) {
      setCanCreate(hasPermission(state, roleKeys.agreement.create));
      setCanEdit(hasPermission(state, roleKeys.agreement.edit));
    }
  }, [state]);

  useEffect(() => {
    const { newAgreement } = AgreementFormActions;
    const { params } = match;

    const queryParams = new URLSearchParams(location.search);

    const selectedPartnerId = queryParams.has('partner') ? queryParams.get('partner') : '';
    if (params.id === 'create') {
      newAgreement(selectedPartnerId)(dispatch);
    }
  }, [
    currencies,
    countries,
    location,
    match,
    canEdit,
  ]);

  useEffect(() => {
    if (agreementForm) {
      handleUnsavedChanges(hasBeenModified.agreement || hasBeenModified.version);
    }
    return () => {
      cleanupHandleUnsavedChanges();
    };
  }, [agreementForm, hasBeenModified]);

  useEffect(() => {
    return () => {
      const { resetAgreement } = AgreementFormActions;
      dispatch(resetAgreement());
      cleanupHandleUnsavedChanges();
    };
  }, []);

  // Helper functions
  const toggleVersionSearch = (isOpen) => {
    if (!isOpen) {
      setVersionSearchValue('');
    }
    setSearchIsOpen(isOpen);
  };

  const setVersionSearch = (value) => {
    setVersionSearchValue(value);
  };

  const navigateToPreviousPage = (
    notificationContLocal,
    shouldSave,
  ) => {
    const {
      initialStatuses: { status: initialStatus },
      agreement: { status: currentStatus },
    } = agreementForm;
    const queryParams = new URLSearchParams(location.search);
    const returnPath = queryParams.has('return') ? queryParams.get('return') : '/agreements';

    setWarnOrderAffected(initialStatus === ACTIVE
      && (currentStatus === SUSPENDED || currentStatus === INACTIVE));

    checkSaveClick(
      notificationContLocal,
      agreementForm,
      () => push(returnPath),
      shouldSave,
      warnOrderAffected,
      setShowWarningModal,
    )(dispatch);
  };

  const renderLabel = () => {
    const { canEditVersion, agreementVersionId } = agreementForm;
    const { version } = hasBeenModified;

    const sortedAgreementVersions = _.orderBy(agreementVersions, 'agreementVersionId', ['asc']);
    const foundVersion = sortedAgreementVersions.reduce((foundNumber, localVersion, index) => {
      return localVersion.agreementVersionId === agreementVersionId ? index : foundNumber;
    }, '');

    if (canEditVersion && isNewVersion && version) {
      return _.padStart(sortedAgreementVersions.length + 1, 3, '0');
    }
    return _.padStart(foundVersion + 1, 3, '0');
  };

  const renderButton = () => {
    const shouldSave = buttonState.label === 'msg_codes_cta_save';

    return (
      <Button
        id="save-agreement-button"
        primary
        onClick={() => navigateToPreviousPage(
          notificationCont,
          shouldSave,
        )}
        disabled={buttonState.disabled}
      >
        {t(buttonState.label)}
      </Button>
    );
  };

  const renderNav = () => {
    return (
      <Layout id="agreement-header-wrapper" className="agreement-header-wrapper">
        <Flex id="agreement-header" className="agreement-header" colSpan={12}>
          <Flex id="agreement-header-title" className="agreement-header-title" colSpan={5}>
            <h3 data-test-id="agreement-header-heading">
              {agreement.agreementId ? (
                <span name="agreementHeader">Agreement ID: {`0000000${agreement.agreementId}`.slice(-8)}</span>
              ) : (<span name="agreementHeader">{t('msg_codes_orderList_agreement')}</span>)}
            </h3>
            <div className="agreement-version-selector">
              <AgreementVersionSelector
                data-testid="dropdown-list"
                canEdit={canEdit || canCreate}
                toggleDropdown={toggleVersionSearch}
                setSearchValue={setVersionSearch}
                searchValue={versionSearchValue}
                renderLabel={renderLabel}
                toggleVersionSearch={toggleVersionSearch}
                searchIsOpen={searchIsOpen}
              />
            </div>
          </Flex>
          <Flex id="agreement-header-buttons" className="agreement-header-buttons" colSpan={7}>
            {(canEdit || canCreate) && renderButton()}
          </Flex>
        </Flex>
      </Layout>
    );
  };

  return (
    <>
      <Prompt
        when={hasBeenModified.agreement || hasBeenModified.version}
        message={t('msg_codes_confirmation_quit_body')}
      />
      <Container id="agreement-conatiner" className="agreement">
        {renderNav()}
        <AgreementFormComponent editable={canEdit || canCreate} />
        <AgreementChangeHistoryComponent canEdit={canEdit || canCreate} />
      </Container>
    </>
  );
};

AgreementPage.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
};

AgreementPage.defaultProps = {
  location: {},
  history: {},
};

export default AgreementPage;
