import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Container,
  Layout,
  Flex,
  Collapsible,
  Divider,
  Select,
  TextInput,
  DateInput,
} from '@partner-global-ui/components';
import { useTranslation } from 'react-i18next';
import * as AgreementStates from '../../../constants/agreementState.constants';
import { THIRD_PARTY_RATECARD } from '../../../constants/agreementType.constants';
import { updateAgreementProp } from '../../../actions/agreementFormActions';

function AgreementInformation({ agreementForm, editable }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { agreement } = agreementForm;
  const { creationDate = '' } = agreement;
  const placeholderPartnerName = 'PLACE_HOLDER_PARTNER_NAME';
  const canSeePartnerName = agreement.partner.name !== placeholderPartnerName;

  const [formattedVersionEffectiveDate, setVersionEffectiveDate] = useState('');

  useEffect(() => {
    const { draftAgreementVersion: { agreementVersionEffectiveDate } } = agreementForm;
    setVersionEffectiveDate(moment(agreementVersionEffectiveDate).utc().format('MM/DD/YYYY'));
  }, [agreementForm.draftAgreementVersion.agreementVersionEffectiveDate]);

  const partnerOptions = [{
    value: agreement.partner.partnerId,
    label: agreement.partner.name,
  }];

  const agreementTypeOptions = [{
    value: THIRD_PARTY_RATECARD,
    label: t('msg_codes_agreementType_thirdPartyPublisher'),
  }];

  const agreementStatusOptions = [
    {
      value: AgreementStates.ACTIVE,
      label: t('msg_codes_agreementStatus_active'),
    },
    {
      value: AgreementStates.SUSPENDED,
      label: t('msg_codes_agreementStatus_suspended'),
    },
  ];

  const agreementVersionStatusOptions = [{
    value: AgreementStates.FINAL,
    label: t('msg_codes_agreementVersionStatus_final'),
  }];

  const getAgreementStatusOption = (status) => {
    return agreementStatusOptions.find((option) => {
      return option.value === status;
    });
  };

  const handleStatus = ({ target: { name, value } }) => {
    updateAgreementProp(name, value.value)(dispatch);
  };
  return (
    <Layout>
      <Flex colSpan="12">
        <Collapsible
          heading={t('msg_codes_agreements_agreementDetails')}
          id="third-party-agreement-details"
          rightContent={`${t('msg_codes_agreements_dateCreated')} ${moment(creationDate).format('MM/DD/YYYY')}`}
          defaultIsOpen
        >
          <Container className="info-container">
            <Layout className="layout">
              <Flex colSpan={12} className="flex-wrapper">
                <Flex colSpan={4}>
                  {canSeePartnerName && (
                    <Select
                      name="partner"
                      id="partnerId"
                      value={partnerOptions[0]}
                      options={partnerOptions}
                      required
                      disabled
                      label={t('msg_codes_agreements_contractingPartner')}
                    />
                  )}
                  <TextInput
                    name="globalPartnerId"
                    id="globalPartnerId"
                    value={`${agreement.partner.globalPartnerId}`}
                    required
                    disabled
                    label={t('msg_codes_assignments_GlobalPartnerID')}
                  />
                  <Select
                    name="agreementType"
                    id="agreementType"
                    value={agreementTypeOptions[0]}
                    options={agreementTypeOptions}
                    required
                    disabled
                    label={t('msg_codes_agreements_agreementType_label')}
                  />
                </Flex>

                <Flex colSpan={1} offset={1}>
                  <Divider style={{ border: 'none' }} secondary vertical />
                </Flex>

                <Flex colSpan={4}>
                  <Select
                    name="status"
                    id="status"
                    value={getAgreementStatusOption(agreement.status)}
                    options={agreementStatusOptions}
                    disabled={!editable}
                    label={t('msg_codes_agreements_agreementStatus_label')}
                    onChange={handleStatus}
                    required
                  />
                  <Select
                    name="agreementVersionStatus"
                    id="versionStatus"
                    value={agreementVersionStatusOptions[0]}
                    options={agreementVersionStatusOptions}
                    label={t('msg_codes_agreements_agreementVersionStatus')}
                    required
                    disabled
                  />
                  <DateInput
                    name="agreementVersionEffectiveDate"
                    label={t('msg_codes_agreements_agreementVersionEffectiveDate_label')}
                    value={formattedVersionEffectiveDate}
                    disabled
                  />
                </Flex>
              </Flex>
            </Layout>
          </Container>
        </Collapsible>
      </Flex>
    </Layout>
  );
}

AgreementInformation.propTypes = {
  agreementForm: PropTypes.object,
  editable: PropTypes.bool,
};

AgreementInformation.defaultProps = {
  agreementForm: {},
  editable: false,
};

export default AgreementInformation;
