import React, { useEffect, useCallback } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import {
  Collapsible,
} from '@partner-global-ui/components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { voucherTypeOptions } from '../../../constants/filterOptions.constants';
import VoucherFormComponent from './VoucherForm';
import './VoucherCoverageTable.scss';

const AgencyVoucherCoverageTableComponent = ({
  product: voucherCoverageSet,
  canEdit,
  region,
  coverages,
  useActiveCurrencies,
  removeProductType,
}) => {
  const { t } = useTranslation();
  const { countries, currencies } = useSelector(state => state);
  const products = useSelector(state => state.voucherTypes.agencyVoucherTypes);

  useEffect(() => {
    /**
     * The collapsible component does not provide properties to modify the
     * button type in the title bar. Therefore, we are required to manipulate
     * the DOM directly to make these changes.
     */
    const buttons = document
      .querySelectorAll('.action-container > button');
    buttons.forEach((button) => {
      const buttonText = button.innerText || button.textContent;
      if (buttonText.includes('countries/regions')) {
        button.style.border = 'none';
        button.style.pointerEvents = 'none';
      }
    });
  });

  const getCurrencies = useCallback(() => {
    if (useActiveCurrencies) {
      return currencies.filter((currency) => {
        return currency.status === 'ACTIVE';
      });
    }
    return currencies;
  }, [useActiveCurrencies, currencies]);

  const showCountrySet = (coverageSet) => {
    const {
      feeStructure,
      voucherProductType,
    } = coverageSet;
    const selectedCountries = feeStructure.map(fee => fee.country);

    const actions = [
      {
        actionCallback: () => removeProductType(voucherProductType),
        actionText: t('msg_codes_cta_remove'),
      },
    ];

    if (selectedCountries.length > 0) {
      actions.unshift({
        actionCallback: () => {},
        actionText: t('msg_codes_agreements_countriesSelected', {
          selectedNum: selectedCountries.length,
          totalNum: coverages.length,
        }),
      });
    }

    return actions;
  };

  const showCoverageTable = (type) => {
    const typeTitle = _.find(products, p => p.value === type).value;

    const typeLabel = voucherTypeOptions
      .find(typeOption => typeOption.value === typeTitle).label;

    return (
      <div id="agency-coverage-table-container" className="agency-coverage-table-container">
        <Collapsible
          id="agency-coverage-table-container-collapsible"
          heading={t(typeLabel)}
          rightContent={showCountrySet(voucherCoverageSet)}
          defaultIsOpen={false}
          hideBorder
          secondary
        >
          <VoucherFormComponent
            countries={countries}
            voucherProductType={type}
            region={region}
            coverages={coverages}
            voucherCoverageSet={voucherCoverageSet}
            canEdit={canEdit}
            currencies={getCurrencies()}
          />
        </Collapsible>
      </div>
    );
  };

  return (
    showCoverageTable(voucherCoverageSet.voucherProductType)
  );
};

AgencyVoucherCoverageTableComponent.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.object),
  coverages: PropTypes.arrayOf(PropTypes.object),
  voucherCoverageSet: PropTypes.object,
  region: PropTypes.string,
  toggleAllCountries: PropTypes.func,
  removeVoucherProductTypeAction: PropTypes.func,
  canEdit: PropTypes.bool,
  products: PropTypes.array,
  removeProductType: PropTypes.func,
};

AgencyVoucherCoverageTableComponent.defaultProps = {
  region: '',
  coverages: [],
  countries: [{
    name: 'United States',
    currency: 'USD',
    settlementCurrency: 'USD',
    commissionRate: 0,
    selected: false,
    code: 'US',
  }],
  voucherCoverageSet: {},
  toggleAllCountries: () => { },
  removeVoucherProductTypeAction: () => { },
  canEdit: false,
  products: [],
  removeProductType: () => { },
};

export default AgencyVoucherCoverageTableComponent;
