import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function loadingCartReducer(state = initialState.loadingOrder, action) {
  if (action.type === types.BEGIN_LOAD_ORDER) {
    return true;
  } if (action.type === types.LOAD_ORDER_SUCCESS || action.type === types.FINISH_LOAD_ORDER) {
    return false;
  }

  return state;
}
