import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import './Header.scss';
import LocalNavigation from './LocalNavigation';
import * as UserRoles from '../../constants/userRoles.constants';
import roles from '../../utils/accessControl/roleKeys';
import hasPermission from '../../utils/accessControl/hasPermission';
import getFeature from '../../utils/accessControl/getFeature';

const HeaderComponent = () => {
  //  Setup
  const state = useSelector(localState => localState);
  const {
    user,
    user: {
      mustSetupEncryptionKey,
    },
    hasActiveFingerprint,
    userPage: {
      userDetailForm,
    },
  } = state;
  const { VouchersPartnerRequester } = UserRoles;

  const canViewProductCatalog = hasPermission(state, roles.thirdParty.viewCatalog)
    && getFeature(roles.thirdParty.viewCatalog);
  const canViewAgreements = hasPermission(state, roles.agreement.viewList)
    && getFeature(roles.agreement.viewList);
  const canViewPartners = hasPermission(state, roles.partner.viewList)
    && getFeature(roles.partner.viewList);
  const canViewUsers = hasPermission(state, roles.user.view)
    && getFeature(roles.user.view);
  const canViewVoucherTypes = hasPermission(state, roles.voucherTypes.viewList)
    && getFeature(roles.voucherTypes.viewList);
  const canViewCodeProducts = hasPermission(state, roles.codeProducts.viewList)
    && getFeature(roles.codeProducts.viewList);
  const canViewVoucherBatches = hasPermission(state, roles.voucherBatches.viewList)
    && getFeature(roles.voucherBatches.viewList);
  const canViewOrders = hasPermission(state, roles.order.viewList)
    && getFeature(roles.order.viewList);
  const canViewTos = hasPermission(state, roles.termsOfService.canView)
    && getFeature(roles.termsOfService.canView);
  const canAccessPgpKey = hasPermission(state, roles.user.canSeePgpKeys)
    && getFeature(roles.user.canSeePgpKeys);
  const canAccessMaintainanceDashboard = hasPermission(state, roles.user.canSeePgpKeys);
  const ordersNavOptionDisabled = !userDetailForm.hasActiveFingerprint
    && user.roleCode === VouchersPartnerRequester.roleCode
    && !hasActiveFingerprint && mustSetupEncryptionKey;

  const renderLocalNav = useCallback(() => {
    return (
      <LocalNavigation
        canViewProductCatalog={canViewProductCatalog}
        canViewOrders={canViewOrders}
        ordersNavOptionDisabled={ordersNavOptionDisabled}
        user={user}
        canViewAgreements={canViewAgreements}
        canViewPartners={canViewPartners}
        canViewUsers={canViewUsers}
        canViewVoucherTypes={canViewVoucherTypes}
        canViewCodeProducts={canViewCodeProducts}
        canViewVoucherBatches={canViewVoucherBatches}
        canAccessPgpKey={canAccessPgpKey}
        canViewTos={canViewTos}
        canAccessMaintainanceDashboard={canAccessMaintainanceDashboard}
      />
    );
  }, [canViewProductCatalog, canViewOrders, ordersNavOptionDisabled, user,
    canViewUsers, canViewPartners, canViewAgreements, canViewVoucherTypes,
    canViewCodeProducts, canViewVoucherBatches, canAccessPgpKey, canViewTos,
    canAccessMaintainanceDashboard,
  ]);

  return (
    <div className={ordersNavOptionDisabled ? 'venom-header-disabled-orders' : 'venom-header'}>
      {renderLocalNav()}
    </div>
  );
};

export default HeaderComponent;
