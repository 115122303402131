import axios from 'axios';
import moment from 'moment';
import i18n from 'i18next';
import * as types from './actionTypes';
import formatResponseError from '../utils/formatResponseError';
import { autoHide, timeout } from '../constants/notifications.constant';


export function beginLoadVoucherBatches() {
  return { type: types.BEGIN_LOAD_VOUCHER_BATCHES };
}

export function finishLoadVoucherBatches(voucherBatchCatalogs) {
  return { type: types.FINISH_LOAD_VOUCHER_BATCHES, voucherBatchCatalogs };
}

export function setVoucherBatchesPage(page) {
  return { type: types.SET_VOUCHER_BATCHES_PAGE, page };
}

export function setVoucherBatchAction(voucherBatch) {
  return { type: types.SET_VOUCHER_BATCH, voucherBatch };
}

export function resetFilters() {
  return { type: types.RESET_VOUCHER_BATCHES_FILTERING };
}

export function setFilters(filterName, filterValue) {
  return {
    type: types.SET_VOUCHER_BATCHES_FILTER,
    filterName,
    filterValue,
  };
}

export function setVoucherBatchesSearch(search) {
  return { type: types.SET_VOUCHER_BATCHES_SEARCH, search };
}

export function clearVoucherBatchesSearch() {
  return { type: types.CLEAR_VOUCHER_BATCHES_SEARCH };
}

export function resetVoucherBatchesPage() {
  return { type: types.RESET_VOUCHER_BATCHES_PAGE };
}

export function beginLoadSelectVoucherBatches() {
  return { type: types.BEGIN_LOAD_SELECT_VOUCHER_BATCHES };
}

export function finishLoadSelectVoucherBatches(voucherBatches, isAssociatedBatchSection) {
  const type = isAssociatedBatchSection ? types.FINISH_LOAD_SELECT_VOUCHER_TYPE_BATCHES
    : types.FINISH_LOAD_SELECT_VOUCHER_BATCHES;
  return { type, voucherBatches };
}

function startDateFormatting(startDate) {
  const localToUtcDate = moment(startDate).utc().format('YYYY-MM-DD HH:mm');
  return localToUtcDate;
}

function endDateFormatting(endDate) {
  const localToUtcDate = moment(endDate).add(24, 'hours').add(-1, 'minutes').utc()
    .format('YYYY-MM-DD HH:mm');
  return localToUtcDate;
}

function mapFilterParams(filters) {
  let params = '';
  // Expected input: filters: npRegion =
  // [{label: 'SCEA', value: 'SCEA'},{label: 'SCEE', value: 'SCEE'}]
  // Expected output: '&npRegion=SCEA,SCEE'
  Object.keys(filters).forEach((key) => {
    if (filters[key] && filters[key].length > 0) {
      if (key === 'startDate') {
        params += `&${key}=${startDateFormatting(filters[key])}`;
      } else if (key === 'endDate') {
        params += `&${key}=${endDateFormatting(filters[key])}`;
      } else if (key === 'search') {
        params += `&${key}=${filters[key]}`;
      } else {
        params += `&${key}=${filters[key].map(item => item.value).join(',')}`;
      }
    }
  });

  return params;
}

export function loadVoucherBatches(
  size = 10,
  page = 0,
  sort = { name: 'id', order: 'asc' },
  filters = {},
) {
  const sortParam = `?sort=${sort.name},${sort.order}`;
  const pageParam = `page=${page}`;
  const sizeParam = `size=${size}`;
  const filtersParams = mapFilterParams(filters);
  const url = `/venom/api/voucherBatchDetails${sortParam}&${pageParam}&${sizeParam}&${filtersParams}`;
  const { name: sortName, order: sortOrder } = sort;
  return (dispatch) => {
    dispatch(beginLoadVoucherBatches());
    return axios.get(url)
      .then(response => response.data)
      .then((voucherBatches) => {
        dispatch(finishLoadVoucherBatches(voucherBatches.content));
        dispatch(setVoucherBatchesPage({
          number: voucherBatches.number,
          size: voucherBatches.size,
          totalElements: voucherBatches.totalElements,
          sort: sortName,
          sortOrder,
        }));
      }).catch((error) => {
        console.error(error);
      });
  };
}

export function setVoucherBatch(voucherBatch) {
  return (dispatch) => {
    dispatch(setVoucherBatchAction(voucherBatch));
  };
}

export function fetchSelectVoucherBatches(notificationCont, batchIds, isAssociatedBatchSection) {
  return (dispatch) => {
    dispatch(beginLoadSelectVoucherBatches());
    return axios.get(`/venom/api/voucherBatchDetails?voucherBatchId=${batchIds.join()}`)
      .then(response => response.data)
      .then((voucherBatches) => {
        dispatch(finishLoadSelectVoucherBatches(voucherBatches.content, isAssociatedBatchSection));
      })
      .catch((error) => {
        notificationCont.dispatch({
          type: 'add',
          payload: {
            status: 'error',
            autoHide,
            timeout,
            message: i18n.t(formatResponseError(error.response)),
          },
        });
      });
  };
}
