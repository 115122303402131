const getTableHeaders = (t, isAwaitingApproval, hideAllLineKabobs) => {
  const tableHeaders = [
    t('msg_codes_voucherType_product_lowerCase'),
    t('msg_codes_codes_productID'),
    t('msg_codes_codes_productType'),
    t('msg_codes_codes_version'),
    t('msg_codes_codes_platform'),
    t('msg_codes_codes_concept'),
    t('msg_codes_target_regions'),
    t('msg_codes_orderlist_estReleaseDate_header'),
    t('msg_codes_codes_releaseDate'),
    t('msg_codes_orders_codeName_label'),
    t('msg_codes_common_region'),
    t('msg_codes_orderList_countryHeader'),
    t('msg_codes_orderList_skuid'),
    t('msg_codes_codes_quantity'),
    {
      label: t('msg_codes_use_count'),
      tooltip: t('msg_codes_use_count_approver_tooltip'),
    },
    ...(!isAwaitingApproval ? [t('msg_codes_codes_batchID')] : []),
    ...(!isAwaitingApproval ? [t('msg_codes_orderDetailsAdmin_batchFileName_label')] : []),
    t('msg_codes_orderDetails_startDate'),
    t('msg_codes_orderDetails_endDate'),
    ...(!isAwaitingApproval ? [t('msg_codes_orderDetailsAdmin_fulfillment_label')] : []),
    ...(!isAwaitingApproval ? [t('msg_codes_orderDetails_delivery')] : []),
    ...(!isAwaitingApproval ? [t('msg_codes_orderDetailsAdmin_Activation_label')] : []),
    ...(!hideAllLineKabobs ? [''] : []),
  ];
  return tableHeaders;
};

export default getTableHeaders;
