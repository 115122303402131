import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Layout } from '@partner-global-ui/components';
import { acceptTerms } from '../../actions/userActions';
import history from '../../history';
import './termsOfService.scss';

function ReferencePage() {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const { t } = useTranslation();
  const handleCancel = useCallback(() => history.push('/canceledtos'), [history]);
  const handleAccept = useCallback(async () => {
    acceptTerms(user, history)(dispatch);
  }, [acceptTerms, dispatch]);

  const hasAccepted = !user.mustAcceptCurrentTerms || user.hasAcceptedCurrentTerms === true;
  return (
    <div className="tos" style={{ paddingTop: !hasAccepted ? 20 : 41 }}>
      {!hasAccepted && (
        <Layout className="header">
          <h3 className="title" data-testid="tos-header-title">
            {t('msg_codes_termsOfService')}
          </h3>
          <div className="buttons" data-testid="tos-header-buttons">
            <Button
              id="cancel-button"
              name="cancel"
              onClick={handleCancel}
            >
              {t('msg_codes_cta_cancel')}
            </Button>
            <div className="spacer" />
            <Button
              id="accept-button"
              name="agree"
              primary
              onClick={handleAccept}
            >
              {t('msg_codes_cta_agree')}
            </Button>
          </div>
        </Layout>
      )}

      <Layout className="body">
        <h2 className="title" name="title" style={{ marginTop: !hasAccepted ? 120 : 60 }}>
          {t('msg_codes_termsOfService_PSCodesAppTermsOfUse')}
        </h2>

        <p className="terms">
          {t('msg_codes_termsOfService_body')}
        </p>
      </Layout>
    </div>
  );
}

export default ReferencePage;
