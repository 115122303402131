import initialState from './initialState';
import * as types from '../actions/actionTypes';

export default (state = initialState.orderActivityRules, action) => {
  switch (action.type) {
    case types.SET_ORDER_ACTIVITY_RULES:
      return action.rules;

    default:
      return state;
  }
};
