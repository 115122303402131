import i18n from 'i18next';
import axios from 'axios';
import * as types from './actionTypes';
import displayErrorMessage from '../utils/errorMessageHandler';
import { autoHide, timeout } from '../constants/notifications.constant';

export function changeVoucherBatchProp(name, value) {
  return { type: types.CHANGE_VOUCHER_BATCH_PROP, name, value };
}

export function loadNewVoucherBatch(voucherTypeId) {
  return { type: types.LOAD_NEW_VOUCHER_BATCH, voucherTypeId };
}

export function beginLoadingVoucherBatch() {
  return { type: types.BEGIN_LOAD_VOUCHER_BATCH };
}

export function loadVoucherBatchSuccess(voucherBatch) {
  return { type: types.LOAD_VOUCHER_BATCH_SUCCESS, voucherBatch };
}

export function saveVoucherBatchSuccess(voucherBatch) {
  return { type: types.SAVE_VOUCHER_BATCH_SUCCESS, voucherBatch };
}

export function voucherBatchActionSuccess(voucherBatch) {
  return { type: types.VOUCHER_BATCH_ACTION_SUCCESS, voucherBatch };
}

export function resetVoucherBatch() {
  return { type: types.RESET_VOUCHER_BATCH };
}

export function voucherBatchPollingSuccess(voucherBatch) {
  return { type: types.VOUCHER_BATCH_POLLING_SUCCESS, voucherBatch };
}

// Dispatching functions

export function changeProp(name, value) {
  return (dispatch => dispatch(changeVoucherBatchProp(name, value)));
}

export function pollVoucherBatch(notificationCont, id, successText) {
  return (async (dispatch) => {
    await axios.get(`/venom/api/voucherBatchDetails/${id}`).then(
      (response) => {
        const voucherBatch = response.data;
        if (voucherBatch.state !== 'FILE_GENERATION_IN_PROGRESS' && voucherBatch.state !== 'CREATION_IN_PROGRESS') {
          dispatch(voucherBatchPollingSuccess(voucherBatch));
          notificationCont.dispatch({
            type: 'add',
            payload: {
              status: 'success',
              autoHide,
              timeout,
              message: successText,
            },
          });
        }
      },
      () => {},
    );
  });
}

export function loadVoucherBatch(notificationCont, id) {
  return (async (dispatch) => {
    dispatch({ type: types.BEGIN_LOAD_VOUCHER_BATCH });
    await axios(`/venom/api/voucherBatchDetails/${id}`)
      .then((response) => {
        const voucherBatch = response.data;
        dispatch({ type: types.LOAD_VOUCHER_BATCH_SUCCESS, voucherBatch });
      })
      .catch(() => {
        dispatch({ type: types.LOAD_VOUCHER_BATCH_FAILURE });
        notificationCont.dispatch({
          type: 'add',
          payload: {
            status: 'error',
            autoHide,
            timeout,
            message: i18n.t('There was an error loading the Voucher Batch.'),
          },
        });
      });
  });
}

export function createNewVoucherBatch() {
  return (dispatch => dispatch(loadNewVoucherBatch()));
}

export const saveVoucherBatch = ({ notificationCont, voucherBatch, navigate }) => (dispatch) => {
  return axios(`/venom/api/voucherBatchDetails/${voucherBatch.voucherBatchId ? voucherBatch.voucherBatchId : ''}`,
    {
      method: voucherBatch.voucherBatchId ? 'PUT' : 'POST',
      data: voucherBatch,
    })
    .then((response) => {
      const voucherBatchResponse = response.data;
      dispatch(saveVoucherBatchSuccess(voucherBatchResponse));
      navigate(voucherBatchResponse.voucherBatchId);
      notificationCont.dispatch({
        type: 'add',
        payload: {
          status: 'success',
          autoHide,
          timeout,
          message: i18n.t('msg_codes_batch_saved_success'),
        },
      });
    },
    (error) => {
      const message = error.response.data;

      if (error.message.includes('Request failed with status code 409')) {
        notificationCont.dispatch({
          type: 'add',
          payload: {
            status: 'error',
            autoHide,
            timeout,
            message: i18n.t('Batch control label already in use. Enter another batch control label.'),
            testId: 'error',
          },
        });
      } else {
        displayErrorMessage(notificationCont, message, {});
      }
    });
};

export const activateVoucherBatch = (notificationCont, batchId) => (dispatch) => {
  return axios.put(`/venom/api/voucherBatchDetails/${batchId}/activate`)
    .then((response) => {
      const voucherBatchResponse = response.data;
      dispatch(voucherBatchActionSuccess(voucherBatchResponse));
      notificationCont.dispatch({
        type: 'add',
        payload: {
          status: 'success',
          autoHide,
          timeout,
          message: i18n.t('Voucher Batch activated!'),
        },
      });
    }).catch((error) => {
      const message = error.response.data;
      displayErrorMessage(notificationCont, message, {});
    });
};

export const generateVoucherBatchFile = (notificationCont, batchId) => (dispatch) => {
  return axios.put(`/venom/api/voucherBatchDetails/${batchId}/generateFile`)
    .then((response) => {
      const voucherBatchResponse = response.data;
      if (voucherBatchResponse.state === 'FILE_GENERATION_ERROR') {
        notificationCont.dispatch({
          type: 'add',
          payload: {
            status: 'error',
            autoHide,
            timeout,
            message: i18n.t('Voucher Batch file generation failed.'),
          },
        });
      } else if (voucherBatchResponse.state === 'FILE_GENERATION_IN_PROGRESS') {
        notificationCont.dispatch({
          type: 'add',
          payload: {
            status: 'success',
            autoHide,
            timeout,
            message: i18n.t('msg_codes_file_generation_in_progress'),
          },
        });
        dispatch(voucherBatchActionSuccess(voucherBatchResponse));
      } else {
        dispatch(voucherBatchActionSuccess(voucherBatchResponse));
        notificationCont.dispatch({
          type: 'add',
          payload: {
            status: 'success',
            autoHide,
            timeout,
            message: i18n.t('msg_codes_batch_file_generated_success'),
          },
        });
      }
    },
    (error) => {
      const message = error.response.data;
      displayErrorMessage(notificationCont, message, {});
    });
};

export const downloadVoucherBatch = (notificationCont, batchId) => (dispatch) => {
  return axios.put(`/venom/api/voucherBatchDetails/${batchId}/download`)
    .then((response) => {
      const voucherBatchResponse = response.data;
      dispatch(voucherBatchActionSuccess(voucherBatchResponse));
      if (
        typeof voucherBatchResponse.downloadUrl !== 'undefined'
          && voucherBatchResponse.downloadUrl !== null
          && voucherBatchResponse.downloadUrl.length > 0
      ) {
        window.open(voucherBatchResponse.downloadUrl);
      }
    },
    (error) => {
      const message = error.response.data;
      displayErrorMessage(notificationCont, message, {});
    });
};

function queryStringify(params) {
  if (typeof params !== 'object') {
    return null;
  }
  return Object.keys(params)
    .filter(key => params[key] !== undefined && params[key] !== null)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');
}

export const deactivateVoucherBatch = (
  notificationContext, batchId, params, callback,
) => (dispatch) => {
  return axios.put(`/venom/api/voucherBatchDetails/${batchId}/cancel?${queryStringify(params)}`)
    .then((response) => {
      if (callback) callback();
      const voucherBatchResponse = response.data;
      dispatch(voucherBatchActionSuccess(voucherBatchResponse));
      const batchesInRange = params && ((Number(params.endIndex) - Number(params.startIndex)) + 1);
      const message = params && !(batchesInRange === response.data.quantity)
        ? i18n.t(
          'msg_codes_partial_deactivated_toast', {
            NUMERATOR: batchesInRange,
            DENOMINATOR: response.data.quantity,
          },
        )
        : 'Voucher Batch deactivated!';
      notificationContext.dispatch({
        type: 'add',
        payload: {
          status: 'success',
          autoHide,
          timeout,
          message,
        },
      });
    },
    (error) => {
      if (callback) callback();
      const message = error.response.data;
      displayErrorMessage(notificationContext, message, {});
    });
};

export const fetchAuditHistory = (notificationCont, voucherBatchId) => {
  return (async (dispatch) => {
    await axios.get(`venom/api/voucherBatchDetails/${voucherBatchId}/auditHistory`)
      .then(
        (response) => {
          const voucherBatchAuditHistory = response.data;
          dispatch({
            type: types.LOAD_VOUCHER_BATCH_AUDIT_HISTORY_SUCCESS,
            voucherBatchId,
            content: voucherBatchAuditHistory,
          });
        },
        () => {
          dispatch({
            type: types.LOAD_VOUCHER_BATCH_AUDIT_HISTORY_FAILURE,
            voucherBatchId,
          });
          notificationCont.dispatch({
            type: 'add',
            payload: {
              status: 'error',
              autoHide,
              timeout,
              message: i18n.t('There was an error loading the Voucher Batch Audit History.'),
            },
          });
        },
      );
  });
};

export const resetAuditHistory = () => (dispatch) => {
  dispatch({
    type: types.RESET_VOUCHER_BATCH_AUDIT_HISTORY,
  });
};
