import moment from 'moment';
import axios from 'axios';
import * as types from './actionTypes';
import * as lineCacheActions from './orderLineCacheActions';
import countries from '../constants/countries.constants';

export function beginLoadOrders() {
  return { type: types.BEGIN_LOAD_ORDERS };
}

export function beginAjaxCall() {
  return { type: types.BEGIN_AJAX_CALL };
}

export function beginLoadCountries() {
  return { type: types.BEGIN_LOAD_COUNTRIES };
}

export function finishLoadOrders(orders) {
  return { type: types.FINISH_LOAD_ORDERS, orders };
}


export function finishLoadCountries(fetchedCountries) {
  return { type: types.FINISH_LOAD_COUNTRIES, fetchedCountries };
}

export function setUser(user) {
  return { type: types.SET_USER, user };
}

const getMultiFilterParam = (label, set) => {
  return set.length > 0
    ? set.map(item => `&${label}=${item.value}`).join('')
    : '';
};

export function loadOrders(
  dispatch,
  {
    page = 0, size = 50, sort = { sortBy: 'orderNumber', sortDir: 'desc' }, approverView,
  }, // page data
  { searchType = 'all', search = '' }, // search data
  {
    agreementType = [],
    actionRequired = [],
    country = [],
    region = [],
    activationState = [],
    orderStatus = [],
    creationDateRange = [],
  }, // filter data
  removedOrderLines = new Set(),
) {
  const pageParams = `page=${page}&size=${size}`;

  // use approverView flag if set otherwise use sorting parameters
  const sortParam = approverView ? '&approverView=true' : `&sort=${sort.sortBy},${sort.sortDir}`;

  const searchParam = (searchType && search) ? `&${searchType}=${search}` : '';
  const agreementTypeParam = getMultiFilterParam('agreementType', agreementType);
  const regionParam = getMultiFilterParam('region', region);
  const statusParam = getMultiFilterParam('orderStatus', orderStatus);
  const startParam = creationDateRange[0] ? `&startDate=${moment(creationDateRange[0]).format('YYYY-MM-DD')}` : '';
  const endParam = creationDateRange[1] ? `&endDate=${moment(creationDateRange[1]).format('YYYY-MM-DD')}` : '';
  const actionRequiredParam = getMultiFilterParam('actionRequired', actionRequired);
  const countryParam = getMultiFilterParam('country', country);
  const activationStateParam = getMultiFilterParam('activationState', activationState);
  const filterParams = `${regionParam}${statusParam}${startParam}${endParam}${agreementTypeParam}${actionRequiredParam}${countryParam}${activationStateParam}`;

  const url = `/venom/api/orders?${pageParams}${sortParam}${searchParam}${filterParams}`;
  dispatch(beginLoadOrders());
  return axios.get(url)
    .then(response => response.data)
    .then((orders) => {
      const { content, ...rest } = orders;
      const newOrders = content.map((order) => {
        const newOrder = { ...order };
        const newDeletedOrderLines = lineCacheActions
          .filterSeenDeletedOrderLines(newOrder.deletedOrderLines, removedOrderLines);
        newOrder.orderLines = [...newOrder.orderLines, ...newDeletedOrderLines];
        return newOrder;
      });
      dispatch(finishLoadOrders(newOrders));
      return rest;
    });
}

export function getActiveAgreementRegions(partnerId) {
  let response;
  return async (dispatch) => {
    try {
      response = await axios.get(`/venom/api/agreements/activeRegions?partnerId=${partnerId}`);

      const regions = await response.data;
      dispatch({
        type: types.GET_ACTIVE_AGREEMENT_REGIONS,
        regions,
      });
      return regions;
    } catch (error) {
      return error.response;
    }
  };
}

export function getCountries() {
  let response;
  return async (dispatch) => {
    dispatch(beginLoadCountries());
    try {
      response = await axios.get('/venom/api/countries');
      const fetchedCountries = response.data;
      dispatch(finishLoadCountries(fetchedCountries));
      return fetchedCountries;
    } catch (error) {
      dispatch(finishLoadCountries(countries));
    }
    return response;
  };
}
