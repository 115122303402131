import axios from 'axios';
import * as types from './actionTypes';

import * as CodeProductTypes from '../constants/codeProductTypes.constants';

export function beginLoadCodeProducts() {
  return { type: types.BEGIN_LOAD_CODE_PRODUCTS };
}

export function finishLoadingCodeProducts(codeProducts) {
  return { type: types.LOAD_CODE_PRODUCTS, codeProducts };
}

export function loadCodeProductsSuccess() {
  return { type: types.LOAD_CODE_PRODUCTS_SUCCESS };
}

export function setCodeProductsPage(page) {
  return { type: types.SET_CODE_PRODUCTS_PAGE, page };
}

export function resetCodeProductsPage() {
  return { type: types.RESET_CODE_PRODUCTS_PAGE };
}

export function beginSaveCodeProduct() {
  return { type: types.BEGIN_SAVE_CODE_PRODUCT };
}

export function saveCodeProductSuccess(codeProduct) {
  return { type: types.SAVE_CODE_PRODUCT_SUCCESS, codeProduct };
}

export function loadCodeProducts(
  {
    codeProductType = [],
    loadType = [],
    applicableAgreementType = [],
    exclusiveToPartner = [],
    region = [],
  }, // filters
  search = '',
  size = 50,
  page = 0,
  sort = { sortBy: 'codeProductType', sortDir: 'desc' },
) {
  const cptParam = codeProductType
    .map(type => `&codeProductType=${type.value === 'THIRDPARTY' ? 'PRODUCT' : type.value}`)
    .join('');
  const loadTypeParam = loadType.map(type => `&loadType=${type.value}`).join('');
  const aatParam = applicableAgreementType.map(type => `&applicableAgreementType=${type.value}`).join('');
  const etpParam = exclusiveToPartner.map(type => `&exclusiveToPartner=${type.value}`).join('');
  const regionParam = region.map(type => `&region=${type.value}`).join('');
  const filterParams = `${cptParam}${aatParam}${etpParam}${regionParam}${loadTypeParam}`;

  const searchParam = search && `&search=${encodeURIComponent(search)}`;

  const pageParams = `page=${page}&size=${size}`;

  const sortParam = `&sort=${sort.sortBy},${sort.sortDir}`;

  const url = `/venom/api/codeProducts?${pageParams}${sortParam}${searchParam}${filterParams}`;
  return (dispatch) => {
    dispatch(beginLoadCodeProducts());
    return axios.get(url).then((response) => {
      if (response.status !== 200) {
        return Promise.reject();
      }
      return response.data;
    }).then((codeProducts) => {
      dispatch(finishLoadingCodeProducts(codeProducts.content));
      dispatch(loadCodeProductsSuccess());
      dispatch(setCodeProductsPage({
        number: codeProducts.number,
        size: codeProducts.size,
        totalElements: codeProducts.totalElements,
      }));
    }).catch(() => {
      // TODO: error handling
    });
  };
}

export const setProductProp = (prop, value) => {
  return (dispatch) => {
    return dispatch({ type: types.SET_PRODUCT_PROP, payload: { prop, value } });
  };
};

export const setUpdatedCountries = (updatedAvailableCountries) => {
  return (dispatch) => {
    return dispatch({ type: types.UPDATE_COUNTRIES, payload: updatedAvailableCountries });
  };
};

export const clearProduct = () => {
  return dispatch => dispatch({ type: types.CLEAR_PRODUCT });
};

export const clearProductDetail = () => {
  return dispatch => dispatch({ type: types.CLEAR_PRODUCT_DETAIL });
};

export const getSkus = async (skuIds) => {
  const response = await axios.get('/venom/api/specific-skus/', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'sku-ids': skuIds,
    },
    credentials: 'include',
  });
  const content = await response.data;
  return content.skus;
};

export const getProduct = (id) => {
  return async (dispatch) => {
    const response = await axios.get(`/venom/api/codeProducts/${id}`);
    const product = await response.data;

    // TODO: ability to choose other forms once they are added
    // const form = product.codeProductType === MONEY.code && 'moneyForm';

    // if (form === 'moneyForm') {
    //   dispatch({ type: types.GET_PRODUCT, payload: product });
    // }

    const updatedProduct = product.codeProductType === 'PSPLUS' || product.codeProductType === 'PSNOW'
      ? {
        ...product,
        codeProductType: CodeProductTypes.SUBSCRIPTION.code,
        subscriptionType: product.codeProductType,
      } : product;

    dispatch({ type: types.GET_PRODUCT, payload: updatedProduct });

    if (updatedProduct.skuIds) {
      const productSkus = await getSkus(product.skuIds);

      dispatch({ type: types.GET_PRODUCT_SKU, payload: productSkus });

      return {
        ...updatedProduct,
        skus: productSkus,
      };
    }

    return updatedProduct;
  };
};

export const setApplicableAgreementTypes = () => {
  return async (dispatch) => {
    const response = await axios.get('/venom/api/agreementTypesForProductTypes');
    const applicableAgreementTypesMap = await response.data;

    dispatch({
      type: types.SET_APPLICABLE_AGREEMENT_TYPES_MAP,
      payload: applicableAgreementTypesMap,
    });

    return applicableAgreementTypesMap;
  };
};

export const setPartnersForProductTypes = () => {
  return async (dispatch) => {
    const response = await axios.get('/venom/api/partnersForProductTypes');
    const exclusiveToPartnersMap = await response.data;

    dispatch({
      type: types.SET_EXCLUSIVE_TO_PARTNERS_MAP,
      payload: exclusiveToPartnersMap,
    });

    return exclusiveToPartnersMap;
  };
};

const getMoneyProductFields = (product, country) => {
  return {
    codeProductName: product.codeProductName || `${country.name} ${product.faceValue} ${country.currency.code}`, // can this reference product.productName instead of building it here?
    codeProductType: product.codeProductType,
    status: product.status,
    region: product.region,
    country: product.country,
    applicableAgreementTypes: product.applicableAgreementTypes,
    exclusiveToPartner: product.exclusiveToPartner,
    faceValue: product.faceValue,
  };
};

export const getSubscriptionProductFields = (product) => {
  return {
    codeProductName: product.codeProductName,
    codeProductType: product.subscriptionType,
    codeProductDescription: product.codeProductDescription,
    status: product.status,
    region: product.region,
    isKorea: product.isKorea,
    countries: product.countries,
    applicableAgreementTypes: product.applicableAgreementTypes,
    exclusiveToPartner: product.exclusiveToPartner,
    skuIds: product.skuIds,
  };
};

const get3PProductFields = (product) => {
  return {
    codeProductName: product.codeProductName,
    codeProductType: product.codeProductType,
    status: product.status,
    region: product.region,
    countries: product.countries,
    applicableAgreementTypes: product.applicableAgreementTypes,
    exclusiveToPartner: product.exclusiveToPartner,
    skuIds: product.skuIds,
  };
};

export const getFormattedFields = (product) => {
  const {
    faceValue,
    country,
  } = product;
  const isMoney = product.codeProductType === CodeProductTypes.MONEY.code;

  if (isMoney) {
    return {
      npType: 'MONEY',
      faceValue,
      country,
    };
  }

  return {
    npType: 'PRODUCT',
    skuIds: product.skuIds,
  };
};

export const checkExistingForConfiguration = () => {
  return async (dispatch, getState) => {
    const { codeProduct } = getState();
    const formattedFields = getFormattedFields(codeProduct);

    const url = '/venom/api/codeProducts/existingForConfiguration';

    return axios.put(url, {
      ...formattedFields,
    }).then(async (response) => {
      if (response.status !== 200) {
        await response.data;
        return Promise.reject();
      }
      return response.data;
    }).then((alreadyExistsForConfiguration) => {
      dispatch({
        type: types.EXISTING_FOR_CONFIGURATION,
        payload: alreadyExistsForConfiguration,
      });
      return alreadyExistsForConfiguration;
    }).catch(() => {
      // TODO: error handling
    });
  };
};

export const checkIfImpactedOrderLines = (id) => {
  return async (dispatch, getState) => {
    const { codeProduct, countries } = getState();

    const country = countries.find(c => c.code === codeProduct.country);
    const formattedProduct = {
      [true]: () => get3PProductFields(codeProduct),
      [codeProduct.codeProductType === CodeProductTypes.SUBSCRIPTION.code]:
        () => getSubscriptionProductFields(codeProduct),
      [codeProduct.codeProductType === CodeProductTypes.MONEY.code]:
        () => getMoneyProductFields(codeProduct, country),
    }.true();

    const url = `/venom/api/codeProducts/unsupportedOrders/${id}`;

    return axios.put(url, {
      ...formattedProduct,
    }).then(async (response) => {
      if (response.status !== 200) {
        return Promise.reject();
      }
      return response.data;
    }).then((impactedOrderLines) => {
      dispatch({ type: types.SET_IMPACTED_ORDERLINES, payload: impactedOrderLines });
      return impactedOrderLines;
    }).catch(() => {
      // TODO: error handling
    });
  };
};

export const saveProduct = () => {
  return async (dispatch, getState) => {
    dispatch(beginSaveCodeProduct());
    const { codeProduct, countries } = getState();

    const country = countries.find(c => c.code === codeProduct.country);
    const formattedProduct = {
      [true]: () => get3PProductFields(codeProduct),
      [codeProduct.codeProductType === CodeProductTypes.SUBSCRIPTION.code]:
        () => getSubscriptionProductFields(codeProduct),
      [codeProduct.codeProductType === CodeProductTypes.MONEY.code]:
        () => getMoneyProductFields(codeProduct, country),
    }.true();

    const url = codeProduct.voucherCatalogId
      ? `/venom/api/codeProducts/${codeProduct.voucherCatalogId}`
      : '/venom/api/codeProducts';

    const response = codeProduct.voucherCatalogId
      ? await axios.put(url, {
        ...formattedProduct,
      })
      : await axios.post(url, {
        ...formattedProduct,
      });

    const newProduct = await response.data;

    if (newProduct.detailMessages && newProduct.detailMessages[0].includes('already in use by another code product')) {
      return { ...newProduct, duplicate: true };
    }

    const updatedProduct = newProduct.codeProductType === 'PSPLUS' || newProduct.codeProductType === 'PSNOW'
      ? {
        ...newProduct,
        codeProductType: CodeProductTypes.SUBSCRIPTION.code,
        subscriptionType: newProduct.codeProductType,
      } : newProduct;

    dispatch(saveCodeProductSuccess(updatedProduct));
    dispatch({ type: types.GET_PRODUCT, payload: updatedProduct });

    return newProduct;
  };
};
