export default (searchTerm) => {
  if (!searchTerm) {
    return undefined;
  }

  // Decode any encoded URI characters
  const decodedTerm = decodeURIComponent(searchTerm);
  // Replace URL's formatting of using +'s for spaces
  const replacedSearchTerm = decodedTerm.replace(/\+/g, ' ');

  return replacedSearchTerm;
};
