import axios from 'axios';
import * as types from './actionTypes';

export function finishLoadCallbackGateways(callbackGateways) {
  return { type: types.SET_CALLBACK_GATEWAYS, callbackGateways };
}

export function loadCallbackGateways() {
  const url = '/venom/api/voucherRedemptionCallbackGateways';
  return dispatch => axios.get(url)
    .then(response => response.data)
    .then((response) => {
      dispatch(finishLoadCallbackGateways(response));
    })
    .catch((error) => {
      dispatch(finishLoadCallbackGateways([]));
      console.error(error);
    });
}
