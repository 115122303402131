import { Container, Layout } from '@partner-global-ui/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import image from '../assets/Astro_sitting_playing_360.png';
import './Error.scss';

const CanceledTos = () => {
  const { t } = useTranslation();

  return (
    <Container id="error-page" className="error-page">
      <div className="canceled-tos-content">
        <Layout>
          <p>{t('msg_codes_termsOfService_redirect_body')} <Link to="/tos">{t('msg_codes_termsOfService')}</Link>.</p>
          <img src={image} alt="Astro-sitting-playing-360" className="astro-sitting-playing-360" />
        </Layout>
      </div>
    </Container>
  );
};

export default CanceledTos;
