import axios from 'axios';
import i18n from 'i18next';
import * as types from './actionTypes';
import { autoHide, timeout } from '../constants/notifications.constant';


export function beginLoadVenomJobs() {
  return { type: types.BEGIN_LOAD_VENOM_JOBS };
}

export function finishLoadVenomJobs() {
  return { type: types.FINISH_LOAD_VENOM_JOBS };
}

export function updateVenomJobs(data) {
  return { type: types.UPDATE_VENOM_JOB_STATUS, venomJob: data };
}

export function beginLoadVIDSJobs() {
  return { type: types.BEGIN_LOAD_VIDS_JOBS };
}

export function finishLoadVIDSJobs() {
  return { type: types.FINISH_LOAD_VIDS_JOBS };
}

export function updateVIDSJobs(data) {
  return { type: types.UPDATE_VIDS_JOB_STATUS, vidsJob: data };
}

export function loadVenomJobs(
  sort = { sortBy: 'jobCode', sortDir: 'asc' },
  search = '',
) {
  const sortParam = sort && `&sort=${sort.sortBy},${sort.sortDir}`;
  const searchParam = search && `&search=${encodeURIComponent(search)}`;
  const url = `venom/api/venomJobs?${sortParam}${searchParam}`;

  return (dispatch) => {
    dispatch(beginLoadVenomJobs());
    return axios.get(url)
      .then((response) => {
        return response;
      }).then(({ data }) => {
        dispatch({
          type: types.LOAD_VENOM_JOBS,
          venomjobs: data,
        });
        dispatch(finishLoadVenomJobs());
      }).catch(() => {
        dispatch(finishLoadVenomJobs());
      });
  };
}

export function changeVenomJobStatus(id, status, revision, notificationContext) {
  const url = `venom/api/venomJobs/${id}`;
  const eTag = `"${revision}"`;
  const requestBody = {
    enabled: status,
  };

  return (dispatch) => {
    return axios.put(url,
      requestBody,
      {
        headers: {
          'if-match': eTag,
        },
      })
      .then((response) => {
        return response;
      }).then(({ data }) => {
        dispatch(updateVenomJobs(data));
        const toastMessage = status ? 'msg_codes_turn_on_job' : 'msg_codes_turn_off_job';
        notificationContext.dispatch({
          type: 'add',
          payload: {
            status: 'success',
            autoHide,
            timeout,
            message: i18n.t(toastMessage, {
              jobName: id,
              interpolation: {
                escapeValue: false,
              },
            }),
            testId: 'success',
          },
        });
      }).catch((error) => {
        notificationContext.dispatch({
          type: 'add',
          payload: {
            status: 'error',
            autoHide,
            timeout,
            message: i18n.t('msg_codes_job_update_error'),
            testId: 'error',
          },
        });
        console.error(error);
      });
  };
}

export function loadVIDSJobs(
  sort = { sortBy: 'jobCode', sortDir: 'asc' },
  search = '',
) {
  const sortParam = `&sort=${sort.sortBy},${sort.sortDir}`;
  const searchParam = search && `&search=${encodeURIComponent(search)}`;
  const url = `venom/api/warehouseJobs?${sortParam}${searchParam}`;

  return (dispatch) => {
    dispatch(beginLoadVIDSJobs());
    return axios.get(url)
      .then((response) => {
        return response;
      }).then(({ data }) => {
        dispatch({
          type: types.LOAD_VIDS_JOBS,
          vidsJobs: data,
        });
        dispatch(finishLoadVIDSJobs());
      }).catch(() => {
        dispatch(finishLoadVIDSJobs());
      });
  };
}

export function changeVIDSJobStatus(id, status, revision, notificationContext) {
  const url = `venom/api/warehouseJobs/${id}`;
  const eTag = `"${revision}"`;
  const requestBody = {
    enabled: status,
  };

  return (dispatch) => {
    return axios.put(url,
      requestBody,
      {
        headers: {
          'if-match': eTag,
        },
      })
      .then((response) => {
        return response;
      }).then(({ data }) => {
        dispatch(updateVIDSJobs(data));
        const toastMessage = status ? 'msg_codes_turn_on_job' : 'msg_codes_turn_off_job';
        notificationContext.dispatch({
          type: 'add',
          payload: {
            status: 'success',
            autoHide,
            timeout,
            message: i18n.t(toastMessage, {
              jobName: id,
              interpolation: {
                escapeValue: false,
              },
            }),
            testId: 'success',
          },
        });
      }).catch(() => {
        notificationContext.dispatch({
          type: 'add',
          payload: {
            status: 'error',
            autoHide,
            timeout,
            message: i18n.t('msg_codes_job_update_error'),
            testId: 'error',
          },
        });
      });
  };
}
