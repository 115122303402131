import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function voucherBatchesReducer(state = initialState.voucherBatchCatalogs, action) {
  switch (action.type) {
    case types.FINISH_FILTER_VOUCHER_BATCHES:
      return [...action.voucherBatchCatalogs];
    case types.FINISH_LOAD_VOUCHER_BATCHES:
      return [...action.voucherBatchCatalogs];
    case types.FINISH_LOAD_SELECT_VOUCHER_BATCHES:
      return state.map((batch) => {
        const found = action.voucherBatches.find(b => b.voucherBatchId === batch.voucherBatchId);
        return found || batch;
      });
    default:
      return state;
  }
}
