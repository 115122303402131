import * as types from './actionTypes';
import { productReasons } from '../constants/autocanceledReasons.constants';

const getLocalStorageKey = (userId) => {
  return `removedLineItemCache-${userId}`;
};

export function filterSeenDeletedOrderLines(deletedLineItems = [], seenLines = new Set()) {
  const reasons = Object.keys(productReasons);

  return deletedLineItems.filter((deletedLineItem) => {
    return !seenLines.has(deletedLineItem.orderLineId)
      || reasons.includes(deletedLineItem.autoCancelReason);
  });
}

export function addRemovedLineItems(lineItems = []) {
  return (dispatch, getState) => {
    // scoped to user
    const { removedOrderLines, user: { userId } } = getState();
    // creates new Set of notified order line item ids
    const newLineItemsSet = lineItems.reduce((previousLineItems, lineItem) => {
      return previousLineItems.add(lineItem.orderLineId);
    }, removedOrderLines);
    // stores list of ids in local storage
    localStorage.setItem(getLocalStorageKey(userId), JSON.stringify([...newLineItemsSet]));
    dispatch({ type: types.ADD_REMOVED_ORDER_LINES_TO_CACHE, lineItems: newLineItemsSet });
  };
}

export function loadRemovedOrderLineCache() {
  return (dispatch, getState) => {
    // scoped to user
    const { user: { userId } } = getState();
    const removedLineItemCache = new Set(
      JSON.parse(localStorage.getItem(getLocalStorageKey(userId))),
    );
    dispatch({ type: types.LOAD_ORDER_LINE_CACHE, removedLineItems: removedLineItemCache });
  };
}
