import React from 'react';
import PropTypes from 'prop-types';
import {
  TableRow,
  TableCell,
  Icon,
  Truncate,
} from '@partner-global-ui/components';
import moment from 'moment';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { formatOrderHistoryComment } from '../../../utils/parterMerge';
import { historyDisplayStatuses } from '../../../constants/order.constants';

function OrderHistoryLine({
  line,
  rowKey,
  toggleComment,
  showComment,
}) {
  const { t } = useTranslation();

  // Find row's key in showComment
  const showCommentKeyValue = Object
    .keys(showComment)
    .some(key => parseInt(key, 10) === rowKey);
  // If row's key does not exist on showComment, display comment. Else use
  //  the row's display boolean value from showComment.
  const displayComment = (!showCommentKeyValue ? true : showComment[rowKey]) && line.status !== 'PARTNER_MERGE';

  const cellKey = rowKey + 1;
  const isTruncated = document.getElementById(`truncate-${cellKey}`) && document.getElementById(`truncate-${cellKey}`).clientHeight;
  const isStatusLabel = (line.comment || line.batchControlLabel) && line.status !== 'PARTNER_MERGE';

  const renderStatus = () => {
    if (line.status === 'PARTNER_MERGE') {
      return formatOrderHistoryComment(t, line.status, line.comment, line.commentVariables);
    }
    // Find status in constant
    const statusDisplayValue = historyDisplayStatuses
      .find(displayStatus => displayStatus.value === line.status);

    // If status is found, set display value to the status' pretty value.
    //  Otherwise, display status on the order history object.
    const status = typeof statusDisplayValue !== 'undefined'
      ? t(statusDisplayValue.translationStringId)
      : line.status;

    return status;
  };

  const iconClasses = classNames(
    'status-icon',
    {
      rotate: !displayComment,
    },
  );

  const commentTooltipContent = (
    <div className="order-history-comment-tooltip">
      {formatOrderHistoryComment(t, line.status, line.comment, line.commentVariables)}
    </div>
  );

  return (
    <TableRow
      id={`row-id-${rowKey}`}
      key={`row-key-${rowKey}`}
    >
      <TableCell id={`date-${cellKey}`} key={`date-${cellKey}`}>
        {moment(line.creationDate).utc().format('hh:mm a MM/DD/YYYY')}
      </TableCell>
      <TableCell
        id={`status-${cellKey}`}
        key={`status-${cellKey}`}
        onClick={() => toggleComment(rowKey)}
      >
        <div className={isTruncated && 'status-wrap'}>
          <div>
            <span data-testid="order-history-status">
              {renderStatus(line)}
            </span>
            {isStatusLabel && (
              <span className={iconClasses} data-testid="status-arrow-icon">
                <Icon size={12}>ico-select-arrow</Icon>
              </span>
            )}
          </div>
          {displayComment && (
            <>
              {line.comment && (
                <div data-testid="order-history-comment">
                  <Truncate
                    id={`truncate-${cellKey}`}
                    height={20}
                    content={commentTooltipContent}
                  >
                    {formatOrderHistoryComment(t, line.status, line.comment, line.commentVariables)}
                  </Truncate>
                </div>
              )}
              {line.batchControlLabel && (
                <div data-testid="order-history-batch-control-label">
                  {t('msg_codes_orderListAdmin_batchControlLabel_dropdown')}: {line.batchControlLabel}
                </div>
              )}
            </>
          )}
        </div>
      </TableCell>
      <TableCell id={`createdBy-${cellKey}`} key={`createdBy-${cellKey}`}>
        {line.byUser === 'System' ? t('msg_codes_users_system') : line.byUser}
      </TableCell>
    </TableRow>
  );
}

OrderHistoryLine.propTypes = {
  line: PropTypes.object,
  rowKey: PropTypes.number,
  toggleComment: PropTypes.func,
  showComment: PropTypes.object,
};

OrderHistoryLine.defaultProps = {
  line: {},
  rowKey: 0,
  toggleComment: () => {},
  showComment: {},
};

export default OrderHistoryLine;
