import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function callbackGatewaysReducer(state = initialState.callbackGateways, action) {
  const { type, callbackGateways = [] } = action;
  if (type === types.SET_CALLBACK_GATEWAYS) {
    const newCallbackGateways = callbackGateways.reduce((hash, gateway) => {
      return { ...hash, [gateway.gatewayCode]: gateway.name };
    }, {});
    return { isLoaded: true, callbackGateways: newCallbackGateways };
  }
  return state;
}
