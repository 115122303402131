import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container } from '@partner-global-ui/components';
import PropTypes from 'prop-types';
import {
  useHistory,
  useLocation,
} from 'react-router-dom';
import { changeProp, changePropOnLine, newOrder } from '../../../actions/orderActions';
import { loadPartners } from '../../../actions/partnerActions';
import Nav from './Nav';
import Steps from './Steps';
import OrderInfo from './OrderInfo';
import OrderContents from './OrderContents';
import OrderDocuments from './OrderDocuments/OrderDocuments';
import OrderHistoryNew from '../OrderHistory/OrderHistoryNew';
import OnNavigateAway from '../../thirdPartyCatalog/shared/OnNavigateAway';
import './agencyOrders.scss';

const AgencyOrder = ({
  pageRef,
}) => {
  const {
    triggerInitialOrder,
    loadingOrder,
    order,
    partners,
    partner,
    orderForm,
    user,
  } = useSelector(state => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [initialLoad, setInitialLoad] = useState(false);
  const [readyToAdd, setReadyToAdd] = useState(false);

  const handleChangeProp = (e) => {
    if (typeof e.persist === 'function') {
      e.persist();
    }
    changeProp(e.target.name, e.target.value)(dispatch);
  };

  useEffect(() => {
    // Fetch only the integrator parters to populate the Partner Type select
    const partnerType = 'INTEGRATOR';
    dispatch(loadPartners(
      1000,
      undefined,
      undefined,
      {},
      partnerType,
    ));
  }, []);

  const newOrderOnNavigate = (newLocation) => {
    if (!newLocation.pathname.includes('add-lines')) {
      newOrder()(dispatch);
    }
  };

  const changeLineProp = (e, line) => {
    if (e.preventDefault) {
      e.preventDefault();
    }
    if (e.persist) {
      e.persist();
    }

    // prevent user from entering price lower than 0.0001
    if (e.target.name === 'cardManufacturePrice' && e.target.value < 0) {
      return false;
    }

    dispatch(changePropOnLine(
      line,
      e.target.name,
      e.target.name === 'branded' ? e.target.value.value : e.target.value,
    ));

    return true;
  };

  // checks to see if the orderDocument have changed

  return (
    <>
      <Steps order={order} />
      <Nav
        order={order}
        setReadyToAdd={setReadyToAdd}
        initialLoad={initialLoad}
        setInitialLoad={setInitialLoad}
        triggerInitialOrder={triggerInitialOrder}
        pageRef={pageRef}
      />
      <Container id="agency-order-detail" className="order agency-order-page">
        <OrderInfo
          loadingOrder={loadingOrder}
          order={order}
          orderForm={orderForm}
          partners={partners}
          partner={partner}
          user={user}
          onChange={handleChangeProp}
        />
        <OrderContents
          order={order}
          disabled={!readyToAdd}
          changeProp={changeLineProp}
          name="lineItems"
        />
        <OrderDocuments order={order} />
        {order.orderHistory && order.orderHistory.length > 0
          ? <OrderHistoryNew name="orderHistory" order={order} />
          : null
        }
      </Container>
      <OnNavigateAway
        isBlocked={order.orderModified}
        history={history}
        location={location}
        onNavigateFunction={newOrderOnNavigate}
      />
    </>
  );
};

AgencyOrder.propTypes = {
  pageRef: PropTypes.object,
};

AgencyOrder.defaultProps = {
  pageRef: {},
};

export default AgencyOrder;
