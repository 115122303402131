import React, { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Icon, TableRow, TableCell, Truncate,
} from '@partner-global-ui/components';
import {
  Link,
} from 'react-router-dom';
import getRegionName from '../../../utils/region.utils';

import {
  getProductType,
  getPlatform,
} from '../../../constants/thirdPartyCatalog.constants';
import ExpandedCartRow from './expandedCartRow';

const cartReducer = (state, action) => {
  switch (action.type) {
    case 'toggle':
      return !state;
    case 'set':
      return action.isExpanded;
    default:
      return state;
  }
};
function CartRow({
  product: {
    codeProduct,
    productId,
    voucherCatalogId,
    estimatedReleaseDate,
    productTypeCode,
    platform,
    cartId,
  },
  product,
  setCartProducts,
  setPoFileAddedNotSubmitted,
  setDisableSubmitButton,
  onTrashClick,
  tableRef,
}) {
  const [isExpanded, localDispatch] = useReducer(cartReducer, false);
  useEffect(() => {
    localDispatch({
      type: 'set',
      isExpanded: false,
    });
  }, [product.productId]);
  const { t } = useTranslation();
  const rowClick = () => {
    return () => {
      localDispatch({ type: 'toggle' });
    };
  };

  return (
    <TableRow
      key={cartId}
      id={cartId}
      pad
      onClick={rowClick(isExpanded)}
      selected={isExpanded}
      expanded={isExpanded}
      expandable={product !== null}
      expandedContent={(
        <ExpandedCartRow
          product={product}
          setCartProducts={setCartProducts}
          setPoFileAddedNotSubmitted={setPoFileAddedNotSubmitted}
          setDisableSubmitButton={setDisableSubmitButton}
          isCartRowView
          tableRef={tableRef}
        />
      )}
      data-testid="cart-row-container"
    >
      <TableCell id={`codeProductName-${cartId}`} key={`${cartId}-codeProductName`} sortable data-testid="cart-row-product-name" className="cart-row-product-name">
        <span>
          <Truncate content={codeProduct}>
            <Link className="code-product-link" to={`/codeProduct/${voucherCatalogId}`}>{codeProduct}</Link>
          </Truncate>
        </span>
      </TableCell>
      <TableCell id={`productId-${cartId}`} key={`${cartId}-productId`} sortable data-testid="cart-row-productId">{productId}</TableCell>
      <TableCell id={`productType-${cartId}`} key={`${cartId}-productType`} sortable data-testid="cart-row-cart-id">
        {t(getProductType(productTypeCode))}
      </TableCell>
      <TableCell id={`platform-${cartId}`} key={`${cartId}-platform`} sortable data-testid="cart-row-platform">
        {t(getPlatform(platform))}
      </TableCell>
      <TableCell
        id={`registrationRegion-${cartId}`}
        key={`${cartId}-registrationRegion`}
        data-testid="cart-row-registration-region"
        className="registrationRegion"
      >
        {t(getRegionName(product.availabilityRegion))}
      </TableCell>
      <TableCell id={`estimatedReleaseDate-${cartId}`} key={`${cartId}-estimatedReleaseDate`} sortable data-testid="cart-row-estimated-release-date">{estimatedReleaseDate ? moment.utc(estimatedReleaseDate).format('YYYY/MM/DD') : t('msg_codes_codes_status_notAvailable')}</TableCell>
      <TableCell
        key={`${cartId}-cartRowDelete`}
        className="cart-row-delete"
        name="cart-row-delete"
        onClick={() => onTrashClick(cartId)}
        id={`cart-row-delete-${cartId}`}
        data-testid="cart-row-delete"
      >
        <div
          className="trash-icon-wrap"
          name="trash-icon-wrap"
        >
          <Icon
            name="trash-icon"
            className="trash-icon"
          >
            ico-trashcan
          </Icon>
        </div>
      </TableCell>
    </TableRow>
  );
}

CartRow.propTypes = {
  product: PropTypes.object,
  onTrashClick: PropTypes.func,
  setCartProducts: PropTypes.func,
  setPoFileAddedNotSubmitted: PropTypes.func,
  setDisableSubmitButton: PropTypes.func,
  tableRef: PropTypes.object,
};

CartRow.defaultProps = {
  product: {},
  onTrashClick: () => {},
  setCartProducts: () => {},
  setPoFileAddedNotSubmitted: () => {},
  setDisableSubmitButton: () => {},
  tableRef: {},
};

export default CartRow;
