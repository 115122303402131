import axios from 'axios';
import * as types from './actionTypes';
import handleSaveClick from './agreementFormHandleSave';
import { generateAgreementPut } from './agreementFormActions';
import * as agreementTypeConstant from '../constants/agreementType.constants';

export function checkAgreement(
  notificationContext,
  oldAgreementForm,
  navigate,
  shouldSave,
  warnOrderAffected,
  setShowWarningModal,
) {
  const {
    latestFinalEffectiveDate,
    agreement: { agreementId, agreementType }, eTag, isNewAgreement,
  } = oldAgreementForm;

  const agreementCheck = isNewAgreement
    && agreementType !== agreementTypeConstant.AGENCY
    && latestFinalEffectiveDate !== '';
  const agreementToSend = generateAgreementPut(oldAgreementForm);

  return ((dispatch) => {
    if (agreementCheck && !warnOrderAffected) {
      dispatch(handleSaveClick(notificationContext, oldAgreementForm, navigate, shouldSave));
    } else {
      return axios.put(
        `/venom/api/agreements/unsupportedOrders/${agreementId}`,
        agreementToSend,
        { headers: { 'if-match': eTag } },
      )
        .then(response => response.data)
        .then((orderLines) => {
          if (orderLines.length > 0 && shouldSave) {
            dispatch({ type: types.SELECTED_ORDERLINES_LIST, orderLines });
            if (typeof setShowWarningModal === 'function') {
              setShowWarningModal(true);
            }
            return { removedCountries: true };
          }
          dispatch({ type: types.NO_ORDERS_AFFECTED });
          return { removedCountries: false };
        })
        .then(async ({ removedCountries }) => {
          if (!removedCountries) {
            dispatch(handleSaveClick(notificationContext, oldAgreementForm, navigate, shouldSave));
          }
        });
    }
    return null;
  });
}

export default checkAgreement;
