export const allSearchOption = { value: 'all', label: 'msg_codes_filter_all' };

export const thirdPartyCatalogSearchOptions = [
  { value: 'conceptName', label: 'msg_codes_filter_conceptName' },
  { value: 'productGroupName', label: 'msg_codes_codes_productName' },
  { value: 'productId', label: 'msg_codes_codes_productID' },
  // TODO: Uncomment option when search by SKU ID is supported by API
  // { value: 'skuId', label: 'msg_codes_orderList_skuid' },
];

export const thirdPartyOrderHistorySearchOptions = [
  { value: 'orderId', label: 'msg_codes_codes_orderID' },
  { value: 'conceptName', label: 'msg_codes_filter_conceptName' },
  { value: 'productName', label: 'msg_codes_codes_productName' },
  { value: 'productId', label: 'msg_codes_codes_productID' },
  { value: 'skuId', label: 'msg_codes_orderList_skuid' },
];
