import il8n from 'i18next';

export const SUCCESS = il8n.t('msg_codes_banner_agreementSaved');
export const NO_SAVE = il8n.t('msg_codes_toast_agreementNotSaved');
export const ERROR = il8n.t('msg_codes_codes_banner_somethingWentWrong');

export const baseStrings = {
  SUCCESS: 'msg_codes_banner_agreementSaved',
  NO_SAVE: 'msg_codes_toast_agreementNotSaved',
  ERROR: 'msg_codes_codes_banner_somethingWentWrong',
};
