import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  Layout,
  Flex,
  Collapsible,
  Select,
  Divider,
  RadioGroup,
} from '@partner-global-ui/components';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Agreements.scss';
import PropTypes from 'prop-types';
import moment from 'moment';

import ProductSelector from './agreementVersionComponent/ProductSelector';
import * as AgreementTypes from '../../constants/agreementType.constants';
import { DRAFT, ABANDONED } from '../../constants/agreementState.constants';
import * as ContractingSieRegions from '../../constants/contractingSieRegion.constants';
import * as CallbackGateways from '../../constants/callbackGateways.constants';
import * as agreementActions from '../../actions/agreementFormActions';
import * as partnerActions from '../../actions/partnerActions';
import AgreementVersion from './agreementVersionComponent/agreementVersionComponent';
import FirstPartyVoucherCoverageTable from './firstPartyVoucherCoverageComponent/FirstPartyVoucherCoverageTable';
import AgencyVoucherCoverageTable from './agencyVoucherCoverageComponent/AgencyVoucherCoverageTable';
import generateCountriesCoverage from '../../utils/generateCountriesCoverage';
import {
  firstPartyInvoiceTimingRequired,
  firstPartyHasFeeStructure,
} from '../../utils/isValidAgreementForm';
import hasPermission from '../../utils/accessControl/hasPermission';
import roleKeys from '../../utils/accessControl/roleKeys';

const AgreementForm = ({
  editable,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const callbackGatewaysOptions = [
    { label: 'No Gateway', value: 'NO_GATEWAY' },
    { label: 'Blackhawk', value: 'BLACKHAWK' },
    { label: 'epay', value: 'EPAY' },
    { label: 'InComm', value: 'INCOMM' },
  ];

  const invoiceTimingOptions = [
    { label: t('msg_codes_3pagreements_onDelivery'), value: 'ON_DELIVERY' },
    { label: t('msg_codes_3pagreements_onCodeSale'), value: 'ON_CODE_SALE' },
    { label: t('msg_codes_3pagreements_onRedemption'), value: 'ON_REDEMPTION' },
    { label: t('msg_codes_3pagreements_onFinancialcycle'), value: 'ON_FINANCIAL_CYCLE' },
  ];

  const productsOfTypeOptions = [
    {
      label: t('msg_codes_voucherType_money_lowerCase'),
      value: 'MONEY',
      name: 'Money',
      code: 'MONEY',
      id: 0,
    },
    {
      label: t('msg_codes_voucherType_psNow_lowerCase'),
      value: 'PSNOW',
      name: 'PS Now',
      code: 'PSNOW',
      id: 1,
    },
    {
      label: t('msg_codes_voucherType_psPlus_lowerCase'),
      value: 'PSPLUS',
      name: 'PS Plus',
      code: 'PSPLUS',
      id: 2,
    },
  ];

  const [hasMadePartnersCall, setHasMadePartnersCall] = useState(false);
  const [radio, setRadio] = useState();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const state = useSelector(globalState => globalState);
  const {
    partners,
    agreementForm: {
      isNewVersion,
      agreement: {
        agreementVersions,
        partner: selectedPartner,
        partnerId: selectedPartnerId,
        region: selectedRegion,
        agreementType,
      },
      draftAgreementVersion: selectedAgreementVersion,
      isNewAgreement,
      initialStatuses: { versionStatus },
      initialStatuses,
      agreement,
      canEditVersion,
    },
    countries,
    currencies,
  } = state;

  const isFirstVersion = agreementVersions.length === 1 && !isNewVersion;

  const agreementTypeOptions = [
    {
      label: t(AgreementTypes.AGENCY),
      value: 'AGENCY',
    },
    {
      label: t(AgreementTypes.FIRST_PARTY_PUBLISHER),
      value: 'FIRST_PARTY_PUBLISHER',
    },
  ];

  const showAgreementVersionDetails = hasPermission(
    state, roleKeys.agreement.showAgreementVersionDetails,
  );

  const partnerOptions = partners
    .filter(partner => partner.partnerType === 'INTEGRATOR')
    .map((partner) => {
      return {
        ...partner,
        label: partner.name,
        value: partner.partnerId,
      };
    });
  const selectedPartnerOption = partnerOptions
    .find(partner => partner.partnerId === selectedPartnerId);

  const selectedInvoiceTimingOption = invoiceTimingOptions
    .find(option => option.value === selectedAgreementVersion.invoiceTiming);

  const contractingSieRegionsOptions = Object.values(ContractingSieRegions)
    .map(((region) => {
      return {
        ...region,
        label: region.name,
        value: region.code,
      };
    }));
  const selectedSieRegion = contractingSieRegionsOptions
    .find(regionOption => regionOption.code === selectedRegion);

  const selectedAgreementType = agreementTypeOptions
    .find(aType => aType.value === agreementType);

  const isAgreementInfoEditable = editable
    && (isNewAgreement
      || (isFirstVersion && versionStatus === DRAFT));

  const { creationDate = '' } = agreement;

  const canEditAgreementVersion = agreement.status !== 'ACTIVE'
  && selectedAgreementVersion.versionStatus !== 'FINAL'
    ? canEditVersion
    : true;

  const selectedCallbackGatewaysOption = callbackGatewaysOptions
    .find(option => option.value === selectedAgreementVersion.voucherRedemptionCallbackGateway);


  const loadIntegragorParters = () => {
    // Fetch only the integrator parters to populate the Partner Type select
    //  since agreements for Third Party Partners cannot be created manually.
    const statusFilter = { value: 'ACTIVE' };
    const partnerType = 'INTEGRATOR';
    dispatch(partnerActions.loadPartners(
      1000,
      undefined,
      undefined,
      { status: [statusFilter] },
      partnerType,
    ));

    setHasMadePartnersCall(true);
  };

  useEffect(() => {
    // Fetch Integrator Partners on page load if creating new agreement.
    if (isNewAgreement) {
      loadIntegragorParters();
    }
  }, []);

  useEffect(() => {
    // Fetch Integrator Partners when local
    // isAgreementInfoEditable is updated when loading existing agreement.
    if (isAgreementInfoEditable && !hasMadePartnersCall) {
      loadIntegragorParters();
    }
  }, [isAgreementInfoEditable, hasMadePartnersCall]);

  const handleEdit = (e) => {
    const { target: { name, value } } = e;
    if (!isNewAgreement && !isFirstVersion) return;
    dispatch(agreementActions.updateAgreementProp(name, value));
  };

  const handlePartner = (e) => {
    const { target: { name, value } } = e;
    const newEvent = { target: { name, value: value.partnerId !== '' ? Number(value.partnerId) : value.partnerId } };
    handleEdit(newEvent);
  };

  const handleRegion = (e) => {
    const { target: { name, value: { value } } } = e;
    if (!isNewAgreement && !isFirstVersion) return;
    dispatch(agreementActions.updateAgreementProp(name, value));
  };

  const handleAgreement = (e) => {
    const { target: { name, value: { value } } } = e;
    if (!isNewAgreement && !isFirstVersion) return;
    dispatch(agreementActions.updateAgreementProp(name, value));
  };

  const agreementStatusEnabled = () => {
    if (editable) {
      if (!isFirstVersion) {
        return true;
      }
      if (
        (isNewAgreement
          || (initialStatuses.versionStatus === DRAFT && !isNewVersion))
        || initialStatuses.versionStatus === ABANDONED
      ) {
        return false;
      }
      return true;
    }
    return false;
  };

  const handleCallbackGateway = (e) => {
    const { target: { name, value: { value } } } = e;

    if (name === 'versionStatus') {
      dispatch(
        agreementActions.updateDraftAgreementVersion({ [name]: value }, agreementStatusEnabled()),
      );
    } else if (name === 'voucherRedemptionCallbackGateway') {
      const voucherRedemptionCallbackMandatory = null;
      setRadio(null);
      dispatch(
        agreementActions.updateDraftAgreementVersion({
          [name]: value,
          voucherRedemptionCallbackMandatory,
        }),
      );
    } else {
      dispatch(
        agreementActions.updateDraftAgreementVersion({ [name]: value }),
      );
    }
  };

  const renderCallbackGateway = useCallback(() => {
    return (
      <Select
        label={t('msg_codes_callbackGateway')}
        name="voucherRedemptionCallbackGateway"
        id="voucherRedemptionCallbackGateway"
        required
        options={callbackGatewaysOptions}
        disabled={!(canEditVersion && editable)}
        onChange={handleCallbackGateway}
        value={selectedCallbackGatewaysOption}
        placeholder={t('msg_codes_common_select')}
      />
    );
  }, [canEditVersion, editable, callbackGatewaysOptions, selectedCallbackGatewaysOption]);

  const handleGroupRadioChange = (e) => {
    setRadio(e.target.value);
    const selectedValue = e.target.value === 'true'
      ? true
      : e.target.value === 'false'
        ? false
        : undefined;

    dispatch(
      agreementActions
        .updateDraftAgreementVersion({ voucherRedemptionCallbackMandatory: selectedValue }),
    );
  };

  const handleProductUpdate = (allProducts, selected) => {
    const notSelected = allProducts.filter((allOption) => {
      return !selected.map(option => option.value).includes(allOption.value);
    });

    selected.forEach((product) => {
      if (!allProducts.find(type => type === product.code)) {
        dispatch(
          agreementActions.addEmptyVoucherProductType(product.code),
        );
      }
    });

    notSelected.forEach((option) => {
      dispatch(
        agreementActions.removeVoucherProductType(option.code),
      );
    });
  };

  const removeProductType = (productType) => {
    const updatedSelectedProducts = selectedProducts
      .filter(product => product.value !== productType);
    setSelectedProducts(updatedSelectedProducts);
    handleProductUpdate(productsOfTypeOptions, updatedSelectedProducts);
  };

  const handleInvoiceTiming = (e) => {
    const { target: { name, value: { value } } } = e;

    if (name === 'versionStatus') {
      dispatch(
        agreementActions.updateDraftAgreementVersion({ [name]: value }, agreementStatusEnabled()),
      );
    } else if (name === 'voucherRedemptionCallbackGateway') {
      const voucherRedemptionCallbackMandatory = null;
      setRadio(null);
      dispatch(
        agreementActions.updateDraftAgreementVersion({
          [name]: value,
          voucherRedemptionCallbackMandatory,
        }),
      );
    } else {
      dispatch(
        agreementActions.updateDraftAgreementVersion({ [name]: value }),
      );
    }
  };

  const renderCallbackGatewayMandatory = (agreementVersion, disabled) => {
    const {
      voucherRedemptionCallbackGateway,
    } = agreementVersion;

    if (voucherRedemptionCallbackGateway === '' || CallbackGateways[voucherRedemptionCallbackGateway] === CallbackGateways.NO_GATEWAY) {
      return null;
    }

    const callbackGatewayMandatoryOptions = [
      {
        label: t('msg_codes_common_yes'),
        value: 'true',
      },
      {
        label: t('msg_codes_cta_no'),
        value: 'false',
      },
    ];

    return (
      <div data-testid="voucherRedemptionCallbackMandatory-radio-group" className="voucherRedemptionCallbackMandatory-radio-group">
        <RadioGroup
          name="voucherRedemptionCallbackMandatory"
          id="voucherRedemptionCallbackMandatory"
          label={t('msg_codes_callbackGateway_label')}
          disabled={disabled}
          options={callbackGatewayMandatoryOptions}
          onChange={handleGroupRadioChange}
          value={radio !== null && radio !== undefined && radio.toString()}
          required
        />
      </div>
    );
  };

  const renderInvoiceTiming = useCallback((
    agreementParam, agreementVersion, canEditVersionParam,
  ) => {
    const { voucherProductTypeCoverageSet } = agreementVersion;
    const enabled = agreementParam.agreementType === 'FIRST_PARTY_PUBLISHER'
      ? canEditVersionParam && firstPartyInvoiceTimingRequired(voucherProductTypeCoverageSet)
      : canEditVersionParam;

    const showForFirstParty = agreementParam.agreementType === 'FIRST_PARTY_PUBLISHER'
      ? firstPartyHasFeeStructure(voucherProductTypeCoverageSet)
      : true;

    const showInvoiceTiming = agreementParam.agreementType !== ''
      && agreementParam.region !== ''
      && (agreementParam.partnerId !== '' || selectedPartner.name)
      && showForFirstParty
      && showAgreementVersionDetails;

    return showInvoiceTiming ? (
      <div data-testid="invoicetiming" className="invoicetiming">
        <Select
          label={t('msg_codes_invoice_timing')}
          name="invoiceTiming"
          id="invoiceTiming"
          required={enabled}
          options={invoiceTimingOptions}
          onChange={handleInvoiceTiming}
          disabled={!enabled}
          className="invoiceTiming"
          optionSelectValue=""
          value={selectedInvoiceTimingOption}
          placeholder={t('msg_codes_common_select')}
        />
      </div>
    ) : null;
  }, [agreement]);

  const renderCoverageComponents = () => {
    if (
      (AgreementTypes[agreementType] === AgreementTypes.FIRST_PARTY_PUBLISHER
        || agreementType.name === AgreementTypes.FIRST_PARTY_PUBLISHER)
      && agreement.region !== ''
    ) {
      return (<FirstPartyVoucherCoverageTable canEdit={editable} />);
    }
    if (
      (AgreementTypes[agreementType] === AgreementTypes.AGENCY)
      || (agreementType === 'AGENCY')
    ) {
      const coverages = generateCountriesCoverage(countries, 'agency', agreement.region, currencies);

      const result = selectedAgreementVersion.voucherProductTypeCoverageSet
        .map(product => (
          <AgencyVoucherCoverageTable
            key={product.voucherProductType}
            product={product}
            region={agreement.region}
            coverages={_.orderBy(coverages, 'country')}
            canEdit={editable}
            useActiveCurrencies={isNewAgreement || isNewVersion || canEditAgreementVersion}
            removeProductType={removeProductType}
          />
        ));

      // prevent coverage tables from swapping places when updated
      return _.orderBy(result, 'key');
    }

    return null;
  };

  return (
    <>
      <Layout id="agreement-information-layout" className="agreement-information-layout">
        <Flex id="agreement-information-flex" className="agreement-information-flex" colSpan="12">
          <Collapsible
            heading={t('msg_codes_agreements_agreementInformation')}
            id="agreement-information-collapsible"
            rightContent={
              !isNewAgreement
                ? t('msg_codes_orderList_createdOn', {
                  date: moment(creationDate).format('MM/DD/YYYY'),
                  interpolation: { escapeValue: false },
                })
                : ''
            }
            defaultIsOpen
          >
            <div data-testid="agreement-information-content" className="agreement-information-content">
              <Layout colSpan={12}>
                <Flex colSpan={5}>
                  <div data-testid="info-wrap" className="info-wrap">
                    <Select
                      id="partnerId"
                      label={t('msg_codes_agreements_contractingPartner')}
                      options={partnerOptions}
                      disabled={!isAgreementInfoEditable}
                      name="partnerId"
                      required
                      onChange={e => handlePartner(e)}
                      value={selectedPartnerOption}
                      placeholder={t('msg_codes_common_select')}
                    />
                    <Select
                      label={t('msg_codes_agreements_contractingSIEregion')}
                      name="region"
                      id="region"
                      required
                      options={contractingSieRegionsOptions}
                      disabled={!isAgreementInfoEditable}
                      onChange={handleRegion}
                      value={selectedSieRegion}
                      placeholder={t('msg_codes_common_select')}
                    />
                    <Select
                      label={t('msg_codes_agreements_agreementType')}
                      required
                      name="agreementType"
                      id="agreementType"
                      options={agreementTypeOptions}
                      onChange={handleAgreement}
                      disabled={!isAgreementInfoEditable}
                      value={selectedAgreementType}
                      placeholder={t('msg_codes_common_select')}
                    />
                    {renderCallbackGateway()}
                    {renderCallbackGatewayMandatory(
                      selectedAgreementVersion, !(canEditVersion && editable),
                    )}
                  </div>
                </Flex>
                <Flex offset={1} colSpan={1}>
                  <Divider vertical />
                </Flex>
                <Flex colSpan={5}>
                  <AgreementVersion
                    selectedPartner={selectedPartner}
                    canEdit={editable}
                    setRadio={setRadio}
                  />
                </Flex>
              </Layout>
            </div>
          </Collapsible>
        </Flex>
      </Layout>
      <Layout id="agreement-coverage-layout" className="agreement-coverage-layout">
        <Flex
          id="agreement-coverage-flex"
          className="agreement-coverage-flex"
          colSpan={12}
        >
          <Collapsible
            heading={t('msg_codes_agreements_voucheProductTypeCoverage')}
            id="agreement-coverage-content"
            defaultIsOpen
          >
            <div
              data-testid="invoice-timing-content"
              className="invoice-timing-content"
            >
              <Layout id="invoice-timing-layout" className="invoice-timing-layout">
                <Flex
                  id="product-selector"
                  className="product-selector"
                  offset={1}
                  colSpan={3}
                >
                  <ProductSelector
                    name="productSelector"
                    onProductUpdate={handleProductUpdate}
                    disabled={!canEditAgreementVersion}
                    showProductSelector={editable}
                    productsOfTypeOptions={productsOfTypeOptions}
                    selectedProducts={selectedProducts}
                    setSelectedProducts={setSelectedProducts}
                  />
                </Flex>
                <Flex offset={0} colSpan={3}>
                  {renderInvoiceTiming(
                    agreement,
                    selectedAgreementVersion,
                    (canEditVersion && editable),
                  )}
                </Flex>
              </Layout>
              {renderCoverageComponents(agreementType,
                (canEditVersion && editable))}
            </div>
          </Collapsible>
        </Flex>
      </Layout>
    </>
  );
};

AgreementForm.propTypes = {
  editable: PropTypes.bool,
};

AgreementForm.defaultProps = {
  editable: false,
};

export default withRouter(AgreementForm);
