import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Layout,
  Flex,
  Search,
} from '@partner-global-ui/components';
import * as voucherTypeActions from '../../actions/voucherTypeActions';

const Nav = ({ setPageNumber }) => {
  const search = useSelector(state => state.voucherTypesPage.search) || '';
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const searchVoucherTypes = useCallback((term) => {
    setPageNumber(0);
    dispatch(voucherTypeActions.setVoucherTypesSearch(term));
  }, [dispatch, search]);

  const clearSearch = useCallback(() => {
    dispatch(voucherTypeActions.clearVoucherTypesSearch());
  }, [dispatch, search]);

  return (
    <Layout id="voucherTypes-nav" name="voucherTypesNav" className="voucherTypes-nav">
      <span className="voucherTypes-nav-content">
        <Flex colSpan={3}>
          <h3 name="voucherTypes-title" className="voucherTypes-title">
            {t('msg_codes_voucher_types')}
          </h3>
        </Flex>
        <Flex offset={5} colSpan={3}>
          <Search
            search={searchVoucherTypes}
            onClearSearch={clearSearch}
            id="voucherTypes-searchInput"
            width={500}
            useClearIcon
            hideCategories
            placeholder={t('msg_codes_search_code_type_name_id')}
          />
        </Flex>
      </span>
    </Layout>
  );
};

Nav.propTypes = {
  setPageNumber: PropTypes.func,
};

Nav.defaultProps = {
  setPageNumber: () => {},
};

export default Nav;
