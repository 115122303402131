export default {
  DRAFT_ORDER: 'DRAFT_ORDER',
  APPROVED_ORDER: 'APPROVED_ORDER',
  CANCELLED_ORDER: 'CANCELLED_ORDER',
  REJECTED: 'REJECTED',
  PENDING_DRAFT_AMENDMENT: 'PENDING_DRAFT_AMENDMENT',
  SUBMITTED_PENDING_APPROVAL: 'SUBMITTED_PENDING_APPROVAL',
};

export const displayStatuses = [
  {
    value: 'APPROVED_ORDER',
    name: 'Approved',
    translationStringId: 'msg_codes_status_approved',
  },
  {
    value: 'SUBMITTED_PENDING_APPROVAL',
    name: 'Submitted',
    translationStringId: 'msg_codes_submitted',
  },
  {
    value: 'CANCELLED_ORDER',
    name: 'Cancelled',
    translationStringId: 'msg_codes_canceled',
  },
  {
    value: 'DRAFT_ORDER',
    name: 'Draft',
    translationStringId: 'msg_codes_agreementStatus_draft_lowerCase',
  },
  {
    value: 'PENDING_DRAFT_AMENDMENT',
    name: 'Amendment Requested',
    translationStringId: 'msg_codes_amendmentRequested',
  },
  {
    value: 'REJECTED',
    name: 'Rejected',
    translationStringId: 'msg_codes_orderlist_rejected',
  },
];

export const historyDisplayStatuses = [
  ...displayStatuses,
  { value: 'ACTIVATED', name: 'Activated', translationStringId: 'msg_codes_activation_activated' },
  { value: 'APPROVED', name: 'Approved', translationStringId: 'msg_codes_status_approved' },
  { value: 'CANCELLATION_ERROR', name: 'Cancellation Error', translationStringId: 'Cancellation Error' },
  { value: 'CANCELLED', name: 'Cancelled', translationStringId: 'msg_codes_canceled' },
  { value: 'DRAFT', name: 'Draft', translationStringId: 'msg_codes_agreementStatus_draft_lowerCase' },
  { value: 'DEACTIVATED', name: 'Deactivated', translationStringId: 'msg_codes_codes_status_deactivated' },
  { value: 'DEACTIVATING', name: 'Deactivating', translationStringId: 'Deactivating' },
  { value: 'USER_DELIVERED', name: 'Delivered', translationStringId: 'msg_codes_delivery_delivered' },
  { value: 'SFTP_DELIVERED', name: 'Delivered', translationStringId: 'msg_codes_delivery_delivered' },
  { value: 'FULFILLED', name: 'Fulfilled', translationStringId: 'msg_codes_fulfillment_fulfilled' },
  { value: 'SUBMITTED', name: 'Submitted', translationStringId: 'msg_codes_submitted' },
  { value: 'FEE_WAIVED_CONTRACTUAL', name: 'Fee-Waived-Contractual', translationStringId: 'msg_codes_fee_waived_contractual' },
  { value: 'FEE_WAIVED_BUSINESS_APPROVED', name: 'Fee-Waived-Busines', translationStringId: 'msg_codes_fee_waived_gpdr_business' },
  { value: 'PARTNER_MERGE', name: 'Partner Merge', translationStringId: 'msg_codes_merged_status_gpids' },
  { value: 'CODE_PRODUCT_SWAP', name: 'Code Product Swap', translationStringId: 'msg_codes_swap_code_product' },
];

export const thirdPartyVoucherCatalogTypes = [
  { value: 'PRODUCT', name: 'Product' },
];
