import moment from 'moment-timezone';
import { activitySelectTypes } from '../../../constants/activityType.constants';

export const isValidQuantity = (order, orderActivityRules) => {
  const selectedActivity = orderActivityRules[order.activity];
  const parsedQuantity = Number(order.quantity);
  if (typeof selectedActivity === 'undefined') {
    return false;
  }
  if (selectedActivity.fixedQuantity) {
    return selectedActivity.fixedQuantity === parsedQuantity;
  }

  return Number.isInteger(parsedQuantity)
    && parsedQuantity > 0
    && selectedActivity
    && parsedQuantity <= selectedActivity.quantityLimit;
};

export const isFreeAllocationActivityType = (activity) => {
  return activity && activity.value
  === activitySelectTypes.PUBLISHER_FREE_ALLOCATION.value;
};

export const isPaidAllocationActivityType = (activity) => {
  return activity && activity.value
  === activitySelectTypes.PUBLISHER_PAID_ALLOCATION.value;
};

export const isActivitySelected = (activity) => {
  return typeof activity !== 'undefined'
    && activity !== ''
    && activity !== activitySelectTypes.SELECT.value;
};

export const isValidDates = (order) => {
  const {
    startDate,
    startTime,
    endDate,
    endTime,
    timezone = { value: 'UTC' },
  } = order;
  const { value } = timezone;
  const formatter = 'YYYY-MM-DD h:m a';
  const startDateTime = startDate !== '' ? moment.tz(`${startDate} ${startTime}`, formatter, value) : moment.invalid();
  const endDateTime = endDate !== '' ? moment.tz(`${endDate} ${endTime}`, formatter, value) : moment.invalid();
  if (
    (startDate === '' || startDate === null || typeof startDate === 'undefined')
    && (endDate === '' || endDate === null || typeof endDate === 'undefined')
    && typeof startTime === 'undefined'
    && typeof endTime === 'undefined'
  ) {
    return true;
  }

  if (
    (moment.isMoment(startDate) && startDate.isValid())
    && (moment.isMoment(endDate) && endDate.isValid())
  ) {
    return startDate.isBefore(endDate);
  } if (
    (moment.isMoment(startDate) && startDate.isValid())
    || (moment.isMoment(endDate) && endDate.isValid())
  ) {
    return true;
  }

  if (startDateTime.isValid() && endDateTime.isValid()) {
    return startDateTime.isBefore(endDateTime);
  } if (startDateTime.isValid() || endDateTime.isValid()) {
    return true;
  }
  return false;
};

export const filterSuccessfulCartItems = (currentCart, failedCartItems) => {
  const remainingCartProducts = currentCart.reduce((newCart, cartItem) => {
    let message;
    const matchingCartItem = failedCartItems.find(({ value: { cartId, errorResponse } }) => {
      message = errorResponse;
      return cartId === cartItem.cartId;
    });
    if (matchingCartItem) {
      newCart.push({
        ...cartItem,
        errorResponse: message,
      });
    }
    return newCart;
  }, []);
  return remainingCartProducts;
};

export const isPurchaseOrderRequired = (activity, isBillable = false) => {
  if (isActivitySelected(activity)) {
    return (isBillable && activity === activitySelectTypes.CONSUMER_PROMOTION.value)
      || activity === activitySelectTypes.PUBLISHER_PAID_ALLOCATION.value
      || activity === activitySelectTypes.DISC_SUBSTITUTE.value;
  }
  return false;
};

export const showPurchaseOrderInput = (billable, activity) => {
  if (isActivitySelected(activity)) {
    return isPurchaseOrderRequired(activity, billable);
  }
  return false;
};

export const isValidPO = (order, cartProductsFromStore) => {
  const currentCartItem = cartProductsFromStore
    && cartProductsFromStore.length && cartProductsFromStore
    .filter(product => product.cartId === order.cartId);

  const {
    billable,
    poNumber,
  } = order;

  const isValidPONumber = poNumber && poNumber.length ? poNumber.length < 101 : false;
  const hasPOFile = Boolean(currentCartItem
    && currentCartItem[0]
    && currentCartItem[0].poFile
    && currentCartItem[0].poFile.size !== 0
    && currentCartItem[0].poFile.type === 'pdf');

  const isValidPurchaseOrder = hasPOFile && isValidPONumber;

  return isPurchaseOrderRequired(order.activity, billable) ? isValidPurchaseOrder : true;
};

export const cartButtonState = (
  {
    isCatalogRowView,
    activity,
    standardParId,
    quantityValue,
    useCountState,
    catalogPoNumber,
    catalogPoFile,
    catalogStartDate,
    catalogEndDate,
    catalogStartTime,
    catalogEndTime,
    billable,
  },
  {
    isCartRowView,
    cart, cartItem,
  },
  orderActivityRules,
) => {
  if (isCatalogRowView) {
    const draftOrder = {
      cartId: 1,
      standardParId,
      activity: activity.value,
      quantity: quantityValue,
      useCount: useCountState,
      startDate: catalogStartDate,
      endDate: catalogEndDate,
      startTime: catalogStartTime,
      endTime: catalogEndTime,
      poFile: catalogPoFile,
      poNumber: catalogPoNumber,
      billable,
    };
    const tempCart = [draftOrder];
    const isAddtoCartInformationValid = isActivitySelected(activity.value)
      && isValidQuantity(draftOrder, orderActivityRules)
      && isValidDates(draftOrder)
      && isValidPO(draftOrder, tempCart);
    return !isAddtoCartInformationValid;
  }
  if (isCartRowView) {
    return isValidPO(cartItem, cart)
      && isValidDates(cartItem) && isValidQuantity(cartItem, orderActivityRules);
  }
  return true;
};
