import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function loadingReducer(state = initialState.loading, action) {
  if (action.type === types.START_LOADING) {
    return true;
  } if (action.type === types.STOP_LOADING) {
    return false;
  }

  return state;
}
