import _ from 'lodash';
import { useSelector } from 'react-redux';
import * as rolePermissions from './roles';
import integratorPartnerTypes from '../../constants/integratorPartnerTypes.constants';

const getKeys = (key) => {
  if (key.includes('.')) {
    return key.split('.');
  }
  throw Error('use valid key "feature.action"');
};

export function mapRoles(roles) {
  if (typeof roles !== 'undefined') {
    return roles.map(role => role.role.roleCode);
  }
  return [];
}

// get role code for front end permissions
const getRoleCode = (user) => {
  let roleCode = _.get(user, 'roleCode', 'defaultRole');

  if (roleCode === 'PARTNER') {
    const storePartnerType = _.get(user, 'domainPartner.partnerType', '');

    if (storePartnerType === integratorPartnerTypes.INTEGRATOR) {
      roleCode = 'INTEGRATOR_PARTNER';
    } else {
      roleCode = 'THIRD_PARTY_PARTNER';
    }
  }
  return roleCode;
};

const getPermission = (roleCode, featureAction, conditions) => {
  try {
    const [feature, action] = getKeys(featureAction);
    const hasPermission = rolePermissions[roleCode][feature][action];
    if (typeof hasPermission === 'function') {
      if (conditions.length === 0) {
        throw Error('permission requires arguments');
      }
      return hasPermission(...conditions);
    }
    if (typeof hasPermission === 'boolean' || typeof hasPermission === 'string') {
      return hasPermission;
    }
    throw Error('no valid permission found');
  } catch (error) {
    return false;
  }
};

export function usePermission(featureAction, ...conditions) {
  const user = useSelector(state => state.user);
  const roleCode = getRoleCode(user);
  return getPermission(roleCode, featureAction, conditions);
}

export function userPermission(user, featureAction, ...conditions) {
  const roleCode = getRoleCode(user);
  return getPermission(roleCode, featureAction, conditions);
}

export default (store, featureAction, ...conditions) => {
  const user = (typeof store === 'object' && typeof store.getState === 'function')
    ? store.getState().user : store.user;
  const roleCode = getRoleCode(user);
  return getPermission(roleCode, featureAction, conditions);
};
