import React, {
  useState, useEffect, useReducer,
  useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import '../../App.scss';
import {
  Container,
  DataTable,
  TableHeader,
  TableHeaderCell,
  GroupState,
  ActionBar,
  Pagination,
  RowDisplay,
} from '@partner-global-ui/components';
import { xor } from 'lodash';
import FiltersContainer from '../common/Filters/FiltersContainer';
import { optionsTranslationCreator, catalogTypeOptions } from '../../constants/filterOptions.constants';
import { filterCounter } from '../../helpers/filterMethods';
import RowDisplayLocalization from '../../helpers/RowDisplayLocalization';
import Voucher from './Voucher';
import Nav from './Nav';
import './Catalog.scss';
import * as orderActions from '../../actions/orderActions';
import getCountries from '../../actions/countryActions';
import * as voucherCatalogActions from '../../actions/voucherProductActions';
import countryMap from '../../constants/countries.constants';

export const componentActions = {
  updateSearch: 0,
  updateSort: 1,
  updatePageNumber: 2,
  updatePageSize: 3,
  setFilter: 4,
  clearFilters: 5,
};

const defaultFilters = {
  typeName: {},
  country: [],
};

export const initialState = {
  filters: defaultFilters,
  filtered: false,
  filterCount: 0,
  selectedRows: [],
  currentSearch: '',
  searched: false,
  currentSort: {
    sortBy: 'voucherName',
    sortDir: 'desc',
  },
  currentPageNumber: 0,
  size: 50,
  totalElements: 0,
  loading: true,
  defaultSort: true,
};

export const localStateReducer = (
  state,
  {
    type = '',
    name,
    value,
    voucherCatalogId,
    voucherCatalogIds,
    filters = defaultFilters,
    page,
    pageNumber,
    pageSize,
    search,
    sort,
  },
) => {
  const filteredState = filters && (
    filters.typeName.length > 0
    || filters.country.length > 0);
  let newState;
  switch (type) {
    case 'setState':
      return { ...state, [name]: value };
    case 'setFilter':
      newState = {
        ...state,
        filters: { ...state.filters, [name]: value },
        filtered: !filteredState,
      };
      return {
        ...newState,
        filterCount: filterCounter(newState.filters),
      };
    case 'clearFilters':
      return {
        ...state,
        filters: defaultFilters,
        filtered: false,
        filterCount: 0,
        loading: true,
        defaultSort: true,
      };
    case 'toggleRow':
      return {
        ...state,
        selectedRows: xor(state.selectedRows, [voucherCatalogId]),
      };
    case 'togglePage':
      return {
        ...state,
        selectedRows: xor(state.selectedRows, voucherCatalogIds),
      };
    case 'setPage':
      return {
        ...state,
        ...page,
      };
    case 'finishedLoading':
      return {
        ...state,
        loading: false,
      };
    case 'startLoading':
      return {
        ...state,
        loading: true,
      };
    case componentActions.updatePageNumber:
      return {
        ...state,
        number: pageNumber,
        loading: true,
      };
    case componentActions.updatePageSize:
      return {
        ...state,
        size: pageSize,
        loading: true,
      };
    case componentActions.updateSort:
      return {
        ...state,
        currentSort: sort,
        loading: true,
        defaultSort: false,
      };
    case componentActions.updateSearch:
      return {
        ...state,
        currentSearch: search,
        loading: true,
      };
    case 'resetCheckBoxes':
      return {
        ...state,
        selectedRows: initialState.selectedRows,
      };
    default:
      return state;
  }
};

const CatalogPage = ({ match, history }) => {
  //  Setup
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [itemsToAdd, setItemsToAdd] = useState([]);
  const [readyToAdd, setReadyToAdd] = useState(false);
  const [localState, localDispatch] = useReducer(localStateReducer, initialState);

  const {
    selectedRows,
    filterCount,
    filters,
    totalElements,
    currentSearch,
    number: pageNumber,
    size,
    currentSort,
    loading,
    defaultSort,
  } = localState;

  // only show same region from catalog
  const stateOrder = useSelector(state => state.order);
  const vouchers = useSelector(state => state.vouchers);
  const stateCountries = useSelector(state => state.countries);


  const groupCheckboxState = selectedRows.length > 0 ? GroupState.SOME : GroupState.NONE;

  let roles = useSelector(state => state.user.userRoles);
  if (roles) {
    roles = roles.map(role => role.role.roleCode);
  }

  useEffect(() => {
    if (loading) {
      // Load order vouchers and hide indicator
      dispatch(voucherCatalogActions
        .loadVouchersForOrder(
          match.params.id,
          currentSearch,
          filters,
          size,
          pageNumber,
          currentSort,
          defaultSort,
        ))
        .then((page) => {
          localDispatch({ type: 'setPage', page });
        }).finally(
          () => localDispatch({ type: 'finishedLoading' }),
        );
    }
  }, [
    pageNumber,
    currentSearch,
    filters,
    size,
    currentSort,
    loading,
  ]);

  useEffect(() => {
    if (typeof match.params.id !== 'undefined') {
      getCountries()(dispatch);
    }
  }, []);

  useEffect(() => {
    const filtersCount = filters.typeName.value && filters.typeName.value !== 'all'
      ? filterCounter(filters) + 1
      : filterCounter(filters);
    localDispatch({ type: 'setState', name: 'filterCount', value: filtersCount });
  }, [filters]);

  useEffect(() => {
    setReadyToAdd(itemsToAdd.length);
  }, [itemsToAdd]);

  const resetFilters = () => {
    localDispatch({ type: 'clearFilters' });
  };

  const returnToOrder = () => {
    history.push(`/order/${match.params.id}`);
  };

  const selectVoucher = (voucher) => {
    // 1. check to see if the voucher is already in itemsToAdd by verifying the
    // voucher.voucherCatalogId and voucher.country
    const alreadyAdded = itemsToAdd.find(
      item => item.voucherCatalogId === voucher.voucherCatalogId
      && item.countryCode === voucher.countryCode,
    );
    if (alreadyAdded) {
      // 2. if it is, remove it from itemsToAdd
      setItemsToAdd(itemsToAdd.filter(item => item !== alreadyAdded));
    } else {
      // 3. if it is not, add it to itemsToAdd
      setItemsToAdd([...itemsToAdd, voucher]);
    }
    // 4. set the selectedRows state
    localDispatch({ type: 'toggleRow', voucherCatalogId: voucher.voucherCatalogId });
  };

  const addItemsToOrder = () => {
    dispatch(orderActions.addItemsToOrder(stateOrder, itemsToAdd));
    history.push(`/order/${stateOrder.orderNumber}`);
  };

  const toggleGroupCheckbox = (rows) => {
    return () => {
      const voucherCatalogIds = rows.map(row => row.voucherCatalogId);
      setItemsToAdd(selectedRows.length ? [] : rows);
      localDispatch({ type: 'togglePage', voucherCatalogIds });
    };
  };

  // labels for table headers
  const tableHeaders = [
    { label: t('msg_codes_voucherProductName'), value: 'voucherName' },
    { label: t('msg_codes_orderList_countryHeader'), value: 'countryCode' },
    { label: t('msg_codes_voucherProductType'), value: 'type' },
    { label: t('msg_codes_face_value'), value: 'faceValue' },
  ];

  const sortCatalog = useCallback((name) => {
    const { sortBy, sortDir } = currentSort;
    const newSortDir = {
      [true]: () => 'asc',
      [name === sortBy && sortDir === 'asc']: () => 'desc',
      [name === sortBy && sortDir !== 'asc']: () => 'asc',
    }.true();
    const newSort = { sortBy: name, sortDir: newSortDir };
    localDispatch({ type: componentActions.updateSort, sort: newSort });
  }, [currentSort.sortBy, currentSort.sortDir]);

  const renderHeaderCells = () => {
    return tableHeaders.map(({ label, value }, headerCellIndex) => {
      const headerKey = headerCellIndex + 1;
      return (
        <TableHeaderCell
          id={headerKey}
          key={headerKey}
          value={value}
          className={value ? `${value}` : 'remove'}
          sortable
          onClick={sortCatalog}
          sort={defaultSort ? {} : currentSort}
        >
          {label}
        </TableHeaderCell>
      );
    });
  };

  const renderTableRows = () => {
    return (
      vouchers.map((voucher) => {
        const updatedVoucher = { ...voucher };
        const checkboxState = itemsToAdd
          .some(selectedRow => selectedRow.voucherCatalogId === voucher.voucherCatalogId
            && selectedRow.countryCode === voucher.countryCode)
          ? GroupState.ALL : GroupState.NONE;

        updatedVoucher.country = t(`${countryMap[updatedVoucher.countryCode].name}`);

        const matchingCatalogTypeOptions = catalogTypeOptions
          .filter(catalogTypeOption => catalogTypeOption.value === voucher.type);
        updatedVoucher.typeName = t(`${matchingCatalogTypeOptions[0].label}`);

        return (
          <Voucher
            key={`${voucher.voucherCatalogId}-${voucher.countryCode}`}
            voucher={updatedVoucher}
            itemsToAdd={itemsToAdd}
            selectVoucher={selectVoucher}
            checkboxState={checkboxState}
            name="voucher"
          />
        );
      })
    );
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    localDispatch({ type: 'setFilter', name, value });
  };

  const typesOptions = optionsTranslationCreator(catalogTypeOptions, t)
    .sort((type1, type2) => type1.label.localeCompare(type2.label));
  const createFilters = useCallback((localCountries) => {
    const {
      typeName,
      country,
    } = filters;

    const countryOptions = localCountries
      .filter(countryItem => countryItem.region.code === stateOrder.region)
      .map(countryItem => ({
        label: t(countryItem.nameStringId),
        value: countryItem.code,
      }))
      .sort((countryItem1, countryItem2) => countryItem1.label.localeCompare(countryItem2.label));

    return [
      {
        label: t('msg_codes_voucherProductType'),
        name: 'typeName',
        placeholder: t('msg_codes_filter_all'),
        value: typeName.length === 0 ? typesOptions[0] : typeName,
        options: typesOptions,
        handleChange: handleFilterChange,
      },
      {
        label: t('msg_codes_orderList_countryHeader'),
        name: 'country',
        placeholder: t('msg_codes_filter_all'),
        multiple: true,
        value: country,
        options: optionsTranslationCreator(countryOptions, t),
        handleChange: handleFilterChange,
      },
    ];
  }, [filters, stateCountries, vouchers, stateOrder, vouchers]);

  const actionBar = (localCountries) => {
    return (
      <ActionBar
        filters={
          <FiltersContainer filters={createFilters(localCountries)} />
        }
        onFilterClear={resetFilters}
        filterCount={filterCount}
        onFilterApply={() => localDispatch({ type: 'startLoading' })}
        filterClearLabel={t('msg_codes_cta_clear')}
        filterPrimaryLabel={t('msg_codes_cta_apply')}
        filterSecondaryLabel={t('msg_codes_cta_cancel')}
        filterToolTipText={t('msg_codes_filter')}
        filterTitle={t('msg_codes_filter')}
      />
    );
  };

  const renderDataTable = () => {
    return (
      <DataTable
        columnTemplate="repeat(4, 1fr)"
        scrollHeight={400}
        actionBar={actionBar(stateCountries)}
        loading={loading}
        hasCheckbox
        id="product-catalog-table"
        data-testid="product-catalog-table"
      >
        <TableHeader
          onCheckboxClick={toggleGroupCheckbox(vouchers)}
          checkboxState={groupCheckboxState}
        >
          {renderHeaderCells()}
        </TableHeader>
        {renderTableRows()}
      </DataTable>
    );
  };

  return (
    <div className="catalog">
      <Container name="catalogGrid">
        <Nav
          itemsToAdd={itemsToAdd}
          cancel={returnToOrder}
          save={addItemsToOrder}
          disabled={!readyToAdd}
          roles={roles}
          name="catalogNav"
          canSearch
          catalogDispatch={localDispatch}
        />
        {renderDataTable()}
        {totalElements > 10 && (
          <div data-testid="code-products-paginator" className="code-products-paginator">
            <RowDisplay
              id="code-products-paginator-selector"
              currentPage={pageNumber + 1}
              totalItems={totalElements}
              pageSizes={[10, 25, 50, 100]}
              onPageSizeChange={
                pageSize => localDispatch({
                  type: componentActions.updatePageSize,
                  pageSize,
                })
              }
              initialPageSize={size}
              showingOfText={RowDisplayLocalization('msg_codes_pagination_showing')(t)}
            />
            {totalElements > size && (
              <Pagination
                id="code-products-paginator-navigator"
                totalRecords={totalElements}
                currentPage={pageNumber + 1}
                pageLimit={size}
                onPageItemClick={
                  newPageNumber => localDispatch({
                    type: componentActions.updatePageNumber,
                    pageNumber: newPageNumber - 1,
                  })
                }
              />
            )}
          </div>
        )}
      </Container>
    </div>
  );
};

export default CatalogPage;
