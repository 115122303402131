import React, { useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Prompt } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Layout,
  Flex,
  Button,
  Collapsible,
  ModalContainer,
  NotificationContext,
} from '@partner-global-ui/components';
import UserInfoComponent from './userInfo/UserInfo';
import * as userFormAction from '../../actions/userFormActionsNew';
import * as userActionsCreator from '../../actions/userActions';
import * as OktaUserStatus from '../../constants/oktaUserStatus.constants';
import handleUnsavedChanges, { cleanupHandleUnsavedChanges } from '../../utils/handleUnsavedChanges';
import hasPermission from '../../utils/accessControl/hasPermission';
import roleKeys from '../../utils/accessControl/roleKeys';
import './user.scss';

const UserPage = ({
  match: { params: { id } },
  history: { push },
}) => {
  const notificationContext = useContext(NotificationContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const state = useSelector(globalState => globalState);
  const { user, userPage, pgpKey } = state;

  const {
    changesMade,
    unsaved,
    isSaving,
  } = userPage;

  const {
    activeFingerprint,
    hasActiveFingerprint,
  } = user;

  let isDeprovisioned = false;
  isDeprovisioned = userPage.externalStatus === OktaUserStatus.DEPROVISIONED;
  const hideFormDetails = (isDeprovisioned || userPage.externalStatus === '') && userPage.userFoundIn !== 'none';

  const userHasPgpAccess = hasPermission(
    state,
    roleKeys.user.canAddPgpKeys,
    user.email,
    userPage.userDetailForm.email,
  );

  const {
    key,
    activeFingerprint: localActiveFingerprint,
    hasKey,
    validated,
  } = pgpKey;

  const { creationDate = '' } = userPage.userDetailForm;

  const rightContent = (!hideFormDetails && creationDate) && `${t('msg_codes_agreements_dateCreated')}: ${moment(creationDate).format('MM/DD/YYYY')}`;

  const showSaveButton = !hideFormDetails && userHasPgpAccess;
  useEffect(() => {
    if (!id) {
      dispatch(userFormAction.resetUserPage());
      push('/users');
    }
  }, []);

  useEffect(() => {
    handleUnsavedChanges(changesMade && unsaved);
  }, [changesMade, unsaved]);

  useEffect(() => {
    cleanupHandleUnsavedChanges();
    if (!hasActiveFingerprint && typeof activeFingerprint === 'undefined') {
      dispatch(
        userActionsCreator.viewedPGPSetupPage(),
      );
      dispatch(
        userActionsCreator.removePGPKeyFromUser(),
      );
    }
  }, [hasActiveFingerprint, activeFingerprint]);

  const onSaveClick = () => {
    if (userHasPgpAccess && validated) {
      if (typeof key !== 'undefined' && (typeof localActiveFingerprint === 'undefined' || localActiveFingerprint === null)) {
        dispatch(userActionsCreator.addPgpKey(notificationContext));
      } else if (hasKey) {
        dispatch(userActionsCreator.editPgpKey(notificationContext));
      }
    }
  };

  const renderNav = () => {
    const disabled = userHasPgpAccess
      ? (!pgpKey.validated || pgpKey.validating) && (!changesMade || isSaving)
      : true;

    return (
      <div className="user-nav">
        <Container>
          <Layout>
            <Flex colSpan="10" className="title">{t('msg_codes_users_user')}</Flex>
            <Flex colSpan="2" className="buttons">
              {showSaveButton && (
                <Button
                  name="UserPageButton"
                  id="userSaveButton"
                  primary
                  disabled={disabled}
                  onClick={onSaveClick}
                >
                  {t('msg_codes_cta_save')}
                </Button>
              )}
            </Flex>
          </Layout>
        </Container>
      </div>
    );
  };

  return (
    <div className="user-page">
      <ModalContainer>
        {renderNav()}
        <Prompt
          name="UserPagePrompt"
          when={userPage.unsaved}
          message={t('msg_codes_modal_unsavedChanges_body')}
        />
        <Collapsible heading={t('msg_codes_users_userProfile')} rightContent={rightContent} defaultIsOpen>
          <UserInfoComponent name="UserInfoComponent" />
        </Collapsible>
      </ModalContainer>
    </div>
  );
};

UserPage.defaultProps = {
  userPage: {},
  showSaveButton: false,
  userHasPgpAccess: false,
  location: {},
  userFormActions: {},
  userActions: {
    editUser: () => {},
  },
  isSaving: false,
  hideFormDetails: true,
  pgpKey: {},
  isSuperAdmin: false,
  isPartner: false,
};

export default UserPage;
