export const contentTypes = [
  {
    id: 1,
    value: 'TV',
    label: 'TV',
    nameStringId: 'TV',
  },
  {
    id: 2,
    value: 'Promotion',
    label: 'Promotion',
    nameStringId: 'Promotion',
  },
  {
    id: 3,
    value: 'Promotion Video',
    label: 'Promotion Video',
    nameStringId: 'Promotion Video',
  },
  {
    id: 4,
    value: 'Game',
    label: 'Game',
    nameStringId: 'Game',
  },
  {
    id: 5,
    value: 'Video',
    label: 'Video',
    nameStringId: 'Video',
  },
  {
    id: 6,
    value: 'Music',
    label: 'Music',
    nameStringId: 'Music',
  },
  {
    id: 7,
    value: 'Comic',
    label: 'Comic',
    nameStringId: 'Comic',
  },
  {
    id: 8,
    value: 'Change Online ID',
    label: 'Change Online ID',
    nameStringId: 'Change Online ID',
  },
  {
    id: 9,
    value: 'Web',
    label: 'Web',
    nameStringId: 'Web',
  },
  {
    id: 10,
    value: 'Application',
    label: 'Application',
    nameStringId: 'Application',
  },
];

export const skuTypes = [
  {
    id: 1,
    value: 'Pre-Order',
    label: 'Pre-Order',
    nameStringId: 'Pre-Order',
  },
  {
    id: 2,
    value: 'BDC',
    label: 'BDC',
    nameStringId: 'BDC',
  },
  {
    id: 3,
    value: 'Standard',
    label: 'Standard',
    nameStringId: 'Standard',
  },
  {
    id: 4,
    value: 'Promotion',
    label: 'Promotion',
    nameStringId: 'Promotion',
  },
  {
    id: 5,
    value: 'Subscription',
    label: 'Subscription',
    nameStringId: 'Subscription',
  },
];
