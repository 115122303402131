import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function codeProductsCatalog(
  state = initialState.codeProductsCatalog,
  action,
) {
  switch (action.type) {
    case types.LOAD_CODE_PRODUCTS:
      return [...action.codeProducts];
    default:
      return state;
  }
}
