import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Layout,
  Flex,
  Search,
} from '@partner-global-ui/components';
import * as voucherBatchActions from '../../actions/voucherBatchActions';

function Nav({ setPageNumber }) {
  const search = useSelector(state => state.voucherBatchesPage.search);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const searchVoucherBatches = useCallback((searchTerm) => {
    setPageNumber(0);
    dispatch(voucherBatchActions.setVoucherBatchesSearch(searchTerm));
  }, [search, dispatch]);

  const clearVoucherBatchesSearch = useCallback(() => {
    dispatch(voucherBatchActions.clearVoucherBatchesSearch());
  }, [dispatch, search]);

  return (
    <Layout id="voucherBatches-nav" name="voucherBatchesNav" className="voucherBatches-nav">
      <Flex className="title" colSpan={3}>
        <h3
          name="voucherBatches-title"
          className="voucherBatches-title"
          id="voucherBatches-title"
          data-testid="voucherBatches-title"
        >
          {t('msg_codes_voucherBatchCatalog')}
        </h3>
      </Flex>
      <Flex className="search" colSpan={3}>
        <Search
          search={searchVoucherBatches}
          onClearSearch={clearVoucherBatchesSearch}
          searchDefaultValue=""
          id="voucherBatches-searchInput"
          name="voucherBatches-searchInput"
          data-testid="voucherBatches-searchInput"
          placeholder={t('msg_codes_type_something')}
          width={300}
          useClearIcon
          hideCategories
        />
      </Flex>
    </Layout>
  );
}

Nav.propTypes = {
  setPageNumber: PropTypes.func,
};

Nav.defaultProps = {
  setPageNumber: () => {},
};

export default Nav;
