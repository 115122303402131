import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

export const RouteChange = (props) => {
  const {
    location, actions, store, user,
  } = props;

  const routeChanged = () => {
    if (typeof actions !== 'undefined') {
      actions.forEach((action) => {
        store.dispatch(action(location, user, store.getState().app));
      });
    }
  };

  useEffect(() => {
    routeChanged();
  }, [location.pathname]);

  return null;
};

RouteChange.propTypes = {
  location: PropTypes.object,
  store: PropTypes.object,
  user: PropTypes.object,
};

RouteChange.defaultProps = {
  location: {},
  store: {},
  user: {},
};

export default withRouter(RouteChange);
