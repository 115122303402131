import React from 'react';
import { Layout, Loader } from '@partner-global-ui/components';
import PropTypes from 'prop-types';

export default function ThirdPartyDownloadHistory({
  downloadHistory, downLoadHistoryTitles, noHistoryMessage, loadingDownloadHistory,
}) {
  const {
    voucherCodeFileName,
    orderNumber,
    voucherBatchId,
    lastDownloadDate,
    lastDownloadBy,
    codeStartDate,
    codeEndDate,
  } = downloadHistory;

  const {
    fileName,
    orderID,
    batchID,
    dateLastDownloaded,
    userLastDownloaded,
    codeStartEndDate,
  } = downLoadHistoryTitles;

  return (
    <Layout className="download-history-container">
      {
        loadingDownloadHistory
          ? <Loader />
          : (
            voucherCodeFileName
              ? (
                <div className="download-history-info" data-testid="download-history-info">
                  <div className="download-name download-history-row" data-testid="download-name">
                    <div className="download-history-info-title">{fileName}</div>
                    <div>{voucherCodeFileName}</div>
                  </div>
                  <div className="download-ids download-history-row">
                    <div className="order-id" data-testid="order-id">
                      <div className="download-history-info-title">{orderID}</div>
                      <div>{orderNumber}</div>
                    </div>
                    <div className="batch-id" data-testid="batch-id">
                      <div className="download-history-info-title">{batchID}</div>
                      <div>{voucherBatchId}</div>
                    </div>
                  </div>
                  <div className="last-download download-history-row">
                    <div className="last-downloaded" data-testid="last-downloaded">
                      <div className="download-history-info-title">{dateLastDownloaded}</div>
                      <div>{lastDownloadDate}</div>
                    </div>
                    <div className="last-downloaded-by" data-testid="last-downloaded-by">
                      <div className="download-history-info-title">{userLastDownloaded}</div>
                      <div>{lastDownloadBy}</div>
                    </div>
                  </div>
                  <div className="start-end-date download-history-row" data-testid="start-end-date">
                    <div className="download-history-info-title">{codeStartEndDate}</div>
                    <div>{codeStartDate} - {codeEndDate}</div>
                  </div>
                </div>
              )
              : <div className="no-order-history" data-testid="no-order-history">{noHistoryMessage}</div>
          )
      }
    </Layout>
  );
}

ThirdPartyDownloadHistory.propTypes = {
  downloadHistory: PropTypes.object,
  downLoadHistoryTitles: PropTypes.object,
  noHistoryMessage: PropTypes.string,
  loadingDownloadHistory: PropTypes.bool,
};

ThirdPartyDownloadHistory.defaultProps = {
  downloadHistory: {},
  downLoadHistoryTitles: {},
  noHistoryMessage: '',
  loadingDownloadHistory: false,
};
