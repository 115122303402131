import React, {
  useCallback,
  useEffect,
  useRef,
  useReducer,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Layout,
  Flex,
  DataTable,
  TableHeader,
  TableHeaderCell,
  RowDisplay,
  Pagination,
} from '@partner-global-ui/components';
import { isEmpty } from 'lodash';
import SearchHeader from '../common/SearchHeader/SearchHeader';
import PartnersColumns from './PartnersColumns';
import RowDisplayLocalization from '../../helpers/RowDisplayLocalization';
import Partner from './Partner';
import { loadPartners } from '../../actions/partnerActions';
import './Partners.scss';


export const initialState = {
  currentSort: {
    sortBy: 'name',
    sortDir: 'asc',
  },
  currentPageNumber: 0,
  currentPageSize: 50,
};

export function localStateReducer(
  state,
  {
    type = '',
    name,
    value,
    ...payload
  },
) {
  switch (type) {
    case 'sortPartners':
      return {
        ...state,
        currentSort: payload.currentSort,
      };
    case 'changePageNumber':
      return {
        ...state,
        currentPageNumber: payload.page,
      };
    case 'updatePageSize':
      localStorage.setItem(`partnersPageSize-${payload.userId}`, payload.pageSize);
      return {
        ...state,
        currentPageSize: payload.pageSize,
      };
    default:
      return state;
  }
}


const PartnersPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userId } = useSelector(state => state.user);
  let updatedInitialState = initialState;
  const hasPageSize = !isEmpty(localStorage.getItem(`partnersPageSize-${userId}`));

  updatedInitialState = {
    ...updatedInitialState,
    currentPageSize: hasPageSize
      ? Number(localStorage.getItem(`partnersPageSize-${userId}`))
      : updatedInitialState.currentPageSize,
  };

  const [localState, localDispatch] = useReducer(localStateReducer, updatedInitialState);
  const tableRef = useRef();
  const partners = useSelector(state => state.partners);

  const {
    page: {
      pageNumber,
      pageSize,
      totalElements,
    },
    loadingPartners,
  } = useSelector(state => state.partnersPage);

  const {
    currentSort,
    currentPageSize,
    currentPageNumber,
  } = localState;

  useEffect(() => {
    dispatch(
      loadPartners(currentPageSize),
    );
  }, []);

  const columnTemplate = '20% 20% 20% 20% 20%';

  const sortPartners = useCallback((name) => {
    const { sortBy, sortDir } = currentSort;

    const newSortDir = {
      [true]: () => 'asc',
      [name === sortBy && sortDir === 'asc']: () => 'desc',
      [name === sortBy && sortDir !== 'asc']: () => 'asc',
    }.true();
    const newSort = { sortBy: name, sortDir: newSortDir };
    localDispatch({ type: 'sortPartners', currentSort: newSort });

    dispatch(
      loadPartners(
        localStorage.getItem(`partnersPageSize-${userId}`) !== null
          ? Number(localStorage.getItem(`partnersPageSize-${userId}`))
          : currentPageSize,
        currentPageNumber,
        newSort,
      ),
    );
  }, [currentSort.sortBy, currentSort.sortDir]);

  const changePage = useCallback((page) => {
    localDispatch({ type: 'changePageNumber', page });

    dispatch(
      loadPartners(
        currentPageSize,
        page,
        currentSort,
      ),
    );
  });

  const changeRowAmount = useCallback((pageSizeParam, id) => {
    localDispatch({ type: 'updatePageSize', pageSize: pageSizeParam, userId: id });
    dispatch(
      loadPartners(
        pageSizeParam,
        currentPageNumber,
        currentSort,
      ),
    );
  });

  const renderPartnersRows = useCallback(() => {
    return partners.map((partner, index) => (
      <Partner
        key={partner.partnerId}
        rowindex={index + 1}
        partner={partner}
        tableRef={tableRef}
      />
    ));
  }, [partners]);

  // Render
  return (
    <Container id="partners-page" className="partners-page">
      <Layout id="partners-header" className="partners-header">
        <SearchHeader
          title={t('msg_codes_partners')}
          name="partners"
          canSearch={false}
          hideSecondaryNav
        />
      </Layout>
      <Layout id="partners-list-layout" className="partners-list">
        <Flex id="partners-list-table">
          <DataTable
            id="partners-list-table"
            columnTemplate={columnTemplate}
            loading={loadingPartners}
            ref={tableRef}
          >
            <TableHeader id="partners-list-table-header">
              {PartnersColumns.map(column => (
                <TableHeaderCell
                  key={column.id}
                  value={column.value}
                  id={column.id}
                  sortable={column.sortable}
                  sort={localState.currentSort}
                  onClick={sortPartners}
                >
                  {t(column.stringId)}
                </TableHeaderCell>
              ))}
            </TableHeader>
            {renderPartnersRows()}
          </DataTable>
          {totalElements > 10 && (
            <div data-testid="partners-paginator" className="partners-paginator">
              <RowDisplay
                id="partners-paginator-selector"
                currentPage={pageNumber + 1}
                totalItems={totalElements}
                pageSizes={[10, 25, 50, 100]}
                onPageSizeChange={pageRowAmount => changeRowAmount(pageRowAmount, userId)}
                initialPageSize={currentPageSize}
                showingOfText={RowDisplayLocalization('msg_codes_pagination_showing')(t)}
              />
              {totalElements > pageSize && (
                <Pagination
                  id="partners-paginator-navigator"
                  totalRecords={totalElements}
                  currentPage={pageNumber + 1}
                  pageLimit={currentPageSize}
                  onPageItemClick={pageNum => changePage(pageNum - 1)}
                />
              )}
            </div>
          )}
        </Flex>
      </Layout>
    </Container>
  );
};

export default PartnersPage;
