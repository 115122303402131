import { downloadProduct, thirdPartyProductRetry } from '../components/thirdPartyCatalog/shared/thirdPartyCatalogPageMethods';
import { downloadThirdPartyProduct, retryThirdPartyProduct } from '../actions/thirdPartyCatalogActions';
import getFeature from './accessControl/getFeature';

const helpUrl = getFeature('helpUrl');
export const feesUrl = getFeature('feesUrl');
const downloadHelpUrl = getFeature('downloadHelpUrl');

const download = (
  voucherCatalogId,
  notificationCont,
  addProductToDownloadQueue,
  catalogPageDispatch,
  globalDisptach,
  removeProductsFromDownloadQueue,
  partnerId = null,
) => {
  return downloadProduct(
    voucherCatalogId,
    downloadThirdPartyProduct,
    notificationCont,
    addProductToDownloadQueue,
    removeProductsFromDownloadQueue,
    catalogPageDispatch,
    globalDisptach,
    partnerId,
  );
};

const retry = (
  voucherCatalogId, notificationCont, addProductToDownloadQueue, catalogPageDispatch,
  globalDisptach,
) => {
  return thirdPartyProductRetry(
    voucherCatalogId, retryThirdPartyProduct, notificationCont,
    addProductToDownloadQueue, catalogPageDispatch, globalDisptach,
  );
};

const thirdPartyDownloadStatus = {
  ACTIVATION_ERROR: {
    buttonDisabled: false,
    buttonStyle: 'secondary',
    buttonText: 'msg_codes_cta_tryAgain',
    showButton: true,
    onButtonClick: retry,
    showLoading: false,
    showToolTip: true,
    statusName: 'ACTIVATION_ERROR',
    toastOnDownloadMessage: 'msg_codes_codes_banner_somethingWentWrong',
    toastOnPollingMessage: 'msg_codes_codes_banner_somethingWentWrong',
    toastActionText: null,
    downloadToastType: 'error',
    pollingToastType: 'error',
    addToDownloadQueue: false,
    retryPolling: true,
    removeFromDownloadQueue: true,
    initiateDownload: false,
    promiseAction: 'reject',
    toggleLoadingOnClick: true,
    toastUrl: null,
  },
  ACTIVATED: {
    buttonDisabled: false,
    buttonStyle: 'primary',
    buttonText: 'msg_codes_cta_redownload',
    showButton: true,
    onButtonClick: download,
    showLoading: false,
    showToolTip: false,
    statusName: 'ACTIVATED',
    toastOnDownloadMessage: 'msg_codes_codes_banner_codesDownloaded',
    toastOnPollingMessage: null,
    toastActionText: 'msg_codes_cta_learnMore',
    downloadToastType: 'success',
    pollingToastType: null,
    addToDownloadQueue: false,
    retryPolling: false,
    removeFromDownloadQueue: true,
    initiateDownload: true,
    promiseAction: 'resolve',
    toggleLoadingOnClick: false,
    toastUrl: downloadHelpUrl,
  },
  ACTIVATING_ON_DOWNLOAD: {
    buttonDisabled: true,
    buttonStyle: 'loading',
    buttonText: 'Loading',
    showButton: true,
    showLoading: true,
    showToolTip: false,
    statusName: 'ACTIVATING_ON_DOWNLOAD',
    toastOnDownloadMessage: 'msg_codes_codes_banner_codesDownloaded',
    toastOnPollingMessage: null,
    toastActionText: 'msg_codes_cta_learnMore',
    downloadToastType: 'success',
    pollingToastType: null,
    addToDownloadQueue: true,
    retryPolling: true,
    removeFromDownloadQueue: false,
    initiateDownload: true,
    promiseAction: 'resolve',
    toggleLoadingOnClick: false,
    toastUrl: downloadHelpUrl,
  },
  ACTIVATING_ON_RETRY: {
    buttonDisabled: true,
    buttonStyle: 'loading',
    buttonText: 'Loading',
    showButton: true,
    showLoading: true,
    showToolTip: false,
    statusName: 'ACTIVATING_ON_RETRY',
    toastOnDownloadMessage: 'msg_codes_your_codes_activating',
    toastOnPollingMessage: null,
    toastActionText: null,
    downloadToastType: 'warning',
    pollingToastType: null,
    addToDownloadQueue: true,
    retryPolling: true,
    removeFromDownloadQueue: false,
    initiateDownload: true,
    promiseAction: 'resolve',
    toggleLoadingOnClick: false,
    toastUrl: null,
  },
  AGREEMENT_SUSPENDED_WITH_ORDER: {
    buttonDisabled: true,
    buttonStyle: 'link',
    buttonText: 'msg_codes_codes_status_notAvailable',
    showButton: true,
    onButtonClick: download,
    showLoading: false,
    showToolTip: false,
    statusName: 'AGREEMENT_SUSPENDED_WITH_ORDER',
    toastOnDownloadMessage: 'msg_codes_codes_banner_agreementInactive',
    toastOnPollingMessage: null,
    toastActionText: 'msg_codes_cta_learnMore',
    downloadToastType: 'error',
    pollingToastType: null,
    addToDownloadQueue: false,
    retryPolling: false,
    removeFromDownloadQueue: true,
    initiateDownload: false,
    promiseAction: 'resolve',
    toggleLoadingOnClick: false,
    toastUrl: helpUrl,
  },
  AGREEMENT_SUSPENDED_WITHOUT_ORDER: {
    buttonDisabled: true,
    buttonStyle: 'link',
    buttonText: 'msg_codes_codes_status_notAvailable',
    showButton: true,
    onButtonClick: () => {},
    showLoading: false,
    showToolTip: false,
    statusName: 'AGREEMENT_SUSPENDED_WITHOUT_ORDER',
    toastOnDownloadMessage: 'msg_codes_codes_banner_agreementInactive',
    toastOnPollingMessage: null,
    toastActionText: null,
    downloadToastType: 'error',
    pollingToastType: null,
    addToDownloadQueue: false,
    retryPolling: false,
    removeFromDownloadQueue: true,
    initiateDownload: false,
    promiseAction: 'resolve',
    toggleLoadingOnClick: false,
    toastUrl: null,
  },
  // DEACTIVATNG state
  CANCELLING_CODE: {
    buttonDisabled: true,
    buttonStyle: 'link',
    buttonText: 'msg_codes_deactivating',
    showButton: true,
    onButtonClick: () => {},
    showLoading: true,
    showToolTip: false,
    statusName: 'CANCELLING_CODE',
    toastOnDownloadMessage: null,
    toastOnPollingMessage: null,
    toastActionText: null,
    downloadToastType: 'warning',
    pollingToastType: null,
    addToDownloadQueue: true,
    retryPolling: true,
    removeFromDownloadQueue: false,
    initiateDownload: false,
    promiseAction: 'resolve',
    toggleLoadingOnClick: false,
    toastUrl: null,
  },
  DEACTIVATED: {
    buttonDisabled: true,
    buttonStyle: 'link',
    buttonText: 'msg_codes_codes_status_deactivated',
    showButton: true,
    onButtonClick: () => {},
    showLoading: false,
    showToolTip: true,
    statusName: 'DEACTIVATED',
    toastOnDownloadMessage: 'msg_codes_codes_status_deactivated',
    toastActionText: null,
    downloadToastType: 'error',
    pollingToastType: null,
    toastOnPollingMessage: null,
    addToDownloadQueue: false,
    retryPolling: false,
    removeFromDownloadQueue: false,
    initiateDownload: false,
    promiseAction: 'resolve',
    toggleLoadingOnClick: false,
    toastUrl: null,
  },
  FILE_GENERATION_ERROR: {
    buttonDisabled: true,
    buttonStyle: 'link',
    buttonText: 'msg_codes_error',
    showButton: true,
    onButtonClick: download,
    showLoading: false,
    showToolTip: true,
    statusName: 'FILE_GENERATION_ERROR',
    toastOnDownloadMessage: 'msg_codes_codes_banner_fileGenerationError',
    toastOnPollingMessage: 'msg_codes_codes_banner_fileGenerationError',
    toastActionText: 'msg_codes_cta_learnMore',
    downloadToastType: 'error',
    pollingToastType: 'error',
    addToDownloadQueue: false,
    retryPolling: false,
    removeFromDownloadQueue: true,
    initiateDownload: false,
    promiseAction: 'reject',
    toggleLoadingOnClick: false,
    toastUrl: helpUrl,
  },
  FULFILLED: {
    buttonDisabled: false,
    buttonStyle: 'primary',
    buttonText: 'msg_codes_cta_download',
    showButton: true,
    onButtonClick: download,
    showLoading: false,
    showToolTip: false,
    statusName: 'FULFILLED',
    toastOnDownloadMessage: 'msg_codes_codes_banner_codesDownloaded',
    toastOnPollingMessage: 'msg_codes_codes_banner_codesReady',
    toastActionText: null,
    downloadToastType: 'success',
    pollingToastType: 'success',
    addToDownloadQueue: false,
    retryPolling: false,
    removeFromDownloadQueue: true,
    initiateDownload: true,
    promiseAction: 'resolve',
    toggleLoadingOnClick: false,
    toastUrl: null,
  },
  FULFILLING: {
    buttonDisabled: true,
    buttonStyle: 'loading',
    buttonText: 'Loading',
    showButton: true,
    onButtonClick: () => {},
    showLoading: true,
    showToolTip: false,
    statusName: 'FULFILLING',
    toastOnDownloadMessage: 'msg_codes_codes_banner_codeGenerationInProgress',
    toastOnPollingMessage: null,
    toastActionText: null,
    downloadToastType: 'warning',
    pollingToastType: null,
    addToDownloadQueue: true,
    retryPolling: true,
    removeFromDownloadQueue: false,
    initiateDownload: false,
    promiseAction: 'resolve',
    toggleLoadingOnClick: false,
    toastUrl: null,
  },
  FULFILLMENT_ERROR: {
    buttonDisabled: false,
    buttonStyle: 'secondary',
    buttonText: 'msg_codes_cta_tryAgain',
    onButtonClick: retry,
    showButton: true,
    showLoading: false,
    showToolTip: true,
    statusName: 'FULFILLMENT_ERROR',
    toastOnDownloadMessage: 'msg_codes_codes_banner_somethingWentWrong',
    toastOnPollingMessage: 'msg_codes_codes_banner_somethingWentWrong',
    toastActionText: null,
    downloadToastType: 'error',
    pollingToastType: 'error',
    addToDownloadQueue: false,
    retryPolling: false,
    removeFromDownloadQueue: true,
    initiateDownload: false,
    promiseAction: 'reject',
    toggleLoadingOnClick: true,
    toastUrl: null,
  },
  GENERATING_EXPIRED_FILE: {
    buttonDisabled: true,
    buttonStyle: 'loading',
    buttonText: 'Loading',
    showButton: true,
    showLoading: true,
    showToolTip: false,
    statusName: 'GENERATING_EXPIRED_FILE',
    toastOnDownloadMessage: 'msg_codes_codes_banner_codeGenerationInProgress',
    toastOnPollingMessage: null,
    toastActionText: null,
    toastType: null,
    downloadToastType: 'warning',
    addToDownloadQueue: true,
    removeFromDownloadQueue: false,
    retryPolling: true,
    initiateDownload: false,
    promiseAction: 'resolve',
    toggleLoadingOnClick: true,
    toastUrl: null,
  },
  INACTIVE_PRODUCT_WITHOUT_ORDER: {
    buttonDisabled: true,
    buttonStyle: 'link',
    buttonText: 'msg_codes_codes_status_notAvailable',
    showButton: true,
    onButtonClick: () => {},
    showLoading: false,
    showToolTip: false,
    statusName: 'INACTIVE_PRODUCT_WITHOUT_ORDER',
    toastOnDownloadMessage: 'msg_codes_codes_banner_productInactive',
    toastOnPollingMessage: null,
    toastActionText: 'msg_codes_cta_learnMore',
    toastType: 'error',
    addToDownloadQueue: false,
    retryPolling: false,
    removeFromDownloadQueue: true,
    initiateDownload: false,
    promiseAction: 'resolve',
    toggleLoadingOnClick: false,
    toastUrl: helpUrl,
  },
  NOT_AVAILABLE: {
    buttonDisabled: true,
    buttonStyle: 'link',
    buttonText: 'msg_codes_codes_status_notAvailable',
    showButton: true,
    onButtonClick: () => {},
    showLoading: false,
    showToolTip: false,
    statusName: 'NOT_AVAILABLE',
    toastOnDownloadMessage: 'msg_codes_codes_banner_fileGenerationError',
    toastOnPollingMessage: 'msg_codes_codes_banner_agreementInactive',
    downloadToastType: 'error',
    toastActionText: null,
    pollingToastType: 'error',
    addToDownloadQueue: false,
    retryPolling: false,
    removeFromDownloadQueue: false,
    initiateDownload: false,
    promiseAction: 'resolve',
    toggleLoadingOnClick: false,
    toastUrl: null,
  },
  NOT_REQUESTED: {
    buttonDisabled: false,
    buttonStyle: 'secondary',
    buttonText: 'msg_codes_cta_generate',
    showButton: true,
    onButtonClick: download,
    showLoading: false,
    showToolTip: false,
    statusName: 'NOT_REQUESTED',
    toastOnDownloadMessage: null,
    toastOnPollingMessage: null,
    toastActionText: null,
    downloadToastType: null,
    pollingToastType: null,
    addToDownloadQueue: true,
    retryPolling: false,
    removeFromDownloadQueue: false,
    initiateDownload: false,
    promiseAction: 'resolve',
    toggleLoadingOnClick: true,
    toastUrl: null,
  },
};

export default thirdPartyDownloadStatus;
