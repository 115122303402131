import React, {
  useEffect,
  useReducer,
  useCallback,
  useContext,
  useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  useLocation,
} from 'react-router-dom';
import {
  ActionBar,
  Button,
  Container,
  DataTable,
  Flex,
  Layout,
  NoResults,
  TableHeader,
  TableHeaderCell,
  RowDisplay,
  Pagination,
  Modal,
  ModalContext,
  MODAL_ACTIONS,
} from '@partner-global-ui/components';
import _ from 'lodash';

import SearchHeader from '../common/SearchHeader/SearchHeader';
import FiltersContainer from '../common/Filters/FiltersContainer';
import { filterCounter } from '../../helpers/filterMethods';
import RowDisplayLocalization from '../../helpers/RowDisplayLocalization';
import {
  loadOrders,
  getActiveAgreementRegions,
  getCountries,
} from '../../actions/ordersActions';
import {
  newOrder,
} from '../../actions/orderActions';
import { fireEvent } from '../../actions/trackingActions';
import { getOrderLineStatus, getOrdersLinesStatusSuccess } from '../../actions/pollingActions';
import pollingInterval from '../../api/pollingInterval';
import OrderRow from './Order';
import { usePermission } from '../../utils/accessControl/hasPermission';
import sanitizeSearchTerm from '../../utils/sanitizeSearchTerm';
import history from '../../history';
import roleKeys from '../../utils/accessControl/roleKeys';
import { displayStatuses } from '../../constants/order.constants';
import countries from '../../constants/countries.constants';
import * as defaultRegions from '../../constants/contractingSieRegion.constants';
import * as agreementTypeOptionsMap from '../../constants/agreementTypeOptionsMap.constants';
import isInactiveOrder, { getWarningMessage } from '../../utils/isInactiveOrder';
import { setSessionFilters, getSessionFilters } from '../../utils/sessionFilters';

import './Orders.scss';

const searchCategories = {
  all: 'all',
  ordernumber: 'orderNumber',
  npVoucherBatchId: 'npVoucherBatchId',
  batchLabelCode: 'batchLabelCode',
  orderReference: 'orderReference',
  skuId: 'skuId',
  productId: 'productId',
  partnerName: 'partnerName',
};

const defaultFilters = {
  actionRequired: [],
  creationDateRange: [],
  country: [],
  region: [],
  activationState: [],
  orderStatus: [],
  agreementType: [],
};

// orders page state
const initialPage = {
  empty: false,
  first: true,
  last: false,
  number: 0,
  numberOfElements: 0,
  size: 50,
  sort: { sorted: false, unsorted: true, empty: true },
  totalElements: 0,
  totalPages: 0,
};

export const initialState = {
  filters: defaultFilters,
  filtered: false,
  filterCount: 0,
  pageInformation: initialPage,
  currentPage: 0,
  currentSize: 50,
  isLoadingOrders: true,
  sort: {
    sortBy: 'orderNumber',
    sortDir: 'desc',
  },
  showWarningModal: false,
  modalMessage: '',
  search: { category: searchCategories.all, term: '', searched: false },
  approverFlag: false,
  userRoleSet: false,
  countriesForFilter: countries,
};

const componentActions = {
  changePage: 1,
  changePageSize: 2,
  setPageInformation: 3,
  setIsLoadingOrders: 4,
  changeSort: 5,
  setWarningModalVisibility: 6,
  updateSearch: 7,
  setApproverFlag: 8,
  setCountriesForFilter: 9,
};

export function localStateReducer(
  state,
  {
    type = '',
    name,
    value,
    filters = defaultFilters,
    sessionFilters,
    ...payload
  },
) {
  const filteredState = filters && (
    filters.actionRequired.length > 0
    || filters.creationDateRange.length > 0
    || filters.country.length > 0
    || filters.region.length > 0
    || filters.activationState.length > 0
    || filters.orderStatus.length > 0
    || filters.agreementType.length > 0);

  let newState;

  switch (type) {
    case 'setAllFilter':
      newState = {
        ...state,
        filters: { ...sessionFilters },
        filtered: !filteredState,
      };
      return { ...newState, filterCount: filterCounter(newState.filters) };
    case 'setFilter':
      newState = {
        ...state,
        filters: { ...state.filters, [name]: value },
        filtered: !filteredState,
      };
      return { ...newState, filterCount: filterCounter(newState.filters) };
    case 'clearFilters':
      return {
        ...state,
        filters: defaultFilters,
        filtered: false,
        filterCount: 0,
      };
    case componentActions.changePage:
      return {
        ...state,
        currentPage: payload.pageNumber,
      };
    case componentActions.changePageSize:
      localStorage.setItem(`ordersPageSize-${payload.userId}`, payload.pageSize);
      return {
        ...state,
        currentSize: payload.pageSize,
        currentPage: 0,
      };
    case componentActions.setPageInformation:
      return {
        ...state,
        pageInformation: payload.newPageInformation,
      };
    case componentActions.setIsLoadingOrders:
      return {
        ...state,
        isLoadingOrders: payload.isLoadingOrders,
      };
    case componentActions.changeSort:
      return {
        ...state,
        sort: payload.sort,
        approverFlag: false,
      };
    case componentActions.setWarningModalVisibility:
      return {
        ...state,
        showWarningModal: payload.showWarningModal,
        modalMessage: payload.modalMessage,
      };
    case componentActions.updateSearch:
      return {
        ...state,
        search: payload.search,
        approverFlag: false,
        sort: payload.sort,
      };
    case componentActions.setApproverFlag:
      return {
        ...state,
        approverFlag: payload.approverFlag,
        userRoleSet: true,
        sort: payload.sort,
      };
    case componentActions.setCountriesForFilter:
      return {
        ...state,
        countriesForFilter: payload.countriesForFilter,
      };
    default:
      return state;
  }
}

// try to get Partner Id or return null
const getPartnerIdOrNull = (user) => {
  try {
    return user.domainPartner.partnerId;
  } catch (error) {
    return null;
  }
};

const getUserRole = (user) => {
  try {
    return user.roleCode;
  } catch (error) {
    return null;
  }
};

// getOrderLines to check for updates
const getProcessingOrderLinesAndOrders = (orders = []) => {
  const processingSets = orders.reduce((processingOrders, order) => {
    order.orderLines.forEach((line) => {
      if (line.processingOrderLine) {
        processingOrders.orderLineIds.add(line.orderLineId);
        processingOrders.orderIds.add(order.orderNumber);
      }
    });
    return processingOrders;
  }, { orderIds: new Set(), orderLineIds: new Set() });
  return {
    orderIds: Array.from(processingSets.orderIds),
    orderLineIds: Array.from(processingSets.orderLineIds),
  };
};

// child component configs
const searchOptions = [
  { label: 'msg_codes_ordernum_dropdown', value: searchCategories.ordernumber },
  { label: 'msg_codes_codeBatchID', value: searchCategories.npVoucherBatchId },
  { label: 'msg_codes_orderListAdmin_batchControlLabel_dropdown', value: searchCategories.batchLabelCode },
  { label: 'msg_codes_newOrder_orderName', value: searchCategories.orderReference },
  { label: 'msg_codes_orderList_skuid', value: searchCategories.skuId },
  { label: 'msg_codes_codes_productID', value: searchCategories.productId },
];

const orderActionTypes = [
  { label: 'msg_codes_fulfillmentError', value: 'FULFILLMENT_ERROR' },
  { label: 'msg_codes_fileGenErrors', value: 'FILE_GENERATION_ERROR' },
  { label: 'msg_codes_deliveryErrors', value: 'DELIVERY_ERROR' },
  { label: 'msg_codes_activationErrors', value: 'ACTIVATION_ERROR' },
];

const activationStatuses = [
  { label: 'msg_codes_activation_activated', value: 'ACTIVATED' },
  { label: 'msg_codes_partnerStatus_inactive', value: 'INACTIVE' },
  { label: 'msg_codes_sieRegion_notApplicable', value: 'NOT_APPLICABLE' },
];

const OrdersPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const modalContext = useContext(ModalContext);
  const { user, user: { userId } } = useSelector(state => state);
  const canSeePartnerNameOption = usePermission(roleKeys.order.viewSearchListPartnerName);
  const [sanitizedTerm, setSanitizedTerm] = useState('');

  useEffect(() => {
    if (canSeePartnerNameOption) {
      searchOptions.push(
        {
          label: 'msg_codes_partners_partnerName_label',
          value: searchCategories.partnerName,
        },
      );
    }
    const queryParams = new URLSearchParams(location.search);
    const searchParam = queryParams.toString().split('=');
    setSanitizedTerm(sanitizeSearchTerm(searchParam[1]));
  }, []);

  let updatedInitialState = initialState;
  if (localStorage.getItem(`ordersPageSize-${userId}`) !== null) {
    updatedInitialState = {
      ...updatedInitialState,
      currentSize: Number(localStorage.getItem(`ordersPageSize-${userId}`)),
    };
  }
  const pageParam = new URLSearchParams(location.search).get('page');
  updatedInitialState.currentPage = pageParam
    ? (Number(pageParam) - 1)
    : updatedInitialState.currentPage;

  const [localState, localDispatch] = useReducer(localStateReducer, updatedInitialState);
  const {
    currentPage,
    currentSize,
    pageInformation,
    isLoadingOrders,
    sort,
    search,
    approverFlag,
    userRoleSet,
    countriesForFilter,
    filters,
    filterCount,
  } = localState;

  useEffect(() => {
    const sessionFilters = getSessionFilters('ordersPageFilters');
    const areAllFiltersEmpty = Object.values(sessionFilters).every(filter => filter.length === 0);

    if (!areAllFiltersEmpty) {
      localDispatch({ type: 'setAllFilter', sessionFilters });
    }
  }, []);

  useEffect(() => {
    setSessionFilters('ordersPageFilters', filters);
  }, [filters]);

  const hideWarningModal = () => {
    modalContext.dispatch({
      type: MODAL_ACTIONS.HIDE,
    });
    localDispatch({
      type: componentActions.setWarningModalVisibility,
      showWarningModal: false,
    });
  };

  // Modal Props
  const defaultModalProps = {
    id: 'warning-modal',
    title: t('msg_codes_orderList_warning'),
    primaryLabel: t('msgid_codes_orderList_OK'),
    onPrimary: hideWarningModal,
    onClose: hideWarningModal,
    hideCancelButton: true,
  };

  // If country name and code are Gensen string ids, translate them. Otherwise, set to their values.
  // This might need some refactoring once BE includes the string ids in the /countries response.
  const countryOptions = Object
    .values(countriesForFilter)
    .map(country => ({
      label: country.name.substring(0, 4) === 'msg_' ? t(country.name) : country.name,
      value: country.code.substring(0, 4) === 'msg_' ? t(country.code) : country.code,
    }))
    .sort((a, b) => a.label > b.label ? 1 : -1)
    .map((cty) => {
      return {
        ...cty,
        label: countries[cty.value] && t(countries[cty.value].name),
      };
    });

  const canViewAgreementType = usePermission(roleKeys.order.viewAgreementType);

  const agreementTypes = Object
    .keys(agreementTypeOptionsMap)
    .map((agreementType) => {
      return {
        label: t(agreementTypeOptionsMap[agreementType].translationStringId),
        value: agreementType,
      };
    });
  const partnerIdOrNull = getPartnerIdOrNull(user);
  const removedOrderLines = useSelector(state => state.removedOrderLines);
  const orders = useSelector(state => state.orders);
  const canCreateOrders = usePermission(roleKeys.order.create);
  const orderRegionCheck = usePermission(roleKeys.order.hasOrderRegions, 'regionCheck');

  const hasActiveRegion = (passedUser = { userRoles: [], activeRegions: [] }) => {
    return orderRegionCheck === 'all'
      || (orderRegionCheck === 'some' && passedUser.activeRegions.length > 0);
  };

  const navigateToExistingOrder = (url) => {
    const orderNumber = Number(url.split('/').pop());
    const order = orders.find(o => o.orderNumber === orderNumber);

    if (isInactiveOrder(order)) {
      localDispatch({
        type: componentActions.setWarningModalVisibility,
        showWarningModal: true,
        modalMessage: getWarningMessage(order),
      });

      const modalProps = {
        ...defaultModalProps,
        content: getWarningMessage(order),
      };
      modalContext.dispatch({
        type: MODAL_ACTIONS.SHOW,
        payload: <Modal {...modalProps} />,
      });
    }
  };

  const navigateToNewOrder = () => {
    if (hasActiveRegion(user)) {
      dispatch(fireEvent('new order click', 'click', 'empty', 'New Order', 'page-header',
        '1', 'button'));
      newOrder()(dispatch);
      history.push('/order');
    } else {
      localDispatch({
        type: componentActions.setWarningModalVisibility,
        showWarningModal: true,
        modalMessage: getWarningMessage(),
      });

      const modalProps = {
        ...defaultModalProps,
        content: getWarningMessage(),
      };
      modalContext.dispatch({
        type: MODAL_ACTIONS.SHOW,
        payload: <Modal {...modalProps} />,
      });
    }
  };

  const sortOrders = (name) => {
    const sortDir = {
      [true]: () => 'asc',
      [name === sort.sortBy && sort.sortDir === 'asc']: () => 'desc',
      [name === sort.sortBy && sort.sortDir !== 'asc']: () => 'asc',
    }.true();
    const newSort = { sortBy: name, sortDir };
    localDispatch({ type: componentActions.changeSort, sort: newSort });
  };

  const searchOrders = (term, category) => {
    localDispatch({ type: componentActions.setIsLoadingOrders, isLoadingOrders: true });
    localDispatch({
      type: componentActions.updateSearch,
      search: { category, term, searched: true },
      sort: approverFlag ? { sortBy: 'orderNumber', sortDir: 'desc' } : sort,
    });
    loadOrders(
      dispatch,
      {
        page: currentPage, size: currentSize, sort, approverView: approverFlag,
      },
      { searchType: category, search: term },
      filters,
      removedOrderLines,
    )
      .then((newPageInformation) => {
        localDispatch({ type: componentActions.setPageInformation, newPageInformation });
        localDispatch({ type: componentActions.setIsLoadingOrders, isLoadingOrders: false });
      });
  };

  const renderHeader = useCallback(() => {
    return (
      <Layout className="orders-list-header">
        <SearchHeader
          name="orders-list-search-header"
          title={t('msg_codes_orders')}
          options={searchOptions}
          placeholder={t('msg_codes_codeProdCat_typeSomething_dropdown')}
          clearIcon
          hideSecondaryNav
          changeSearch={searchOrders}
          canSearch
          term={sanitizedTerm}
        />
      </Layout>
    );
  }, [sanitizeSearchTerm]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    localDispatch({ type: 'setFilter', name, value });
  };

  const createFilters = useCallback(() => {
    const {
      actionRequired,
      creationDateRange,
      country,
      region,
      activationState,
      orderStatus,
      agreementType,
    } = filters;

    const actionRequiredOptions = orderActionTypes.map((option) => {
      return {
        label: t(option.label),
        value: option.value,
      };
    });

    const regionOptions = _.map(
      defaultRegions, localRegion => ({ label: localRegion.name, value: localRegion.code }),
    );

    const activationStatusOptions = activationStatuses.map((status) => {
      return {
        label: t(status.label),
        value: status.value,
      };
    });

    const submissionStatusOptions = displayStatuses.map((status) => {
      return {
        label: t(status.translationStringId),
        value: status.value,
      };
    });

    return [
      {
        label: t('msg_codes_orderList_actionRequired'),
        name: 'actionRequired',
        placeholder: t('msg_codes_filter_all'),
        value: actionRequired,
        multiple: true,
        options: actionRequiredOptions,
        handleChange: handleFilterChange,
      },
      {
        label: t('msg_codes_countries_label'),
        name: 'country',
        placeholder: t('msg_codes_filter_all'),
        value: country,
        multiple: true,
        options: countryOptions,
        handleChange: handleFilterChange,
      },
      {
        label: t('msg_codes_common_region'),
        name: 'region',
        placeholder: t('msg_codes_filter_all'),
        value: region,
        multiple: true,
        options: regionOptions,
        handleChange: handleFilterChange,
      },
      {
        label: t('msg_codes_orderList_activationStatus'),
        name: 'activationState',
        placeholder: t('msg_codes_filter_all'),
        value: activationState,
        multiple: true,
        options: activationStatusOptions,
        handleChange: handleFilterChange,
      },
      {
        label: t('msg_codes_codes_submissionStatus'),
        name: 'orderStatus',
        placeholder: t('msg_codes_filter_all'),
        value: orderStatus,
        multiple: true,
        options: submissionStatusOptions,
        handleChange: handleFilterChange,
      },
      {
        label: t('msg_codes_agreements_dateCreated'),
        type: 'dateRange',
        name: 'creationDateRange',
        placeholder: t('msg_codes_codes_releaseDate_prompt'),
        value: creationDateRange,
        handleChange: handleFilterChange,
      },
      ...(canViewAgreementType ? [{
        label: t('msg_codes_orderList_agreement'),
        name: 'agreementType',
        placeholder: t('msg_codes_filter_all'),
        value: agreementType,
        multiple: true,
        options: agreementTypes,
        handleChange: handleFilterChange,
      }] : []
      ),
    ];
  }, [filters, currentPage]);

  // Action Bar
  const actionBar = () => {
    const filterOrdersList = () => {
      localDispatch({ type: componentActions.setIsLoadingOrders, isLoadingOrders: true });
      loadOrders(
        dispatch,
        {
          size: currentSize, sort, approverView: approverFlag,
        },
        search,
        filters,
        removedOrderLines,
      ).then((newPageInformation) => {
        localDispatch({ type: componentActions.setPageInformation, newPageInformation });
        localDispatch({
          type: componentActions.updateSearch,
          search: { ...search, searched: true },
          sort: approverFlag ? { sortBy: 'orderNumber', sortDir: 'desc' } : sort,
        });
        localDispatch({ type: componentActions.setIsLoadingOrders, isLoadingOrders: false });
      });
    };
    const clearFilters = () => {
      loadOrders(
        dispatch,
        {
          size: currentSize, sort, approverView: approverFlag,
        },
        search,
        defaultFilters,
        removedOrderLines,
      )
        .then((response) => {
          const { content, ...newPageInformation } = response;
          localDispatch({ type: componentActions.setPageInformation, newPageInformation });
          localDispatch({ type: 'clearFilters' });
        });
    };

    return (
      <ActionBar
        filterCount={filterCount}
        onFilterApply={filterOrdersList}
        onFilterClear={clearFilters}
        filters={
          <FiltersContainer filters={createFilters()} />
        }
        filterClearLabel={t('msg_codes_cta_clear')}
        filterPrimaryLabel={t('msg_codes_cta_apply')}
        filterSecondaryLabel={t('msg_codes_cta_cancel')}
        filterToolTipText={t('msg_codes_filter')}
        filterTitle={t('msg_codes_filter')}
        right={
          canCreateOrders && (
            <Button
              id="new-order-button"
              onClick={() => navigateToNewOrder()}
              primary
              className="new-order-button"
              icon="ico-plus"
            >
              {t('msg_codes_cta_newOrder')}
            </Button>
          )
        }
      />
    );
  };

  // Table Header Cell
  const tableHeaders = [
    {
      id: 'orderReference',
      value: 'orderReference',
      stringId: t('msg_codes_newOrder_orderName'),
      sortable: true,
    },
    {
      id: 'partnerName',
      value: 'partnerName',
      stringId: t('msg_codes_accountType_partner'),
      sortable: true,
    },
    {
      id: 'orderNumber',
      value: 'orderNumber',
      stringId: t('msg_codes_ordernum'),
      sortable: true,
    },
    {
      id: 'region',
      value: 'region',
      stringId: t('msg_codes_common_region'),
      sortable: true,
    },
    ...(canViewAgreementType ? [{
      id: 'agreementType',
      value: 'agreementType',
      stringId: t('msg_codes_orderList_agreement'),
      sortable: true,
    }] : []),
    {
      id: 'creationDate',
      value: 'creationDate',
      stringId: t('msg_codes_common_created'),
      sortable: true,
    },
    {
      id: 'orderStatusName',
      value: 'orderStatusName',
      stringId: t('msg_codes_codes_submissionStatus'),
      sortable: true,
    },
  ];

  const renderHeaderCells = () => {
    return tableHeaders.map((item) => {
      return (
        <TableHeaderCell
          id={item.id}
          key={item.id}
          value={item.value}
          sort={sort}
          onClick={sortOrders}
          sortable={item.sortable}
          hasExpandable
        >
          {item.stringId}
        </TableHeaderCell>
      );
    });
  };

  const columnTemplate = canViewAgreementType ? '24% 12% 8% 9% 15% 12% 20%' : '30% 15% 10% 10% 15% 20%';

  const changePageSizeHandler = (pageSize) => {
    localDispatch({ type: componentActions.changePageSize, pageSize, userId });
  };

  const changePageHandler = (pageNumber) => {
    localDispatch({ type: componentActions.changePage, pageNumber: pageNumber - 1 });
    history.replace(`/orders?page=${pageNumber}`);
    setSanitizedTerm('');
  };

  // get active regions when partner Id changes
  useEffect(() => {
    if (typeof partnerIdOrNull === 'number') {
      getActiveAgreementRegions(partnerIdOrNull)(dispatch);
    }
  }, [partnerIdOrNull, dispatch]);

  useEffect(() => {
    // Set Approver flag for special approver view of orders
    const isApprover = getUserRole(user) === 'APPROVER';
    localDispatch({
      type: componentActions.setApproverFlag,
      approverFlag: isApprover,
      sort: isApprover
        ? {
          sortBy: '',
          sortDir: '',
        }
        : initialState.sort,
    });

    // Fetch list of countries
    getCountries()(dispatch)
      .then((response) => {
        localDispatch({
          type: componentActions.setCountriesForFilter,
          countriesForFilter: response,
        });
      });
  }, []);

  useEffect(() => {
    localDispatch({ type: componentActions.setIsLoadingOrders, isLoadingOrders: true });
    if (userRoleSet) {
      loadOrders(
        dispatch,
        {
          page: currentPage, size: currentSize, sort, approverView: approverFlag,
        },
        { searchType: search.category, search: search.term },
        filters,
        removedOrderLines,
      )
        .then((newPageInformation) => {
          localDispatch({ type: componentActions.setPageInformation, newPageInformation });
          localDispatch({ type: componentActions.setIsLoadingOrders, isLoadingOrders: false });
        });
    }
  }, [
    currentPage,
    currentSize,
    sort,
    removedOrderLines,
    approverFlag,
    dispatch,
    userRoleSet,
  ]);

  // setup interval for updating processing order lines
  useEffect(() => {
    let checkProcessingOrderLinesInterval;
    const { orderLineIds, orderIds } = getProcessingOrderLinesAndOrders(orders);
    if (orderIds.length > 0) {
      checkProcessingOrderLinesInterval = setInterval(() => {
        getOrderLineStatus(orderLineIds, orderIds, getOrdersLinesStatusSuccess)(dispatch);
      }, pollingInterval);
    }
    return () => {
      if (orderIds.length > 0) {
        // Clean up the subscription
        clearInterval(checkProcessingOrderLinesInterval);
      }
    };
  }, [orders, dispatch, pollingInterval]);

  // Render
  return (
    <Container id="orders-list" className="orders orders-list">
      {renderHeader()}
      <Layout>
        <Flex>
          <DataTable
            actionBar={actionBar()}
            columnTemplate={columnTemplate}
            loading={isLoadingOrders}
          >
            <TableHeader>
              {renderHeaderCells()}
            </TableHeader>
            <>
              {orders
                .map(order => (
                  <OrderRow
                    name="ordersRow"
                    key={order.orderNumber}
                    order={order}
                    navigate={navigateToExistingOrder}
                    showAgreementColumn={canViewAgreementType}
                  />
                ))
              }
              {pageInformation.empty && (
                <NoResults>{t('msg_codes_noOrdersFound')}</NoResults>
              )}
            </>
          </DataTable>
          <div>
            {pageInformation.totalElements > 10 && (
              <div className="order-list-pagination">
                <RowDisplay
                  currentPage={currentPage + 1}
                  totalItems={pageInformation.totalElements}
                  pageSizes={[10, 25, 50, 100]}
                  onPageSizeChange={changePageSizeHandler}
                  initialPageSize={pageInformation.size}
                  showingOfText={RowDisplayLocalization('msg_codes_pagination_showing')(t)}
                />
                {pageInformation.totalElements > pageInformation.size && (
                  <Pagination
                    totalRecords={pageInformation.totalElements}
                    currentPage={currentPage + 1}
                    pageLimit={pageInformation.size}
                    onPageItemClick={changePageHandler}
                  />
                )}
              </div>
            )}
          </div>
        </Flex>
      </Layout>
    </Container>
  );
};

export default OrdersPage;
