/* ///// MISC ///// */
export const BEGIN_AJAX_CALL = 'BEGIN_AJAX_CALL';
export const AJAX_CALL_ERROR = 'AJAX_CALL_ERROR';
export const VERSION_MISMATCH = 'VERSION_MISMATCH';
export const CHANGE_PAGE = 'CHANGE_PAGE';
export const FIRE_EVENT = 'FIRE_EVENT';

/* 3rd party cart */
export const BEGIN_LOAD_CART = 'BEGIN_LOAD_CART';
export const FINISH_LOAD_CART = 'FINISH_LOAD_CART';
export const UPDATE_CART_SIZE = 'UPDATE_CART_SIZE';
export const INCREMENT_CART_SIZE = 'INCREMENT_CART_SIZE';
export const DECREMENT_CART_SIZE = 'DECREMENT_CART_SIZE';
export const LOAD_CART_FROM_LOCAL_STORAGE = 'LOAD_CART_FROM_LOCAL_STORAGE';
export const ADD_ITEM_FROM_CATALOG = 'ADD_ITEM_FROM_CATALOG';
export const REMOVE_ITEM_FROM_CART = 'REMOVE_ITEM_FROM_CART';
export const EMPTY_CART = 'EMPTY_CART';
export const UPDATE_CART_ITEM_QUANTITY = 'UPDATE_CART_ITEM_QUANTITY';
export const UPDATE_CART_ITEM_USE_COUNT = 'UPDATE_CART_ITEM_USE_COUNT';
export const UPDATE_CART_ITEM_PO_NUMBER = 'UPDATE_CART_ITEM_PO_NUMBER';
export const UPDATE_CART_ITEM_PO_FILE = 'UPDATE_CART_ITEM_PO_FILE';
export const UPDATE_CART_ITEM_START_DATE = 'UPDATE_CART_ITEM_START_DATE';
export const UPDATE_CART_ITEM_END_DATE = 'UPDATE_CART_ITEM_END_DATE';
export const UPDATE_CART_ITEM_START_TIME = 'UPDATE_CART_ITEM_START_TIME';
export const UPDATE_CART_ITEM_END_TIME = 'UPDATE_CART_ITEM_END_TIME';
export const UPDATE_CART_PARTNER_COMMENTS = 'UPDATE_CART_PARTNER_COMMENTS';
export const REMOVE_PO_FILES = 'REMOVE_PO_FILES';

/* ///// THIRD PARTY CATALOGS ///// */
export const BEGIN_LOAD_THIRD_PARTY_CATALOG = 'BEGIN_LOAD_THIRD_PARTY_CATALOG';
export const FINISH_LOAD_THIRD_PARTY_CATALOG = 'FINISH_LOAD_THIRD_PARTY_CATALOG';
export const BEGIN_LOAD_PARTNERS = 'BEGIN_LOAD_PARTNERS';
export const END_LOAD_PARTNERS = 'END_LOAD_PARTNERS';
export const BEGIN_FILTER_VOUCHERS = 'BEGIN_FILTER_VOUCHERS';
export const FINISH_FILTER_VOUCHERS = 'FINISH_FILTER_VOUCHERS';
export const THIRD_PARTY_CATALOG_PARTNER = 'THIRD_PARTY_CATALOG_PARTNER';
export const SET_THIRD_PARTY_CATALOG_PAGE = 'SET_THIRD_PARTY_CATALOG_PAGE';
export const SET_THIRD_PARTY_PRODUCT = 'SET_THIRD_PARTY_PRODUCT';
export const SET_VOUCHERS_PAGE = 'SET_VOUCHERS_PAGE';
export const SET_VOUCHER = 'SET_VOUCHER';
export const UPDATE_FROM_POLLING = 'UPDATE_FROM_POLLING';

export const BEGIN_LOAD_VOUCHERS = 'BEGIN_LOAD_VOUCHERS';
export const FINISH_LOAD_VOUCHERS = 'FINISH_LOAD_VOUCHERS';
export const SET_VOUCHERS_FILTER = 'SET_VOUCHERS_FILTER';
export const BEGIN_LOAD_ORDERS = 'BEGIN_LOAD_ORDERS';
export const BEGIN_LOAD_ORDER = 'BEGIN_LOAD_ORDER';
export const FINISH_LOAD_APP = 'FINISH_LOAD_APP';
export const SET_APP_CONFIG = 'SET_APP_CONFIG';
export const SET_MAINTAINANCE_MODE_CONFIG = 'SET_MAINTAINANCE_MODE_CONFIG';
export const FINISH_LOAD_ORDERS = 'FINISH_LOAD_ORDERS';
export const FINISH_LOAD_ORDER = 'FINISH_LOAD_ORDER';
export const BEGIN_REMOVE_ITEM = 'BEGIN_REMOVE_ITEM';
export const FINISH_REMOVE_ITEM = 'FINISH_REMOVE_ITEM';
export const BEGIN_UPDATE_ITEM = 'BEGIN_UPDATE_ITEM';
export const FINISH_UPDATE_ITEM = 'FINISH_UPDATE_ITEM';
export const ACTIVATE_ITEM_SUCCESS = 'ACTIVATE_ITEM_SUCCESS';
export const CANCEL_ITEM_SUCCESS = 'CANCEL_ITEM_SUCCESS';
export const SET_THIRD_PARTY_CATALOG_FILTER = 'SET_THIRD_PARTY_CATALOG_FILTER';
export const SET_VOUCHERS_SEARCH = 'SET_VOUCHERS_SEARCH';
export const CLEAR_VOUCHERS_SEARCH = 'CLEAR_VOUCHERS_SEARCH';
export const SET_COUNTRY_FILTER_OPTIONS = 'SET_COUNTRY_FILTER_OPTIONS';
export const SET_AGENCY_VOUCHER_TYPES = 'SET_AGENCY_VOUCHER_TYPES';
export const SET_FIRST_PARTY_PUBLISHER_VOUCHER_TYPES = 'SET_FIRST_PARTY_PUBLISHER_VOUCHER_TYPES';
export const SET_ALL_VOUCHER_TYPES = 'SET_ALL_VOUCHER_TYPES';
export const RESET_THIRD_PARTY_CATALOG_FILTERING = 'RESET_THIRD_PARTY_CATALOG_FILTERING';
export const BEGIN_LOAD_COUNTRIES = 'BEGIN_LOAD_COUNTRIES';
export const FINISH_LOAD_COUNTRIES = 'FINISH_LOAD_COUNTRIES';

/* ///// ORDERS ///// */
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const APPROVE_ORDER_SUCCESS = 'APPROVE_ORDER_SUCCESS';
export const REJECT_ORDER_SUCCESS = 'REJECT_ORDER_SUCCESS';
export const SUBMIT_ORDER_SUCCESS = 'SUBMIT_ORDER_SUCCESS';
export const CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS';
export const ORDER_LINE_STATUS = 'ORDER_LINE_STATUS';
export const ORDERS_LINES_STATUS = 'ORDERS_LINES_STATUS';
export const SAVE_ORDER_SUCCESS = 'SAVE_ORDER_SUCCESS';
export const SAVE_ORDER_FAILURE = 'SAVE_ORDER_FAILURE';
export const BEGIN_SAVE_ORDER = 'BEGIN_SAVE_ORDER';
export const BEGIN_APPROVE_ORDER = 'BEGIN_APPROVE_ORDER';
export const BEGIN_SUBMIT_ORDER = 'BEGIN_SUBMIT_ORDER';
export const BEGIN_REJECT_ORDER = 'BEGIN_REJECT_ORDER';
export const BEGIN_REQUEST_AMENDMENT = 'BEGIN_REQUEST_AMENDMENT';
export const BEGIN_RETRY_ORDER_LINE = 'BEGIN_RETRY_ORDER_LINE';
export const LOAD_ORDER_SUCCESS = 'LOAD_ORDER_SUCCESS';
export const REQUEST_AMENDMENT_SUCCESS = 'REQUEST_AMENDMENT_SUCCESS';
export const RETRY_ORDER_LINE_SUCCESS = 'RETRY_ORDER_LINE_SUCCESS';
export const DELIVER_ORDER_LINE_SUCCESS = 'DELIVER_ORDER_LINE_SUCCESS';
export const ADD_ITEMS_SUCCESS = 'ADD_ITEMS_SUCCESS';
export const REMOVE_ITEM_SUCCESS = 'REMOVE_ITEM_SUCCESS';
export const CHANGE_PROP_SUCCESS = 'CHANGE_PROP_SUCCESS';
export const NEW_ORDER_SUCCESS = 'NEW_ORDER_SUCCESS';
export const CHANGE_PROP_ON_LINE_SUCCESS = 'CHANGE_PROP_ON_LINE_SUCCESS';
export const GET_ACTIVE_AGREEMENT_REGIONS = 'GET_ACTIVE_AGREEMENT_REGIONS';
export const SELECTED_ORDERLINES_LIST = 'SELECTED_ORDERLINES_LIST';
export const NO_ORDERS_AFFECTED = 'NO_ORDERS_AFFECTED';
export const GET_ACTIVE_AGREEMENT_VERSION_SUCCESS = 'GET_ACTIVE_AGREEMENT_VERSION_SUCCESS';
export const GET_ACTIVE_AGREEMENT_VERSION_ERROR = 'GET_ACTIVE_AGREEMENT_VERSION_ERROR';
export const REMOVE_ACTIVE_AGREEMENT_VERSION = 'REMOVE_ACTIVE_AGREEMENT_VERSION';
export const SET_ORDER_AUDIT_FIELDS = 'SET_ORDER_AUDIT_FIELDS';
export const SAVE_INITIAL_ORDER = 'SAVE_INITIAL_ORDER';
export const RESET_INITIAL_ORDER = 'RESET_INITIAL_ORDER';
export const SET_PARTNER_FOR_ORDER = 'SET_PARTNER_FOR_ORDER';
export const CHANGE_SHARE_WITH_PARTNER = 'CHANGE_SHARE_WITH_PARTNER';
export const ADD_ORDER_DOCUMENT = 'ADD_ORDER_DOCUMENT';
export const REMOVE_ORDER_DOCUMENT = 'REMOVE_ORDER_DOCUMENT';
export const UPDATE_ORDER_DOCUMENTS_SCAN_STATUS = 'UPDATE_ORDER_DOCUMENTS_SCAN_STATUS';


/* ///// PARTNERS ///// */
export const CHANGE_PARTNER_PROP_SUCCESS = 'CHANGE_PARTNER_PROP_SUCCESS';
export const GET_PARTNERS_SUCCESS = 'GET_PARTNERS_SUCCESS';
export const LOAD_PARTNERS_SUCCESS = 'LOAD_PARTNERS_SUCCESS';
export const LOAD_PARTNER_PENDING = 'LOAD_PARTNER_PENDING';
export const LOAD_PARTNER_SUCCESS = 'LOAD_PARTNER_SUCCESS';
export const LOAD_PARTNER_REJECTED = 'LOAD_PARTNER_REJECTED';
export const SAVE_PARTNER_SUCCESS = 'SAVE_PARTNER_SUCCESS';
export const NEW_PARTNER_SUCCESS = 'NEW_PARTNER_SUCCESS';
export const GET_PARTNER_PROJECTION_SUCCESS = 'GET_PARTNER_PROJECTION_SUCCESS';
export const SET_PARTNERS_PAGE = 'SET_PARTNERS_PAGE';
export const SET_PARTNERS_FILTER = 'SET_PARTNERS_FILTER';
export const RESET_PARTNERS_PAGE_FILTERING = 'RESET_PARTNERS_PAGE_FILTERING';

/* ///// AGREEMENT ///// */
export const RESET_AGREEMENT = 'RESET_AGREEMENT';
export const SAVE_NEW_AGREEMENT = 'SAVE_NEW_AGREEMENT_SUCCESS';
export const TOGGLE_ALL_COUNTRIES = 'TOGGLE_ALL_COUNTRIES';
export const SAVE_NEW_AGREEMENT_VERSION = 'SAVE_NEW_AGREEMENT_VERSION';
export const BEGIN_LOAD_AGREEMENT = 'BEGIN_LOAD_AGREEMENT';
export const LOAD_AGREEMENT_SUCCESS = 'LOAD_AGREEMENT_SUCCESS';
export const CLEAR_AGREEMENTS = 'CLEAR_AGREEMENTS';
export const UPDATE_AGREEMENT_VERSION_DRAFT = 'UPDATE_AGREEMENT_VERSION_DRAFT';
export const UPDATE_AGREEMENT_VOUCHER = 'UPDATE_AGREEMENT_VOUCHER';
export const UPDATE_COUNTRY = 'UPDATE_COUNTRY';
export const UPDATE_AGREEMENT_FORM = 'UPDATE_AGREEMENT_FORM';
export const UPDATE_AGREEMENT_PROPERTY = 'UPDATE_AGREEMENT_PROPERTY';
export const SET_CURRENCIES = 'ADD_CURRENCIES';
export const SET_COUNTRIES = 'SET_COUNTIRES';
export const NEW_AGREEMENT_SUCCESS = 'NEW_AGREEMENT_SUCCESS';
export const SAVE_AGREEMENT_SUCCESS = 'SAVE_AGREEMENT_SUCCESS';
export const LOAD_AGREEMENT_PARTNER_SUCCESS = 'LOAD_AGREEMENT_PARTNER_SUCCESS';
export const LOAD_AGREEMENT_VERSION = 'LOAD_AGREEMENT_VERSION';
export const CAN_AGREEMENT_SAVE = 'CAN_AGREEMENT_SAVE';
export const SET_AGREEMENT_VERSION = 'SET_AGREEMENT_VERSION';
export const ADD_FEE_STRUCTURE = 'ADD_FEE_STRUCTURE';
export const REMOVE_FEE_STRUCTURE = 'REMOVE_NEW_FEE_STRUCTURE';
export const UPDATE_FEE_STRUCTURE = 'UPDATE_NEW_FEE_STRUCTURE';
export const REMOVE_ALL_FEE_STRUCTURES = 'REMOVE_ALL_FEE_STRUCTURES';
export const AGREEMENT_NOT_FOUND = 'AGREEMENT_NOT_FOUND';
export const AGREEMENT_VERSION_MISMATCH = 'AGREEMENT_VERSION_MISMATCH';
export const AGREEMENT_VERSION_LATEST_FINAL_EFFECTIVE_DATE = 'AGREEMENT_VERSION_LATEST_FINAL_EFFECTIVE_DATE';
export const BEGIN_AGREEMENT_SAVE = 'BEGIN_AGREEMENT_SAVE';
export const AGREEMENT_SAVE_ERROR = 'AGREEMENT_SAVE_ERROR';

/* ///// Agreements Page ///// */
export const GET_AGREEMENTS_SUCCESS = 'GET_AGREEMENTS_SUCCESS';
export const BEGIN_LOAD_AGREEMENTS = 'BEGIN_LOAD_AGREEMENTS';
export const LOAD_AGREEMENTS_SUCCESS = 'LOAD_AGREEMENTS_SUCCESS';
export const LOAD_AGREEMENTS_ERROR = 'LOAD_AGREEMENTS_ERROR';
export const SET_AGREEMENTS_PAGE = 'SET_AGREEMENTS_PAGE';
export const SET_INITIAL_STATUSES = 'SET_INITIAL_STATUSES';
export const SET_AGREEMENTS_FILTER = 'SET_AGREEMENTS_FILTER';
export const SET_AGREEMENTS_SEARCH = 'SET_AGREEMENTS_SEARCH';
export const RESET_AGREEMENTS_PAGE_FILTERING = 'RESET_AGREEMENTS_PAGE_FILTERING';
export const RESET_HAS_BEEN_MODIFIED = 'RESET_HAS_BEEN_MODIFIED';

/* ///// DRAFT AGREEMENT VERSION ///// */
export const RESET_DRAFT = 'RESET_DRAFT';
export const LOAD_DRAFT_AGREEMENT_VERSION = 'LOAD_DRAFT_AGREEMENT_VERSION';
export const ADD_EMPTY_VOUCHER_PRODUCT_TYPE = 'ADD_EMPTY_VOUCHER_PRODUCT_TYPE';
export const REMOVE_VOUCHER_PRODUCT_TYPE = 'REMOVE_VOUCHER_PRODUCT_TYPE';
export const RESET_VOUCHER_PRODUCT_TYPE_COVERAGE_SET = 'RESET_VOUCHER_PRODUCT_TYPE_COVERAGE_SET';
export const SET_CAN_EDIT_VERSION = 'SET_CAN_EDIT_VERSION';
export const PRODUCT_TYPE_HAS_BEEN_ADDED = 'PRODUCT_TYPE_HAS_BEEN_ADDED';

/* ///// USERS ///// */
// this sets the logged in user
export const SET_USER = 'SET_USER';
// these handle account users
export const BEGIN_LOAD_USERS = 'BEGIN_LOAD_USERS';
export const LOAD_USER_LIST_SUCCESS = 'LOAD_USER_LIST_SUCCESS';
export const SET_USERS_PAGE = 'SET_USERS_PAGE';
export const SET_USER_LIST_PAGE = 'SET_USER_LIST_PAGE';

export const CLEAR_USERS_SEARCH = 'CLEAR_USERS_SEARCH';
export const FINISH_FILTER_USERS = 'FINISH_FILTER_USERS';
export const FINISH_LOAD_USERS = 'FINISH_LOAD_USERS';
export const FINISH_LOAD_PARTNER_USERS = 'FINISH_PARTNER_USERS';

export const RESET_USERS_FILTERING = 'RESET_USERS_FILTERING';
export const RESET_USERS_PAGE = 'RESET_USERS_PAGE';
export const SET_USERS_FILTER = 'SET_USERS_FILTER';
export const SET_USERS_SEARCH = 'SET_USERS_SEARCH';

/* ///// USER ///// */
export const BEGIN_LOAD_USER = 'BEGIN_LOAD_USER';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';

export const BEGIN_LOAD_USER_DETAIL = 'BEGIN_LOAD_USER_DETAIL';
export const LOAD_USER_DETAIL_SUCCESS = 'LOAD_USER_DETAIL_SUCCESS';
export const COMPLETE_USER_FOUND = 'COMPLETE_USER_FOUND';
export const SET_EXTERNAL_USER_STATUS = 'SET_EXTERNAL_USER_STATUS';
export const SET_CHANGES_MADE = 'SET_CHANGES_MADE';
export const RESET_USER_PAGE = 'RESET_USER_PAGE';

export const RESET_PGP_KEY = 'RESET_PGP_KEY';
export const UPDATE_PGP_KEY = 'UPDATE_PGP_KEY';
export const VALIDATE_PGP_KEY = 'VALIDATE_PGP_KEY';
export const ADD_PGP_KEY = 'ADD_PGP_KEY';
export const REMOVE_PGP_KEY = 'REMOVE_PGP_KEY';
export const EDIT_PGP_KEY = 'EDIT_PGP_KEY';
export const DOWNLOAD_PGP_KEY = 'DOWNLOAD_PGP_KEY';

export const ACCEPT_TERMS_SUCCESS = 'ACCEPT_TERMS_SUCCESS';
export const ACCEPT_TERMS_ERROR = 'ACCEPT_TERMS_ERROR';

export const CANCELED_PGP_SETUP = 'CANCELED_PGP_SETUP';
export const VIEWED_PGP_SETUP_PAGE = 'VIEWED_PGP_SETUP_PAGE';
export const REMOVE_PGP_KEY_FROM_USER = 'REMOVE_PGP_KEY_FROM_USER';

/* ///// SFTPS ///// */
export const BEGIN_LOAD_SFTPS = 'BEGIN_LOAD_SFTP';
export const LOAD_SFTP_LIST_SUCCESS = 'LOAD_SFTP_LIST_SUCCESS';
export const SET_SFTPS_PAGE = 'SET_SFTPS_PAGE';
export const CLEAR_PARTNER_SFTPS = 'CLEAR_PARTNER_SFTPS';

/* ///// Order Line Cache ///// */
export const LOAD_ORDER_LINE_CACHE = 'LOAD_ORDER_LINE_CACHE';
export const ADD_REMOVED_ORDER_LINES_TO_CACHE = 'ADD_REMOVED_ORDER_LINES_TO_CACHE';

/* ///// Callback Gateway Service ///// */
export const SET_CALLBACK_GATEWAYS = 'SET_CALLBACK_GATEWAYS';

/* ///// VOUCHER TYPES ///// */
export const BEGIN_LOAD_VOUCHER_TYPES = 'BEGIN_LOAD_VOUCHER_TYPES';
export const FINISH_LOAD_VOUCHER_TYPES = 'FINISH_LOAD_VOUCHER_TYPES';
export const SET_VOUCHER_TYPES_PAGE = 'SET_VOUCHER_TYPES_PAGE';
export const SET_VOUCHER_TYPE = 'SET_VOUCHER_TYPE';
export const FINISH_FILTER_VOUCHER_TYPES = 'FINISH_FILTER_VOUCHER_TYPES';
export const SET_VOUCHER_TYPES_FILTER = 'SET_VOUCHER_TYPES_FILTER';
export const SET_VOUCHER_TYPES_SEARCH = 'SET_VOUCHER_TYPES_SEARCH';
export const CLEAR_VOUCHER_TYPES_SEARCH = 'CLEAR_VOUCHER_TYPES_SEARCH';
export const RESET_VOUCHER_TYPES_FILTERING = 'RESET_VOUCHER_TYPES_FILTERING';
export const RESET_VOUCHER_TYPES_PAGE = 'RESET_VOUCHER_TYPES_PAGE';
export const BEGIN_LOAD_VOUCHER_TYPE = 'BEGIN_LOAD_VOUCHER_TYPE';
export const LOAD_VOUCHER_TYPE_SUCCESS = 'LOAD_VOUCHER_TYPE_SUCCESS';
export const LOAD_VOUCHER_TYPE_FAILURE = 'LOAD_VOUCHER_TYPE_FAILURE';
export const LOAD_NEW_VOUCHER_TYPE = 'LOAD_NEW_VOUCHER_TYPE';
export const CHANGE_VOUCHER_TYPE_PROP = 'CHANGE_VOUCHER_TYPE_PROP';
export const SAVE_VOUCHER_TYPE_SUCCESS = 'SAVE_VOUCHER_TYPE_SUCCESS';
export const SET_VOUCHER_TYPE_BATCH_PAGE = 'SET_VOUCHER_TYPE_BATCH_PAGE';
export const RESET_VOUCHER_TYPE_BATCH_PAGE = 'RESET_VOUCHER_TYPE_BATCH_PAGE';
export const BEGIN_LOAD_VOUCHER_TYPE_BATCHES = 'BEGIN_LOAD_VOUCHER_TYPE_BATCHES';
export const FINISH_LOAD_VOUCHER_TYPE_BATCHES = 'FINISH_LOAD_VOUCHER_TYPE_BATCHES';
export const FINISH_LOAD_SELECT_VOUCHER_TYPE_BATCHES = 'FINISH_LOAD_SELECT_VOUCHER_TYPE_BATCHES';
export const BEGIN_LOAD_SKUS = 'BEGIN_LOAD_SKUS';
export const FINISH_LOAD_SKUS = 'FINISH_LOAD_SKUS';
export const SET_ADD_SKU_PAGE = 'SET_ADD_SKU_PAGE';
export const SET_SELECTED_SKUS = 'SET_SELECTED_SKUS';
export const SET_SKU_SEARCH = 'SET_SKUS_SEARCH';
export const RESET_ADD_SKU_PAGE = 'RESET_ADD_SKU_PAGE';
export const RESET_VOUCHER_TYPE = 'RESET_VOUCHER_TYPE';

/* ///// VOUCHER BATCHES ///// */
export const BEGIN_LOAD_VOUCHER_BATCHES = 'BEGIN_LOAD_VOUCHER_BATCHES';
export const FINISH_LOAD_VOUCHER_BATCHES = 'FINISH_LOAD_VOUCHER_BATCHES';
export const BEGIN_LOAD_SELECT_VOUCHER_BATCHES = 'BEGIN_LOAD_SELECT_VOUCHER_BATCHES';
export const FINISH_LOAD_SELECT_VOUCHER_BATCHES = 'FINISH_LOAD_SELECT_VOUCHER_BATCHES';
export const SET_VOUCHER_BATCHES_PAGE = 'SET_VOUCHER_BATCHES_PAGE';
export const SET_VOUCHER_BATCH = 'SET_VOUCHER_BATCH';
export const FINISH_FILTER_VOUCHER_BATCHES = 'FINISH_FILTER_VOUCHER_BATCHES';
export const SET_VOUCHER_BATCHES_FILTER = 'SET_VOUCHER_BATCHES_FILTER';
export const SET_VOUCHER_BATCHES_SEARCH = 'SET_VOUCHER_BATCHES_SEARCH';
export const CLEAR_VOUCHER_BATCHES_SEARCH = 'CLEAR_VOUCHER_BATCHES_SEARCH';
export const RESET_VOUCHER_BATCHES_FILTERING = 'RESET_VOUCHER_BATCHES_FILTERING';
export const RESET_VOUCHER_BATCHES_PAGE = 'RESET_VOUCHER_BATCHES_PAGE';
export const BEGIN_LOAD_VOUCHER_BATCH = 'BEGIN_LOAD_VOUCHER_BATCH';
export const LOAD_VOUCHER_BATCH_SUCCESS = 'LOAD_VOUCHER_BATCH_SUCCESS';
export const LOAD_VOUCHER_BATCH_FAILURE = 'LOAD_VOUCHER_BATCH_FAILURE';
export const LOAD_NEW_VOUCHER_BATCH = 'LOAD_NEW_VOUCHER_BATCH';
export const CHANGE_VOUCHER_BATCH_PROP = 'CHANGE_VOUCHER_BATCH_PROP';
export const SAVE_VOUCHER_BATCH_SUCCESS = 'SAVE_VOUCHER_BATCH_SUCCESS';
export const RESET_VOUCHER_BATCH = 'RESET_VOUCHER_BATCH';
export const VOUCHER_BATCH_ACTION_SUCCESS = 'VOUCHER_BATCH_ACTION_SUCCESS';
export const VOUCHER_BATCH_POLLING_SUCCESS = 'VOUCHER_BATCH_POLLING_SUCCESS';
export const LOAD_VOUCHER_BATCH_AUDIT_HISTORY_SUCCESS = 'LOAD_VOUCHER_BATCH_AUDIT_HISTORY_SUCCESS';
export const LOAD_VOUCHER_BATCH_AUDIT_HISTORY_FAILURE = 'LOAD_VOUCHER_BATCH_AUDIT_HISTORY_FAILURE';
export const RESET_VOUCHER_BATCH_AUDIT_HISTORY = 'RESET_VOUCHER_BATCH_AUDIT_HISTORY';

/* ///// PRODUCT MANAGEMENT ///// */
export const SET_PRODUCT_PROP = 'SET_PRODUCT_PROP';
export const UPDATE_COUNTRIES = 'UPDATE_COUNTRIES';
export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PRODUCT_SKU = 'GET_PRODUCT_SKU';
export const SAVE_MONEY_PRODUCT = 'SAVE_MONEY_PRODUCT';
export const CLEAR_PRODUCT = 'CLEAR_PRODUCT';
export const CLEAR_PRODUCT_DETAIL = 'CLEAR_PRODUCT_DETAIL';
export const BEGIN_SAVE_CODE_PRODUCT = 'BEGIN_SAVE_CODE_PRODUCT';
export const SAVE_CODE_PRODUCT_SUCCESS = 'SAVE_CODE_PRODUCT_SUCCESS';
export const SET_APPLICABLE_AGREEMENT_TYPES_MAP = 'SET_APPLICABLE_AGREEMENT_TYPES_MAP';
export const SET_EXCLUSIVE_TO_PARTNERS_MAP = 'SET_EXCLUSIVE_TO_PARTNERS_MAP';

/* ///// CODE PRODUCTS ///// */
export const BEGIN_LOAD_CODE_PRODUCTS = 'BEGIN_LOAD_CODE_PRODUCTS';
export const LOAD_CODE_PRODUCTS = 'LOAD_CODE_PRODUCTS';
export const LOAD_CODE_PRODUCTS_SUCCESS = 'LOAD_CODE_PRODUCTS_SUCCESS';
export const SET_CODE_PRODUCTS_PAGE = 'SET_CODE_PRODUCTS_PAGE';
export const RESET_CODE_PRODUCTS_PAGE = 'RESET_CODE_PRODUCTS_PAGE';
export const SET_IMPACTED_ORDERLINES = 'SET_IMPACTED_ORDERLINES';
export const EXISTING_FOR_CONFIGURATION = 'EXISTING_FOR_CONFIGURATION';
export const SET_ORDER_ACTIVITY_RULES = 'SET_ORDER_ACTIVITY_RULES';

/* ///// ORDER HISTORY ///// */
export const BEGIN_LOAD_ORDER_HISTORY = 'BEGIN_LOAD_ORDER_HISTORY';
export const SET_ORDER_HISTORY_CATALOG = 'SET_ORDER_HISTORY_CATALOG';
export const FINISH_LOAD_ORDER_HISTORY = 'FINISH_LOAD_ORDER_HISTORY';
export const DOWNLOADED_ORDER_HISTORY = 'DOWNLOADED_ORDER_HISTORY';
export const ORDER_HISTORY_UPDATE_PRODUCT = 'ORDER_HISTORY_UPDATE_PRODUCT';
export const UPDATE_ORDER_QUANTITY = 'UPDATE_ORDER_QUANTITY';
export const UPDATE_ORDER_USE_COUNT = 'UPDATE_ORDER_USE_COUNT';
export const UPDATE_ORDER_PO_NUMBER = 'UPDATE_ORDER_PO_NUMBER';
export const UPDATE_ORDER_PO_FILE = 'UPDATE_ORDER_PO_FILE';
export const UPDATE_ORDER_START_DATE = 'UPDATE_ORDER_START_DATE';
export const UPDATE_ORDER_START_TIME = 'UPDATE_ORDER_START_TIME';
export const UPDATE_ORDER_END_DATE = 'UPDATE_ORDER_END_DATE';
export const UPDATE_ORDER_END_TIME = 'UPDATE_ORDER_END_TIME';
export const ORDER_HISTORY_PENDING_RESPONSE = 'ORDER_HISTORY_PENDING_RESPONSE';
export const ORDER_HISTORY_PENDING_RESPONSE_CLEARED = 'ORDER_HISTORY_PENDING_RESPONSE_CLEARED';
export const ORDER_HISTORY_UPDATE_FROM_SUBMISSION = 'ORDER_HISTORY_UPDATE_FROM_SUBMISSION';
export const ORDER_HISTORY_ERROR_FROM_SUBMISSION = 'ORDER_HISTORY_ERROR_FROM_SUBMISSION';
export const UPDATE_ORDER_TIME_ZONE = 'UPDATE_ORDER_TIME_ZONE';
export const UPDATE_ORDER_DOWNLOAD_ERROR = 'UPDATE_ORDER_DOWNLOAD_ERROR';
export const UPDATE_ORDER_RETRY_ERROR = 'UPDATE_ORDER_RETRY_ERROR';

// Loading
export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';
export const UPDATE_ORDER_COMMENTS = 'UPDATE_ORDER_COMMENTS';

/* ///// CRON JOBS ///// */
export const BEGIN_LOAD_VENOM_JOBS = 'BEGIN_LOAD_VENOM_JOBS';
export const LOAD_VENOM_JOBS = 'LOAD_VENOM_JOBS';
export const FINISH_LOAD_VENOM_JOBS = 'FINISH_LOAD_VENOM_JOBS';
export const UPDATE_VENOM_JOB_STATUS = 'UPDATE_VENOM_JOB_STATUS';
export const BEGIN_LOAD_VIDS_JOBS = 'BEGIN_LOAD_VIDS_JOBS';
export const LOAD_VIDS_JOBS = 'LOAD_VIDS_JOBS';
export const FINISH_LOAD_VIDS_JOBS = 'FINISH_LOAD_VIDS_JOBS';
export const UPDATE_VIDS_JOB_STATUS = 'UPDATE_VIDS_JOB_STATUS';
