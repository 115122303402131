import initialState from './initialState';
import * as types from '../actions/actionTypes';

export default (state = initialState.addSkuPage, action) => {
  const { search } = action;
  switch (action.type) {
    case types.SET_ADD_SKU_PAGE:
      return {
        ...state,
        page: action.page,
      };
    case types.RESET_ADD_SKU_PAGE:
      return {
        ...initialState.addSkuPage,
      };
    case types.SET_SKU_SEARCH:
      return {
        ...state,
        search,
        page: {
          ...state.page,
          number: 0,
        },
      };
    default:
      return state;
  }
};
