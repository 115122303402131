import * as types from '../actions/actionTypes';
import initialState from './initialState';
import { MAINTENANCE_MODE } from '../constants/appConfig.constants';

export default function appConfigReducer(state = initialState.appConfig, action) {
  const {
    // type
    maintainanceModeConfig,
  } = action;
  switch (action.type) {
    case types.SET_APP_CONFIG:
      return action.appConfig;
    case types.SET_MAINTAINANCE_MODE_CONFIG:
      return [
        ...state.map(
          configSection => configSection.key === MAINTENANCE_MODE
            ? maintainanceModeConfig
            : configSection,
        ),
      ];
    default:
      return state;
  }
}
