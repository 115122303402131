import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function voucherTypeBatchPageReducer(
  state = initialState.voucherTypeBatchPage,
  action,
) {
  switch (action.type) {
    case types.SET_VOUCHER_TYPE_BATCH_PAGE:
      return {
        ...state,
        page: action.page,
      };
    case types.RESET_VOUCHER_TYPE_BATCH_PAGE:
      return {
        ...initialState.voucherTypeBatchPage,
      };
    case types.FINISH_LOAD_VOUCHER_TYPE_BATCHES:
      return {
        ...state,
        batches: action.voucherBatches,
      };
    case types.FINISH_LOAD_SELECT_VOUCHER_TYPE_BATCHES:
      return {
        ...state,
        batches: state.batches ? state.batches.map((batch) => {
          const found = action.voucherBatches.find(b => b.voucherBatchId === batch.voucherBatchId);
          return found || batch;
        }) : [],
      };
    default:
      return state;
  }
}
