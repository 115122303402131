import axios from 'axios';

export default (id, type, notification) => {
  return (() => {
    return axios.post(
      type === 'VOUCHER_TYPE'
        ? `/venom/api/snsMessageForVoucherType/${id}/?eventType=${notification}`
        : `/venom/api/snsMessageForVoucherBatch/${id}/?eventType=${notification}`,
    )
      .then(response => response.data)
      .then((res) => {
        return res;
      })
      .catch(({ response }) => {
        return response.data;
      });
  });
};
