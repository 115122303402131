export const SCEE = [{
  id: '9',
  name: 'SCEE',
}, {
  id: '1',
  name: 'Incomm',
}, {
  id: '11',
  name: 'Blackhawk',
}, {
  id: '13',
  name: 'ePay',
}];

export const SCEA = [{
  id: '5',
  name: 'SCEA',
}, {
  id: '1',
  name: 'Incomm',
}, {
  id: '11',
  name: 'Blackhawk',
}];

export const SCEK = [{
  id: '4',
  name: 'SCEK',
}];


export const SCEH = [{
  id: '6',
  name: 'SCEH',
}, {
  id: '1',
  name: 'Incomm',
}];

export const SCEJ = [{
  id: '3',
  name: 'SCEJ',
}, {
  id: '8',
  name: 'Amazon/KP/SMC',
}, {
  id: '15',
  name: 'BitCash',
}, {
  id: '11',
  name: 'Blackhawk',
}, {
  id: '1',
  name: 'Incomm',
}, {
  id: '7',
  name: 'Rakuten/TP/SMC',
}, {
  id: '12',
  name: 'SMOJ/Sony Marketing Japan Inc.',
}, {
  id: '10',
  name: 'Toppan corporation',
}, {
  id: '14',
  name: 'WebMoney',
}, {
  id: '2',
  name: 'eContext',
}];

export const FULL_LIST = [{
  id: '0',
  name: 'None',
}, {
  id: '1',
  name: 'Incomm',
}, {
  id: '2',
  name: 'eContext',
}, {
  id: '3',
  name: 'SCEJ',
}, {
  id: '4',
  name: 'SCEK',
}, {
  id: '5',
  name: 'SCEA',
}, {
  id: '6',
  name: 'SCEH',
}, {
  id: '7',
  name: 'Rakuten/TP/SMC',
}, {
  id: '8',
  name: 'Amazon/KP/SMC',
}, {
  id: '9',
  name: 'SCEE',
}, {
  id: '10',
  name: 'Toppan corporation',
}, {
  id: '11',
  name: 'Blackhawk',
}, {
  id: '12',
  name: 'SMOJ/Sony Marketing Japan Inc.',
}, {
  id: '13',
  name: 'ePay',
}, {
  id: '14',
  name: 'WebMoney',
}, {
  id: '15',
  name: 'BitCash',
}];
