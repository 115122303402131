import React from 'react';
import {
  Flex,
  Layout,
  Button,
  Search,
} from '@partner-global-ui/components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const AddVoucherTypeIdHeader = ({
  searchVoucherTypes,
  clearVoucherTypesSearch,
  onClose,
  onAddTypeId,
}) => {
  //  Setup
  const { t } = useTranslation();

  const handleAddTypeIdClick = () => {
    onAddTypeId();
    onClose();
  };

  return (
    <Layout id="type-id-wrapper" className="type-id-wrapper">
      <Flex id="type-id" className="type-id" colSpan={12}>
        <Flex id="type-id-title" className="type-id-title" colSpan={3}>
          <h3 data-test-id="type-id-heading">{t('msg_codes_select_voucher_type_id')}</h3>
        </Flex>
        <Flex id="type-id-buttons" className="type-id-buttons" colSpan={9}>
          <Search
            id="type-id-header-search"
            search={searchVoucherTypes}
            onClearSearch={clearVoucherTypesSearch}
            useClearIcon
            className="type-id-search"
            placeholder={t('msg_codes_type_something')}
          />
          <Button
            id="cancel-button"
            className="cancel-button"
            onClick={onClose}
          >
            {t('msg_codes_cta_cancel')}
          </Button>
          <Button
            primary
            id="add-type-id-Button"
            className="add-type-id-button"
            onClick={handleAddTypeIdClick}
          >
            {t('msg_codes_select_voucher_type_id')}
          </Button>
        </Flex>
      </Flex>
    </Layout>
  );
};

AddVoucherTypeIdHeader.propTypes = {
  onClose: PropTypes.func,
  onAddTypeId: PropTypes.func,
  searchVoucherTypes: PropTypes.func,
  clearVoucherTypesSearch: PropTypes.func,
};

AddVoucherTypeIdHeader.defaultProps = {
  onClose: () => null,
  onAddTypeId: () => null,
  searchVoucherTypes: () => null,
  clearVoucherTypesSearch: () => null,
};

export default AddVoucherTypeIdHeader;
