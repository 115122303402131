import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import * as types from './actionTypes';
import filtersBuilder from '../helpers/FilterParamBuilder';
import { autoHide, timeout } from '../constants/notifications.constant';


export function beginLoadAgreement() {
  return { type: types.BEGIN_LOAD_AGREEMENT };
}

export function beginLoadAgreements() {
  return { type: types.BEGIN_LOAD_AGREEMENTS };
}

export function loadAgreementsSuccess(agreements) {
  return { type: types.LOAD_AGREEMENTS_SUCCESS, agreements };
}

export function loadAgreementsError() {
  return { type: types.LOAD_AGREEMENTS_ERROR };
}

export function setCanEditVersion(status, versionStatus, userCanEdit) {
  if (!userCanEdit) {
    return {
      type: types.SET_CAN_EDIT_VERSION,
      isEditing: false,
    };
  }

  return {
    type: types.SET_CAN_EDIT_VERSION,
    isEditing: status !== 'ACTIVE' || versionStatus !== 'FINAL',
  };
}

export function resetFilters() {
  return {
    type: types.RESET_AGREEMENTS_PAGE_FILTERING,
  };
}

export function setFilter(filterType, filterItems) {
  return {
    type: types.SET_AGREEMENTS_FILTER,
    filterType,
    filterItems,
  };
}

export function setAgreementsSearch(search) {
  return { type: types.SET_AGREEMENTS_SEARCH, search };
}

function versionLatestFinalEffectiveDate(agreementVersions) {
  const sortedVersions = _.orderBy(agreementVersions, version => moment(version.agreementVersionEffectiveDate), ['desc']);
  const latestFinalEffectiveDate = sortedVersions.find(version => version.versionStatus === 'FINAL');

  return typeof latestFinalEffectiveDate === 'undefined'
    ? moment.now()
    : latestFinalEffectiveDate.agreementVersionEffectiveDate;
}

export function agreementVersionLatestFinalEffectiveDate(agreementVersions) {
  return ((dispatch) => {
    dispatch(
      {
        type: types.AGREEMENT_VERSION_LATEST_FINAL_EFFECTIVE_DATE,
        latestFinalEffectiveDate: versionLatestFinalEffectiveDate(agreementVersions),
      },
    );
  });
}

export function loadAgreementSuccess(agreement, eTag) {
  return { type: types.LOAD_AGREEMENT_SUCCESS, agreement, eTag };
}

export function loadAgreementNotFound() {
  return { type: types.AGREEMENT_NOT_FOUND };
}

export function setAgreementVersion(
  agreementVersion, agreementVersionId, status, userCanEdit,
) {
  return {
    type: types.SET_AGREEMENT_VERSION,
    agreementVersion,
    canEditVersion:
      setCanEditVersion(status, agreementVersion.versionStatus, userCanEdit).isEditing,
    agreementVersionId,
    initialStatuses: {
      status,
      versionStatus: agreementVersion.versionStatus,
    },
  };
}

export function setInitialStatuses(status, versionStatus) {
  return {
    type: types.SET_INITIAL_STATUSES,
    status,
    versionStatus,
  };
}

export function loadAgreements(
  size = 50,
  page = 0,
  sort = { sortBy: 'agreementId', sortDir: 'desc' },
  filters = {},
) {
  const sortParam = `?sort=${sort.sortBy},${sort.sortDir}`;
  const pageParam = `page=${page}`;
  const sizeParam = `size=${size}`;
  const filtersParams = filtersBuilder(filters);
  const url = `/venom/api/agreements${sortParam}&${pageParam}&${sizeParam}${filtersParams}`;
  return (dispatch) => {
    dispatch(beginLoadAgreements());
    return axios.get(url)
      .then((response) => {
        return response.data;
      })
      .then((agreements) => {
        dispatch({
          type: types.SET_AGREEMENTS_PAGE,
          agreementsPage: {
            ...agreements,
          },
        });
        dispatch(loadAgreementsSuccess(agreements.content));
        return agreements;
      })
      .catch((error) => {
        console.error(error.response);
      });
  };
}

export function loadAgreement(agreementId, userCanEdit, notificationContext, currencies) {
  return ((dispatch) => {
    dispatch(beginLoadAgreement);
    return axios.get(`/venom/api/agreements/${agreementId}`)
      .then((response) => {
        const { headers: { etag } } = response;
        return { agreement: response.data, etag };
      })
      .then(({ agreement, etag }) => {
        if (agreement.message === 'AccessDeniedException') return false;

        /**
         * This code block is responsible for updating the currency status in the fee structure
         * of each voucher product type coverage in each agreement version of an agreement.
         * It does this by iterating over each agreement version, then for each agreement version,
         * it iterates over its voucher product type coverage set.
         * For each voucher product type coverage, it iterates over its fee structure.
         * It then finds the currency that matches the settlement currency of the fee structure.
         * If such a currency is found, it sets the currency status of the fee structure to the
         * status of the found currency.
         */
        agreement.agreementVersions.forEach((agreementVersion) => {
          agreementVersion.voucherProductTypeCoverageSet.forEach((voucherProductTypeCoverage) => {
            voucherProductTypeCoverage.feeStructure.forEach((feeStructure) => {
              const currency = currencies
                .find(curr => curr.code === feeStructure.settlementCurrency);
              if (currency) {
                feeStructure.currencyStatus = currency.status;
              }
            });
          });
        });

        const loadedAgreement = { ...agreement };
        loadedAgreement.partnerId = loadedAgreement.partner.partnerId;
        const [first] = _.orderBy(loadedAgreement.agreementVersions, 'agreementVersionId', 'desc');
        dispatch(setInitialStatuses(agreement.status, first.versionStatus));
        dispatch(loadAgreementSuccess(loadedAgreement, etag));
        dispatch(setAgreementVersion(
          first, first.agreementVersionId, agreement.status, userCanEdit,
        ));
        dispatch(agreementVersionLatestFinalEffectiveDate(agreement.agreementVersions));
        return agreement;
      }).catch((error) => {
        if (error.response.status === 404) {
          const errorMessage = 'Agreement cannot be found';
          notificationContext.dispatch({
            type: 'add',
            payload: {
              status: 'error',
              autoHide,
              timeout,
              message: errorMessage,
              testId: 'error',
            },
          });
          dispatch(loadAgreementNotFound());
        }
        if (error.response.status === 403) {
          const errorMessage = 'You are not authorized to view this agreement';
          notificationContext.dispatch({
            type: 'add',
            payload: {
              status: 'error',
              autoHide,
              timeout,
              message: errorMessage,
              testId: 'error',
            },
          });
        }
        return error.message;
      });
  });
}

export function loadPartnerAgreements(
  partnerId, page = 0, size = 10, sort = { name: 'agreementId', order: 'desc' },
) {
  const sortParam = `${sort.name},${sort.order}`;
  const queryParams = `?page=${page}&size=${size}&sort=${sortParam}`;
  return ((dispatch) => {
    dispatch(beginLoadAgreements());
    return axios.get(`/venom/api/partners/${partnerId}/agreements${queryParams}`)
      .then((response) => {
        return response.data;
      }).then((agreements) => {
        const pageInfo = {
          size: agreements.size,
          totalElements: agreements.totalElements,
          totalPages: agreements.totalPages,
          number: agreements.number,
        };
        dispatch(loadAgreementsSuccess(agreements.content));
        dispatch({
          type: types.SET_AGREEMENTS_PAGE,
          agreementsPage: {
            page: pageInfo,
          },
        });
      }).catch((error) => {
        console.error(error);
      });
  });
}
