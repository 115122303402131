import React from 'react';
import {
  TableRow,
  TableCell,
  Kabob,
  Tooltip,
  GroupState,
} from '@partner-global-ui/components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { formatVoucherBatchStatus, getKabobItems } from '../../utils/voucherBatchStatus';

export const VoucherBatchRow = (props) => {
  const { t } = useTranslation();

  const {
    batch, selectBatch, isSelected, onBatchAction,
    bulkAction,
    isActionAllowed,
    isEditable,
    tableRef,
  } = props;
  const readOnlyBatch = typeof batch.partnerId === 'number';

  const formatDate = (date) => {
    const momentObject = moment.utc(date);
    if (!momentObject.isValid()) return null;
    return momentObject.tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm z [(UTC ]Z[)]');
  };

  const renderKabob = () => {
    const kabobItems = getKabobItems(batch, onBatchAction, isEditable);
    const kabob = (
      <Kabob
        menuItems={kabobItems}
        disabled={kabobItems.length === 0 || readOnlyBatch}
        id="voucher-batch-table-kabob"
      />
    );
    if (readOnlyBatch) {
      return (
        <Tooltip
          id="voucher-batch-table-kabob-tooltip"
          placement="top"
          content={t('Auto generated from orders within Codes, not editable.')}
          strategy="fixed"
          anchor={tableRef}
        >
          {kabob}
        </Tooltip>
      );
    }
    return kabob;
  };

  const checkboxDisabled = !isActionAllowed(bulkAction, batch);

  const rowCheckboxState = isSelected && !checkboxDisabled ? GroupState.ALL : GroupState.NONE;

  return (
    <>
      <TableRow
        className="voucher-batch-table-row"
        key={batch.voucherBatchId}
        id={batch.voucherBatchId}
        hasCheckbox
        onCheckboxClick={() => selectBatch(batch.voucherBatchId)}
        checkboxState={rowCheckboxState}
        checkboxProps={{ disabled: checkboxDisabled }}
      >
        <TableCell
          className="voucher-batch-table-batch-id"
          id="voucher-batch-table-batch-id"
        >
          <Link
            data-testid="voucherBatchId-link"
            className="voucher-batchId-link"
            to={`/voucherbatch/${batch.voucherBatchId}`}
          >
            {batch.voucherBatchId}
          </Link>
        </TableCell>
        <TableCell
          className="voucher-batch-table-control-label"
          id="voucher-batch-table-control-label"
        >
          {batch.controlLabel}
        </TableCell>
        <TableCell
          className="voucher-batch-table-voucher-purpose"
          id="voucher-batch-table-voucher-purpose"
        >
          {batch.voucherPurpose}
        </TableCell>
        <TableCell
          className="voucher-batch-table-start-date voucher-batch-table-date"
          id="voucher-batch-table-start-date"
        >
          {formatDate(batch.startDate)}
        </TableCell>
        <TableCell
          className="voucher-batch-table-end-date voucher-batch-table-date"
          id="voucher-batch-table-end-date"
        >
          {formatDate(batch.endDate)}
        </TableCell>
        <TableCell
          className="voucher-batch-table-date-modified voucher-batch-table-date"
          id="voucher-batch-table-date-modified"
        >
          {formatDate(batch.modifyDate)}
        </TableCell>
        <TableCell
          className="voucher-batch-table-status"
          id="voucher-batch-table-status"
        >
          {formatVoucherBatchStatus(batch)}
        </TableCell>
        <TableCell
          className="voucher-batch-table-actions actions-column"
          id={`actions-column-${batch.voucherBatchId}`}
        >
          {renderKabob()}
        </TableCell>
      </TableRow>
    </>
  );
};

VoucherBatchRow.propTypes = {
  batch: PropTypes.object,
  selectBatch: PropTypes.func,
  onBatchAction: PropTypes.func,
  isActionAllowed: PropTypes.func,
  isSelected: PropTypes.bool,
  bulkAction: PropTypes.string,
  isEditable: PropTypes.bool,
  tableRef: PropTypes.object,
};

VoucherBatchRow.defaultProps = {
  batch: {},
  selectBatch: () => null,
  onBatchAction: () => null,
  isActionAllowed: () => null,
  isSelected: false,
  bulkAction: '',
  isEditable: false,
  tableRef: {},
};

export default VoucherBatchRow;
