import axios from 'axios';
import * as types from './actionTypes';
import filtersBuilder from '../helpers/FilterParamBuilder';

export function changePropSuccess(name, value) {
  return { type: types.CHANGE_PARTNER_PROP_SUCCESS, name, value };
}


export function beginLoadPartners() {
  return { type: types.BEGIN_LOAD_PARTNERS };
}

export function loadPartnersSuccess(partners) {
  return { type: types.LOAD_PARTNERS_SUCCESS, partners };
}

export function changeProp(name, value) {
  return (dispatch => dispatch(changePropSuccess(name, value)));
}

export function versionMismatch() {
  return { type: types.VERSION_MISMATCH };
}

export function loadPartner(id, sftpStatus) {
  return (async (dispatch) => {
    dispatch({ type: types.LOAD_PARTNER_PENDING });

    const statusQuery = typeof sftpStatus !== 'undefined' ? `?status=${sftpStatus}` : '';
    return axios.get(`/venom/api/partners/${id}/partnerSftps${statusQuery}`).then(
      (response) => {
        const version = response.headers.ETag;
        const partner = { ...response.data, version };
        dispatch({ type: types.LOAD_PARTNER_SUCCESS, partner: { ...partner, version } });
        return partner;
      },
      async (error) => {
        if (error.response && error.response.status === 404) {
          await axios.get(`/venom/api/partners/${id}`).then(
            (response) => {
              const version = response.headers.ETag;
              dispatch({
                type: types.LOAD_PARTNER_SUCCESS,
                partner: {
                  partner: { ...response.data },
                  partnerSftpMapList: [],
                  version,
                },
              });
            },
            () => {
              dispatch({ type: types.LOAD_PARTNER_REJECTED });
            },
          );
        }
      },
    );
  });
}

export function resetFilters() {
  return { type: types.RESET_PARTNERS_PAGE_FILTERING };
}

export function setFilter(filterType, filterItems) {
  return {
    type: types.SET_PARTNERS_FILTER,
    filterType,
    filterItems,
  };
}

export function loadPartners(
  size = 1000,
  page = 0,
  sort = { sortBy: 'name', sortDir: 'asc' },
  filters = {},
  partnerType,
) {
  const sortParam = `?sort=${sort.sortBy},${sort.sortDir}`;
  const pageParam = `page=${page}`;
  const sizeParam = `size=${size}`;
  const filtersParams = filtersBuilder(filters);
  const partnerTypeParam = typeof partnerType !== 'undefined' ? `&partnerType=${partnerType}` : '';
  const url = `/venom/api/partners${sortParam}&${pageParam}&${sizeParam}${filtersParams}${partnerTypeParam}`;
  return (dispatch) => {
    dispatch(beginLoadPartners());
    return axios.get(url)
      .then(response => response.data)
      .then((partners) => {
        if (partners.content) {
          dispatch({
            type: types.SET_PARTNERS_PAGE,
            partnersPage: {
              page: {
                ...partners.pageable,
                totalElements: partners.totalElements,
              },
            },
          });
          dispatch({ type: types.END_LOAD_PARTNERS });
          dispatch(loadPartnersSuccess(partners.content));
        }
        return partners.content;
      });
  };
}
