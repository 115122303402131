export const invoiceTiming = {
  ON_REDEMPTION: 'msg_codes_3pagreements_onRedemption',
  ON_DELIVERY: 'msg_codes_3pagreements_onDelivery',
  ON_CODE_SALE: 'msg_codes_3pagreements_onCodeSale',
  ON_FINANCIAL_CYCLE: 'msg_codes_3pagreements_onFinancialcycle',
};

export const redemptionFee = {
  'Store Margin': 'msg_codes_3pagreements_storeMargin',
};

export const quantityLimit = {
  'No Limit': 'msg_codes_3pagreements_noLimit',
};
