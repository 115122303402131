import React, {
  useState, useEffect, useCallback, useRef, useReducer,
} from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  Container,
  DataTable,
  GroupState,
  ActionBar,
  Pagination,
  RowDisplay,
  TableHeader,
  TableHeaderCell,
  NoResults,
} from '@partner-global-ui/components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash';
import FiltersContainer from '../Filters/FiltersContainer';
import { filterCounter } from '../../../helpers/filterMethods';
import * as addSkuActions from '../../../actions/addSkuActons';
import Sku from './Sku';
import RowDisplayLocalization from '../../../helpers/RowDisplayLocalization';
import './addSku.scss';
import AddSkuHeader from './AddSkuHeader';
import getCountries from '../../../actions/countryActions';
import * as CodeProductTypes from '../../../constants/codeProductTypes.constants';
import { contentTypes, skuTypes } from './FilterOptions';

const defaultFilters = {
  contentType: [],
  skuType: [],
  country: [],
};

export const initialState = {
  filters: defaultFilters,
  filtered: false,
  filterCount: 0,
};

export const localStateReducer = (
  state,
  {
    type = '',
    name,
    value,
    filters = defaultFilters,
  },
) => {
  const filteredState = filters
    && (
      filters.contentType.length > 0
      || filters.skuType.length > 0
      || filters.country.length > 0
    );


  let newState;
  switch (type) {
    case 'setFilter':
      newState = {
        ...state,
        filters: { ...state.filters, [name]: value },
        filtered: !filteredState,
      };
      return { ...newState, filterCount: filterCounter(newState.filters) };
    case 'clearFilters':
      return {
        ...state,
        filters: defaultFilters,
        filtered: false,
        filterCount: 0,
      };
    default:
      return state;
  }
};

export const AddSkuPage = ({ ...props }) => {
  // Setup
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tableRef = useRef();

  const [selectedSkus, setSelectedSkus] = useState([]);
  const { userId } = useSelector(state => state.user);
  const search = useSelector(state => state.addSkuPage.search);
  const countries = useSelector(state => state.countries);
  const skus = useSelector(state => state.skus);
  const addSkuPage = useSelector(state => state.addSkuPage.page, shallowEqual);
  const loadingSkus = useSelector(state => state.loadingSkus);

  const [localState, localDispatch] = useReducer(localStateReducer, initialState);
  const {
    filterCount,
    filters,
  } = localState;

  const {
    size, number, sort, sortOrder, totalElements,
  } = addSkuPage;
  const {
    addedSkus, region, onAddSkus, onClose, isCodeProduct,
    codeProductType,
  } = props;

  useEffect(() => {
    getCountries()(dispatch);
  }, []);

  useEffect(() => {
    dispatch(addSkuActions.resetAddSkuPage());
  }, []);

  useEffect(() => {
    setSelectedSkus(addedSkus);
  }, [addedSkus]);

  useEffect(() => {
    const pageSize = localStorage.getItem(`addSkuPageSize-${userId}`) !== null
      ? Number(localStorage.getItem(`addSkuPageSize-${userId}`))
      : size;
    addSkuActions.loadSkus(
      region,
      pageSize,
      number,
      { name: sort, order: sortOrder },
      {
        ...filters,
      },
      search,
    )(dispatch);
  }, [size, sort, sortOrder, search]);

  const searchSkus = (searchTerm) => {
    addSkuActions.loadSkus(
      region,
      size,
      number,
      { name: sort, order: sortOrder },
      {
        ...filters,
      },
      searchTerm,
    )(dispatch);
  };

  const clearSkuSearch = () => {
    addSkuActions.loadSkus(
      region,
      size,
      number,
      { name: sort, order: sortOrder },
      {
        ...filters,
      },
    )(dispatch);
  };

  const changePage = useCallback((pageNumber) => {
    addSkuActions.changePage({ ...addSkuPage, number: pageNumber - 1, filters }, region, dispatch);
  }, [addSkuPage, filters]);

  const changeRowAmount = (newSize) => {
    localStorage.setItem(`addSkuPageSize-${userId}`, newSize);
    dispatch(addSkuActions.setAddSkuPage({ ...addSkuPage, number: 0, size: newSize }));
  };

  const sortSkus = (name) => {
    const newSortOrder = name === sort ? sortOrder === 'asc' ? 'desc' : 'asc' : 'asc';
    dispatch(addSkuActions.setAddSkuPage({ ...addSkuPage, sort: name, sortOrder: newSortOrder }));
  };

  const isSkuInList = (list, sku) => {
    return list.some(item => item.skuId === sku.skuId);
  };

  const areAllSkusChecked = () => {
    if (skus && skus.length === 0) {
      return false;
    }
    return skus
      && skus.length
      && skus.every(sku => isSkuInList(selectedSkus, sku));
  };

  const isAnySkuChecked = () => {
    return skus && skus.some(sku => isSkuInList(selectedSkus, sku))
      && !areAllSkusChecked();
  };

  const handleSelectAllSkusCheck = () => {
    let allSelectedSkus = [...selectedSkus];
    if (!areAllSkusChecked() && !isAnySkuChecked()) {
      if (skus && skus.length) {
        skus.forEach((sku) => {
          if (!isSkuInList(addedSkus, sku)) {
            allSelectedSkus.push(sku);
          }
        });
      }
    } else {
      allSelectedSkus = allSelectedSkus.filter(sku => !isSkuInList(skus, sku));
    }
    setSelectedSkus(allSelectedSkus);
  };

  const handleSkuCheckboxChange = (sku) => {
    const modifiedSelectedSkus = [...selectedSkus];
    if (isSkuInList(modifiedSelectedSkus, sku)) {
      modifiedSelectedSkus.splice(modifiedSelectedSkus.findIndex(s => s.skuId === sku.skuId), 1);
    } else {
      modifiedSelectedSkus.push(sku);
    }
    setSelectedSkus(modifiedSelectedSkus);
  };

  const addSkus = () => {
    onAddSkus(selectedSkus);
    dispatch(addSkuActions.setSelectedSkus([]));
  };

  const handlePageClose = () => {
    const pageSize = localStorage.getItem(`addSkuPageSize-${userId}`) !== null
      ? Number(localStorage.getItem(`addSkuPageSize-${userId}`))
      : 10;
    onClose();
    changePage(0);
    changeRowAmount(pageSize);
    dispatch(addSkuActions.setSelectedSkus([]));
    dispatch(addSkuActions.resetAddSkuPage());
  };

  const isSkuDisabled = (sku) => {
    const hasNoCountries = sku.skuAvailabilities.length === 0;
    const alreadySaved = addedSkus.some(addedSku => addedSku.skuId === sku.skuId);

    if (isCodeProduct) {
      // added exception to handle multiple sku subscription products
      if (codeProductType === CodeProductTypes.SUBSCRIPTION.code) {
        return false || alreadySaved;
      }
      if (selectedSkus.length >= 1) {
        return selectedSkus[0].skuId !== sku.skuId;
      }
      return hasNoCountries;
    }

    return isSkuInList(addedSkus, sku) || hasNoCountries;
  };

  // labels for table headers
  const tableHeaders = [
    { label: t('msg_codes_content_type'), value: 'contentType', sortable: true },
    { label: t('msg_codes_parent_product'), value: 'parentProductName', sortable: true },
    { label: t('msg_codes_orderList_skuid'), value: 'skuId', sortable: true },
    { label: t('msg_codes_voucher_sku_type'), value: 'skuType', sortable: true },
    { label: t('msg_codes_sku_name'), value: 'skuName', sortable: true },
    { label: t('msg_codes_orderList_countryHeader'), value: 'country', sortable: false },
    { label: t('msg_codes_last_updated'), value: 'lastUpdatedDate', sortable: true },
    { label: t('msg_codes_agreements_agreementStatus'), value: 'status', sortable: true },
  ];

  const renderHeaderCells = () => {
    return tableHeaders.map(({ label, value, sortable }, headerCellIndex) => {
      const headerKey = headerCellIndex + 1;

      return (
        <TableHeaderCell
          sortable={sortable}
          onClick={sortSkus}
          sort={
            {
              sortBy: sort,
              sortDir: sortOrder,
            }
          }
          id={`skus-${headerKey}`}
          key={headerKey}
          value={value}
          className={`sku-header-${value}`}
        >
          {label}
        </TableHeaderCell>
      );
    });
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    localDispatch({ type: 'setFilter', name, value });
  };

  const createFilters = useCallback(() => {
    const {
      contentType,
      skuType,
      country,
    } = filters;

    const contentTypeOptions = contentTypes.map((option) => {
      return {
        value: option.value,
        label: t(option.nameStringId),
      };
    });

    const skuTypeOptions = skuTypes.map((option) => {
      return {
        value: option.value,
        label: t(option.nameStringId),
      };
    });

    const countriesOptions = sortBy(countries, ['name']).map((option) => {
      return {
        value: option.code,
        label: t(option.nameStringId),
      };
    });

    return [
      {
        label: t('msg_codes_content_type'),
        name: 'contentType',
        placeholder: t('msg_codes_filter_all'),
        multiple: true,
        value: contentType,
        options: contentTypeOptions,
        handleChange: handleFilterChange,
      },
      {
        label: t('msg_codes_voucher_sku_type'),
        name: 'skuType',
        placeholder: t('msg_codes_filter_all'),
        value: skuType,
        multiple: true,
        options: skuTypeOptions,
        handleChange: handleFilterChange,
      },
      {
        label: t('msg_codes_orderList_countryHeader'),
        name: 'country',
        placeholder: t('msg_codes_filter_all'),
        value: country,
        multiple: true,
        options: countriesOptions,
        handleChange: handleFilterChange,
      },
    ];
  });

  const actionBar = () => {
    const handleApplyFilters = () => {
      addSkuActions.loadSkus(
        region,
        size,
        number,
        { name: sort, order: sortOrder },
        {
          ...filters,
        },
        search,
      )(dispatch);
    };

    const clearFilters = () => {
      localDispatch({ type: 'clearFilters' });
      addSkuActions.loadSkus(
        region,
        size,
        number,
        { name: sort, order: sortOrder },
        {
          ...defaultFilters,
        },
        search,
      )(dispatch);
    };

    return (
      <ActionBar
        filters={(
          <FiltersContainer
            filters={createFilters()}
            id="sku-list-filters"
          />
        )}
        onFilterClear={clearFilters}
        filterCount={filterCount}
        onFilterApply={handleApplyFilters}
        filterClearLabel={t('msg_codes_cta_clear')}
        filterPrimaryLabel={t('msg_codes_cta_apply')}
        filterSecondaryLabel={t('msg_codes_cta_cancel')}
        filterToolTipText={t('msg_codes_filter')}
        filterTitle={t('msg_codes_filter')}
        id="skus-list-action-bar"
      />
    );
  };

  const renderTableRows = useCallback(() => {
    return skus && skus.length > 0 ? (
      skus.map((sku) => {
        const selectedSkuIds = selectedSkus.map(selectedSku => selectedSku.skuId);
        const checkboxState = selectedSkuIds.includes(sku.skuId)
          ? GroupState.ALL : GroupState.NONE;
        return (
          <Sku
            sku={sku}
            handleSkuCheckboxChange={handleSkuCheckboxChange}
            checkboxState={checkboxState}
            isSkuSelected={isSkuInList(selectedSkus, sku)}
            isDisabled={isSkuDisabled(sku)}
            countries={countries}
            contentTypes={contentTypes}
            skuTypes={skuTypes}
            tableRef={tableRef}
          />
        );
      })
    ) : (
      <NoResults
        id="sku-no-results"
        title={t('msg_codes_codes_noResultsFound')}
        message={t('There are no skus.')}
      />
    );
  });

  const renderPagination = useCallback(() => {
    return totalElements > 10 && (
      <div data-testid="sku-selection-paginator" className="sku-selection-paginator">
        <RowDisplay
          id="sku-selection-paginator-selector"
          currentPage={number + 1}
          totalItems={totalElements}
          pageSizes={[10, 25, 50, 100]}
          onPageSizeChange={changeRowAmount}
          initialPageSize={size}
          showingOfText={RowDisplayLocalization('msg_codes_pagination_showing')(t)}
        />
        {totalElements > size && (
          <Pagination
            id="sku-selection-paginator-navigator"
            totalRecords={totalElements}
            currentPage={number + 1}
            pageLimit={size}
            onPageItemClick={changePage}
          />
        )}
      </div>
    );
  });

  const renderDataTable = () => {
    const state = areAllSkusChecked() && !isAnySkuChecked()
      ? GroupState.ALL
      : isAnySkuChecked() ? GroupState.SOME : GroupState.NONE;

    return (
      <DataTable
        id="sku-list-table"
        columnTemplate="17% 13% 10% 15% 11% 12% 13% 9%"
        scrollHeight={400}
        hasCheckbox
        ref={tableRef}
        actionBar={actionBar()}
        loading={loadingSkus}
      >
        <TableHeader
          onCheckboxClick={handleSelectAllSkusCheck}
          checkboxState={state}
          hasCheckbox
        >
          {renderHeaderCells()}
        </TableHeader>
        {renderTableRows()}
      </DataTable>
    );
  };

  return (
    <Container className="skus-list">
      <AddSkuHeader
        onClose={handlePageClose}
        onAddSkus={addSkus}
        searchSkus={searchSkus}
        clearSkuSearch={clearSkuSearch}
      />
      {renderDataTable()}
      {renderPagination()}
    </Container>
  );
};

AddSkuPage.propTypes = {
  region: PropTypes.string,
  onClose: PropTypes.func,
  onAddSkus: PropTypes.func,
  addedSkus: PropTypes.array,
  isCodeProduct: PropTypes.bool,
  codeProductType: PropTypes.string,
};

AddSkuPage.defaultProps = {
  region: '',
  onClose: () => null,
  onAddSkus: () => null,
  addedSkus: [],
  isCodeProduct: false,
  codeProductType: '',
};

export default AddSkuPage;
