import moment from 'moment';
import * as types from '../actions/actionTypes';
import initialState from './initialState';


const formatDate = date => moment(new Date(date)).utc().format('MM/DD/YYYY');

export default function partnersReducer(state = initialState.partners, action) {
  switch (action.type) {
    case types.LOAD_PARTNERS_SUCCESS:
      action.partners.forEach((partner) => {
        partner.creationDate = formatDate(partner.creationDate);
      });
      return action.partners;
    default:
      return state;
  }
}
