import axios from 'axios';
import * as types from './actionTypes';

export function beginLoadSkus() {
  return { type: types.BEGIN_LOAD_SKUS };
}

export function finishLoadSkus(skus) {
  return { type: types.FINISH_LOAD_SKUS, skus };
}

export function setAddSkuPage(page) {
  return { type: types.SET_ADD_SKU_PAGE, page };
}

export function resetAddSkuPage() {
  return { type: types.RESET_ADD_SKU_PAGE };
}

export function setSelectedSkus(selectedSkus) {
  return (dispatch) => {
    dispatch({ type: types.SET_SELECTED_SKUS, selectedSkus });
  };
}

export function setSkuSearch(search) {
  return { type: types.SET_SKU_SEARCH, search };
}

export function loadSkus(
  region = '',
  size = 50,
  page = 0,
  sort = { name: 'lastUpdatedDate', order: 'desc' },
  filters = {
    contentType: [],
    skuType: [],
    country: [],
  },
  search = '',
) {
  const sortParam = `?sort=${sort.name},${sort.order}`;
  const pageParam = `page=${page}`;
  const sizeParam = `size=${size}`;
  const regionParam = region !== '' ? `&npRegion=${region}` : '';
  const searchParam = search !== '' ? `&search=${search}` : '';

  const contentTypeParam = filters.contentType.map(type => `&contentType=${type.value}`).join('');
  const skuTypeParam = filters.skuType.map(type => `&skuType=${type.value}`).join('');
  const countryParam = filters.country.map(type => `&country=${type.value}`).join('');
  const filtersParams = `${contentTypeParam}${skuTypeParam}${countryParam}`;

  const url = `/venom/api/skus${sortParam}&${pageParam}&${sizeParam}${regionParam}${searchParam}${filtersParams}`;
  const { name: sortName, order: sortOrder } = sort;
  return (dispatch) => {
    dispatch(beginLoadSkus());
    return axios.get(url).then((response) => {
      return response.data;
    }).then((skus) => {
      dispatch(finishLoadSkus(skus.content));
      dispatch(setAddSkuPage({
        number: skus.number,
        size: skus.size,
        totalElements: skus.totalElements,
        sort: sortName,
        sortOrder,
      }));
    }).catch(() => {
      // TODO: error handling
    });
  };
}

export const changePage = (page, region, dispatch) => {
  const {
    size, number, sort, sortOrder,
    filters, search,
  } = page;
  loadSkus(
    region,
    size,
    number,
    { name: sort, order: sortOrder },
    {
      ...filters,
    },
    search,
  )(dispatch);
};
