import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Collapsible,
  RowDisplay,
  Pagination,
  TableHeader,
  TableHeaderCell,
  DataTable,
  NoResults,
  Loader,
  Flex,
} from '@partner-global-ui/components';
import UsersHeaderColumns from './TableHeaderColumns';
import * as userListAction from '../../../actions/userListActions';
import UserRow from './User';
import hasPermission from '../../../utils/accessControl/hasPermission';
import roleKeys from '../../../utils/accessControl/roleKeys';
import RowDisplayLocalization from '../../../helpers/RowDisplayLocalization';
import './UsersPartner.scss';

const PartnerDetailUsers = (props) => {
  const { userId } = useSelector(state => state.user);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const initialSort = {
    sortBy: 'lastName',
    sortDir: 'asc',
  };
  const { loadPartnerUsers } = userListAction;
  let showPgpKeysColumn = false;
  showPgpKeysColumn = hasPermission(useSelector(state => state), roleKeys.user.canSeePgpKeys);
  const columnTemplate = showPgpKeysColumn ? 'repeat(6, 1fr)' : 'repeat(5, 1fr)';
  const loadingUsers = useSelector(state => state.loadingUsers);
  const page = useSelector(state => state.usersPage.page);
  const userList = useSelector(state => state.usersPage.users).map(userFromList => ({
    ...userFromList,
    userId: userFromList.userId,
  }));
  const [sort, setSort] = useState(initialSort);
  const pageSize = localStorage.getItem(`partnerUsersPageSize-${userId}`) !== null
    ? Number(localStorage.getItem(`partnerUsersPageSize-${userId}`))
    : 50;
  const [pageRowAmount, setPageRowAmount] = useState(pageSize);

  useEffect(() => {
    const { match } = props;
    loadPartnerUsers(match.params.id, 0, 50)(dispatch);
  }, []);

  const changePageRowAmount = (size) => {
    const { match } = props;
    localStorage.setItem(`partnerUsersPageSize-${userId}`, size);
    setPageRowAmount(size);
    loadPartnerUsers(
      match.params.id, 0, size, { name: sort.sortBy, order: sort.sortDir },
    )(dispatch);
  };

  const changePage = (localPage) => {
    const { match } = props;
    loadPartnerUsers(
      match.params.id, localPage - 1, pageRowAmount, { name: sort.sortBy, order: sort.sortDir },
    )(dispatch);
  };

  const sortUsers = (name) => {
    let { sortDir } = sort;
    const { sortBy } = sort;

    const {
      match,
    } = props;
    // eslint-disable-next-line no-nested-ternary
    sortDir = name === sortBy
      ? sortDir === 'asc'
        ? 'desc'
        : 'asc'
      : 'asc';

    setSort({
      sortBy: name,
      sortDir,
    });

    loadPartnerUsers(
      match.params.id, page.number, page.size, { name, order: sortDir },
    )(dispatch);
  };

  const renderLoadingMessage = () => {
    return (
      <div
        name="partner-detail-users-grid"
        className="partner-detail-users-grid"
      >
        <div
          name="partner-detail-users-header-row"
          className="partner-detail-users-header-row"
        >
          <div
            name="partner-detail-users-header-col"
            className="partner-detail-users-header-col"
            sm={12}
          >
            <Loader message="" className="users-loader" id="users-loading" />
          </div>
        </div>
      </div>
    );
  };

  const mappedUsers = userList.map(user => <UserRow key={user.email} user={user} />);

  return (
    <Flex className={page.totalElements > 10 ? 'users-wrapper' : ''}>
      <Collapsible
        heading={t('msg_codes_users')}
        id="users-table"
        scrollHeight={368}
        defaultIsOpen
      >
        <div name="partner-detail-users-grid" className="partner-detail-users-grid">
          <div
            name="partner-detail-users-header-row"
            className="partner-detail-users-header-row"
            style={{ minHeight: mappedUsers.length * 41 }}
          >
            <div
              name="partner-detail-users-header-col"
              className="partner-detail-users-header-col"
              sm={12}
            >
              {loadingUsers
                ? renderLoadingMessage()
                : (
                  <div style={{ flexDirection: 'column', width: '100%' }}>
                    <DataTable
                      columnTemplate={columnTemplate}
                      scrollHeight={368}
                    >
                      <TableHeader>
                        {UsersHeaderColumns.map((column) => {
                          return (
                            <TableHeaderCell
                              key={column.id}
                              id={column.id}
                              value={column.value}
                              sortable={column.sortable}
                              sort={sort}
                              onClick={sortUsers}
                              hasExpandable
                              className={!showPgpKeysColumn && column.value === 'pgpKeys' ? `header-cell-${column.value}-hidden` : `header-cell-${column.value}`}
                            >
                              {t(column.translationStringId)}
                            </TableHeaderCell>
                          );
                        })}
                      </TableHeader>
                      {page.totalElements === 0
                        ? (
                          <NoResults
                            id="no-users"
                            title={t('msg_codes_codes_noResultsFound')}
                            message=""
                          />
                        )
                        : mappedUsers}
                    </DataTable>
                    {page.totalElements > 10 && (
                      <div className="partner-detail-users-paginator">
                        <RowDisplay
                          currentPage={page.number + 1}
                          totalItems={page.totalElements}
                          pageSizes={[10, 25, 50, 100]}
                          onPageSizeChange={changePageRowAmount}
                          initialPageSize={pageRowAmount}
                          showingOfText={RowDisplayLocalization('msg_codes_pagination_showing')(t)}
                        />
                        {page.totalPages > 1
                          && (
                            <Pagination
                              totalRecords={page.totalElements}
                              currentPage={page.number + 1}
                              pageLimit={pageRowAmount}
                              onPageItemClick={changePage}
                            />
                          )}
                      </div>
                    )}
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </Collapsible>
    </Flex>
  );
};

export default PartnerDetailUsers;
