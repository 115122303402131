import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { Tooltip, Icon, iconSizes } from '@partner-global-ui/components';
import getFeature from '../../../../utils/accessControl/getFeature';

const PrimaryRowHeader = ({
  tableRef,
  showUseCount,
  name,
}) => {
  const { t } = useTranslation();
  const feesUrl = getFeature('feesUrl');

  const activityTooltipContent = (
    <div className="tooltipDefaultStyles">
      <Trans
        i18nKey="msg_codes_cart_activity_tooltip"
        defaults="Default Message"
        components={[<a data-testid={`activity-tooltip-${name}`} href={feesUrl} className="tooltip-link" rel="noopener noreferrer" target="_blank">here</a>]}
      />
    </div>
  );
  const orderFeeTooltipContent = (
    <div className="tooltipDefaultStyles">
      <Trans
        i18nKey="msg_codes_orderFee_tooltip"
        defaults="Default Message"
        components={[<a data-testid={`orderFee-tooltip-${name}`} href={feesUrl} className="tooltip-link" rel="noopener noreferrer" target="_blank">here</a>]}
      />
    </div>
  );
  const redemptionFeeTooltipContent = (
    <div className="tooltipDefaultStyles">
      <Trans
        i18nKey="msg_codes_redonBill_tooltip"
        defaults="Default Message"
        components={[<a data-testid={`activtiy-tooltip-${name}`} href={feesUrl} className="tooltip-link" rel="noopener noreferrer" target="_blank">here</a>]}
      />
    </div>
  );
  const useCountTooltipContent = (
    <div className="tooltipDefaultStyles useCount">
      <Trans
        i18nKey="msg_codes_use_count_partner_tooltip"
        defaults="Default Message"
      />
    </div>
  );
  const countryStringLineBreak = t('msg_codes_orderList_countryHeader').split('/');
  const countryString = `${countryStringLineBreak[0]}/\n${countryStringLineBreak[1]}`;

  return (
    <>
      <div
        key="codeName"
        id="codeName"
        value="codeName"
        className="primary-header codeName"
        data-testid={`primary-row-code-name-title-${name}`}
      >
        {t('msg_codes_orders_codeName_label')}
      </div>
      <div
        key="region"
        id="region"
        value="region"
        className="primary-header region"
        data-testid={`primary-row-region-title-${name}`}
      >
        {t('msg_codes_common_region')}
      </div>
      <div
        key="country"
        id="country"
        value="country"
        className="primary-header country"
        data-testid={`primary-row-country-title-${name}`}
      >
        {countryString}
      </div>
      <div
        key="version"
        id="version"
        value="version"
        className="primary-header version"
        data-testid={`primary-row-version-title-${name}`}
      >
        {t('msg_codes_codes_version')}
      </div>
      <div
        key="sku"
        id="sku"
        value="sku"
        className="primary-header sku"
        data-testid={`primary-row-sku-title-${name}`}
      >
        {t('msg_codes_orderList_skuid')}
      </div>
      <div
        key="activity"
        value="activity"
        className="primary-header activity"
        data-testid={`primary-row-activity-title-${name}`}
      >
        <div className="activity-text">
          {t('msg_codes_common_activity')}
        </div>
        <div className="tooltip-wrapper">
          <Tooltip
            id="activity-tooltip"
            content={activityTooltipContent}
            position="top"
            shortCopy
            strategy="fixed"
            anchor={tableRef}
          >
            <Icon className="info-icon" size={iconSizes.Small}>info</Icon>
          </Tooltip>
        </div>
      </div>
      <div
        key="quantity"
        id="quantity"
        value="quantity"
        className="primary-header quantity"
        data-testid={`primary-row-quantity-title-${name}`}
      >
        {t('msg_codes_codes_quantity')}
      </div>
      {showUseCount && (
        <div
          key="useCount"
          value="useCount"
          className="primary-header useCount"
          data-testid={`primary-row-useCount-title-${name}`}
        >
          <div className="useCount-text">
            {t('msg_codes_use_count')}
          </div>
          <div className="tooltip-wrapper">
            <Tooltip
              id="useCount-tooltip"
              content={useCountTooltipContent}
              position="top"
              shortCopy
              strategy="fixed"
              anchor={tableRef}
            >
              <Icon className="info-icon" size={iconSizes.Small}>info</Icon>
            </Tooltip>
          </div>
        </div>
      )}
      <div
        key="orderFee"
        id="orderFee"
        value="orderFee"
        className="primary-header orderFee"
        data-testid={`primary-row-orderFee-title-${name}`}
      >
        <div className="fee-text">
          {t('msgid_codes_3pagreement_orderFee')}
        </div>
        <div className="tooltip-wrapper">
          <Tooltip
            id="order-fee-tooltip"
            content={orderFeeTooltipContent}
            position="top"
            shortCopy
            strategy="fixed"
            anchor={tableRef}
          >
            <Icon className="info-icon" size={iconSizes.Small}>info</Icon>
          </Tooltip>
        </div>
      </div>
      <div
        key="redemptionFee"
        id="redemptionFee"
        value="redemptionFee"
        className="primary-header redemptionFee"
        data-testid={`primary-row-redemptionFee-title-${name}`}
      >
        <div className="fee-text">
          {t('msg_codes_orders_redemptionFee').replace(/\(/, '\n(')}
        </div>
        <div className="tooltip-wrapper">
          <Tooltip
            id="redemption-fee-tooltip"
            content={redemptionFeeTooltipContent}
            position="top"
            shortCopy
            strategy="fixed"
            anchor={tableRef}
          >
            <Icon className="info-icon" size={iconSizes.Small}>info</Icon>
          </Tooltip>
        </div>
      </div>
    </>
  );
};

PrimaryRowHeader.propTypes = {
  name: PropTypes.string, // value used to prefix datatestIds in row
  tableRef: PropTypes.object,
  showUseCount: PropTypes.bool,
};

PrimaryRowHeader.defaultProps = {
  name: '', // value used to prefix datatestIds in row
  tableRef: { },
  showUseCount: false,
};

export default PrimaryRowHeader;
