import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function loadingUsersReducer(state = initialState.loadingUsers, action) {
  switch (action.type) {
    case types.BEGIN_LOAD_USERS:
      return true;
    case types.LOAD_USER_LIST_SUCCESS:
    case types.FINISH_LOAD_PARTNER_USERS:
      return false;
    default:
      return state;
  }
}
