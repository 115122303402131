export const MONEY = {
  code: 'MONEY',
  name: 'Money',
  stringId: 'msg_codes_voucherType_money_lowerCase',
};
export const PSPLUS = {
  code: 'PSPLUS',
  name: 'PS Plus',
  stringId: 'msg_codes_voucherType_psPlus_lowerCase',
};
export const PSNOW = {
  code: 'PSNOW',
  name: 'PS Now',
  stringId: 'msg_codes_voucherType_psNow_lowerCase',
};
export const PRODUCT = {
  code: 'PRODUCT',
  name: 'Product',
  stringId: 'msg_codes_voucherType_product_lowerCase',
};
