import React from 'react';
import PropTypes from 'prop-types';
import AgencyOrder from './Agency/AgencyOrder';
import ThirdPartyOrder from './ThirdParty/ThirdPartyOrder';

const OrderSelector = ({
  order,
  isApproved,
}) => {
  const { agreementType } = order;

  switch (agreementType) {
    case 'AGENCY':
      return (<AgencyOrder order={order} isApproved={isApproved} />);
    case 'THIRD_PARTY_CONTRACTUAL':
    case 'THIRD_PARTY_RATECARD':
      return (<ThirdPartyOrder order={order} isApproved={isApproved} />);
    default:
      return <></>;
  }
};

OrderSelector.propTypes = {
  order: PropTypes.object,
  isApproved: PropTypes.func,
};

OrderSelector.defaultProps = {
  order: {},
  isApproved: () => {},
};

export default OrderSelector;
