import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function selectedSkusReducer(state = initialState.selectedSkus, action) {
  switch (action.type) {
    case types.SET_SELECTED_SKUS:
      return [...action.selectedSkus];
    default:
      return state;
  }
}
