import i18n from 'i18next';
import _ from 'lodash';
import {
  loadAgreements,
  resetFilters,
  setAgreementsSearch,
  setFilter,
} from '../../actions/agreementActions';
import {
  optionsTranslationCreator,
  agreementFilterTypeOptions,
  agreementStatusOptions,
  registrationRegionOptions,
  voucherTypeOptions,
} from '../../constants/filterOptions.constants';

export const loadAgreementsList = ({
  dispatch,
  size,
  page,
  sort,
  filters,
}) => {
  loadAgreements(size, page, sort, filters)(dispatch);
};

export const applySearch = ({
  dispatch,
  size,
  page,
  sort,
  filters,
  search,
}) => {
  dispatch(setAgreementsSearch(search));
  loadAgreementsList({
    dispatch,
    size,
    page,
    sort,
    filters,
  });
};

export const applyFilters = ({
  dispatch,
  size,
  page,
  sort,
  filters,
}) => {
  loadAgreementsList({
    dispatch,
    size,
    page,
    sort,
    filters,
  });
};

export const clearFilters = ({
  dispatch,
  size,
  page,
  sort,
}) => {
  dispatch(resetFilters());
  loadAgreementsList({
    dispatch,
    size,
    page,
    sort,
  });
};

const sortOptions = (options, sortBy = 'label') => _.sortBy(options, sortBy);

export const createFilters = (
  filters,
  dispatch,
  countries,
) => {
  const {
    agreementType,
    country,
    region,
    status,
    voucherProductType,
  } = filters;

  const placeholder = i18n.t('msg_codes_select');

  const regionOptions = [
    {
      value: 'ALL',
      label: i18n.t('msg_codes_sieRegion_notApplicable'),
      attr: {
        'data-testid': 'all-regions',
      },
    },
    ...sortOptions(optionsTranslationCreator(registrationRegionOptions)),
  ];

  const countryOptions = _.sortBy(countries.map(option => ({
    value: option.code,
    label: i18n.t(option.nameStringId),
    attr: {
      'data-testid': `country-${option.code}`,
    },
  })), 'label');

  const handleSelectChange = (e, type) => {
    return dispatch(setFilter(type, e.target.value));
  };

  return [
    {
      label: i18n.t('msg_codes_agreements_contractingSIEregion'),
      name: 'region',
      placeholder,
      multiple: true,
      value: region,
      options: regionOptions,
      handleChange: e => handleSelectChange(e, 'region'),
    },
    {
      label: i18n.t('msg_codes_agreements_agreementType'),
      name: 'agreementType',
      placeholder,
      value: agreementType,
      multiple: true,
      options: sortOptions(optionsTranslationCreator(agreementFilterTypeOptions)),
      handleChange: e => handleSelectChange(e, 'agreementType'),
    },
    {
      label: i18n.t('msg_codes_agreements_agreementStatus_label'),
      name: 'status',
      placeholder,
      value: status,
      multiple: true,
      options: sortOptions(optionsTranslationCreator(agreementStatusOptions)),
      handleChange: e => handleSelectChange(e, 'status'),
    },
    {
      label: i18n.t('msg_codes_agreements_countryCoverage'),
      name: 'country',
      placeholder,
      value: country,
      multiple: true,
      options: countryOptions,
      handleChange: e => handleSelectChange(e, 'country'),
    },
    {
      label: i18n.t('msg_codes_voucherProductType'),
      name: 'voucherProductType',
      placeholder,
      value: voucherProductType,
      multiple: true,
      options: sortOptions(optionsTranslationCreator(voucherTypeOptions)),
      handleChange: e => handleSelectChange(e, 'voucherProductType'),
    },
  ];
};
