import i18next from 'i18next';
import { SUSPENDED, INACTIVE } from '../constants/agreementState.constants';
import orderStatuses from '../constants/order.constants';

export default ({ orderStatusOnAutoCancel }) => {
  return (
    orderStatusOnAutoCancel === orderStatuses.DRAFT_ORDER
    || orderStatusOnAutoCancel === orderStatuses.PENDING_DRAFT_AMENDMENT
    || orderStatusOnAutoCancel === orderStatuses.SUBMITTED_PENDING_APPROVAL
    || orderStatusOnAutoCancel === orderStatuses.APPROVED_ORDER
  );
};

export const getWarningMessage = (order = { agreementStatus: '', orderStatusOnAutoCancel: '' }) => {
  const { agreementStatus, orderStatusOnAutoCancel } = order;

  const wasDraft = orderStatusOnAutoCancel === orderStatuses.DRAFT_ORDER
    || orderStatusOnAutoCancel === orderStatuses.PENDING_DRAFT_AMENDMENT;

  let message = i18next.t('msg_codes_noActiveAgreements_error');

  if (wasDraft && agreementStatus === SUSPENDED) {
    message = i18next.t('msg_codes_agreementSuspended_error');
  } else if (wasDraft && agreementStatus === INACTIVE) {
    message = i18next.t('msg_codes_noAgreement_error');
  } else if (!wasDraft && (agreementStatus === INACTIVE || agreementStatus === SUSPENDED)) {
    message = i18next.t('msg_codes_noActiveAgreements_error');
  }

  return message;
};
