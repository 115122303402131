import axios from 'axios';
import * as types from './actionTypes';

// eslint-disable-next-line import/prefer-default-export
export async function getExternalUserStatus(dispatch, emailAddress) {
  return axios.get(`/venom/api/users/findByEmail/${emailAddress}`)
    .then(response => response.data)
    .then((user) => {
      if (user.externalUser) {
        dispatch({ type: types.SET_EXTERNAL_USER_STATUS, ...user });
        return { externalStatus: user.externalUser.status };
      }
      return null;
    })
    .catch(() => {
      return null;
    });
}
