import axios from 'axios';
import * as types from './actionTypes';
import { beginAjaxCall } from './ajaxStatusActions';
import { loadOrder } from './orderActions';

export function removeItemFromOrderSuccess(order, line) {
  return { type: types.REMOVE_ITEM_SUCCESS, order, line };
}

export function activateItemSuccess(order, line) {
  return { type: types.ACTIVATE_ITEM_SUCCESS, order, line };
}

export function cancelItemSuccess(order, line) {
  return { type: types.CANCEL_ITEM_SUCCESS, order, line };
}

export function removeItemFromOrder(order, line, dispatch) {
  return dispatch(removeItemFromOrderSuccess(order, line));
}

export function retryOrderLineSuccess(line) {
  return { type: types.RETRY_ORDER_LINE_SUCCESS, line };
}

export function deliverOrderLineSuccess(line) {
  return { type: types.DELIVER_ORDER_LINE_SUCCESS, line };
}

export function activateItem(order, line, dispatch) {
  dispatch(beginAjaxCall());
  return axios.put(`/venom/api/activate/${line.orderLineId}`, {})
    .then(response => response.data)
    .then((updatedLine) => {
      dispatch(activateItemSuccess(order, updatedLine));
    })
    .catch(() => {
      // TODO: error handling
      // dispatch(vouchersHasErrored(true))
    });
}

export function cancelItem(order, line, dispatch) {
  dispatch(beginAjaxCall());
  return axios.put(`/venom/api/cancel/${line.orderLineId}`, {})
    .then(response => response.data)
    .then((updatedLine) => {
      dispatch(cancelItemSuccess(order, updatedLine));
    })
    .catch(() => {
      // TODO: error handling
      // dispatch(vouchersHasErrored(true))
    });
}

export function retryOrderLine(orderLineId, dispatch, getState, orderNumber) {
  return axios.put(`/venom/api/retry/${orderLineId}`, {})
    .then(response => response.data)
    .then((line) => {
      if (getState && orderNumber) {
        loadOrder(orderNumber)(dispatch, getState);
      }
      dispatch(retryOrderLineSuccess(line));
    })
    .catch(() => {
      // TODO: error handling
      // dispatch(vouchersHasErrored(true))
    });
}

export function deliverOrderLine(id, dispatch) {
  return axios.put(`/venom/api/deliver/${id}`, {})
    .then(response => response.data)
    .then((line) => {
      dispatch(deliverOrderLineSuccess(line));
      return line;
    })
    .catch(() => {
      // TODO: error handling
      // dispatch(vouchersHasErrored(true))
    });
}
