import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Collapsible,
  DataTable,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableCell,
  GroupState,
  Layout,
  Flex,
  Tooltip,
} from '@partner-global-ui/components';
import { useTranslation } from 'react-i18next';
import * as ActivityCoverage from '../../../constants/permittedUsesActivityCoverage.constants';
import regions from '../../../utils/region.utils';

function ActivityTable({
  heading,
  activityData,
  regionCountries,
  defaultIsOpen,
}) {
  const { t } = useTranslation();
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    const data = activityData.map((item) => {
      const redemptionFee = item.redemptionFee === 'Store Margin'
        ? t(ActivityCoverage.redemptionFee[item.redemptionFee])
        : item.redemptionFee;

      const quantityLimit = item.quantityLimit === 'No Limit'
        ? t(ActivityCoverage.quantityLimit[item.quantityLimit])
        : item.quantityLimit;

      return {
        region: item.region,
        orderFee: `${item.orderFee} ${item.orderFeeCurrencyCode}`,
        orderFeeInvoiceTiming: t(ActivityCoverage.invoiceTiming[item.orderFeeInvoiceTiming]),
        redemptionFeeBillableProduct: `${redemptionFee} ${item.redemptionFeeCurrencyCode || ''}`,
        redemptionFeeInvoiceTiming:
          t(ActivityCoverage.invoiceTiming[item.redemptionFeeInvoiceTiming]),
        quantityLimit,
      };
    });

    setRowData(data);
  }, [activityData]);

  function renderHeaderCells() {
    const activityTableHeaders = [
      t('msg_codes_orderList_countryHeader'),
      t('msgid_codes_3pagreement_orderFee'),
      t('msg_codes_3pagreements_orderFeeInvoice'),
      t('msg_codes_3passignments_redemptionFee'),
      t('msg_codes_3pagreements_redemptionFeeInvoiceTiming'),
      t('msg_codes_3pagreements_quantityLimit'),
    ];

    return activityTableHeaders.map(header => (
      <TableHeaderCell key={header} value={header} id={header}>{header}</TableHeaderCell>
    ));
  }

  function renderCountriesTooltip(region) {
    // If no countries in region, return.
    if (typeof regionCountries[region] === 'undefined') {
      return '';
    }

    const regionAllCountries = regionCountries[region];
    const regionCountryCount = regionAllCountries.length;

    // If 1 country in region, display country.
    if (regionAllCountries.length === 1) {
      return (<> &#40;{regionAllCountries}&#41;</>);
    }

    const countryCountText = `(${regionCountryCount} ${t('msg_codes_countries')})`;

    // If 2+ countries in region, display countries in a tooltip.
    return (
      <span className="tooltip-wrapper">
        <Tooltip
          content={regionAllCountries.join(', ')}
          shortCopy={regionCountryCount <= 3}
          position="right"
        >
          {countryCountText}
        </Tooltip>
      </span>
    );
  }

  function renderTableRows() {
    /* eslint-disable react/no-array-index-key */
    return rowData.map((data, i) => (
      <TableRow
        key={`row-${i}`}
        id={`row-${i}`}
        hasCheckbox
        checkboxState={GroupState.ALL}
        expandable={false}
        pad
        checkboxProps={{ disabled: true }}
      >
        <TableCell key="country" id="country">
          {t(regions(data.region))}
          {renderCountriesTooltip(data.region)}
        </TableCell>
        <TableCell key="order-Fee" id="orderFee">
          {data.orderFee}
        </TableCell>
        <TableCell key="order-fee-invoice-timing" id="orderFeeInvoiceTiming">
          {data.orderFeeInvoiceTiming}
        </TableCell>
        <TableCell key="billable-product" id="redemptionFeeBillableProduct">
          {data.redemptionFeeBillableProduct}
        </TableCell>
        <TableCell key="redemption-fee-invoice-timing" id="redemptionFeeInvoiceTiming">
          {data.redemptionFeeInvoiceTiming}
        </TableCell>
        <TableCell key="quantity-liming" id="quantityLimit">
          {data.quantityLimit}
        </TableCell>
      </TableRow>
    ));
    /* eslint-enable react/no-array-index-key */
  }

  const testId = heading
    .toLowerCase()
    .replace('/', '-'); // Remove "/" from In-box/Pre-order Promotion

  return (
    <Layout>
      <Flex colSpan="12">
        <Collapsible
          heading={heading}
          id={`third-party-${testId}`}
          defaultIsOpen={defaultIsOpen}
        >
          <DataTable
            columnTemplate="3fr repeat(5, 1fr)"
            scrollHeight={400}
            hasCheckbox
            id={`third-party-${heading}-table`}
            data-testid={`third-party-${heading}-table`}
          >
            <TableHeader checkboxState={GroupState.ALL}>
              {renderHeaderCells()}
            </TableHeader>
            {renderTableRows()}
          </DataTable>
        </Collapsible>
      </Flex>
    </Layout>
  );
}

ActivityTable.propTypes = {
  heading: PropTypes.string,
  activityData: PropTypes.array,
  defaultIsOpen: PropTypes.bool,
  regionCountries: PropTypes.object,
};

ActivityTable.defaultProps = {
  heading: '',
  activityData: [],
  defaultIsOpen: false,
  regionCountries: {},
};

export default ActivityTable;
