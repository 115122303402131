export const ACTIVE = 'ACTIVE';
export const INACTIVE = 'INACTIVE';
export const DELETED = 'DELETED';

export const partnerStatuses = {
  '': [ACTIVE],
  ACTIVE: [ACTIVE, INACTIVE],
  INACTIVE: [ACTIVE, INACTIVE, DELETED],
  DELETED: [DELETED],
};

export const partnerTagStatuses = {
  ACTIVE: 'valid',
  INACTIVE: 'inactive',
  DELETED: 'error',
};

export const StatusStringMapping = {
  ACTIVE: 'msg_codes_partnerStatus_active',
  INACTIVE: 'msg_codes_partnerStatus_inactive',
  DELETED: 'Deleted',
};
