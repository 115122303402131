import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  updateOrderQuantity,
  updateOrderStartDate,
  updateOrderEndDate,
  updateOrderComments,
} from '../../../actions/orderHistoryActions';
import {
  showPurchaseOrderInput,
  isFreeAllocationActivityType,
  isPaidAllocationActivityType,
} from '../shared/orderValidation';
import ordersHistoryStatus from '../../../utils/ordersHistoryStatus';
import { activitySelectTypes } from '../../../constants/activityType.constants';
import PrimaryRow from '../shared/expandedContent/primaryRow';
import SecondaryRow from '../shared/expandedContent/secondaryRow';
import TertiaryOrderRow from '../shared/expandedContent/tertiaryOrderRow';
import OrderHistoryInfoTooltip from './OrderHistoryInfoTooltip';


const canEdit = (orderStatus) => {
  return orderStatus === ordersHistoryStatus.PENDING_DRAFT_AMENDMENT.statusName;
};

const showDateInputs = (orderStatus, activity) => {
  return !(isFreeAllocationActivityType({ value: activity })
    || isPaidAllocationActivityType({ value: activity }))
    && canEdit(orderStatus);
};

const shouldShowUseCount = (activity) => {
  return !(isFreeAllocationActivityType({ value: activity })
    || isPaidAllocationActivityType({ value: activity }));
};

const ExpandedOrderRow = ({
  order: {
    codeName,
    billable,
    version,
    urlToScpPar,
    voucherSku,
    availabilityRegion,
    countryAvailability,
    activity,
    quantity,
    orderId,
    orderStatus,
    partnerComments,
    partnerCommentsDate,
    partnerCommentsUser,
    amendmentComments,
    amendmentCommentsDate,
    amendmentCommentsUser,
    rejectedComments,
    rejectedCommentsDate,
    rejectedCommentsUser,
    poNumber,
    hasPreviousDownload,
    useCount,
    standardParId,
    codeFee,
    codeFeeCurrency,
  },
  order,
  tableRef,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [approverComments, setApproverComments] = useState({
    user: '',
    comment: '',
    date: '',
  });

  const handleQuantityChange = ({ target: { value } }) => {
    dispatch(updateOrderQuantity(orderId, value));
  };

  useEffect(() => {
    switch (orderStatus) {
      case ordersHistoryStatus.PENDING_DRAFT_AMENDMENT.statusName:
        setApproverComments({
          user: amendmentCommentsUser,
          comment: amendmentComments || '',
          date: amendmentCommentsDate,
        });
        break;
      case ordersHistoryStatus.REJECTED.statusName:
        setApproverComments({
          user: rejectedCommentsUser,
          comment: rejectedComments || '',
          date: rejectedCommentsDate,
        });
        break;
      default:
        break;
    }
  }, [
    orderStatus,
    amendmentComments,
    amendmentCommentsDate,
    amendmentCommentsUser,
    rejectedComments,
    rejectedCommentsDate,
    rejectedCommentsUser,
  ]);

  const selectedActivity = {
    ...activitySelectTypes[activity],
    label: t(activitySelectTypes[activity].translationStringId),
  };

  const handlePartnerCommentsChange = ({ target: { value } }) => {
    dispatch(updateOrderComments(orderId, value));
  };

  const handleDateTimeChange = (dispatchMethod) => {
    return (dateTime) => {
      dispatch(dispatchMethod(orderId, dateTime));
    };
  };

  const renderDownloadHistoryTooltip = () => {
    return (<OrderHistoryInfoTooltip order={order} tableRef={tableRef} />);
  };

  return (
    <div data-testid={`expanded-order-row-${orderId}`}>
      <PrimaryRow
        tableRef={tableRef}
        codeName={codeName}
        version={version}
        urlToScpPar={urlToScpPar}
        sku={voucherSku}
        availabilityRegion={availabilityRegion}
        countryAvailability={countryAvailability}
        activity={selectedActivity}
        showQuantityInput={canEdit(orderStatus)}
        showActivitySelect={false}
        showUseCount={shouldShowUseCount(activity)}
        useCount={useCount}
        quantity={quantity}
        handleQuantityChange={handleQuantityChange}
        name={orderId}
        standardParId={standardParId}
        codeFee={codeFee}
        codeFeeCurrency={codeFeeCurrency}
      />
      <SecondaryRow
        tableRef={tableRef}
        isOrderRowView
        order={order}
        activity={activity}
        catalogPoNumber={poNumber}
        handleStartDateTimeChange={handleDateTimeChange(updateOrderStartDate)}
        handleEndDateTimeChange={handleDateTimeChange(updateOrderEndDate)}
        startDate={order.startDate}
        endDate={order.endDate}
        showPurchaseOrderInput={showPurchaseOrderInput(billable, activity)}
        downloadHistoryTooltip={renderDownloadHistoryTooltip}
        showDownloadHistoryTooltip={hasPreviousDownload}
        canEditDates={showDateInputs(orderStatus, activity)}
        name={orderId}
      />
      <TertiaryOrderRow
        tableRef={tableRef}
        canEditPartnerComments={canEdit(orderStatus)}
        partnerComments={{
          user: partnerCommentsUser,
          comment: partnerComments || '',
          date: partnerCommentsDate || moment(),
        }}
        approverComments={approverComments}
        handlePartnerCommentsChange={handlePartnerCommentsChange}
        name={orderId}
      />
    </div>
  );
};

ExpandedOrderRow.propTypes = {
  order: PropTypes.object,
  tableRef: PropTypes.object,
};

ExpandedOrderRow.defaultProps = {
  order: { },
  tableRef: {},
};

export default ExpandedOrderRow;
