export default [{
  value: 'SCEA',
  label: 'SCEA',
}, {
  value: 'SCEE',
  label: 'SCEE',
}, {
  value: 'SCEH',
  label: 'SCEH',
}, {
  value: 'SCEJ',
  label: 'SCEJ',
}, {
  value: 'SCEK',
  label: 'SCEK',
}];
