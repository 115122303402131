export default [
  {
    id: 'msg_codes_voucherType_product_lowerCase',
    value: 'productName',
    sortable: true,
  },
  {
    id: 'msg_codes_codes_productID',
    value: 'productId',
    sortable: true,
  },
  {
    id: 'msg_codes_codes_productType',
    value: 'productTypeCode',
    sortable: true,
  },
  {
    id: 'msg_codes_common_region',
    value: 'availabilityRegion',
    sortable: true,
  },
  {
    id: 'msg_codes_codes_orderID',
    value: 'orderId',
    sortable: true,
  },
  {
    id: 'msg_codes_common_created',
    value: 'creationDate',
    sortable: true,
  },
  {
    id: 'msg_codes_agreements_createdBy',
    value: 'createdBy',
    sortable: true,
  },
];
