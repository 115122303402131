import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function UserListReducer(state = initialState.userList, action) {
  const {
    filterName,
    filterValue,
    search,
    type,
    users,
    page,
  } = action;

  switch (type) {
    case types.SET_USER_LIST_PAGE:
      return {
        ...state,
        page,
      };
    case types.SET_USERS_FILTER:
      return {
        ...state,
        filters: { ...state.filters, [filterName]: filterValue },
        page: {
          ...state.page,
          number: 0,
        },
        isFiltering: true,
      };
    case types.SET_USERS_SEARCH:
      return {
        ...state,
        search: { value: search },
        page: {
          ...state.page,
          number: 0,
        },
      };
    case types.CLEAR_USERS_SEARCH:
      return {
        ...state,
        search: '',
        page: {
          ...state.page,
          number: 0,
        },
      };
    case types.RESET_USERS_FILTERING:
      return {
        ...state,
        filters: initialState.userList.filters,
        page: {
          ...state.page,
          number: 0,
        },
        isFiltering: false,
      };
    case types.RESET_USERS_PAGE:
      return {
        ...initialState.userList,
      };
    case types.FINISH_FILTER_USERS:
      return {
        ...state,
        users,
      };
    case types.FINISH_LOAD_USERS:
      return {
        ...state,
        users,
      };
    default:
      return state;
  }
}
