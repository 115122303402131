import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import {
  Collapsible,
  Layout,
  Flex,
} from '@partner-global-ui/components';

import roles from '../../utils/accessControl/roleKeys';
import getFeature from '../../utils/accessControl/getFeature';
import getCountries from '../../actions/countryActions';

function VoucherTypeDetailSection({
  voucherType,
  isMoneyVoucherType,
  productTypes,
  disableProductType,
  regions,
  disableRegion,
  disableName,
  disableCountry,
  disableFaceValue,
  getVoucherTypeHeader,
  getHeaderDescriptionText,
  renderSelect,
  renderInputWithMaxCharLimit,
  renderFaceValue,
  loadingVoucherType,
  countries,
  country,
}) {
  const dispatch = useDispatch();
  const selectRef = useRef();
  const canSeeCountry = isMoneyVoucherType && !getFeature(roles.codeProducts.viewList);

  const productType = voucherType.npType ? {
    label: capitalize(voucherType.npType),
    value: voucherType.npType,
  } : '';

  const region = voucherType.npRegion ? {
    label: voucherType.npRegion,
    value: voucherType.npRegion,
  } : '';

  useEffect(() => {
    dispatch(getCountries(voucherType.npRegion, 'npRegion'));
  }, [voucherType.npRegion]);

  return (
    <div
      data-testid="voucher-type-details-section"
      ref={selectRef}
      className="voucher-type-details-section"
    >
      <Collapsible
        id="voucher-type-details-section-container"
        defaultIsOpen
        heading={getVoucherTypeHeader()}
        rightContent={voucherType.id === '' ? '' : getHeaderDescriptionText()}
        loading={loadingVoucherType}
      >
        <div data-testid="voucher-type-details-content" className="voucher-type-details-form">
          <Layout id="voucher-type-details-row-1" className="voucher-type-details-row-1">
            <Flex id="voucher-type-details-type" colSpan={6}>
              {renderSelect('msg_codes_voucher_product_type', true, productType, 'npType', productTypes, disableProductType, selectRef)}
            </Flex>
            <Flex id="voucher-type-details-region" colSpan={6}>
              {renderSelect('msg_codes_NP_region', true, region, 'npRegion', regions, disableRegion, selectRef)}
            </Flex>
          </Layout>
          <Layout id="voucher-type-details-row-2" className="voucher-type-details-row-2">
            <Flex id="voucher-type-details-name" colSpan={6}>
              {renderInputWithMaxCharLimit('msg_codes_voucher_type_name', true, voucherType.name, 'name', disableName, 1024, false, true)}
            </Flex>
            {canSeeCountry && (
              <Flex id="voucher-type-details-country" colSpan={6}>
                {renderSelect('msg_codes_orderList_countryHeader', true, country, 'country', countries, disableCountry, selectRef)}
              </Flex>
            )
            }
          </Layout>
          {isMoneyVoucherType
            && (
              <Layout id="voucher-type-details-row-3" className="voucher-type-details-row-3">
                <Flex id="voucher-type-details-faceValue" colSpan={6}>
                  {renderFaceValue('msg_codes_face_value', true, voucherType.faceValue, 'faceValue', disableFaceValue, 30, false)}
                </Flex>
              </Layout>
            )}
        </div>
      </Collapsible>
    </div>
  );
}

VoucherTypeDetailSection.propTypes = {
  voucherType: PropTypes.object,
  isMoneyVoucherType: PropTypes.bool,
  productTypes: PropTypes.array,
  disableProductType: PropTypes.bool,
  regions: PropTypes.array,
  disableRegion: PropTypes.bool,
  disableName: PropTypes.bool,
  disableCountry: PropTypes.bool,
  disableFaceValue: PropTypes.bool,
  getVoucherTypeHeader: PropTypes.func,
  getHeaderDescriptionText: PropTypes.func,
  renderSelect: PropTypes.func,
  renderInputWithMaxCharLimit: PropTypes.func,
  renderFaceValue: PropTypes.func,
  loadingVoucherType: PropTypes.bool,
  countries: PropTypes.array,
  country: PropTypes.object,
};

VoucherTypeDetailSection.defaultProps = {
  voucherType: {},
  isMoneyVoucherType: false,
  productTypes: [],
  disableProductType: false,
  regions: [],
  disableRegion: false,
  disableName: false,
  disableCountry: false,
  disableFaceValue: false,
  getVoucherTypeHeader: () => { },
  getHeaderDescriptionText: () => { },
  renderSelect: () => { },
  renderInputWithMaxCharLimit: () => { },
  renderFaceValue: () => { },
  loadingVoucherType: false,
  countries: [],
  country: {},
};

export default VoucherTypeDetailSection;
