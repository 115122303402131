import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Tooltip, Icon, TextInput, iconSizes,
} from '@partner-global-ui/components';

export const handleBlur = (e) => {
  if (e.currentTarget.value === '0') e.currentTarget.value = '1';
};

export const handleKeypress = (e) => {
  const characterCode = e.key;
  if (characterCode === 'Backspace') return;
  const characterNumber = Number(characterCode);
  if (characterNumber >= 0 && characterNumber <= 9) {
    if (e.currentTarget.value && e.currentTarget.value.length) {
      return;
    }
    if (characterNumber === 0) {
      e.preventDefault();
    }
  } else {
    e.preventDefault();
  }
};

const Quantity = ({
  quantity,
  showQuantityInput,
  handleQuantityChange,
  tableRef,
  name,
}) => {
  const { t } = useTranslation();

  const errorMessageQuantity = quantity * 1 > 900000 ? 'msg_codes_codeProdCat_900k_tooltip' : '';

  if (showQuantityInput) {
    return (
      <div className="quantity-input-wrapper">
        <TextInput
          value={quantity}
          placeholder={t('msg_codes_codeProdCat_900k_fieldText')}
          hasError={errorMessageQuantity}
          onChange={handleQuantityChange}
          onKeyDown={handleKeypress}
          onBlur={handleBlur}
          type="number"
          size="sm"
          name={name}
        />
        {errorMessageQuantity && (
          <div className="tooltip-wrapper">
            <Tooltip
              id={`${name}-quantity`}
              content={t(errorMessageQuantity)}
              position="top"
              shortCopy
              anchor={tableRef}
            >
              <Icon className="alert-icon" size={iconSizes.Small}>ico-alert</Icon>
            </Tooltip>
          </div>
        )}
      </div>
    );
  }
  return <>{quantity || t('msg_codes_codes_status_notAvailable')}</>;
};

Quantity.propTypes = {
  quantity: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  showQuantityInput: PropTypes.bool,
  handleQuantityChange: PropTypes.func,
  tableRef: PropTypes.object,
  name: PropTypes.string,
};

Quantity.defaultProps = {
  quantity: 1,
  showQuantityInput: false,
  handleQuantityChange: () => {},
  tableRef: {},
  name: '',
};

export default Quantity;
