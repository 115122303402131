import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Button,
  Layout,
  Checkbox,
  Flex,
} from '@partner-global-ui/components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { canceledPGPSetup, viewedPGPSetupPage } from '../../actions/userActions';
import './needPGPSetup.scss';

const NeedPGPSetupPage = ({ location }) => {
  //  Setup
  const {
    user: {
      userId,
      hasActiveFingerprint,
      mustSetupEncryptionKey,
    },
  } = useSelector(state => state);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userURL = `/user/${userId}`;
  const initialStateCheckBox = localStorage.getItem(`needsPGPSetup-${userId}`) !== null
  && localStorage.getItem(`needsPGPSetup-${userId}`) === 'true' ? Boolean(localStorage.getItem(`needsPGPSetup-${userId}`)) : false;
  const [doNotShowAgain, setDoNotShowAgain] = useState(initialStateCheckBox);

  useEffect(() => {
    viewedPGPSetupPage(true)(dispatch);
  }, [viewedPGPSetupPage, dispatch]);

  const getCancelUrl = useCallback(() => {
    const previousUrl = location?.state?.referrer;
    return typeof previousUrl === 'undefined' ? '/' : previousUrl;
  }, [location]);

  const handleCancel = useCallback(async () => {
    sessionStorage.setItem('canceledNeedsPGPSetup', 'true');
    canceledPGPSetup()(dispatch);
  }, [canceledPGPSetup, dispatch]);

  const renderNav = () => {
    return (
      <Layout className="header">
        <h3 className="title">{t('msg_codes_PGPKey')}</h3>
        <div className="buttons">
          {
            mustSetupEncryptionKey
              ? <a href="https://partners.sit.pt.playstation.net/hub"><Button id="cancel" onClick={() => handleCancel()}>{t('msg_codes_cta_cancel')}</Button></a>
              : <Link to={getCancelUrl()}><Button id="cancel" onClick={() => handleCancel()}>{t('msg_codes_cta_cancel')}</Button></Link>
          }
          <div className="spacer" />
          <Link to={`${userURL}?focus=pgpkey`}>
            <Button primary>
              { !hasActiveFingerprint && !mustSetupEncryptionKey
                ? t('msg_codes_cta_configurePGPKey')
                : t('msg_codes_cta_addPGPKey')
              }
            </Button>
          </Link>
        </div>
      </Layout>
    );
  };

  const handleChange = () => {
    localStorage.setItem(`needsPGPSetup-${userId}`, !doNotShowAgain);
    setDoNotShowAgain(!doNotShowAgain);
  };

  return (
    <Layout
      className="need-pgp-setup-page"
      data-testid="need-pgp-setup-page"
    >
      {renderNav()}
      <div
        className="need-pgp-setup-page-content"
        name="need-pgp-setup-page-content"
        data-testid="need-pgp-setup-page-content"
      >
        <Flex
          colSpan={6}
          offset={2}
          className="need-pgp-setup-page-content-col"
          name="need-pgp-setup-page-content-col"
          data-testid="need-pgp-setup-page-content-col"
        >
          {
            !hasActiveFingerprint && !mustSetupEncryptionKey
              ? (
                <>
                  <div data-testid="one-key-title">
                    <h3>{t('msg_codes_PGPKeys_oneKey_pleaseConfigure')}</h3>
                  </div>
                  <br />
                  <div data-testid="one-key-message">
                    <p>
                      {t('msg_codes_PGPKeys_oneKey_body')}
                    </p>
                  </div>
                </>
              )
              : (
                <>
                  <div data-testid="no-key-title">
                    <h3>{t('msg_codes_cta_addPGPKey')}</h3>
                  </div>
                  <br />
                  <div data-testid="no-key-message">
                    <p>
                      {t('msg_codes_PGPKeys_noKey_body')}
                    </p>
                  </div>
                </>
              )
          }
          <br />
          {
            !mustSetupEncryptionKey && (
              <div data-testid="do-not-show-checkbox">
                <Checkbox
                  id="not-show-checkbox"
                  data-testid="not-show-checkbox"
                  onClick={() => handleChange()}
                  checked={doNotShowAgain}
                  label={t('msg_codes_PGPKeys_dontShowMessage')}
                >
                  {t('msg_codes_PGPKeys_dontShowMessage')}
                </Checkbox>
              </div>
            )
          }
        </Flex>
      </div>
    </Layout>
  );
};

NeedPGPSetupPage.propTypes = {
  location: PropTypes.object,
};

NeedPGPSetupPage.defaultProps = {
  location: {},
};

export default NeedPGPSetupPage;
