import React, {
  useState, useEffect, useCallback, useContext,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Layout, Flex, Button, Divider, ModalTrigger,
  ModalContext, MODAL_ACTIONS, Tooltip,
} from '@partner-global-ui/components';
import PropTypes from 'prop-types';
import {
  resetPgpKey, updatePgpKey, validatePgpKey, removePgpKey, downloadPgpKey,
} from '../../actions/userActions';
import TextArea from './TextArea';

function PgpContainer({
  readonly,
  defaultOpen,
  permissions,
}) {
  const [showKey, setShowKey] = useState(false);
  const [preventDefaultOpen, setPreventDefaultOpen] = useState(false);
  const dispatch = useDispatch();
  const pgpKey = useSelector(state => state.pgpKey);
  const { userDetailForm } = useSelector(state => state.userPage);
  const { dispatch: modalDispatch } = useContext(ModalContext);
  const { t } = useTranslation();

  useEffect(() => {
    if ((defaultOpen || pgpKey.key) && !preventDefaultOpen && !showKey) {
      setShowKey(true);
    }
  }, [defaultOpen, preventDefaultOpen, pgpKey.key]);

  useEffect(() => {
    if (showKey && !readonly) {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }
  }, [showKey, readonly]);

  const handleCancel = useCallback(() => {
    const { hasActiveFingerprint } = userDetailForm;
    if (!hasActiveFingerprint) {
      setShowKey(false);
    }
    resetPgpKey(dispatch);
  }, [setShowKey, resetPgpKey, dispatch]);

  const handleRemove = useCallback(() => {
    setShowKey(false);
    setPreventDefaultOpen(true);
    removePgpKey(userDetailForm)(dispatch);
    modalDispatch({ type: MODAL_ACTIONS.HIDE });
  }, [setPreventDefaultOpen, removePgpKey, userDetailForm, dispatch]);

  const downloadKey = useCallback(() => {
    downloadPgpKey(userDetailForm)(dispatch);
  }, [downloadPgpKey, userDetailForm, dispatch]);

  const disabled = pgpKey.validating || !pgpKey.key;

  let validationText = '';
  if (pgpKey.validationError) {
    validationText = t('msg_codes_error_PGPKeyInvalid');
  } else if (pgpKey.validated) {
    validationText = t('msg_codes_PGPKeys_validated');
  }

  const inputDisabled = pgpKey.validating || pgpKey.removing;

  const toolbarItems = (permissions.downloadPgpKeys && userDetailForm.activeFingerprint) && (
    <div className="management-icons">
      <div className="download" data-testid="pgp-download-key">
        <Tooltip content={t('msg_codes_cta_download')} shortCopy>
          <Button id="download-pgp-button" link icon="ico-download" onClick={downloadKey} />
        </Tooltip>
      </div>
      <div className="pgp-delete-key" data-testid="pgp-delete-key">
        <Tooltip content={t('msg_codes_cta_delete')} shortCopy>
          <ModalTrigger
            link
            icon="ico-trashcan"
            id="delete-pgp"
            modalProps={{
              title: t('msg_codes_orderList_warning'),
              onPrimary: handleRemove,
              content: permissions.canSeeDetailedModalMessage
                ? t('msg_codes_pgp_key_remove')
                : t('msg_codes_confirmation_removePGPKey'),
              primaryLabel: t('msg_codes_cta_remove'),
              cancelLabel: t('msg_codes_cta_cancel'),
              id: 'warning-modal',
            }}
          />
        </Tooltip>
      </div>
    </div>
  );

  return (
    <div className="pgp-key">
      <Divider style={{ border: 'none' }} secondary />

      <Layout style={{ marginTop: '60px' }}>
        <Flex colSpan={5}>
          <div className="pgp-input-container">

            {showKey
              ? (
                <TextArea
                  disabled={inputDisabled}
                  defaultValue={pgpKey.activeFingerprint}
                  onChange={e => updatePgpKey(dispatch, e.target.value)}
                  placeholder={t('msg_codes_cta_addPGPKey')}
                  errorMessage={pgpKey.validationError && validationText}
                  successMessage={pgpKey.validated && validationText}
                  height={150}
                  toolbarItems={toolbarItems}
                  onSubmit={() => validatePgpKey(pgpKey.key)(dispatch)}
                  onCancel={handleCancel}
                  cancelLabel={t('msg_codes_cta_cancel')}
                  submitLabel={t('msg_codes_users_validate')}
                  submitDisabled={disabled}
                  cancelDisabled={disabled}
                  showSubmit={permissions.showValidate}
                  showCancel={permissions.showValidate}
                  focus={showKey && !readonly}
                />
              )
              : (
                <div id="add-pgp-button">
                  <Button link onClick={() => setShowKey(true)}>+ {t('msg_codes_cta_addPGPKey')}</Button>
                </div>
              )
            }
          </div>
        </Flex>
      </Layout>
    </div>
  );
}

PgpContainer.propTypes = {
  defaultOpen: PropTypes.bool,
  readonly: PropTypes.bool,
  permissions: PropTypes.object,
};

PgpContainer.defaultProps = {
  defaultOpen: false,
  readonly: false,
  permissions: {},
};

export default PgpContainer;
