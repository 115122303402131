import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  TableRow,
  TableCell,
  Tooltip,
} from '@partner-global-ui/components';
import { map, find } from 'lodash';
import PropTypes from 'prop-types';

import formatDate from '../../utils/formatDate';
import * as agreementTypes from '../../constants/agreementType.constants';
import * as codeProductTypes from '../../constants/codeProductTypes.constants';


const CodeProduct = ({ ...props }) => {
  //  Setup
  const { t } = useTranslation();
  const loadTypeStrings = {
    VARIABLE: 'msg_codes_variable',
    STANDARD: 'msg_codes_standard',
  };

  const codeProductTypeStrings = {
    ...codeProductTypes,
    PSNOW: { name: 'msg_codes_voucherType_psNow_lowerCase' },
    PSPLUS: { name: 'msg_codes_voucherType_psPlus_lowerCase' },
  };

  const {
    codeProduct: {
      codeProductName,
      codeProductType,
      loadType,
      voucherTypeId,
      applicableAgreementTypes,
      exclusiveToPartner,
      region,
      lastUpdated,
      status,
      voucherCatalogId,
    },
  } = props;

  const applicableAgreementTypesTooltip = () => {
    const agreementTypesData = map(agreementTypes, (label, value) => ({
      label: t(label),
      value,
    }));

    const agreementTypesContent = (
      applicableAgreementTypes.map((applicableAgreementType, index) => {
        const key = index + 1;
        const agreementType = find(
          agreementTypesData,
          type => type.value === applicableAgreementType,
        );

        return (
          <div
            data-testid={`agreement-type-${key}-${agreementType.value}`}
            key={key}
          >
            {agreementType.label}
          </div>
        );
      })
    );

    if (applicableAgreementTypes.length > 1) {
      return (
        <Tooltip
          placement="top"
          content={agreementTypesContent}
          id={`applicable-agreement-types-${voucherTypeId}`}
        >
          <span data-testid={`applicable-agreement-types-${voucherTypeId}-multiple`} className="multiple">{t('msg_codes_agreementType_multiple')}</span>
        </Tooltip>
      );
    }

    if (applicableAgreementTypes.length === 1) {
      const agreementType = find(
        agreementTypesData,
        type => type.value === applicableAgreementTypes[0],
      );
      return agreementType.label;
    }

    return '-';
  };

  const exclusiveToPartnerTooltip = () => {
    const exclusiveToPartnerOverlay = (
      exclusiveToPartner.map((exclusiveToPartnerItem, index) => {
        const key = index + 1;
        return (<div key={key}>{exclusiveToPartnerItem}</div>);
      })
    );

    if (exclusiveToPartner.length > 1) {
      return (
        <Tooltip
          placement="top"
          content={exclusiveToPartnerOverlay}
          id={`exclusive-to-partner-${voucherTypeId}`}
        >
          <span data-testid={`exclusive-to-partner-${voucherTypeId}-multiple`} className="multiple">{t('Multiple')}</span>
        </Tooltip>
      );
    }

    if (exclusiveToPartner.length === 1) {
      return exclusiveToPartner[0];
    }

    return '-';
  };

  const statusMap = {
    INACTIVE: 'msg_codes_agreementStatus_inactive',
    ACTIVE: 'msg_codes_agreementStatus_active',
  };

  return (
    <TableRow pad id={`table-row-${voucherTypeId}`} expandable={false}>
      <TableCell
        id="codeProduct-codeProductName"
        className="code-product-name"
      >
        <Link data-testid={`${codeProductName}=${voucherCatalogId}`} to={`/codeproduct/${voucherCatalogId}`}>{codeProductName}</Link>
      </TableCell>
      <TableCell
        id="codeProduct-codeProductType"
        className="code-product-type"
      >
        {codeProductTypeStrings[codeProductType] ? t(codeProductTypeStrings[codeProductType].name) : ''}
      </TableCell>
      <TableCell
        id="codeProduct-loadType"
        className="load-type"
      >
        {loadType ? t(loadTypeStrings[loadType]) : '-'}
      </TableCell>
      <TableCell
        id="codeProduct-npRegion"
        className="code-product-region"
      >
        {voucherTypeId}
      </TableCell>
      <TableCell
        id="codeProduct-applicableAgreementTypes"
        className="applicable-agreement-types"
      >
        {applicableAgreementTypesTooltip(applicableAgreementTypes)}
      </TableCell>
      <TableCell
        id="codeProduct-exclusiveToPartner"
        className="exclusive-to-Partner"
      >
        {exclusiveToPartnerTooltip(exclusiveToPartner)}
      </TableCell>
      <TableCell
        id="codeProduct-region"
        className="region"
      >
        {region}
      </TableCell>
      <TableCell
        id="codeProduct-lastUpdated"
        className="last-updated"
      >
        {formatDate(lastUpdated)}
      </TableCell>
      <TableCell
        id="codeProduct-status"
        className="dateModified"
      >
        {t(statusMap[status])}
      </TableCell>
    </TableRow>
  );
};

CodeProduct.propTypes = {
  codeProduct: PropTypes.object,
};

CodeProduct.defaultProps = {
  codeProduct: {},
};

export default CodeProduct;
