import * as CallbackGateways from '../../../../constants/callbackGateways.constants';

export function handletotalManufactureFreightCostAndAdditionalCostsDisplay(
  {
    freightCost,
    totalManufactureFreightCostAndAdditionalCosts:
      loadedtotalManufactureFreightCostAndAdditionalCosts,
    orderLines,
    additionalCosts,
  },
) {
  const additionalCostsFloat = parseFloat(additionalCosts || 0);
  const freightCostFloat = parseFloat(freightCost || 0);
  if (orderLines.length === 0) {
    return Number(freightCostFloat + additionalCostsFloat);
  }

  let totalMAFCDisplay = Number(loadedtotalManufactureFreightCostAndAdditionalCosts);
  let totalManufactureFreightCostAndAdditionalCosts = Number(orderLines.reduce((acc, curr) => {
    const {
      cardManufacturePrice,
      quantity,
    } = curr;

    return acc + Number(cardManufacturePrice * quantity);
  }, 0));

  totalManufactureFreightCostAndAdditionalCosts = Number(parseFloat(
    totalManufactureFreightCostAndAdditionalCosts,
  )) + Number(freightCostFloat);

  if (!Number.isNaN(totalManufactureFreightCostAndAdditionalCosts)) {
    totalMAFCDisplay = (Number(totalManufactureFreightCostAndAdditionalCosts)
    + additionalCostsFloat);
  }

  return totalMAFCDisplay;
}

export function isAdditionalRedemptionCheckRequired(
  voucherRedemptionCallback, voucherRedemptionCallbackMandatory,
) {
  if (voucherRedemptionCallback === CallbackGateways.NO_GATEWAY) {
    return false;
  }
  if (voucherRedemptionCallbackMandatory) {
    return true;
  }
  return null;
}

function isAdditionalRedemptionCheckRequiredDisabled(
  voucherRedemptionCallback, voucherRedemptionCallbackMandatory,
) {
  return voucherRedemptionCallback === CallbackGateways.NO_GATEWAY
    || voucherRedemptionCallbackMandatory;
}

export default (voucherRedemptionCallback, voucherRedemptionCallbackMandatory) => {
  if (
    typeof voucherRedemptionCallback !== 'string'
    || typeof voucherRedemptionCallbackMandatory !== 'boolean'
  ) {
    return {
      voucherRedemptionCallback: null, disabled: true, hidden: true,
    };
  }
  return {
    voucherRedemptionCallback: isAdditionalRedemptionCheckRequired(
      voucherRedemptionCallback, voucherRedemptionCallbackMandatory,
    ),
    disabled: isAdditionalRedemptionCheckRequiredDisabled(
      voucherRedemptionCallback, voucherRedemptionCallbackMandatory,
    ),
    hidden: false,
  };
};
