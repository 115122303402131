import React, { useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Collapsible,
  Switch,
  NotificationContext,
  AlertContext,
  ALERT_ACTIONS,
  Alert,
  STATUS,
} from '@partner-global-ui/components';

import { toggleMaintenanceMode } from '../../actions/appConfigActions';
import { MAINTENANCE_MODE } from '../../constants/appConfig.constants';
import boolToWord from '../../utils/boolToWord';

const MaintenanceMode = () => {
  // Setup
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const notificationContext = useContext(NotificationContext);
  const alertContext = useContext(AlertContext);
  const appConfig = useSelector(state => state.appConfig);

  const maintenanceModeConfig = appConfig
    .find(appConfigSection => appConfigSection.key === MAINTENANCE_MODE);

  const [maintenanceMode, setMaintenanceMode] = useState(
    JSON.parse(maintenanceModeConfig.value.toLowerCase()),
  );

  const handleToggle = () => {
    setMaintenanceMode(!maintenanceMode);
    dispatch(toggleMaintenanceMode(
      maintenanceModeConfig.key,
      !maintenanceMode,
      maintenanceModeConfig.revision,
      notificationContext,
    ));
  };

  const alertProps = {
    title: t('msg_codes_orderList_warning'),
    status: STATUS.INFO,
    content: t("You're about to turn on Maintenance Mode. Partners won't be able to access Codes until Maintenance Mode is turned off. Are you sure you want to continue?"),
    primaryButtonLabel: t('msg_codes_common_yes'),
    secondaryButtonLabel: t('msg_codes_cta_cancel'),
    primaryAction: () => {
      handleToggle();
    },
  };

  const renderAlert = (context) => {
    return (
      context.dispatch({
        type: ALERT_ACTIONS.SHOW,
        payload: {
          component: (
            <Alert {...alertProps} />
          ),
        },
      })
    );
  };

  return (
    <div data-testid="maintenance-mode" className="maintenance-mode">
      <Collapsible
        heading={t('msg_codes_maintenance_mode')}
        secondary
        defaultIsOpen
      >
        <div
          data-testid="maintenance-mode-content"
          className="maintenance-mode-content"
        >
          <span
            data-testid="maintenance-mode-label"
            className="maintenance-mode-label"
          >
            {t('msg_codes_toggle_maintenance_mode')}
          </span>
          <span
            data-testid="maintenance-mode-toggle"
            className="maintenance-mode-toggle"
          >
            <Switch
              id="maintenance-mode-switch"
              className="maintenance-mode-switch"
              checked={maintenanceMode}
              onChange={
                maintenanceMode
                  ? () => handleToggle()
                  : () => renderAlert(alertContext)
              }
            />
          </span>
          <span
            data-testid="maintenance-mode-value"
            className="maintenance-mode-value"
          >
            {boolToWord(maintenanceMode)}
          </span>
        </div>
      </Collapsible>
    </div>
  );
};

export default MaintenanceMode;
