import _ from 'lodash';
import i18n from 'i18next';

export function isVoucherBatchActionAllowed(
  action,
  {
    voucherStatus, state, npType, skusCount, partnerId,
  },
) {
  const readOnlyBatch = typeof partnerId === 'number';
  switch (action) {
    case 'activate':
      return voucherStatus === 'Inactive'
        && (state === 'CREATION_COMPLETE' || state === 'FILE_GENERATED'
          || state === 'FILE_GENERATION_ERROR') && (npType !== 'PRODUCT' || skusCount > 0)
        && !readOnlyBatch;
    case 'download':
      return state === 'FILE_GENERATED' && !readOnlyBatch;
    case 'deactivate':
      return (voucherStatus === 'Active' || voucherStatus === 'Inactive')
        && (state === 'CREATION_COMPLETE' || state === 'FILE_GENERATED'
          || state === 'FILE_GENERATION_ERROR') && !readOnlyBatch;
    case 'generate':
      return (state === 'CREATION_COMPLETE' || state === 'FILE_GENERATION_ERROR') && !readOnlyBatch;
    default:
      return false;
  }
}

export function isActionAllowed(
  action,
  batch,
  isReadOnlyBatch = true,
  npType,
  skusCount,
  isEditable = false,
) {
  if (!isEditable || isReadOnlyBatch) {
    return false;
  }
  const allowed = isVoucherBatchActionAllowed(action, batch);
  return (action === 'activate' && allowed && (npType !== 'PRODUCT' || skusCount > 0))
    || (action !== 'activate' && allowed);
}

export function formatVoucherBatchStatus(voucherBatch) {
  if (voucherBatch.voucherStatus === 'Inactive') {
    switch (voucherBatch.state) {
      case 'CREATION_IN_PROGRESS':
        return i18n.t('msg_codes_inactive_fulfilling');
      case 'CREATION_ERROR':
        return i18n.t('msg_codes_inactive_fulfillment_error');
      case 'FILE_GENERATION_IN_PROGRESS':
        return i18n.t('msg_codes_inactive_generating_file');
      case 'FILE_GENERATION_ERROR':
        return i18n.t('msg_codes_inactive_file_generation_error');
      case 'FILE_GENERATED':
      case 'CREATION_COMPLETE':
      default:
        return i18n.t('msg_codes_agreementStatus_inactive');
    }
  } else if (voucherBatch.voucherStatus === 'Active') {
    switch (voucherBatch.state) {
      case 'CREATION_IN_PROGRESS':
        return i18n.t('msg_codes_active_fulfilling');
      case 'CREATION_ERROR':
        return i18n.t('msg_codes_fulfillment_fulfillmentError');
      case 'FILE_GENERATION_IN_PROGRESS':
        return i18n.t('msg_codes_active_generating_file');
      case 'FILE_GENERATION_ERROR':
        return i18n.t('msg_codes_active_file_generation_error');
      case 'FILE_GENERATED':
      case 'CREATION_COMPLETE':
      default:
        return i18n.t('msg_codes_agreementStatus_active');
    }
  } else if (voucherBatch.voucherStatus === 'Deactivated') {
    return i18n.t('msg_codes_codes_status_deactivated');
  }
  return _.capitalize(voucherBatch.voucherStatus);
}

export function getKabobItems(batch, onClick, isEditable) {
  const items = [];
  const readOnlyBatch = typeof batch.partnerId === 'number';
  const skuCount = batch.skusCount || 0;

  if (isActionAllowed(
    'activate', batch,
    readOnlyBatch, batch.npType, skuCount.length,
    isEditable,
  )) {
    items.push({
      id: 'activate',
      icon: 'ico-download',
      label: i18n.t('msg_codes_activate_cta'),
      onClick: () => onClick('activate', [batch.voucherBatchId]),
    });
  }
  if (isActionAllowed(
    'generate', batch,
    readOnlyBatch, batch.npType, skuCount.length,
    isEditable,
  )) {
    items.push({
      id: 'generate',
      icon: 'ico-download',
      label: i18n.t('msg_codes_generate_file'),
      onClick: () => onClick('generate', [batch.voucherBatchId]),
    });
  }
  if (isActionAllowed(
    'download', batch,
    readOnlyBatch, batch.npType, skuCount.length,
    isEditable,
  )) {
    items.push({
      id: 'download',
      icon: 'ico-download',
      label: i18n.t('msg_codes_cta_download'),
      onClick: () => onClick('download', [batch.voucherBatchId]),
    });
  }
  if (isActionAllowed(
    'deactivate', batch,
    readOnlyBatch, batch.npType, skuCount.length,
    isEditable,
  )) {
    items.push({
      id: 'deactivate',
      icon: 'ico-cancel-circle',
      label: i18n.t('msg_codes_deactivate_cta'),
      onClick: () => onClick('deactivate', [batch.voucherBatchId]),
    });
  }
  return items;
}
