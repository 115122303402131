import React from 'react';
import {
  TextInput,
} from '@partner-global-ui/components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import TertiaryRowHeader from './tertiaryRowHeader';
import './tertiaryRow.scss';

const partnerCommentsTooltip = (partnerComments, placeholder) => {
  if (partnerComments && partnerComments.length > 0) {
    return (
      <div className="tooltipDefaultStyles partnerComments">
        { partnerComments }
      </div>
    );
  }
  return placeholder;
};

function TertiaryRow({
  canEditPartnerComments,
  handlePartnerCommentsChange,
  partnerComments,
  approverComments,
  name,
  tableRef,
}) {
  const { t } = useTranslation();
  return (
    <div className="tertiary-row">
      {
        (partnerComments.comment !== '' || canEditPartnerComments || approverComments.comment !== '')
          && (<TertiaryRowHeader name={name} />)
      }
      <div className="tertiary-order-row-content">
        { (partnerComments.comment !== '' || canEditPartnerComments)
          && (
            <>
              <div data-testid={`${name}-partner-commenter`} className="commenter">
                {partnerComments.user}
              </div>
              <div data-testid={`${name}-partner-commented-date`} className="commented-date">
                {moment.utc(partnerComments.date).format('hh:mm A DD/MM/YYYY')}
              </div>
              <TextInput
                disabled={!canEditPartnerComments}
                charCountMax={500}
                displayCount
                size="lg"
                width={900}
                value={partnerComments.comment || ''}
                onChange={handlePartnerCommentsChange}
                name={`${name}-partner-comment`}
                id={`${name}-partner-comment`}
                required={false}
                label=" "
                placeholder={t('msg_codes_codeProdCat_typeSomething_dropdown')}
                tooltip={{
                  position: 'top',
                  anchor: tableRef,
                  content: partnerCommentsTooltip(partnerComments.comment, t('msg_codes_codeProdCat_typeSomething_dropdown')),
                }}
              />
            </>
          )}
        { approverComments.comment !== ''
          && (
            <>
              <div data-testid={`${name}-approver-commenter`} className="commenter">
                {approverComments.user || t('msg_codes_users_system')}
              </div>
              <div data-testid={`${name}-approver-commented-date`} className="commented-date">
                {moment.utc(approverComments.date).format('hh:mm A DD/MM/YYYY')}
              </div>
              <div data-testid={`${name}-approver-comment`} className="comment">
                {t(approverComments.comment)}
              </div>
            </>
          )
        }
      </div>
    </div>
  );
}

TertiaryRow.propTypes = {
  name: PropTypes.string, // value used to prefix data-testid in row
  canEditPartnerComments: PropTypes.bool,
  handlePartnerCommentsChange: PropTypes.func,
  partnerComments: PropTypes.object,
  approverComments: PropTypes.object,
  tableRef: PropTypes.object,
};

TertiaryRow.defaultProps = {
  name: '', // value used to prefix data-testid in row
  canEditPartnerComments: false,
  handlePartnerCommentsChange: () => {},
  partnerComments: {
    comment: '',
    user: '',
    date: '',
  },
  approverComments: {
    comment: '',
    user: '',
    date: '',
  },
  tableRef: {},
};

export default TertiaryRow;
