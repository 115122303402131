import React, {
  useCallback,
  useState,
  useEffect,
  useContext,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  Button,
  NotificationContext,
  Tooltip,
  Icon,
} from '@partner-global-ui/components';
import {
  addItemFromCatalog,
  loadCartFromStorage,
} from '../../../actions/cartProductsActions';
import PrimaryRow from '../shared/expandedContent/primaryRow';
import SecondaryRow from '../shared/expandedContent/secondaryRow';
import TertiaryRow from '../shared/expandedContent/tertiaryRow';
import ThirdPartyDownloadHistory from '../shared/ThirdPartyDownloadHistory';
import { thirdPartyDownloadHistory } from '../../../actions/thirdPartyCatalogActions';

import {
  cartButtonState,
  isFreeAllocationActivityType,
  isPaidAllocationActivityType,
  isActivitySelected,
  showPurchaseOrderInput,
} from '../shared/orderValidation';

import { activitySelectTypes } from '../../../constants/activityType.constants';
import { autoHide, timeout } from '../../../constants/notifications.constant';
import thirdPartyDownloadStatus from '../../../utils/thirdPartyDownloadStatus';

export const updateProductWithOptions = (product, cart) => {
  const updatedOrderActivities = product.orderActivities.filter((orderActivity) => {
    const cartCheck = cart && cart.find((element) => {
      return element.voucherCatalogId === product.voucherCatalogId;
    });
    if (
      cartCheck
      && cartCheck.activity === 'PUBLISHER_PAID_ALLOCATION'
      && cartCheck.activity === orderActivity
    ) {
      return false;
    }
    return true;
  });
  return {
    ...product,
    orderActivities: ['SELECT', ...updatedOrderActivities],
  };
};

const showInputs = (activity) => {
  return !(isFreeAllocationActivityType(activity)
    || isPaidAllocationActivityType(activity))
    && isActivitySelected(activity.value);
};

const canEditComments = (activity) => {
  return isActivitySelected(activity.value) && !isFreeAllocationActivityType(activity);
};

function ExpandedCatalogRow({
  product,
  productVariant,
  // setDisableSubmitButton,
  // setPoFileAddedNotSubmitted,
  tableRef,
  downloadHistoryTitles,
  downloadPermissions,
  addProductToDownloadQueue,
  removeProductsFromDownloadQueue,
  catalogPageDispatch,
  selectedPartner,
  orderActivityRules,
  userId,
}) {
  const { t } = useTranslation();
  const notificationCont = useContext(NotificationContext);
  const dispatch = useDispatch();
  const { cart } = useSelector(state => state.cartPage);
  const updatedProductWithOptions = updateProductWithOptions(product, cart);
  const {
    codeName,
    urlToScpPar,
    availabilityRegion,
    countryAvailability,
    version,
    voucherSku,
    orderActivities = [],
    productId,
    status,
    voucherCatalogId,
    billable,
    downloaded,
  } = updatedProductWithOptions;
  const [loadingDownloadHistory, setLoadingDownloadHistory] = useState(false);
  const [downloadHistory, setDownloadHistory] = useState({});
  const [activity, setActivity] = useState({ label: '', value: '' });
  const [quantityValue, setQuantityValue] = useState('');
  const [catalogPoNumber, setCatalogPoNumber] = useState('');
  const [catalogPoFile, setCatalogPoFile] = useState({});
  const [partnerComments, setPartnerComments] = useState('');
  const [hasStartDateError, setHasStartDateError] = useState(false);
  const [hasEndDateError, setHasEndDateError] = useState(false);
  const [downloadStatus, setDownloadStatus] = useState({ buttonText: 'msg_codes_cta_download', wasClicked: false });
  const [disableCartButton] = useState(false);
  const [
    catalogStartDateTime,
    setCatalogStartDateTime,
  ] = useState('');
  const [
    catalogEndDateTime,
    setCatalogEndDateTime,
  ] = useState('');

  const buttonText = t(downloadStatus.buttonText);

  const initialActivitiesState = orderActivities.length
    ? orderActivities
    : typeof activity !== 'undefined'
      ? [activity]
      : [];

  const activityOptions = initialActivitiesState.map((activityOption) => {
    const option = activitySelectTypes[activityOption] || { name: null, value: null };
    return { label: t(option.translationStringId), value: option.value };
  }).filter(activityOption => activityOption.value !== null);

  const showUseCount = showInputs(activity);

  useEffect(() => {
    setDownloadStatus({
      ...thirdPartyDownloadStatus[status],
      wasClicked: false,
      hasPermission: downloadPermissions[status],
    });
  }, [status]);

  useEffect(() => {
    if (isFreeAllocationActivityType(activity)) {
      setQuantityValue(1000);
    } else if (
      isPaidAllocationActivityType(activity)
    ) {
      setQuantityValue(5000);
    } else {
      setQuantityValue('');
    }
    setCatalogPoNumber('');
    setCatalogPoFile({});
    setCatalogStartDateTime('');
    setCatalogEndDateTime('');
    setPartnerComments('');
  }, [activity && activity.value]);

  useEffect(() => {
    if (activity.value === '') {
      setActivity(activityOptions[0]);
    }
  }, [activityOptions, orderActivities]);

  const handleQuantityChange = useCallback(({ target: { value } }) => {
    if (value) {
      setQuantityValue(value);
    }
  }, [quantityValue]);

  const handleActivityChange = ({ target: { value } }) => {
    if (value !== '') {
      setActivity(value);
    }
  };

  const handlePartnerCommentsChange = ({ target: { value } }) => {
    setPartnerComments(value);
  };

  const handleResponse = (localProduct) => {
    setDownloadStatus({
      ...thirdPartyDownloadStatus[localProduct.status],
      wasClicked: false,
      hasPermission: downloadPermissions[status],
    });
  };

  const handleDownload = () => {
    setDownloadStatus({
      ...downloadStatus,
      wasClicked: downloadStatus.toggleLoadingOnClick,
      hasPermission: downloadPermissions[status],
    });
    thirdPartyDownloadStatus[downloadStatus.statusName].onButtonClick(
      voucherCatalogId,
      notificationCont,
      addProductToDownloadQueue,
      catalogPageDispatch,
      dispatch,
      removeProductsFromDownloadQueue,
      selectedPartner,
    ).then(handleResponse, handleResponse);
  };

  const addToCart = useCallback((
    poNumber,
    cPoFile,
  ) => {
    if (!activity.value || !quantityValue) return;

    const item = {
      activity,
      quantity: Number(quantityValue),
      poNumber,
      poFile: cPoFile,
      partnerComments,
      startDate: catalogStartDateTime,
      endDate: catalogEndDateTime,
    };
    try {
      dispatch(addItemFromCatalog(
        productVariant,
        updatedProductWithOptions,
        item,
      ));
      dispatch(loadCartFromStorage(userId));
      setQuantityValue('');
      setActivity(activityOptions[0]);
      setCatalogPoNumber('');
      setCatalogPoFile({});
      setCatalogStartDateTime('');
      setCatalogEndDateTime('');
      setPartnerComments('');
      if (typeof notificationCont !== 'undefined') {
        notificationCont.dispatch({
          type: 'add',
          payload: {
            status: 'success',
            autoHide,
            timeout,
            message: t('msg_codes_codeProdCat_addedtoCart_banner'),
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [
    activity.value,
    quantityValue,
    updatedProductWithOptions,
    catalogPoNumber,
    catalogStartDateTime,
    catalogEndDateTime,
    catalogPoFile,
    partnerComments,
  ]);


  const addCartDisabled = useCallback(() => {
    const sDate = catalogStartDateTime.substring(0, catalogStartDateTime.indexOf('T'));
    const startTime = catalogStartDateTime
      .substring(catalogStartDateTime.indexOf('T') + 1).slice(0, -1);

    const eDate = catalogStartDateTime.substring(0, catalogStartDateTime.indexOf('T'));
    const endTime = catalogStartDateTime
      .substring(catalogStartDateTime.indexOf('T') + 1).slice(0, -1);

    return cartButtonState(
      {
        isCatalogRowView: true,
        activity,
        billable,
        sDate,
        eDate,
        startTime,
        endTime,
        quantityValue,
        catalogPoNumber,
        catalogPoFile,
      },
      {},
      orderActivityRules,
    );
  }, [
    catalogStartDateTime,
    catalogEndDateTime,
    quantityValue,
    catalogPoNumber,
    catalogPoFile,
  ]);

  const renderButton = useCallback(() => {
    if (isFreeAllocationActivityType(activity)) {
      return (
        <Button
          id={`product-${productId}`}
          name={`product-${codeName}`}
          data-testid="download-button"
          onClick={handleDownload}
          primary={downloadStatus.buttonStyle === 'primary'}
          secondary={downloadStatus.buttonStyle === 'secondary'}
          link={downloadStatus.buttonStyle === 'link'}
          disabled={downloadStatus.buttonDisabled}
          loading={downloadStatus.showLoading || downloadStatus.wasClicked}
        >
          {buttonText}
        </Button>
      );
    }

    return (
      <Button
        id={`product-${productId}`}
        name={`product-${codeName}`}
        data-testid="download-button"
        onClick={() => addToCart(
          catalogPoNumber,
          catalogPoFile,
        )}
        primary
        disabled={disableCartButton || addCartDisabled()}
      >
        {t('msg_codes_codeProdCat_addToOrder_label')}
      </Button>
    );
  }, [
    activity.value,
    quantityValue,
    downloadStatus,
    catalogPoNumber,
    catalogStartDateTime,
    catalogEndDateTime,
    catalogPoFile,
    disableCartButton,
    partnerComments,
  ]);

  const getDownloadHistory = async () => {
    const history = await thirdPartyDownloadHistory(voucherCatalogId, setLoadingDownloadHistory);
    if (history) {
      setDownloadHistory(history);
    }
  };

  const downloadHistoryToolTip = useCallback(() => {
    const noHistoryMessage = t('msg_codes_codes_freeCodes_tooltip_error');
    if (isFreeAllocationActivityType(activity) && downloaded) {
      return (
        <div className="download-history-tooltip" onMouseEnter={getDownloadHistory}>
          <Tooltip
            id="downloadHistory"
            position="bottom"
            content={(
              <ThirdPartyDownloadHistory
                downloadHistory={downloadHistory}
                downLoadHistoryTitles={downloadHistoryTitles}
                noHistoryMessage={noHistoryMessage}
                loadingDownloadHistory={loadingDownloadHistory}
              />
            )}
            strategy="fixed"
            anchor={tableRef}
          >
            <Icon>ico-info</Icon>
          </Tooltip>
        </div>
      );
    }
    return null;
  }, [activity]);

  const handleStartDateTimeChange = useCallback((dateTime) => {
    const updatedStartDateTime = dateTime;

    if (
      updatedStartDateTime
      && updatedStartDateTime !== 'Invalid date'
      && updatedStartDateTime !== ''
    ) {
      if (!moment(updatedStartDateTime).isValid()) {
        setHasStartDateError(true);
        return;
      }
      setHasStartDateError(false);
      setCatalogStartDateTime(updatedStartDateTime);
    }
  });

  const handleEndDateTimeChange = useCallback((dateTime) => {
    const updatedEndDateTime = dateTime;

    if (
      updatedEndDateTime
      && updatedEndDateTime !== 'Invalid date'
      && updatedEndDateTime !== ''
    ) {
      if (!moment(updatedEndDateTime).isValid()) {
        setHasEndDateError(true);
        return;
      }
      setHasEndDateError(false);
      setCatalogEndDateTime(updatedEndDateTime);
    }
  });

  return (
    <>
      <PrimaryRow
        tableRef={tableRef}
        codeName={codeName}
        version={version}
        urlToScpPar={urlToScpPar}
        sku={voucherSku}
        availabilityRegion={availabilityRegion}
        countryAvailability={countryAvailability}
        showActivitySelect
        activity={activity}
        activityOptions={activityOptions}
        handleActivityChange={handleActivityChange}
        showQuantityInput={showInputs(activity)}
        quantity={quantityValue || ''}
        handleQuantityChange={handleQuantityChange}
        showUseCount={showUseCount}
        name={voucherCatalogId}
        standardParId={product.standardParId}
      />
      <SecondaryRow
        order={product}
        isCatalogRowView
        catalogPoNumber={catalogPoNumber}
        setCatalogPoNumber={setCatalogPoNumber}
        catalogPoFile={catalogPoFile}
        setCatalogPoFile={setCatalogPoFile}
        activity={activity}
        handleStartDateTimeChange={handleStartDateTimeChange}
        hasStartDateError={hasStartDateError}
        handleEndDateTimeChange={handleEndDateTimeChange}
        hasEndDateError={hasEndDateError}
        canEditDates={showInputs(activity)}
        startDate={catalogStartDateTime}
        endDate={catalogEndDateTime}
        showPurchaseOrderInput={showPurchaseOrderInput(billable, activity.value)}
        name={voucherCatalogId}
      />
      <TertiaryRow
        tableRef={tableRef}
        partnerComments={partnerComments}
        handlePartnerCommentsChange={handlePartnerCommentsChange}
        showCTA
        renderCTA={renderButton}
        downloadStatus={downloadStatus}
        downloadHistoryToolTip={downloadHistoryToolTip}
        canEditComments={canEditComments(activity)}
        name={voucherCatalogId}
      />
    </>
  );
}

ExpandedCatalogRow.propTypes = {
  product: PropTypes.object,
  productVariant: PropTypes.object,
  downloadHistoryTitles: PropTypes.object,
  // setDisableSubmitButton: PropTypes.func,
  // setPoFileAddedNotSubmitted: PropTypes.func,
  tableRef: PropTypes.object,
  downloadPermissions: PropTypes.object,
  addProductToDownloadQueue: PropTypes.func,
  catalogPageDispatch: PropTypes.func,
  removeProductsFromDownloadQueue: PropTypes.func,
  selectedPartner: PropTypes.object,
  orderActivityRules: PropTypes.object,
  userId: PropTypes.number,
};

ExpandedCatalogRow.defaultProps = {
  product: { },
  productVariant: {},
  downloadHistoryTitles: {
    fileName: 'msg_codes_codes_fileName',
    orderID: 'msg_codes_codes_orderID',
    batchID: 'msg_codes_codes_batchID',
    dateLastDownloaded: 'msg_codes_codes_lastDownloaded',
    userLastDownloaded: 'msg_codes_codes_lastDownloadedBy',
    codeStartEndDate: 'msg_codes_codes_codeStartEndDate',
    expirationMessage: 'msg_codes_codes_freeCodes_tooltip_body',
  },
  // setDisableSubmitButton: () => {},
  // setPoFileAddedNotSubmitted: () => {},
  tableRef: {},
  downloadPermissions: {
    FULFILLED: false,
    NOT_REQUESTED: false,
    ACTIVATED: false,
    ACTIVATION_ERROR: false,
    FULFILLMENT_ERROR: false,
  },
  addProductToDownloadQueue: () => {},
  catalogPageDispatch: () => {},
  removeProductsFromDownloadQueue: () => {},
  selectedPartner: {},
  orderActivityRules: {},
  userId: 0,
};

export default ExpandedCatalogRow;
