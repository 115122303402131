import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button, Pill, Divider, Icon,
} from '@partner-global-ui/components';
import { useTranslation } from 'react-i18next';

import './SecondaryNav.scss';

function SecondaryNav({ isOrdersPage, cartSize }) {
  const { t } = useTranslation();

  return (
    <div className="links-header-container" data-testid="links-header-container">
      <Link className="orders-link" to={isOrdersPage ? '/thirdPartyCatalog' : '/orderhistory'}>
        <Button id="orders" link>
          {isOrdersPage ? t('msg_codes_codeProductCatalog') : t('msg_codes_orders')}
        </Button>
      </Link>
      <Divider
        vertical
        id="links-header-divider"
        style={{
          marginRight: '15px',
          marginTop: '10px',
          height: '20px',
        }}
      />
      <Link className="cart-link" to="/cart">
        {cartSize > 0 && <Pill status="error" label={cartSize} />}
        <Icon className="shopping-cart" size={20}>shopping_cart</Icon>
        <Button id="cart" link>
          {t('msg_codes_common_cart')}
        </Button>
      </Link>
    </div>
  );
}

SecondaryNav.propTypes = {
  cartSize: PropTypes.number,
  isOrdersPage: PropTypes.bool,
};

SecondaryNav.defaultProps = {
  cartSize: 0,
  isOrdersPage: false,
};

export default SecondaryNav;
