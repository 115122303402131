import React, {
  useEffect,
  useState,
  useContext,
} from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Collapsible,
  TextInput,
  Button,
  Select,
  NotificationContext,
} from '@partner-global-ui/components';
import { isEmpty } from 'lodash';

import { autoHide, timeout } from '../../../constants/notifications.constant';
import createBatchNotification from '../../../actions/notificationsActions';

const BatchNotifications = () => {
  // Setup
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const notificationContext = useContext(NotificationContext);

  const entityTypes = [
    { value: 'VOUCHER_TYPE', label: t('msg_codes_voucher_type_admin') },
    { value: 'VOUCHER_BATCH', label: t('msg_codes_voucher_batch_admin') },
  ];

  const notificationTypes = [
    { value: 'CREATE', label: t('msg_codes_notification_type_create') },
    { value: 'UPDATE', label: t('msg_codes_notification_type_update') },
    { value: 'ACTIVE', label: t('ACTIVE') },
    { value: 'CANCEL', label: t('msg_codes_notification_type_cancel') },
  ];

  const [creating, setCreating] = useState(false);
  const [entityId, setEntityId] = useState('');
  const [entityIdErrorMessage, setEntityIdErrorMessage] = useState('');
  const [entityType, setEntityType] = useState('');
  const [notificationType, setNotificationType] = useState('');
  const isCreateDisabled = isEmpty(entityId)
    || isEmpty(entityType)
    || isEmpty(notificationType);

  useEffect(() => {
    const showError = isEmpty(entityId) && !isEmpty(entityType) && !isEmpty(notificationType);
    if (showError) {
      setEntityIdErrorMessage(t('msg_codes_entity_id_error'));
    } else {
      setEntityIdErrorMessage('');
    }
  }, [entityId, entityType, notificationType]);

  useEffect(() => {
    setNotificationType('');
  }, [entityType]);

  const handleEntityIdChange = (e) => {
    setEntityId(e.target.value);
  };

  const handleEntityTypeChange = (e) => {
    setEntityType(e.target.value);
  };

  const handleNotificationTypeChange = (e) => {
    setNotificationType(e.target.value);
  };

  const reset = () => {
    setCreating(false);
    setEntityId('');
    setEntityIdErrorMessage('');
    setEntityType('');
    setNotificationType('');
  };

  const create = () => {
    setCreating(true);
    dispatch(createBatchNotification(
      entityId,
      entityType.value,
      notificationType.value,
    )).then((res) => {
      if (!isEmpty(res)) {
        notificationContext.dispatch({
          type: 'add',
          payload: {
            status: 'error',
            autoHide,
            timeout,
            message: t('msg_codes_codes_banner_somethingWentWrong'),
            testId: 'error',
          },
        });
      }
      reset();
    });
  };

  return (
    <div
      data-testid="batch-notifications"
      className="batch-notifications"
    >
      <Collapsible
        id="batch-notifications-collapsible"
        heading={t('msg_codes_batch_notifications')}
        secondary
        defaultIsOpen
      >
        <>
          <div
            data-testid="batch-notifications-content"
            className="batch-notifications-content"
          >
            <span
              data-testid="batch-notifications-label"
              className="batch-notifications-label"
            >
              {t('msg_codes_new_batch_notification_info')}
            </span>
            <div
              data-testid="inputs"
              className="inputs"
            >
              <span
                data-testid="entity-id"
                className="entity-id"
              >
                <TextInput
                  id="entity-id-text"
                  value={entityId}
                  onChange={e => handleEntityIdChange(e)}
                  width={400}
                  required
                  label={t('msg_codes_entity_id')}
                  placeholder={t('Enter Voucher Type or Batch ID...')}
                  hasError={!isEmpty(entityIdErrorMessage)}
                  errorMessage={entityIdErrorMessage}
                />
              </span>
              <span
                data-testid="entity-type"
                className="entity-type"
              >
                <Select
                  id="entity-type-select"
                  options={entityTypes}
                  value={entityType}
                  onChange={e => handleEntityTypeChange(e)}
                  required
                  label={t('msg_codes_entity_type')}
                  placeholder={t('msg_codes_select')}
                />
              </span>
              <span
                data-testid="notification-type"
                className="notification-type"
              >
                <Select
                  id="notification-type-select"
                  options={
                   entityType.value === 'VOUCHER_BATCH'
                     ? notificationTypes
                     : notificationTypes.filter(({ value }) => value !== 'CANCEL' && value !== 'ACTIVE')
                  }
                  value={notificationType}
                  onChange={e => handleNotificationTypeChange(e)}
                  required
                  label={t('msg_codes_notification_type')}
                  placeholder={t('msg_codes_select')}
                  disabled={isEmpty(entityType)}
                />
              </span>
            </div>
          </div>
          <footer
            data-testid="batch-notifications-footer"
            className="batch-notifications-footer"
          >
            <Button
              primary
              disabled={isCreateDisabled}
              id="create-button"
              onClick={() => create()}
              loading={creating}
            >
              {t('msg_codes_create_new_batch_notification')}
            </Button>
          </footer>
        </>
      </Collapsible>
    </div>
  );
};

export default BatchNotifications;
