import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function voucherTypesReducer(state = initialState.voucherTypeCatalogs, action) {
  switch (action.type) {
    case types.FINISH_FILTER_VOUCHER_TYPES:
      return [...action.voucherTypeCatalogs];
    case types.FINISH_LOAD_VOUCHER_TYPES:
      return [...action.voucherTypeCatalogs];
    default:
      return state;
  }
}
