import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  TableRow,
  TableCell,
  Tooltip,
  Icon,
} from '@partner-global-ui/components';
import { useTranslation } from 'react-i18next';
import formatDate from '../../utils/formatDate';

const VoucherType = ({ ...props }) => {
  const { t } = useTranslation();

  const renderVoucherTypeName = () => {
    const {
      voucherType: {
        name,
        id,
        skusCount,
        npType,
      },
    } = props;
    const showIndicator = npType === 'PRODUCT' && skusCount === 0;
    return (
      <>
        {showIndicator && (
          <Tooltip content={t('No SKUs Attached')}>
            <Icon>ico-info</Icon>
          </Tooltip>
        )}
        <Link to={`/vouchertype/${id}`}>{name}</Link>
      </>
    );
  };

  const voucherProductTypes = {
    MONEY: t('msg_codes_voucherType_money_lowerCase'),
    PRODUCT: t('msg_codes_voucherType_product_lowerCase'),
  };

  const {
    voucherType: {
      id: voucherTypeId,
      npType: voucherProductType = '',
      npRegion: voucherTypeRegion,
      dateModified: lastUpdated,
    },
    ...rest
  } = props;

  return (
    <TableRow
      pad
      id={`voucher-type-row${voucherTypeId}`}
      className="voucher-type-row"
      {...rest}
    >
      <TableCell id="voucherType-voucherTypeName" className="voucherTypeName">{renderVoucherTypeName()}</TableCell>
      <TableCell id="voucherType-voucherProductType" className="voucherProductType">{voucherProductTypes[voucherProductType]}</TableCell>
      <TableCell id="voucherType-npRegion" className="voucherTypeRegion"><span>{voucherTypeRegion}</span></TableCell>
      <TableCell id="voucherType-voucherTypeId" className="voucherTypeId"><span>{voucherTypeId}</span></TableCell>
      <TableCell id="voucherType-dateModified" className="dateModified">{formatDate(lastUpdated)}</TableCell>
    </TableRow>
  );
};

VoucherType.propTypes = {
  voucherType: PropTypes.object,
};

VoucherType.defaultProps = {
  voucherType: {},
};

export default VoucherType;
