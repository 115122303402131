import * as types from '../actions/actionTypes';
import initialState from './initialState';

const updateVouchers = (newProducts, currentProducts) => {
  const updatedProducts = currentProducts.map((product) => {
    const matchingProduct = newProducts.find((newProduct) => {
      return newProduct.voucherCatalogId === product.voucherCatalogId;
    });
    if (typeof matchingProduct === 'undefined') {
      return product;
    }
    return matchingProduct;
  });
  return updatedProducts;
};

export default function catalogReducer(state = initialState.vouchers, action) {
  switch (action.type) {
    case types.FINISH_FILTER_VOUCHERS:
      return [...action.vouchers];
    case types.FINISH_LOAD_VOUCHERS:
      return [...action.vouchers];
    case types.UPDATE_FROM_POLLING:
      return updateVouchers(action.products, state);
    default:
      return state;
  }
}
