const productTypes = [
  { value: 'GAME.ADD_ON_SERVICE_ENTITLEMENT', stringId: 'msg_codes_productType_addOnService' },
  { value: 'GAME.ADD_ON_UNIFIED_ENTITLEMENT', stringId: 'msg_codes_productType_addOnUnified' },
  { value: 'GAME.APPLICATION', stringId: 'msg_codes_productType_application' },
  { value: 'GAME.AVATAR', stringId: 'msg_codes_productType_avatar' },
  { value: 'GAME.BUNDLE', stringId: 'msg_codes_productType_bundle' },
  { value: 'GAME.CREATOR_PACK', stringId: 'msg_codes_productType_creatorPack' },
  { value: 'GAME.DEMO', stringId: 'msg_codes_productType_demo' },
  { value: 'GAME.FALLBACK', stringId: 'msg_codes_productType_fallback' },
  { value: 'GAME.FULL_GAME', stringId: 'msg_codes_productType_fullGame' },
  { value: 'GAME.SHAREFACTORY_THEME', stringId: 'msg_codes_productType_sharefactoryTheme' },
  { value: 'GAME.SUBSCRIPTION', stringId: 'msg_codes_productType_subscription' },
  { value: 'GAME.THEME', stringId: 'msg_codes_productType_theme' },
];

export const getProductType = (productTypeCode) => {
  const matchingProductType = productTypes.find((productType) => {
    return productType.value === productTypeCode;
  });
  return matchingProductType ? matchingProductType.stringId : productTypeCode;
};

export const platforms = [
  { value: 'PS5', label: 'PS5', stringId: 'msg_codes_platform_ps5' },
  { value: 'PS4', label: 'PS4', stringId: 'msg_codes_platform_ps4' },
];

export const getPlatform = (platformCode) => {
  const matchingPlatform = platforms.find((platform) => {
    return platform.value === platformCode;
  });
  return matchingPlatform ? matchingPlatform.stringId : platformCode;
};

export const versions = [
  { value: 'FULL', label: 'Full Game', stringId: 'msg_codes_version_full' },
  { value: 'TRIAL', label: 'Trial', stringId: 'msg_codes_version_trial' },
];

export const getVersion = (versionCode) => {
  const matchingVersion = versions.find((version) => {
    return version.value === versionCode;
  });
  return matchingVersion ? matchingVersion.stringId : versionCode;
};
