import React, {
  useState, useEffect, useCallback, useContext,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import './Agreements.scss';
import PropTypes from 'prop-types';
import { NotificationContext } from '@partner-global-ui/components';
import hasPermission from '../../utils/accessControl/hasPermission';
import roleKeys from '../../utils/accessControl/roleKeys';
import * as agreementTypes from '../../constants/agreementType.constants';
import * as AgreementActions from '../../actions/agreementActions';
import * as AgreementFormActions from '../../actions/agreementFormActions';
import * as PartnerActions from '../../actions/partnerActions';
import getCurrencies from '../../actions/currencyActions';
import getCountries from '../../actions/countryActions';
import CreateAgreementPage from './CreateAgreementPage';
import FreeAllocationDetailPage from './FreeAllocationDetailPage';
import PermittedUsesDetailPage from './PermittedUsesDetailPage';
import NotAuthorized from '../error/NotAuthorized';

const AgreementPageSelector = ({ location, match, history }) => {
  const notificationContext = useContext(NotificationContext);
  const dispatch = useDispatch();
  // Global State
  const currencies = useSelector(state => state.currencies);
  const countries = useSelector(state => state.countries);
  const partners = useSelector(state => state.partners);
  const agreementForm = useSelector(state => state.agreementForm);

  const { agreement: { agreementType } } = agreementForm;

  /* eslint-disable no-shadow */
  const state = useSelector(state => state);
  /* eslint-enable no-shadow */

  // Local State
  const [agreementNotFound, setAgreementNotFound] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  // const [canCreate, setCanCreate] = useState(false);
  const [unauthorized, setUnauthorized] = useState(false);
  // Notify the selector is ready to render the page after the
  //  agreement is loaded and view permissions are verified.
  const [isReadyToRender, setIsReadyToRender] = useState(false);

  useEffect(() => {
    if (state && state.user) {
      // setCanCreate(hasPermission(state, roleKeys.agreement.create));
      setCanEdit(hasPermission(state, roleKeys.agreement.edit));
    }
  }, [state]);

  useEffect(() => {
    const { loadAgreement } = AgreementActions;
    const { newAgreement } = AgreementFormActions;
    const { params } = match;
    const { loadPartners } = PartnerActions;

    const queryParams = new URLSearchParams(location.search);

    const selectedPartnerId = queryParams.has('partner') ? queryParams.get('partner') : '';
    const statusFilter = { value: 'ACTIVE' };
    if (partners.length === 0) {
      loadPartners(1000, undefined, undefined, { status: [statusFilter] }, 'INTEGRATOR')(dispatch);
    }
    if (currencies.length === 0) {
      getCurrencies()(dispatch);
    }
    if (countries.length === 0) {
      getCountries()(dispatch);
    }
    if (params.id === 'create') {
      newAgreement(selectedPartnerId)(dispatch);
      setIsReadyToRender(true);
    } else {
      loadAgreement(
        params.id, canEdit, notificationContext, currencies,
      )(dispatch).then((agreementData) => {
        if (agreementData === 'unauthorized') {
          setUnauthorized(true);
        } else if (agreementData === 'not found') {
          setAgreementNotFound(true);
        }
        setIsReadyToRender(true);
      }, () => {
        setAgreementNotFound(true);
        setIsReadyToRender(true);
      });
    }
  }, [
    location,
    match,
    canEdit,
    currencies,
  ]);

  const renderFreeAllocation = () => {
    const canView = agreementType === 'THIRD_PARTY_CONTRACTUAL'
      ? hasPermission(state, roleKeys.partner.viewThirdPartyAgreements)
      : true;

    if (!canView) {
      return history.push('/');
    }

    return <FreeAllocationDetailPage match={match} history={history} location={location} />;
  };

  const render3PPPermittedUses = () => {
    const canView = agreementType === 'THIRD_PARTY_RATECARD'
      ? hasPermission(state, roleKeys.partner.viewThirdPartyPublisherAgreements)
      : true;

    if (!canView) {
      return history.push('/');
    }

    return <PermittedUsesDetailPage match={match} history={history} location={location} />;
  };

  const getAgreementPage = useCallback(() => {
    const agreementTypeMatch = agreementTypes[agreementType];

    if (agreementNotFound) {
      return (<Redirect to="/agreements" />);
    }

    if (unauthorized) {
      return <NotAuthorized />;
    }

    if (isReadyToRender) {
      switch (agreementTypeMatch) {
        case agreementTypes.AGENCY:
        case agreementTypes.FIRST_PARTY_PUBLISHER:
          return (<CreateAgreementPage match={match} history={history} location={location} />);
        case agreementTypes.THIRD_PARTY_CONTRACTUAL:
          return renderFreeAllocation();
        case agreementTypes.THIRD_PARTY_RATECARD:
          return render3PPPermittedUses();
        default:
          return (<CreateAgreementPage match={match} history={history} location={location} />);
      }
    }

    return null;
  }, [
    agreementType,
    agreementNotFound,
    unauthorized,
    isReadyToRender,
  ]);

  return getAgreementPage();
};

AgreementPageSelector.propTypes = {
  location: PropTypes.object,
};

AgreementPageSelector.defaultProps = {
  location: {},
};

export default AgreementPageSelector;
