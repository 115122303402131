import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  TableCell, TableRow,
} from '@partner-global-ui/components';

import * as AgreementTypes from '../../../constants/agreementType.constants';
import { AgreementStatusStringMapping } from '../../../constants/agreementState.constants';
import { registrationRegionOptions, agreementTypeOptions, voucherTypeOptions } from '../../../constants/filterOptions.constants';
import { usePermission } from '../../../utils/accessControl/hasPermission';
import roleKeys from '../../../utils/accessControl/roleKeys';

const Agreement = (props) => {
  const { agreement } = props;
  const { t } = useTranslation();
  const userCanLink = agreement.agreementType === 'THIRD_PARTY_CONTRACTUAL'
    ? usePermission(roleKeys.partner.viewThirdPartyAgreements)
    : true;

  const gotoAgreement = (e, agreementId) => {
    if (!userCanLink) return;

    e.preventDefault();
    const { history, location } = props;
    history.push(`/agreement/${agreementId}?return=${location.pathname}`);
  };

  const {
    agreementTypeName,
    agreementId, status,
    numberOfFeeStructuresWithCountries: countryCoverageCount,
    voucherProductType,
  } = agreement;

  const regionsMap = registrationRegionOptions.reduce((acc, curr) => ({
    ...acc,
    [curr.value]: curr.label,
  }), {
    ALL: 'msg_codes_filter_all', // Init object with ALL option
  });

  const agreementTypesMap = agreementTypeOptions.reduce((acc, curr) => ({
    ...acc,
    [curr.value]: curr.label,
  }), {});

  const voucherTypesMap = voucherTypeOptions.reduce((acc, curr) => ({
    ...acc,
    [curr.value]: curr.label,
  }), {});

  const voucherProductTypeCoverage = voucherProductType.length === 0
    ? t('msg_codes_no_product_types_covered_zero')
    : voucherProductType
      .map(productType => t(voucherTypesMap[productType]))
      .join(', ');

  const regionName = agreement.region;

  const displaySIERegion = agreementTypeName === AgreementTypes.THIRD_PARTY_CONTRACTUAL
    ? t('msg_codes_sieRegion_notApplicable')
    : t(regionsMap[regionName]);

  const displayCountryCoverage = {
    [true]: () => countryCoverageCount,
    [agreementTypeName === AgreementTypes.THIRD_PARTY_CONTRACTUAL]: () => t('msg_codes_sieRegion_notApplicable'),
    [countryCoverageCount === 'ALL']: () => t('msg_codes_filter_all'),
  }.true();

  return (
    <>
      <TableRow name="partnerDetailAgreementRow" id={agreementId} className="agreement-row">
        <TableCell id="partnerDetailAgreementId" className="agreementID">
          <span
            onClick={e => gotoAgreement(e, agreementId)}
            role="presentation"
            className={userCanLink ? 'agreementIdLink' : 'disable-link'}
          >
            {agreementId}
          </span>
        </TableCell>
        <TableCell id="partnerDetailAgreementRegion" className="contractingSIERegion">
          {displaySIERegion}
        </TableCell>
        <TableCell id="partnerDetailAgreementType" className="agreementType">
          {t(agreementTypesMap[agreementTypeName])}
        </TableCell>
        <TableCell id="partnerDetailAgreementCountry" className="countryCoverage">
          {displayCountryCoverage}
        </TableCell>
        <TableCell id="partnerDetailAgreementCoverage" className="voucherProductTypeCoverage">
          {voucherProductTypeCoverage}
        </TableCell>
        <TableCell id="partnerDetailAgreementStatus" className={`agreementStatus ${status}`}>
          <span />
          {'  '}
          {t(AgreementStatusStringMapping[status])}
        </TableCell>
      </TableRow>
    </>
  );
};

Agreement.propTypes = {
  agreement: PropTypes.object,
  location: PropTypes.object,
};

Agreement.defaultProps = {
  agreement: {
    partner: { partnerId: 0 },
    agreementVersions: [],
    agreementType: {},
  },
  location: {},
};

export default Agreement;
