import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  TableRow,
  TableCell,
  Switch,
  Truncate,
} from '@partner-global-ui/components';

const JobRow = (props) => {
  const {
    job: {
      description,
      enabled,
      jobCode,
      modifiedBy,
      modifyDate,
      revision,
    },
    setJobStatus,
  } = props;

  return (
    <TableRow
      id={`row-${jobCode}`}
      key={`row-${jobCode}`}
    >
      <TableCell className="status" key={`status-${jobCode}`} id={`status-${jobCode}`}>
        <span key={`status-${jobCode}`} className="switch-wrapper">
          <Switch
            className="switch"
            id={`switch-${jobCode}`}
            checked={enabled}
            onChange={() => setJobStatus(jobCode, !enabled, revision)}
          />
        </span>
      </TableCell>
      <TableCell className="job-code" key={`job-code-${jobCode}`} id={`job-code-${jobCode}`}>
        {jobCode}
      </TableCell>
      <TableCell className="job-description" key={`creation-date-${jobCode}`} id={`creation-date-${jobCode}`}>
        <Truncate
          id="job-description-truncate"
          content={(
            <div
              data-testid="description-tooltip"
              className="description-tooltip"
            >
              {description}
            </div>
          )}
          position="top"
          strategy="fixed"
          height={20}
        >
          {description}
        </Truncate>
      </TableCell>
      <TableCell key={`agreement-status-${jobCode}`} id={`agreement-status-${jobCode}`}>
        {moment(modifyDate).format('YYYY-MM-DD HH:mm')}
      </TableCell>
      <TableCell key={`agreement-version-status-${jobCode}`} id={`agreement-version-status-${jobCode}`}>
        {modifiedBy}
      </TableCell>
    </TableRow>
  );
};

JobRow.propTypes = {
  job: PropTypes.shape({
    description: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired,
    jobCode: PropTypes.string.isRequired,
    modifiedBy: PropTypes.string.isRequired,
    modifyDate: PropTypes.instanceOf(Date).isRequired,
    revision: PropTypes.number.isRequired,
  }).isRequired,
  setJobStatus: PropTypes.func.isRequired,
};

export default JobRow;
