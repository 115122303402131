const Currencies = [
  'AUD',
  'BGN',
  'BRL',
  'CAD',
  'CHF',
  'CNY',
  'CZK',
  'DKK',
  'EUR',
  'GBP',
  'HKD',
  'HRK',
  'HUF',
  'IDR',
  'ILS',
  'INR',
  'JPY',
  'KRW',
  'MYR',
  'NOK',
  'NZD',
  'PLN',
  'RON',
  'RUB',
  'SEK',
  'SGD',
  'THB',
  'TRY',
  'TWD',
  'UAH',
  'USD',
  'ZAR',
];


export default Currencies;

export const currenciesRegions = {
  SIEA: [
    {
      label: 'USD',
      value: 'msg_codes_currency_USDollar',
    },
  ],
  SIEE: [
    {
      label: 'EUR',
      value: 'msg_codes_currency_euro',
    },
    {
      label: 'AUD',
      value: 'msg_codes_currency_australianDollar',
    },
    {
      label: 'GBP',
      value: 'msg_codes_currency_britishPound',
    },
    {
      label: 'NZD',
      value: 'msg_codes_currencyISO_NZD',
    },
  ],
  SIEH: [
    {
      label: 'USD',
      value: 'msg_codes_currency_USDollar',
    },
    {
      label: 'HKD',
      value: 'msg_codes_currencyISO_HKD',
    },
    {
      label: 'KRW',
      value: 'msg_codes_currencyISO_KRW',
    },
    {
      label: 'TWD',
      value: 'msg_codes_currencyISO_TWD',
    },
  ],
  SIEJ: [
    {
      label: 'JPY',
      value: 'msg_codes_currency_yen',
    },
  ],
  '': [],
};

export const currencyCodes = {
  SIEA: [
    {
      label: 'USD',
      value: 'msg_codes_rechargeAmount_usd',
    },
  ],
  SIEE: [
    {
      label: 'EUR',
      value: 'msg_codes_rechargeAmount_eur',
    },
  ],
  SIEJ: [
    {
      label: 'JPY',
      value: 'msg_codes_rechargeAmount_jpy',
    },
  ],
  SIEH: [
    {
      label: 'USD',
      value: 'msg_codes_rechargeAmount_usd',
    },
  ],
  '': [],
};

export const orderFeeCurrencies = [
  {
    label: 'USD',
    value: 'USD',
  },
  {
    label: 'EUR',
    value: 'EUR',
  },
  {
    label: 'JPY',
    value: 'JPY',
  },
];
