import React, { useEffect, useState, useCallback } from 'react';
import {
  DateInput,
  TimeInput,
} from '@partner-global-ui/components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { isEmpty } from 'lodash';
import moment from 'moment';

const DateTime = ({
  name,
  classPrefix,
  dateTime,
  required,
  disabled,
  hasValidationError,
  handleDateTimeChange,
}) => {
  const { t } = useTranslation();
  const [date, setDate] = useState(moment.utc(dateTime).format('YYYY-MM-DD'));
  const [time, setTime] = useState(moment.utc(dateTime).format('kk:mm'));
  const mask = [/\d/, /\d/, /\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/];
  useEffect(() => {
    if (typeof dateTime === 'undefined' || dateTime === '' || dateTime === null) {
      setDate('');
      setTime('');
    } else {
      setDate(moment.utc(dateTime).format('YYYY-MM-DD'));
      setTime(moment.utc(dateTime).format('HH:mm'));
    }
  }, [dateTime]);

  const handleDateChange = useCallback((e) => {
    const { target: { value } } = e;
    if (value === '') {
      setDate('');
      setTime('');
      handleDateTimeChange('');
    } else {
      handleDateTimeChange(moment.utc(`${value} ${time}`, 'YYYY-MM-DD HH:mm').toISOString());
    }
  }, [date, time]);

  const handleTimeChange = useCallback((value) => {
    handleDateTimeChange(moment.utc(`${date} ${value}`, 'YYYY-MM-DD HH:mm').toISOString());
  }, [date, time]);

  return (
    <div
      className={`${classPrefix}DateTime`}
      data-testid={`${name}-datetime`}
    >
      <DateInput
        name={`${name}-time-input`}
        id={`date-picker-${name}`}
        value={date}
        onBlur={e => handleDateChange(e)}
        required={required}
        disabled={disabled}
        invalidInputErrorText={t('msg_codes_invalidInput_tooltip')}
        mask={mask}
        disableBefore={moment().subtract(1, 'day')}
        hasError={hasValidationError}
        displayFormat="yyyy/MM/dd"
      />
      <TimeInput
        className={`${classPrefix}-time-input`}
        id={`time-selector-${name}`}
        onTimeChange={e => handleTimeChange(e)}
        disabled={disabled || isEmpty(date)}
        timeFormat="12hr"
        value={time}
      />
    </div>
  );
};


DateTime.propTypes = {
  name: PropTypes.string,
  classPrefix: PropTypes.string,
  dateTime: PropTypes.string,
  handleDateTimeChange: () => null,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  hasValidationError: PropTypes.bool,
};

DateTime.defaultProps = {
  name: '',
  classPrefix: '',
  dateTime: '',
  handleDateTimeChange: () => null,
  required: false,
  disabled: false,
  hasValidationError: false,
};

export default DateTime;
