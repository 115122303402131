import initialState from './initialState';
import * as types from '../actions/actionTypes';

export default (state = initialState.pgpKey, action) => {
  switch (action.type) {
    case types.RESET_PGP_KEY:
      return {
        ...initialState.pgpKey,
        key: state.activeFingerprint,
        activeFingerprint: state.activeFingerprint,
      };

    case types.UPDATE_PGP_KEY:
      return {
        ...state,
        key: action.value,
        needsValidating: true,
        validated: false,
      };
    case types.COMPLETE_USER_FOUND:
      return {
        ...state,
        key: action.gvsUser.activeFingerprint,
        activeFingerprint: action.gvsUser.activeFingerprint,
        hasKey: action.gvsUser.hasActiveFingerprint,
        needsValidating: false,
      };

    case types.VALIDATE_PGP_KEY:
      return { ...state, validating: true };

    case `${types.VALIDATE_PGP_KEY}_PENDING`:
      return { ...state, validating: true, validationError: false };
    case `${types.VALIDATE_PGP_KEY}_FULFILLED`:
      return {
        ...state,
        validating: false,
        validated: true,
        needsValidating: false,
      };
    case `${types.VALIDATE_PGP_KEY}_REJECTED`:
      return {
        ...state,
        validating: false,
        validated: false,
        validationError: true,
      };

    case `${types.ADD_PGP_KEY}_PENDING`:
    case `${types.EDIT_PGP_KEY}_PENDING`:
      return { ...state, loading: true };
    case `${types.EDIT_PGP_KEY}_FULFILLED`:
    case `${types.ADD_PGP_KEY}_FULFILLED`:
      return {
        ...state,
        loading: false,
        validated: false,
        hasKey: true,
      };
    case `${types.ADD_PGP_KEY}_REJECTED`:
    case `${types.EDIT_PGP_KEY}_REJECTED`:
      return {
        ...state,
        validated: false,
        loading: false,
      };

    case `${types.REMOVE_PGP_KEY}_PENDING`:
      return { ...state, removing: true };
    case `${types.REMOVE_PGP_KEY}_FULFILLED`:
      return {
        ...state,
        removing: false,
        key: '',
        activeFingerprint: undefined,
        hasActiveFingerprint: false,
      };
    case `${types.REMOVE_PGP_KEY}_REJECTED`:
      return { ...state, removing: false };

    case `${types.DOWNLOAD_PGP_KEY}_PENDING`:
      return { ...state, downloading: true, downloadFailed: false };
    case `${types.DOWNLOAD_PGP_KEY}_FULFILLED`:
      return { ...state, downloading: false };
    case `${types.DOWNLOAD_PGP_KEY}_REJECTED`:
      return { ...state, downloading: false, downloadFailed: true };

    default:
      return state;
  }
};
