import React from 'react';
import {
  TextInput,
} from '@partner-global-ui/components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TertiaryRowHeader from './tertiaryRowHeader';
import './tertiaryRow.scss';

const partnerCommentsTooltip = (partnerComments, placeholder) => {
  if (partnerComments && partnerComments.length > 0) {
    return (
      <div className="tooltipDefaultStyles partnerComments">
        { partnerComments }
      </div>
    );
  }
  return placeholder;
};

function TertiaryRow({
  showCTA,
  renderCTA,
  partnerComments,
  handlePartnerCommentsChange,
  downloadHistoryToolTip,
  downloadStatus,
  canEditComments,
  name,
  tableRef,
}) {
  const { t } = useTranslation();
  return (
    <div className="tertiary-row">
      <TertiaryRowHeader showCTA={showCTA} name={name} />
      <div className="tertiary-row-content">
        <div className="comments">
          <TextInput
            charCountMax={500}
            displayCount
            size="lg"
            width={640}
            value={partnerComments || ''}
            onChange={handlePartnerCommentsChange}
            disabled={!canEditComments}
            name={`${name}-partner-comment`}
            id={`${name}-partner-comment`}
            required={false}
            label=" "
            placeholder={t('msg_codes_codeProdCat_typeSomething_dropdown')}
            tooltip={{
              position: 'top',
              anchor: tableRef,
              content: partnerCommentsTooltip(partnerComments, t('msg_codes_codeProdCat_typeSomething_dropdown')),
            }}
          />
        </div>
        { showCTA && (
          <div id="action" className="interactive-row-cell interactive-row-cell-cta cta">
            <div className="download-button-container" hidden={!downloadStatus.showButton}>
              {renderCTA()}
            </div>
            {downloadHistoryToolTip()}
          </div>
        )}
      </div>
    </div>
  );
}

TertiaryRow.propTypes = {
  name: PropTypes.string, // value used to prefix datatestIds in row
  showCTA: PropTypes.bool,
  partnerComments: PropTypes.string,
  handlePartnerCommentsChange: PropTypes.func,
  renderCTA: PropTypes.func,
  downloadHistoryToolTip: PropTypes.func,
  downloadStatus: PropTypes.object,
  canEditComments: PropTypes.bool,
  tableRef: PropTypes.object,
};

TertiaryRow.defaultProps = {
  name: '', // value used to prefix datatestIds in row
  showCTA: false,
  partnerComments: '',
  handlePartnerCommentsChange: () => {},
  renderCTA: () => {},
  downloadHistoryToolTip: () => {},
  downloadStatus: {},
  canEditComments: true,
  tableRef: {},
};

export default TertiaryRow;
