function filtersBuilder(filters) {
  const filterParams = Object.keys(filters).reduce((params, filterParam) => {
    if (filters[filterParam] === undefined) {
      return params;
    }
    if (typeof filters[filterParam] === 'string') {
      if (filters[filterParam] === '') {
        return params;
      }
      return `${params}&${filterParam}=${filters[filterParam]}`;
    }
    return filters[filterParam].reduce((subParams, subFilter) => {
      return `${subParams}&${filterParam}=${subFilter.value}`;
    }, params);
  }, '');
  return filterParams;
}

export default filtersBuilder;
