import initialState from './initialState';
import * as types from '../actions/actionTypes';

export default (state = initialState.voucherTypes, action) => {
  switch (action.type) {
    case types.SET_AGENCY_VOUCHER_TYPES:
      return {
        ...state,
        agencyVoucherTypes: action.voucherTypes,
      };
    case types.SET_FIRST_PARTY_PUBLISHER_VOUCHER_TYPES:
      return {
        ...state,
        firstPartyPublisherVoucherTypes: action.voucherTypes,
      };
    case types.SET_ALL_VOUCHER_TYPES:
      return {
        ...state,
        allVoucherTypes: action.voucherTypes,
      };
    default:
      return state;
  }
};
