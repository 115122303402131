import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function orderFormReducer(state = initialState.orderForm, action) {
  const { type, activeAgreementVersion } = action;
  switch (type) {
    case types.GET_ACTIVE_AGREEMENT_VERSION_SUCCESS:
      return {
        ...state,
        activeAgreementVersion,
      };
    case types.REMOVE_ACTIVE_AGREEMENT_VERSION:
      return {
        ...initialState.orderForm,
      };
    default:
      return {
        ...state,
      };
  }
}
