import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default (state = initialState.cronJobs, action) => {
  const {
    type, venomjobs, vidsJobs, venomJob, vidsJob,
  } = action;

  switch (type) {
    case types.BEGIN_LOAD_VENOM_JOBS:
      return {
        ...state,
        loadingVenomCronJobs: true,
      };
    case types.LOAD_VENOM_JOBS:
      return {
        ...state,
        venomCronJobs: venomjobs,
      };
    case types.FINISH_LOAD_VENOM_JOBS:
      return {
        ...state,
        loadingVenomCronJobs: false,
      };
    case types.UPDATE_VENOM_JOB_STATUS:
      return {
        ...state,
        venomCronJobs: state.venomCronJobs
          .map(cJob => cJob.jobCode === venomJob.jobCode ? venomJob : cJob),
      };
    case types.BEGIN_LOAD_VIDS_JOBS:
      return {
        ...state,
        loadingVIDSCronJobs: true,
      };
    case types.LOAD_VIDS_JOBS:
      return {
        ...state,
        vidsCronJobs: vidsJobs,
      };
    case types.FINISH_LOAD_VIDS_JOBS:
      return {
        ...state,
        loadingVIDSCronJobs: false,
      };
    case types.UPDATE_VIDS_JOB_STATUS:
      return {
        ...state,
        vidsCronJobs: state.vidsCronJobs
          .map(cJob => cJob.jobCode === vidsJob.jobCode ? vidsJob : cJob),
      };
    default:
      return state;
  }
};
