import axios from 'axios';
import * as types from './actionTypes';
import * as UserRoles from '../constants/userRoles.constants';
import getAccountType from '../utils/getAccountType';
import { getExternalUserStatus } from './searchUserActions';

export function loadUserStart() {
  return { type: types.BEGIN_LOAD_USER_DETAIL };
}

export function loadUserSuccess(user) {
  return { type: types.COMPLETE_USER_FOUND, gvsUser: user };
}

export function setChangesMade(value) {
  return { type: types.SET_CHANGES_MADE, value };
}

export function resetUserPage() {
  return { type: types.RESET_USER_PAGE };
}

// Shared User functions
export function saveUser() {
  return async (dispatch) => {
    dispatch(setChangesMade(false));
  };
}

// User Info functions
export function matchRolesToAccountType(user) {
  const { roleCode, ...userWithoutRoles } = user;
  const accountType = getAccountType(user.email);

  const partnerMismatch = (accountType === 'PARTNER'
    && (roleCode !== UserRoles.VouchersPartnerRequester.roleCode && user.partner.name === 'SONY'));

  const sonyMismatch = accountType === 'SONY'
    && (roleCode === UserRoles.VouchersPartnerRequester.roleCode && user.partner.name === 'SONY');

  if (partnerMismatch || sonyMismatch) {
    return userWithoutRoles;
  }

  return user;
}

export async function getUser(dispatch, id) {
  dispatch({ type: types.BEGIN_LOAD_USER_DETAIL });
  const userResponse = await axios.get(`/venom/api/users/${id}`)
    .then(response => response.data);

  const user = matchRolesToAccountType(userResponse);

  if (typeof user.userRoles === 'undefined' && getAccountType(user.email) === 'PARTNER') {
    user.role = 'PARTNER';
  }

  getExternalUserStatus(dispatch, user.email);
  dispatch({ type: types.COMPLETE_USER_FOUND, gvsUser: user });
}
