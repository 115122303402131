import React, { useState } from 'react';
import {
  DateInput,
  TimeInput,
} from '@partner-global-ui/components';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import moment from 'moment';

import './DateTime.scss';
import { useTranslation } from 'react-i18next';

const DateTime = ({
  name,
  label,
  date,
  time,
  required,
  disabled,
  handleDateTimeChange,
  disabledPastDates,
}) => {
  const [hasDateError, setHasDateError] = useState(false);
  const { t } = useTranslation();

  return (
    <div
      className="date-time"
      data-testid="date-time"
    >
      <span
        className="date-input"
        data-testid="date-input"
      >
        <DateInput
          label={label}
          name={name}
          id={`date-picker-${name}`}
          value={date}
          onBlur={(e) => {
            setHasDateError(!moment(e.target.value, 'MM/DD/YYYY', true).isValid());
            handleDateTimeChange(e, name);
          }}
          required={required}
          disabled={disabled}
          disableBefore={disabledPastDates ? moment().subtract(1, 'day') : false}
          hasError={hasDateError}
          errorMessage={hasDateError ? t('msg_codes_invalidInput_tooltip') : ''}
        />
      </span>
      <span
        className="time-input"
        data-testid="time-input"
      >
        <TimeInput
          name={`${name}Time`}
          id={`time-selector-${name}`}
          onTimeChange={(e) => {
            if (moment(e, 'HH:mm', true).isValid()) {
              handleDateTimeChange(e, `${name}Time`);
            }
          }}
          disabled={disabled || isEmpty(date)}
          timeFormat="12hr"
          value={time}
        />
      </span>
    </div>
  );
};


DateTime.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  date: PropTypes.string,
  handleDateTimeChange: () => null,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  time: PropTypes.string,
  disabledPastDates: PropTypes.bool,
};

DateTime.defaultProps = {
  label: '',
  name: '',
  date: '',
  handleDateTimeChange: () => null,
  required: false,
  disabled: false,
  time: '',
  disabledPastDates: false,
};

export default DateTime;
