import React, {
  useState,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Layout,
  Flex,
  Icon,
  MessageBoard,
  useClickOutside,
} from '@partner-global-ui/components';
import moment from 'moment';
import roleKeys from '../../../utils/accessControl/roleKeys';
import { usePermission } from '../../../utils/accessControl/hasPermission';
import { displayStatuses } from '../../../constants/order.constants';

const Steps = ({
  order,
}) => {
  const { t } = useTranslation();
  const commentMessageBoardRef = useRef(null);
  const [showMessageBoard, setShowMessageBoard] = useState(false);

  const {
    orderLines,
    orderStatus,
    amendmentComments,
    rejectedComments,
  } = order;

  const isApproved = orderStatus && orderStatus === 'APPROVED_ORDER';
  const isActive = orderLines && orderLines
    .filter(line => line.activationStatus === 'Activated')
    .length > 0;
  const status = orderStatus && displayStatuses
    .find(displayStatus => displayStatus.value === orderStatus);
  const comment = amendmentComments || rejectedComments;
  const canViewComment = usePermission(roleKeys.order.viewApprovalComments);

  const toggleCommentDisplay = () => setShowMessageBoard(!showMessageBoard);

  useClickOutside(commentMessageBoardRef, () => toggleCommentDisplay());

  return (
    <div data-testid="order-steps" className="order-steps">
      <Container>
        <Layout>
          <Flex>
            <div className="order-progress">
              <ul>
                <li
                  data-testid="submission-step"
                  className={typeof orderStatus !== 'undefined' ? 'active' : ''}
                >
                  <span className="number">1</span>
                  {' '}
                  <span>{t('msg_codes_orderSubmission')}</span>
                  {' '}
                  {status && (
                    <span data-testid="submission-step-status">({t(status.translationStringId)})</span>
                  )}
                  {comment && canViewComment && (
                    <div className="comment-icon">
                      <span
                        id="message-icon-clickbox"
                        data-testid="message-icon-clickbox"
                        onClick={toggleCommentDisplay}
                        // required for aria
                        onKeyDown={toggleCommentDisplay}
                        role="button"
                        tabIndex="-1"
                      >
                        <Icon size={16}>chat</Icon>
                      </span>
                      {showMessageBoard && (
                        <div
                          ref={commentMessageBoardRef}
                          data-testid="comment-message-container"
                          className="comment-message-container"
                        >
                          <MessageBoard
                            id="current-comment"
                            title={t('msg_codes_orderListAdmin_comment_label')}
                            message={comment}
                            onClose={toggleCommentDisplay}
                            name={t('msg_codes_accountType_sony')}
                            date={moment(order.modifyDate).format('MM/DD/YY hh:mm a')}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </li>
                <li
                  data-testid="fulfillment-step"
                  className={isApproved ? 'active' : ''}
                >
                  <span className="number">2</span>
                  {' '}
                  <span>{t('msg_codes_orderDetailsAdmin_fulfillment_label')}</span>
                </li>
                <li
                  data-testid="activation-step"
                  className={isApproved && isActive ? 'active' : ''}
                >
                  <span className="number">3</span>
                  {' '}
                  <span>{t('msg_codes_orderDetailsAdmin_Activation_label')}</span>
                </li>
              </ul>
            </div>
          </Flex>
        </Layout>
      </Container>
    </div>
  );
};

Steps.defaultProps = {
  order: {},
};

Steps.propTypes = {
  order: PropTypes.object,
};

export default Steps;
