import axios from 'axios';
import i18ln from 'i18next';
import { setUser } from '../../actions/ordersActions';
import store from '../../index';
import localeMapping from '../../utils/localeMapping';


export const isLoginNotRequired = () => {
  const NO_LOGIN_REQUIRED_PATHS = ['/order-action'];

  return NO_LOGIN_REQUIRED_PATHS.includes(window.location.pathname);
};


// eslint-disable-next-line import/no-mutable-exports
let globalSession;
// eslint-disable-next-line import/no-mutable-exports
let globalHeader;

export function ssoLogin(idToken) {
  return dispatch => axios.get('/venom/api/ssologin',
    {
      headers: {
        'X-AUTH-TOKEN': idToken,
      },
    })
    .then(response => response.data)
    .then((response) => {
      localStorage.setItem('csrfToken', response.csrfToken);
      localStorage.setItem('idToken', idToken);
      localStorage.setItem('i18nextLng', response.user.localeCode);

      i18ln.changeLanguage(response.user.localeCode);

      const shouldUpdateHeader = globalHeader
        && globalHeader.userLanguage !== localeMapping[i18ln.language];

      if (shouldUpdateHeader && response.user && response.user.localeCode) {
        globalHeader.update({
          userLanguage: localeMapping[response.user.localeCode],
        });
      }
      if (globalHeader) {
        globalHeader.getSessionInfo().then((sessionInfo) => {
          dispatch(setUser({
            ...response.user,
            oktaId: sessionInfo.userId,
          }));
        });
      } else {
        dispatch(setUser(response.user));
      }
      return response;
    });
}

export function logout() {
  localStorage.removeItem('idToken');
  localStorage.removeItem('ordersPageFilters');
  return () => axios.post('/logout')
    .then(response => response.data)
    .catch(() => {
      // TODO: error handling
      // dispatch(vouchersHasErrored(true))
    });
}

const redirectToLogin = (loginUri, redirectUri) => {
  window.location = `${loginUri}?redirectUrl=${encodeURI(redirectUri)}`;
};

const checkAuthentication = () => {
  if (isLoginNotRequired()) {
    return Promise.resolve(true);
  }
  if (globalSession) {
    return globalSession.getSessionInfo()
      .then(info => Promise.resolve(info.loggedIn))
      .catch(error => Promise.reject(error));
  }

  return Promise.reject(new Error('No global session'));
};

const getIdToken = () => {
  if (globalSession) {
    return globalSession.getIdToken()
      .then(token => Promise.resolve(token))
      .catch(error => Promise.reject(error));
  }

  return Promise.reject(new Error('No global session'));
};

const createGlobalSession = (clientId, env, loginUri, redirectUri) => new Promise((resolve) => {
  globalSession = window.globalSessionWidget.init({
    clientId,
    env,
    signoutCallback: () => {
      store.dispatch(logout()).then(() => {
        sessionStorage.removeItem('canceledNeedsPGPSetup');
        sessionStorage.removeItem('selectedPartner');
        redirectToLogin(loginUri, redirectUri);
      });
    },
    signinCallback: () => {
      getIdToken().then((token) => {
        store.dispatch(ssoLogin(token)).then((response) => {
          console.log('ssoLogin via signinCallback');
          if (response.httpStatus === 'UNAUTHORIZED') {
            console.error(response.msg);
          }
        });
      });
    },
    localSessionRefresh: () => {
      const now = new Date();
      const startup = new Date(sessionStorage.getItem('startupTime'));
      const secondsSinceStartup = ((now - startup) / 1000);
      // prevents ssoLogin from being called too soon after startup
      // which can result in 403 errors on subsequent API calls
      if (secondsSinceStartup > 30) {
        getIdToken().then((token) => {
          store.dispatch(ssoLogin(token)).then((response) => {
            if (response.httpStatus === 'UNAUTHORIZED') {
              console.error(response.msg);
              logout();
            }
          });
        });
      }
    },
  });

  return checkAuthentication()
    .then((authenticated) => {
      if (authenticated) {
        // initialize global header
        globalHeader = window.PartnerGlobalUserInterface.init({
          headerSelector: '#globalHeader',
          footerSelector: '#globalFooter',
          globalSession,
          applicationId: 'voucher',
          env,
          showHelpLink: true,
        });

        // everything created correctly
        resolve();
      } else {
        // if the user isn't actually authenticated, have to redirect to the global login page
        redirectToLogin(loginUri, redirectUri);
      }
    });
});

export {
  createGlobalSession, checkAuthentication, globalSession, getIdToken, globalHeader,
};
