import React, {
  useState,
  useEffect,
  useRef,
} from 'react';
import {
  Select,
} from '@partner-global-ui/components';
import {
  useSelector,
} from 'react-redux';
import _, { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import '../Agreements.scss';
import PropTypes from 'prop-types';

const ProductSelectorComponent = ({
  onProductUpdate,
  showProductSelector,
  productsOfTypeOptions,
  selectedProducts,
  setSelectedProducts,
}) => {
  const { t } = useTranslation();
  const [savedProducts, setSavedProducts] = useState([]);
  const initialProducts = useRef(selectedProducts);

  const state = useSelector(globalState => globalState);
  const disabled = (
    state.agreementForm.agreement.agreementType === 'FIRST_PARTY_PUBLISHER'
    || state.agreementForm.agreement.agreementType === ''
    || (state.agreementForm.agreement.agreementType === 'AGENCY'
      && state.agreementForm.initialStatuses.status === 'ACTIVE'
      && state.agreementForm.initialStatuses.versionStatus === 'FINAL'
      && !(state.agreementForm.isNewAgreement || state.agreementForm.isNewVersion)
    )
  );

  const isNotAgency = state.agreementForm.agreement.agreementType !== 'AGENCY';

  const {
    agreementForm: {
      draftAgreementVersion: {
        voucherProductTypeCoverageSet,
      },
    },
  } = state;

  const selectedProductTypes = voucherProductTypeCoverageSet.length && voucherProductTypeCoverageSet
    .map(set => set.voucherProductType);

  const selectedProductTypeOptions = productsOfTypeOptions
    .filter(option => selectedProductTypes
      && selectedProductTypes.length
      && selectedProductTypes.includes(option.value));

  useEffect(() => {
    if (selectedProductTypeOptions && selectedProductTypeOptions.length && !isNotAgency) {
      setSelectedProducts(selectedProductTypeOptions);
      setSavedProducts(selectedProductTypeOptions);
    }
  }, []);

  const hasSelectedProducts = (alreadySaved, selectedAfterSave) => {
    return !_(alreadySaved).xorWith(selectedAfterSave, _.isEqual).isEmpty();
  };

  const applyChanges = () => {
    if (
      selectedProducts.length
      && hasSelectedProducts(savedProducts, selectedProducts)
      // added check for unnecessary re-renders
      && !isEqual(initialProducts.current, selectedProducts)
    ) {
      initialProducts.current = selectedProducts;
      onProductUpdate(productsOfTypeOptions, selectedProducts);
    } else if (selectedProducts.length === 0) {
      initialProducts.current = [];
    }
  };

  const onChange = ({ target: { value } }) => {
    setSelectedProducts(value);
  };

  return (
    <div data-testid="product-selector" className="product-selector">
      {showProductSelector && (
        <Select
          id="product-selector=select"
          options={productsOfTypeOptions}
          value={selectedProducts}
          multiple
          placeholder={t('msg_codes_cta_addProduct')}
          onChange={e => onChange(e)}
          onMenuClose={() => applyChanges()}
          disabled={disabled}
          label={t('msg_codes_cta_addProduct')}
          allPillText={t('msg_codes_filter_all')}
          clearButtonText={t('msg_codes_cta_clear')}
          doneButtonText={t('msg_codes_cta_done')}
          selectAllLabel={t('msg_codes_filter_all')}
        />
      )}
    </div>
  );
};

ProductSelectorComponent.propTypes = {
  onProductUpdate: PropTypes.func,
  showProductSelector: PropTypes.bool,
  productsOfTypeOptions: PropTypes.arrayOf(PropTypes.object),
  selectedProducts: PropTypes.arrayOf(PropTypes.object),
  setSelectedProducts: PropTypes.func,
};

ProductSelectorComponent.defaultProps = {
  onProductUpdate: () => { },
  showProductSelector: false,
  productsOfTypeOptions: [],
  selectedProducts: [],
  setSelectedProducts: () => { },
};

export default ProductSelectorComponent;
