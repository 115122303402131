import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  TableRow,
  TableCell,
  Select,
  TextInput,
  Button,
  Icon,
  iconSizes,
} from '@partner-global-ui/components';
import PropTypes from 'prop-types';
import * as agreementFormActions from '../../../actions/agreementFormActions';
import * as formOptions from '../../../constants/firstPartyForm.constants';
import { firstPartyHasFeeStructure } from '../../../utils/isValidAgreementForm';
import './VoucherCoverageTable.scss';

const CoverageRowComponent = ({
  feeStructure,
  voucherProductType,
  key: index,
}) => {
  const dispatch = useDispatch();
  const state = useSelector(globalState => globalState);
  const {
    agreementForm: {
      isNewVersion,
      isNewAgreement,
      canEditVersion,
      draftAgreementVersion: {
        voucherProductTypeCoverageSet,
      },
    },
  } = state;
  const membershipDurationMax = feeStructure.membershipDurationUnits === 'DAYS' ? 31 : 24;
  const canEdit = isNewVersion || isNewAgreement || canEditVersion;
  const selectedOrderPurpose = formOptions.orderPurposes
    .find(orderPurpose => orderPurpose.value === feeStructure.orderPurpose);
  const selectedmembershipDurationType = formOptions.membershipDurationTypes
    .find(orderPurpose => orderPurpose.value === feeStructure.membershipDurationUnits);

  const rechargeCurrencyOptions = formOptions.rechargeCurrency.map((option) => {
    return {
      label: option,
      value: option,
    };
  });

  const selectedRechargeCurrency = rechargeCurrencyOptions
    .find(rechargeCurrencyOption => rechargeCurrencyOption.value
      === feeStructure.rechargeAmountCurrency);

  const {
    updateFeeStructureForFirstParty,
    removeFeeStructure,
    updateDraftAgreementVersion,
  } = agreementFormActions;

  const handleChange = (event) => {
    const { name, value } = event.target;
    const selectedValue = (name === 'orderPurpose' || name === 'membershipDurationUnits' || name === 'rechargeAmountCurrency')
      ? value.value
      : value;
    const newFeeStructure = { ...feeStructure, [name]: selectedValue };
    dispatch(updateFeeStructureForFirstParty(newFeeStructure, voucherProductType));
  };

  const handleMembershipDurationKeyDown = (event) => {
    const { key } = event;
    const allowedKeys = [
      'Backspace',
      'Delete',
      'ArrowLeft',
      'ArrowRight',
      'Tab',
      'Home',
      'End',
    ];

    // Prevent input change if pressed key is not a number or an allowed key
    if (!key.match(/[0-9]/g) && allowedKeys.indexOf(key) === -1) {
      event.preventDefault();
    }
  };

  const removeRow = () => {
    if (firstPartyHasFeeStructure(voucherProductTypeCoverageSet, true) === 1) {
      dispatch(updateDraftAgreementVersion({ invoiceTiming: null }));
    }
    dispatch(removeFeeStructure(feeStructure, voucherProductType));
  };

  return (
    <TableRow key={`1stPartyCoverageRow-${index}`} id={`1stPartyCoverageRow-${index}`}>
      <TableCell key={`orderPurposeCell-${index}`} id={`orderPurposeCell-${index}`}>
        <Select
          name="orderPurpose"
          id="orderPurpose"
          options={formOptions.orderPurposes}
          value={selectedOrderPurpose}
          onChange={handleChange}
          disabled={!canEdit}
          placeholder="Select"
        />
      </TableCell>
      <TableCell key={`membershipDurationCell-${index}`} id={`membershipDurationCell-${index}`}>
        <TextInput
          value={feeStructure.membershipDuration}
          type="number"
          onChange={handleChange}
          id="membershipDuration"
          name="membershipDuration"
          onKeyDown={handleMembershipDurationKeyDown}
          disabled={!canEdit}
          attr={{
            type: 'number',
            id: 'membershipDuration',
            name: 'membershipDuration',
            min: 1,
            max: membershipDurationMax,
          }}
        />
        <Select
          options={formOptions.membershipDurationTypes}
          value={selectedmembershipDurationType}
          onChange={handleChange}
          name="membershipDurationUnits"
          id="membershipDurationUnits"
          disabled={!canEdit}
        />
      </TableCell>
      <TableCell key={`rechargeAmountnCell-${index}`} id={`rechargeAmountCell-${index}`}>
        <TextInput
          value={feeStructure.rechargeAmount}
          type="number"
          onChange={handleChange}
          id="rechargeAmount"
          name="rechargeAmount"
          disabled={!canEdit}
          attr={{
            type: 'number',
            name: 'rechargeAmount',
            min: 0,
          }}
        />
        <Select
          options={rechargeCurrencyOptions}
          value={selectedRechargeCurrency}
          onChange={handleChange}
          disabled={!canEdit}
          name="rechargeAmountCurrency"
          id="rechargeAmountCurrency"
        />
      </TableCell>
      <TableCell key={`removeCoverageRowButtonCell-${index}`} id={`removeCoverageRowButtonCell-${index}`}>
        <Button
          name="removeCoverageRowButton"
          id="removeCoverageRowButton"
          className="removeRow"
          disabled={!canEdit}
          onClick={removeRow}
        >
          <Icon id="trash-icon" className="remove-icon" size={iconSizes.Large}>ico-trashcan</Icon>
        </Button>
      </TableCell>
    </TableRow>
  );
};

CoverageRowComponent.propTypes = {
  key: PropTypes.string,
  feeStructure: PropTypes.object,
  voucherProductType: PropTypes.string,
  actions: PropTypes.shape({
    updateCountry: PropTypes.func,
    removeAgreementVoucher: PropTypes.func,
  }),
};

CoverageRowComponent.defaultProps = {
  key: '',
  feeStructure: {
    orderPurpose: '',
    membershipDuration: 1,
    membershipDurationUnits: '',
    rechargeAmount: '',
    rechargeAmountCurrency: {},
  },
  voucherProductType: 'PSPLUS',
  actions: {
    removeFeeStructure: () => { },
    updateFeeStructureForFirstParty: () => { },
  },
};

export default CoverageRowComponent;
