import axios from 'axios';
import { isEmpty } from 'lodash';
import * as types from './actionTypes';

export default function getCountries(region, type) {
  const regionType = isEmpty(type) ? 'region' : type;
  const regionParam = region ? `?${regionType}=${region}` : '/';
  return dispatch => axios.get(`venom/api/countries${regionParam}`)
    .then(response => response.data)
    .then((countries) => {
      dispatch({ type: types.SET_COUNTRIES, countries });
    })
    .catch((error) => {
      console.error(error);
    });
}
