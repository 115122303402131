import React, {
  useState, useEffect, useContext, useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import {
  Container,
  NotificationContext,
  Select,
  TextInput,
  Icon,
} from '@partner-global-ui/components';
import { isEmpty } from 'lodash';
import VoucherTypePageHeader from './VoucherTypePageHeader';
import VoucherTypeDetailSection from './VoucherTypeDetailSection';
import './voucherType.scss';
import regions from '../../mocks/regions';
import * as actions from '../../actions/voucherTypeDetailActions';
import getCountries from '../../actions/countryActions';
import SkuInfoSection from '../common/SkuInfoSection/SkuInfoSection';
import VoucherBatchSection from './VoucherBatchSection';
import AssociatedCodeProduct from './AssociatedCodeProduct';
import hasPermission from '../../utils/accessControl/hasPermission';
import roles from '../../utils/accessControl/roleKeys';
import formatDate from '../../utils/formatDate';
import AddSkusPage from '../common/AddSkuPage/AddSkuPage';
import { isVoucherTypeValid } from '../../utils/isValidVoucherTypeForm';
import { isActionAllowed } from '../../utils/voucherBatchStatus';
import getFeature from '../../utils/accessControl/getFeature';
import { autoHide, timeout } from '../../constants/notifications.constant';
import copyToClipboard from '../../helpers/clipboard';

const VoucherTypeDetailPage = ({ ...props }) => {
  const { t } = useTranslation();
  const notificationContext = useContext(NotificationContext);
  const { history, match: { params } } = props;
  const canEditVoucherType = useSelector(state => hasPermission(state, roles.voucherTypes.edit));
  const canEditVoucherBatches = useSelector(state => hasPermission(state,
    roles.voucherBatches.edit));
  const isReadOnlyVoucherType = useSelector(state => hasPermission(
    state, roles.voucherTypes.viewReadyOnlyDetail,
  ));
  const voucherType = useSelector(state => state.voucherType);
  const countriesList = useSelector(state => state.countries);
  const loadingVoucherType = useSelector(state => state.loadingVoucherType);
  const dispatch = useDispatch();
  const [isEdited, setEdited] = useState(false);
  const [codeProductCountries, setCodeProductCountries] = useState();
  const [showAddSkuPage, setShowAddSkuPage] = useState(false);
  const disableVoucherType = (
    voucherType.id !== ''
    && isReadOnlyVoucherType
    && typeof voucherType.voucherCatalogId !== 'undefined'
    && voucherType.voucherCatalogId !== null
  );
  const canSaveAndCancel = canEditVoucherType && !disableVoucherType && (voucherType.npType === 'PRODUCT' || !voucherType.id);
  const isNew = voucherType.id === '';
  const isMoneyVoucherType = voucherType.npType === 'MONEY';
  const isProductVoucherType = voucherType.npType === 'PRODUCT';
  const disableProductType = !canEditVoucherType || voucherType.id !== '' || disableVoucherType;
  const disableRegion = !canEditVoucherType || voucherType.id !== '' || disableVoucherType;
  const disableName = !canEditVoucherType || (isMoneyVoucherType && voucherType.id !== '') || disableVoucherType;
  const disableFaceValue = !canEditVoucherType
    || (isMoneyVoucherType && voucherType.id !== '')
    || disableVoucherType
    || (isMoneyVoucherType && isNew && isEmpty(voucherType.country));
  const disableCountry = !canEditVoucherType || (isMoneyVoucherType && voucherType.id !== '') || disableVoucherType;
  const productTypes = [{
    value: 'PRODUCT',
    label: 'Product',
  }];
  const showVoucherBatchSection = voucherType.id && getFeature(roles.voucherBatches.viewList);
  if (
    !isEmpty(voucherType.id)
    || !getFeature(roles.codeProducts.viewList)
  ) {
    productTypes.push({ value: 'MONEY', label: 'Money' });
  }
  const showCodeProductSection = getFeature(roles.codeProduct.viewDetail);

  const setCountries = async () => {
    await dispatch(getCountries(voucherType.npRegion, 'npRegion'));
  };

  useEffect(() => {
    if (params.id === 'create') {
      dispatch(actions.loadNewVoucherType());
    } else {
      dispatch(actions.loadVoucherType(params.id, notificationContext));
      setCountries();
    }
  }, []);

  useEffect(() => {
    if (typeof voucherType.countries !== 'undefined' && voucherType.countries !== null) {
      setCodeProductCountries(
        countriesList
          .filter(country => voucherType.countries.includes(country.code))
          .map(cnty => cnty.name),
      );
    }
  }, [countriesList, voucherType.countries]);
  const handleShowAddSkuPage = () => {
    setShowAddSkuPage(true);
  };

  const hideAddSkuPage = () => {
    setShowAddSkuPage(false);
  };

  const handleShowAddBatchPage = () => {
    history.push({
      pathname: '/voucherbatch/create',
      voucherTypeId: voucherType.id,
    });
  };

  const getHeaderDescriptionText = () => {
    return t('msg_codes_newOrder_createdBy', {
      name: `${voucherType.createdByName ? voucherType.createdByName : 'System'}`,
      date: `${voucherType.creationDate && formatDate(voucherType.creationDate)}`,
      interpolation: {
        escapeValue: false,
      },
    });
  };

  const handleChange = (event) => {
    const { target: { name, value } } = event;

    dispatch(actions.changeVoucherTypeProp(
      name,
      name === 'name' || name === 'faceValue' ? value : value.value,
    ));
    setEdited(true);
  };

  const handleVoucherTypeSave = async () => {
    const {
      npVoucherTypeId,
      voucherTypeName,
    } = await dispatch(actions.checkExistingForConfiguration());

    if (!isEmpty(npVoucherTypeId)) {
      notificationContext.dispatch({
        type: 'add',
        payload: {
          status: 'error',
          autoHide,
          timeout,
          message: <Trans
            i18nKey="A voucher type ID already exists with this combination of region, country, and face value: <0>{{voucherTypeName}}</0>"
            values={{ npVoucherTypeId }}
            components={[
              <Link
                to={`/vouchertype/${npVoucherTypeId}`}
                className="voucher-typeId-name-link"
                rel="noopener noreferrer"
                target="_blank"
              >
                {{ voucherTypeName }}
              </Link>,
            ]}
          />,
          testId: 'error',
        },
      });
      return;
    }
    const navigate = id => params.id === 'create' ? history.push(`/vouchertype/${id}`) : {};
    actions.saveVoucherType({ voucherType, navigate, notificationContext })(dispatch)
      .then(() => {
        setEdited(false);
      });
  };

  const onDeleteSku = (skuId) => {
    const { skus } = voucherType;
    const newSkus = skus.filter(sku => sku.skuId !== skuId);
    dispatch(actions.changeVoucherTypeProp('skus', newSkus));
    setEdited(true);
  };

  const addSkusToVoucherType = (selectedSkus) => {
    if (selectedSkus) {
      const newSkus = [...selectedSkus];
      dispatch(actions.changeVoucherTypeProp('skus', newSkus));
      setEdited(true);
    }
  };

  const renderPageHeader = useCallback(() => {
    const disableButton = !isEdited || !isVoucherTypeValid(voucherType);
    return (
      <Container id="voucher-type-header" className="voucher-type-header">
        <VoucherTypePageHeader
          disableButton={disableButton}
          canSaveAndCancel={canSaveAndCancel}
          voucherType={voucherType}
          handleVoucherTypeSave={handleVoucherTypeSave}
        />
      </Container>
    );
  }, [isEdited, voucherType, canSaveAndCancel]);

  const renderInputWithMaxCharLimit = (
    label, required, value, name, disabled, maxLimit, isNumber = false, displayCount,
  ) => {
    return (
      <div className="voucher-form-group">
        <TextInput
          required={required}
          label={t(label)}
          name={name}
          className="voucher-details-form-field"
          id={`voucher-type-detail-input-${name}`}
          value={value}
          disabled={disabled}
          onChange={handleChange}
          onKeyDown={e => isNumber && !new RegExp('^\\d+$').test(e.key) && e.preventDefault()}
          placeholder={t('msg_codes_type_something')}
          width={547}
          displayCount={displayCount}
          charCountMax={maxLimit}
          charCountText={t('Characters')}
        />
      </div>
    );
  };

  const currentCountry = countriesList.find(country => country.code === voucherType.country);
  const isDecimalFaceValueSupported = currentCountry
  && currentCountry.currency && currentCountry.currency.divisorExponent === 2;

  const isAllowedValue = (wholeNumber, singleNumber) => {
    const maxValue = isDecimalFaceValueSupported ? 999999.99 : 99999999;
    let value = `${wholeNumber}${singleNumber}`;

    const inverseValue = value.split('').reverse().join('');
    const checkTwoDecimalPlaces = inverseValue.charAt(3) === '.';

    value = parseFloat(value).toFixed(2);
    return value <= maxValue && !checkTwoDecimalPlaces;
  };

  const handleBlur = useCallback((e) => {
    if (e.currentTarget.value === '0') e.currentTarget.value = '1';
  });

  const handleKeypress = useCallback((e) => {
    const characterCode = e.key;
    if (characterCode === 'Backspace') return;
    const characterNumber = Number(characterCode);
    const singleInput = e.key === '.' ? e.key : characterNumber;
    if (
      (
        (characterNumber >= 0 && characterNumber <= 9)
        || (e.key === '.' && isDecimalFaceValueSupported)
      )
      && isAllowedValue(e.currentTarget.value, singleInput)
    ) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      }
      if (characterNumber === 0) {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  });

  const renderFaceValue = (
    label, required, value, name, disabled, maxLimit, displayCount,
  ) => {
    return (
      <div className="voucher-form-group">
        <TextInput
          required={required}
          label={t(label)}
          name={name}
          className="voucher-details-form-field"
          id={`voucher-type-detail-input-${name}`}
          value={value}
          disabled={disabled}
          charCountMax={maxLimit}
          onChange={handleChange}
          onKeyDown={handleKeypress}
          onBlur={handleBlur}
          placeholder={`${isDecimalFaceValueSupported ? '0.00' : '0'}`}
          width={547}
          displayCount={displayCount}
        />
      </div>
    );
  };

  const renderSelect = (label, required, value, name, options, disabled, selectRef) => {
    return (
      <div className="voucher-form-group">
        <Select
          id={`voucher-type-detail-select-${name}`}
          className={['voucher-type-details-form-field-label', required ? 'required' : ''].join(' ')}
          options={options}
          required={required}
          label={t(label)}
          value={value}
          name={name}
          onChange={handleChange}
          disabled={disabled}
          placeholder={t('msg_codes_select')}
          anchor={selectRef}
        />
      </div>
    );
  };

  const getVoucherTypeHeader = () => {
    if (isNew) {
      return t('msg_codes_voucher_type_details');
    }
    return (
      <span
        data-testid="copy-icon-container"
        className="copy-icon"
        onClick={copyToClipboard(voucherType.id, notificationContext, 'msg_codes_typeid_copied_confirmation')}
        onKeyPress={copyToClipboard(voucherType.id, notificationContext, 'msg_codes_typeid_copied_confirmation')}
        role="button"
        tabIndex={0}
      >
        {t('msg_codes_voucher_type_id')}: {voucherType.id}
        <Icon id="copy-icon">ico-copy</Icon>
      </span>
    );
  };

  const renderVoucherTypeDetailSection = () => {
    const countries = countriesList
      .map((country) => {
        return {
          label: t(country.nameStringId),
          value: t(country.codeStringId),
        };
      });

    const country = countries.find(c => c.value === voucherType.country);

    return (
      <VoucherTypeDetailSection
        voucherType={voucherType}
        isMoneyVoucherType={isMoneyVoucherType}
        productTypes={productTypes}
        disableProductType={disableProductType}
        regions={regions}
        disableRegion={disableRegion}
        disableName={disableName}
        disableCountry={disableCountry}
        disableFaceValue={disableFaceValue}
        getVoucherTypeHeader={getVoucherTypeHeader}
        getHeaderDescriptionText={getHeaderDescriptionText}
        renderSelect={renderSelect}
        renderInputWithMaxCharLimit={renderInputWithMaxCharLimit}
        renderFaceValue={renderFaceValue}
        loadingVoucherType={loadingVoucherType}
        countries={countries}
        country={country}
      />
    );
  };

  const isBatchActionAllowed = (action, batch) => {
    const isReadOnlyBatch = typeof batch.partnerId === 'number';

    if (!canEditVoucherBatches) {
      return false;
    }

    const allowed = isActionAllowed(
      action,
      batch,
      isReadOnlyBatch,
      batch.npType,
      batch.skusCount,
      canEditVoucherBatches,
    );
    return allowed;
  };

  return (
    <Container id="voucher-type-detail-page" className="voucher-type-detail-page page-container">
      {!showAddSkuPage && (
        <>
          {renderPageHeader()}
          {renderVoucherTypeDetailSection()}
          {isProductVoucherType && (
          <SkuInfoSection
            canAddSku={canEditVoucherType}
            disableAddSkuButton={voucherType.npRegion === ''}
            skus={voucherType.skus}
            onDeleteSku={onDeleteSku}
            onNewSkuClick={handleShowAddSkuPage}
            region={voucherType.npRegion}
            disableVoucherType={disableVoucherType}
            loadingVoucherType={loadingVoucherType}
          />
          )}
          {
            showCodeProductSection && (
              <AssociatedCodeProduct
                voucherCatalogId={voucherType.voucherCatalogId}
                codeProductName={voucherType.codeProductName}
                codeProductType={voucherType.codeProductType}
                codeProductCountries={codeProductCountries}
                codeProductDateCreated={voucherType.codeProductDateCreated}
                status={voucherType.codeProductStatus}
                loadingVoucherType={loadingVoucherType}
              />
            )
          }
          {showVoucherBatchSection && (
            <VoucherBatchSection
              canAddBatch={canEditVoucherType}
              disableAddBatchButton={voucherType.npRegion === ''}
              onNewBatchClick={handleShowAddBatchPage}
              region={voucherType.npRegion}
              voucherTypeId={voucherType.id ? voucherType.id : 'create'}
              isActionAllowed={isBatchActionAllowed}
              isEditable={canEditVoucherBatches}
            />
          )}
        </>
      )}
      {showAddSkuPage && (
        <AddSkusPage
          region={voucherType.npRegion}
          onClose={hideAddSkuPage}
          onAddSkus={addSkusToVoucherType}
          addedSkus={voucherType.skus}
        />
      )}
    </Container>
  );
};

export default VoucherTypeDetailPage;
