import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function requestingAmendmentReducer(
  state = initialState.requestingAmendment, action,
) {
  if (action.type === types.BEGIN_REQUEST_AMENDMENT) {
    return true;
  } if (action.type === types.REQUEST_AMENDMENT_SUCCESS) {
    return false;
  }

  return state;
}
