import moment from 'moment';
import axios from 'axios';
import generateUuid from 'uuid/v1';
import i18n from 'i18next';
import _ from 'lodash';
import * as types from './actionTypes';
import * as Messages from '../constants/agreementMessages.constants';
import pickAgreement from '../helpers/pickAgreement';
import initialState from '../reducers/initialState';
import stripIds from '../helpers/stripIds';
import displayErrorMessage from '../utils/errorMessageHandler';
import { autoHide, timeout } from '../constants/notifications.constant';

export function resetAgreement() {
  return {
    type: types.RESET_AGREEMENT,
    agreementHasBeenModified: false,
    versionHasBeenModified: false,
  };
}

export function resetVoucherProductTypeCoverageSet() {
  return {
    type: types.RESET_VOUCHER_PRODUCT_TYPE_COVERAGE_SET,
    versionHasBeenModified: true,
  };
}

export function resetRemovedCountries() {
  return {
    type: types.NO_ORDERS_AFFECTED,
  };
}

export function updateAgreementProp(name, value) {
  return (dispatch) => {
    const action = {
      type: types.UPDATE_AGREEMENT_PROPERTY,
      name,
      value,
      agreementHasBeenModified: true,
    };
    dispatch(action);
    if (name === 'agreementType' || name === 'region') {
      dispatch(resetVoucherProductTypeCoverageSet());
    }
  };
}

export function newAgreementSuccess(agreement = initialState.agreementForm.agreement) {
  return {
    type: types.NEW_AGREEMENT_SUCCESS,
    latestFinalEffectiveDate: moment.now(),
    agreement,
    versionHasBeenModified: false,
    agreementHasBeenModified: false,
  };
}

export function loadAgreementSuccess(agreement) {
  return { type: types.LOAD_AGREEMENT_SUCCESS, agreement };
}

export function loadAgreementPartnerSuccess(agreement) {
  return { type: types.LOAD_AGREEMENT_PARTNER_SUCCESS, agreement };
}

export function saveAgreementSuccess(agreement) {
  return { type: types.SAVE_AGREEMENT_SUCCESS, agreement };
}

export function setIsEditing(isEditing, isNewVersion) {
  return { type: types.SET_CAN_EDIT_VERSION, isEditing, isNewVersion };
}

export function resetHasBeenModified() {
  return { type: types.RESET_HAS_BEEN_MODIFIED, latestFinalEffectiveDate: moment.now() };
}

export function versionMismatch() {
  return { type: types.AGREEMENT_VERSION_MISMATCH };
}

export function updateDraftAgreementVersion(value, agreementStatusEnabled = false) {
  return {
    type: types.UPDATE_AGREEMENT_VERSION_DRAFT,
    payload: value,
    agreementHasBeenModified: true,
    versionHasBeenModified: true,
    agreementStatusEnabled,
  };
}

export function saveNewAgreementVersion(agreement) {
  return { type: types.SAVE_NEW_AGREEMENT_VERSION, agreement };
}

export function productTypeHasBeenAdded(value) {
  return { type: types.PRODUCT_TYPE_HAS_BEEN_ADDED, productTypeHasBeenAdded: value };
}

function formatAgreement(agreementForm) {
  return {
    ...agreementForm.agreement,
    agreementVersions: [agreementForm.draftAgreementVersion],
  };
}

function uniqueVersions(versionA, versionB) {
  return versionA.agreementVersionId === versionB.agreementVersionId;
}

export function generateAgreementPut({ draftAgreementVersion, isNewVersion, agreement }) {
  if (isNewVersion) {
    const newDraftAgreementVersion = {
      ...draftAgreementVersion,
      voucherProductTypeCoverageSet: stripIds(
        draftAgreementVersion.voucherProductTypeCoverageSet,
      ),
    };
    return {
      ...agreement,
      agreementVersions: [
        ...agreement.agreementVersions,
        newDraftAgreementVersion,
      ],
    };
  }
  return {
    ...agreement,
    agreementVersions:
      _.uniqWith(
        [
          draftAgreementVersion,
          ...agreement.agreementVersions,
        ],
        uniqueVersions,
      ),
  };
}

function handleAgreementResponse() {
  return Promise.resolve();
}

async function handleAgreementError(error) {
  return Promise.reject(error.response.data);
}
// http methods returns rejected promises if response was not ok

function updateAgreementRequest(agreementId, agreement, eTag) {
  const url = `venom/api/agreements/${agreementId}`;
  return axios.put(
    url,
    pickAgreement(agreement),
    {
      headers: {
        'if-match': eTag,
      },
    },
  ).then(handleAgreementResponse, handleAgreementError);
}

function createAgreementRequest(agreementForm) {
  const url = 'venom/api/agreements';
  return axios.post(
    url,
    pickAgreement(formatAgreement(agreementForm)),
  ).then(handleAgreementResponse, handleAgreementError);
}

export function newAgreement(partnerId) {
  const agreement = {
    ...initialState.agreementForm.agreement,
    partnerId,
  };
  return (dispatch => dispatch(newAgreementSuccess(agreement)));
}

export function addEmptyVoucherProductType(name) {
  return {
    type: types.ADD_EMPTY_VOUCHER_PRODUCT_TYPE,
    payload: {
      voucherProductType: name,
      feeStructure: [],
    },
    versionHasBeenModified: true,
  };
}

export function removeVoucherProductType(name) {
  return {
    type: types.REMOVE_VOUCHER_PRODUCT_TYPE,
    name,
    versionHasBeenModified: true,
  };
}

export function addAgencyFeeStructure(voucherProductType, feeStructure) {
  return {
    type: types.ADD_FEE_STRUCTURE,
    voucherProductType,
    feeStructure: {
      feeStructureType: 'agency',
      country: feeStructure.code,
      settlementCurrency: feeStructure.settlementCurrency,
      commissionRate: feeStructure.commissionRate,
      feeStructureId: feeStructure.feeStructureId || undefined,
      uuid: !feeStructure.feeStructureId ? generateUuid() : undefined,
      currencyStatus: feeStructure.currencyStatus,
    },
    versionHasBeenModified: true,
  };
}

export function removeAgencyFeeStructure(voucherProductType, feeStructure) {
  return {
    type: types.REMOVE_FEE_STRUCTURE,
    voucherProductType,
    feeStructure,
    versionHasBeenModified: true,
  };
}

export function updateAgencyFeeStructure(voucherProductType, feeStructure) {
  return {
    type: types.UPDATE_FEE_STRUCTURE,
    voucherProductType,
    feeStructure,
    versionHasBeenModified: true,
  };
}

export function removeAllAgencyFeeStructures(voucherProductType) {
  return {
    type: types.REMOVE_ALL_FEE_STRUCTURES,
    voucherProductType,
    versionHasBeenModified: true,
  };
}

export function addNewFeeStructureForFirstParty(voucherProductType = 'PSPLUS') {
  return {
    type: types.ADD_FEE_STRUCTURE,
    feeStructure: {
      orderPurpose: '',
      membershipDuration: '',
      membershipDurationUnits: '',
      rechargeAmount: '',
      rechargeAmountCurrency: '',
      uuid: generateUuid(),
      feeStructureType: 'firstParty',
    },
    voucherProductType,
    versionHasBeenModified: true,
  };
}

export function updateFeeStructureForFirstParty(feeStructure, voucherProductType) {
  return {
    type: types.UPDATE_FEE_STRUCTURE,
    feeStructure,
    voucherProductType,
    versionHasBeenModified: true,
  };
}

export function removeFeeStructure(feeStructure, voucherProductType) {
  return {
    type: types.REMOVE_FEE_STRUCTURE,
    voucherProductType,
    feeStructure,
    versionHasBeenModified: true,
  };
}

function updateAgreement({ notificationContext, agreementForm, navigate }) {
  const { agreement: { agreementId }, eTag } = agreementForm;
  const agreementToSend = generateAgreementPut(agreementForm);
  return (async (dispatch) => {
    try {
      dispatch({ type: types.BEGIN_AGREEMENT_SAVE });
      await updateAgreementRequest(agreementId, agreementToSend, eTag);
      dispatch(resetHasBeenModified());
      navigate();
      notificationContext.dispatch({
        type: 'add',
        payload: {
          status: 'success',
          autoHide,
          timeout,
          message: i18n.t(Messages.baseStrings.SUCCESS),
          testId: 'success',
        },
      });
    } catch (error) {
      dispatch({ type: types.AGREEMENT_SAVE_ERROR });
      displayErrorMessage(notificationContext, error, Messages);
    }
  });
}

function createAgreement({ notificationContext, agreementForm, navigate }) {
  return ((dispatch) => {
    dispatch({ type: types.BEGIN_AGREEMENT_SAVE });
    createAgreementRequest(agreementForm).then(() => {
      notificationContext.dispatch({
        type: 'add',
        payload: {
          status: 'success',
          autoHide,
          timeout,
          message: i18n.t(Messages.baseStrings.SUCCESS),
          testId: 'success',
        },
      });
      dispatch(resetHasBeenModified());
      navigate();
    }, (error) => {
      dispatch({ type: types.AGREEMENT_SAVE_ERROR });
      displayErrorMessage(notificationContext, error, Messages);
    });
  });
}

function createNewVersion() {
  return ((dispatch) => {
    dispatch(setIsEditing(true, true));
  });
}

const actions = {
  createNewVersion,
  updateAgreement,
  createAgreement,
};

export default actions;
