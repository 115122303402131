import moment from 'moment';
import _ from 'lodash';
import * as CallbackGateways from '../constants/callbackGateways.constants';
import * as AgreementState from '../constants/agreementState.constants';
import * as AgreementTypes from '../constants/agreementType.constants';

function isAgreementValid(agreement) {
  const {
    region,
    partnerId,
    partner,
    agreementType,
    status,
  } = agreement;
  const resolvedPartnerId = partner && partner.partnerId
    ? partner.partnerId
    : partnerId !== ''
      ? Number(partnerId)
      : partnerId;
  return typeof region === 'string'
    && typeof resolvedPartnerId === 'number'
    && typeof status === 'string'
    && typeof agreementType === 'string';
}

function isCallbackGatewayMandatoryValid(gatewayCode, voucherRedemptionCallbackMandatory) {
  return CallbackGateways[gatewayCode] === CallbackGateways.NO_GATEWAY
    || typeof voucherRedemptionCallbackMandatory === 'boolean';
}

export function isInitialAgreement(
  isNewAgreement,
  initialStatuses,
  versionStatus,
) {
  return isNewAgreement
    || (initialStatuses.status === AgreementState.DRAFT
      && initialStatuses.versionStatus === AgreementState.DRAFT)
    || versionStatus === AgreementState.DRAFT;
}


export function isAgreementVersionEffectiveDateValid(
  versionStatus,
  agreementVersionEffectiveDate,
  latestFinalEffectiveDate,
  initialStatuses,
) {
  const validEffectiveDate = typeof agreementVersionEffectiveDate !== 'undefined' && moment(agreementVersionEffectiveDate).isValid();
  const validDatestFinalEffectiveDate = moment(latestFinalEffectiveDate).isValid();
  if (versionStatus === AgreementState.FINAL) {
    const isTodayOrAfter = moment(agreementVersionEffectiveDate).isSameOrAfter(moment().utc().startOf('day'));
    if (isInitialAgreement(false, initialStatuses, versionStatus) && validEffectiveDate) {
      return isTodayOrAfter;
    }
    return validDatestFinalEffectiveDate && validEffectiveDate
    && moment(agreementVersionEffectiveDate).utc().isAfter(moment(latestFinalEffectiveDate), 'day')
    && isTodayOrAfter;
  }
  return true;
}

export function firstPartyHasFeeStructure(voucherTypeCoverageSet = [], returnLength = false) {
  if (returnLength) {
    return voucherTypeCoverageSet.length > 0
      ? voucherTypeCoverageSet[0].feeStructure.length
      : 0;
  }

  return voucherTypeCoverageSet.length > 0
    && voucherTypeCoverageSet[0].feeStructure.length > 0;
}

export function firstPartyInvoiceTimingRequired(voucherTypeCoverageSet) {
  return _.some(voucherTypeCoverageSet, (coverage) => {
    return _.some(coverage.feeStructure, (feeStructure) => {
      const { uuid, feeStructureType, ...rest } = feeStructure;
      return _.some(rest, field => typeof field === 'string' && field !== '');
    });
  });
}

export function isValidMemberShipDuration(membershipDuration, membershipDurationUnits) {
  const durationMax = membershipDurationUnits === 'DAYS' ? 31 : 24;
  return membershipDuration > 0 && membershipDuration <= durationMax;
}

export function isValidFirstPartyFeeStructure(feeStructure) {
  const {
    feeStructureType,
    orderPurpose,
    membershipDuration,
    membershipDurationUnits,
    rechargeAmountCurrency,
    rechargeAmount,
  } = feeStructure;

  return feeStructureType === 'firstParty'
    && orderPurpose !== ''
    && membershipDurationUnits !== ''
    && rechargeAmount > 0
    && rechargeAmountCurrency !== ''
    && isValidMemberShipDuration(membershipDuration, membershipDurationUnits);
}

export function isValidFirstPartyCoverage(voucherTypeCoverageSet) {
  if (typeof voucherTypeCoverageSet === 'undefined') return false;
  if (voucherTypeCoverageSet.length === 0) return true;
  return voucherTypeCoverageSet.every(coverage => (
    coverage.feeStructure.every(feeStructure => isValidFirstPartyFeeStructure(feeStructure))));
}

function isValidAgencyFeeStructure(feeStructure) {
  const {
    feeStructureType,
    commissionRate,
    country,
    settlementCurrency,
    currencyStatus,
  } = feeStructure;

  return feeStructureType === 'agency'
  && (commissionRate > 0 && commissionRate <= 99999)
  && country !== ''
  && (settlementCurrency !== '' && settlementCurrency !== 'select')
  && currencyStatus === 'ACTIVE';
}

function isValidAgencyCoverage(voucherTypeCoverageSet) {
  if (typeof voucherTypeCoverageSet === 'undefined') return false;
  if (voucherTypeCoverageSet.length === 0) return false;
  return voucherTypeCoverageSet.some(coverage => (
    coverage.feeStructure.length >= 1
    && coverage.feeStructure.every(feeStructure => isValidAgencyFeeStructure(feeStructure))));
}

export function isVoucherProductTypeCoverageSetValid(draftAgreementVersion, agreementType) {
  const { voucherProductTypeCoverageSet } = draftAgreementVersion;
  if (AgreementTypes[agreementType] === AgreementTypes.FIRST_PARTY_PUBLISHER) {
    return isValidFirstPartyCoverage(voucherProductTypeCoverageSet);
  }
  if (AgreementTypes[agreementType] === AgreementTypes.AGENCY) {
    return isValidAgencyCoverage(voucherProductTypeCoverageSet);
  }
  return false;
}

function hasDuplicateEffectiveDates(agreementForm) {
  const {
    agreement: { agreementVersions },
    draftAgreementVersion,
    canEditVersion,
    agreementVersionId,
  } = agreementForm;
  const draftEffectiveDate = agreementForm.draftAgreementVersion.agreementVersionEffectiveDate;
  return agreementVersions.some((version) => {
    const { agreementVersionEffectiveDate } = version;
    return moment(agreementVersionEffectiveDate).isSame(draftEffectiveDate)
      && (draftAgreementVersion.versionStatus === AgreementState.FINAL)
      && canEditVersion
      && agreementVersionId !== draftAgreementVersion.agreementVersionId;
  });
}

function hasAnExistingDraftAgreement(agreementForm) {
  const {
    agreement: { agreementVersions },
    agreementVersionId: existingId,
  } = agreementForm;
  return agreementVersions.some((version) => {
    const { versionStatus, agreementVersionId } = version;
    return versionStatus === AgreementState.DRAFT && agreementVersionId !== existingId;
  });
}

function isDraftAgreementVersionValid(
  draftAgreementVersion, agreementType, latestFinalEffectiveDate, initialStatuses, ignoreDateCheck,
  agreementStatus,
) {
  const {
    voucherRedemptionCallbackGateway,
    voucherRedemptionCallbackMandatory,
    versionStatus,
    agreementVersionEffectiveDate,
    invoiceTiming,
    voucherProductTypeCoverageSet,
  } = draftAgreementVersion;

  if (
    (
      AgreementTypes[agreementType] === AgreementTypes.THIRD_PARTY_CONTRACTUAL
      || AgreementTypes[agreementType] === AgreementTypes.THIRD_PARTY_RATECARD
    )
    && initialStatuses.status !== agreementStatus
  ) {
    return true;
  }

  return (
    (
      !firstPartyHasFeeStructure(voucherProductTypeCoverageSet)
      || (firstPartyInvoiceTimingRequired(voucherProductTypeCoverageSet) && invoiceTiming)
    )
    && versionStatus !== ''
    && voucherRedemptionCallbackGateway !== ''
    && isCallbackGatewayMandatoryValid(
      voucherRedemptionCallbackGateway, voucherRedemptionCallbackMandatory,
    )
    && (ignoreDateCheck || isAgreementVersionEffectiveDateValid(
      versionStatus, agreementVersionEffectiveDate, latestFinalEffectiveDate, initialStatuses,
    ))
    && isVoucherProductTypeCoverageSetValid(draftAgreementVersion, agreementType)
  );
}

export function canCreateNewVersion(agreementForm) {
  const {
    canEditVersion, draftAgreementVersion, agreement,
  } = agreementForm;
  return !canEditVersion
    && AgreementTypes[agreementForm.agreement.agreementType]
      !== AgreementTypes.THIRD_PARTY_CONTRACTUAL
    && draftAgreementVersion.versionStatus === AgreementState.FINAL
    && agreement.status === AgreementState.ACTIVE;
}

export function canDeleteFinalVersion(agreementForm) {
  const {
    canEditVersion, draftAgreementVersion, initialStatuses, agreement,
  } = agreementForm;
  return isAgreementValid(agreement)
    && !canEditVersion
    && initialStatuses.versionStatus === AgreementState.FINAL
    && draftAgreementVersion.versionStatus === AgreementState.DELETED;
}

export function canSaveNewAgreement(agreementForm) {
  const {
    agreement, draftAgreementVersion, agreement: { agreementType, status },
    latestFinalEffectiveDate, initialStatuses, isNewVersion, isNewAgreement,
  } = agreementForm;
  const ignoreDateOnOldVersion = initialStatuses.versionStatus === AgreementState.FINAL
    && !isNewVersion
    && !isNewAgreement;

  return isAgreementValid(agreement)
    && isDraftAgreementVersionValid(
      draftAgreementVersion, agreementType, latestFinalEffectiveDate, initialStatuses,
      ignoreDateOnOldVersion, status,
    );
}

export function canSaveCreatingNewVersion(agreementForm) {
  return canSaveNewAgreement(agreementForm)
    && !hasDuplicateEffectiveDates(agreementForm)
    && !hasAnExistingDraftAgreement(agreementForm);
}

export function canUpdateFirstDraftAgreement(agreementForm) {
  return canSaveNewAgreement(agreementForm);
}

export function canUpdateExistingAgreement(agreementForm) {
  return canSaveNewAgreement(agreementForm)
    && !hasDuplicateEffectiveDates(agreementForm)
    && !hasAnExistingDraftAgreement(agreementForm);
}
