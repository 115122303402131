import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default (state = initialState.loadingSelectVoucherBatches, action) => {
  switch (action.type) {
    case types.BEGIN_LOAD_SELECT_VOUCHER_BATCHES:
      return true;
    case types.FINISH_LOAD_SELECT_VOUCHER_BATCHES:
      return false;
    case types.FINISH_LOAD_SELECT_VOUCHER_TYPE_BATCHES:
      return false;
    default:
      return state;
  }
};
