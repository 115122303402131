import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function savingOrderReducer(state = initialState.savingOrder, action) {
  if (action.type === types.BEGIN_SAVE_ORDER) {
    return true;
  } if (action.type === types.SAVE_ORDER_SUCCESS || action.type === types.UPDATE_ORDER_SUCCESS
    || action.type === types.SUBMIT_ORDER_SUCCESS || action.type === types.VERSION_MISMATCH
    || action.type === types.SAVE_ORDER_FAILURE) {
    return false;
  }

  return state;
}
