import * as types from '../actions/actionTypes';

export default function initalOrderReducer(state = {}, action) {
  switch (action.type) {
    case types.SAVE_INITIAL_ORDER:
      return {
        ...action.order,
      };
    case types.RESET_INITIAL_ORDER:
      return {};
    default:
      return state;
  }
}
