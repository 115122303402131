import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import {
  Collapsible,
  Tooltip,
} from '@partner-global-ui/components';
import { useTranslation } from 'react-i18next';

import './VoucherCoverageTable.scss';
import '../../common/Tooltip.scss';
import * as agreementFormActions from '../../../actions/agreementFormActions';
import Products from '../../../constants/products.constants';
import countriesGensenStrings from '../../../constants/countries.constants';
import FirstPartyVoucherForm from './VoucherForm';
import hasPermission from '../../../utils/accessControl/hasPermission';
import roleKeys from '../../../utils/accessControl/roleKeys';

const FirstPartyVoucherCoverageTableComponent = ({
  canEdit,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const state = useSelector(globalState => globalState);
  const selectedAgreementType = state.agreementForm.agreement.agreementType;
  const hidePSPlusDetails = hasPermission(state, roleKeys.agreement.showAgreementVersionDetails)
    && selectedAgreementType === 'FIRST_PARTY_PUBLISHER';

  const countries = state.countries.filter(country => country.region.code
    === state.agreementForm.agreement.region);
  const {
    agreementForm: {
      draftAgreementVersion: { voucherProductTypeCoverageSet },
      isNewVersion,
      isNewAgreement,
      canEditVersion,
    },
  } = useSelector(globalState => globalState);

  const {
    addEmptyVoucherProductType,
    addNewFeeStructureForFirstParty,
  } = agreementFormActions;

  const addRow = () => {
    if (isNewVersion || canEditVersion || isNewAgreement) {
      if (voucherProductTypeCoverageSet.length === 0) {
        dispatch(addEmptyVoucherProductType(Products[1].code));
      }
      dispatch(addNewFeeStructureForFirstParty());
    }
  };

  const renderTooltip = (countriesList) => {
    const countriesWithStrings = countriesList.map((cty) => {
      return {
        ...cty,
        name: t(countriesGensenStrings[cty.code].name),
      };
    });
    const content = countriesWithStrings.reduce((text, country, index) => `${text} ${country.name}${countriesWithStrings.length !== (index + 1) ? ', ' : ' '}`, '');
    return content;
  };

  const renderPanel = () => {
    return (
      <Collapsible
        id="psplus-collapsible"
        heading="PS Plus"
        rightContent={
          canEdit
          && [{
            actionCallback: !hidePSPlusDetails ? () => {} : e => (addRow(e)),
            actionText: i18n.t('msg_codes_recharge'),
          }]
        }
        hideBorder
        defaultIsOpen
        secondary
      >
        <>
          {hidePSPlusDetails && (
            <FirstPartyVoucherForm key="PSPLUS" voucherProductType="PSPLUS" />
          )}
        </>
      </Collapsible>
    );
  };


  return (
    <div className="firstParty-voucherCoverageTable-container">
      <div name="note" className="note">
        Note: When PS Plus is added here it covers {' '}
        <Tooltip
          id="countries-tooltip"
          placement="top"
          content={renderTooltip(countries)}
        >
          <span name="countryLink" className="country-link">all countries</span>
        </Tooltip>
        {' '} in this region.
      </div>
      {renderPanel()}
    </div>
  );
};

FirstPartyVoucherCoverageTableComponent.propTypes = {
  canEdit: PropTypes.bool,
};

FirstPartyVoucherCoverageTableComponent.defaultProps = {
  canEdit: false,
};

export default FirstPartyVoucherCoverageTableComponent;
