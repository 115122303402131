import * as types from './actionTypes';

export function changePage(location, user, app) {
  let category = '';
  const { pathname } = location;
  if (pathname.indexOf('orders') !== -1 || pathname.indexOf('add-lines') !== -1) {
    category = 'grid';
  } else if (pathname.indexOf('order') !== -1) {
    category = 'detail';
  } else if (pathname.indexOf('login') !== -1) {
    category = 'login';
  }
  let pageName = pathname.replace('/', '').replace('/', ':');

  // homepage (orders)
  if (pathname === '/') {
    pageName = 'orders';
    category = 'grid';
  }
  return {
    type: types.CHANGE_PAGE,
    pageName,
    category,
    user,
    app,
  };
}

export function fireEvent(
  eventName = 'click', eventAction = 'action', assetName = 'assetName',
  label = 'label', moduleName = 'module',
  position = 'empty', elementType = 'element',
) {
  return {
    type: types.FIRE_EVENT,
    eventName,
    eventAction,
    assetName,
    label,
    moduleName,
    position,
    elementType,
  };
}

export default [
  changePage,
];
