import React, {
  useState,
  useEffect,
  useContext,
} from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Collapsible,
  TextInput,
  Icon,
  Button,
  NotificationContext,
  AlertContext,
  ALERT_ACTIONS,
  Alert,
  STATUS,
} from '@partner-global-ui/components';
import { isEmpty } from 'lodash';

import { validateGPID, mergePartner } from '../../../actions/partnerMergeActions';
import { autoHide, timeout } from '../../../constants/notifications.constant';


const PartnerMerge = () => {
  // Setup
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const notificationContext = useContext(NotificationContext);
  const alertContext = useContext(AlertContext);
  const [sourceGPID, setSourceGPID] = useState({
    value: '',
    hasError: false,
    errorMessage: '',
  });
  const [targetGPID, setTargetGPID] = useState({
    value: '',
    hasError: false,
    errorMessage: '',
  });
  const [
    validationState,
    setValidationState,
  ] = useState({
    validation: 'NOT_VALIDATED',
    secondary: false,
    success: false,
    disabled: true,
  });

  const [
    mergeButtonState,
    setMergeButtonState,
  ] = useState({
    primary: false,
    disabled: true,
  });


  useEffect(() => {
    if (!isEmpty(sourceGPID.value) && !isEmpty(targetGPID.value)) {
      setValidationState(prevState => ({
        ...prevState,
        validation: 'NOT_VALIDATED',
        secondary: true,
        success: false,
        disabled: false,
      }));
    } else if (!isEmpty(sourceGPID.value) || !isEmpty(targetGPID.value)) {
      setValidationState(prevState => ({
        ...prevState,
        secondary: false,
        success: false,
        disabled: true,
      }));
    }

    if (isEmpty(sourceGPID.value)) {
      setSourceGPID(prevState => ({
        ...prevState,
        hasError: false,
        errorMessage: '',
      }));
    }

    if (isEmpty(targetGPID.value)) {
      setTargetGPID(prevState => ({
        ...prevState,
        hasError: false,
        errorMessage: '',
      }));
    }
  }, [sourceGPID.value, targetGPID.value]);

  useEffect(() => {
    if (validationState.validation === 'VALIDATED') {
      setMergeButtonState({
        primary: true,
        disabled: false,
      });
    } else {
      setMergeButtonState({
        primary: false,
        disabled: true,
      });
    }
  }, [validationState.validation]);

  const resetFields = () => {
    setSourceGPID('');
    setTargetGPID('');
    setValidationState({
      validation: 'NOT_VALIDATED',
      secondary: false,
      success: false,
      disabled: true,
    });
    setMergeButtonState({
      primary: false,
      disabled: true,
    });
  };

  const alertProps = {
    id: 'partner-merge-alert',
    title: t('msg_codes_partner_merge_successful'),
    status: STATUS.INFO,
    primaryButtonLabel: t('msgid_codes_orderList_OK'),
    secondaryButtonLabel: t('msg_codes_copy_gpid'),
    primaryAction: () => {
      notificationContext.dispatch({
        type: 'add',
        payload: {
          status: 'sucess',
          autoHide,
          timeout,
          message: t('msg_codes_partners_merged_toast'),
        },
      });
      resetFields();
    },
  };

  const renderAlert = (context, newGPID) => {
    const updatedAlertProps = {
      ...alertProps,
      content: t('msg_codes_new_gpid', { newGPID }),
      secondaryAction: () => {
        navigator.clipboard.writeText(newGPID);
      },
    };

    return (
      context.dispatch({
        type: ALERT_ACTIONS.SHOW,
        payload: {
          component: (
            <Alert {...updatedAlertProps} />
          ),
        },
      })
    );
  };

  const handleInputChange = (e) => {
    e.persist();
    if (e.target.name === 'first-input') {
      setSourceGPID(prevState => ({
        ...prevState,
        value: e.target.value,
      }));
    }

    if (e.target.name === 'second-input') {
      setTargetGPID(prevState => ({
        ...prevState,
        value: e.target.value,
      }));
    }
  };

  const validate = async () => {
    const {
      validated,
      sourceErrorMessage,
      targetErrorMessage,
    } = await dispatch(
      validateGPID(sourceGPID.value, targetGPID.value),
    );

    if (validated) {
      setValidationState(prevState => ({
        ...prevState,
        validation: 'VALIDATED',
        secondary: false,
        success: true,
        disabled: false,
      }));
      notificationContext.dispatch({
        type: 'add',
        payload: {
          status: 'sucess',
          autoHide,
          timeout,
          message: 'Validation successful. Select "Merge" to merge partners.',
        },
      });
    } else if (!validated) {
      setValidationState(prevState => ({
        ...prevState,
        secondary: false,
        success: false,
        disabled: false,
      }));
      setSourceGPID(prevState => ({
        ...prevState,
        hasError: true,
        errorMessage: sourceErrorMessage,
      }));
      setTargetGPID(prevState => ({
        ...prevState,
        hasError: true,
        errorMessage: targetErrorMessage,
      }));
    }
  };

  const merge = async () => {
    const { newGPID } = await dispatch(
      mergePartner(sourceGPID.value, targetGPID.value),
    );

    renderAlert(alertContext, newGPID);
  };

  return (
    <div
      data-testid="partner-merge"
      className="partner-merge"
    >
      <Collapsible
        id="partner-merge-collapsible"
        heading={t('msg_codes_partner_merge')}
        secondary
        defaultIsOpen
      >
        <>
          <div
            data-testid="partner-merge-content"
            className="partner-merge-content"
          >
            <span
              data-testid="partner-merge-label"
              className="partner-merge-label"
            >
              <>
                To merge two partners, enter their Global Partner IDs (GPIDs) with the source
                first (partner you&apos;re merging from) and the target second (partner you&apos;re
                merging into). Next, select &ldquo;Validate&rdquo; to check them for errors.
                If both partners are validated, then you can merge them.
              </>
            </span>
            <div
              data-testid="inputs"
              className="inputs"
            >
              <span
                data-testid="first-input"
                className="first-input"
              >
                <TextInput
                  id="first-input-text"
                  name="first-input"
                  value={sourceGPID.value}
                  onChange={e => handleInputChange(e)}
                  width={250}
                  required={false}
                  label={t('msg_codes_partner1_gpid')}
                  placeholder={t('msg_codes_enter_gpid')}
                  hasError={sourceGPID.hasError}
                  errorMessage={sourceGPID.errorMessage}
                />
              </span>
              <span
                data-testid="arrow-forward"
                className="arrow-forward"
              >
                <Icon id="arrow-forward">arrow_forward</Icon>
              </span>
              <span
                data-testid="second-input"
                className="second-input"
              >
                <TextInput
                  id="second-input-text"
                  name="second-input"
                  value={targetGPID.value}
                  onChange={e => handleInputChange(e)}
                  width={250}
                  required={false}
                  label={t('msg_codes_partner2_gpid')}
                  placeholder={t('msg_codes_enter_gpid')}
                  hasError={targetGPID.hasError}
                  errorMessage={targetGPID.errorMessage}
                />
              </span>
              <span
                data-testid="validate-button"
                className="validate-button"
              >
                <Button
                  secondary={validationState.secondary}
                  primary={validationState.primary}
                  success={validationState.success}
                  disabled={validationState.disabled}
                  id="validate-button"
                  onClick={() => validate()}
                >
                  {t('msg_codes_users_validate')}
                </Button>
              </span>
            </div>
          </div>
          <footer
            data-testid="partner-merge-footer"
            className="partner-merge-footer"
          >
            <Button
              secondary={mergeButtonState.secondary}
              primary={mergeButtonState.primary}
              success={mergeButtonState.success}
              disabled={mergeButtonState.disabled}
              id="merge-button"
              onClick={() => merge()}
            >
              {t('msg_codes_merge_cta')}
            </Button>
          </footer>
        </>
      </Collapsible>
    </div>
  );
};

export default PartnerMerge;
