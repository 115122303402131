import React, { useState, useEffect } from 'react';
import {
  LocalNavigation,
} from '@partner-global-ui/components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as UserRoles from '../../constants/userRoles.constants';
import history from '../../history';
import getFeature from '../../utils/accessControl/getFeature';
import roleKeys from '../../utils/accessControl/roleKeys';

const thirdPartyCatalogFlag = getFeature(roleKeys.thirdParty.viewCatalog);
const manageCodesFlag = getFeature(roleKeys.codeProducts.viewList);
const manageVoucherTypesFlag = getFeature(roleKeys.voucherTypes.viewList);
const manageVoucherBatchesFlag = getFeature(roleKeys.voucherBatches.viewList);

const LocalNav = ({
  canViewProductCatalog, canViewOrders, ordersNavOptionDisabled,
  user, canViewUsers, canViewPartners, canViewAgreements,
  canViewVoucherTypes, canViewCodeProducts, canViewVoucherBatches,
  canAccessPgpKey, canViewTos, canAccessMaintainanceDashboard,
  user: {
    userId,
  },
}) => {
  const { t } = useTranslation();
  const defaultMenuItem = {
    menuInformation: {
      label: '',
      value: '',
    },
  };
  const { VouchersPartnerRequester } = UserRoles;
  const userUrl = `/user/${userId}`;

  const showCodes = thirdPartyCatalogFlag && canViewProductCatalog;

  let ordersLinks = {
    menuInformation: {
      label: t('msg_codes_orders'),
      value: '/orders',
      testId: 'orders',
    },
  };
  if (canViewOrders && showCodes) {
    ordersLinks = {
      menuInformation: {
        label: t('msg_codes_orders'),
        value: 'orders',
        testId: 'orders',
      },
      subMenuItems: [
        {
          menuInformation: {
            label: t('msg_codes_orders'),
            value: '/orders',
            testId: 'orders',
          },
        },
        {
          menuInformation: {
            label: t('msg_codes_codes'),
            value: '/thirdPartyCatalog',
            testId: 'codes',
          },
        },
      ],
    };
  } else if (showCodes) {
    ordersLinks = {
      menuInformation: {
        label: t('msg_codes_codes'),
        value: '/thirdPartyCatalog',
        testId: 'codes',
      },
    };
  }

  let adminLinks = null;
  if (user.domainPartner && user.roleCode === VouchersPartnerRequester.roleCode) {
    adminLinks = {
      menuInformation: {
        label: t('msg_codes_admin'),
        value: 'admin',
        testId: 'admin',
      },
      subMenuItems: [
        {
          menuInformation: {
            label: t('msg_codes_partnerInfo'),
            value: `/partner/${user.partner.partnerId}`,
            testId: 'partner-info',
          },
        },
        {
          menuInformation: {
            label: t('msg_codes_PGPKey'),
            value: `${userUrl}?focus=pgpkey`,
            testId: 'pgp-key',
          },
        },
      ],
    };
  } else {
    adminLinks = {
      menuInformation: {
        label: t('msg_codes_admin'),
        value: 'admin',
        testId: 'admin',
      },
      subMenuItems: [
        canAccessPgpKey ? {
          menuInformation: {
            label: t('msg_codes_PGPKey'),
            value: `${userUrl}?focus=pgpkey`,
            testId: 'pgp-key',
          },
        } : defaultMenuItem,
        canViewUsers ? {
          menuInformation: {
            label: t('msg_codes_users'),
            value: '/users',
            testId: 'users',
          },
        } : defaultMenuItem,
        canViewPartners ? {
          menuInformation: {
            label: t('msg_codes_partners'),
            value: '/partners',
            testId: 'partners',
          },
        } : defaultMenuItem,
        canViewAgreements ? {
          menuInformation: {
            label: t('msg_codes_agreements'),
            value: '/agreements',
            testId: 'agreements',
          },
        } : defaultMenuItem,
        (canViewVoucherTypes && manageVoucherTypesFlag) ? {
          menuInformation: {
            label: t('msg_codesVoucherTypeCatalog'),
            value: '/vouchertypes',
            testId: 'vouchertypes',
          },
        } : defaultMenuItem,
        (canViewCodeProducts && manageCodesFlag) ? {
          menuInformation: {
            label: t('msg_codes_codeProductCatalog'),
            value: '/codeproducts',
            testId: 'codeproducts',
          },
        } : defaultMenuItem,
        (canViewVoucherBatches && manageVoucherBatchesFlag) ? {
          menuInformation: {
            label: t('msg_codes_voucherBatchCatalog'),
            value: '/voucherbatches',
            testId: 'voucherbatches',
          },
        } : defaultMenuItem,
        (canAccessMaintainanceDashboard) ? {
          menuInformation: {
            label: t('msg_codes_jobs_maintenance'),
            value: '/maintainance',
            testId: 'maintainance',
          },
        } : defaultMenuItem,
      ],
    };
  }

  const tosLink = canViewTos
    ? {
      menuInformation: {
        label: t('msg_codes_termsOfService'),
        value: '/tos',
        testId: 'terms-of-service',
      },
    }
    : defaultMenuItem;

  const defaultLinks = (!canViewOrders && !showCodes)
    ? [adminLinks, tosLink]
    : [ordersLinks, adminLinks, tosLink];

  const [navItems, setNavItems] = useState(defaultLinks);
  const [selected, setSelected] = useState(navItems[0]);

  useEffect(() => {
    let navItemsFiltered = navItems.map((navItem) => {
      const { subMenuItems } = navItem;
      if (typeof subMenuItems === 'undefined') {
        return {
          ...navItem,
        };
      }
      return {
        ...navItem,
        subMenuItems: subMenuItems.filter(item => item.menuInformation.label !== ''),
      };
    });
    navItemsFiltered = navItemsFiltered.filter(item => item.menuInformation.label !== '');
    setNavItems(navItemsFiltered);
  }, [canViewProductCatalog,
    canViewOrders,
    ordersNavOptionDisabled,
    user, canViewUsers, canViewPartners, canViewAgreements,
    canViewVoucherTypes, canViewCodeProducts,
    canViewVoucherBatches, canAccessPgpKey, canViewTos]);

  const onSelect = (event, selectedItem) => {
    const { menuInformation: { value } } = selectedItem;
    setSelected(selectedItem);
    history.push(value);
  };

  return (
    <LocalNavigation
      menuItems={navItems}
      onSelect={onSelect}
      onToggle={() => {}}
      selectedMenuItem={selected}
      id="venom-nav"
    />
  );
};

LocalNav.propTypes = {
  canViewProductCatalog: PropTypes.bool,
  canViewOrders: PropTypes.bool,
  ordersNavOptionDisabled: PropTypes.bool,
  user: PropTypes.object,
  canViewAgreements: PropTypes.bool,
  canViewPartners: PropTypes.bool,
  canViewUsers: PropTypes.bool,
  canViewVoucherTypes: PropTypes.bool,
  canViewCodeProducts: PropTypes.bool,
  canViewVoucherBatches: PropTypes.bool,
  canViewTos: PropTypes.bool,
  canAccessPgpKey: PropTypes.bool,
  canAccessMaintainanceDashboard: PropTypes.bool,
};

LocalNav.defaultProps = {
  canViewProductCatalog: false,
  canViewOrders: false,
  ordersNavOptionDisabled: false,
  user: {},
  canViewAgreements: false,
  canViewPartners: false,
  canViewUsers: false,
  canViewVoucherTypes: false,
  canViewCodeProducts: false,
  canViewVoucherBatches: false,
  canViewTos: false,
  canAccessPgpKey: false,
  canAccessMaintainanceDashboard: false,
};

export default LocalNav;
