import React from 'react';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import {
  TableCell,
  TableRow,
  Icon,
  Tooltip,
  TextInput,
  Loader,
  Select,
  Truncate,
} from '@partner-global-ui/components';
import _ from 'lodash';

import { useTranslation } from 'react-i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import * as trackingActions from '../../../actions/trackingActions';
import orderLineErrors from '../../../constants/orderLineErrors.constants';
import autoCancelReasons from '../../../constants/autocanceledReasons.constants';
import OrderLineTooltip from './OrderLineToolTip';
import OrderStatuses from '../../../constants/order.constants';
import * as UserRoles from '../../../constants/userRoles.constants';
import * as lineActions from '../../../actions/lineActions';

const OrderContentRow = ({
  line,
  order,
  selectLine,
  changeProp,
  handleBlur,
  handleKeypress,
  permissions,
  tableRef,
  checkboxState,
  isDisabled,
  isApproved,
  canDownload,
  canCancel,
  isSonyUser,
  isCheckboxEnabled,
  showDeleteIcon,
  hideAllLineKabobs,
  ...rest
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);

  const displayCount = true;
  const voucher = typeof line._embedded === 'undefined'
    ? line.voucherCatalog
    : line._embedded.voucherCatalog;
  const {
    orderStatus,
  } = order;
  const {
    exportErrorMessage,
    quantity,
    npVoucherBatchId,
    fulfillmentStatus,
    deliveryStatus,
    activationStatus,
    startDate,
    endDate,
    orderLineId,
    sftpVoucherDeliveryStatus,
    userVoucherDeliverStatus,
    autoCanceled,
    deletedOrderLineId,
    autoCancelReason,
    isNew,
    voucherStatus,
    branded,
    cardManufacturePrice,
    tax,
    total,
    totalTax,
    batchLabelCode,
    itemReference,
    customId,
    deliveredVoucherCodeFilename,
  } = line;
  const {
    country,
    currency,
    typeName,
    voucherName,
  } = voucher;
  const brandedOptions = [
    { label: t('msg_codes_orderDetails_plus'), value: 'PLUS' },
    { label: t('msg_codes_orderDetails_dual'), value: 'DUAL' },
    { label: t('msg_codes_orderDetails_store'), value: 'STORE' },
  ];

  const canRetry = order.orderLines
    && order.orderLines.some(orderLine => orderLine.retryable);
  const canActivate = order.orderLines
    && order.orderLines.some(orderLine => orderLine.activatable);

  let showErrorIcon = false;
  const isTypeMoney = typeName === 'Money';
  const tooltipMessage = autoCancelReasons[autoCancelReason];
  const fulfillmentErrorCode = exportErrorMessage
    ? exportErrorMessage.substring(0, 4)
    : '';
  const isFulfillmentError = fulfillmentStatus === 'Fulfillment Error'
    && orderLineErrors.some(errors => errors.code === fulfillmentErrorCode);
  const fulfillmentErrorReason = orderLineErrors.find((errors) => {
    return errors.code === fulfillmentErrorCode;
  });
  const isLineAutoCanceled = line.autoCanceled || line.canceled;
  const isCancelled = order
    && typeof orderStatus !== 'undefined'
    && (orderStatus === 'CANCELLED_ORDER' || voucherStatus === 'CANCELLED' || autoCanceled);
  const isRemoved = typeof deletedOrderLineId === 'number';
  const isLineDisabled = autoCanceled && activationStatus === 'Inactive';

  const classNames = [
    'order-line-cell',
    ...(isLineAutoCanceled ? ['autocanceled'] : []),
    'order-line',
    ...(isDisabled ? ['hasError'] : []),
    ...(isNew ? ['isNew'] : []),
    ...(isRemoved ? ['isRemoved'] : []),
    ...(isCancelled ? ['isCancelled'] : []),
    ...(isLineAutoCanceled ? ['isLineAutoCanceled'] : []),
  ].join(' ');

  const isRequester = user.roleCode === UserRoles.VouchersPartnerRequester.roleCode;
  const isPendingApproval = order
      && typeof orderStatus !== 'undefined'
      && orderStatus === 'SUBMITTED_PENDING_APPROVAL';
  const isRejected = order
      && typeof orderStatus !== 'undefined'
      && orderStatus === 'REJECTED';
  const isNotEditable = (
    !((isRequester) && (isPendingApproval || isApproved)) && !(isRejected || isCancelled)
  ) || isDisabled;
  const canEditBranded = permissions.canChangeBranded
    && isNotEditable && !isRemoved && !autoCanceled;
  const editableQuantity = permissions.canEditQuantity
    && isNotEditable && !isApproved && !isRemoved && !isDisabled;
  const editableCost = permissions.canEditCost
    && isNotEditable && !isApproved && !isRemoved && !isDisabled;
  const editableTax = permissions.canEditTax
    && isNotEditable && !isApproved && !isRemoved && !isDisabled;
  const editableItem = permissions.canEditItemReference
    && isNotEditable && !isRemoved && !autoCanceled;

  const formatDate = date => moment(date).utc().format('MM/DD/YYYY');

  if (fulfillmentErrorReason) {
    showErrorIcon = true;
  }

  function getTranslationId(status) {
    switch (status) {
      case 'Fulfilling':
        return t('msg_codes_orderListAdmin_fulfilling_label');
      case 'Fulfilled':
        return t('msg_codes_fulfillment_fulfilled');
      case 'Fulfillment Error':
        return t('msg_codes_fulfillment_fulfillmentError');
      case 'Delivered':
        return t('msg_codes_delivery_delivered');
      case 'Activated':
        return t('msg_codes_activation_activated');
      case 'Deactivated':
        return t('msg_codes_codes_status_deactivated');
      case 'Activation Error':
        return t('msg_codes_activationError');
      case 'File generation is in progress, please come back later to download.':
        return (
          <Truncate
            id="truncate-file-generation"
            tooltipContent={t('msg_codes_fileGen')}
            height={20}
          >
            {t('msg_codes_fileGen')}
          </Truncate>
        );
      case 'Pending Delivery':
        return t('msg_codes_pendingDelivery');
      case 'Inactive':
        return t('msg_codes_partnerStatus_inactive');
      default:
        return status;
    }
  }

  function renderStatus(status, isLoading) {
    return (
      <span className="render-status">
        {getTranslationId(status) || '-'}
        {isLoading && <Loader size="sm" />}
      </span>
    );
  }

  function renderKabob() {
    const canDownloadLine = line.downloadable && canDownload;
    const canRetryLine = line.retryable && canRetry;
    const canCancelLine = line.cancelable && canCancel;
    const canActivateLine = line.activatable && canActivate;

    return (
      <OrderLineTooltip
        line={line}
        order={order}
        isRemoved={isRemoved || autoCanceled}
        isApproved={isApproved}
        canCancel={canCancelLine}
        canDownload={canDownloadLine}
        canRetry={canRetryLine}
        canActivate={canActivateLine}
        name="kebob"
      />
    );
  }

  const renderFulfillmentTooltip = () => {
    const errorCode = 'FILE_GENERATION_ERROR';

    const fulfillmentErrorMessage = fulfillmentErrorReason
      ? t(fulfillmentErrorReason.error)
      : '';

    if (sftpVoucherDeliveryStatus === errorCode || userVoucherDeliverStatus === errorCode) {
      return (
        <Tooltip
          content={t('msg_codes_codes_banner_fileGenerationError')}
          id={`fulfillment-overlay-${orderLineId}`}
          anchor={tableRef}
        >
          <Icon
            name={`fulfilmentError-icon-${orderLineId}`}
            size={16}
            className="fulfillment-generation-error"
            data-testid="file-generation-error-icon"
          >
            ico-warning
          </Icon>
        </Tooltip>
      );
    }

    return isFulfillmentError && (
      <Tooltip
        content={fulfillmentErrorMessage}
        id={`overlay-${orderLineId}`}
        anchor={tableRef}
      >
        <Icon
          name={`fulfilmentError-icon-${orderLineId}`}
          size={16}
          className="fulfillment-error"
          id="fulfillment-warning-sign"
        >
          ico-warning
        </Icon>
      </Tooltip>
    );
  };

  const renderError = () => {
    return (
      <span className="info-icon">
        <Tooltip
          content={tooltipMessage}
          id={`overlay-${orderLineId}`}
          anchor={tableRef}
        >
          <Icon
            name={`cancel-icon-${orderLineId}`}
            data-testid="error-info-sign"
            size={16}
          >
            info
          </Icon>
        </Tooltip>
      </span>
    );
  };

  const renderDeliveredTooltip = () => {
    return (
      <Tooltip
        placement="top"
        content={deliveredVoucherCodeFilename}
        id="overlayFilename"
        anchor={tableRef}
      >
        <Icon name={`delivered-icon-${orderLineId}`} size={16}>insert_drive_file</Icon>
      </Tooltip>
    );
  };

  const renderRemovedError = () => {
    const canceledReasons = ['PARTNER_UNAVAILABLE', 'PRODUCT_INACTIVE', 'COUNTRY_UNAVAILABLE', 'AGREEMENT_TYPE_UNAVAILABLE'];
    const isProductChanged = canceledReasons.includes(autoCancelReason);

    const isRemovedTooltipMessage = t('msg_codes_productTypeRemoved_tooltip');

    if (isProductChanged) {
      return (
        <span className="info-icon">
          <Tooltip
            content={isProductChanged ? tooltipMessage : isRemovedTooltipMessage}
            id={`overlay-${orderLineId}`}
            anchor={tableRef}
          >
            <Icon name={`remove-icon-${orderLineId}`} size={16}>info</Icon>
          </Tooltip>
        </span>
      );
    }

    return isRemoved ? (
      <span className="info-icon">
        <Tooltip
          content={isProductChanged ? tooltipMessage : isRemovedTooltipMessage}
          id={`overlay-${orderLineId}`}
          anchor={tableRef}
        >
          <Icon name={`remove-icon-${orderLineId}`} size={16}>info</Icon>
        </Tooltip>
      </span>
    ) : null;
  };

  const handleQuantityBlur = (e) => {
    if (e.currentTarget.value === '0') e.currentTarget.value = '1';
  };

  const handleQuantityKeypress = (e) => {
    const characterCode = e.key;
    if (characterCode === 'Backspace') return;

    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      }
      if (characterNumber === 0) {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  };

  const preventLetterKeypress = (e) => {
    const characterCode = e.key;
    const numericRegex = /^[0-9.]+$/;
    if (characterCode !== 'Backspace' && !numericRegex.test(characterCode)) {
      e.preventDefault();
    }
  };

  const getTotal = () => {
    const manufacturePrice = cardManufacturePrice || 0;
    const draftTotal = Number(manufacturePrice) * Number(quantity).toFixed(4);

    // if draft calculate value, otherwise use server value
    return orderStatus === OrderStatuses.PENDING_DRAFT_AMENDMENT
      || orderStatus === OrderStatuses.DRAFT_ORDER
      ? draftTotal.toFixed(4)
      : total;
  };

  const getTax = () => {
    const manufacturePrice = cardManufacturePrice || 0;
    const manufactureTotal = Number(manufacturePrice) * Number(quantity);
    const manufactureTax = tax || 0;
    const draftTotalTax = (manufactureTotal * manufactureTax * 0.01).toFixed(4);

    // if draft calculate value, otherwise use server value
    return orderStatus === OrderStatuses.PENDING_DRAFT_AMENDMENT
      || orderStatus === OrderStatuses.DRAFT_ORDER
      ? draftTotalTax
      : totalTax;
  };

  const deleteOrderLine = (e, parentOrder, lineToDelete) => {
    e.preventDefault();

    if (!(autoCanceled || typeof deletedOrderLineId === 'number')) {
      lineActions.removeItemFromOrder(parentOrder, lineToDelete, dispatch);

      const lineVoucherName = typeof lineToDelete.voucherCatalog === 'undefined'
        ? lineToDelete._embedded.voucherCatalog.voucherName
        : lineToDelete.voucherCatalog.voucherName;

      trackingActions.fireEvent(
        'item-removed',
        'click',
        'empty',
        lineVoucherName,
        'item-grid',
        '1',
        'button',
      );
    }
  };

  return (
    <TableRow
      id={orderLineId}
      key={orderLineId}
      className={classNames}
      hasCheckbox={isApproved && (canCancel || canDownload)}
      onCheckboxClick={() => selectLine(orderLineId)}
      checkboxState={checkboxState}
      checkboxProps={{ disabled: !isCheckboxEnabled || isLineDisabled }}
      {...rest}
    >
      <TableCell
        id="product"
        className={`${classNames} first-cell`}
        style={{ paddingRight: showErrorIcon ? '40px' : '20px' }}
      >
        {voucherName}
        {' '}
        {renderFulfillmentTooltip()}
        {autoCanceled && !isRemoved && renderError()}
        {isRemoved && renderRemovedError()}
      </TableCell>
      <TableCell id="currency" className={classNames}>{currency}</TableCell>
      <TableCell id="country" className={classNames}>{country}</TableCell>
      <TableCell id="typeName" className={classNames}>{typeName}</TableCell>
      <TableCell id="brand" className={classNames}>
        {isTypeMoney
          ? (
            <Select
              name="branded"
              id="branded"
              value={_.find(brandedOptions, { value: branded })}
              options={brandedOptions}
              disabled={!canEditBranded}
              size="sm"
              onChange={e => changeProp(e, line)}
              placeholder={t('msg_codes_common_select')}
            />
          )
          : (
            <div className="not-available">N/A</div>
          )}
      </TableCell>
      <TableCell id="quantity" className={classNames}>
        {editableQuantity
          ? (
            <TextInput
              type="number"
              name="quantity"
              value={quantity}
              onChange={e => changeProp(e, line)}
              onKeyDown={handleQuantityKeypress}
              onBlur={handleQuantityBlur}
              hasError={Number(line.quantity) > 900000 || Number(line.quantity) < 1}
              data-testid="quantity-input"
            />
          )
          : <span data-testid="quantity-text">{quantity}</span>
        }
      </TableCell>
      <TableCell id="cardManufacturePrice" className={classNames}>
        {editableCost
          ? (
            <TextInput
              type="number"
              name="cardManufacturePrice"
              value={cardManufacturePrice === null ? '' : cardManufacturePrice}
              placeholder="0.0000"
              onChange={e => changeProp(e, line)}
              onKeyDown={preventLetterKeypress}
              data-testid="price-input"
            />
          )
          : <span data-testid="price-text">{Number(cardManufacturePrice).toFixed(4) || ''}</span>
        }
      </TableCell>
      <TableCell id="tax" className={classNames}>
        {editableTax
          ? (
            <TextInput
              name="tax"
              value={tax === null ? '' : tax}
              placeholder="0.00"
              onChange={e => changeProp(e, line)}
              onKeyDown={preventLetterKeypress}
              hasError={Number(line.tax) > 100}
              data-testid="tax-input"
            />
          )
          : <span data-testid="tax-text">{Number(tax).toFixed(2) || ''}</span>
        }
      </TableCell>
      <TableCell id="getTotal" className={classNames}>
        {getTotal()}
      </TableCell>
      <TableCell id="getTax" className={classNames}>
        {getTax(orderStatus, line)}
      </TableCell>
      {isApproved ? (
        <TableCell id="npVoucherBatchId" className={classNames}>{npVoucherBatchId || '-'}</TableCell>
      ) : <></>}
      {isApproved ? (
        <TableCell name="batchLabel" id="batch-label-code">{batchLabelCode || '-'}</TableCell>
      ) : <></>}
      {(permissions.canViewStartDate && isApproved && isSonyUser) ? (
        <TableCell name="startDate" id="start-date">{formatDate(startDate)}</TableCell>
      ) : <></>}
      {(permissions.canViewEndDate && isApproved && isSonyUser) ? (
        <TableCell name="endDate" id="end-date">{formatDate(endDate)}</TableCell>
      ) : <></>}
      <TableCell id="customId" className={classNames}>
        <div data-testid="custom-id-container">
          {isApproved || (!editableItem || isCancelled)
            ? <span data-testid="custom-id-text">{customId}</span>
            : (
              <TextInput
                type="text"
                value={customId || ''}
                name="customId"
                onChange={e => changeProp(e, line)}
                onKeyDown={handleKeypress}
                onBlur={handleBlur}
                charCountMax={20}
                width={500}
                id="custom-id-input"
                displayCount={displayCount}
                placeholder={t('msg_codes_add_custom_id')}
              />
            )
          }
        </div>
      </TableCell>
      <TableCell id="itemReferenceField" className={classNames}>
        {!editableItem || isCancelled
          ? <span data-testid="item-reference-text">{itemReference}</span>
          : (
            <TextInput
              type="text"
              value={itemReference}
              name="itemReference"
              onChange={e => changeProp(e, line)}
              charCountMax={500}
              width={300}
              id="item-reference-input"
              placeholder={t('msg_codes_orderDetails_itemReference')}
            />
          )
        }
      </TableCell>
      {isApproved ? (
        <TableCell id="fulfillment" className={classNames}>
          {renderStatus(fulfillmentStatus, fulfillmentStatus === 'Fulfilling' || fulfillmentStatus === 'Cancelling code')}
        </TableCell>
      ) : <></>}
      {isApproved ? (
        <TableCell id="delivery" className={classNames}>
          {renderStatus(deliveryStatus, deliveryStatus === 'Delivering')}
          {deliveryStatus === 'Delivered' && renderDeliveredTooltip()}
        </TableCell>
      ) : <></>}
      {isApproved ? (
        <TableCell id="activation" className={classNames}>
          {renderStatus(activationStatus, activationStatus === 'Activating')}
        </TableCell>
      ) : <></>}
      {showDeleteIcon ? (
        <TableCell className="delete-icon">
          <span
            onClick={e => deleteOrderLine(e, order, line)}
            onKeyDown={e => deleteOrderLine(e, order, line)}
            role="button"
            tabIndex={0}
          >
            <Icon
              name="trashIcon"
              id="delete-icon"
            >
              ico-trashcan
            </Icon>
            <span className="remove-text">{t('msg_codes_cta_remove')}</span>
          </span>
        </TableCell>
      ) : <></>}
      {hideAllLineKabobs ? <></> : <TableCell id="order-line-tooltip-kabob" className={classNames}>{renderKabob()}</TableCell>}
    </TableRow>
  );
};


OrderContentRow.propTypes = {
  order: PropTypes.object,
  line: PropTypes.object,
  selectLine: PropTypes.func,
  handleKeypress: PropTypes.func,
  handleBlur: PropTypes.func,
  changeProp: PropTypes.func,
  permissions: PropTypes.object,
  tableRef: PropTypes.object,
  checkboxState: PropTypes.object,
  isDisabled: PropTypes.bool,
  isApproved: PropTypes.bool,
  canCancel: PropTypes.bool,
  canDownload: PropTypes.bool,
  isSonyUser: PropTypes.bool,
  hideAllLineKabobs: PropTypes.bool,
  isCheckboxEnabled: PropTypes.bool,
  showDeleteIcon: PropTypes.bool,
};

OrderContentRow.defaultProps = {
  order: {},
  line: {},
  permissions: {},
  selectLine: () => {},
  handleKeypress: () => {},
  handleBlur: () => {},
  changeProp: () => {},
  tableRef: {},
  checkboxState: {},
  isDisabled: false,
  isApproved: false,
  canCancel: false,
  canDownload: false,
  isSonyUser: false,
  isCheckboxEnabled: false,
  showDeleteIcon: false,
  hideAllLineKabobs: false,
};

export default OrderContentRow;
