export const agreementReasons = {
  AGREEMENT_VERSION_CHANGE: 'Order line item cancelled. The Product Type has been removed in the latest version of your Agreement',
  AGREEMENT_STATUS_CHANGE: 'Order line item cancelled. Please contact Sony for further information',
};

export const productReasons = {
  PARTNER_UNAVAILABLE: 'Voucher Product no longer available. Please contact Sony for further information.',
  PRODUCT_INACTIVE: 'Voucher Product no longer available. Please contact Sony for further information.',
  COUNTRY_UNAVAILABLE: 'Voucher Product no longer available. Please contact Sony for further information.',
  AGREEMENT_TYPE_UNAVAILABLE: 'Voucher Product no longer available. Please contact Sony for further information.',
};

export const batchReasons = {
  CANCELLATION_OUTSIDE_THE_ORDERING_WORKFLOW: 'Order line was cancelled outside of the ordering workflow',
};

export default {
  ...agreementReasons,
  ...productReasons,
  ...batchReasons,
};
