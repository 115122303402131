import il8n from 'i18next';
import axios from 'axios';
import { uniq, flatten, partition } from 'lodash';
// import moment from 'moment-timezone';

import * as types from './actionTypes';
import paramBuilder from '../utils/paramBuilder.utils';
import thirdPartyDownloadStatus from '../utils/thirdPartyDownloadStatus';
import getFeature from '../utils/accessControl/getFeature';
import { autoHide, timeout } from '../constants/notifications.constant';

export const actionCallback = (externalUrl) => {
  return () => {
    window.open(externalUrl);
  };
};

export function getItemsToRemoveFromPolling(downloadIdSet, products) {
  const catalogItems = products.reduce((catalog, concept) => {
    catalog.push(...concept.catalogs);
    return catalog;
  }, []);
  const readyToDownload = catalogItems
    .filter((product) => {
      const statusActions = thirdPartyDownloadStatus[product.status];
      const shouldRemoveFromDownloadQueue = statusActions.removeFromDownloadQueue;
      return downloadIdSet.has(product.voucherCatalogId) && shouldRemoveFromDownloadQueue;
    });
  return readyToDownload;
}

export function beginLoadThirdPartyCatalog() {
  return { type: types.BEGIN_LOAD_THIRD_PARTY_CATALOG };
}

export function finishLoadThirdPartyCatalog(thirdPartyCatalog) {
  return { type: types.FINISH_LOAD_THIRD_PARTY_CATALOG, thirdPartyCatalog };
}

export function setThirdPartyCatalogPartner(partnerId) {
  return { type: types.THIRD_PARTY_CATALOG_PARTNER, partnerId };
}

export function setThirdPartyCatalogPage(page) {
  return { type: types.SET_THIRD_PARTY_CATALOG_PAGE, page };
}

export function updateFromPolling(products) {
  return { type: types.UPDATE_FROM_POLLING, products };
}

const filterParam = (filters) => {
  const productTypeParam = filters.productType ? paramBuilder(filters.productType, 'productTypeCode') : '';
  const versionParam = filters.version ? paramBuilder(filters.version, 'version') : '';
  const regionTypeParam = filters.targetRegions ? paramBuilder(filters.targetRegions, 'targetRegions') : '';
  const platformTypeParam = filters.platform ? paramBuilder(filters.platform, 'platform') : '';

  const startDate = filters.releaseDate[0] ? `&releaseDateStart=${filters.releaseDate[0]}` : '';
  const endDate = filters.releaseDate[1] ? `&releaseDateEnd=${filters.releaseDate[1]}` : '';

  return (
    productTypeParam
    + versionParam
    + regionTypeParam
    + platformTypeParam
    + startDate
    + endDate
  );
};

export async function loadThirdPartyCatalog(
  dispatch, size = 10, page = 0, search = { },
  sort = { name: 'codeProduct', order: 'asc' },
  filters = {
    productType: [], version: [], targetRegions: [], releaseDate: [], platform: [],
  },
  partnerId,
  notificationContext,
) {
  const filtersParams = filterParam(filters);
  const sortParam = `?sort=${sort.name},${sort.order}`;
  const pageParam = `page=${page}`;
  const sizeParam = `size=${size}`;
  const searchParam = search.category && search.term ? `&${search.category}=${search.term}` : '';
  const partnerParam = partnerId ? `&partnerId=${partnerId}` : '';
  const url = `/venom/api/third-party-products${sortParam}&${pageParam}&${sizeParam}${filtersParams}${searchParam}${partnerParam}`;

  dispatch(beginLoadThirdPartyCatalog());
  try {
    const response = await axios.get(url);
    const thirdPartyCatalog = await response.data;
    if (response.status !== 200) {
      // TODO: error handling
      dispatch(finishLoadThirdPartyCatalog());

      throw Error(response.statusText);
    }
    dispatch(finishLoadThirdPartyCatalog(thirdPartyCatalog.content));
    dispatch(setThirdPartyCatalogPage(thirdPartyCatalog));
  } catch (error) {
    notificationContext.dispatch({
      type: 'add',
      payload: {
        status: 'error',
        autoHide,
        timeout,
        message: il8n.t('msg_codes_codes_banner_somethingWentWrong_refresh'),
        testId: 'error',
      },
    });
  }
}

export function setFilter(filterType, filterItems) {
  return (dispatch) => {
    dispatch({
      type: types.SET_THIRD_PARTY_CATALOG_FILTER,
      filterType,
      filterItems,
    });
  };
}

export function resetFilters() {
  return {
    type: types.RESET_THIRD_PARTY_CATALOG_FILTERING,
  };
}

export function pollingNotifications(products, notificationContext) {
  products.forEach((product) => {
    const statusActions = thirdPartyDownloadStatus[product.status];
    if (
      statusActions
      && statusActions.toastOnPollingMessage
    ) {
      notificationContext.dispatch({
        type: 'add',
        payload: {
          status: statusActions.pollingToastType,
          autoHide,
          timeout,
          message: il8n.t(statusActions.toastOnPollingMessage),
          actionText:
          statusActions.toastActionText
            && il8n.t(statusActions.toastActionText),
          actionCallback: actionCallback(statusActions.toastUrl),
          testId: statusActions.pollingToastType,
        },
      });
    }
  });
}

export function downloadThirdPartyProduct(
  thirdPartyProductId,
  notificationContext,
  addToDownloadQueue,
  removeFromDownloadQueue,
  partnerId,
) {
  const partnerParam = partnerId ? `?partnerId=${partnerId}` : '';

  return dispatch => axios.post(`/venom/api/third-party-catalogs/${thirdPartyProductId}/download${partnerParam}`)
    .then((response) => {
      if (response.status !== 200) {
        notificationContext.dispatch({
          type: 'add',
          payload: {
            status: 'error',
            autoHide,
            timeout,
            message: il8n.t('msg_codes_codes_banner_somethingWentWrong'),
            testId: 'error',
          },
        });
        return Promise.reject(response);
      }
      return response.data;
    })
    .then((response) => {
      dispatch(updateFromPolling([response]));
      const statusActions = thirdPartyDownloadStatus[response.status];
      if (statusActions) {
        if (statusActions.removeFromDownloadQueue) {
          removeFromDownloadQueue([response.voucherCatalogId]);
        }
        if (statusActions.addToDownloadQueue) {
          addToDownloadQueue(response.voucherCatalogId);
        }
        if (statusActions.initiateDownload && response.downloadUrl) {
          window.open(response.downloadUrl);
        }
        if (statusActions.toastOnDownloadMessage) {
          notificationContext.dispatch({
            type: 'add',
            payload: {
              status: statusActions.downloadToastType,
              autoHide,
              timeout,
              message: il8n.t(statusActions.toastOnDownloadMessage),
              actionText:
              statusActions.toastActionText
                && il8n.t(statusActions.toastActionText),
              actionCallback: actionCallback(statusActions.toastUrl),
              testId: statusActions.downloadToastType,
            },
          });
        }
        return Promise[statusActions.promiseAction](response);
      }
      notificationContext.dispatch({
        type: 'add',
        payload: {
          status: 'error',
          autoHide,
          timeout,
          message: il8n.t('msg_codes_codes_banner_somethingWentWrong'),
          testId: 'error',
        },
      });
      return Promise.reject(response);
    });
}

export function getThirdPartyCatalogStatus(
  pollingIds,
  downloadIds = [],
  notificationContext,
  partnerId,
) {
  const voucherCatalogIds = flatten(uniq([...pollingIds, ...downloadIds]));
  const downloadIdSet = new Set(downloadIds);
  const postBody = {
    voucherCatalogIds,
    partnerId,
  };

  return (dispatch => axios.post('/venom/api/third-party-catalogs/status',
    {
      ...postBody,
    })
    .then((response) => {
      if (response.status !== 200) {
        console.error(response.statusText);
      }
      return response;
    })
    .then(response => response.data)
    .then((products) => {
      const readyToDownload = getItemsToRemoveFromPolling(downloadIdSet, products);
      const catalogItems = products.reduce((catalog, concept) => {
        catalog.push(...concept.catalogs);
        return catalog;
      }, []);
      dispatch(updateFromPolling(catalogItems));
      pollingNotifications(readyToDownload, notificationContext);
      return readyToDownload;
    }));
}

export function retryThirdPartyProduct(
  thirdPartyProductId, notificationContext,
  addToDownloadQueue,
) {
  return dispatch => axios.put(`/venom/api/third-party-catalogs/${thirdPartyProductId}/retry`)
    .then((response) => {
      if (response.status !== 200) {
        notificationContext.dispatch({
          type: 'add',
          payload: {
            status: 'error',
            autoHide,
            timeout,
            message: il8n.t('msg_codes_codes_banner_somethingWentWrong'),
            testId: 'error',
          },
        });
        return Promise.reject(response);
      }
      return response.data;
    })
    .then((response) => {
      dispatch(updateFromPolling([response]));
      const statusActions = thirdPartyDownloadStatus[response.status];
      if (statusActions) {
        if (statusActions.retryPolling) {
          addToDownloadQueue(response.voucherCatalogId);
        }
        if (statusActions.initiateDownload && response.downloadUrl) {
          window.open(response.downloadUrl);
        }
        if (statusActions.toastOnDownloadMessage) {
          notificationContext.dispatch({
            type: 'add',
            payload: {
              status: statusActions.downloadToastType,
              autoHide,
              timeout,
              message: il8n.t(statusActions.toastOnDownloadMessage),
              actionText:
              statusActions.toastActionText
                && il8n.t(statusActions.toastActionText),
              actionCallback,
              testId: statusActions.downloadToastType,
            },
          });
        }
        return Promise[statusActions.promiseAction](response);
      }
      notificationContext.dispatch({
        type: 'add',
        payload: {
          status: 'error',
          autoHide,
          timeout,
          message: il8n.t('msg_codes_codes_banner_somethingWentWrong'),
          testId: 'error',
        },
      });
      return Promise.reject(response);
    });
}

export function thirdPartyDownloadHistory(voucherCatalogId, setLoadingDownloadHistory) {
  setLoadingDownloadHistory(true);
  const url = `/venom/api/third-party-catalogs/${voucherCatalogId}/downloadHistory`;
  return axios.get(url)
    .then((response) => {
      if (response.status !== 200) {
        setLoadingDownloadHistory(false);
        return Promise.reject(response);
      }
      return response.data;
    })
    .then((response) => {
      setLoadingDownloadHistory(false);
      return response;
    });
}

export async function getOrderActivityRules(dispatch) {
  let rules = {};
  try {
    const response = await axios.get('/venom/api/orderActivityRules');
    rules = await response.data;
    dispatch({
      type: types.SET_ORDER_ACTIVITY_RULES,
      rules,
    });
  } catch (error) {
    //  TODO: error handling (optizmized and without logs)
  }

  return rules;
}

export async function getUpdatedCatalog(voucherCatalogIds, partnerId) {
  const response = await axios.post('/venom/api/third-party-products-by-id', {
    voucherCatalogIds,
    partnerId,
  });
  return response.data;
}

export const pickOrder = ({
  voucherCatalogId,
  quantity,
  activity,
  poNumber,
  startDate,
  partnerComments,
  endDate,
}) => {
  const order = {
    voucherCatalogId,
    activity,
    poNumber,
    quantity,
  };
  if (partnerComments) {
    order.partnerComments = partnerComments;
  }
  if (startDate) {
    order.startDate = startDate;
  }

  if (endDate) {
    order.endDate = endDate;
  }
  return order;
};

export function submitCart(cart, notificationContext, partnerId) {
  const orderPromises = cart.map(async (cartItem) => {
    const formBody = pickOrder(cartItem);
    const requestBody = new FormData();
    const bodyContents = partnerId
      ? JSON.stringify({
        ...formBody,
        partnerId,
      })
      : JSON.stringify(formBody);
    requestBody.append('json', bodyContents);
    if (cartItem && cartItem.poFile && cartItem.poFile.file) {
      requestBody.append(
        'file',
        await fetch(cartItem && cartItem.poFile && cartItem.poFile.file).then(r => r.blob()),
        cartItem.poFile.name,
      );
    }
    const response = axios.post('/venom/api/third-party-orders/submit', requestBody)
      .then((result) => {
        const responseBody = result;
        return {
          ...result.data,
          cartId: cartItem.cartId,
          result: result.status,
          errorResponse: result.status === 400 ? responseBody.detailMessages[0] : undefined,
        };
      });
    return response;
  });
  const submissionResults = Promise.allSettled(orderPromises).then((results) => {
    const [rejected, fulfilled] = partition(results, (order) => {
      if (order.value) {
        return order.value.result !== 200;
      }
      return true;
    });
    if (fulfilled.length > 0) {
      const helpUrl = getFeature('helpUrl');

      notificationContext.dispatch({
        type: 'add',
        payload: {
          status: 'success',
          autoHide,
          timeout,
          message: il8n.t('msg_codes_ordersSubmitted_banner', { number: fulfilled.length }),
          actionText: il8n.t('msg_codes_cta_learnMore'),
          actionCallback: actionCallback(helpUrl),
          testId: 'success',
        },
      });
    }
    if (rejected.length > 0) {
      notificationContext.dispatch({
        type: 'add',
        payload: {
          status: 'error',
          autoHide,
          timeout,
          message: il8n.t('msg_codes_OrdernotSubmitted_banner'),
          testId: 'error',
        },
      });
    }
    return [rejected, fulfilled];
  });
  return submissionResults;
}
