import React from 'react';
import PropTypes from 'prop-types';
import {
  Select,
} from '@partner-global-ui/components';


const Activity = ({
  showActivitySelect,
  activity,
  activityOptions,
  handleActivityChange,
  tableRef,
  name,
}) => {
  if (showActivitySelect) {
    return (
      <Select
        value={activity}
        options={activityOptions}
        onChange={handleActivityChange}
        size="sm"
        anchor={tableRef}
        id={`activity-select-${name}`}
      />
    );
  }
  if (activity) {
    return <>{activity.label}</>;
  }
  return <></>;
};

Activity.propTypes = {
  activity: PropTypes.object,
  activityOptions: PropTypes.array,
  showActivitySelect: PropTypes.bool,
  handleActivityChange: PropTypes.func,
  tableRef: PropTypes.object,
  name: PropTypes.string,
};

Activity.defaultProps = {
  activity: {},
  activityOptions: [],
  showActivitySelect: false,
  handleActivityChange: () => {},
  tableRef: {},
  name: '',
};

export default Activity;
