import React from 'react';
import { useDispatch } from 'react-redux';
import {
  GroupState,
  TableCell,
  TableRow,
  Select,
  TextInput,
} from '@partner-global-ui/components';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import countriesGensenStrings from '../../../constants/countries.constants';

import {
  addAgencyFeeStructure, removeAgencyFeeStructure, updateAgencyFeeStructure,
} from '../../../actions/agreementFormActions';
import './VoucherCoverageTable.scss';

const CoverageRowComponent = ({
  country,
  voucherProductType,
  selected,
  canEdit,
  showFeeStructureMoneyDetails,
  currencies,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const checked = selected.country === country.code;

  const toggleCountry = (isChecked, voucher) => {
    if (isChecked) {
      dispatch(removeAgencyFeeStructure(voucherProductType, voucher));
    } else {
      dispatch(addAgencyFeeStructure(voucherProductType, voucher));
    }
  };

  const handleChange = (e, isChecked, voucher) => {
    const { name, value } = e.target;

    const newVoucher = {
      ...voucher,
      [name]: value.code,
      currencyStatus: value.status,
      settlementCurrency: value.code,
    };

    if (!isChecked) {
      toggleCountry(isChecked, newVoucher);
    } else {
      dispatch(updateAgencyFeeStructure(voucherProductType, newVoucher));
    }
  };

  const handleCommissionRateChange = (name, isChecked, voucher) => {
    return (values) => {
      const newVoucher = {
        ...voucher,
        [name]: values.floatValue,
      };
      if (newVoucher.commissionRate > 0) {
        if (!isChecked) {
          toggleCountry(isChecked, newVoucher);
        } else if (newVoucher.modifyDate !== voucher.modifyDate
            || newVoucher.commissionRate !== voucher.commissionRate) {
          dispatch(updateAgencyFeeStructure(voucherProductType, newVoucher));
        }
      } else if (newVoucher.commissionRate === 0 || typeof newVoucher.commissionRate === 'undefined') {
        toggleCountry(true, newVoucher);
      }
    };
  };

  const checkboxState = checked ? GroupState.ALL : GroupState.NONE;

  const voucher = checked
    ? selected
    : country;

  const numericString = true;

  const isAllowedValue = (values) => {
    const { formattedValue, floatValue } = values;
    return (formattedValue === '' || formattedValue === '.' || floatValue <= 999.99);
  };

  const currencyOptions = currencies.map((currency) => {
    return {
      ...currency,
      label: currency.code,
      value: currency.code,
    };
  });

  const selectedCurrencyOption = currencyOptions
    .find(option => option.code === voucher.settlementCurrency);

  return (
    <TableRow
      hasCheckbox
      onCheckboxClick={() => toggleCountry(checked, voucher)}
      key={voucher.npVoucherTypeId}
      className="coverage-row"
      id="coverage-row"
      checkboxState={checkboxState}
    >
      <TableCell id="countryName" name="countryName">
        {countriesGensenStrings[country.code] && t(countriesGensenStrings[country.code].name)}
      </TableCell>
      <TableCell id="currencyCode" name="currencyCode">{country.currency.code}</TableCell>
      {
        showFeeStructureMoneyDetails ? (
          <>
            <TableCell id="settlementCurrency-cell">
              <Select
                id="settlementCurrency-select"
                name="settlementCurrency"
                options={orderBy(currencyOptions, ['code'], ['asc'])}
                onChange={e => handleChange(e, checked, voucher)}
                value={selectedCurrencyOption}
              />
            </TableCell>
            <TableCell id="commissionRate-cell">
              <NumberFormat
                name="commissionRate"
                customInput={TextInput}
                value={voucher.commissionRate}
                disabled={!canEdit}
                placeholder="0.00"
                fixedDecimalScale={numericString}
                defaultValue={0.00}
                allowNegative={!numericString}
                decimalScale={2}
                displayType="input"
                onValueChange={handleCommissionRateChange('commissionRate', checked, voucher)}
                className="commissionRate"
                isNumericString={numericString}
                isAllowed={isAllowedValue}
              />
            </TableCell>
          </>
        ) : <></>
      }
    </TableRow>
  );
};

CoverageRowComponent.propTypes = {
  country: PropTypes.object,
  selected: PropTypes.object,
  canEdit: PropTypes.bool,
  voucherProductType: PropTypes.string,
  showFeeStructureMoneyDetails: PropTypes.bool,
  currencies: PropTypes.array,
};

CoverageRowComponent.defaultProps = {
  country: {
    selected: true,
  },
  selected: {},
  canEdit: false,
  voucherProductType: '',
  showFeeStructureMoneyDetails: false,
  currencies: [],
};

export default CoverageRowComponent;
