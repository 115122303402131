import React, {
  useState,
  useEffect,
  useRef,
  useContext,
} from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Flex,
  Layout,
  Collapsible,
  DataTable,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableCell,
  Tooltip,
  DateInput,
  TextInput,
  Icon,
  Loader,
  GroupState,
  ModalContext,
  MODAL_ACTIONS,
  NotificationContext,
} from '@partner-global-ui/components';
import moment from 'moment';
import { usePermission } from '../../../utils/accessControl/hasPermission';
import roleKeys from '../../../utils/accessControl/roleKeys';
import { getProductType } from '../../../constants/thirdPartyCatalog.constants';
import OrderStatuses from '../../../constants/order.constants';
import orderLineErrors from '../../../constants/orderLineErrors.constants';
import { registrationRegionOptions } from '../../../constants/filterOptions.constants';
import CodeProductSwapModal from './CodeProductSwapModal';
import OrderLineTooltip from './OrderLineTooltip';
import {
  handleSetStartDate,
  handleSetStartTime,
  handleSetEndDate,
  handleSetEndTime,
  handleUseCount,
} from './orderContentsMethods';
import getTableHeaders from './TableHeaders';
import countries from '../../../constants/countries.constants';
import { activitySelectTypes } from '../../../constants/activityType.constants';

function OrderContents({
  order,
  setHasError,
  updatedOrderValues,
  setUpdatedOrderValues,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tableRef = useRef();
  const modalContext = useContext(ModalContext);
  const notificationCont = useContext(NotificationContext);

  const [hideAllLineKabobs, setHideAllLineKabobs] = useState(false);
  // State variables for dates
  const [localStartDate, setLocalStartDate] = useState('');
  const [localStartTime, setLocalStartTime] = useState('');
  const [localEndDate, setLocalEndDate] = useState('');
  const [localEndTime, setLocalEndTime] = useState('');
  const [startDateError, setStartDateError] = useState('');
  const [endDateError, setEndDateError] = useState('');
  const [selectedLines, setSelectedLines] = useState([]);
  const [headerCheckBoxState, setHeaderCheckBoxState] = useState(GroupState.NONE);

  // Local variables
  const isApproved = order.orderStatus === OrderStatuses.APPROVED_ORDER;
  const isAwaitingApproval = order.orderStatus === OrderStatuses.SUBMITTED_PENDING_APPROVAL;

  const checkLineCanceled = orderLine => orderLine.autoCanceled || orderLine.canceled;

  const checkLineHasActions = orderLine => !checkLineCanceled(orderLine)
    && (orderLine.downloadable || orderLine.retryable || orderLine.cancelable);

  useEffect(() => {
    const someLinesHaveActions = order.orderLines.some(checkLineHasActions);
    const someLinesProcessing = order.orderLines.some(orderLine => orderLine.processingOrderLine);
    setHideAllLineKabobs(!someLinesHaveActions && !someLinesProcessing);
  }, [order.orderLines]);

  useEffect(() => {
    const checkBoxState = order.orderLines.map(({ id }) => {
      const allSelected = selectedLines.length && selectedLines.every(line => line.id === id);
      const someSelected = !allSelected && selectedLines.some(line => line.id === id);

      return allSelected
        ? GroupState.ALL
        : someSelected
          ? GroupState.SOME
          : GroupState.NONE;
    })[0];

    setHeaderCheckBoxState(checkBoxState);
  }, [selectedLines, order.orderLines]);

  useEffect(() => {
    if (startDateError.length > 0 || endDateError.length > 0) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [startDateError, endDateError]);

  const permissions = {
    download: usePermission(roleKeys.permittedUsesOrderContent3P.downloadLineItem),
    retry: usePermission(roleKeys.permittedUsesOrderContent3P.retryLineItem),
    cancel: usePermission(roleKeys.permittedUsesOrderContent3P.cancelLineItem),
    viewError: usePermission(roleKeys.order.viewThirdPartyLineErrors),
    editLineDateTime: usePermission(roleKeys.permittedUsesOrderContent3P.editLineDateTime),
    editUseCount: usePermission(roleKeys.permittedUsesOrder.editUseCount)
      && order.orderActivity !== activitySelectTypes.PUBLISHER_PAID_ALLOCATION.value,
    canSwap: usePermission(roleKeys.permittedUsesOrderContent3P.canSwapCodeProduct),
  };

  const isSwapDisabled = () => {
    return !(
      permissions.canSwap
      && selectedLines.length === 1
      && isApproved
      && selectedLines[0].fulfillmentStatus === 'Fulfilled'
      && selectedLines[0].deliveryStatus === 'Delivered'
      && (selectedLines[0].activationStatus === 'Activated'
      || selectedLines[0].activationStatus === 'Inactive')
    );
  };

  const toggleAll = () => {
    if (selectedLines.length < order.orderLines.length) {
      setSelectedLines([
        ...order.orderLines,
      ]);
    } else {
      setSelectedLines([]);
    }
  };

  const selectLine = (clickedLine) => {
    const isAlreadySelected = selectedLines.some(line => line.id === clickedLine.id);
    if (!isAlreadySelected) {
      setSelectedLines([...selectedLines, clickedLine]);
    } else {
      setSelectedLines(selectedLines.filter(line => line.id !== clickedLine.id));
    }
  };


  const swapProduct = () => {
    if (selectedLines.length > 0) {
      modalContext.dispatch({
        type: MODAL_ACTIONS.SHOW,
        payload: <CodeProductSwapModal
          orderLineId={selectedLines[0].orderLineId}
          originalCodeProduct={selectedLines[0].voucherCatalog}
          notificationContext={notificationCont}
          orderNumber={order.orderNumber}
        />,
      });
    }
  };

  function renderHeaderCells() {
    return getTableHeaders(t, isAwaitingApproval, hideAllLineKabobs).map((item) => {
      if (typeof item === 'string') {
        return (
          <TableHeaderCell id={item} key={item} value={item}>{item}</TableHeaderCell>
        );
      }
      return (
        <TableHeaderCell id={item.label} key={item.label} value={item.label}>
          {item.label}
          <Tooltip
            content={(<div className="use-count">{item.tooltip}</div>)}
            position="top"
            strategy="fixed"
          >
            <Icon className="info-icon">ico-help-circle</Icon>
          </Tooltip>
        </TableHeaderCell>
      );
    });
  }

  // Due to how DataTable passes data to its children, line items cannot be in a dedicated component
  function renderTableRows() {
    return order.orderLines.map((line, rowIndex) => {
      const key = rowIndex + 1;
      const {
        exportErrorMessage,
        quantity,
        npVoucherBatchId,
        fulfillmentStatus,
        deliveryStatus,
        activationStatus,
        processingOrderLine,
        scheduledActivationDate,
        scheduledBatchExpirationDate,
        startDate,
        endDate,
        useCount,
        voucherCatalog: {
          codeProduct,
          urlToScpPar,
          productId,
          productTypeCode,
          version,
          platform,
          conceptName,
          targetRegions = '-',
          estimatedReleaseDate,
          releaseDate,
          voucherName,
          region,
          urlToScpProduct,
          voucherSku,
          voucherParId,
          standardParId,
        },
      } = line;

      const isLineSelected = selectedLines.some(selectedLine => selectedLine.id === line.id);
      let showErrorIcon = false;
      const isCanceled = line.autoCanceled || line.canceled;
      const hasUseCountError = useCount < 1 || useCount > 1000000;
      const classNames = [
        'order-line-cell',
        ...(isCanceled ? ['autocanceled'] : []),
      ].join(' ');

      let scheduledStartDate;
      let scheduledEndDate;
      let scheduledStartTime;
      let scheduledEndTime;
      if (isAwaitingApproval) {
        scheduledStartDate = scheduledActivationDate
          ? moment.utc(scheduledActivationDate).format('YYYY-MM-DD')
          : '';
        scheduledStartTime = scheduledActivationDate
          ? moment.utc(scheduledActivationDate).format('hh:mm A')
          : '';

        scheduledEndDate = scheduledBatchExpirationDate
          ? moment.utc(scheduledBatchExpirationDate).format('YYYY-MM-DD')
          : '';
        scheduledEndTime = scheduledBatchExpirationDate
          ? moment.utc(scheduledBatchExpirationDate).format('hh:mm A')
          : '';
      } else {
        scheduledStartDate = startDate
          ? moment.utc(startDate).format('YYYY-MM-DD')
          : '';
        scheduledStartTime = startDate
          ? moment.utc(startDate).format('hh:mm A')
          : '';

        scheduledEndDate = endDate
          ? moment.utc(endDate).format('YYYY-MM-DD')
          : '';
        scheduledEndTime = endDate
          ? moment.utc(endDate).format('hh:mm A')
          : '';
      }

      if (scheduledStartDate && localStartDate.length === 0) {
        setLocalStartDate(scheduledStartDate);
      }

      if (scheduledStartTime && localStartTime.length === 0) {
        setLocalStartTime(scheduledStartTime);
      }


      if (scheduledEndDate && localEndDate.length === 0) {
        setLocalEndDate(scheduledEndDate);
      }

      if (scheduledEndTime && localEndTime.length === 0) {
        setLocalEndTime(scheduledEndTime);
      }

      const fulfillmentErrorCode = exportErrorMessage
        ? exportErrorMessage.substring(0, 4)
        : '';

      const fulfillmentErrorReason = orderLineErrors.find((errors) => {
        return errors.code === fulfillmentErrorCode;
      });

      if (fulfillmentErrorReason) {
        showErrorIcon = true;
      }

      function renderLineErrorIcon() {
        if (isCanceled) {
          return (
            <div className="iconPlacement">
              <Tooltip
                content={t('msg_codes_orderCanceled')}
                position="right"
              >
                <Icon className="warning-icon">ico-alert</Icon>
              </Tooltip>
            </div>
          );
        }

        if (!fulfillmentErrorReason) {
          return null;
        }

        const fulfillmentErrorMessage = fulfillmentErrorReason ? fulfillmentErrorReason.error : '';

        return permissions.viewError && (
          <div className="iconPlacement">
            <Tooltip content={t(fulfillmentErrorMessage)} className="iconPlacement">
              <Icon className="warning-icon">ico-alert</Icon>
            </Tooltip>
          </div>
        );
      }

      function rendervParIcon() {
        const vParMessage = t('msg_codes_codesProdCa_codeOnly_tooltip');
        if (!standardParId && voucherParId) {
          return (
            <div className="iconPlacement">
              <Tooltip content={vParMessage} className="iconPlacement">
                <div className="voucher-only-product">v</div>
              </Tooltip>
            </div>
          );
        }
        return (<></>);
      }

      function renderCountryTooltip() {
        const { countryAvailabilities } = line.voucherCatalog;

        const countriesWithStrings = countryAvailabilities && countryAvailabilities.map((cty) => {
          return {
            ...cty,
            name: t(countries[cty.code].name),
          };
        });

        if (countriesWithStrings && countriesWithStrings.length === 1) {
          return t(countries[countriesWithStrings[0].code].name);
        }

        const content = countriesWithStrings && countriesWithStrings.map(
          ({ code }) => t(countries[code].name),
        )
          .sort()
          .join(', ');

        return (
          <div className="tooltip-container">
            <Tooltip
              id="tooltip"
              className="tooltip"
              content={content}
              shortCopy={countriesWithStrings && countriesWithStrings.length <= 3}
            >
              {countriesWithStrings && countriesWithStrings.length} {t('msg_codes_countries')}
            </Tooltip>
          </div>
        );
      }

      const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
      };

      function renderBatchFileName() {
        const { deliveredVoucherCodeFilename: filename } = line;
        const copyFilename = () => copyToClipboard(filename);
        const handleEnter = (e) => {
          if (e.key === 'Enter') {
            copyFilename();
          }
        };

        return (
          <>
            <div>{filename || '-'}</div>
            {filename && (
              <div
                className="batch-filename-copy"
                onClick={copyFilename}
                // required for eslint and accessibility
                role="button"
                tabIndex={0}
                onKeyDown={handleEnter}
              >
                <Tooltip
                  content={t('msg_codes_copy_tooltip')}
                  shortCopy
                  anchor={tableRef}
                >
                  <Icon id="copy-file-name" size={15}>ico-copy</Icon>
                </Tooltip>
              </div>
            )}
          </>
        );
      }

      function renderStartDateTime() {
        const disableInput = isCanceled || !isApproved || !permissions.editLineDateTime;

        return processingOrderLine
          ? (
            <Tooltip
              content={t('msg_codes_orderDetailsAdmin_orderProcessing_tooltip')}
              position="bottom"
            >
              <div style={{ display: 'flex' }}>
                <DateInput
                  id="start-date-input"
                  className="date-input"
                  name="start"
                  value={scheduledStartDate}
                  disabled
                  displayFormat="yyyy/MM/dd"
                  placeholder={t('msg_codes_ymd')}
                />
                <TextInput
                  id="start-time-input"
                  className="time-input"
                  placeholder="00:00 AM"
                  value={scheduledStartTime}
                  disabled
                  errorPosition="right"
                />
              </div>
            </Tooltip>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <DateInput
                id="start-date-input"
                className="date-input"
                name="start"
                value={scheduledStartDate}
                disabled={disableInput}
                onBlur={event => handleSetStartDate({
                  date: event.target.value,
                  line,
                  localEndDate,
                  updatedOrderValues,
                  localStartTime,
                  setStartDateError,
                  setEndDateError,
                  setLocalStartDate,
                  setUpdatedOrderValues,
                  dispatch,
                  t,
                })}
                onChange={() => setStartDateError('')}
                mask={[/d/, /d/, /d/, /d/, '/', /d/, /d/, '/', /d/, /d/]}
                displayFormat="yyyy/MM/dd"
                hasError={!!startDateError}
                placeholder={t('msg_codes_ymd')}
                disableBefore={moment().subtract(1, 'day')}
              />
              <TextInput
                id="start-time-input"
                className="time-input"
                onBlur={event => handleSetStartTime({
                  time: event.target.value,
                  line,
                  localStartDate,
                  updatedOrderValues,
                  setStartDateError,
                  setUpdatedOrderValues,
                  setLocalStartTime,
                  dispatch,
                  t,
                })}
                placeholder="12:00 AM"
                value={scheduledStartTime}
                disabled={disableInput}
                hasError={startDateError}
              />
              <div className="date-tooltip-wrapper">
                {startDateError && (
                  <Tooltip
                    id="start-date-error"
                    content={startDateError}
                    position="top"
                    shortCopy
                  >
                    <Icon size={12}>ico-alert</Icon>
                  </Tooltip>
                )}
              </div>
            </div>
          );
      }

      function getVersion() {
        let stringId = version;
        if (version === 'FULL') {
          stringId = 'msg_codes_version_full';
        }
        if (version === 'TRIAL') {
          stringId = 'msg_codes_version_trial';
        }
        return stringId;
      }
      function renderEndDateTime() {
        const disableInput = isCanceled || !isApproved || !permissions.editLineDateTime;

        return processingOrderLine
          ? (
            <Tooltip
              content={t('msg_codes_orderDetailsAdmin_orderProcessing_tooltip')}
              position="bottom"
            >
              <div style={{ display: 'flex' }}>
                <DateInput
                  id="end-date-input"
                  className="date-input"
                  name="end"
                  value={scheduledEndDate}
                  disabled
                  displayFormat="yyyy/MM/dd"
                />
                <TextInput
                  id="end-time-input"
                  className="time-input"
                  placeholder="12:00 AM"
                  value={scheduledEndTime}
                  disabled
                  errorMessage={endDateError}
                  errorPosition="right"
                  onBlur={event => handleSetEndDate({ time: event.target.value })}
                />
              </div>
            </Tooltip>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <DateInput
                id="end-date-input"
                className="date-input"
                name="end"
                value={scheduledEndDate}
                disabled={disableInput}
                onBlur={event => handleSetEndDate({
                  date: event.target.value,
                  line,
                  updatedOrderValues,
                  localStartDate,
                  localEndTime,
                  setStartDateError,
                  setEndDateError,
                  setLocalEndDate,
                  setUpdatedOrderValues,
                  dispatch,
                  t,
                })}
                onChange={() => setEndDateError('')}
                mask={[/d/, /d/, /d/, /d/, '/', /d/, /d/, '/', /d/, /d/]}
                displayFormat="yyyy/MM/dd"
                disablePast
                disableBefore={localStartDate}
                hasError={!!endDateError}
              />
              <TextInput
                id="end-time-input"
                className="time-input"
                placeholder="12:00 AM"
                value={scheduledEndTime}
                disabled={disableInput}
                hasError={endDateError}
                onBlur={event => handleSetEndTime({
                  time: event.target.value,
                  line,
                  localEndDate,
                  updatedOrderValues,
                  setEndDateError,
                  setUpdatedOrderValues,
                  setLocalEndTime,
                  dispatch,
                  t,
                })}
              />
              <div className="date-tooltip-wrapper">
                {endDateError && (
                  <Tooltip
                    id="end-date-error"
                    content={endDateError}
                    position="top"
                    shortCopy
                  >
                    <Icon size={12}>ico-alert</Icon>
                  </Tooltip>
                )}
              </div>
            </div>
          );
      }
      function getTranslationId(status) {
        switch (status) {
          case 'Fulfilling':
            return t('msg_codes_orderListAdmin_fulfilling_label');
          case 'Fulfilled':
            return t('msg_codes_fulfillment_fulfilled');
          case 'Fulfillment Error':
            return t('msg_codes_fulfillment_fulfillmentError');
          case 'Delivered':
            return t('msg_codes_delivery_delivered');
          case 'Activated':
            return t('msg_codes_activation_activated');
          case 'Deactivated':
            return t('msg_codes_codes_status_deactivated');
          case 'Activation Error':
            return t('msg_codes_activationError');
          case 'File generation is in progress, please come back later to download.':
            return t('msg_codes_fileGen');
          case 'Pending Delivery':
            return t('msg_codes_pendingDelivery');
          case 'Inactive':
            return t('msg_codes_partnerStatus_inactive');
          default:
            return status;
        }
      }

      const getTargetRegions = () => {
        const regionLabels = registrationRegionOptions
          .filter(option => targetRegions && targetRegions.includes(option.value))
          .map(option => t(option.label));

        const labelsWithDots = regionLabels.map((label, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>
            {label}
            {
              index < regionLabels.length - 1
              && (
                <span style={{
                  display: 'inline-block',
                  height: '5px',
                  width: '5px',
                  backgroundColor: '#767676',
                  borderRadius: '50%',
                  margin: '2px 5px 0px 5px',
                }}
                />
              )
            }
          </React.Fragment>
        ));

        return labelsWithDots.length > 0 ? labelsWithDots : targetRegions && targetRegions[0];
      };

      function renderStatus(status, isLoading) {
        return (
          <span className="render-status">
            {getTranslationId(status) || '-'}
            {isLoading && <Loader size="sm" />}
          </span>
        );
      }

      function renderKabob() {
        const canDownload = line.downloadable && permissions.download;
        const canRetry = line.retryable && permissions.retry
          && (line.fulfillmentStatus === 'Fulfillment Error' || line.activationStatus === 'Activation Error');
        const canCancel = line.cancelable && permissions.cancel;

        return (
          <OrderLineTooltip
            order={order}
            orderLine={line}
            disabled={isCanceled}
            canDownload={canDownload}
            canRetry={canRetry}
            canCancel={canCancel}
          />
        );
      }

      return (
        <TableRow
          id={rowIndex}
          key={key}
          hasCheckbox
          checkboxState={isLineSelected ? GroupState.ALL : GroupState.NONE}
          onCheckboxClick={() => selectLine(line)}
        >
          <TableCell id="product" className={`${classNames} first-cell`} style={{ paddingRight: showErrorIcon ? '40px' : '20px' }}>
            {rendervParIcon()}
            {renderLineErrorIcon()}
            <a href={urlToScpProduct} className="link" target="_blank" rel="noopener noreferrer">{codeProduct}</a>
          </TableCell>
          <TableCell id="productId" className={classNames}>{productId}</TableCell>
          <TableCell id="productType" className={classNames}>{t(getProductType(productTypeCode))}</TableCell>
          <TableCell id="version" className={classNames}>{t(getVersion(version))}</TableCell>
          <TableCell id="platform" className={classNames}>{platform}</TableCell>
          <TableCell id="conceptName" className={classNames}>{conceptName}</TableCell>
          <TableCell id="registrationRegion" className={`${classNames} registrationRegion`}>
            {getTargetRegions()}
          </TableCell>
          <TableCell id="estimatedReleaseDate" className={classNames} data-testid="estimatedReleaseDate">
            {estimatedReleaseDate ? moment.utc(estimatedReleaseDate).format('MM/DD/YYYY') : t('msg_codes_codes_status_notAvailable')}
          </TableCell>
          <TableCell id="releaseDate" className={classNames}>
            {releaseDate ? moment.utc(releaseDate).format('MM/DD/YYYY') : t('msg_codes_codes_status_notAvailable')}
          </TableCell>
          <TableCell id="codeName" className={classNames}>
            <a href={urlToScpPar} className="link" target="_blank" rel="noopener noreferrer">{voucherName}</a>
          </TableCell>
          <TableCell id="region" className={classNames}>
            {t(registrationRegionOptions
              && registrationRegionOptions
                .find(element => element.value === region)
              && registrationRegionOptions
                .find(element => element.value === region).label)
              || t(region)}
          </TableCell>
          <TableCell id="country" className={classNames}>{renderCountryTooltip()}</TableCell>
          <TableCell id="skuId" className={classNames}>{voucherSku || t('msg_codes_codes_status_notAvailable')}</TableCell>
          <TableCell id="quantity" className={classNames}>{quantity}</TableCell>
          <TableCell id="useCount" className={classNames}>
            <TextInput
              name="useCount"
              type="number"
              placeholder="1"
              value={useCount}
              onChange={event => handleUseCount({
                useCount: event.target.value,
                line,
                updatedOrderValues,
                setUpdatedOrderValues,
                dispatch,
              })}
              width={80}
              disabled={!permissions.editUseCount || !isAwaitingApproval}
              hasError={hasUseCountError}
            />
          </TableCell>
          {!isAwaitingApproval ? (
            <TableCell id="npVoucherBatchId" className={classNames}>{npVoucherBatchId || '-'}</TableCell>
          ) : <></>}
          {!isAwaitingApproval ? <TableCell id="batchFileName" className={classNames}>{renderBatchFileName()}</TableCell> : <></>}
          <TableCell id="startDate" className={classNames}>{renderStartDateTime()}</TableCell>
          <TableCell id="endDate" className={classNames}>{renderEndDateTime()}</TableCell>
          {!isAwaitingApproval ? (
            <TableCell id="fulfillment" className={classNames}>
              {renderStatus(fulfillmentStatus, fulfillmentStatus === 'Fulfilling' || fulfillmentStatus === 'Cancelling code')}
            </TableCell>
          ) : <></>}
          {!isAwaitingApproval ? (
            <TableCell id="delivery" className={classNames}>
              {renderStatus(deliveryStatus, deliveryStatus === 'Delivering')}
            </TableCell>
          ) : <></>}
          {!isAwaitingApproval ? (
            <TableCell id="activation" className={classNames}>
              {renderStatus(activationStatus, activationStatus === 'Activating')}
            </TableCell>
          ) : <></>}
          {/* Must use fragment due to TableRow's React.cloneElement usage */}
          {hideAllLineKabobs ? <></> : <TableCell id="order-line-tooltip-kabob" className={classNames}>{renderKabob()}</TableCell>}
        </TableRow>
      );
    });
  }

  const columnTemplate = `repeat(${getTableHeaders(t, isAwaitingApproval, hideAllLineKabobs).length}, minmax(min-content, 400px))`;

  return (
    <Layout className="third-party-order-contents">
      <Flex
        id="third-party-order-contents-flex"
        colSpan="12"
        className={
          isSwapDisabled() && 'isDisabledSwap'
        }
      >
        <Collapsible
          id="third-party-order-contents"
          heading={t('msg_codes_orderContents_header')}
          defaultIsOpen
          rightContent={
            [{
              actionCallback: () => swapProduct(),
              actionText: t('msg_codes_swap_code_product'),
            }]
          }
        >
          <DataTable
            id="order-contents-table"
            data-testid="order-contents-table"
            columnTemplate={columnTemplate}
            scrollHeight={400}
            isFirstColumnSticky
            isLastColumnSticky={!hideAllLineKabobs}
            ref={tableRef}
            hasCheckbox
          >
            <TableHeader
              hasCheckbox
              onCheckboxClick={() => toggleAll()}
              checkboxState={headerCheckBoxState}
            >
              {renderHeaderCells()}
            </TableHeader>
            {renderTableRows()}
          </DataTable>
        </Collapsible>
      </Flex>
    </Layout>
  );
}

OrderContents.propTypes = {
  order: PropTypes.object,
  setHasError: PropTypes.func,
  updatedOrderValues: PropTypes.object,
  setUpdatedOrderValues: PropTypes.func,
};

OrderContents.defaultProps = {
  order: {},
  setHasError: () => { },
  updatedOrderValues: {},
  setUpdatedOrderValues: () => { },
};

export default OrderContents;
