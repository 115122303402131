import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  RowDisplay,
  Pagination,
  TableHeader,
  TableHeaderCell,
  DataTable,
  Container,
  Layout,
  Flex,
  Button,
  NoResults,
  Icon,
  Tooltip,
  NotificationContext,
  Modal,
  ModalTrigger,
  ModalContext,
  MODAL_ACTIONS,
  Checkbox,
} from '@partner-global-ui/components';
import {
  Link,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { orderBy } from 'lodash';
import momentTZ from 'moment-timezone';
import { autoHide, timeout } from '../../../constants/notifications.constant';
import OnNavigateAway from '../shared/OnNavigateAway';
import {
  getOrderActivityRules,
  getUpdatedCatalog,
  submitCart,
} from '../../../actions/thirdPartyCatalogActions';
import {
  getPartnerInSession,
} from '../shared/thirdPartyCatalogPageMethods';
import CartHeaderColumns from './cartHeaderColumns';
import {
  loadCartFromStorage,
  removeItemFromCart,
  emptyCart,
  removePoFiles,
} from '../../../actions/cartProductsActions';
import { saveCartToLocalStorage } from '../../../reducers/cartPageReducer';
import {
  filterSuccessfulCartItems,
  cartButtonState,
} from '../shared/orderValidation';
import CartRow from './cartRow';

import './cart.scss';

export const syncCart = (currentCart, updatedCatalog) => {
  const updatedCart = currentCart.reduce((updatedDraftOrders, order) => {
    const matchingCartItem = updatedCatalog.find((item) => {
      return item.catalogs[0].voucherCatalogId === order.voucherCatalogId;
    });
    if (matchingCartItem) {
      const isValidCartItem = matchingCartItem.catalogs[0].orderActivities
        .find(activity => activity === order.activity);
      const { catalogs, ...fieldsToUpdate } = matchingCartItem;
      const { startDate, endDate, ...remainingFields } = catalogs[0];
      if (isValidCartItem) {
        updatedDraftOrders.push({
          ...order,
          ...fieldsToUpdate,
          ...remainingFields,
          quantity: order.quantity,
        });
      }
    }
    return updatedDraftOrders;
  }, []);
  return updatedCart;
};

const Cart = () => {
  //  Setup
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const modalContext = useContext(ModalContext);
  const {
    user: { userId, roleCode },
    orderActivityRules,
    cartPage: { cart },
    thirdPartyCatalogPage: { selectedPartner: partner },
  } = useSelector(state => state);
  const selectedPartner = partner || getPartnerInSession();
  const [currentPage, setCurrentPage] = useState(1);
  const initialPageSize = localStorage.getItem(`cartPageSize-${userId}`) !== null
    ? Number(localStorage.getItem(`cartPageSize-${userId}`))
    : 10;
  const notificationCont = useContext(NotificationContext);
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [cartProducts, setCartProducts] = useState(cart);
  const [pagedData, setPagedData] = useState(cartProducts);
  const initialCartLength = cart ? cart.length : 0;
  const [totalItems, setTotalItems] = useState(initialCartLength);
  const [sort, setSort] = useState({ sortBy: '', sortDir: '' });
  const [checkboxState, setCheckbox] = useState();
  const isCartRowView = true;
  const [poFileAddedNotSubmitted, setPoFileAddedNotSubmitted] = useState(false);
  const poFileValidation = useRef(false);
  const tableRef = useRef();
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const defaultTimeZone = momentTZ.tz.guess();
  const timezones = [
    { label: t('msg_codes_common_utc'), value: 'UTC' },
    { label: t('msg_codes_common_localTime'), value: defaultTimeZone },
  ];

  useEffect(() => {
    if (!selectedPartner && roleCode === 'SUPER_ADMIN') {
      history.push('/thirdPartyCatalog');
    }

    (async () => {
      getOrderActivityRules(dispatch);
      try {
        const currentCartProducts = cart.map(({ errorResponse, poFile, ...rest }) => {
          return rest;
        });
        const voucherCatalogIds = currentCartProducts
          .map(catalogItems => catalogItems.voucherCatalogId, []);
        const updatedCatalog = await getUpdatedCatalog(voucherCatalogIds, selectedPartner);
        const updatedCart = syncCart(currentCartProducts, updatedCatalog);
        if (updatedCart.length < currentCartProducts.length) {
          notificationCont.dispatch({
            type: 'add',
            payload: {
              status: 'error',
              autoHide,
              timeout,
              message: t('msg_codes_ordersDropped_banner'),
              testId: 'error',
            },
          });
        }
        const cartInitialize = updatedCart.map((item) => {
          return { ...item, timezone: item.timezone ? item.timezone : timezones[1] };
        });
        setCartProducts(cartInitialize);
        saveCartToLocalStorage(cartInitialize, userId);
      } catch (error) {
        console.error(error);
      }
      if (localStorage.getItem(`cartPageSize-${userId}`) !== null) {
        setPageSize(Number(localStorage.getItem(`cartPageSize-${userId}`)));
      }
    })();
  }, []);

  useEffect(() => {
    dispatch(loadCartFromStorage(userId));
  }, [location, cartProducts.length]);

  useEffect(() => {
    setCartProducts(cart);
  }, [totalItems, cart]);

  useEffect(() => {
    setTotalItems(cart.length);
  }, [cart.length]);


  useEffect(() => {
    const sortedCartProducts = orderBy(cartProducts, sort.sortBy, sort.sortDir);
    setPagedData(
      sortedCartProducts
        .slice((currentPage - 1) * pageSize, (currentPage - 1) * pageSize + pageSize),
    );
  }, [cartProducts, totalItems, pageSize, currentPage, sort]);

  const updatePageSize = (newSize) => {
    localStorage.setItem(`cartPageSize-${userId}`, newSize);
    setPageSize(newSize);
  };

  const handlePageNavigation = (newPageNumber) => {
    setCurrentPage(newPageNumber);
  };

  const sortCartProducts = useCallback((name) => {
    let { sortDir } = sort;
    const { sortBy } = sort;

    sortDir = name === sortBy
      ? sortDir === 'asc'
        ? 'desc'
        : 'asc'
      : 'asc';

    setSort({
      sortBy: name,
      sortDir,
    });
  }, [sort]);

  const onTrashClick = (id) => {
    dispatch(removeItemFromCart(id));
    const anyFilesAttached = cart.filter(cartItem => cartItem.cartId !== id)
      .some(cartItem => cartItem.poFile && cartItem.poFile.size);

    if (poFileAddedNotSubmitted === true) {
      setPoFileAddedNotSubmitted(anyFilesAttached);
    }
    setPagedData(
      cartProducts.slice(
        (currentPage - 1) * pageSize, (currentPage - 1) * pageSize + pageSize,
      ),
    );
  };

  const onModalCancel = useCallback(() => {
    modalContext.dispatch({ type: MODAL_ACTIONS.HIDE });
  });

  const onCheckboxClick = useCallback(() => {
    setCheckbox(!checkboxState);
  });

  const onSubmitClick = useCallback((storeCartProducts = []) => {
    modalContext.dispatch({ type: MODAL_ACTIONS.HIDE });
    const newCart = cartProducts.map((product) => {
      const matchingProduct = storeCartProducts
        .find(storeProduct => storeProduct.cartId === product.cartId);
      if (matchingProduct) {
        return {
          ...product,
          poFile: matchingProduct.poFile,
        };
      }
      return product;
    });

    setDisableSubmitButton(true);

    submitCart(newCart, notificationCont, selectedPartner).then(async ([failures]) => {
      try {
        const currentCartProducts = cart;
        const remainingCartProducts = filterSuccessfulCartItems(currentCartProducts, failures);
        const voucherCatalogIds = remainingCartProducts
          .map(catalogItems => catalogItems.voucherCatalogId, []);
        const updatedCatalog = await getUpdatedCatalog(voucherCatalogIds, selectedPartner);
        const updatedCart = syncCart(remainingCartProducts, updatedCatalog);
        if (updatedCart.length < remainingCartProducts.length) {
          notificationCont.dispatch({
            type: 'add',
            payload: {
              status: 'error',
              autoHide,
              timeout,
              message: t('msg_codes_ordersDropped_banner'),
              testId: 'error',
            },
          });
        }
        setCartProducts(updatedCart);
        saveCartToLocalStorage(updatedCart, userId);
      } catch (error) {
        console.error(error);
      }
      if (failures.length === 0) {
        poFileValidation.current = true;
        setPoFileAddedNotSubmitted(false);
        poFileValidation.current = false;
        dispatch(emptyCart());
        history.push('/ordersuccess');
      }
    }).finally(() => {
      setDisableSubmitButton(false);
    });
  }, [cartProducts, cart]);

  const renderContent = useCallback(() => {
    return (
      <>
        <span><b>{t('msg_codes_termsAndCon_modal_header')}</b></span>
        <br /> <br />
        <p className="custom-modal-body">
          {t('msg_codes_cart_legalNotice_body_modal')}
        </p>
        <div className="custom-modal-footer">
          <Checkbox
            id="singleCheck0"
            onClick={() => onCheckboxClick()}
            checked={checkboxState}
            label={t('msg_codes_termsandcon_agree')}
          />
          <span className="buttons-toolbar">
            <Button
              type="button"
              link
              onClick={() => onModalCancel()}
            >
              {t('msg_codes_cta_cancel')}
            </Button>
            <Button
              primary
              type="submit"
              onClick={() => onSubmitClick(cart)}
              disabled={!checkboxState}
            >
              {t('msg_codes_submitOrder_cta')}
            </Button>
          </span>
        </div>
      </>
    );
  }, [checkboxState]);

  const modalProps = {
    width: 900,
    hideCancelButton: true,
    onPrimary: onSubmitClick,
    title: t('msg_codes_legalNotice_modal_header'),
    content: renderContent(),
  };

  useEffect(() => {
    if (typeof checkboxState !== 'undefined') {
      modalContext.dispatch({
        type: MODAL_ACTIONS.SHOW,
        payload: <Modal {...modalProps} />,
      });
    }
  }, [checkboxState]);

  const renderNav = useCallback(() => {
    const isReadyForSubmission = cart && cart.length && cart.every((cartItem) => {
      return cartButtonState(
        {},
        {
          isCartRowView,
          cart,
          cartItem,
        },
        orderActivityRules,
      );
    });

    return (
      <div className="cart-header">
        <Container>
          <Layout className="cart-header-layout">
            <Flex colSpan="4">
              <h3>{t('msg_codes_orders_cartnum_header', { number: totalItems })}</h3>
            </Flex>
            <Flex colSpan="6" offset="2" className="button-toolbar">
              <span className="back-to-product">
                <Link to="/thirdPartyCatalog">
                  <Button secondary>
                    {t('msg_codes_orders_backToCodeProdCat_cta')}
                  </Button>
                </Link>
              </span>
              <span className="submit">
                <ModalTrigger
                  id="submit-order"
                  disabled={!isReadyForSubmission || disableSubmitButton}
                  loading={disableSubmitButton}
                  modalProps={{
                    ...modalProps,
                  }}
                >
                  {t('msg_codes_orders_submitApproval_cta')}
                </ModalTrigger>
              </span>
            </Flex>
          </Layout>
        </Container>
      </div>
    );
  }, [
    totalItems,
    cartProducts,
    orderActivityRules,
    checkboxState,
    cart,
    disableSubmitButton,
  ]);

  return (
    <Container>
      {renderNav()}
      <Layout className="cart-note">
        <Flex>
          {t('msg_codes_ordernum_subheader')}
        </Flex>
      </Layout>
      <Layout
        className="cart"
        id="cart"
      >
        <Flex
          className="cart-content"
          id="cart-content"
        >
          <DataTable
            columnTemplate="20% 16% 12% 10% 15% 15% 12%"
            ref={tableRef}
          >
            <TableHeader>
              {CartHeaderColumns.map((column) => {
                return (
                  <TableHeaderCell
                    key={column.id}
                    id={column.value}
                    value={column.value}
                    sortable={column.sortable}
                    stickyHorizontal
                    hasExpandable
                    sort={sort}
                    onClick={sortCartProducts}
                  >
                    {t(column.id)}
                    {typeof column.tooltipCopy !== 'undefined' && (
                      <span className="tooltip-wrapper">
                        <Tooltip
                          id="cart-header-tooltip"
                          className="cart-header-tooltip"
                          position="top"
                          content={t(column.tooltipCopy)}
                        >
                          <Icon>info</Icon>
                        </Tooltip>
                      </span>
                    )}
                  </TableHeaderCell>
                );
              })}
              <TableHeaderCell value="trash" />
            </TableHeader>
            {totalItems === 0
              ? <NoResults />
              : (
                pagedData.map((product) => {
                  return (
                    <CartRow
                      product={product}
                      key={product.cartId}
                      setCartProducts={setCartProducts}
                      setPoFileAddedNotSubmitted={setPoFileAddedNotSubmitted}
                      onTrashClick={onTrashClick}
                      tableRef={tableRef}
                    />
                  );
                })
              )
            }
          </DataTable>
          {totalItems > 10 && (
            <div className="cartPagePaginator">
              <RowDisplay
                currentPage={currentPage}
                totalItems={totalItems}
                pageSizes={[10, 25, 50, 100]}
                onPageSizeChange={updatePageSize}
                initialPageSize={initialPageSize}
              />
              {pageSize < totalItems && (
                <Pagination
                  totalRecords={totalItems}
                  currentPage={currentPage}
                  pageLimit={pageSize}
                  onPageItemClick={handlePageNavigation}
                />
              )}
            </div>
          )}
          <OnNavigateAway
            isBlocked={poFileAddedNotSubmitted}
            history={history}
            location={location}
            onNavigateFunction={() => { dispatch(removePoFiles()); }}
            message="msg_codes_cart_POFile_modalBody"
          />
        </Flex>
      </Layout>
    </Container>
  );
};

export default Cart;
