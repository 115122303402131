export default (feature) => {
  const envConfig = localStorage.getItem('envConfig');

  if (!envConfig) {
    return true;
  }

  const parsedConfig = JSON.parse(envConfig);

  return Object.prototype.hasOwnProperty.call(parsedConfig, feature)
    ? parsedConfig[feature]
    : true;
};
