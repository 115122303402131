export default [
  {
    label: 'Batch ID',
    sortable: true,
    value: 'voucherBatchId',
  },
  {
    label: 'Control Label',
    sortable: true,
    value: 'controlLabel',
  },
  {
    label: 'Purpose',
    sortable: true,
    value: 'voucherPurpose',
  },
  {
    label: 'Start Date',
    sortable: true,
    value: 'startDate',
  },
  {
    label: 'End Date',
    sortable: true,
    value: 'endDate',
  },
  {
    label: 'Last Updated',
    sortable: true,
    value: 'modifyDate',
  },
  {
    label: 'Status',
    sortable: true,
    value: 'voucherStatus',
  },
  {
    label: '',
    sortable: false,
  },
];
