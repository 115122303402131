import _ from 'lodash';

const agreementProps = {
  baseProps: ['agreementId', 'agreementVersions', 'partner', 'partnerId', 'region', 'status', 'agreementType'],
  version: [
    'agreementVersionComments',
    'agreementVersionEffectiveDate',
    'agreementVersionId',
    'agreementId',
    'invoiceTiming',
    'versionStatus',
    'voucherProductTypeCoverageSet',
    'voucherRedemptionCallbackGateway',
    'voucherRedemptionCallbackMandatory',
  ],
  voucherProductTypeCoverageSet: [
    'agreementVersionId',
    'feeStructure',
    'voucherProductType',
    'voucherProductTypeCoverageId',
  ],
  feeStructure: [
    'commissionRate',
    'country',
    'feeStructureId',
    'settlementCurrency',
    'feeStructureType',
    'voucherProductTypeCoverageId',
    'orderPurpose',
    'rechargeAmount',
    'rechargeAmountCurrency',
    'membershipDuration',
    'membershipDurationUnits',
  ],
};
export function pickFeeStructure(feeStructure) {
  return _.pick(feeStructure, agreementProps.feeStructure);
}
export function pickVoucherProductTypeCoverageSet(voucherProductTypeCoverageSet) {
  const pickedVoucherTypeCoverageSet = _.pick(
    voucherProductTypeCoverageSet,
    agreementProps.voucherProductTypeCoverageSet,
  );
  if (pickedVoucherTypeCoverageSet.feeStructure) {
    pickedVoucherTypeCoverageSet.feeStructure = pickedVoucherTypeCoverageSet
      .feeStructure.map(feeStructure => pickFeeStructure(feeStructure));
  }
  return pickedVoucherTypeCoverageSet;
}
export function pickVersion(version) {
  const pickedVersion = _.pick(version, agreementProps.version);
  if (pickedVersion.voucherProductTypeCoverageSet) {
    pickedVersion.voucherProductTypeCoverageSet = pickedVersion.voucherProductTypeCoverageSet
      .map(coverageSet => pickVoucherProductTypeCoverageSet(coverageSet));
  }
  return pickedVersion;
}
function pickAgreementObject(agreementToPick) {
  try {
    const pickedAgreement = _.pick(agreementToPick, agreementProps.baseProps);
    pickedAgreement.agreementVersions = pickedAgreement
      .agreementVersions.map(version => pickVersion(version));
    return pickedAgreement;
  } catch (error) {
    return agreementToPick;
  }
}
export default pickAgreementObject;
