import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function removedOrderLines(state = initialState.removedOrderLines, action) {
  const { lineItems, removedLineItems } = action;

  switch (action.type) {
    case types.ADD_REMOVED_ORDER_LINES_TO_CACHE:
      return lineItems;
    case types.LOAD_ORDER_LINE_CACHE:
      return removedLineItems;
    default:
      return state;
  }
}
