function isEmpty(value) {
  return (value == null || value.length === 0);
}

export function isVoucherBatchValid(voucherBatch) {
  const {
    voucherPurposeId,
    voucherStatusId,
    distributorId,
    controlLabel,
    batchDescription,
    startDate,
    endDate,
    quantity,
    useCount,
  } = voucherBatch;
  return ![voucherPurposeId, voucherStatusId, distributorId, controlLabel,
    batchDescription, startDate, endDate, quantity, useCount].some(value => isEmpty(value))
      && Number(quantity) > 0 && Number(quantity) <= 900000
      && Number(useCount) > 0 && Number(useCount) <= 1000000;
}

export default isVoucherBatchValid;
