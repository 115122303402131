import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function loadingAgreementsReducer(state = initialState.loadingUsers, action) {
  if (action.type === types.BEGIN_LOAD_AGREEMENTS) {
    return true;
  }
  if (action.type === types.LOAD_AGREEMENTS_SUCCESS) {
    return false;
  }
  if (action.type === types.LOAD_AGREEMENTS_ERROR) {
    return false;
  }

  return state;
}
