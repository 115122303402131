import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function submittingOrderReducer(state = initialState.submittingOrder, action) {
  if (action.type === types.BEGIN_SUBMIT_ORDER) {
    return true;
  } if (action.type === types.SUBMIT_ORDER_SUCCESS) {
    return false;
  }

  return state;
}
