import axios from 'axios';
import * as types from './actionTypes';

export function beginLoadUsers() {
  return { type: types.BEGIN_LOAD_USERS };
}

export function finishLoadUsers(users) {
  return { type: types.FINISH_LOAD_USERS, users };
}

export function finishLoadPartnerUsers(users) {
  return { type: types.FINISH_LOAD_PARTNER_USERS, users };
}

export function setUsersPage(page) {
  return { type: types.SET_USER_LIST_PAGE, page };
}

export function resetFilters() {
  return { type: types.RESET_USERS_FILTERING };
}

export function setFilters(filterName, filterValue) {
  return {
    type: types.SET_USERS_FILTER,
    filterName,
    filterValue,
  };
}

export function setUsersSearch(search) {
  return { type: types.SET_USERS_SEARCH, search };
}

export function clearUsersSearch() {
  return { type: types.CLEAR_USERS_SEARCH };
}

export function resetUsersPage() {
  return { type: types.RESET_USERS_PAGE };
}

export function loadUsers(
  size = 50,
  page = 0,
  sort = { name: 'firstName', order: 'asc' },
  filters = { creationDate: [] },
) {
  const sortParam = `?sort=${sort.name},${sort.order}`;
  const pageParam = `page=${page}`;
  const sizeParam = `size=${size}`;
  const startDate = filters.creationDate[0] ? `createdStartDate=${filters.creationDate[0]}&` : '';
  const endDate = filters.creationDate[1] ? `createdEndDate=${filters.creationDate[1]}` : '';
  const filtersParams = Object.keys(filters)
    .filter(key => filters[key] !== '' && key !== 'creationDate')
    .map(key => `${key}=${filters[key].value}`).join('&');
  const url = `/venom/api/users${sortParam}&${pageParam}&${sizeParam}&${filtersParams}&${startDate}${endDate}`;
  const { name: sortName, order: sortOrder } = sort;
  return (dispatch) => {
    dispatch(beginLoadUsers());
    return axios.get(url)
      .then((response) => {
        return response.data;
      }).then(({ content, ...rest }) => {
        dispatch(finishLoadUsers(content));
        dispatch({
          type: 'LOAD_USER_LIST_SUCCESS',
        });
        dispatch(setUsersPage({
          number: rest.number,
          size: rest.size,
          totalElements: rest.totalElements,
          sort: sortName,
          sortOrder,
        }));
      }).catch((error) => {
        // TODO: error handling
        console.error(error);
      });
  };
}


export function loadPartnerUsers(
  partnerId, page = 0, size = 10, sort = { name: 'lastName', order: 'asc' },
) {
  const sortParam = `${sort.name},${sort.order}`;
  const queryParams = `?page=${page}&size=${size}&sort=${sortParam}`;
  return (async (dispatch) => {
    dispatch(beginLoadUsers());
    const users = await axios.get(`/venom/api/partners/${partnerId}/users${queryParams}`)
      .then(response => response.data);
    const { content, ...rest } = users;
    dispatch(finishLoadPartnerUsers(content));
    dispatch({
      type: types.SET_USERS_PAGE,
      usersPage: {
        page: rest,
      },
    });
  });
}
