import React from 'react';
import {
  Link,
  useLocation,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  TableRow,
  TableCell,
  Tag,
  Truncate,
} from '@partner-global-ui/components';
import * as agreementTypes from '../../constants/agreementType.constants';
import * as voucherProductTypes from '../../constants/voucherProductTypes.constants';
import {
  AgreementStatusStringMapping,
  AgreementTagStatuses,
} from '../../constants/agreementState.constants';
import getRegionName from '../../utils/region.utils';

const AgreementRow = ({
  agreement,
  rowIndex,
  tableRef,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;

  const {
    agreementId,
    agreementType,
    numberOfFeeStructuresWithCountries,
    partner: {
      partnerId,
      name: partnerName,
    },
    region,
    status,
    voucherProductType,
  } = agreement;

  const agreementTypeName = t(agreementTypes[agreementType]);

  const displaySIERegion = {
    [true]: () => t(getRegionName(region)),
    [region === 'ALL']: () => t('msg_codes_filter_all'),
    [agreementType === 'THIRD_PARTY_CONTRACTUAL']: () => t('msg_codes_sieRegion_notApplicable'),
  }.true();

  const displayCountryCoverage = {
    [true]: () => numberOfFeeStructuresWithCountries,
    [numberOfFeeStructuresWithCountries === 'ALL']: () => t('msg_codes_filter_all'),
    [agreementType === 'THIRD_PARTY_CONTRACTUAL']: () => t('msg_codes_sieRegion_notApplicable'),
  }.true();

  const displayVoucherProducts = voucherProductType.length === 0
    ? t('msg_codes_no_product_types_covered_zero')
    : voucherProductType
      .map((productType) => {
        const type = voucherProductTypes[productType];

        return typeof type === 'undefined'
          ? productType
          : t(type.stringId);
      })
      .join(', ');


  // Render
  return (
    <TableRow id={rowIndex}>
      <TableCell id="agreement-id" className="first-column agreement-id-column">
        <Link data-testid="agreement-link" to={`/agreement/${agreementId}?return=${pathname}`}>
          {agreementId}
        </Link>
      </TableCell>
      <TableCell id="partner" className="partner-column">
        <Truncate id="partner-truncate" content={partnerName} anchor={tableRef}>
          <Link data-testid="partner-link" to={`/partner/${partnerId}`}>
            {partnerName}
          </Link>
        </Truncate>
      </TableCell>
      <TableCell id="agreement-type" className="agreement-type-column">
        {agreementTypeName}
      </TableCell>
      <TableCell id="region" className="region-column">
        {displaySIERegion}
      </TableCell>
      <TableCell id="country-coverage" className="country-coverage-column">
        {displayCountryCoverage}
      </TableCell>
      <TableCell id="voucher-product-type-coverage" className="voucher-product-type-coverage-column">
        {displayVoucherProducts}
      </TableCell>
      <TableCell id="status" className="status-column">
        <Tag
          id={`status-tag-${agreementId}`}
          status={AgreementTagStatuses[status]}
          iconOnly
        />
        <span data-testid={`status-label-${agreementId}`}>
          {t(AgreementStatusStringMapping[status])}
        </span>
      </TableCell>
    </TableRow>
  );
};

AgreementRow.propTypes = {
  agreement: PropTypes.object,
  rowIndex: PropTypes.number,
  tableRef: PropTypes.object,
};

AgreementRow.defaultProps = {
  agreement: {},
  rowIndex: undefined,
  tableRef: {},
};

export default AgreementRow;
