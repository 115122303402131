import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default (state = initialState.loadingPartner, { type }) => {
  switch (type) {
    case types.LOAD_PARTNER_PENDING:
      return true;
    case types.LOAD_PARTNER_SUCCESS:
    case types.LOAD_PARTNER_REJECTED:
      return false;

    default:
      return state;
  }
};
