import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import getFeature from './accessControl/getFeature';
import EN from '../locales/en_US';
import JP from '../locales/ja_JP';

const i18nInstance = i18n
  .use(LanguageDetector)
  .use(initReactI18next);

i18nInstance.init({
  // we init with resources
  resources: {
    en_US: { translation: { ...EN } },
    ja_JP: { translation: { ...JP } },
  },
  debug: getFeature('i18nDebug'),
  interpolation: {
    skipOnVariables: true,
  },
  fallbackLng: 'dev',
  // have a common namespace used around the full app
  ns: ['translation'],
  defaultNS: 'translation',
  keySeparator: false,
  detection: {
    order: ['localStorage'],
  },
});

export default i18nInstance;
