import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Collapsible,
  TableHeader,
  TableHeaderCell,
  DataTable,
  NoResults,
  Loader,
} from '@partner-global-ui/components';
import SFTP from './SFTP';
import SFTPHeaderColumns from './TableHeaderColumns';
import './SFTPPartner.scss';

const PartnerDetailSFTP = ({ sftpList }) => {
  const { t } = useTranslation();
  // Global State
  const loadingSftps = useSelector(state => state.loadingSftps);

  const mappedSftps = sftpList.map(sftp => <SFTP key={sftp.sftpId} sftp={sftp} />);

  const renderLoadingMessage = () => {
    return (
      <div
        name="partner-detail-sftp-grid"
        className="partner-detail-sftp-grid"
      >
        <div
          name="partner-detail-sftp-row"
          className="partner-detail-sftp-row"
        >
          <div
            name="partner-detail-sftp-col"
            className="partner-detail-sftp-col"
            sm={12}
          >
            <Loader message="" className="sftps-loader" />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Collapsible
      heading={t('msg_codes_partners_outboundSFTPs')}
      scrollHeight={368}
      defaultIsOpen
    >
      <div
        name="partner-detail-sftp-grid"
        className="partner-detail-sftp-grid"
      >
        <div
          name="partner-detail-sftp-row"
          className="partner-detail-sftp-row"
          style={{ minHeight: mappedSftps.length * 41 }}
        >
          <div
            name="partner-detail-sftp-col"
            className="partner-detail-sftp-col"
            sm={12}
          >
            {loadingSftps
              ? renderLoadingMessage()
              : (
                <div style={{ flexDirection: 'column', width: '100%' }}>
                  <DataTable
                    columnTemplate="repeat(4, 1fr)"
                    scrollHeight={368}
                  >
                    <TableHeader>
                      {SFTPHeaderColumns.map((column) => {
                        return (
                          <TableHeaderCell
                            key={column.id}
                            id={column.id}
                            value={column.value}
                            className={`header-cell-${column.value}`}
                          >
                            {t(column.label)}
                          </TableHeaderCell>
                        );
                      })}
                    </TableHeader>
                    {sftpList.length === 0
                      ? (
                        <NoResults
                          id="no-sftps"
                          title={t('msg_codes_codes_noResultsFound')}
                          message=""
                        />
                      )
                      : mappedSftps}
                  </DataTable>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </Collapsible>
  );
};

PartnerDetailSFTP.propTypes = {
  sftpList: PropTypes.array,
};

PartnerDetailSFTP.defaultProps = {
  sftpList: [],
};

export default PartnerDetailSFTP;
