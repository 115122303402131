import React, { useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Collapsible,
  DataTable,
  NoResults,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  NotificationContext,
} from '@partner-global-ui/components';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import * as actions from '../../actions/voucherBatchDetailActions';
import { formatDateTimeInLocalWithUTC } from '../../utils/formatDateTime';


const actionTypes = {
  Created: 'msg_codes_common_created',
  Updated: 'msg_codes_updated',
  Active: 'msg_codes_active',
  Inactive: 'msg_codes_agreementStatus_inactive',
  Deactivated: 'msg_codes_codes_status_deactivated',
  'Active - Partially Deactivated': 'msg_codes_active_partially_deactivated',
  'Inactive - Partially Deactivated': 'msg_codes_inactive_partially_deactivated',
  'Requested File Generation': 'msg_codes_requested_file_generation',
  Download: 'msg_codes_cta_download',
};

const VoucherBatchAuditHistory = ({
  voucherBatch: {
    voucherBatchId,
    activeQuantity,
    cancelledQuantity,
    inactiveQuantity,
    state: voucherBatchState,
    downloadUrl,
  },
}) => {
  const { t } = useTranslation();
  const notificationCont = useContext(NotificationContext);

  const {
    content: auditHistoryList,
  } = useSelector(state => state.voucherBatchAuditHistory);
  const loadingAuditHistory = useSelector(state => state.loadingVoucherBatchAuditHistory);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(actions.resetAuditHistory());
  }, []);

  useEffect(() => {
    if (voucherBatchId) {
      dispatch(actions.fetchAuditHistory(notificationCont, voucherBatchId));
    }
  }, [
    voucherBatchId,
    activeQuantity,
    cancelledQuantity,
    inactiveQuantity,
    voucherBatchState,
    downloadUrl,
  ]);

  // labels for table headers
  const tableHeaders = [
    { label: t('msg_codes_common_date') },
    { label: t('msg_codes_agreements_agreementStatus') },
    { label: t('msg_codes_orderDetails_by') },
  ];

  const renderHeaderCells = () => {
    return tableHeaders.map(({ label }, headerCellIndex) => {
      const headerKey = headerCellIndex + 1;
      return (
        <TableHeaderCell
          id={headerKey}
          key={headerKey}
          value={label}
          className={label}
        >
          {label}
        </TableHeaderCell>
      );
    });
  };

  const renderTableBody = () => {
    return auditHistoryList.map(data => (
      <TableRow
        key={data.id}
        className="voucher-batch-audit-history-row"
        id="voucher-batch-audit-history-row"
      >
        <TableCell
          className="cell"
          id="cell-date"
        >
          {formatDateTimeInLocalWithUTC(data.creationDate)}
        </TableCell>
        <TableCell
          className="cell"
          id="cell-status"
        >
          {t(actionTypes[data.actionType])}
        </TableCell>
        <TableCell
          className="cell"
          id="cell-by"
        >
          {data.email}
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <>
      {
        isEmpty(auditHistoryList)
          ? null
          : (
            <div
              className="batch-audit-history"
              data-testid="batch-audit-history"
            >
              <Collapsible
                defaultIsOpen
                heading={t('msg_codes_agreements_history')}
                id="batch-audit-history-collapsible"
                hideBorder
              >
                <DataTable
                  columnTemplate="40% 30% 40%"
                  id="batch-audit-history-table"
                  loading={loadingAuditHistory}
                >
                  <TableHeader id="batch-audit-history-table-header">
                    {renderHeaderCells()}
                  </TableHeader>
                  {auditHistoryList.length
                    ? renderTableBody()
                    : <NoResults id="batch-audit-history-table-no-results" />
                  }
                </DataTable>
              </Collapsible>
            </div>
          )
      }
    </>
  );
};

VoucherBatchAuditHistory.propTypes = {
  voucherBatch: PropTypes.object,
};

VoucherBatchAuditHistory.defaultProps = {
  voucherBatch: {},
};

export default VoucherBatchAuditHistory;
