import moment from 'moment';
import initialState from './initialState';
import * as types from '../actions/actionTypes';
import ordersHistoryStatus from '../utils/ordersHistoryStatus';
import * as scanStatuses from '../constants/scanStatus.constants';
import { isTimeMatched } from '../utils/formatDateTime';

const fileScanUploadRequirement = (scanStatus, isPoFileUpdated = false) => {
  return scanStatus === scanStatuses.FAILED && !isPoFileUpdated;
};

const updateCatalogProduct = (catalogs, updatedProduct) => {
  const updatedCatalogs = catalogs.map((product) => {
    return product.orderId === updatedProduct.orderId
      ? {
        ...product,
        ...updatedProduct,
        ctaStatus: ordersHistoryStatus[updatedProduct.orderStatus],
      } : product;
  });

  return updatedCatalogs;
};

const updatedOrderHistoryPO = (
  orderHistoryCatalog,
  orderId,
  poNumber,
) => {
  const updatedCatalogs = orderHistoryCatalog.map((order) => {
    if (order.orderId === orderId) {
      if (poNumber !== '') {
        return {
          ...order,
          isPoNumberUpdated: true,
          poNumber,
          ctaStatus: {
            ...order.ctaStatus,
            buttonDisabled: fileScanUploadRequirement(
              order.poFileScanStatus,
              order.isPoFileUpdated,
            ),
            buttonStyle: 'primary',
            buttonText: 'msg_codes_common_submit',
            showButton: true,
          },
        };
      }
      return {
        ...order,
      };
    }
    return order;
  });

  return updatedCatalogs;
};

const updatedOrderHistoryPOFile = (
  orderHistoryCatalog,
  orderId,
  fileType,
  poFile,
) => {
  const updatedCatalogs = orderHistoryCatalog.map((order) => {
    if (order.orderId === orderId) {
      if (poFile) {
        const updatedFileData = {
          orderId,
          name: poFile.name,
          size: poFile.size,
          type: fileType,
          file: poFile.file,
        };
        return {
          ...order,
          isPoFileUpdated: true,
          poFile: updatedFileData,
          ctaStatus: {
            ...order.ctaStatus,
            buttonDisabled: false,
            buttonStyle: 'primary',
            buttonText: 'msg_codes_common_submit',
            showButton: true,
          },
        };
      }
      return {
        ...order,
      };
    }
    return order;
  });

  return updatedCatalogs;
};

const updatedOrderHistoryQuantity = (
  orderHistoryCatalog,
  orderId,
  quantity,
) => {
  const updatedCatalogs = orderHistoryCatalog.map((order) => {
    if (order.orderId === orderId) {
      if (quantity !== '') {
        return {
          ...order,
          isQuantityUpdated: true,
          quantity: Number(quantity),
          ctaStatus: {
            ...order.ctaStatus,
            buttonDisabled: fileScanUploadRequirement(
              order.poFileScanStatus,
              order.isPoFileUpdated,
            ),
            buttonStyle: 'primary',
            buttonText: 'msg_codes_common_submit',
            showButton: true,
          },
        };
      }
      return {
        ...order,
        ctaStatus: {
          ...order.ctaStatus,
          buttonDisabled: true,
          buttonStyle: 'link',
          buttonText: 'msg_codes_orders_updateReq',
          showButton: true,
        },
      };
    }
    return order;
  });

  return updatedCatalogs;
};

const updatedOrderHistoryUseCount = (
  orderHistoryCatalog,
  orderId,
  useCount,
) => {
  const updatedCatalogs = orderHistoryCatalog.map((order) => {
    if (order.orderId === orderId) {
      if (useCount !== '') {
        return {
          ...order,
          isUseCountUpdated: true,
          useCount: Number(useCount),
          ctaStatus: {
            ...order.ctaStatus,
            buttonDisabled: fileScanUploadRequirement(
              order.poFileScanStatus,
              order.isPoFileUpdated,
            ),
            buttonStyle: 'primary',
            buttonText: 'msg_codes_common_submit',
            showButton: true,
          },
        };
      }
      return {
        ...order,
        ctaStatus: {
          ...order.ctaStatus,
          buttonDisabled: true,
          buttonStyle: 'link',
          buttonText: 'msg_codes_orders_updateReq',
          showButton: true,
        },
      };
    }
    return order;
  });

  return updatedCatalogs;
};

export const updateOrderHistoryComments = (
  orderHistoryCatalog,
  orderId,
  partnerComments,
) => {
  const updatedCatalogs = orderHistoryCatalog.map((order) => {
    if (order.orderId === orderId) {
      if (partnerComments !== '') {
        return {
          ...order,
          isCommentsUpdated: true,
          partnerComments,
          ctaStatus: {
            ...order.ctaStatus,
            buttonDisabled: fileScanUploadRequirement(
              order.poFileScanStatus,
              order.isPoFileUpdated,
            ),
            buttonStyle: 'primary',
            buttonText: 'msg_codes_common_submit',
            showButton: true,
          },
        };
      }
      return {
        ...order,
        ctaStatus: {
          ...order.ctaStatus,
          buttonDisabled: true,
          buttonStyle: 'link',
          buttonText: 'msg_codes_orders_updateReq',
          showButton: true,
        },
      };
    }
    return order;
  });

  return updatedCatalogs;
};

const checkDate = (product) => {
  const {
    startDate, startTime, endDate, endTime,
  } = product;
  let check = false;

  const start = {
    date: moment(startDate, 'YYYY/MM/DD').format('YYYY-MM-DD'),
    time: startTime ? moment(startTime, 'hh:mm a').format('HH:mm:ss') : '12:00:00',
  };

  const end = {
    date: moment(endDate, 'YYYY/MM/DD').format('YYYY-MM-DD'),
    time: endTime ? moment(endTime, 'hh:mm a').format('HH:mm:ss') : '12:00:00',
  };

  const dateString = set => `${set.date}T${set.time}`;

  const parsedStartDate = moment(dateString(start));
  const parsedEndDate = moment(dateString(end));

  if (
    parsedStartDate.isValid()
      ? !parsedEndDate.isValid()
      : parsedEndDate.isValid()) {
    return true;
  }

  if (parsedStartDate.isBefore(parsedEndDate, 'minute')) {
    check = true;
  }

  return check;
};

const updatedOrderHistoryStartDate = (
  orderHistoryCatalog,
  orderId,
  startDate,
) => {
  const updatedCatalogs = orderHistoryCatalog.map((order) => {
    if (order.orderId === orderId) {
      const isValid = startDate && order.endDate ? checkDate({
        startDate,
        startTime: order.startTime,
        endDate: order.endDate,
        endTime: order.endTime,
      }) : true;
      if (moment.utc(order.startDate).isSame(moment(startDate), 'day')) {
        return order;
      }
      return {
        ...order,
        isStartDateUpdated: true,
        startDate: moment.utc(startDate).format(),
        ctaStatus: {
          ...order.ctaStatus,
          buttonDisabled: fileScanUploadRequirement(
            order.poFileScanStatus,
            order.isPoFileUpdated,
          ) && !isValid,
          buttonStyle: 'primary',
          buttonText: 'msg_codes_common_submit',
          showButton: true,
        },
      };
    }
    return order;
  });

  return updatedCatalogs;
};

export const updatedOrderHistoryStartTime = (
  orderHistoryCatalog,
  orderId,
  startTime,
) => {
  const updatedCatalogs = orderHistoryCatalog.map((order) => {
    if (order.orderId === orderId) {
      const isValid = checkDate({
        startDate: order.startDate,
        startTime,
        endDate: order.endDate,
        endTime: order.endTime,
      });
      const time = moment(startTime, 'hh:mm a');
      const newStartDate = time.isValid()
        ? moment.utc(order.startDate).hour(time.hour()).minute(time.minute())
        : moment.utc(order.startDate);
      if (isTimeMatched(order.startDate, startTime)) {
        return order;
      }
      return {
        ...order,
        isStartTimeUpdated: true,
        startDate: newStartDate,
        startTime: time.format('hh:mm A'),
        ctaStatus: {
          ...order.ctaStatus,
          buttonDisabled: fileScanUploadRequirement(
            order.poFileScanStatus,
            order.isPoFileUpdated,
          ) && !isValid,
          buttonStyle: 'primary',
          buttonText: 'msg_codes_common_submit',
          showButton: true,
        },
      };
    }
    return order;
  });

  return updatedCatalogs;
};


export const updatedOrderHistoryEndDate = (
  orderHistoryCatalog,
  orderId,
  endDate,
) => {
  const updatedCatalogs = orderHistoryCatalog.map((order) => {
    if (order.orderId === orderId) {
      const isValid = order.startDate && endDate ? checkDate({
        startDate: order.startDate,
        startTime: order.startTime,
        endDate,
        endTime: order.endTime,
      }) : true;
      if (moment.utc(order.endDate).isSame(moment.utc(endDate), 'day')) {
        return order;
      }
      return {
        ...order,
        isEndDateUpdated: true,
        endDate: moment.utc(endDate).format(),
        ctaStatus: {
          ...order.ctaStatus,
          buttonDisabled: fileScanUploadRequirement(
            order.poFileScanStatus,
            order.isPoFileUpdated,
          ) && !isValid,
          buttonStyle: 'primary',
          buttonText: 'msg_codes_common_submit',
          showButton: true,
        },
      };
    }
    return order;
  });

  return updatedCatalogs;
};

export const updatedOrderHistoryEndTime = (
  orderHistoryCatalog,
  orderId,
  endTime,
) => {
  const updatedCatalogs = orderHistoryCatalog.map((order) => {
    if (order.orderId === orderId) {
      const isValid = checkDate({
        startDate: order.startDate,
        startTime: order.startTime,
        endDate: order.endDate,
        endTime,
      });
      const time = moment(endTime, 'hh:mm a');
      const newEndDate = time.isValid()
        ? moment.utc(order.endDate).hour(time.hour()).minute(time.minute())
        : moment.utc(order.endDate);
      if (isTimeMatched(order.endDate, endTime)) {
        return order;
      }
      return {
        ...order,
        isEndTimeUpdated: true,
        endDate: newEndDate,
        endTime: time.format('hh:mm A'),
        ctaStatus: {
          ...order.ctaStatus,
          buttonDisabled: fileScanUploadRequirement(
            order.poFileScanStatus,
            order.isPoFileUpdated,
          ) && !isValid,
          buttonStyle: 'primary',
          buttonText: 'msg_codes_common_submit',
          showButton: true,
        },
      };
    }
    return order;
  });

  return updatedCatalogs;
};


const setOrderHistoryCatalog = (
  orderHistoryCatalog,
) => {
  const updatedCatalogs = orderHistoryCatalog.map((order) => {
    const { orderStatus } = order;
    return {
      ...order,
      ctaStatus: ordersHistoryStatus[orderStatus],
    };
  });
  return updatedCatalogs;
};

export const setOrderHistoryPendingResponse = (
  orderHistoryCatalog,
  orderId,
) => {
  const updatedCatalogs = orderHistoryCatalog.map((localOrder) => {
    if (localOrder.orderId === orderId) {
      return {
        ...localOrder,
        pending: true,
      };
    }
    return localOrder;
  });
  return updatedCatalogs;
};

export const setOrderHistoryPendingResponseCleared = (
  orderHistoryCatalog,
  orderId,
) => {
  const updatedCatalogs = orderHistoryCatalog.map((localOrder) => {
    if (localOrder.orderId === orderId) {
      return {
        ...localOrder,
        pending: false,
      };
    }
    return localOrder;
  });
  return updatedCatalogs;
};


export const setOrderHistoryOnSubmission = (
  orderHistoryCatalog,
  order,
) => {
  const updatedCatalogs = orderHistoryCatalog.map((localOrder) => {
    if (localOrder.orderId === order.orderId) {
      const { orderStatus } = order;
      return {
        ...order,
        ctaStatus: ordersHistoryStatus[orderStatus],
        pending: false,
      };
    }
    return localOrder;
  });
  return updatedCatalogs;
};

const updateOrderTimeZone = (
  catalog,
  id,
  timeZoneValue,
) => {
  const updatedCatalogs = catalog.map((order) => {
    return {
      ...order,
      timezone: order.orderId === id ? timeZoneValue : order.timezone,
    };
  });
  return updatedCatalogs;
};

const updateOrderDownloadError = (
  catalog,
  id,
  error,
) => {
  const updatedCatalogs = catalog.map((order) => {
    return {
      ...order,
      downloadError: order.orderId === id ? error : order.downloadError,
    };
  });
  return updatedCatalogs;
};

const updateOrderRetryError = (
  catalog,
  id,
  error,
) => {
  const updatedCatalogs = catalog.map((order) => {
    return {
      ...order,
      retryError: order.orderId === id ? error : order.retryError,
    };
  });
  return updatedCatalogs;
};

export default (state = initialState.orderHistory, action) => {
  const {
    orderHistoryCatalog,
    product,
    orderId,
    quantity,
    useCount,
    order,
    startDate,
    startTime,
    endDate,
    endTime,
    timeZone,
    poNumber,
    poFile,
    fileType,
    error,
    partnerComments,
  } = action;
  switch (action.type) {
    case types.BEGIN_LOAD_ORDER_HISTORY:
      return {
        ...state,
        loadingOrderHistory: true,
      };
    case types.SET_ORDER_HISTORY_CATALOG:
      return {
        ...state,
        orderHistoryCatalog: setOrderHistoryCatalog(orderHistoryCatalog),
      };
    case types.FINISH_LOAD_ORDER_HISTORY:
      return {
        ...state,
        loadingOrderHistory: false,
      };
    case types.ORDER_HISTORY_UPDATE_PRODUCT:
      return {
        ...state,
        orderHistoryCatalog: updateCatalogProduct(state.orderHistoryCatalog, product),
      };
    case types.UPDATE_ORDER_QUANTITY:
      return {
        ...state,
        orderHistoryCatalog: updatedOrderHistoryQuantity(
          state.orderHistoryCatalog,
          orderId,
          quantity,
        ),
      };
    case types.UPDATE_ORDER_USE_COUNT:
      return {
        ...state,
        orderHistoryCatalog: updatedOrderHistoryUseCount(
          state.orderHistoryCatalog,
          orderId,
          useCount,
        ),
      };
    case types.UPDATE_ORDER_COMMENTS:
      return {
        ...state,
        orderHistoryCatalog: updateOrderHistoryComments(
          state.orderHistoryCatalog,
          orderId,
          partnerComments,
        ),
      };
    case types.UPDATE_ORDER_PO_NUMBER:
      return {
        ...state,
        orderHistoryCatalog: updatedOrderHistoryPO(
          state.orderHistoryCatalog,
          orderId,
          poNumber,
        ),
      };
    case types.UPDATE_ORDER_PO_FILE:
      return {
        ...state,
        orderHistoryCatalog: updatedOrderHistoryPOFile(
          state.orderHistoryCatalog,
          orderId,
          fileType,
          poFile,
        ),
      };
    case types.UPDATE_ORDER_START_DATE:
      return {
        ...state,
        orderHistoryCatalog: updatedOrderHistoryStartDate(
          state.orderHistoryCatalog,
          orderId,
          startDate,
        ),
      };
    case types.UPDATE_ORDER_START_TIME:
      return {
        ...state,
        orderHistoryCatalog: updatedOrderHistoryStartTime(
          state.orderHistoryCatalog,
          orderId,
          startTime,
        ),
      };
    case types.UPDATE_ORDER_END_DATE:
      return {
        ...state,
        orderHistoryCatalog: updatedOrderHistoryEndDate(
          state.orderHistoryCatalog,
          orderId,
          endDate,
        ),
      };
    case types.UPDATE_ORDER_END_TIME:
      return {
        ...state,
        orderHistoryCatalog: updatedOrderHistoryEndTime(
          state.orderHistoryCatalog,
          orderId,
          endTime,
        ),
      };
    case types.ORDER_HISTORY_PENDING_RESPONSE:
      return {
        ...state,
        orderHistoryCatalog:
          setOrderHistoryPendingResponse(state.orderHistoryCatalog, order.orderId),
      };
    case types.ORDER_HISTORY_PENDING_RESPONSE_CLEARED:
      return {
        ...state,
        orderHistoryCatalog:
          setOrderHistoryPendingResponseCleared(state.orderHistoryCatalog, order.orderId),
      };
    case types.ORDER_HISTORY_ERROR_FROM_SUBMISSION:
      return {
        ...state,
        orderHistoryCatalog:
        setOrderHistoryPendingResponseCleared(state.orderHistoryCatalog, order.orderId),
      };
    case types.ORDER_HISTORY_UPDATE_FROM_SUBMISSION:
      return {
        ...state,
        orderHistoryCatalog: setOrderHistoryOnSubmission(state.orderHistoryCatalog, order),
      };
    case types.UPDATE_ORDER_TIME_ZONE:
      return {
        ...state,
        orderHistoryCatalog: updateOrderTimeZone(
          state.orderHistoryCatalog,
          orderId,
          timeZone,
        ),
      };
    case types.UPDATE_ORDER_DOWNLOAD_ERROR:
      return {
        ...state,
        orderHistoryCatalog: updateOrderDownloadError(
          state.orderHistoryCatalog,
          orderId,
          error,
        ),
      };
    case types.UPDATE_ORDER_RETRY_ERROR:
      return {
        ...state,
        orderHistoryCatalog: updateOrderRetryError(
          state.orderHistoryCatalog,
          orderId,
          error,
        ),
      };
    default:
      return state;
  }
};
