import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Collapsible,
  Container,
  Divider,
  Layout,
  Flex,
} from '@partner-global-ui/components';
import moment from 'moment';
import PropTypes from 'prop-types';
import partnerTypes from '../../constants/integratorPartnerTypes.constants';
import StaticText from '../common/StaticText';

const PartnerInfo = (props) => {
  const {
    partner: {
      name,
      partnerType,
      gpid,
      creationDate,
    },
  } = props;

  const { t } = useTranslation();

  const placeholderPartnerName = 'PLACE_HOLDER_PARTNER_NAME';

  const updatedPartnerTypes = [
    { label: t('msg_codes_partnerType_integrator'), value: partnerTypes.INTEGRATOR },
    { label: t('msg_codes_partnerType_thirdParty'), value: partnerTypes.THIRD_PARTY },
  ];

  const selectedPartnerType = updatedPartnerTypes.filter(type => type.value === partnerType)[0];
  const accountCreated = moment(creationDate).format('MM/DD/YYYY');

  return (
    <Collapsible
      id="partner-profile"
      heading={t('msg_codes_partners_partnerProfile')}
      rightContent={`${t(
        'msg_codes_users_addedOnDate',
        {
          date: accountCreated,
          interpolation: {
            escapeValue: false,
          },
        },
      )}`}
      defaultIsOpen
    >
      <Container className="partner-profile">
        <Layout style={{ margin: '60px 0px' }}>
          <Flex colSpan={12} style={{ display: 'flex', width: '100%' }}>
            <Flex colSpan={5}>
              {name !== placeholderPartnerName
                && <StaticText label={t('msg_codes_partners_partnerName_label')} value={name} testId="partner-name" />
              }
              <StaticText label={t('msg_codes_partners_partnerType_label')} value={selectedPartnerType && selectedPartnerType.label} testId="partner-type" />
            </Flex>

            <Flex colSpan={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Divider secondary vertical />
            </Flex>

            <Flex colSpan={6} style={{ paddingLeft: '40px' }}>
              {gpid
                && <StaticText label={t('msg_codes_partners_GPID')} value={gpid} testId="partner-gpid" />
              }
            </Flex>
          </Flex>
        </Layout>
      </Container>
    </Collapsible>
  );
};

PartnerInfo.propTypes = {
  partner: PropTypes.object,
};

PartnerInfo.defaultProps = {
  partner: {},
};

export default PartnerInfo;
