import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function userReducer(state = initialState.user, action) {
  const { user, isViewed } = action;
  switch (action.type) {
    case types.SET_USER:
      return user;

    case types.GET_ACTIVE_AGREEMENT_REGIONS:
      return {
        ...state,
        activeRegions: action.regions,
      };
    case types.ACCEPT_TERMS_SUCCESS:
      return {
        ...state,
        hasAcceptedCurrentTerms: true,
      };

    case types.VIEWED_PGP_SETUP_PAGE:
      return {
        ...state,
        viewedPGPSetupPage: isViewed,
      };

    case types.REMOVE_PGP_KEY_FROM_USER:
      return {
        ...state,
        hasActiveFingerprint: false,
      };

    case types.CANCELED_PGP_SETUP:
      return {
        ...state,
        hasCanceledPGPSetup: true,
      };

    default:
      return state;
  }
}
