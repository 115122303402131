import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import {
  Collapsible,
  DataTable,
  ActionBar,
  TableHeader,
  TableHeaderCell,
  NoResults,
} from '@partner-global-ui/components';

import JobRow from './JobRow';

const JobsTable = ({
  title,
  jobs,
  searchTerm = '',
  searchJobs,
  setJobStatus,
  loading,
  currentSort,
  sortJobs,
  isOpen,
}) => {
  const { t } = useTranslation();

  // labels for table headers
  const tableHeaders = [
    { label: '', value: 'job-flag', sortable: false },
    { label: t('msg_codes_job_name'), value: 'jobCode', sortable: true },
    { label: t('msg_codes_job_description'), value: 'description', sortable: true },
    { label: t('msg_codes_last_updated'), value: 'modifyDate', sortable: true },
    { label: t('msg_codes_agreements_updatedBy'), value: 'modifiedBy', sortable: true },
  ];

  const renderJobsHeaderCells = () => {
    return tableHeaders.map(({ label, value, sortable }, headerCellIndex) => {
      const headerKey = headerCellIndex + 1;
      return (
        <TableHeaderCell
          id={headerKey}
          key={headerKey}
          value={value}
          className={value}
          sortable={sortable}
          onClick={sortJobs}
          sort={currentSort}
        >
          {label}
        </TableHeaderCell>
      );
    });
  };


  const actionBar = () => {
    return (
      <ActionBar
        displaySearch
        searchDefaultValue={searchTerm}
        search={searchJobs}
        searchPlaceholder={t('msg_codes_search_job_name_description')}
      />
    );
  };

  const columnTemplate = '5% 28% 37% 15% 15%';
  return (
    <div className={title.toLowerCase().replace(/\s+/g, '-')}>
      <Collapsible defaultIsOpen={isOpen} secondary heading={t(title)}>
        <DataTable
          columnTemplate={columnTemplate}
          scrollHeight={400}
          id="cron-jobs-table"
          data-testid="cron-jobs-table"
          loading={loading}
          actionBar={actionBar()}
        >
          <TableHeader>
            {renderJobsHeaderCells()}
          </TableHeader>
          {
            isEmpty(jobs)
              ? <NoResults />
              : jobs.map((job) => {
                return (
                  <JobRow job={job} setJobStatus={setJobStatus} />
                );
              })
          }
        </DataTable>
      </Collapsible>
    </div>
  );
};

JobsTable.propTypes = {
  title: PropTypes.string,
  jobs: PropTypes.array,
  searchTerm: PropTypes.string,
  searchJobs: PropTypes.func,
  setJobStatus: PropTypes.func,
  loading: PropTypes.bool,
  currentSort: PropTypes.object,
  sortJobs: PropTypes.func,
  isOpen: PropTypes.bool,
};

JobsTable.defaultProps = {
  title: '',
  jobs: [],
  searchTerm: '',
  searchJobs: () => null,
  setJobStatus: () => null,
  loading: false,
  currentSort: {
    sortBy: 'jobCode', sortDir: 'asc',
  },
  sortJobs: () => null,
  isOpen: false,
};

export default JobsTable;
