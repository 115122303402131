import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { useSelector, useDispatch, useStore } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Loader } from '@partner-global-ui/components';
// import Order from './OrderPage';
import AgencyOrder from './agency/OrderPage';
import FreeAllocationOrder from './freeAllocation/OrderPage';
import PermittedUsesOrder from './permittedUses/OrderPage';
import * as agreementTypes from '../../constants/agreementType.constants';
import * as OrderActions from '../../actions/orderActions';
import { usePermission } from '../../utils/accessControl/hasPermission';
import roleKeys from '../../utils/accessControl/roleKeys';
import NotFoundError from '../error/NotFound';

const OrderDetailPageSelector = ({ location, match, history }) => {
  const dispatch = useDispatch();
  const pageRef = useRef();
  const store = useStore();
  const order = useSelector(state => state.order);
  const { agreementType } = order;

  // Local State
  const [orderNotFound, setOrderNotFound] = useState(false);
  const [orderLoaded, setOrderLoaded] = useState(false);
  const [initialOrder, setInitialOrder] = useState(false);
  const [unauthorized, setUnauthorized] = useState(false);

  const canViewFreeAllocation = usePermission(roleKeys.freeAllocationOrderContent.viewDetail);
  const canViewPermittedUses = usePermission(roleKeys.permittedUsesOrder.viewDetail);

  useEffect(() => {
    const { loadOrder, newOrder } = OrderActions;
    const { params } = match;

    if (!order.linesAdded) {
      if (typeof params.id !== 'undefined') {
        loadOrder(params.id)(dispatch, store.getState).then((res) => {
          if (!res.ok) {
            setUnauthorized(true);
            setOrderLoaded(true);
          } else if (typeof res !== 'undefined' && res.ok && typeof order === 'undefined') {
            history.push('/order');
            setInitialOrder(false);
            setOrderLoaded(true);
          } else {
            setInitialOrder(true);
            setOrderLoaded(true);
          }
        }, () => {
          setOrderNotFound(true);
        });
      } else {
        newOrder()(dispatch);
        setOrderLoaded(true);
      }
    } else {
      setOrderLoaded(true);
    }
  }, [location]);

  const getOrderDetailPage = useCallback(() => {
    const agreementTypeMatch = agreementTypes[agreementType];

    if (orderNotFound) {
      return (<Redirect to="/orders" />);
    }

    if (unauthorized) {
      return (<NotFoundError />);
    }

    if (orderLoaded) {
      switch (agreementTypeMatch) {
        case agreementTypes.AGENCY:
        case agreementTypes.FIRST_PARTY_PUBLISHER:
          return (
            <AgencyOrder triggerInitialOrder={initialOrder} pageRef={pageRef} />
          );

        case agreementTypes.THIRD_PARTY_CONTRACTUAL:
          if (canViewFreeAllocation) {
            return <FreeAllocationOrder />;
          }
          return (<Redirect to="/orders" />);

        case agreementTypes.THIRD_PARTY_RATECARD:
          if (canViewPermittedUses) {
            return <PermittedUsesOrder />;
          }
          return <Redirect to="/orders" />;

        default:
          return (
            <AgencyOrder triggerInitialOrder={initialOrder} pageRef={pageRef} />
          );
      }
    }
    return (<div className="order-detail-loader"><Loader /></div>);
  }, [agreementType, orderLoaded, orderNotFound]);

  return (
    <div
      data-testid="order-detail-page"
      className="order-detail-page"
      ref={pageRef}
    >
      {getOrderDetailPage()}
    </div>
  );
};

OrderDetailPageSelector.propTypes = {
  location: PropTypes.object,
};

OrderDetailPageSelector.defaultProps = {
  location: {},
};

export default OrderDetailPageSelector;
