import React from 'react';
import PropTypes from 'prop-types';
import {
  Tooltip,
} from '@partner-global-ui/components';
import { useTranslation } from 'react-i18next';
import PrimaryRowHeader from './primaryRowHeader';
import Quantity from './quantity';
import Activity from './activity';
import countriesGensenStrings from '../../../../constants/countries.constants';
import getRegionName from '../../../../utils/region.utils';
import { activitySelectTypes } from '../../../../constants/activityType.constants';

import './primaryRow.scss';

// COUNTRIES COLUMN RENDER (All Pages)
const renderCountry = (countryAvailability, tableRef, t) => {
  const countriesWithStrings = countryAvailability && countryAvailability.map((cty) => {
    return {
      ...cty,
      name: t(countriesGensenStrings[cty.code].name),
    };
  });

  const countriesArray = countriesWithStrings.map((country) => {
    return country && country.name;
  });
  const listFormatter = new Intl.ListFormat('en', { style: 'narrow', type: 'conjunction' });
  const tooltipContent = (
    <div className="info-country-tooltip-content">
      {listFormatter.format(countriesArray)}
    </div>
  );
  return (
    countryAvailability && countryAvailability.length > 1
      ? (
        <Tooltip
          position="bottom"
          content={tooltipContent}
          shortCopy={countryAvailability.length <= 3}
          anchor={tableRef}
        >
          {countryAvailability.length} {t('msg_codes_countries')}
        </Tooltip>
      ) : (
        <div>
          {countriesArray && countriesArray[0]}
        </div>
      )
  );
};

const renderOrderFee = (activity, availabilityRegion, codeFee, codeFeeCurrency) => {
  if (activity.value === '') {
    return '';
  }

  if (activity.value === 'DISC_SUBSTITUTE' && codeFee && codeFeeCurrency) {
    const feeType = [activitySelectTypes.DISC_SUBSTITUTE].map(items => ({
      ...items,
      orderFee: `${codeFee}${' '}${codeFeeCurrency}`,
    }))[0];

    return feeType.orderFee;
  }

  return activitySelectTypes && activitySelectTypes[activity.value]
    && activitySelectTypes[activity.value].orderFee(availabilityRegion);
};

const renderRedemptionFee = (activity, availabilityRegion) => {
  return activity && activity.value === ''
    ? ''
    : activitySelectTypes && activitySelectTypes[activity && activity.value]
      && activitySelectTypes[activity && activity.value].redemptionFee(availabilityRegion);
};

function PrimaryRow({
  showUseCount,
  codeName,
  countryAvailability,
  version,
  sku,
  activity,
  quantity,
  useCount,
  codeFee,
  codeFeeCurrency,
  tableRef,
  availabilityRegion,
  urlToScpPar,
  showQuantityInput,
  showActivitySelect,
  activityOptions,
  handleQuantityChange,
  handleActivityChange,
  name,
  standardParId,
}) {
  const { t } = useTranslation();
  const gridTemplateColumns = '1fr 100px 120px 90px 1fr 200px 125px 105px 200px';
  const gridTemplateColumnsWithUseCount = '1fr 100px 120px 90px 1fr 200px 125px 100px 105px 200px';
  const gridStyle = showUseCount ? { 'grid-template-columns': gridTemplateColumnsWithUseCount }
    : { 'grid-template-columns': gridTemplateColumns };

  const rendervParIcon = () => {
    const vParMessage = t('msg_codes_codesProdCa_codeOnly_tooltip');
    if (standardParId) {
      return (<></>);
    }
    return (
      <div className="information-row-item information-row-vpar">
        <div className="iconPlacement">
          <Tooltip
            position="top"
            shortCopy
            strategy="fixed"
            content={vParMessage}
            className="iconPlacement"
            anchor={tableRef}
          >
            <div className="voucher-only-product">v</div>
          </Tooltip>
        </div>
      </div>
    );
  };
  return (
    <div className="primary-row" style={gridStyle}>
      <PrimaryRowHeader
        tableRef={tableRef}
        showUseCount={showUseCount}
        name={name}
      />
      <div className="cell-container codeName" data-testid={`primary-codeName-${name}`}>
        <div className="content">
          { rendervParIcon() }
          <Tooltip
            position="bottom"
            content={codeName}
            anchor={tableRef}
          >
            <a className="codeNameLink truncate" href={urlToScpPar} target="_blank" rel="noopener noreferrer">
              { codeName }
            </a>
          </Tooltip>
        </div>
      </div>
      <div className="cell-container region" data-testid={`primary-region-${name}`}>
        { t(getRegionName(availabilityRegion)) }
      </div>
      <div className="cell-container country" data-testid={`primary-country-${name}`}>
        { renderCountry(countryAvailability, tableRef, t) }
      </div>
      <div className="cell-container version" data-testid={`primary-version-${name}`}>
        {version}
      </div>
      <div className="cell-container sku" data-testid={`primary-sku-${name}`}>
        <Tooltip
          position="bottom"
          content={sku || t('msg_codes_codes_status_notAvailable')}
          anchor={tableRef}
        >
          <span className="truncate">{sku || t('msg_codes_codes_status_notAvailable')}</span>
        </Tooltip>
      </div>
      <div className="cell-container activity" data-testid={`primary-activity-${name}`}>
        <Activity
          activityOptions={activityOptions}
          activity={activity}
          showActivitySelect={showActivitySelect}
          tableRef={tableRef}
          handleActivityChange={handleActivityChange}
          name={name}
        />
      </div>
      <div className="cell-container quantity" data-testid={`primary-quantity-${name}`}>
        <Quantity
          quantity={quantity}
          showQuantityInput={showQuantityInput}
          handleQuantityChange={handleQuantityChange}
          name={name}
        />
      </div>
      { showUseCount && (
        <div className="cell-container useCount" data-testid={`primary-name-${name}`}>
          {useCount}
        </div>
      )}
      <div className="cell-container orderFee" data-testid={`primary-orderFee-${name}`}>
        { renderOrderFee(activity, availabilityRegion, codeFee, codeFeeCurrency) }
      </div>
      <div className="cell-container redemptionFee" data-testid={`primary-redemptionFee-${name}`}>
        { renderRedemptionFee(activity, availabilityRegion) }
      </div>
    </div>
  );
}

PrimaryRow.propTypes = {
  name: PropTypes.string, // value used to prefix datatestIds in row
  showUseCount: PropTypes.bool,
  codeName: PropTypes.string,
  countryAvailability: PropTypes.array,
  availabilityRegion: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  version: PropTypes.string,
  sku: PropTypes.string,
  activity: PropTypes.object,
  quantity: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  useCount: PropTypes.number,
  tableRef: PropTypes.object,
  urlToScpPar: PropTypes.string,
  codeFee: PropTypes.string,
  codeFeeCurrency: PropTypes.string,
  showActivitySelect: PropTypes.bool,
  showQuantityInput: PropTypes.bool,
  activityOptions: PropTypes.array,
  handleQuantityChange: PropTypes.func,
  handleActivityChange: PropTypes.func,
  standardParId: PropTypes.number,
};

PrimaryRow.defaultProps = {
  name: '',
  showUseCount: false,
  codeName: 'name',
  countryAvailability: [],
  version: 'version',
  sku: 'sku',
  activity: { value: '' },
  quantity: 1,
  useCount: 1,
  tableRef: {},
  availabilityRegion: {},
  urlToScpPar: '',
  codeFee: '',
  codeFeeCurrency: '',
  showActivitySelect: false,
  showQuantityInput: false,
  activityOptions: [],
  handleQuantityChange: () => {},
  handleActivityChange: () => {},
  standardParId: undefined,
};

export default PrimaryRow;
