import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { Tooltip, Icon, iconSizes } from '@partner-global-ui/components';
import getFeature from '../../../../utils/accessControl/getFeature';

const SecondaryRowHeader = ({
  page,
  tableRef,
  isOrderRowView,
  isPurchaseOrderRequired,
  name,
}) => {
  const { t } = useTranslation();
  const startEndDateUrl = getFeature('startEndDateUrl');

  const poNumberTooltipContent = (
    <div className="tooltipDefaultStyles poNumber">
      { t('msg_codes_order_comment_box_tooltip') }
    </div>
  );

  const startEndDateTooltipContent = (
    <div className="tooltipDefaultStyles startEndDate">
      <Trans
        i18nKey={isOrderRowView ? 'msg_codes_order_startDateAndEndDate_tooltip' : 'msg_codes_cart_startDateAndEndDate_tooltip'}
        defaults="Default Message"
        components={[<a data-testid={`startEndDate-tooltip-${name}`} href={startEndDateUrl} className="tooltip-link" rel="noopener noreferrer" target="_blank">here</a>]}
      />
    </div>
  );

  return (
    <div className={`secondary-headers-row ${page}`}>
      <div
        key="poNumber"
        id="poNumber"
        value="poNumber"
        className="secondary-header poNumber"
        data-testid={`secondary-row-poNumber-title-${name}`}
      >
        {t('msg_codes_orders_ponum').replace(/\(/, '\n(')}
        <div className="tooltip-wrapper">
          <Tooltip
            id="po-tooltip"
            content={poNumberTooltipContent}
            position="top"
            shortCopy
            strategy="fixed"
            anchor={tableRef}
          >
            <Icon className="info-icon" size={iconSizes.Small}>info</Icon>
          </Tooltip>
        </div>
        {isPurchaseOrderRequired ? ' *' : ''}
      </div>
      <div
        key="startDateEndDate"
        id="startDateEndDate"
        value="startDateEndDate"
        className="secondary-header startEndDate"
        data-testid={`secondary-row-startEndDate-title-${name}`}
      >
        <div className="startEndDate-text">
          {t('msg_codes_orderDetails_startDate')}/{t('msg_codes_orderDetails_endDate')}
        </div>
        <div className="tooltip-wrapper">
          <Tooltip
            id="activity-tooltip"
            content={startEndDateTooltipContent}
            position="top"
            shortCopy
            strategy="fixed"
            anchor={tableRef}
          >
            <Icon className="info-icon" size={iconSizes.Small}>info</Icon>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

SecondaryRowHeader.propTypes = {
  name: PropTypes.string, // value used to prefix datatestIds in row
  page: PropTypes.string,
  tableRef: PropTypes.object,
  isPurchaseOrderRequired: PropTypes.bool,
  isOrderRowView: PropTypes.bool,
};

SecondaryRowHeader.defaultProps = {
  name: '', // value used to prefix datatestIds in row
  page: '',
  tableRef: { },
  isPurchaseOrderRequired: false,
  isOrderRowView: false,
};

export default SecondaryRowHeader;
