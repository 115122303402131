export default [
  {
    id: 'sftpName',
    value: 'sftpName',
    label: 'msg_codes_partners_SFTP',
  },
  {
    id: 'authenticationType',
    value: 'authenticationType',
    label: 'msg_codes_partners_authType',
    sortable: true,
  },
  {
    id: 'sftpSiteHostName',
    value: 'sftpSiteHostName',
    label: 'msg_codes_partners_SFTPSiteHost',
    sortable: false,
  },
  {
    id: 'sftpStatus',
    value: 'sftpStatus',
    label: 'msg_codes_partners_SFTPStatus',
    sortable: true,
  },
];
