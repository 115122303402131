import axios from 'axios';
import * as types from './actionTypes';

export default function getCurrencies() {
  return dispatch => axios.get('/venom/api/currencies/')
    .then(response => response.data)
    .then((currencies) => {
      dispatch({ type: types.SET_CURRENCIES, currencies });
    })
    .catch(() => {
      // TODO: error handling
    });
}
