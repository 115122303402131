import generateUuid from 'uuid/v1';
import moment from 'moment';
import _ from 'lodash';
import initialState from './initialState';
import * as types from '../actions/actionTypes';

export const parseCartFromLocalStorage = (userId) => {
  const serializedCart = localStorage.getItem(`cartItems-${userId}`);
  if (typeof serializedCart === 'string') {
    try {
      const cart = JSON.parse(serializedCart);
      return cart;
    } catch (error) {
      // if there is an error parsing localstorage it will set the cart to empty
      console.error('Unable to load cart from local storage', error);
      localStorage.setItem(`cartItems-${userId}`, JSON.stringify([]));
      return [];
    }
  }
  return [];
};

export const saveCartToLocalStorage = (cart, userId) => {
  localStorage.setItem(`cartItems-${userId}`, JSON.stringify(cart));
};

export const updateCartItemPoFile = (
  state,
  userId,
  cartId,
  fileType,
  poFile,
) => {
  const { cart } = state;
  const updatedCart = cart.map((product) => {
    let updatedFileData = {};

    if (poFile) {
      updatedFileData = {
        cartId,
        name: poFile.name,
        size: poFile.size,
        type: fileType,
        file: poFile.file,
      };
    }

    if (product.cartId === cartId) {
      if (poFile) {
        return {
          ...product,
          poFile: updatedFileData,
        };
      }
      return {
        ...product,
      };
    }
    return product;
  });

  saveCartToLocalStorage(updatedCart, userId);
  return {
    ...state,
    poFileAdded: true,
    cart: updatedCart,
  };
};

export const loadCartFromLocalStorage = (userId, state) => {
  return {
    ...state,
    cart: parseCartFromLocalStorage(userId),
  };
};

const emptyCart = (userId, state) => {
  saveCartToLocalStorage([], userId);
  return {
    ...state,
    cart: [],
  };
};

export const removePoFiles = (userId, state) => {
  const cart = state.cart.map((cartItem) => {
    return _.omit(cartItem, 'poFile');
  });

  saveCartToLocalStorage(cart, userId);
  return {
    ...state,
    cart,
  };
};

const removeItemFromCart = (userId, itemId, state) => {
  const cart = state.cart.filter(({ cartId }) => cartId !== itemId);
  saveCartToLocalStorage(cart, userId);
  return {
    ...state,
    cart,
  };
};

export const updateCartItemQuantity = (state, userId, itemId, quantity) => {
  const cart = state.cart.map(product => ({
    ...product,
    quantity: product.cartId === itemId
      ? Number(quantity)
      : product.quantity,
  }));
  saveCartToLocalStorage(cart, userId);
  return {
    ...state,
    cart,
  };
};

export const updateCartPartnerComments = (state, userId, itemId, partnerComments) => {
  const cart = state.cart.map(product => ({
    ...product,
    partnerComments: product.cartId === itemId
      ? partnerComments
      : product.partnerComments,
  }));
  saveCartToLocalStorage(cart, userId);
  return {
    ...state,
    cart,
  };
};


export const updateCartItemUseCount = (state, userId, itemId, useCount) => {
  const cart = state.cart.map(product => ({
    ...product,
    useCount: product.cartId === itemId
      ? Number(useCount)
      : product.quantity,
  }));
  saveCartToLocalStorage(cart, userId);
  return {
    ...state,
    cart,
  };
};

export const updateCartItemStartDate = (state, userId, itemId, startDate) => {
  const cart = state.cart.map((product) => {
    let updatedProduct = { ...product };
    if (product.cartId === itemId) {
      updatedProduct = {
        ...product,
        startDate,
      };
    }
    return updatedProduct;
  });
  saveCartToLocalStorage(cart, userId);
  return {
    ...state,
    cart,
  };
};

export const updateCartItemEndDate = (state, userId, itemId, endDate) => {
  const cart = state.cart.map((product) => {
    let updatedProduct = { ...product };
    if (product.cartId === itemId) {
      updatedProduct = {
        ...product,
        endDate,
      };
    }
    return updatedProduct;
  });
  saveCartToLocalStorage(cart, userId);
  return {
    ...state,
    cart,
  };
};


export const updateCartItemStartTime = (state, userId, itemId, startTime) => {
  const cart = state.cart.map((product) => {
    const start = {
      date: moment(product.startDate, 'YYYY/MM/DD').format('YYYY-MM-DD'),
      time: startTime ? moment(startTime, 'hh:mm a').format('HH:mm:ss')
        : moment(product.startDate, 'YYYY/MM/DD hh:mm a').format('HH:mm:ss'),
    };

    const dateString = set => `${set.date}T${set.time}`;
    const updatedProduct = { ...product };
    if (product.cartId === itemId) {
      updatedProduct.startDate = dateString(start);
      updatedProduct.startTime = startTime;
    }
    return updatedProduct;
  });

  saveCartToLocalStorage(cart, userId);
  return {
    ...state,
    cart,
  };
};

export const updateCartItemEndTime = (state, userId, itemId, endTime) => {
  const cart = state.cart.map((product) => {
    const end = {
      date: moment(product.endDate, 'YYYY/MM/DD').format('YYYY-MM-DD'),
      time: endTime ? moment(endTime, 'hh:mm a').format('HH:mm:ss')
        : moment(product.endDate, 'YYYY/MM/DD hh:mm a').format('HH:mm:ss'),
    };

    const dateString = set => `${set.date}T${set.time}`;
    const updatedProduct = { ...product };
    if (product.cartId === itemId) {
      updatedProduct.endDate = dateString(end);
      updatedProduct.endTime = endTime;
    }
    return updatedProduct;
  });

  saveCartToLocalStorage(cart, userId);
  return {
    ...state,
    cart,
  };
};

export const updateCartItemPoNumber = (state, userId, itemId, poNumber) => {
  const cart = state.cart.map(product => ({
    ...product,
    poNumber: product.cartId === itemId
      ? poNumber
      : product.poNumber,
  }));
  saveCartToLocalStorage(cart, userId);
  return {
    ...state,
    cart,
  };
};

const addItemFromCatalog = (
  state,
  codeProduct,
  productVariant,
  activity,
  quantity,
  useCount,
  startDate,
  startTime,
  endDate,
  endTime,
  poNumber,
  poFile,
  userId,
  partnerComments,
) => {
  const item = {
    ...codeProduct,
    activity: activity.value,
    quantity: Number(quantity),
    useCount: Number(useCount),
    codeProduct: productVariant.codeProduct,
    productId: productVariant.productId,
    conceptName: productVariant.conceptName,
    registrationRegion: productVariant.registrationRegion,
    cartId: generateUuid(),
    startDate,
    startTime,
    endDate,
    endTime,
    poNumber,
    poFile,
    partnerComments,
  };
  try {
    const newCart = localStorage.getItem(`cartItems-${userId}`) === null
      ? []
      : JSON.parse(localStorage.getItem(`cartItems-${userId}`));
    newCart.push(item);
    saveCartToLocalStorage(newCart, userId);
    return {
      ...state,
      cart: newCart,
    };
  } catch (error) {
    console.log(error);
    return state;
  }
};

export default (state = initialState.cart, action) => {
  const {
    cartId,
    fileType,
    poFile,
    codeProduct,
    productVariant,
    activity,
    quantity,
    useCount,
    startDate,
    startTime,
    endDate,
    endTime,
    poNumber,
    userId,
    partnerComments,
  } = action;
  switch (action.type) {
    case types.EMPTY_CART:
      return emptyCart(userId, state);
    case types.REMOVE_PO_FILES:
      return removePoFiles(userId, state);
    case types.REMOVE_ITEM_FROM_CART:
      return removeItemFromCart(userId, cartId, state);
    case types.UPDATE_CART_ITEM_QUANTITY:
      return updateCartItemQuantity(state, userId, cartId, quantity);
    case types.UPDATE_CART_PARTNER_COMMENTS:
      return updateCartPartnerComments(state, userId, cartId, partnerComments);
    case types.UPDATE_CART_ITEM_USE_COUNT:
      return updateCartItemUseCount(state, userId, cartId, useCount);
    case types.UPDATE_CART_ITEM_START_DATE:
      return updateCartItemStartDate(state, userId, cartId, startDate);
    case types.UPDATE_CART_ITEM_END_DATE:
      return updateCartItemEndDate(state, userId, cartId, endDate);
    case types.UPDATE_CART_ITEM_START_TIME:
      return updateCartItemStartTime(state, userId, cartId, startTime);
    case types.UPDATE_CART_ITEM_END_TIME:
      return updateCartItemEndTime(state, userId, cartId, endTime);
    case types.UPDATE_CART_ITEM_PO_NUMBER:
      return updateCartItemPoNumber(state, userId, cartId, poNumber);
    case types.UPDATE_CART_ITEM_PO_FILE:
      return updateCartItemPoFile(state, userId, cartId, fileType, poFile);
    case types.LOAD_CART_FROM_LOCAL_STORAGE:
      return loadCartFromLocalStorage(userId, state);
    case types.ADD_ITEM_FROM_CATALOG:
      return addItemFromCatalog(
        state,
        codeProduct,
        productVariant,
        activity,
        quantity,
        useCount,
        startDate,
        startTime,
        endDate,
        endTime,
        poNumber,
        poFile,
        userId,
        partnerComments,
      );
    default:
      return state;
  }
};
