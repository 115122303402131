import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function agreementsReducer(state = initialState.agreements, action) {
  switch (action.type) {
    case types.LOAD_AGREEMENTS_SUCCESS:
      return action.agreements;
    case types.CLEAR_AGREEMENTS:
      return initialState.agreements;
    default:
      return state;
  }
}
