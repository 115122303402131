import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import {
  Collapsible,
  DataTable,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableCell,
  Layout,
  Flex,
} from '@partner-global-ui/components';
import PropTypes from 'prop-types';
import { orderBy, padStart, capitalize } from 'lodash';
import moment from 'moment';
import './Agreements.scss';
import * as agreementActions from '../../actions/agreementActions';

const AgreementChangeHistory = ({
  canEdit,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [agreementVersionAudits, setAgreementVersionAudits] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const {
    agreementForm: {
      isNewAgreement,
      hasBeenModified,
      agreement: {
        status,
      },
      agreement: { agreementVersions },
    },
  } = useSelector(state => state);

  const agreementVersionOptions = orderBy(agreementVersions, 'agreementVersionId', ['asc'])
    .map((version, index) => {
      return {
        versionNumber: padStart(index + 1, 3, '0'),
        version,
      };
    });

  const getAgreementVersionAudits = useCallback(() => {
    setLoaded(true);
    const agreementVersionAuditPromises = agreementVersionOptions
      .map(async (option) => {
        const {
          version: { agreementVersionId },
          versionNumber,
        } = option;
        const audits = await axios.get(`/venom/api/audit/agreementVersion/${agreementVersionId}`)
          .then(response => response.data);
        if (audits && audits.length >= 1) {
          const detail = JSON.parse(audits[0].detail);
          return {
            ...detail,
            createdByName: audits[0].createdByName,
            agreementVersionId,
            versionNumber,
            creationDate: audits[0].creationDate,
          };
        }
        return { versionNumber, createdByName: '', agreementVersionId };
      }, []);
    Promise.all(agreementVersionAuditPromises).then((versionAudits) => {
      setAgreementVersionAudits(versionAudits);
    });
  });

  useEffect(() => {
    if (
      !isNewAgreement
      && typeof agreementVersionOptions[0].version.agreementVersionId !== 'undefined'
      && agreementVersionAudits.length === 0
      && !loaded
    ) {
      getAgreementVersionAudits(agreementVersionOptions);
    }
  }, [agreementVersionAudits]);

  const loadVersion = useCallback((agreementVersionId, vStatus) => {
    const agreementVersionOption = agreementVersionOptions
      .find(option => option.version.agreementVersionId === agreementVersionId);
    const { version } = agreementVersionOption;

    const shouldChange = (hasBeenModified.agreement || hasBeenModified.version)
      ? window.confirm(t('msg_codes_modal_unsavedChanges_body'))
      : true;

    if (shouldChange) {
      dispatch(
        agreementActions.setAgreementVersion(
          version, agreementVersionId, vStatus, canEdit,
        ),
      );
    }
  });

  const renderHeaderCells = () => {
    const activityTableHeaders = [
      t('msg_codes_codes_version'),
      t('msg_codes_common_date'),
      t('msg_codes_agreements_agreementStatus_label'),
      t('msg_codes_agreements_versionStatus'),
      t('msg_codes_agreements_agreementVersionEffectiveDateUTC'),
      t('msg_codes_orderDetails_by'),
    ];

    return activityTableHeaders.map(header => (
      <TableHeaderCell key={header} id={header}>{header}</TableHeaderCell>
    ));
  };

  const renderTableRows = () => {
    const sortedAgreementVersions = orderBy(agreementVersionAudits, 'creationDate', ['desc']);
    return sortedAgreementVersions.map((version, i) => {
      const key = i + 1;
      return (
        <TableRow
          key={key}
          id={`row-${key}`}
        >
          <TableCell key={`version-number-${key}`} id={`version-number-${key}`}>
            <span
              className="version-number-link"
              onClick={() => loadVersion(version.agreementVersionId, status)}
              onKeyDown={() => loadVersion(version.agreementVersionId, status)}
              role="button"
              tabIndex={0}
            >
              {version.versionNumber}
            </span>
          </TableCell>
          <TableCell key={`creation-date-${key}`} id={`creation-date-${key}`}>
            {
              version.creationDate
                ? moment(version.creationDate).format('h:mm a MM/DD/YYYY')
                : '-'
            }
          </TableCell>
          <TableCell key={`agreement-status-${key}`} id={`agreement-status-${key}`}>
            {capitalize(version.agreementStatus) || '-'}
          </TableCell>
          <TableCell key={`agreement-version-status-${key}`} id={`agreement-version-status-${key}`}>
            {capitalize(version.agreementVersionStatus) || '-'}
          </TableCell>
          <TableCell key={`agreement-version-effective-date-${key}`} id={`agreement-version-effective-date-${key}`}>
            {
              version.agreementVersionEffectiveDate
                ? moment(version.agreementVersionEffectiveDate).utc().format('MM/DD/YYYY (UTC)')
                : '-'
            }
          </TableCell>
          <TableCell key={`createdBy-display-name-${key}`} id={`createdBy-display-name-${key}`}>
            {version.createdByName || '-'}
          </TableCell>
        </TableRow>
      );
    });
  };

  return isNewAgreement ? null : (
    <Layout id="agreement-change-history-layout" className="agreement-change-history-layout">
      <Flex id="agreement-change-history-flex" className="agreement-change-history-flex" colSpan="12">
        <Collapsible
          heading={t('codes_agreements_agreementChangehistory')}
          id="agreement-change-history-collapsible"
          defaultIsOpen
        >
          <DataTable
            columnTemplate="repeat(6, 1fr)"
            scrollHeight={400}
            id="agreement-change-history-table"
          >
            <TableHeader id="agreement-change-history-header">
              {renderHeaderCells()}
            </TableHeader>
            {renderTableRows()}
          </DataTable>
        </Collapsible>
      </Flex>
    </Layout>
  );
};

AgreementChangeHistory.propTypes = {
  canEdit: PropTypes.bool,
};

AgreementChangeHistory.defaultProps = {
  canEdit: false,
};

export default AgreementChangeHistory;
