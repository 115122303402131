import React, { useCallback, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  Button,
  TextInput,
  Modal,
  ModalContext,
  MODAL_ACTIONS,
  DataTable,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@partner-global-ui/components';
import { isEmpty } from 'lodash';

import StaticText from '../../common/StaticText';
import { validateCodeProductSwap, swapCodeProduct } from '../../../actions/orderActions';
import './CodeProductSwapModal.scss';


const initialCodeProductMatch = {
  codeProduct: '',
  productId: '',
  region: '',
};

export const mapErrorStrings = (data) => {
  const errorMessage = data.detailMessages && !isEmpty(data.detailMessages[0])
    ? data.detailMessages[0]
    : data.message;

  const codeProductSwapErrorMessages = {
    'This products end date has passed': 'msg_codes_swap_expired_error',
    'The new code product must be active': 'msg_codes_swap_inactive_error',
    'The new code product must be wholesale price approved for billable voucher pars when order activity is CONSUMER_PROMOTION': 'msg_codes_swap_wsp_error',
    'This product does not have any country availability': 'msg_codes_swap_region_error',
    'The new code product must have available skus': 'msg_codes_swap_sku_error',
    'The new code product must have the same type as the orderLine': 'msg_codes_product_type_swap_error',
    'Product not found by npVoucherTypeId': 'msg_codes_zero_products_search',
    'Product not found by voucherCatalogId': 'msg_codes_zero_products_search',
  };

  const stringId = codeProductSwapErrorMessages[errorMessage]
    ? codeProductSwapErrorMessages[errorMessage] : errorMessage;

  return stringId;
};

const CodeProductSwapModal = ({
  orderNumber,
  orderLineId,
  originalCodeProduct,
  notificationContext,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const swapModalContext = useContext(ModalContext);
  const confirmationModalContext = useContext(ModalContext);
  const [catalogIdOrTypeId, setCatalogIdOrTypeId] = useState('');
  const [isValidSwap, setIsValidSwap] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [matchingCodeProduct, setMatchingCodeProduct] = useState(initialCodeProductMatch);

  const closeSwapModal = () => {
    swapModalContext.dispatch({
      type: MODAL_ACTIONS.HIDE,
    });
  };

  const validateCodeProductOnClick = useCallback(() => {
    setIsLoading(true);
    validateCodeProductSwap(orderLineId, catalogIdOrTypeId).then((result) => {
      if (result.response && result.response.status >= 400) {
        setIsValidSwap(false);
        setError(t(
          mapErrorStrings(result.response.data),
          {
            oldCodeProductName: originalCodeProduct.codeProduct,
            newCodeProductName: matchingCodeProduct.codeProduct,
            interpolation: { escapeValue: false },
          },
        ));
        setMatchingCodeProduct(initialCodeProductMatch);
      } else {
        setMatchingCodeProduct(result);
        setIsValidSwap(true);
        setError(undefined);
      }
      setIsLoading(false);
    });
  }, [orderLineId, originalCodeProduct, catalogIdOrTypeId, matchingCodeProduct]);

  const handleNewCodeProductInput = (e) => {
    if (typeof e.persist === 'function') {
      e.persist();
    }
    setError(undefined);
    setCatalogIdOrTypeId(e.target.value);
  };

  const swapProduct = () => {
    swapCodeProduct(
      orderLineId,
      catalogIdOrTypeId,
      notificationContext,
      originalCodeProduct.codeProduct,
      matchingCodeProduct.codeProduct,
      dispatch,
      orderNumber,
    );
    confirmationModalContext.dispatch({
      type: MODAL_ACTIONS.HIDE,
    });
  };

  const confirmSwapProduct = async (oldCodeProductName, newCodeProductName) => {
    const confirmationModalProps = {
      id: 'confirmation-code-product-modal',
      name: 'confirmation-code-product-modal',
      title: t('msg_codes_confirm_swap'),
      content: t('msg_codes_confirm_product_swap', {
        oldCodeProductName,
        newCodeProductName,
      }),
      primaryLabel: t('msg_codes_yes_confirm'),
      secondaryLabel: t('msg_codes_cta_cancel'),
      onPrimary: swapProduct,
      onSecondary: () => confirmationModalContext.dispatch({ type: MODAL_ACTIONS.HIDE }),
      onClose: () => confirmationModalContext.dispatch({ type: MODAL_ACTIONS.HIDE }),
      hideCancelButton: true,
    };

    confirmationModalContext.dispatch({
      type: MODAL_ACTIONS.SHOW,
      payload: <Modal {...confirmationModalProps} />,
    });
  };

  const modalBody = (
    <>
      {t('msg_codes_product_swap_info')}
      <StaticText label={t('msg_codes_new_code_product_swap')} required testId="new-product" />
      <div className="code-product-search">
        <TextInput
          id="newCodeProduct"
          className="newCodeProduct-input"
          placeholder={t('msg_codes_product_id_ghost')}
          value={catalogIdOrTypeId}
          onChange={handleNewCodeProductInput}
          required
          width={340}
          errorMessage={error}
        />
        <Button
          disabled={catalogIdOrTypeId.length <= 0}
          onClick={validateCodeProductOnClick}
          loading={isLoading}
          id="code-product-search-button"
        >
          {t('msg_codes_search_prompt')}
        </Button>
      </div>
      <DataTable
        columnTemplate="repeat(2, 1fr)"
        id="code-product-swap-table"
        secondaryLabel
      >
        <TableHeader id="code-product-swap-header">
          <TableHeaderCell key={0} id="orignal-code-product-header-cell">{t('msg_codes_current_product')}</TableHeaderCell>
          <TableHeaderCell key={1} id="new-code-product-header-cell">{t('msg_codes_new_code_product_swap')}</TableHeaderCell>
        </TableHeader>
        <TableCell
          key="product-name-first-label-cell"
          id="product-name-first-label-cell"
          className="label-cell"
        >
          {t('msg_codes_voucherProductName')}
        </TableCell>
        <TableCell
          key="product-name-second-label-cell"
          id="product-name-second-label-cell"
          className="label-cell"
        />
        <TableRow
          key="code-product-row"
          id="code-product-row"
        >
          <TableCell isFirstCell key="original-code-product" id="original-code-product">
            <b>{originalCodeProduct.codeProduct}</b>
          </TableCell>
          <TableCell key="new-code-product" id="new-code-product">
            <b>{matchingCodeProduct.codeProduct}</b>
          </TableCell>
        </TableRow>
        <TableRow
          key="code-product-id-row"
          id="code-product-id-row"
        >
          <TableCell
            key="product-id-first-label-cell"
            id="product-id-first-label-cell"
            className="label-cell"
          >
            {t('msg_codes_codes_productID')}
          </TableCell>
          <TableCell
            key="product-id-second-label-cell"
            id="product-id-second-label-cell"
            className="label-cell"
          />
          <TableCell isFirstCell key="original-code-product-id" id="original-code-product-id">
            <b>{originalCodeProduct.productId}</b>
          </TableCell>
          <TableCell key="new-code-product-id" id="new-code-product-id">
            <b>{matchingCodeProduct.productId}</b>
          </TableCell>
        </TableRow>
        <TableRow
          key="code-product-region-row"
          id="code-product-region-row"
        >
          <TableCell
            key="product-region-first-label-cell"
            id="product-region-first-label-cell"
            className="label-cell"
          >
            {t('msg_codes_common_region')}
          </TableCell>
          <TableCell
            key="product-region-second-label-cell"
            id="product-region-second-label-cell"
            className="label-cell"
          />
          <TableCell isFirstCell key="original-code-product-region" id="original-code-product-region">
            <b>{originalCodeProduct.region}</b>
          </TableCell>
          <TableCell key="new-code-product-region" id="new-code-product-region">
            <b>{matchingCodeProduct.region}</b>
          </TableCell>
        </TableRow>
      </DataTable>
      <footer className="code-product-swap-footer">
        <Button
          onClick={closeSwapModal}
        >
          {t('msg_codes_cta_cancel')}
        </Button>
        <Button
          primary
          disabled={!isValidSwap}
          onClick={() => confirmSwapProduct(
            originalCodeProduct.codeProduct,
            matchingCodeProduct.codeProduct,
          )}
        >
          {t('msg_codes_swap_code_product')}
        </Button>
      </footer>
    </>
  );

  const modalProps = {
    id: 'swap-code-product-modal',
    name: 'swap-code-product-modal',
    title: t('msg_codes_swap_code_product'),
    content: modalBody,
    onPrimary: () => confirmSwapProduct(),
    onSecondary: closeSwapModal,
    onClose: closeSwapModal,
    hideCancelButton: true,
  };

  return (<Modal {...modalProps} />);
};

CodeProductSwapModal.propTypes = {
  orderLineId: PropTypes.number.isRequired,
  originalCodeProduct: PropTypes.object.isRequired,
  notificationContext: PropTypes.object.isRequired,
  orderNumber: PropTypes.number.isRequired,
};

export default CodeProductSwapModal;
