import React from 'react';
import {
  TableHeader, TableHeaderCell, DataTable,
  GroupState,
} from '@partner-global-ui/components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CoverageRow from './CoverageRow';
import './VoucherCoverageTable.scss';

const VoucherForm = ({
  countries,
}) => {
  const { t } = useTranslation();
  const renderCountryRows = () => {
    const countryRows = countries.map((country, i) => {
      return (
        <CoverageRow
          key={`${country.code}${i.toString()}`}
          country={country}
        />
      );
    });
    return countryRows;
  };

  return (
    <DataTable
      columnTemplate="minmax(auto, 1fr) minmax(auto, 3fr)"
      scrollHeight={400}
      hasCheckbox
      id="voucher-form-header"
      data-testid="voucher-form-header"
    >
      <TableHeader checkboxState={GroupState.ALL}>
        <TableHeaderCell
          key="country"
          value="country"
          id="country"
        >
          {t('msg_codes_agreements_country')}
        </TableHeaderCell>
        <TableHeaderCell
          key="voucherFee"
          value="voucherFee"
          id="voucherFee"
        >
          {t('msg_codes_agreements_codeFee')}
        </TableHeaderCell>
      </TableHeader>
      {renderCountryRows()}
    </DataTable>
  );
};

VoucherForm.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.object),
};

VoucherForm.defaultProps = {
  countries: [{
    name: 'United States',
    currency: 'USD',
    settlementCurrency: 'USD',
    commissionRate: 0.00,
    selected: false,
    code: 'US',
  }],
};

export default VoucherForm;
