import il8n from 'i18next';
import axios from 'axios';
import { uniq } from 'lodash';

import * as types from './actionTypes';
import paramBuilder from '../utils/paramBuilder.utils';
import { getOrderActivityRules } from './thirdPartyCatalogActions';
import { autoHide, timeout } from '../constants/notifications.constant';
import * as activityType from '../constants/activityType.constants';
import thirdPartyDownloadStatus from '../utils/thirdPartyDownloadStatus';
import ordersHistoryStatus from '../utils/ordersHistoryStatus';
import getFeature from '../utils/accessControl/getFeature';


export const actionCallback = (externalUrl) => {
  return () => {
    window.open(externalUrl);
  };
};

export function beginLoadOrderHistory() {
  return { type: types.BEGIN_LOAD_ORDER_HISTORY };
}

export function setOrderHistoryCatalog(orderHistoryCatalog) {
  return { type: types.SET_ORDER_HISTORY_CATALOG, orderHistoryCatalog };
}

export function finishLoadOrderHistory() {
  return { type: types.FINISH_LOAD_ORDER_HISTORY };
}

export function updateProduct(product) {
  return { type: types.ORDER_HISTORY_UPDATE_PRODUCT, product };
}

export function pendingResponse(order) {
  return { type: types.ORDER_HISTORY_PENDING_RESPONSE, order };
}

export function pendingResponseClear(order) {
  return { type: types.ORDER_HISTORY_PENDING_RESPONSE_CLEARED, order };
}

export function updateSubmittedOrder(order) {
  return { type: types.ORDER_HISTORY_UPDATE_FROM_SUBMISSION, order };
}

export function updateOrderError(order) {
  return { type: types.ORDER_HISTORY_ERROR_FROM_SUBMISSION, order };
}

export function updateOrderQuantity(orderId, quantity) {
  return { type: types.UPDATE_ORDER_QUANTITY, orderId, quantity };
}

export function updateOrderUseCount(orderId, useCount) {
  return { type: types.UPDATE_ORDER_USE_COUNT, orderId, useCount };
}

export function updateOrderComments(orderId, comments) {
  return { type: types.UPDATE_ORDER_COMMENTS, orderId, partnerComments: comments };
}

export function updateOrderPoNumber(orderId, poNumber) {
  return { type: types.UPDATE_ORDER_PO_NUMBER, orderId, poNumber };
}

export function updateOrderPoFile(orderId, fileType, poFile) {
  return {
    type: types.UPDATE_ORDER_PO_FILE, orderId, fileType, poFile,
  };
}

export function updateOrderStartDate(orderId, startDate) {
  return { type: types.UPDATE_ORDER_START_DATE, orderId, startDate };
}

export function updateOrderStartTime(orderId, startTime) {
  return { type: types.UPDATE_ORDER_START_TIME, orderId, startTime };
}

export function updateOrderEndDate(orderId, endDate) {
  return { type: types.UPDATE_ORDER_END_DATE, orderId, endDate };
}

export function updateOrderEndTime(orderId, endTime) {
  return { type: types.UPDATE_ORDER_END_TIME, orderId, endTime };
}

export function updateOrderTimeZone(orderId, timeZone) {
  return { type: types.UPDATE_ORDER_TIME_ZONE, orderId, timeZone };
}

export function updateOrderDownloadError(orderId, error) {
  return { type: types.UPDATE_ORDER_DOWNLOAD_ERROR, orderId, error };
}

export function updateOrderRetryError(orderId, error) {
  return { type: types.UPDATE_ORDER_RETRY_ERROR, orderId, error };
}

export const getContractualErrorMessage = (contractualErrorCode) => {
  let errorMessage;
  if (!contractualErrorCode) return null;
  switch (contractualErrorCode) {
    case 'INVALID_VOUCHER_TYPE':
      errorMessage = 'Invalid Voucher Type';
      break;
    case 'BATCH_LABEL_IN_USE':
      errorMessage = 'Batch Label In Use';
      break;
    case 'BATCH_ALREADY_ACTIVE':
      errorMessage = 'Batch Label Already Active';
      break;
    case 'BATCH_ALREADY_CANCELLED':
      errorMessage = 'Batch Already Cancelled';
      break;
    case 'PREEXISTING_ACTIVE_VOUCHERS':
      errorMessage = 'Pre-existing Active Vouchers';
      break;
    default:
      errorMessage = 'Something Unexpected Went Wrong';
      break;
  }
  return errorMessage;
};

const filterParam = (filters) => {
  const activityTypeParam = filters.activityTypes ? paramBuilder(filters.activityTypes, 'activity') : '';
  const statusParam = filters.status ? paramBuilder(filters.status, 'status') : '';
  const regionParam = filters.region ? paramBuilder(filters.region, 'region') : '';

  const startDate = filters.creationDate[0] ? `&creationDateStart=${filters.creationDate[0]}` : '';
  const endDate = filters.creationDate[1] ? `&creationDateEnd=${filters.creationDate[1]}` : '';

  return (
    activityTypeParam
    + statusParam
    + regionParam
    + startDate
    + endDate
  );
};

export async function loadOrderHistory(
  dispatch,
  partnerId,
  size = 10, page = 0, search = {},
  sort = { sortBy: 'startDate', sortDir: 'asc' },
  filters = {
    activityTypes: [], region: [], status: [], creationDate: [],
  },
) {
  const filtersParams = filterParam(filters);
  const sortParam = `?sort=${sort.sortBy},${sort.sortDir}`;
  const pageParam = `page=${page}`;
  const sizeParam = `size=${size}`;
  const searchParam = search.category && search.term ? `&${search.category}=${search.term}` : '';
  const partnerParam = partnerId ? `&partnerId=${partnerId}` : '';
  const url = `/venom/api/third-party-orders${sortParam}&${pageParam}&${sizeParam}${filtersParams}${searchParam}${partnerParam}`;

  (async () => {
    getOrderActivityRules(dispatch);
  })();

  dispatch(beginLoadOrderHistory());
  try {
    const response = await axios.get(url);
    if (response.status !== 200) {
      dispatch(finishLoadOrderHistory());
      throw Error(response.statusText);
    }
    const orderHistory = await response.data;
    dispatch(setOrderHistoryCatalog(orderHistory.content));
    dispatch(finishLoadOrderHistory());
    if (orderHistory && orderHistory.content) {
      orderHistory.content.forEach((order) => {
        const contractualError = getContractualErrorMessage(order && order.contractualErrorCode);
        if (contractualError) {
          dispatch(updateOrderRetryError(order.orderId, contractualError));
        }
      });
    }
    return orderHistory;
  } catch (error) {
    //  TODO: error handling (optizmized and without logs)
    return null;
  }
}

export async function getOrderStatus(
  dispatch, localDispatch, downloadQueue, retryQueue, order,
) {
  const { orderId = [] } = order;
  const thirdPartyOrders = uniq([orderId]);
  const url = '/venom/api/third-party-orders/status';
  const postBody = {
    thirdPartyOrders,
  };

  try {
    const response = await axios.post(url, {
      ...postBody,
    });

    const statusResponse = await response.data;
    const {
      status,
      fileRegenerating,
      orderStatus,
      contractualErrorCode,
    } = statusResponse.content[0];
    const orderDetails = [order];
    const alreadyInDownloadQueue = downloadQueue.some(item => item.orderId === order.orderId);
    const alreadyInRetryQueue = retryQueue.some(item => item.orderId === order.orderId);

    if (
      alreadyInDownloadQueue
      && (
        (typeof status !== 'undefined' && status !== thirdPartyDownloadStatus.GENERATING_EXPIRED_FILE.statusName)
        || (typeof fileRegenerating !== 'undefined' && !fileRegenerating)
      )
    ) {
      localDispatch({ type: 'removeFromDownloadQueue', orderDetails });
    }

    if (
      alreadyInRetryQueue && orderStatus !== ordersHistoryStatus.PROCESSING.statusName
    ) {
      localDispatch({ type: 'removeFromRetryQueue', orderDetails });
    }
    if (
      contractualErrorCode
    ) {
      const errorMessage = getContractualErrorMessage(contractualErrorCode);
      dispatch(updateOrderRetryError(orderId, errorMessage));
    }
    dispatch(updateProduct(statusResponse.content[0]));
    return statusResponse.content[0];
  } catch (error) {
    //  TODO: error handling (optizmized and without logs)
    return null;
  }
}

export async function downloadOrder(
  dispatch,
  localDispatch,
  downloadQueue,
  order,
  notificationContext,
) {
  const { activity, orderId, voucherCatalogId } = order;
  const url = activityType[activity] === 'Publisher Free Allocation'
    ? `/venom/api/third-party-catalogs/${voucherCatalogId}/download`
    : `/venom/api/third-party-orders/${orderId}/download`;

  try {
    const response = await axios.post(url);
    if (response.status !== 200) {
      notificationContext.dispatch({
        type: 'add',
        payload: {
          status: 'error',
          autoHide,
          timeout,
          message: il8n.t('msg_codes_codes_banner_somethingWentWrong'),
        },
      });
      dispatch(updateOrderDownloadError(orderId, response));
    }

    const downloadProductResponse = await response.data;
    if (downloadProductResponse
      && (downloadProductResponse.errorCode
        || downloadProductResponse.contractualErrorCode)) {
      dispatch(updateOrderDownloadError(
        orderId,
        getContractualErrorMessage(downloadProductResponse.contractualErrorCode),
      ));
      notificationContext.dispatch({
        type: 'add',
        payload: {
          status: 'error',
          autoHide,
          timeout,
          message: il8n.t('msg_codes_codes_banner_somethingWentWrong'),
        },
      });
    }
    const { downloadUrl, status, fileRegenerating } = downloadProductResponse;
    const orderDetails = [order];
    const alreadyInDownloadQueue = downloadQueue.some(item => item.orderId === order.orderId);

    if (
      !alreadyInDownloadQueue
      && (
        (typeof status !== 'undefined' && status === thirdPartyDownloadStatus.GENERATING_EXPIRED_FILE.statusName)
        || (typeof fileRegenerating !== 'undefined' && fileRegenerating)
      )
    ) {
      localDispatch({ type: 'addToDownloadQueue', orderDetails });
    }

    if (
      typeof downloadUrl !== 'undefined'
      && downloadUrl !== null
    ) {
      window.open(downloadUrl);
      localDispatch({ type: 'removeFromDownloadQueue', orderDetails });
      localDispatch({ type: 'toggleRow', orderId });
    }
    const downloadHelpUrl = getFeature('downloadHelpUrl');

    dispatch(updateProduct(downloadProductResponse));
    notificationContext.dispatch({
      type: 'add',
      payload: {
        status: 'success',
        autoHide,
        timeout,
        message: il8n.t('msg_codes_codes_banner_codesDownloaded'),
        actionText: il8n.t('msg_codes_cta_learnMore'),
        actionCallback: actionCallback(downloadHelpUrl),
      },
    });
    return downloadProductResponse;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function submitOrder(dispatch, notificationContext, order) {
  const {
    orderId,
    revision,
    poNumber,
    quantity,
    startDate,
    endDate,
    partnerComments,
    isPoNumberUpdated,
    isStartDateUpdated,
    isStartTimeUpdated,
    isEndDateUpdated,
    isEndTimeUpdated,
    isQuantityUpdated,
    isPoFileUpdated,
    isCommentsUpdated,
    poFile,
  } = order;

  const url = `/venom/api/third-party-orders/${orderId}/update`;
  const formBody = {};
  if (isPoNumberUpdated) {
    formBody.poNumber = poNumber;
  }
  if (isStartDateUpdated || isStartTimeUpdated) {
    formBody.startDate = startDate;
  }
  if (isEndDateUpdated || isEndTimeUpdated) {
    formBody.endDate = endDate;
  }
  if (isQuantityUpdated) {
    formBody.quantity = quantity;
  }
  if (isCommentsUpdated) {
    formBody.partnerComments = partnerComments;
  }
  const requestBody = new FormData();
  if (
    isPoNumberUpdated
    || isStartDateUpdated
    || isEndDateUpdated
    || isQuantityUpdated
    || isStartTimeUpdated
    || isEndTimeUpdated
    || isCommentsUpdated
  ) {
    requestBody.append('json', JSON.stringify(formBody));
  }
  if (isPoFileUpdated) {
    requestBody.append(
      'file',
      await fetch(poFile.file).then(r => r.blob()),
      poFile.name,
    );
  }
  const eTag = `"${revision}"`;

  try {
    dispatch(pendingResponse(order));
    const response = await axios.patch(
      url,
      requestBody,
      {
        headers: {
          'if-match': eTag,
        },
      },
    );

    if (response.status !== 200) {
      dispatch(updateOrderError(orderId, response));
      notificationContext.dispatch({
        type: 'add',
        payload: {
          status: 'error',
          autoHide,
          timeout,
          message: il8n.t('msg_codes_codes_banner_somethingWentWrong'),
        },
      });
    }

    if (response.status === 200) {
      notificationContext.dispatch({
        type: 'add',
        payload: {
          status: 'success',
          autoHide,
          timeout,
          message: il8n.t('Order has been submitted successfully.'),
        },
      });
    }

    const responseBody = await response.data;
    if (response.status === 400) {
      const rejectedOrder = {
        ...order,
        errorResponse: responseBody,
      };
      dispatch(updateSubmittedOrder(rejectedOrder));
      return order;
    }
    dispatch(updateSubmittedOrder(responseBody));
    return responseBody;
  } catch (error) {
    //  TODO: error handling (optizmized and without logs)
    dispatch(pendingResponseClear(order));
    return null;
  }
}

export async function retryOrder(dispatch, localDispatch, retryQueue, order, notificationContext) {
  const orderDetails = [order];
  const { orderId } = order;
  const alreadyInRetryQueue = retryQueue.some(item => item.orderId === order.orderId);
  if (!alreadyInRetryQueue) {
    localDispatch({ type: 'addToRetryQueue', orderDetails });
  }
  const url = `/venom/api/third-party-orders/${orderId}/retry`;

  try {
    const response = await axios.post(url);

    if (response.status !== 200) {
      notificationContext.dispatch({
        type: 'add',
        payload: {
          status: 'error',
          autoHide,
          timeout,
          message: il8n.t('msg_codes_codes_banner_somethingWentWrong'),
        },
      });
    }
    const retriedProduct = await response.data;
    if (
      alreadyInRetryQueue
      && retriedProduct.orderStatus !== ordersHistoryStatus.PROCESSING.statusName
    ) {
      localDispatch({ type: 'removeFromRetryQueue', orderDetails });
    }
    dispatch(updateProduct(retriedProduct));
    return retriedProduct;
  } catch (error) {
    //  TODO: error handling (optizmized and without logs)
    return error;
  }
}

export function downloadPoFile(orderId) {
  return (async () => {
    const response = await axios.post(`/venom/api/third-party-orders/${orderId}/download-po-file`);

    const orderWithFile = await response.data;
    const { poFileUrl } = orderWithFile;
    if (orderWithFile) {
      window.open(poFileUrl);
    }
    return poFileUrl;
  });
}

export function ordersDownloadHistory(orderId, setLoadingDownloadHistory) {
  setLoadingDownloadHistory(true);
  const url = `/venom/api/third-party-orders/${orderId}/downloadHistory`;
  return axios.get(url, {
    method: 'GET',
    credentials: 'include',
  })
    .then((response) => {
      if (response.status !== 200) {
        setLoadingDownloadHistory(false);
        return Promise.reject(response);
      }
      return response.data;
    })
    .then((response) => {
      setLoadingDownloadHistory(false);
      return response;
    });
}
