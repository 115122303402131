import React, {
  useEffect,
  useCallback,
} from 'react';
import {
  Select,
  DateInput,
  TextArea,
} from '@partner-global-ui/components';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import '../Agreements.scss';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import {
  agreementStatuses,
  versionStatuses,
  DRAFT,
  ABANDONED,
  VersionStatusStringMapping,
  AgreementStatusStringMapping,
} from '../../../constants/agreementState.constants';
import * as agreementFormActions from '../../../actions/agreementFormActions';
import hasPermission from '../../../utils/accessControl/hasPermission';
import roleKeys from '../../../utils/accessControl/roleKeys';

const AgreementVersionComponent = ({
  canEdit,
  setRadio,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    updateAgreementProp,
    updateDraftAgreementVersion,
  } = agreementFormActions;

  const state = useSelector(globalState => globalState);

  const {
    agreementForm: {
      initialStatuses,
      draftAgreementVersion,
      draftAgreementVersion: selectedAgreementVersion,
      agreement: {
        agreementVersions,
        isNewAgreement,
        status,
      },
      isNewVersion,
      canEditVersion,
    },
  } = useSelector(globalState => globalState);

  const isFirstVersion = agreementVersions.length === 1 && !draftAgreementVersion.isNewVersion;
  const showAgreementVersionDetails = hasPermission(
    state, roleKeys.agreement.showAgreementVersionDetails,
  );

  const agreementStatusOptions = agreementStatuses[initialStatuses.status]
    .map((option) => {
      return {
        label: t(AgreementStatusStringMapping[option]),
        value: option,
      };
    });

  const selectedAgreementStatusOption = agreementStatusOptions
    .find(option => option.value === status);

  let effectiveDate;
  if (selectedAgreementVersion.agreementVersionEffectiveDate) {
    effectiveDate = moment(selectedAgreementVersion.agreementVersionEffectiveDate).utc().format('YYYY-MM-DD');
  }

  const getVersionStatusOptions = () => {
    if (isNewAgreement || isNewVersion) {
      return versionStatuses[''];
    }

    if (
      initialStatuses.versionStatus === 'FINAL'
      && moment(effectiveDate, 'MM/DD/YYYY').isAfter(moment(), 'days')
    ) {
      return [...versionStatuses[initialStatuses.versionStatus], 'DELETED'];
    }

    return versionStatuses[initialStatuses.versionStatus];
  };

  const versionStatusOptions = getVersionStatusOptions().map((option) => {
    return {
      label: t(VersionStatusStringMapping[option]),
      value: option,
    };
  });

  const selectedVersionStatusOption = versionStatusOptions
    .find(option => option.value === selectedAgreementVersion.versionStatus);

  useEffect(() => {
    setRadio(selectedAgreementVersion.voucherRedemptionCallbackMandatory);
  }, []);


  const handleStatus = (e) => {
    const { name, value: { value } } = e.target;
    dispatch(
      updateAgreementProp(name, value),
    );
  };

  const agreementStatusEnabled = () => {
    if (canEdit) {
      if (!isFirstVersion) {
        return true;
      }
      if (
        (isNewAgreement
          || (initialStatuses.versionStatus === DRAFT && !isNewVersion))
        || initialStatuses.versionStatus === ABANDONED
      ) {
        return false;
      }
      return true;
    }
    return false;
  };

  const handleEdit = (e) => {
    const { target: { name, value } } = e;

    if (name === 'versionStatus') {
      dispatch(
        updateDraftAgreementVersion({ [name]: value }, agreementStatusEnabled()),
      );
    } else if (name === 'voucherRedemptionCallbackGateway') {
      const voucherRedemptionCallbackMandatory = null;
      setRadio(null);
      dispatch(
        updateDraftAgreementVersion({
          [name]: value,
          voucherRedemptionCallbackMandatory,
        }),
      );
    } else {
      dispatch(
        updateDraftAgreementVersion({ [name]: value }),
      );
    }
  };

  const handleAgreementVersion = (e) => {
    const { target: { name, value: { value } } } = e;

    if (name === 'versionStatus') {
      dispatch(
        updateDraftAgreementVersion({ [name]: value }, agreementStatusEnabled()),
      );
    } else if (name === 'voucherRedemptionCallbackGateway') {
      const voucherRedemptionCallbackMandatory = null;
      setRadio(null);
      dispatch(
        updateDraftAgreementVersion({
          [name]: value,
          voucherRedemptionCallbackMandatory,
        }),
      );
    } else {
      dispatch(
        updateDraftAgreementVersion({ [name]: value }),
      );
    }
  };

  const handleEditDate = useCallback((e) => {
    const { target: { value } } = e;
    if (e && value !== effectiveDate) {
      const agreementVersionEffectiveDate = typeof e === 'string'
        ? undefined
        : moment.utc(value).toISOString();
      dispatch(
        updateDraftAgreementVersion({ agreementVersionEffectiveDate }),
      );
    }
  }, [effectiveDate]);

  return (
    <div data-testid="agreement-version-wrap" className="agreement-version-wrap">
      <div data-testid="agreement-version-content" className="agreement-version-content">
        <Select
          label={t('msg_codes_agreements_agreementStatus_label')}
          name="status"
          id="status"
          required
          options={agreementStatusOptions}
          disabled={!agreementStatusEnabled()}
          onChange={handleStatus}
          value={selectedAgreementStatusOption}
          placeholder={t('msg_codes_common_select')}
        />
        <Select
          label={t('msg_codes_agreements_agreementVersionStatuslabel')}
          name="versionStatus"
          id="versionStatusSelect"
          required
          options={versionStatusOptions}
          disabled={!canEdit}
          onChange={handleAgreementVersion}
          value={selectedVersionStatusOption}
          placeholder={t('msg_codes_common_select')}
        />
        <DateInput
          id="agreementVersionEffectiveDate"
          name="agreementVersionEffectiveDate"
          label={t('msg_codes_agreements_agreementVersionEffectiveDateUTC')}
          onChange={e => handleEditDate(e)}
          disableBefore={moment().subtract(1, 'day')}
          required={false}
          value={effectiveDate}
          disabled={!(canEditVersion && canEdit)}
          tooltip={t('msg_codes_tooltip_agreementVersionEffectiveDateUTC')}
        />
      </div>
      {showAgreementVersionDetails && (
        <TextArea
          label={t('msg_codes_agreements_agreementVersionComments')}
          id="agreementVersionComments"
          value={selectedAgreementVersion.agreementVersionComments}
          disabled={!(canEditVersion && canEdit)}
          charCountMax={500}
          onChange={handleEdit}
          placeholder={t('msg_codes_codeProdCat_typeSomething_dropdown')}
        />
      )}
    </div>
  );
};

AgreementVersionComponent.propTypes = {
  canEdit: PropTypes.bool,
  setRadio: PropTypes.func,
};

AgreementVersionComponent.defaultProps = {
  canEdit: false,
  setRadio: () => {},
};

export default withRouter(AgreementVersionComponent);
