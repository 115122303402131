import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function voucherTypeReducer(state = initialState.voucherType, action) {
  switch (action.type) {
    case types.CHANGE_VOUCHER_TYPE_PROP:
      return {
        ...state,
        [action.name]: action.value,
      };
    case types.LOAD_NEW_VOUCHER_TYPE:
      return initialState.voucherType.voucherType;
    case types.LOAD_VOUCHER_TYPE_SUCCESS:
      return action.voucherType;
    case types.SAVE_VOUCHER_TYPE_SUCCESS:
      return action.voucherType;
    case types.RESET_VOUCHER_TYPE:
      return initialState.voucherType;
    default:
      return state;
  }
}
