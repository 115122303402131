import React from 'react';
import PropTypes from 'prop-types';
import {
  TableRow,
  TableCell,
  GroupState,
} from '@partner-global-ui/components';
import { useTranslation } from 'react-i18next';
import { formatDateTimeInLocal } from '../../../utils/formatDateTime';

const VoucherType = ({
  voucherType,
  voucherType: {
    id,
    name,
    npType,
    dateModified,
    npRegion,
    skusCount,
  },
  isSelected,
  handleCheckBoxChange,
  isProductVoucherType = false,
  ...rest
}) => {
  const { t } = useTranslation();
  const checkboxState = isSelected ? GroupState.ALL : GroupState.NONE;
  const npTypeLowerCase = {
    MONEY: t('msg_codes_voucherType_money_lowerCase'),
    PRODUCT: t('msg_codes_voucherType_product_lowerCase'),
  };

  console.log('voucherType', npTypeLowerCase[npType]);
  return (
    <TableRow
      id={`voucher-type-row-${voucherType.id}`}
      key={id}
      pad
      value={voucherType}
      hasCheckbox
      className="voucher-type-row"
      checkboxState={checkboxState}
      onCheckboxClick={() => handleCheckBoxChange(id)}
      {...rest}
    >
      <TableCell id={`voucher-type-name-${id}`} key={`voucher-type-name-${id}`} className="voucher-type-name">
        {name}
      </TableCell>
      <TableCell id={`voucher-type-id-${id}`} key={`voucher-type-id-${id}`} className="voucher-type-id">
        {id}
      </TableCell>
      <TableCell id={`voucher-type-code-${id}`} key={`voucher-type-code-${id}`} className="voucher-type-code">
        {npTypeLowerCase[npType]}
      </TableCell>
      <TableCell id={`last-updated-${id}`} key={`last-updated-${id}`} className="last-updated">
        {formatDateTimeInLocal(dateModified)}
      </TableCell>
      <TableCell id={`voucher-type-npRegion-${id}`} key={`voucher-type-npRegion-${id}`} className="voucher-type-npRegion">
        {npRegion}
      </TableCell>
      {isProductVoucherType ? (
        <TableCell id={`voucher-type-skus-count-${id}`} key={`voucher-type-skus-count-${id}`} className="voucher-type-skus-count">
          {skusCount}
        </TableCell>
      ) : <></>}
    </TableRow>
  );
};

VoucherType.propTypes = {
  voucherType: PropTypes.object,
  handleCheckBoxChange: PropTypes.func,
  isSelected: PropTypes.bool,
  isProductVoucherType: PropTypes.bool,
};

VoucherType.defaultProps = {
  voucherType: {},
  handleCheckBoxChange: () => null,
  isSelected: false,
  isProductVoucherType: false,
};

export default VoucherType;
