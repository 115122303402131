export default (response) => {
  if (response.ok) {
    return '';
  }
  let err = `Operation failed with error ${response.status}`;
  if (response.statusText) {
    err += `: ${response.statusText}`;
  }
  return err;
};
