import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

// import InformationContent from '../shared/informationContent';
// import InteractiveContent from '../shared/interactiveContent';
import PrimaryRow from '../shared/expandedContent/primaryRow';
import SecondaryRow from '../shared/expandedContent/secondaryRow';
import TertiaryRow from '../shared/expandedContent/tertiaryRow';

import {
  updateCartQuantity,
  updateCartComments,
  updateCartStartDate,
  updateCartEndDate,
} from '../../../actions/cartProductsActions';
import {
  showPurchaseOrderInput,
  isFreeAllocationActivityType,
  isPaidAllocationActivityType,
} from '../shared/orderValidation';
import { activitySelectTypes } from '../../../constants/activityType.constants';

const shouldShowUseCount = (activity) => {
  return !(isFreeAllocationActivityType(activity)
    || isPaidAllocationActivityType(activity));
};

function ExpandedCartRow({
  product: {
    codeName,
    version,
    urlToScpPar,
    voucherSku,
    availabilityRegion,
    countryAvailability,
    orderActivities,
    activity,
    quantity,
    cartId,
    partnerComments,
    poNumber,
    billable,
    standardParId,
  },
  product,
  setCartProducts,
  setPoFileAddedNotSubmitted,
  tableRef,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const initialActivitiesState = orderActivities.length
    ? orderActivities
    : typeof activity !== 'undefined'
      ? [activity]
      : [];
  const activityOptions = initialActivitiesState.map((activityOption) => {
    const option = activitySelectTypes[activityOption] || { name: null, value: null };
    return { label: t(option.translationStringId), value: option.value };
  }).filter(activityOption => activityOption.value !== null);
  const selectedActivity = {
    ...activitySelectTypes[activity],
    label: t(activitySelectTypes[activity].translationStringId),
  };

  // Page Specific handling is implemented in the expanded components

  const handleQuantityChange = ({ target: { value } }) => {
    dispatch(updateCartQuantity(cartId, value));
  };

  const handlePartnerCommentsChange = ({ target: { value } }) => {
    dispatch(updateCartComments(cartId, value));
  };

  const handleDateTimeChange = (dispatchMethod) => {
    return (dateTime) => {
      dispatch(dispatchMethod(cartId, dateTime));
    };
  };

  return (
    <div>
      <PrimaryRow
        tableRef={tableRef}
        codeName={codeName}
        version={version}
        urlToScpPar={urlToScpPar}
        sku={voucherSku}
        availabilityRegion={availabilityRegion}
        countryAvailability={countryAvailability}
        activity={selectedActivity}
        activityOptions={activityOptions}
        showQuantityInput
        showUseCount={shouldShowUseCount(selectedActivity)}
        product={product}
        quantity={quantity}
        handleQuantityChange={handleQuantityChange}
        name={cartId}
        standardParId={standardParId}
      />
      <SecondaryRow
        canEditDates
        tableRef={tableRef}
        isCartRowView
        order={product}
        activity={selectedActivity}
        catalogPoNumber={poNumber}
        setCartProducts={setCartProducts}
        setPoFileAddedNotSubmitted={setPoFileAddedNotSubmitted}
        handleStartDateTimeChange={handleDateTimeChange(updateCartStartDate)}
        handleEndDateTimeChange={handleDateTimeChange(updateCartEndDate)}
        startDate={product.startDate}
        endDate={product.endDate}
        showPurchaseOrderInput={showPurchaseOrderInput(billable, selectedActivity.value)}
        name={cartId}
      />
      <TertiaryRow
        tableRef={tableRef}
        partnerComments={partnerComments}
        handlePartnerCommentsChange={handlePartnerCommentsChange}
        name={cartId}
      />
    </div>
  );
}

ExpandedCartRow.propTypes = {
  product: PropTypes.object,
  setCartProducts: PropTypes.func,
  setPoFileAddedNotSubmitted: PropTypes.func,
  tableRef: PropTypes.object,
};

ExpandedCartRow.defaultProps = {
  product: { },
  setCartProducts: () => { },
  setPoFileAddedNotSubmitted: () => {},
  tableRef: {},
};

export default ExpandedCartRow;
