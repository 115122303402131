import moment from 'moment-timezone';

export const isTimeMatched = (date, time) => {
  const parsedDate = moment.utc(date);
  const parsedTime = moment.utc(time);
  parsedTime.set('year', parsedDate.get('year'));
  parsedTime.set('month', parsedDate.get('month'));
  parsedTime.set('date', parsedDate.get('date'));
  return moment.utc(date).isSame(parsedTime);
};

export const formatDateTimeInLocal = (utcDate) => {
  const momentObject = moment.utc(utcDate);
  if (!momentObject.isValid()) return null;
  return momentObject.tz(moment.tz.guess()).format('MM/DD/YYYY HH:mm:ss');
};

export const formatDateTimeInLocalWithUTC = (date) => {
  const momentObject = moment.utc(date);
  if (!momentObject.isValid()) return null;
  return momentObject.tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm z [(UTC ]Z[)]');
};

export default date => moment.utc(date).format('MM/DD/YYYY HH:mm:ss');
