import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import fileTypeUtil from 'file-type/browser';
import {
  Flex,
  Layout,
  Collapsible,
  DataTable,
  TableHeader,
  TableHeaderCell,
  NoResults,
  NotificationContext,
  ModalContext,
  Modal,
  MODAL_ACTIONS,
  Button,
} from '@partner-global-ui/components';

import OrderDocumentLine from './OrderDocumentLine';
import { submitOrderFile, setOrderDocumentsScanStatus } from '../../../../actions/orderActions';
import { usePermission } from '../../../../utils/accessControl/hasPermission';
import roleKeys from '../../../../utils/accessControl/roleKeys';
import { autoHide, timeout } from '../../../../constants/notifications.constant';
import pollingInterval from '../../../../constants/pollingInterval.constants';
import * as scanStatuses from '../../../../constants/scanStatus.constants';
import './OrderDocuments.scss';

const OrderDocuments = ({
  order: {
    orderNumber,
    revision,
    attachments = [],
    orderStatus,
  },
}) => {
  //  Setup
  const { t } = useTranslation();
  const ref = useRef(null);
  const pendingVirusScanQueueRef = useRef([]);
  const dispatch = useDispatch();
  const canShareWithPartner = usePermission(roleKeys.orderDocuments.viewShareWithPartner);
  const canUploadAttachment = usePermission(roleKeys.orderDocuments.uploadAttachment, orderStatus);
  const [maxFiles, setMaxFiles] = useState(false);
  const [fileType, setFileType] = useState();
  const [fileData, setFileData] = useState({});
  const notificationCont = useContext(NotificationContext);
  const modalContext = useContext(ModalContext);
  const columnTemplate = '16% 40% 12% 12% 20%';

  useEffect(() => {
    setMaxFiles(attachments.length >= 5);

    const pendingStatusQueue = attachments.filter(
      attachment => scanStatuses.PENDING === attachment.scanStatus,
    );

    pendingVirusScanQueueRef.current = pendingStatusQueue;
  }, [attachments]);

  /* handle order documents polling */
  useEffect(() => {
    let intervalId;

    /* initiate polling when line is processing */
    if (pendingVirusScanQueueRef.current.length) {
      intervalId = setInterval(() => {
        dispatch(setOrderDocumentsScanStatus(orderNumber));
      }, pollingInterval);
    }

    /* clear interval when component unmounts */
    return () => clearInterval(intervalId);
  }, [pendingVirusScanQueueRef.current]);

  const isValidFile = () => {
    return (
      fileType === 'pdf'
      && fileData.size >= 10240
      && fileData.size <= 10485760);
  };

  // upload pdf
  const generateFileStats = useCallback((file) => {
    const reader = new FileReader();
    reader.onload = async (loadedEvent) => {
      const arrayBuffer = loadedEvent.target.result;
      const { ext } = await fileTypeUtil.fromBuffer(arrayBuffer);
      setFileType(ext);
    };

    reader.readAsArrayBuffer(file);
  });

  const convertToFileObjectUrl = useCallback((localFile) => {
    setFileData({
      file: localFile,
      fileName: localFile.name,
      fileType: localFile.type,
    });
    const reader = new FileReader();
    reader.readAsDataURL(localFile);
    const file = URL.createObjectURL(localFile);
    reader.onload = async () => {
      const { size } = localFile;
      const { name } = localFile;

      setFileData({
        size,
        name,
        file,
      });
    };
    return fileData;
  });

  const uploadFile = () => {
    // select the dom element with the id of 'file-input'
    const fileInput = document.getElementById('file-input');
    // trigger the click event on the dom element
    fileInput.click();
  };

  const onFileSelect = async (event) => {
    if (
      event.target.files[0]
      && event.target.files[0].type === 'application/pdf'
      && event.target.files[0].size >= 10240
      && event.target.files[0].size < 10485760
    ) {
      generateFileStats(event.target.files[0]);
      convertToFileObjectUrl(event.target.files[0]);
      //  reset target after file selection
      event.target.value = '';
    } else {
      notificationCont.dispatch({
        type: 'add',
        payload: {
          status: 'error',
          autoHide,
          timeout,
          message: t('msg_codes_pdfFileSize_toast'),
          testId: 'error',
        },
      });
    }
  };

  // Modal
  const onModalCancel = useCallback(() => {
    modalContext.dispatch({ type: MODAL_ACTIONS.HIDE });
    setFileData({});
    setFileType();
  });

  const onSubmitClick = useCallback(() => {
    const documentFile = {
      name: fileData.name,
      size: fileData.size,
      type: fileType,
      file: fileData.file,
    };

    submitOrderFile(
      dispatch,
      notificationCont,
      orderNumber,
      revision,
      documentFile,
    );

    modalContext.dispatch({ type: MODAL_ACTIONS.HIDE });
    setFileData({});
    setFileType();
  });

  const renderContent = useCallback(() => {
    return (
      <>
        <p id="modal-body" data-testid="modal-body" className="custom-modal-body">
          {t('msg_codes_modal_uploadFile_body', { fileName: fileData.name })}
        </p>
        <div id="modal-footer" data-testid="modal-footer" className="custom-modal-footer">
          <span className="buttons-toolbar">
            <Button
              id="cancel-button"
              type="button"
              link
              onClick={() => onModalCancel()}
            >
              {t('msg_codes_cta_cancel')}
            </Button>
            <Button
              id="yes-button"
              primary
              type="submit"
              onClick={() => onSubmitClick()}
            >
              {t('msg_codes_common_yes')}
            </Button>
          </span>
        </div>
      </>
    );
  }, [fileType, fileData]);

  const modalProps = {
    width: 900,
    hideCancelButton: true,
    onPrimary: onSubmitClick,
    title: t('msg_codes_modal_uploadFile_header'),
    content: renderContent(),
  };

  useEffect(() => {
    if (isValidFile()) {
      modalContext.dispatch({
        type: MODAL_ACTIONS.SHOW,
        payload: <Modal {...modalProps} />,
      });
    }
  }, [fileType, fileData]);


  // labels for table headers
  const tableHeaders = [
    { label: t('msg_codes_common_date'), name: 'date' },
    { label: t('msg_codes_codes_fileName'), name: 'fileName' },
    { label: t(canShareWithPartner ? 'msg_codes_sharePartner' : ''), name: 'share' },
    { label: t('msg_codes_uploadedBy'), name: 'uploadedBy' },
    { label: '' },
  ];

  const renderHeaderCells = () => {
    return tableHeaders.map(({ label, name }, headerCellIndex) => {
      const headerKey = headerCellIndex + 1;
      return (
        <TableHeaderCell
          id={name}
          key={headerKey}
          value={label}
          className={label ? `${label}` : 'remove'}
        >
          {label}
        </TableHeaderCell>
      );
    });
  };

  const renderTableRows = useCallback(() => {
    return attachments.map((attachment, rowIndex) => {
      const rowKey = rowIndex + 1;
      return (
        <OrderDocumentLine
          orderNumber={orderNumber}
          orderDocument={attachment}
          canShareWithPartner={canShareWithPartner}
          rowKey={rowKey}
          orderStatus={orderStatus}
          anchorRef={ref}
        />
      );
    });
  }, [attachments]);

  const renderRightContent = useCallback(() => {
    if (maxFiles) {
      return t('msg_codes_maxFiles');
    }
    if (!canUploadAttachment) {
      return (<></>);
    }
    return [{ actionCallback: uploadFile, actionText: t('msg_codes_cta_newPDF') }];
  }, [maxFiles, canUploadAttachment]);

  return (
    <div ref={ref}>
      <Layout className="order-documents">
        <label htmlFor="file-input" hidden>
          <input type="file" id="file-input" data-testid="file-input" onChange={onFileSelect} accept="application/pdf" />
        </label>
        <Flex colSpan="12">
          <Collapsible
            id="order-documents-collapsible"
            heading={t('msg_codes_orderDocuments_header')}
            rightContent={renderRightContent()}
            defaultIsOpen
          >
            <DataTable
              id="order-documents-table"
              data-testid="order-documents-table"
              columnTemplate={columnTemplate}
            >
              {attachments.length ? (
                <TableHeader id="table-header">
                  {renderHeaderCells()}
                </TableHeader>
              ) : <></>}
              {attachments.length
                ? renderTableRows()
                : (
                  <NoResults
                    id="no-results"
                    title={t('msg_codes_nothing_here_yet')}
                    message=""
                  />
                )
              }
            </DataTable>
          </Collapsible>
        </Flex>
      </Layout>
    </div>
  );
};

OrderDocuments.propTypes = {
  order: PropTypes.object,
};

OrderDocuments.defaultProps = {
  order: {},
};

export default OrderDocuments;
