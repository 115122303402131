import i18n from 'i18next';

export const getErrorTooltip = (isCancelled, tooltipMessage) => {
  if (isCancelled) {
    return tooltipMessage;
  }
  return null;
};

export const getFulfillmentErrorTooltip = (
  fulfillmentErrors,
  fulfillmentErrorCode,
) => {
  const fulfillmentErrorReason = fulfillmentErrors.find(
    errors => errors.code === fulfillmentErrorCode,
  );

  return fulfillmentErrorReason && i18n.t(fulfillmentErrorReason.error);
};

export const getRemovedTooltip = (autoCancelReason, tooltipMessage) => {
  const canceledReasons = ['PARTNER_UNAVAILABLE', 'PRODUCT_INACTIVE', 'COUNTRY_UNAVAILABLE', 'AGREEMENT_TYPE_UNAVAILABLE'];
  const isProductChanged = canceledReasons.includes(autoCancelReason);

  if (isProductChanged) {
    return tooltipMessage;
  }
  return i18n.t('msg_codes_productTypeRemoved_tooltip');
};

export const getLineClasses = (autoCanceled, activationStatus, isRemoved, isCancelled) => {
  const hasError = autoCanceled && activationStatus === 'Inactive';

  return [
    'order-line',
    hasError ? 'hasError' : '',
    isRemoved ? 'isRemoved' : '',
    isCancelled ? 'isCancelled' : '',
  ].join(' ').trim().replace(/\s+/g, ' ');
};

export default {
  getErrorTooltip,
  getFulfillmentErrorTooltip,
  getRemovedTooltip,
  getLineClasses,
};

export const getStatusTranslationId = (status) => {
  switch (status) {
    case 'Fulfilling':
      return i18n.t('msg_codes_orderListAdmin_fulfilling_label');
    case 'Fulfilled':
      return i18n.t('msg_codes_fulfillment_fulfilled');
    case 'Fulfillment Error':
      return i18n.t('msg_codes_fulfillment_fulfillmentError');
    case 'Delivered':
      return i18n.t('msg_codes_delivery_delivered');
    case 'Activated':
      return i18n.t('msg_codes_activation_activated');
    case 'Deactivated':
      return i18n.t('msg_codes_codes_status_deactivated');
    case 'Activation Error':
      return i18n.t('msg_codes_activationError');
    case 'File generation is in progress, please come back later to download.':
      return i18n.t('msg_codes_fileGen');
    case 'Pending Delivery':
      return i18n.t('msg_codes_pendingDelivery');
    case 'Inactive':
      return i18n.t('msg_codes_partnerStatus_inactive');
    default:
      return i18n.t(status);
  }
};
