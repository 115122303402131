import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function trackingReducer(state = initialState.tracking, action) {
  const {
    app, pageName, category, user, eventName, assetName,
    elementType, eventAction, label, moduleName, position,
  } = action;
  switch (action.type) {
    case types.CHANGE_PAGE:
      window.digitalData = {
        ...state,
        pageInstanceID: `pspartners:codes-${app.version}:JS-2.9:empty`,
        component: [{
          componentInfo: {
            pageFlag: 'true',
            timestamp: new Date(),
          },
        }],
        page: {
          pageInfo: {
            ...state.page.pageInfo,
            pageName: `pspartners:codes:${pageName}`,
            sysEnv: 'web',
            destinationURL: window.location.href,
            version: app.env,
            onsiteSearchTerm: null,
            onsiteSearchResults: null,
          },
          category: {
            pageType: category,
          },
        },
        user: {
          profile: {
            profileInfo: {
              profileID: user.oktaId || 'empty',
              language: user.localeCode,
            },
            attributes: {
              companyName: user && user.partner
                ? user.partner.name === 'Sony' ? 'sie internal user' : user.partner.name
                : 'SIE',
              ...(user && user.partner && { gpid: user.partner.globalPartnerId }),
            },
          },
        },
        event: [...state.event],
      };
      if (typeof window._satellite !== 'undefined') {
        window._satellite.pageBottom();
      }
      return Object.assign({}, window.digitalData);
    case types.FIRE_EVENT:
      window.digitalData.event = [
        ...state.event.map(value => Object.assign({}, value)),
        {
          eventInfo: {
            eventName,
            eventAction,
          },
          attributes: {
            assetName,
            label,
            moduleName,
            position,
            elementType,
            linkURL: 'empty',
            userActionFlag: 'true',
          },
        },
      ];
      return Object.assign({}, window.digitalData);
    default:
      return state;
  }
}
