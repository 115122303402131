import * as types from '../actions/actionTypes';
import initialState from './initialState';
import { isAdditionalRedemptionCheckRequired } from '../components/order/orderPage.helper';
import * as callbackGateways from '../constants/callbackGateways.constants';

export default function orderReducer(state = initialState.order, action) {
  let orderLines = [];
  let orderLineIds = [];
  let maxId = 0;
  const {
    auditFields,
    partner,
    attachmentId,
    value,
    orderDocumentsScanStatus,
  } = action;
  const {
    activeAgreementVersion = {},
  } = action;
  if (typeof action.order !== 'undefined' && typeof action.order.orderLines !== 'undefined') {
    action.order.orderLines = action.order.orderLines.sort((a, b) => b.orderLineId - a.orderLineId);
    orderLines = [...action.order.orderLines];
    // need to fake order line Ids to provide keys for lines that havent been saved yet
    orderLineIds = [...action.order.orderLines].map(line => line.orderLineId);
    maxId = Math.max(...orderLineIds);
  }
  switch (action.type) {
    case types.LOAD_ORDER_SUCCESS:
      return {
        ...action.order,
        orderLines,
      };
    case types.CHANGE_PROP_SUCCESS:
      return {
        ...state,
        [action.name]: action.value,
        orderModified: true,
      };
    case types.SET_PARTNER_FOR_ORDER:
      return {
        ...state,
        partnerName: partner.name,
        partner: partner.partnerCode,
        partnerId: partner.partnerId,
      };
    case types.REMOVE_ITEM_SUCCESS:
      return {
        ...state,
        orderLines: [
          ...state.orderLines.filter(line => line.orderLineId !== action.line.orderLineId),
        ].sort((a, b) => b.orderLineId - a.orderLineId),
        linesRemoved: true,
      };
    case types.CHANGE_PROP_ON_LINE_SUCCESS:
      return {
        ...state,
        newOrder: false,
        orderModified: true,
        orderLines: [
          ...state.orderLines.filter(line => line.orderLineId !== action.line.orderLineId),
          {
            ...action.line,
            [action.name]: action.value,
          },
        ].sort((a, b) => b.orderLineId - a.orderLineId),
      };
    case types.NEW_ORDER_SUCCESS:
      return {
        orderLines: [],
        region: '',
        orderReference: '',
        poNumber: '',
        orderComments: '',
        newOrder: true,
        orderHistories: [],
        additionalRedemptionCheckRequired: null,
        partnerSftpId: null,
        partnerId: '',
        auditFields: {},
        freightCost: '0.00',
        freightCostTaxPercentage: '0.00',
        freightCostTax: '0.00',
        totalManufactureFreightCostAndAdditionalCosts: '0.00',
        cardManufacturePriceCurrency: null,
        totalTax: '0.00',
        additionalCosts: '0.00',
      };
    case types.ADD_ITEMS_SUCCESS:
      // in cases where no lines are yet present
      if (maxId < 0) {
        maxId = 0;
      }
      return {
        ...state,
        orderLines: [
          ...state.orderLines,
          ...action.lines.map((line, index) => ({
            _embedded: {
              voucherCatalog: line,
            },
            itemReference: '',
            orderLineStatus: '',
            quantity: 1,
            branded: null,
            voucherCatalogId: line.voucherCatalogId,
            voucherStatus: 'INACTIVE',
            orderLineId: maxId + (index + 1),
            isNew: true,
            tax: '',
          })),
        ].sort((a, b) => b.orderLineId - a.orderLineId),
        linesAdded: true,
        orderModified: true,
      };
    case types.SAVE_ORDER_SUCCESS:
      return {
        ...action.order,
      };
    case types.SET_ORDER_AUDIT_FIELDS:
      return {
        ...state,
        auditFields,
      };
    case types.ACTIVATE_ITEM_SUCCESS:
    case types.RETRY_ORDER_LINE_SUCCESS:
    case types.DELIVER_ORDER_LINE_SUCCESS:
      return {
        ...state,
        orderLines: [
          ...state.orderLines.map((line) => {
            const { orderLineId } = line;
            if (orderLineId === action.line.orderLineId) {
              return {
                ...action.line,
              };
            }
            return line;
          }),
        ],
        newOrder: false,
      };
    case types.CANCEL_ITEM_SUCCESS:
      return {
        ...state,
        orderLines: [
          ...state.orderLines.filter(line => line.orderLineId !== action.line.orderLineId),
          { ...action.line },
        ].sort((a, b) => b.orderLineId - a.orderLineId),
        newOrder: false,
      };
    case types.ORDER_LINE_STATUS:
      return {
        ...state,
        orderLines: [
          ...state.orderLines.filter(line => !action.orderLineIds.includes(line.orderLineId)),
          ...action.orderLines,
        ].sort((a, b) => b.orderLineId - a.orderLineId),
        newOrder: false,
      };
    case types.GET_ACTIVE_AGREEMENT_VERSION_SUCCESS: {
      return {
        ...state,
        additionalRedemptionCheckRequired: state.additionalRedemptionCheckRequired === null
          ? isAdditionalRedemptionCheckRequired(
            callbackGateways[activeAgreementVersion.voucherRedemptionCallbackGateway],
            activeAgreementVersion.voucherRedemptionCallbackMandatory,
          )
          : state.additionalRedemptionCheckRequired,
      };
    }
    case types.CHANGE_SHARE_WITH_PARTNER: {
      return {
        ...state,
        attachments: [
          ...state.attachments.map((documentLine) => {
            if (documentLine.attachmentId === attachmentId) {
              return {
                ...documentLine,
                shareWithPartner: value,
              };
            }
            return documentLine;
          }),
        ],
      };
    }
    case types.ADD_ORDER_DOCUMENT: {
      return {
        ...state,
        attachments: [...action.order.attachments],
      };
    }
    case types.REMOVE_ORDER_DOCUMENT: {
      return {
        ...state,
        attachments: [
          ...state.attachments
            .filter(documentLine => documentLine.attachmentId !== attachmentId),
        ],
      };
    }
    case types.UPDATE_ORDER_DOCUMENTS_SCAN_STATUS: {
      return {
        ...state,
        attachments: state.attachments.map((attachment) => {
          return {
            ...attachment,
            scanStatus: orderDocumentsScanStatus[attachment.attachmentId],
          };
        }),
      };
    }
    default:
      return state;
  }
}
