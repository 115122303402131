import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  map,
  sortBy,
  find,
  isEmpty,
  isEqual,
} from 'lodash';
import {
  Flex,
  Select,
  TextInput,
  Divider,
  Layout,
  Tooltip,
  Icon,
  Loader,
} from '@partner-global-ui/components';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ACTIVE, INACTIVE } from '../../constants/codeProductStatuses.constants';
import * as ContractingSieRegions from '../../constants/contractingSieRegion.constants';
import { loadPartners } from '../../actions/partnerActions';
import * as actions from '../../actions/codeProductActions';
import * as CodeProductTypes from '../../constants/codeProductTypes.constants';
import countriesGensenStrings from '../../constants/countries.constants';
import getCountries from '../../actions/countryActions';
import { usePermission } from '../../utils/accessControl/hasPermission';
import getFeature from '../../utils/accessControl/getFeature';

import roleKeys from '../../utils/accessControl/roleKeys';
import './codeProduct.scss';

export const isValidFaceValue = (faceValueType, faceValue) => {
  if (typeof faceValue === 'undefined') { return false; }
  switch (faceValueType) {
    case 'VARIABLE':
      return Number(faceValue) === 0;
    case 'STANDARD':
    default:
      return Number(faceValue) > 0;
  }
};

function ProductDetailForm({
  codeProduct,
  handleChange,
  selectRef,
  productTypeSelectOptions,
  canSeeEndDate,
  setFaceValueType,
  faceValueType,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();

  const {
    codeProductType,
    status,
    region,
    country,
    countries,
    applicableAgreementTypes,
    applicableAgreementTypesMap,
    exclusiveToPartner,
    exclusiveToPartnerMap,
    faceValue,
    subscriptionType,
    voucherCatalogId,
    codeProductName,
    codeProductDescription,
    endDate,
  } = codeProduct;

  const [isDisabled, setIsDisabled] = useState(false);
  const [showFaceValueSelection, setShowFaceValueSelection] = useState(false);
  const [countriesOptions, setCountriesOptions] = useState([]);
  const [currentCountry, setCurrentCountry] = useState({ name: '', currency: { code: '', symbol: '' } });
  const isDecimalFaceValueSupported = currentCountry.currency.divisorExponent === 2;
  const allowVariableLoad = getFeature(roleKeys.codeProduct.allowVariableLoad);
  useEffect(() => {
    dispatch(loadPartners(1000));
  }, []);

  const [isFetching, setIsFetching] = useState(false);
  const [selectedPartners, setSelectedPartners] = useState();
  const [availablePartnerOptions, setAvailablePartnerOptions] = useState([]);
  const {
    countries: allAvailableCountries,
  } = useSelector(state => state);
  const isMoney = codeProductType === CodeProductTypes.MONEY.code;

  const getPartners = async () => {
    const partners = await dispatch(loadPartners(1000));
    return partners;
  };

  useEffect(() => {
    getPartners().then((partners) => {
      setIsFetching(true);
      if (!isEmpty(partners)) {
        const productType = isMoney ? codeProductType : subscriptionType;
        const exclusiveToPartnerMapUpdated = {
          ...exclusiveToPartnerMap,
          '': [],
        };

        const selectedExclusiveToPartners = partners.filter((partner) => {
          const { partnerId } = partner;
          return exclusiveToPartner.includes(partnerId);
        });
        const selectedOptionsToDisplay = selectedExclusiveToPartners.length > 0
          ? selectedExclusiveToPartners.map((partner) => {
            return {
              ...partner,
              value: partner.partnerCode,
              label: partner.name,
            };
          })
          : [];

        const availableOptions = partners
          .filter((partner) => {
            const { partnerCode } = partner;
            return exclusiveToPartnerMapUpdated[typeof productType !== 'undefined' ? productType : 'PSPLUS'] && exclusiveToPartnerMapUpdated[typeof productType !== 'undefined' ? productType : 'PSPLUS'].includes(partnerCode);
          });
        const availableOptionsToDisplay = availableOptions.map((partner) => {
          return {
            ...partner,
            value: partner.partnerCode,
            label: partner.name,
          };
        });

        setSelectedPartners(selectedOptionsToDisplay);
        setAvailablePartnerOptions(availableOptionsToDisplay);
      }
      setIsFetching(false);
    });
  }, []);

  useEffect(() => {
    const countriesWithStrings = allAvailableCountries.map((cty) => {
      return {
        ...cty,
        name: t(countriesGensenStrings[cty.code].name),
      };
    });
    setCountriesOptions(countriesWithStrings);
  }, [allAvailableCountries]);

  useEffect(() => {
    if (voucherCatalogId) {
      if (allowVariableLoad) {
        setShowFaceValueSelection(true);
      }
      if (applicableAgreementTypes.includes('AGENCY')) {
        if (Number(faceValue) === 0) {
          setFaceValueType({ value: 'VARIABLE', label: t('msg_codes_variable') });
        } else {
          setFaceValueType({ value: 'STANDARD', label: t('msg_codes_standard') });
        }
      }
    }
  }, [voucherCatalogId, faceValue]);


  useEffect(() => {
    const cty = countriesOptions.find(c => c.code === country);
    if (cty) {
      setCurrentCountry(cty);
    }
  }, [country, countriesOptions]);


  useEffect(() => {
    setCurrentCountry({ name: '', currency: { code: '', symbol: '' } });
  }, [codeProductType]);

  const permissions = {
    viewDetail: usePermission(roleKeys.codeProduct.viewDetail),
    createDetail: usePermission(roleKeys.codeProduct.createDetail),
    editDetail: usePermission(roleKeys.codeProduct.editDetail),
  };

  const isSubscription = codeProductType === CodeProductTypes.SUBSCRIPTION.code;
  const isThirdParty = codeProductType === CodeProductTypes.THIRDPARTY.code;
  const productTypeClass = (isMoney && '-money')
  || (isSubscription && '-subscription')
  || (isThirdParty && '-third-party');

  const agreementTypeOptionsMap = {
    AGENCY: {
      value: 'AGENCY',
      label: 'Agency',
    },
    FIRST_PARTY_PUBLISHER: {
      value: 'FIRST_PARTY_PUBLISHER',
      label: '1st Party Publisher',
    },
    THIRD_PARTY_CONTRACTUAL: {
      value: 'THIRD_PARTY_CONTRACTUAL',
      label: '3rd Party - Free Allocation',
    },
    THIRD_PARTY_RATECARD: {
      value: 'THIRD_PARTY_RATECARD',
      label: '3rd Party - Permitted Uses',
    },
  };

  const statusOptions = [
    { label: t('msg_codes_agreementStatus_active'), value: ACTIVE },
    { label: t('msg_codes_agreementStatus_inactive'), value: INACTIVE },
  ];

  // variable load feature flagging removes option to select variable load
  const faceValueOptions = allowVariableLoad ? [
    { label: t('msg_codes_standard'), value: 'STANDARD' },
    { label: t('msg_codes_variable'), value: 'VARIABLE' },
  ]
    : [
      { label: t('msg_codes_standard'), value: 'STANDARD' },
    ];

  const regionOptions = Object.keys(ContractingSieRegions).map((key) => {
    const product = ContractingSieRegions[key];
    return ({
      value: product.code,
      label: product.name,
    });
  });

  useEffect(() => {
    const cty = countriesOptions.find(c => c.code === country);
    if (cty) {
      setCurrentCountry(cty);
    }
  }, [country, countriesOptions]);

  useEffect(() => {
    if (isMoney && !voucherCatalogId) {
      handleChange({
        target: {
          name: 'codeProductName',
          value: {
            value: `${currentCountry.name} ${faceValueType.value === 'VARIABLE' ? 'Variable Load' : faceValue} ${currentCountry.currency.code}`,
          },
        },
      });
    }
  }, [currentCountry.name, currentCountry.currency.code, faceValue, isMoney, faceValueType]);

  /* methods */
  const handleRegionChange = useCallback((e) => {
    handleChange(e);
    handleChange({ target: { name: 'country', value: '' } });
    dispatch(getCountries(e.target.value.value));
  }, [handleChange, getCountries, dispatch]);

  const handleFaceValueSelection = useCallback((e) => {
    setFaceValueType(e.target.value);
    if (e.target.value.value === 'VARIABLE') {
      dispatch(actions.setProductProp('applicableAgreementTypes', ['AGENCY']));
      setIsDisabled(true);
      if (!voucherCatalogId) {
        dispatch(actions.setProductProp('faceValue', '0'));
      }
    } else if (e.target.value.value === 'STANDARD') {
      dispatch(actions.setProductProp('applicableAgreementTypes', ['AGENCY']));
      dispatch(actions.setProductProp('faceValue', ''));
      setIsDisabled(false);
    }
  }, [handleChange, getCountries, dispatch]);

  const handleFaceValueChange = useCallback((e) => {
    const val = e && e.target && e.target.value;
    if (!voucherCatalogId && isValidFaceValue(faceValueType.value, val)) {
      dispatch(actions.setProductProp('faceValue', val));
    }
  }, [faceValueType]);

  const handleAATChange = useCallback((existingTypes, { target: { value } }) => {
    const selectedTypes = value.map(type => type.value);

    // To stop setting props twice on menu close
    if (
      !isEqual(sortBy(existingTypes), sortBy(selectedTypes))
    ) {
      dispatch(actions.setProductProp('applicableAgreementTypes', map(value, 'value')));
      if (value[0].value === 'AGENCY' && allowVariableLoad) {
        setShowFaceValueSelection(true);
      }
    }
  }, [actions.setProductProp, dispatch]);

  const handleETPChange = useCallback((existingPartners, { target: { value } }) => {
    const selectedPartnerIds = value.map(({ partnerId }) => partnerId);

    // To stop setting props twice on menu close
    if (
      !isEqual(sortBy(existingPartners), sortBy(selectedPartnerIds))
    ) {
      dispatch(actions.setProductProp('exclusiveToPartner', map(value, 'partnerId')));
      setSelectedPartners(value);
    }
  }, [actions.setProductProp, dispatch]);

  const handleCountriesChange = useCallback((countriesSelected) => {
    dispatch(actions.setProductProp('countries', map(countriesSelected, 'value')));
  }, [actions.setProductProp, dispatch]);

  const isAllowedValue = (wholeNumber, singleNumber) => {
    const maxValue = isDecimalFaceValueSupported ? 999999.99 : 99999999;
    let value = `${wholeNumber}${singleNumber}`;

    const inverseValue = value.split('').reverse().join('');
    const checkTwoDecimalPlaces = inverseValue.charAt(3) === '.';

    value = parseFloat(value).toFixed(2);
    return value <= maxValue && !checkTwoDecimalPlaces;
  };

  const handleBlur = useCallback((e) => {
    if (e.currentTarget.value === '0') e.currentTarget.value = '1';
  });

  const handleKeypress = useCallback((e) => {
    const characterCode = e.key;
    if (characterCode === 'Backspace') return;
    const characterNumber = Number(characterCode);
    const singleInput = e.key === '.' ? e.key : characterNumber;
    if (
      ((characterNumber >= 0 && characterNumber <= 9) || e.key === '.')
      && isAllowedValue(e.currentTarget.value, singleInput)
      && e.key !== ' '
    ) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      }
      if (characterNumber === 0) {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  });

  /* renders */
  const renderAAT = useCallback(() => {
    const productType = isSubscription ? subscriptionType : codeProductType;
    if (applicableAgreementTypesMap.length === 0) {
      return <></>;
    }
    const applicableAgreementTypesMapUpdated = {
      ...applicableAgreementTypesMap,
      '': [],
    };
    const productTypeAgreements = applicableAgreementTypesMapUpdated[typeof productType !== 'undefined' ? productType : 'PSPLUS'];
    const options = productTypeAgreements
      .map((agreementType) => {
        return agreementTypeOptionsMap[agreementType];
      })
      // TODO: Remove this filter when FIRST_PARTY_PUBLISHER is supported
      .filter(option => option && option.value !== 'FIRST_PARTY_PUBLISHER');
    let selected = '';
    if (applicableAgreementTypes) {
      selected = applicableAgreementTypes.map((agreementType) => {
        return agreementTypeOptionsMap[agreementType];
      }).filter(option => option !== undefined);
    }

    return (
      <Select
        label={t('msg_codes_agreement_types')}
        id="applicable-agreement-types"
        multiple
        required={false}
        value={selected}
        options={options}
        onChange={AATypes => handleAATChange(applicableAgreementTypes, AATypes)}
        disabled={isThirdParty || !permissions.editDetail || isDisabled}
        anchor={selectRef}
        placeholder={t('msg_codes_common_select')}
        selectAllLabel={t('msg_codes_filter_all')}
        allPillText={t('msg_codes_filter_all')}
        clearButtonText={t('msg_codes_cta_clear')}
        doneButtonText={t('msg_codes_cta_done')}
      />
    );
  }, [applicableAgreementTypesMap, applicableAgreementTypes, isDisabled]);

  const renderETP = useCallback(() => {
    if (!isFetching) {
      return (
        <Select
          label={t('msg_codes_exclusive_partners')}
          id="exclusive-to-partners"
          multiple
          required={false}
          value={selectedPartners}
          options={availablePartnerOptions}
          onChange={etp => (handleETPChange(exclusiveToPartner, etp))}
          disabled={isThirdParty || !permissions.editDetail}
          anchor={selectRef}
          placeholder={t('msg_codes_common_select')}
          selectAllLabel={t('msg_codes_filter_all')}
          allPillText={t('msg_codes_filter_all')}
          clearButtonText={t('msg_codes_cta_clear')}
          doneButtonText={t('msg_codes_cta_done')}
        />
      );
    }

    return null;
  });

  const renderCountriesSelect = useCallback((localCountries) => {
    const availableCountriesOptions = sortBy(localCountries
      .map((cty) => {
        return {
          ...cty,
          label: t(cty.nameStringId),
          value: cty.code,
        };
      }), 'label');

    return (
      <Flex id="countries-select" colSpan={4} className="countries-select">
        <Select
          label={t('msg_codes_agreements_country')}
          name="country"
          id="country"
          required
          disabled={!(!!region && !voucherCatalogId)}
          options={availableCountriesOptions}
          onChange={handleChange}
          value={find(availableCountriesOptions, { value: country })}
          placeholder={t('msg_codes_common_select')}
        />
      </Flex>
    );
  });

  const renderCountries = useCallback(() => {
    const availableCountriesOptions = sortBy(countriesOptions
      .map((cty) => {
        return {
          ...cty,
          label: cty.name,
          value: cty.code,
        };
      }), 'label');

    const selectedCountries = availableCountriesOptions.filter((cty) => {
      const { code } = cty;
      return countries.includes(code);
    });

    return (
      <Flex colSpan={4} id="countries-multi-display" className="countries-multi-display">
        <Select
          id="countries-multi-select-display"
          label={t('msg_codes_countries_regions')}
          defaultValue={selectedCountries}
          disabled
          placeholder={t('msg_codes_common_select')}
          value={selectedCountries}
          options={availableCountriesOptions}
          onChange={e => handleCountriesChange(e)}
          multiple
        />
      </Flex>
    );
  });

  return (
    <Layout id="product-detail-form" className={`product-detail-form${productTypeClass}`}>
      {isFetching
        ? <Loader />
        : (
          <>

            <Flex colSpan={5} className={`product-detail-form-left${productTypeClass}`}>
              {isThirdParty && (
                <Flex colSpan={4} className={`code-product-type${productTypeClass}`}>
                  <Select
                    label="Code Product Type"
                    name="codeProductType"
                    id="codeProductType"
                    required
                    disabled={id}
                    options={productTypeSelectOptions}
                    onChange={handleChange}
                    value={find(productTypeSelectOptions, { value: codeProductType })}
                    optionKey="code"
                    optionSelectValue=""
                    placeholder={t('msg_codes_common_select')}
                  />
                </Flex>
              )}
              {canSeeEndDate && (
                <Flex id="end-date" colSpan={4} className="end-date">
                  <TextInput
                    label="End Date"
                    id="endDate"
                    name="endDate"
                    value={moment(endDate).format('YYYY/DD/MM')}
                    required={false}
                    disabled
                    width={420}
                  />
                </Flex>
              )}
              <Flex id="code-product-name" colSpan={4} className={`code-product-name-${codeProductType.toLowerCase()}`}>
                <TextInput
                  label={t('msg_codes_voucherProductName')}
                  name="codeProductName"
                  id="codeProductName"
                  value={codeProductName}
                  disabled={
                    !(
                      !isMoney && !isThirdParty
                      && (permissions.createDetail || permissions.editDetail)
                    )
                  }
                  onChange={e => handleChange(e)}
                  required={!isMoney}
                  charCountMax={1024}
                  width={420}
                />
              </Flex>
              {(isMoney || isThirdParty) && (
                <Flex id="code-product-status-select" colSpan={4} className={`code-product-status-select${productTypeClass}`}>
                  <Select
                    label={t('msg_codes_code_product_status')}
                    name="status"
                    id="status"
                    required
                    disabled={!(permissions.createDetail || permissions.editDetail)}
                    options={statusOptions}
                    onChange={handleChange}
                    value={find(statusOptions, { value: status })}
                    optionSelectValue=""
                    placeholder={t('msg_codes_common_select')}
                  />
                </Flex>
              )}
              {isSubscription && renderCountries()}
              <Flex
                colSpan={4}
                id="applicable-agreement-types"
                className={`applicable-agreement-types-${codeProductType.toLowerCase()}`}
              >
                {renderAAT()}
              </Flex>
              <Flex
                colSpan={4}
                id="exclusive-to-partners-select"
                className={`exclusive-to-partners-select-${codeProductType.toLowerCase()}`}
              >
                {renderETP()}
              </Flex>
              {
                isMoney && (
                  <Flex id="face-value=flex" colSpan={4} className={`face-value-${codeProductType.toLowerCase()}`}>
                    <div style={{ textAlign: 'left' }} className="required">
                      <b>Face Value</b>
                      <Tooltip
                        strategy="absolute"
                        content={t('msg_codes_face_value_tooltip')}
                        className="face-value-tooltip"
                      >
                        <Icon className="face-value-icon" size={14}>ico-help-circle</Icon>
                      </Tooltip>
                    </div>
                    <span
                      className={`face-value-selection${showFaceValueSelection && '-hidden'}`}
                      data-testid="face-value-selection"
                    >
                      {
                        showFaceValueSelection && (
                        <span>
                          <Select
                            name="faceValueType"
                            id="faceValueType"
                            options={faceValueOptions}
                            onChange={handleFaceValueSelection}
                            value={faceValueType}
                            anchor={selectRef}
                            disabled={voucherCatalogId}
                            placeholder={t('msg_codes_choose_ghost')}
                          />
                        </span>
                        )}
                      <TextInput
                        name="face-value"
                        id="face-value"
                        placeholder={`${isDecimalFaceValueSupported ? '0.00' : '0'}`}
                        value={voucherCatalogId ? faceValue.toFixed(2) : faceValue}
                        persistantSymbolLeft={currentCountry.currency.symbol}
                        disabled={voucherCatalogId || isDisabled}
                        onChange={handleFaceValueChange}
                        onKeyDown={handleKeypress}
                        onBlur={handleBlur}
                        width={showFaceValueSelection ? 300 : 400}
                      />
                    </span>
                    { allowVariableLoad && (
                      <div className="variable-load-help">{t('msg_codes_variable_load_help')}</div>
                    ) }
                  </Flex>
                )
              }
            </Flex>
            <Flex
              offset={1}
              colSpan={1}
              className={`product-detail-form-center${productTypeClass}`}
            >
              <Divider
                id="vertical-divider"
                secondary
                vertical
              />
            </Flex>
            <Flex
              colSpan={5}
              className={`product-detail-form-right${productTypeClass}`}
            >
              {(isMoney || isThirdParty) && (
                <Flex id="region-select" colSpan={4} className="region-select">
                  <Select
                    label={t('msg_codes_common_region')}
                    name="region"
                    id="region"
                    required
                    disabled={!(!voucherCatalogId)}
                    options={regionOptions}
                    onChange={handleRegionChange}
                    value={find(regionOptions, { value: region })}
                    optionKey="code"
                    optionSelectValue=""
                    placeholder={t('msg_codes_common_select')}
                  />
                </Flex>
              )}
              {
                ((isMoney && allAvailableCountries.length) || (!isEmpty(id) && isEmpty(region)))
                && renderCountriesSelect(allAvailableCountries)
              }
              {isThirdParty && renderCountries()}
              {isSubscription && (
                <>
                  <Flex id="code-product-status-select" colSpan={4} className={`code-product-status-select${productTypeClass}`}>
                    <Select
                      label={t('msg_codes_code_product_status')}
                      name="status"
                      id="status"
                      required
                      disabled={!(permissions.createDetail || permissions.editDetail)}
                      options={statusOptions}
                      onChange={handleChange}
                      value={find(statusOptions, { value: status })}
                      optionSelectValue=""
                      placeholder={t('msg_codes_common_select')}
                    />
                  </Flex>
                  <Flex id="code-product-description" colSpan={4} className="code-product-description">
                    <TextInput
                      label={t('msg_codes_product_description')}
                      name="codeProductDescription"
                      id="codeProductDescription"
                      value={codeProductDescription || ''}
                      disabled={!(permissions.createDetail || permissions.editDetail)}
                      onChange={handleChange}
                      charCountMax={1024}
                      width={420}
                      required={false}
                      placeholder={t('msg_codes_codeProdCat_typeSomething_dropdown')}
                    />
                  </Flex>
                </>
              )}
            </Flex>
          </>
        )
      }
    </Layout>
  );
}

ProductDetailForm.propTypes = {
  codeProduct: PropTypes.object,
  handleChange: PropTypes.func,
  selectRef: PropTypes.object,
  productTypeSelectOptions: PropTypes.array,
  canSeeEndDate: PropTypes.string,
  faceValueType: PropTypes.object,
  setFaceValueType: PropTypes.func,
};

ProductDetailForm.defaultProps = {
  codeProduct: {},
  handleChange: () => { },
  selectRef: {},
  productTypeSelectOptions: [],
  canSeeEndDate: '',
  faceValueType: {},
  setFaceValueType: () => {},
};

export default ProductDetailForm;
