import initialState from './initialState';
import * as types from '../actions/actionTypes';

const updateVouchers = (newCatalogs, productGroups) => {
  const updatedProducts = productGroups.map((productGroup) => {
    const catalogs = productGroup.catalogs.map((catalog) => {
      const matchingCatalog = newCatalogs.find((newCatalog) => {
        return newCatalog.voucherCatalogId === catalog.voucherCatalogId;
      });
      if (typeof matchingCatalog === 'undefined') {
        return catalog;
      }
      return matchingCatalog;
    });
    return {
      ...productGroup,
      catalogs,
    };
  });
  return updatedProducts;
};


export default (state = initialState.thirdPartyCatalogPage, action) => {
  const { filterType, filterItems, search } = action;
  switch (action.type) {
    case types.SET_THIRD_PARTY_CATALOG_PAGE:
      return {
        ...action.page,
        selectedPartner: state.selectedPartner,
      };
    case types.SET_THIRD_PARTY_CATALOG_FILTER:
      return {
        ...state,
        filters: { ...state.filters, [filterType]: filterItems },
      };
    case types.SET_VOUCHERS_SEARCH:
      return {
        ...state,
        search,
      };
    case types.CLEAR_VOUCHERS_SEARCH:
      return {
        ...state,
        search: '',
      };
    case types.SET_COUNTRY_FILTER_OPTIONS:
      return {
        ...state,
        countries: [...action.countries],
      };
    case types.RESET_THIRD_PARTY_CATALOG_FILTERING:
      return {
        ...state,
        filters: initialState.thirdPartyCatalogPage.filters,
      };
    case types.UPDATE_FROM_POLLING:
      return {
        ...state,
        content: updateVouchers(action.products, state.content),
      };
    case types.THIRD_PARTY_CATALOG_PARTNER:
      return {
        ...state,
        selectedPartner: action.partnerId,
      };
    default:
      return state;
  }
};
