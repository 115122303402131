import initialState from './initialState';
import * as types from '../actions/actionTypes';

export default (state = initialState.voucherBatchesPage, action) => {
  const { filterName, filterValue, search } = action;
  switch (action.type) {
    case types.SET_VOUCHER_BATCHES_PAGE:
      return {
        ...state,
        page: action.page,
      };
    case types.SET_VOUCHER_BATCHES_FILTER:
      return {
        ...state,
        filters: { ...state.filters, [filterName]: filterValue },
        page: {
          ...state.page,
          number: 0,
        },
        isFiltering: true,
      };
    case types.SET_VOUCHER_BATCHES_SEARCH:
      return {
        ...state,
        search,
        page: {
          ...state.page,
          number: 0,
        },
      };
    case types.CLEAR_VOUCHER_BATCHES_SEARCH:
      return {
        ...state,
        search: '',
        page: {
          ...state.page,
          number: 0,
        },
      };
    case types.RESET_VOUCHER_BATCHES_FILTERING:
      return {
        ...state,
        filters: initialState.voucherBatchesPage.filters,
        page: {
          ...state.page,
          number: 0,
        },
        isFiltering: false,
      };
    case types.RESET_VOUCHER_BATCHES_PAGE:
      return {
        ...initialState.voucherBatchesPage,
      };
    default:
      return state;
  }
};
