export function isVoucherTypeValid(voucherType) {
  const {
    npType,
    npRegion,
    name,
    faceValue,
    country,
  } = voucherType;
  if (npType === 'PRODUCT') {
    return name.length <= 1024 && ![npRegion, name].some(value => !value);
  }
  if (npType === 'MONEY') {
    return ![npRegion, name, faceValue, country].some(value => !value);
  }
  return false;
}

export default isVoucherTypeValid;
