import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  ModalContainer,
} from '@partner-global-ui/components';
import VenomJobsTable from './VenomJobsTable';
import VIDSJobsTable from './VIDSJobsTable';
import BatchNotifications from './batchNotifications/batchNotifications';
import MaintenanceMode from './maintenanceMode';
import PartnerMerge from './partnerMerge/partnerMerge';

import './maintenanceDashboard.scss';

const AdminDashboard = () => {
  const { t } = useTranslation();

  return (
    <div className="adminDashboard-page" data-testid="admin-dashboard-page">
      <ModalContainer>
        <Container>
          <h1
            className="dashboard-title"
            data-testid="dashboard-title"
          >
            {t('msg_codes_jobs_maintenance')}
          </h1>
          <VenomJobsTable />
          <VIDSJobsTable />
          <BatchNotifications />
          <MaintenanceMode />
          <PartnerMerge />
        </Container>
      </ModalContainer>
    </div>
  );
};

export default AdminDashboard;
