import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FilterSelector from './FilterSelector';
import './FiltersContainer.scss';

export default function FiltersContainer({ filters }) {
  const userLocale = useSelector(state => state.user.localeCode).replace(/_/g, '-');
  const { t } = useTranslation();

  return (
    <div className="filters-container">
      {filters.map(
        filter => (
          <div className={`filter ${filter.name}`} key={filter.name}>
            <FilterSelector
              type={filter.type}
              name={filter.name}
              id={filter.name}
              multiple={filter.multiple ? filter.multiple : false}
              placeholder={filter.placeholder}
              value={filter.value}
              options={filter.options ? filter.options : null}
              label={filter.label}
              required={typeof filter.required !== 'undefined'
                ? filter.required
                : false
              }
              onChange={filter.handleChange}
              countryCodeLocale={userLocale}
              displayFormat="yyyy/MM/dd"
              mask={[
                /\d/, /\d/, /\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/,
                '-',
                /\d/, /\d/, /\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/,
              ]}
              selectAllLabel={t('msg_codes_filter_all')}
              allPillText={t('msg_codes_filter_all')}
              clearButtonText={t('msg_codes_cta_clear')}
              doneButtonText={t('msg_codes_cta_done')}
              disabled={filter.disabled}
            />
          </div>
        ),
      )}
    </div>
  );
}

FiltersContainer.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.object),
};

FiltersContainer.defaultProps = {
  filters: [{}],
};
