const ordersHistoryStatus = {
  PENDING_DRAFT_AMENDMENT: {
    buttonDisabled: true,
    buttonStyle: 'link',
    buttonText: 'msg_codes_orders_updateReq',
    showButton: true,
    showDownloadIcon: false,
    statusName: 'PENDING_DRAFT_AMENDMENT',
  },
  PROCESSING: {
    buttonDisabled: true,
    buttonStyle: 'link',
    buttonText: 'msg_code_orders_processing_label',
    showButton: false,
    showDownloadIcon: false,
    statusName: 'PROCESSING',
  },
  DOWNLOAD: {
    buttonDisabled: false,
    buttonStyle: 'primary',
    buttonText: 'msg_codes_cta_download',
    showButton: true,
    showDownloadIcon: true,
    statusName: 'DOWNLOAD',
  },
  REDOWNLOAD: {
    buttonDisabled: false,
    buttonStyle: 'secondary',
    buttonText: 'msg_codes_cta_redownload',
    showButton: true,
    showDownloadIcon: false,
    statusName: 'REDOWNLOAD',
  },
  CANCELLED: {
    buttonDisabled: true,
    buttonStyle: 'link',
    buttonText: 'msg_codes_orderCanceled',
    showButton: false,
    showDownloadIcon: false,
    statusName: 'CANCELLED',
  },
  REJECTED: {
    buttonDisabled: true,
    buttonStyle: 'link',
    buttonText: 'msg_codes_orderlist_rejected',
    showButton: true,
    showDownloadIcon: false,
    statusName: 'REJECTED',
  },
  ERROR: {
    buttonDisabled: false,
    buttonStyle: 'secondary',
    buttonText: 'msg_codes_cta_tryAgain',
    showButton: true,
    showDownloadIcon: false,
    statusName: 'ERROR',
  },
};

export default ordersHistoryStatus;
