import actions from './agreementFormActions';

function handleSaveClick(
  notificationContext,
  oldAgreementForm,
  navigate,
  shouldSave,
  hideWarningModalCancel,
) {
  const oldInvoiceTiming = oldAgreementForm.draftAgreementVersion.invoiceTiming;
  const agreementForm = {
    ...oldAgreementForm,
    draftAgreementVersion: {
      ...oldAgreementForm.draftAgreementVersion,
      invoiceTiming: oldInvoiceTiming === '' ? null : oldInvoiceTiming,
    },
  };

  const {
    isNewAgreement,
  } = agreementForm;

  const payload = {
    notificationContext,
    agreementForm,
    navigate,
  };

  const {
    draftAgreementVersion: { versionStatus },
  } = agreementForm;

  if (typeof hideWarningModalCancel !== 'undefined') {
    hideWarningModalCancel();
  }

  return (dispatch) => {
    if ((!isNewAgreement && shouldSave) || versionStatus === 'DELETED') {
      actions.updateAgreement(payload)(dispatch);
    } else if (isNewAgreement && shouldSave) {
      actions.createAgreement(payload)(dispatch);
    } else {
      actions.createNewVersion()(dispatch);
    }
  };
}

export default handleSaveClick;
