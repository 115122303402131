import pageTitles from '../constants/pageTitles.constants';

export default (pathname, t) => {
  // Shared title strings
  const appName = t('msg_codes_global_codes');
  const psPartners = t('msg_codes_global_PSPartners');

  // Split the path into its individual parameters
  //  and filter out any empty parameters
  const pathSplit = pathname
    .split('/')
    .filter(split => split.length > 0);

  const title = {
    // If no page title value exists
    [true]: () => '',
    // If the path contains 1 parameter
    [pathSplit[0] && pathSplit[0].length > 0]: () => t(pageTitles[pathSplit[0]]),
    // If the second path parameter is a number
    [pathSplit[1] && typeof parseInt(pathSplit[1], 10) === 'number']: () => t(
      pageTitles[`${pathSplit[0]}WithId`],
      { ID: pathSplit[1] },
    ),
    // If the second path parameter is "create"
    [pathSplit[1] && pathSplit[1] === 'create']: () => t(pageTitles[`${pathSplit[0]}create`]),
    // If path is partner profile
    [pathSplit[0] === 'partner' && pathSplit[0].length > 0]: () => t('msg_codes_partners_partnerProfile'),
  }.true();

  return title === ''
    ? psPartners
    : `${title} - ${appName} | ${psPartners}`;
};
