import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  DataTable,
  TableHeader,
  TableHeaderCell,
  GroupState,
} from '@partner-global-ui/components';
import { orderBy } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  addAgencyFeeStructure, removeAllAgencyFeeStructures,
} from '../../../actions/agreementFormActions';
import * as AgreementTypes from '../../../constants/agreementType.constants';
import hasPermission from '../../../utils/accessControl/hasPermission';
import roleKeys from '../../../utils/accessControl/roleKeys';
import CoverageRow from './CoverageRow';
import './VoucherCoverageTable.scss';

function checkAllSelected(voucherCoverageSet, countries) {
  return voucherCoverageSet.feeStructure.length >= countries.length;
}

const VoucherForm = ({
  voucherCoverageSet,
  region,
  countries,
  agreementType,
  voucherProductType,
  canEdit,
  currencies,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filteredCountries = countries.filter(country => country.region.code === region);
  const state = useSelector(globalState => globalState);

  const selectedAgreementType = state.agreementForm.agreement.agreementType;
  const showFeeStructureMoneyDetails = hasPermission(state,
    roleKeys.agreement.showAgreementVersionDetails)
    && selectedAgreementType === 'AGENCY';

  const toggleAllCountries = (allSelected) => {
    if (allSelected) {
      dispatch(removeAllAgencyFeeStructures(voucherProductType));
    } else {
      countries.forEach((countryFeeStructure) => {
        const index = voucherCoverageSet.feeStructure
          .findIndex(fee => fee.country === countryFeeStructure.country);
        if (index === -1) {
          dispatch(addAgencyFeeStructure(voucherProductType, countryFeeStructure));
        }
      });
    }
  };

  const renderCountryRows = (filCountries) => {
    const countryRows = orderBy(filCountries, ['name'], ['asc']).map((country, i) => {
      const selected = voucherCoverageSet.feeStructure.find(set => set.country === country.code);

      return (
        <CoverageRow
          key={`${country.code}${i.toString()}`}
          country={country}
          voucherProductType={voucherProductType}
          agreementType={agreementType}
          selected={selected}
          canEdit={canEdit}
          showFeeStructureMoneyDetails={showFeeStructureMoneyDetails}
          currencies={currencies}
        />
      );
    });
    return countryRows;
  };

  // labels for table headers
  const tableHeaders = [
    { label: t('Country') },
    { label: t('msg_codes_transactionCurrency_label') },
    ...(showFeeStructureMoneyDetails ? [{
      label: <span className="required">{t('msg_codes_settlementCurrency_label')}</span>,
    }] : []),
    ...(showFeeStructureMoneyDetails ? [{
      label: <span className="required">{t('msg_codes_commision_label')}</span>,
    }] : []),
  ];

  const renderHeaderCells = () => {
    return tableHeaders.map(({ label }, headerCellIndex) => {
      const headerKey = headerCellIndex + 1;
      return (
        <TableHeaderCell
          id={headerKey}
          key={headerKey}
          value={label}
          className={label ? `${label}` : 'remove'}
        >
          {label}
        </TableHeaderCell>
      );
    });
  };

  const renderHeader = () => {
    const allSelected = checkAllSelected(voucherCoverageSet, countries);
    const headerCheckBoxState = allSelected ? GroupState.ALL : GroupState.NONE;

    return (
      <TableHeader
        id="agency-coverage-table-header"
        onCheckboxClick={() => toggleAllCountries(allSelected)}
        checkboxState={headerCheckBoxState}
      >
        {renderHeaderCells()}
      </TableHeader>
    );
  };

  const columnTemplate = showFeeStructureMoneyDetails
    ? 'repeat(4, 1fr)'
    : 'repeat(2, 1fr)';

  return (
    <DataTable
      columnTemplate={columnTemplate}
      scrollHeight={400}
      hasCheckbox
      id="agency-coverage-table"
    >
      {renderHeader()}
      {renderCountryRows(filteredCountries, agreementType)}
    </DataTable>
  );
};

VoucherForm.propTypes = {
  region: PropTypes.string,
  countries: PropTypes.arrayOf(PropTypes.object),
  agreementType: PropTypes.arrayOf(AgreementTypes),
  voucherProductType: PropTypes.string,
  voucherCoverageSet: PropTypes.object,
  canEdit: PropTypes.bool,
  currencies: PropTypes.array,
};

VoucherForm.defaultProps = {
  region: '',
  countries: [{
    name: 'United States',
    currency: 'USD',
    settlementCurrency: 'USD',
    commissionRate: 0.00,
    selected: false,
    code: 'US',
  }],
  agreementType: null,
  voucherProductType: '',
  voucherCoverageSet: {},
  canEdit: false,
  currencies: [],
};

export default VoucherForm;
