import React from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap-theme.css';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import baseUrl from './api/baseUrl';
import registerServiceWorker from './registerServiceWorker';
import configureStore from './store/configureStore';
import App from './App';
import history from './history';
import './utils/i18n';

const store = configureStore();
axios.defaults.baseURL = baseUrl;

axios.interceptors.request.use((config) => {
  return {
    ...config,
    withCredentials: true,
    headers: {
      ...config.headers,
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': localStorage.getItem('csrfToken'),
    },
  };
});

axios.interceptors.response.use((response) => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, (error) => {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (error.response) {
    if (error.response.status === 403 && error.response.headers['sony-sie-codes-maintenance-mode']) {
      history.push('/down');
      return error.response;
    }
  }
  return Promise.reject(error);
});

// DEBUG: Attach store to window so it can be viewed in hosted environments
if (process.env.NODE_ENV !== 'prod') {
  window.store = store;
}

render(
  <Provider store={store}>
    <App store={store} />
  </Provider>,
  document.getElementById('root') || document.createElement('div'),
);

registerServiceWorker();

// export store to be used in okta.js
export default store;
