export default [
  {
    id: 'id',
    value: 'agreementId',
    stringId: 'msg_codes_agreements_agreementID',
    sortable: true,
    width: '12%',
  },
  {
    id: 'partner',
    value: 'partner.name',
    stringId: 'msg_codes_agreements_contractingPartner',
    sortable: true,
    width: '15%',
  },
  {
    id: 'type',
    value: 'agreementType',
    stringId: 'msg_codes_agreements_agreementType',
    sortable: true,
    width: '13%',
  },
  {
    id: 'region',
    value: 'region',
    stringId: 'msg_codes_agreements_contractingSIEregion',
    sortable: true,
    width: '16%',
  },
  {
    id: 'countryCoverage',
    value: 'countryCoverage',
    stringId: 'msg_codes_agreements_countryCoverageLabel',
    sortable: false,
    width: '12%',
  },
  {
    id: 'voucherProductTypeCoverage',
    value: 'voucherProductTypeCoverage',
    stringId: 'msg_codes_agreements_voucheProductTypeCoverage',
    sortable: false,
    width: '18%',
  },
  {
    id: 'status',
    value: 'status',
    stringId: 'msg_codes_agreements_agreementStatus_label',
    sortable: true,
    width: '14%',
  },
];
