import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Layout,
  Flex,
  Button,
} from '@partner-global-ui/components';


export default function Header({
  id, name, permissions, readyToSave, checkIfImpactedOrderlines,
}) {
  const { t } = useTranslation();

  return (
    <Layout id="header" className="header">
      <Flex id="header-container" colSpan="6">
        <div className="header-title-container" data-testid="header-container">
          <h3
            id={`${name}-header`}
            name={`${name}-header`}
            data-testid="title"
            className="title"
          >
            {id ? t('msg_codes_codes_codeProduct') : t('msg_codes_new_code_product')}
          </h3>
        </div>
      </Flex>
      {(permissions.createDetail || permissions.editDetail) && (
        <Flex id="buttons-container" className="buttons-container" colSpan="6">
          <span className="buttons-wrapper">
            <Button
              className="cancel-button"
              id="cancel-button"
              secondary
            >
              <Link data-testid="cancel-button-link" to="/codeproducts">{t('msg_codes_cta_cancel')}</Link>
            </Button>
            <Button
              id="save-button"
              primary
              className="save-button"
              disabled={readyToSave}
              onClick={() => checkIfImpactedOrderlines()}
            >
              {t('msg_codes_cta_save')}
            </Button>
          </span>
        </Flex>
      )}
    </Layout>
  );
}

Header.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  readyToSave: PropTypes.bool,
  permissions: PropTypes.object,
  checkIfImpactedOrderlines: PropTypes.func,
};

Header.defaultProps = {
  id: '',
  name: '',
  readyToSave: false,
  permissions: {},
  checkIfImpactedOrderlines: () => { },
};
