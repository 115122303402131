import initialState from './initialState';
import * as types from '../actions/actionTypes';

export default (state = initialState.voucherBatchAuditHistory, action) => {
  switch (action.type) {
    case types.LOAD_VOUCHER_BATCH_AUDIT_HISTORY_SUCCESS:
      return {
        ...state,
        voucherBatchId: action.voucherBatchId,
        content: action.content,
      };
    case types.LOAD_VOUCHER_BATCH_AUDIT_HISTORY_FAILURE:
      return {
        ...state,
        voucherBatchId: action.voucherBatchId,
        content: [],
      };
    case types.RESET_VOUCHER_BATCH_AUDIT_HISTORY:
      return {
        ...state,
        voucherBatchId: '',
        content: [],
      };
    default:
      return state;
  }
};
