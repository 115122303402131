import il8n from 'i18next';
import { sortBy, isEqual } from 'lodash';
import initialState from './initialState';
import * as types from '../actions/actionTypes';
import countriesConstants from '../constants/countries.constants';
import { registrationRegionOptions } from '../constants/filterOptions.constants';
import { MONEY } from '../constants/codeProductTypes.constants';

const isCountryKorea = ({
  region,
  country,
  codeProductType,
  countries,
}) => {
  // if sieh region
  if (region === registrationRegionOptions[2].value) {
    // if code product type is money and country field is kr
    if (
      codeProductType === MONEY.code
    ) {
      if (countriesConstants[country] === countriesConstants.KR) {
        return true;
      }
      return false;
    }
    // if countries field has korea
    if (countries.length === 1 && countries[0] === il8n.t(countriesConstants.KR.code)) {
      return true;
    }
    return false;
  }
  return undefined;
};

const checkIfAgreementsUpdated = (state, payload) => {
  const existing = sortBy(state.applicableAgreementTypes);
  const incoming = sortBy(payload.value);
  const isChanged = !isEqual(existing, incoming);
  return isChanged;
};

const checkIfPartnersUpdated = (state, payload) => {
  const existing = sortBy(state.exclusiveToPartner);
  const incoming = sortBy(payload.value);
  const isChanged = !isEqual(existing, incoming);
  return isChanged;
};

export default (state = initialState.codeProduct, { type, payload }) => {
  switch (type) {
    case types.SET_PRODUCT_PROP:
      if (payload.prop === 'applicableAgreementTypes') {
        return {
          ...state,
          changesMade: checkIfAgreementsUpdated(state, payload),
          [payload.prop]: payload.value,
        };
      }

      if (payload.prop === 'exclusiveToPartner') {
        return {
          ...state,
          changesMade: checkIfPartnersUpdated(state, payload),
          [payload.prop]: payload.value,
        };
      }

      return {
        ...state,
        changesMade: true,
        [payload.prop]: payload.value,
      };

    case types.UPDATE_COUNTRIES:
      return {
        ...state,
        countries: payload,
      };

    case types.GET_PRODUCT:
      return {
        ...state,
        codeProductType: payload.codeProductType,
        changesMade: false,
        createdBy: payload.createdBy,
        dateCreated: payload.dateCreated,
        updatedBy: payload.updatedBy,
        lastUpdated: payload.lastUpdated,
        isKorea: isCountryKorea(payload),
        ...payload,
      };

    case types.GET_PRODUCT_SKU:
      return {
        ...state,
        skus: payload,
      };

    case types.SET_APPLICABLE_AGREEMENT_TYPES_MAP:
      return {
        ...state,
        applicableAgreementTypesMap: payload,
      };

    case types.SET_EXCLUSIVE_TO_PARTNERS_MAP:
      return {
        ...state,
        exclusiveToPartnerMap: payload,
      };

    case types.CLEAR_PRODUCT:
      return {
        ...initialState.codeProduct,
        applicableAgreementTypesMap: state.applicableAgreementTypesMap,
        exclusiveToPartnerMap: state.exclusiveToPartnerMap,
      };

    case types.CLEAR_PRODUCT_DETAIL:
      return {
        ...initialState.codeProduct,
        changesMade: state.changesMade,
        codeProductType: state.codeProductType,
        subscriptionType: state.subscriptionType,
        applicableAgreementTypesMap: state.applicableAgreementTypesMap,
        exclusiveToPartnerMap: state.exclusiveToPartnerMap,
      };

    case types.SET_IMPACTED_ORDERLINES:
      return {
        ...state,
        impactedOrderlines: payload,
      };

    case types.EXISTING_FOR_CONFIGURATION:
      return {
        ...state,
        existsForConfiguration: payload,
      };

    default:
      return state;
  }
};
