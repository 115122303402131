import React from 'react';
import { useSelector } from 'react-redux';
import {
  DataTable,
  NoResults,
  TableHeader,
  TableHeaderCell,
} from '@partner-global-ui/components';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import CoverageRow from './CoverageRow';
import './VoucherCoverageTable.scss';

const VoucherForm = ({
  voucherProductType,
}) => {
  const state = useSelector(globalState => globalState);
  const {
    agreementForm,
    agreementForm: {
      draftAgreementVersion,
      agreementVersionId,
      isNewVersion,
      isNewAgreement,
      canEditVersion,
    },
  } = state;
  let feeStructure = [];

  if (isNewAgreement || isNewVersion || canEditVersion) {
    const voucherProductTypeCoverage = draftAgreementVersion.voucherProductTypeCoverageSet
      .find(coverage => coverage.voucherProductType === voucherProductType);
    feeStructure = voucherProductTypeCoverage ? voucherProductTypeCoverage.feeStructure : [];
  } else {
    const selectedAgreementVersion = agreementForm.agreement.agreementVersions
      .find(agreementVersion => agreementVersion.agreementVersionId === agreementVersionId);
    // make dynamic
    if (selectedAgreementVersion.voucherProductTypeCoverageSet.length > 0) {
      const voucherProductTypeCoverage = selectedAgreementVersion.voucherProductTypeCoverageSet
        .find(coverage => coverage.voucherProductType === voucherProductType);
      ({ feeStructure } = voucherProductTypeCoverage);
    }
  }

  const internalOrders = [...feeStructure].sort((a, b) => {
    const sortValue1 = a.feeStructureId || a.uuid;
    const sortValue2 = b.feeStructureId || b.uuid;
    const sortValue2isNumber = typeof sortValue2 === 'number';
    if (sortValue2isNumber) {
      return sortValue1 > sortValue2 ? 1 : -1;
    }
    return (sortValue1 > sortValue2) ? -1 : 1;
  });

  const displayInternalOrdersRows = (iOrders) => {
    const internalOrderRows = iOrders.length ? iOrders.map(
      internalOrder => (
        <CoverageRow
          key={internalOrder.feeStructureId || internalOrder.uuid}
          feeStructure={internalOrder}
        />
      ),
    ) : (
      <NoResults />
    );
    return internalOrderRows;
  };

  // labels for table headers
  const tableHeaders = [
    { label: i18n.t('msg_codes_orderPurpose') },
    { label: i18n.t('msg_codes_membershipDuration') },
    { label: i18n.t('msg_codes_rechargeAmount') },
    ['Remove'],
  ];

  const renderHeaderCells = () => {
    return tableHeaders.map(({ label }, headerCellIndex) => {
      const headerKey = headerCellIndex + 1;
      return (
        <TableHeaderCell
          id={headerKey}
          key={headerKey}
          value={label}
          className={label ? `${label}` : 'remove'}
        >
          {label}
        </TableHeaderCell>
      );
    });
  };

  return (
    <DataTable
      columnTemplate="30% 30% 30% 10%"
      scrollHeight={400}
      id="1stParty-coverage-table"
      data-testid="1stParty-coverage-table"
    >
      <TableHeader>
        { renderHeaderCells() }
      </TableHeader>
      { displayInternalOrdersRows(internalOrders) }
    </DataTable>
  );
};

VoucherForm.propTypes = {
  voucherProductType: PropTypes.string,
};

VoucherForm.defaultProps = {
  voucherProductType: '',
};

export default VoucherForm;
