import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Collapsible } from '@partner-global-ui/components';
import VoucherFormComponent from './VoucherForm';
import './VoucherCoverageTable.scss';

const ThirdPartyContractualCoverageTable = ({
  product,
  coverages,
}) => {
  const { t } = useTranslation();
  const { voucherProductType } = product;
  const showCoverageTable = () => {
    const title = t('msg_codes_agreements_codeProductTypeCoverage');
    return (
      <div data-testid="third-party-coverage-table" className="third-party-coverage-table">
        <Collapsible
          heading={title}
          id="coverage-table"
          scrollHeight={400}
          rightContent={t('msg_codes_agreements_countriesSelected', {
            selectedNum: coverages.length,
            totalNum: coverages.length,
          })}
        >
          <VoucherFormComponent
            countries={coverages}
            product={product}
          />
        </Collapsible>
      </div>
    );
  };

  return (
    showCoverageTable(voucherProductType)
  );
};

ThirdPartyContractualCoverageTable.propTypes = {
  coverages: PropTypes.arrayOf(PropTypes.object),
  products: PropTypes.array,
};

ThirdPartyContractualCoverageTable.defaultProps = {
  coverages: [{
    name: 'United States',
    currency: 'USD',
    settlementCurrency: 'USD',
    commissionRate: 0,
    selected: false,
    code: 'US',
  }],
  products: [],
};

export default ThirdPartyContractualCoverageTable;
