import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default (state = initialState.usersPage, action) => {
  switch (action.type) {
    case types.FINISH_LOAD_PARTNER_USERS:
      return {
        ...state,
        users: action.users,
      };
    case types.SET_USERS_PAGE:
      return {
        ...state,
        page: action.usersPage.page,
      };
    default:
      return state;
  }
};
