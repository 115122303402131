import React, { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import PropTypes from 'prop-types';

import {
  TableCell, TableRow, Truncate,
} from '@partner-global-ui/components';

import { getTargetRegionNames } from '../../../utils/region.utils';
import {
  getProductType,
  getPlatform,
} from '../../../constants/thirdPartyCatalog.constants';
import ExpandedCatalog from './expandedCatalog';

const catalogReducer = (state, action) => {
  switch (action.type) {
    case 'toggle':
      return !state;
    case 'set':
      return action.isExpanded;
    default:
      return state;
  }
};

const ThirdPartyCatalogParentRow = ({
  productVariant,
  productVariant: {
    catalogs,
    estimatedReleaseDate,
    platform,
    productId,
    productTypeCode,
    codeProduct,
    targetRegions = '-',
    scpProductUrl,
  },
  addProductToDownloadQueue,
  removeProductsFromDownloadQueue,
  catalogPageDispatch,
  globalDisptach,
  initialIsExpandedState = false,
  tableRef,
  ...rest
}) => {
  const [isExpanded, localDispatch] = useReducer(catalogReducer, initialIsExpandedState);
  useEffect(() => {
    localDispatch({
      type: 'set',
      isExpanded: false,
    });
  }, [productId]);
  const { t } = useTranslation();
  const rowClick = () => {
    return () => {
      localDispatch({ type: 'toggle' });
    };
  };

  return (
    <TableRow
      key={productId}
      id={productId}
      pad
      selected={isExpanded}
      onClick={rowClick(isExpanded)}
      expanded={isExpanded}
      expandable={catalogs.length > 0}
      expandedContent={(
        <ExpandedCatalog
          catalogs={catalogs}
          productVariant={productVariant}
          addProductToDownloadQueue={addProductToDownloadQueue}
          removeProductsFromDownloadQueue={removeProductsFromDownloadQueue}
          catalogPageDispatch={catalogPageDispatch}
          globalDisptach={globalDisptach}
          tableRef={tableRef}
          showInteractiveRowCTA
        />
      )}
      {...rest}
    >
      <TableCell id="codeProduct" key={`${productId}-codeProduct`} sortable>
        <Truncate content={codeProduct}>
          <a href={scpProductUrl} rel="noopener noreferrer" target="_blank">{codeProduct}</a>
        </Truncate>
      </TableCell>
      <TableCell id="productId" key={`${productId}-productId`} sortable>
        {productId}
      </TableCell>
      <TableCell id="productTypeCode" key={`${productId}-productTypeCode`} sortable>
        {t(getProductType(productTypeCode))}
      </TableCell>
      <TableCell id="platform" key={`${productId}-platform`} sortable>
        {t(getPlatform(platform))}
      </TableCell>
      <TableCell
        id="registrationRegion"
        key={`${productId}-registrationRegion`}
        className="registrationRegion"
      >
        {getTargetRegionNames(targetRegions, t)}
      </TableCell>
      <TableCell id="estimatedReleaseDate" key={`${productId}-estimatedReleaseDate`} sortable>
        {estimatedReleaseDate ? moment.utc(estimatedReleaseDate).format('YYYY/MM/DD') : t('msg_codes_codes_status_notAvailable')}
      </TableCell>
    </TableRow>
  );
};

ThirdPartyCatalogParentRow.propTypes = {
  productVariant: PropTypes.object,
  addProductToDownloadQueue: PropTypes.func,
  removeProductsFromDownloadQueue: PropTypes.func,
  catalogPageDispatch: PropTypes.func,
  globalDisptach: PropTypes.func,
  initialIsExpandedState: PropTypes.bool,
  tableRef: PropTypes.object,
};

ThirdPartyCatalogParentRow.defaultProps = {
  productVariant: { },
  addProductToDownloadQueue: () => { },
  removeProductsFromDownloadQueue: () => { },
  catalogPageDispatch: () => { },
  globalDisptach: () => { },
  initialIsExpandedState: false,
  tableRef: { },
};

export default ThirdPartyCatalogParentRow;
