import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function partnerReducer(state = initialState.partner, action) {
  switch (action.type) {
    case types.CHANGE_PARTNER_PROP_SUCCESS:
      return {
        ...state,
        [action.name]: action.value,
      };
    case types.NEW_PARTNER_SUCCESS:
      return initialState.partner;
    case types.LOAD_PARTNER_SUCCESS:
      return {
        ...state,
        ...action.partner,
        partner: {
          ...action.partner.partner,
          initialName: action.partner.partner.name,
          initialStatus: action.partner.partner.status,
        },
      };
    default:
      return state;
  }
}
