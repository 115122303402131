export const prodPartials = [
  '//codes.',
  '//vouchers.',
  '//venom-pqa.',
  '//venom-mgmt.',
  '//venom-spint.',
];

export const isProd = (origin, urls) => {
  return Boolean(urls.find((url) => {
    return origin.includes(url);
  }));
};
