import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './orderEmailActionPage.scss';
import OrderEmailActionContent from './orderEmailActionPageContent';
import successImage from '../assets/images/astro_pointing_up.png';
import errorImage from '../assets/images/astro_sad.png';
import expiredRejectedImage from '../assets/images/astro_sit_with_flower.png';
import alreadyTakenImage from '../assets/images/astro_hands_on_hips.png';
import { emailOrderAction, emailOrderResend } from '../../actions/orderActions';


const STATUSES = {
  APPROVED: 'approved',
  REJECTED: 'rejected',
  EXPIRED_RECENTLY: 'expired_recently',
  EXPIRED: 'expired',
  ALREADY_TAKEN_APPROVED: 'already_taken_approved',
  ALREADY_TAKEN_REJECTED: 'already_taken_rejected',
  ERROR: 'error',
};


function OrderEmailAction() {
  const { t } = useTranslation();
  const [status, setStatus] = useState(null);
  const [emailId, setEmailId] = useState('');
  const [orderAction, setOrderAction] = useState('');
  const [orderNumber, setOrderNumber] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const email = queryParams.get('id');
    setEmailId(email);

    const order = queryParams.get('orderId');
    setOrderNumber(order);

    const action = queryParams.get('eventType');
    setOrderAction(action);
  }, []);

  useEffect(() => {
    if (emailId) {
      emailOrderAction(emailId, orderAction)
        .then((result) => {
          const response = result?.response?.data;
          if (!response) {
            if (orderAction === 'APPROVE') {
              setStatus(STATUSES.APPROVED);
            } else if (orderAction === 'REJECT') {
              setStatus(STATUSES.REJECTED);
            }
          } else if (/Order: \S+ has already been approved at this approval level/.test(response.message)) {
            setStatus(STATUSES.ALREADY_TAKEN_APPROVED);
          } else if (/Order: \S+ has already been rejected/.test(response.message)) {
            setStatus(STATUSES.ALREADY_TAKEN_REJECTED);
          } else if (/Order: \S+ OrderStatus: APPROVED_ORDER has already been used/.test(response.message)) {
            setStatus(STATUSES.ALREADY_TAKEN_APPROVED);
          } else if (/Order: \S+ OrderStatus: REJECTED has already been used/.test(response.message)) {
            setStatus(STATUSES.ALREADY_TAKEN_REJECTED);
          } else if (/Order: \S+ OrderStatus: APPROVED_ORDER is no longer in a state to be approved or rejected/.test(response.message)) {
            setStatus(STATUSES.ALREADY_TAKEN_APPROVED);
          } else if (/Order: \S+ OrderStatus: REJECTED is no longer in a state to be approved or rejected/.test(response.message)) {
            setStatus(STATUSES.ALREADY_TAKEN_REJECTED);
          } else if (/Order: \S+ is expired, and email is ineligible to be resent/.test(response.message)) {
            setStatus(STATUSES.EXPIRED);
          } else if (/Order: \S+ is expired, but email is eligible to be resent/.test(response.message)) {
            setStatus(STATUSES.EXPIRED_RECENTLY);
          } else {
            setStatus(STATUSES.ERROR);
          }
        });
    }
  }, [emailId]);

  const renderPage = useCallback(() => {
    if (!status) return <></>;
    let title = '';
    let image = null;
    let description = '';
    let buttonAction = null;
    let buttonText = '';
    let showButton = false;

    switch (status) {
      case STATUSES.APPROVED:
        title = t('msg_codes_approval_successful');
        description = t('msg_codes_order_approved_success', { orderNumber });
        image = successImage;
        break;

      case STATUSES.EXPIRED: {
        const orderURLPath = `${window.location.origin}/order/${orderNumber}`;
        const orderURL = `<a href="${orderURLPath}">${orderURLPath}</a>`;
        title = t('msg_codes_something_went_wrong');
        description = t('msg_codes_order_expired_error', { orderNumber, orderURL, interpolation: { escapeValue: false } });
        image = expiredRejectedImage;
        showButton = true;
        buttonText = t('msg_codes_review_order_cta');
        buttonAction = () => {
          window.location = `/order/${orderNumber}`;
        };
        break;
      }
      case STATUSES.EXPIRED_RECENTLY:
        title = t('msg_codes_something_went_wrong');
        description = t('msg_codes_order_expired_24hrs_firstweek_error', { orderNumber });
        image = expiredRejectedImage;
        showButton = true;
        buttonText = t('msg_codes_resend_email_cta');
        buttonAction = async () => {
          try {
            await emailOrderResend(emailId);
          } catch (error) {
            setStatus(STATUSES.ERROR);
          }
        };
        break;

      case STATUSES.ALREADY_TAKEN_APPROVED:
        title = t('msg_codes_action_taken');
        description = t('msg_codes_order_approved_already_error', { orderNumber });
        image = alreadyTakenImage;
        break;

      case STATUSES.ALREADY_TAKEN_REJECTED:
        title = t('msg_codes_action_taken');
        description = t('msg_codes_order_rejected_already_error', { orderNumber });
        image = alreadyTakenImage;
        break;

      case STATUSES.REJECTED:
        title = t('msg_codes_order_rejected');
        description = t('msg_codes_order_rejected_success', { orderNumber });
        image = expiredRejectedImage;
        break;

      case STATUSES.ERROR: {
        const orderURLPath = `${window.location.origin}/order/${orderNumber}`;
        const orderURL = `<a href="${orderURLPath}">${orderURLPath}</a>`;
        title = t('msg_codes_something_went_wrong');
        description = t('msg_codes_approve_order_error', { orderNumber, orderURL, interpolation: { escapeValue: false } });
        image = errorImage;
        showButton = true;
        buttonText = t('msg_codes_review_order_cta');
        buttonAction = () => {
          window.location = `/order/${orderNumber}`;
        };
        break;
      }
      default:
        break;
    }
    return (
      <OrderEmailActionContent
        orderNumber={orderNumber}
        image={image}
        title={title}
        description={description}
        buttonText={buttonText}
        buttonAction={buttonAction}
        showButton={showButton}
      />
    );
  }, [status]);

  return (
    <div
      data-testid="confirmation"
      className="confirmation"
    >
      {renderPage()}
    </div>
  );
}

export default OrderEmailAction;
