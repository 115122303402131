import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  TableCell, TableRow, Kabob, Tooltip,
} from '@partner-global-ui/components';
import { Link } from 'react-router-dom';
import formatDate from '../../utils/formatDate';
import { formatDateTimeInLocalWithUTC } from '../../utils/formatDateTime';
import {
  isActionAllowed, formatVoucherBatchStatus, getKabobItems,
} from '../../utils/voucherBatchStatus';
import voucherBatchPurposes from '../../mocks/voucherBatchPurposes';

const VoucherBatch = ({ ...props }) => {
  const { t } = useTranslation();
  const {
    voucherBatch,
    onCheckboxClick,
    checkboxState,
    onBatchAction,
    bulkAction,
    isEditable,
    tableRef,
  } = props;
  const {
    voucherBatchId,
    controlLabel,
    voucherTypeId,
    voucherPurposeId,
    startDate,
    endDate,
    modifyDate,
    npType,
    skusCount,
  } = voucherBatch;
  const readOnlyBatch = typeof voucherBatch.partnerId === 'number';
  const selectedPurpose = voucherBatchPurposes.find((purpose) => {
    return purpose.value === voucherPurposeId;
  }) || { label: t('msg_codes_codes_status_notAvailable') };
  const kabobItems = getKabobItems(voucherBatch, onBatchAction, isEditable);

  const renderStatus = () => {
    if (readOnlyBatch) {
      return (
        <span className="read-only-status">
          <span className="status-name">{formatVoucherBatchStatus(voucherBatch)}</span>
          <span className="status-icon">
            <Tooltip
              id="readOnlyBatchTooltip"
              anchor={tableRef}
              content={t('msg_codes_read_only_fields')}
              position="top"
              strategy="fixed"
            >
              <Kabob
                id="voucherBatch-kabob-tooltip-readOnly"
                disabled={kabobItems.length === 0 || readOnlyBatch}
                menuItems={kabobItems}
              />
            </Tooltip>
          </span>
        </span>
      );
    }
    return (
      <>
        {formatVoucherBatchStatus(voucherBatch)}
        <Kabob
          id="voucherBatch-kabob"
          disabled={kabobItems.length === 0 || readOnlyBatch}
          menuItems={
            kabobItems
          }
        />
      </>
    );
  };

  const checkboxDisabled = !isActionAllowed(
    bulkAction, voucherBatch, readOnlyBatch, npType, skusCount, isEditable,
  );
  return (
    <>
      <TableRow
        key={voucherBatchId}
        id={`voucher-batch-table-row-${voucherBatchId}`}
        pad
        value={voucherBatch}
        hasCheckbox
        checkboxState={checkboxState}
        checkboxProps={{
          disabled: checkboxDisabled,
        }}
        onCheckboxClick={() => !checkboxDisabled && onCheckboxClick(voucherBatch)}
        className="voucher-batch-row"
        data-testid="voucher-batch-table-row-container"
      >
        <TableCell id="voucherBatch-batchId" className="voucherBatch-batchId"><Link to={`/voucherbatch/${voucherBatchId}`}>{voucherBatchId}</Link></TableCell>
        <TableCell id="voucherBatch-controlLabel" className="voucherBatch-controlLabel">{controlLabel}</TableCell>
        <TableCell id="voucherBatch-typeId" className="voucherBatch-typeId"><span>{voucherTypeId}</span></TableCell>
        <TableCell id="voucherBatch-purpose" className="voucherBatch-purpose">{t(selectedPurpose.label)}</TableCell>
        <TableCell id="voucherBatch-startDate" className="voucherBatch-startDate">{formatDateTimeInLocalWithUTC(startDate)}</TableCell>
        <TableCell id="voucherBatch-endDate" className="voucherBatch-endDate">{formatDateTimeInLocalWithUTC(endDate)}</TableCell>
        <TableCell id={`voucherBatch-lastUpdated-${voucherBatchId}`} className="voucherBatch-lastUpdated">{formatDate(modifyDate)}</TableCell>
        <TableCell id="voucherBatch-kabob" className="voucherBatch-kabob">{renderStatus()}</TableCell>
      </TableRow>
    </>
  );
};

VoucherBatch.propTypes = {
  voucherBatch: PropTypes.object,
  cancelBatch: PropTypes.func,
  selectBatch: PropTypes.func,
  onBatchAction: PropTypes.func,
  isEditable: PropTypes.bool,
  bulkAction: PropTypes.string,
  checkboxState: PropTypes.number,
  onCheckboxClick: PropTypes.func,
  tableRef: PropTypes.object,
};

VoucherBatch.defaultProps = {
  voucherBatch: {},
  cancelBatch: () => null,
  selectBatch: () => null,
  onBatchAction: () => null,
  checkboxState: 1,
  onCheckboxClick: () => { },
  isEditable: false,
  bulkAction: '',
  tableRef: {},
};

export default VoucherBatch;
