import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function partnersPageReducer(state = initialState.partnersPage, action) {
  const { partnersPage, filterType, filterItems } = action;
  switch (action.type) {
    case types.SET_PARTNERS_PAGE:
      return { ...state, ...partnersPage };
    case types.SET_PARTNERS_FILTER:
      return {
        ...state,
        filters: { ...state.filters, [filterType]: filterItems },
        isFiltering: true,
      };
    case types.RESET_PARTNERS_PAGE_FILTERING:
      return { ...state, filters: initialState.partnersPage.filters, isFiltering: false };
    case types.BEGIN_LOAD_PARTNERS:
      return {
        ...state,
        loadingPartners: true,
      };
    case types.END_LOAD_PARTNERS:
      return {
        ...state,
        loadingPartners: false,
      };
    default:
      return state;
  }
}
