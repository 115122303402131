import React, { useEffect, useReducer, useCallback } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import {
  Button, Tooltip, Icon, TableRow, TableCell, Truncate,
} from '@partner-global-ui/components';
import thirdPartyErrorStatus from '../../../constants/thirdPartyErrorStatus';
import {
  getProductType,
} from '../../../constants/thirdPartyCatalog.constants';
import ExpandedOrderRow from './expandedOrderRow';
import ordersHistoryStatus from '../../../utils/ordersHistoryStatus';
import getRegionName from '../../../utils/region.utils';


const cartReducer = (state, action) => {
  switch (action.type) {
    case 'toggle':
      return !state;
    case 'set':
      return action.isExpanded;
    default:
      return state;
  }
};

const OrderHistoryRow = ({
  tableRef,
  order: {
    productName,
    orderId,
    productId,
    productTypeCode,
    availabilityRegion,
    creationDate,
    createdBy,
    ctaStatus,
    error,
    contractualErrorCode,
    scpProductUrl,
    pending,
  },
  order,
  checkboxState,
  canCheckRow,
  onCheckboxClick,
  onCTAButtonClick,
  isOrderRowView,
}) => {
  //  Setup
  const { t } = useTranslation();
  const [isExpanded, localDispatch] = useReducer(cartReducer, false);

  useEffect(() => {
    localDispatch({
      type: 'set',
      isExpanded: false,
    });
  }, [order.orderId]);

  const rowClick = () => {
    return () => {
      localDispatch({ type: 'toggle' });
    };
  };

  const shouldHaveIcon = useCallback(() => {
    if (ctaStatus && ctaStatus.showDownloadIcon) {
      return 'ico-download';
    }
    return '';
  });

  // change the button status to active or inactive
  // only if it is in the Re-download status

  const orderStatus = useCallback(() => {
    if (order.orderStatus === ordersHistoryStatus.REDOWNLOAD.statusName) {
      if (order.activationState === 'ACTIVATED') {
        return t('msg_codes_partnerStatus_active');
      }
      return t('msg_codes_partnerStatus_inactive');
    }
    return null;
  })();

  return (
    <TableRow
      key={orderId}
      id={orderId}
      pad
      value={order}
      hasCheckbox
      checkboxState={checkboxState}
      checkboxProps={{
        disabled: !canCheckRow,
      }}
      onCheckboxClick={() => onCheckboxClick(orderId)}
      onClick={rowClick(isExpanded)}
      selected={isExpanded}
      expanded={isExpanded}
      expandable={order !== null}
      expandedContent={(
        <ExpandedOrderRow
          order={order}
          tableRef={tableRef}
          isOrderRowView={isOrderRowView}
        />
      )}
      data-testid="order-history-row-container"
    >
      <TableCell id={`codeProductName-${orderId}-${productId}`} key={`${orderId}-${productId}-codeProductName`} sortable>
        {productName && (
          <div className="code-product-name-container">
            <Truncate content={productName} anchor={tableRef}>
              <a
                className="code-product-link"
                rel="noopener noreferrer"
                target="_blank"
                href={scpProductUrl}
                data-testid="order-history-row-product-name"
              >
                {productName}
              </a>
            </Truncate>
          </div>
        )}
        {(error || contractualErrorCode) && (
          <span className="order-history-info-tooltip">
            <Tooltip
              id="order-history-info-tooltip"
              content={t(thirdPartyErrorStatus[error || contractualErrorCode])}
              position="top"
              strategy="fixed"
              data-testid="order-history-row-info-tooltip"
              anchor={tableRef}
            >
              <Icon size={16}>error</Icon>
            </Tooltip>
          </span>
        )}
      </TableCell>
      <TableCell id={`productId-${orderId}-${productId}`} key={`${orderId}-${productId}-productId`} sortable>
        {productId && (
          <Truncate content={productId} data-testid="order-history-row-product-id">
            {productId}
          </Truncate>
        )}
      </TableCell>
      <TableCell id={`productType-${orderId}-${productId}`} key={`${orderId}-${productId}-productType`} sortable>
        {productTypeCode && (
          <Truncate content={productTypeCode} data-testid="order-history-row-product-type">
            {t(getProductType(productTypeCode))}
          </Truncate>
        )}
      </TableCell>
      <TableCell id={`availabilityRegion-${orderId}-${productId}`} key={`${orderId}-${productId}-availabilityRegion`} sortable>
        {availabilityRegion && (
          <Truncate tooltipContent={t(getRegionName(availabilityRegion))} data-testid="order-history-row-availabilityRegion">
            {t(getRegionName(availabilityRegion))}
          </Truncate>
        )}
      </TableCell>
      <TableCell id={`orderId-${orderId}-${productId}`} key={`${orderId}-${productId}-orderId`}>
        {orderId && (
          <Truncate content={orderId} sortable data-testid="order-history-row-order-id">
            {orderId}
          </Truncate>
        )}
      </TableCell>
      <TableCell id={`created-${orderId}-${productId}`} key={`${orderId}-${productId}-created`} sortable data-testid="order-history-row-creation-date">
        {creationDate && moment(creationDate).format('YYYY/MM/DD')}
      </TableCell>
      <TableCell id={`createdBy-${orderId}-${productId}`} key={`${orderId}-${productId}-createdBy`} sortable data-testid="order-history-row-created-by">{createdBy}</TableCell>
      <TableCell
        className="order-cta"
        name="order-cta"
        key={`${orderId}-${productId}-orderCTA`}
        id={`orderCTA-${orderId}-${productId}`}
      >
        <div
          className="cta-button-wrap"
          name="cta-button-wrap"
        >
          <Button
            icon={shouldHaveIcon(order)}
            className="order-cta-button"
            success={ctaStatus && ctaStatus.buttonStyle === 'primary'}
            warning={ctaStatus && ctaStatus.buttonStyle === 'secondary'}
            link={ctaStatus && ctaStatus.buttonStyle === 'link'}
            onClick={() => onCTAButtonClick(order)}
            data-testid="order-history-row-order-cta"
            disabled={
              (ctaStatus && ctaStatus.buttonDisabled)
              || pending
            }
          >
            {ctaStatus && `${t(ctaStatus.buttonText)} ${t(orderStatus)}`}
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
};

OrderHistoryRow.propTypes = {
  order: PropTypes.object,
  checkboxState: PropTypes.func,
  onCTAButtonClick: PropTypes.func,
  isOrderRowView: PropTypes.bool,
  tableRef: PropTypes.object,
  canCheckRow: PropTypes.bool,
  onCheckboxClick: PropTypes.func,
};

OrderHistoryRow.defaultProps = {
  order: { },
  checkboxState: () => { },
  onCTAButtonClick: () => { },
  isOrderRowView: false,
  tableRef: { },
  canCheckRow: false,
  onCheckboxClick: () => { },
};

export default OrderHistoryRow;
