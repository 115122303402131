import React, { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  NotificationContext,
  Button,
  Container,
  Layout,
  Flex,
  Modal,
  ModalContext,
  MODAL_ACTIONS,
  ScrollArea,
} from '@partner-global-ui/components';
import {
  SUSPENDED, INACTIVE, ACTIVE,
} from '../../constants/agreementState.constants';
import countries from '../../constants/countries.constants';
import { displayStatuses } from '../../constants/order.constants';
import { activitySelectTypes } from '../../constants/activityType.constants';
import handleSaveClick from '../../actions/agreementFormHandleSave';
import checkSaveClick from '../../actions/agreementFormBeforeSave';
import { usePermission } from '../../utils/accessControl/hasPermission';
import roleKeys from '../../utils/accessControl/roleKeys';
import handleUnsavedChanges, { cleanupHandleUnsavedChanges } from '../../utils/handleUnsavedChanges';
import { canUpdateExistingAgreement } from '../../utils/isValidAgreementForm';
import AgreementInfo from './PermittedUsesForm/PermittedUsesAgreementInformation';
import ActivityTable from './PermittedUsesForm/PermittedUsesActivityTable';
import HistoryTable from './PermittedUsesForm/PermittedUsesHistoryTable';
import './PermittedUses.scss';

function PermittedUsesDetailPage({ location, history }) {
  const notificationCont = useContext(NotificationContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { push } = history;

  const agreementForm = useSelector(state => state.agreementForm);
  const [warnOrderAffected, setWarnOrderAffected] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [buttonState, setButtonState] = useState({ label: 'Save', disabled: true });
  const modalContext = useContext(ModalContext);

  const canEdit = usePermission(roleKeys.agreement.edit);

  const {
    agreement,
    agreement: {
      agreementVersions,
      regionToCountryMapping,
    },
    hasBeenModified,
    isNewVersion,
    draftAgreementForm,
    isSaving,
    orderLines,
  } = agreementForm;

  const regionCountries = regionToCountryMapping && regionToCountryMapping.reduce((acc, curr) => ({
    ...acc,
    [curr.region]: curr.countries.map(countryCode => t(countries[countryCode].name)),
  }), {});

  const [currentVersion] = agreement.agreementVersions;
  const { orderActivities } = currentVersion;
  const { feeStructure: activityCoverage } = currentVersion.voucherProductTypeCoverageSet[0];

  const voucherActivityCoverage = activityCoverage.reduce((current, next) => {
    if (current[next.orderActivity]) {
      current[next.orderActivity].push(next);
    } else {
      current[next.orderActivity] = [next];
    }

    return current;
  }, {});

  const discTypeActivityCoverage = activityCoverage.filter(item => item.orderActivity === 'DISC_SUBSTITUTE')
    .map(item => ({
      ...item,
      orderFee: activitySelectTypes.DISC_SUBSTITUTE.orderFee(),
      orderFeeCurrencyCode: '',
    })).reduce((curr, next) => {
      if (curr[next.orderActivity]) {
        curr[next.orderActivity].push(next);
      } else {
        curr[next.orderActivity] = [next];
      }

      return curr;
    }, {});

  const hideWarningModalCancel = () => {
    modalContext.dispatch({
      type: MODAL_ACTIONS.HIDE,
    });
    setWarnOrderAffected(false);
    setShowWarningModal(false);
  };

  const hideWarningModalYes = () => {
    if (warnOrderAffected) {
      setWarnOrderAffected(false);
    }
    const shouldSave = true;
    const queryParams = new URLSearchParams(location.search);
    const returnPath = queryParams.has('return') ? queryParams.get('return') : '/agreements';
    handleSaveClick(
      notificationCont,
      agreementForm,
      () => push(returnPath),
      shouldSave,
      hideWarningModalCancel,
    )(dispatch);
  };

  const orderLineStatusValueMap = displayStatuses.reduce((acc, curr) => ({
    ...acc,
    [curr.value]: curr.translationStringId,
  }), {});

  const scrollAreaHeight = (orderLines.length * 24) + 24 > 400
    ? 400
    : (orderLines.length * 24) + 24;

  const warningModalBody = (
    <div
      name="WarningModalText"
      className="modal-body-content"
    >
      <div
        name="WarningModalIntro"
        className="warning-modal-intro"
        data-testid="warning-modal-intro"
      >
        {t('msg_codes_confirmation_saveChangesAgreement_body')}<br />
      </div>
      <br />
      <div style={{ height: `${scrollAreaHeight}px` }}>
        <ScrollArea overlay>
          <table className="orderlines-table">
            <tbody>
              <tr>
                <th className="tableCell">{t('msg_codes_codes_orderID')}</th>
                <th className="tableCell">{t('msg_codes_codes_orderReference')}</th>
                <th className="tableCell">{t('msg_codes_codes_submissionStatus')}</th>
              </tr>
              {
                orderLines.map((orderLine, index) => {
                  const key = index + 1;
                  return (
                    <tr key={key}>
                      <td className="tableCell">{orderLine.orderNumber}</td>
                      <td className="tableCell">{orderLine.orderReference}</td>
                      <td className="tableCell">{t(orderLineStatusValueMap[orderLine.orderStatus])}</td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </ScrollArea>
      </div>
    </div>
  );

  const modalProps = {
    id: 'third-party-warning-modal',
    name: 'ThirdPartyWarningModal',
    title: t('msg_codes_confirmation_saveChangesAgreement'),
    content: warningModalBody,
    primaryLabel: t('msg_codes_confirmation_saveChangesAgreement_cta_yesSave'),
    secondaryLabel: t('msg_codes_confirmation_leave_cta_no'),
    onPrimary: hideWarningModalYes,
    onSecondary: hideWarningModalCancel,
    onClose: hideWarningModalCancel,
    hideCancelButton: true,
  };

  useEffect(() => {
    setButtonState({
      ...buttonState,
      label: t('msg_codes_cta_save'),
      name: 'saveAgreementButton',
      actionType: 'save',
      disabled: {
        [true]: () => !canUpdateExistingAgreement(agreementForm),
        [!hasBeenModified.agreement]: () => true,
        [isSaving]: () => true,
      }.true(),
    });
  }, [agreementForm, hasBeenModified, draftAgreementForm]);

  useEffect(() => {
    if (showWarningModal) {
      modalContext.dispatch({
        type: MODAL_ACTIONS.SHOW,
        payload: <Modal {...modalProps} />,
      });
    }
  }, [showWarningModal]);

  useEffect(() => {
    if (agreementForm) {
      handleUnsavedChanges(hasBeenModified.agreement || hasBeenModified.version);
    }

    return () => {
      cleanupHandleUnsavedChanges();
    };
  }, [agreementForm, hasBeenModified]);

  function navigateToPreviousPage(notificationContLocal, shouldSave) {
    const {
      initialStatuses: { status: initialStatus },
      agreement: { status: currentStatus },
    } = agreementForm;

    const queryParams = new URLSearchParams(location.search);
    const returnPath = queryParams.has('return') ? queryParams.get('return') : '/agreements';

    const warnAffected = initialStatus === ACTIVE
      && (currentStatus === SUSPENDED || currentStatus === INACTIVE);

    setWarnOrderAffected(warnAffected);

    checkSaveClick(
      notificationContLocal,
      agreementForm,
      () => push(returnPath),
      shouldSave,
      warnAffected,
      setShowWarningModal,
    )(dispatch);
  }

  function renderLabel() {
    const { canEditVersion, agreementVersionId } = agreementForm;
    const { version } = hasBeenModified;

    const sortedAgreementVersions = _.orderBy(agreementVersions, 'agreementVersionId', ['asc']);
    const foundVersion = sortedAgreementVersions.reduce((foundNumber, localVersion, index) => {
      return localVersion.agreementVersionId === agreementVersionId ? index : foundNumber;
    }, '');

    if (canEditVersion && isNewVersion && version) {
      return _.padStart(sortedAgreementVersions.length + 1, 3, '0');
    }
    return _.padStart(foundVersion + 1, 3, '0');
  }

  function renderButton() {
    const name = buttonState.name ? buttonState.name : 'saveAgreementButton';
    const shouldSave = buttonState.actionType === 'save';

    return (
      <Button
        data-testid="save-agreement-button"
        id="saveAgreementButton"
        name={name}
        primary
        onClick={() => navigateToPreviousPage(
          notificationCont,
          shouldSave,
        )}
        disabled={buttonState.disabled}
      >
        {buttonState.label}
      </Button>
    );
  }

  function renderNav() {
    return (
      <div className="permitted-uses-agreement-header">
        <Container>
          <Layout>
            <Flex className="title" colSpan="10">
              {agreement.agreementId ? (
                <h3 name="agreementHeader">
                  {t(
                    'msg_codes_agreements_agreementIDnumber',
                    { ID: `0000000${agreement.agreementId}`.slice(-8) },
                  )}
                </h3>
              ) : (
                <h3 name="agreementHeader">
                  {t('msg_codes_orderList_agreement')}
                </h3>
              )}
              <div className="divider" />
              <span name="dropdown" className="version">
                <h6 name="dropdownLabel">
                  {t(
                    'msg_codes_agreements_versionNumber',
                    { versionNumber: renderLabel() },
                  )}
                </h6>
              </span>
            </Flex>
            <Flex className="button" colSpan="2">
              {(canEdit) && renderButton()}
            </Flex>
          </Layout>
        </Container>
      </div>
    );
  }

  return (
    <>
      <Prompt
        when={hasBeenModified.agreement || hasBeenModified.version}
        message={t('msg_codes_confirmation_quit_body')}
      />
      {renderNav()}
      <Container>
        <Layout className="agreement permitted-uses">

          <AgreementInfo agreementForm={agreementForm} editable={canEdit} />

          <Flex colSpan="12">
            <div data-testid="agreement-permitted-uses-section-label" className="section-label">
              {t('msg_codes_agreements_voucherActivityCoverage')}
            </div>
          </Flex>
          {orderActivities.includes('BETA_TESTING') && (
            <ActivityTable
              heading={t('msg_code_assignments_betaTesting')}
              activityData={voucherActivityCoverage.BETA_TESTING}
              regionCountries={regionCountries}
            />
          )}
          {orderActivities.includes('CONSUMER_PROMOTION') && (
            <ActivityTable
              heading={t('msg_codes_assignments_consumerPromo')}
              activityData={voucherActivityCoverage.CONSUMER_PROMOTION}
              regionCountries={regionCountries}
              defaultIsOpen
            />
          )}
          {orderActivities.includes('DISC_SUBSTITUTE') && (
            <ActivityTable
              heading={t('msg_codes_orderDetailsAdmin_activity_discSubstitute')}
              activityData={discTypeActivityCoverage.DISC_SUBSTITUTE}
              regionCountries={regionCountries}
            />
          )}
          {orderActivities.includes('RETAIL_PROMOTION') && (
            <ActivityTable
              heading={t('msg_codes_inboxPreorderPromotion')}
              activityData={voucherActivityCoverage.RETAIL_PROMOTION}
              regionCountries={regionCountries}
            />
          )}
          {orderActivities.includes('PUBLISHER_PAID_ALLOCATION') && (
            <ActivityTable
              heading={t('msg_codes_agreements_publisherPaidAllocation')}
              activityData={voucherActivityCoverage.PUBLISHER_PAID_ALLOCATION}
              regionCountries={regionCountries}
            />
          )}
          <HistoryTable agreementVersionId={currentVersion.agreementVersionId} />
        </Layout>
      </Container>
    </>
  );
}

PermittedUsesDetailPage.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
};

PermittedUsesDetailPage.defaultProps = {
  location: {},
  history: {},
};

export default PermittedUsesDetailPage;
