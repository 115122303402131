import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  DataTable,
  TableHeader,
  TableHeaderCell,
} from '@partner-global-ui/components';
import OrderLine from './OrderLine';
import { usePermission } from '../../../utils/accessControl/hasPermission';
import roleKeys from '../../../utils/accessControl/roleKeys';

const ThirdPartyOrder = ({
  order,
  isApproved,
}) => {
  const { t } = useTranslation();
  const tableRef = useRef();
  const canViewError = usePermission(roleKeys.order.viewThirdPartyLineErrors);

  const columnTemplate = isApproved(order)
    ? '20% 10% 10% 7% 9% 14% 10% 10% 10%'
    : '19% 18% 18% 17% 9% 19%';

  return (
    <DataTable
      key="order-list-sub-table"
      id="sub-table"
      subtable
      className="sku-info-sub-table"
      columnTemplate={columnTemplate}
      ref={tableRef}

    >
      <TableHeader id="sub-table-header-row">
        <TableHeaderCell id="sub-table-header-skuid">{t('msg_codes_orderList_skuid')}</TableHeaderCell>
        <TableHeaderCell id="sub-table-header-type">{t('msg_codes_codes_productType')}</TableHeaderCell>
        <TableHeaderCell id="sub-table-header-country">{t('msg_codes_orderList_countryHeader')}</TableHeaderCell>
        <TableHeaderCell id="sub-table-header-quantity">{t('msg_codes_codes_quantity')}</TableHeaderCell>
        <TableHeaderCell id="sub-table-header-useCount">{t('msg_codes_use_count')}</TableHeaderCell>
        <TableHeaderCell id="sub-table-header-batchid">{t('msg_codes_codes_batchID')}</TableHeaderCell>
        {isApproved(order)
          ? <TableHeaderCell id="sub-table-header-fulfillment">{t('msg_codes_orderDetailsAdmin_fulfillment_label')}</TableHeaderCell>
          : <></>
        }
        {isApproved(order)
          ? <TableHeaderCell id="sub-table-header-delivery">{t('msg_codes_orderDetails_delivery')}</TableHeaderCell>
          : <></>
        }
        {isApproved(order)
          ? <TableHeaderCell id="sub-table-header-activation">{t('msg_codes_orderDetailsAdmin_Activation_label')}</TableHeaderCell>
          : <></>
        }
      </TableHeader>
      {order.orderLines.map(line => (
        <OrderLine
          key={line.orderLineId}
          line={line}
          order={order}
          name="orderLineContent"
          canViewError={canViewError}
          t={t}
          tableRef={tableRef}
        />
      ))}
    </DataTable>
  );
};

ThirdPartyOrder.propTypes = {
  order: PropTypes.object,
  isApproved: PropTypes.func,
};

ThirdPartyOrder.defaultProps = {
  order: {},
  isApproved: () => {},
};

export default ThirdPartyOrder;
