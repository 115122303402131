import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  Layout,
  Flex,
  Button,
  ProgressIndicator,
  Modal,
  ModalTrigger,
  ModalContext,
  MODAL_ACTIONS,
  RadioGroup,
  Icon,
  TextInput,
} from '@partner-global-ui/components';
import { useTranslation } from 'react-i18next';
import { toNumber, isEmpty } from 'lodash';


const DownloadSection = ({
  activeCount,
  inactiveCount,
  deactivatedCount,
  isMoneyVoucherType,
  handleBatchActions,
  statusId,
  state,
  hasSkus,
  canEditVoucherBatch,
  batchId,
}) => {
  const modalContext = useContext(ModalContext);
  const { t } = useTranslation();

  const isInactive = statusId === 3;
  const isDeactivated = statusId === 4;
  const isCreationComplete = state === 'CREATION_COMPLETE' || state === 'FILE_GENERATION_ERROR';
  const isGenerated = state === 'FILE_GENERATED';
  const isGenerationInProgress = state === 'FILE_GENERATION_IN_PROGRESS';
  const isCreationInProgress = state === 'CREATION_IN_PROGRESS';

  const [isEntireBatch, setIsEntireBatch] = useState();
  const [startIndex, setStartIndex] = useState();
  const [endIndex, setEndIndex] = useState();

  const isValidIndexes = toNumber(endIndex) > toNumber(startIndex);

  const disabled = typeof isEntireBatch === 'undefined'
  || (
    isEntireBatch === 'false'
    && (isEmpty(startIndex) || isEmpty(endIndex))
    && !isValidIndexes
  );

  const renderWarningMessage = useCallback(() => {
    let message = '';
    if (typeof isEntireBatch === 'undefined') { return <></>; }
    if (isEntireBatch === 'true') {
      message = t('msg_codes_deactivate_batch', { BatchID: batchId });
    } else if (isValidIndexes) {
      message = t('msg_codes_deactivate_range', { BatchID: batchId, StartingIndex: startIndex, EndingIndex: endIndex });
    } else {
      return (<></>);
    }

    return (
      <>
        <Icon className="warning-icon">ico-warning</Icon>
        {message}
      </>
    );
  }, [isEntireBatch, startIndex, endIndex, isValidIndexes]);

  const renderActivateButton = () => {
    if (isInactive && (isCreationComplete || isGenerated)) {
      if (isMoneyVoucherType || hasSkus) {
        return (
          <div className="activate button">
            <Button
              className="action-button"
              id="voucher-batch-detail-activate-button"
              onClick={() => handleBatchActions('activate')}
            >
              {t('msg_codes_activate_cta')}
            </Button>
          </div>
        );
      }
    }
    return null;
  };

  const closeModal = () => {
    modalContext.dispatch({
      type: MODAL_ACTIONS.HIDE,
    });
  };

  const deactivateBatch = () => {
    if (isEntireBatch === 'true') {
      handleBatchActions('deactivate');
    } else {
      handleBatchActions('deactivate', { startIndex, endIndex });
    }

    setIsEntireBatch();
    setStartIndex('');
    setEndIndex('');
    closeModal();
  };

  const onSubmitClick = useCallback(() => {
    deactivateBatch();
  });

  const handleRadioChange = useCallback((e) => {
    setIsEntireBatch(e.target.value);
  });

  const renderContent = useCallback(() => {
    const batchOptions = [
      {
        label: t('msg_codes_entire_batch'),
        value: 'true',
      },
      {
        label: t('msg_codes_range'),
        value: 'false',
      },
    ];

    return (
      <>
        <RadioGroup
          name="radioGroup"
          id="radioGroup"
          label={t('msg_codes_deactivate_batch_or_range')}
          options={batchOptions}
          onChange={handleRadioChange}
          value={isEntireBatch !== null && isEntireBatch !== undefined && isEntireBatch.toString()}
          required
        />
        {typeof isEntireBatch !== 'undefined' && (
          <div className="warning-message">
            {renderWarningMessage()}
          </div>
        )}
        <br />
        {isEntireBatch === 'false' && (
          <Layout>
            <Flex>
              <TextInput
                type="number"
                label="Start Index"
                value={startIndex}
                onChange={e => setStartIndex(e.target.value)}
              />
            </Flex>
            <Flex>
              <TextInput
                type="number"
                label="End Index"
                value={endIndex}
                onChange={e => setEndIndex(e.target.value)}
              />
            </Flex>
          </Layout>
        )}
        <div className="custom-modal-footer">
          <span className="buttons-toolbar">
            <Button
              type="button"
              link
              onClick={() => closeModal()}
            >
              {t('msg_codes_cta_cancel')}
            </Button>
            <Button
              primary
              type="submit"
              onClick={() => onSubmitClick()}
              disabled={disabled}
            >
              {t('msg_codes_common_yes')}
            </Button>
          </span>
        </div>
      </>
    );
  }, [disabled, startIndex, endIndex, isEntireBatch, isValidIndexes]);

  const modalProps = {
    width: 900,
    height: 500,
    hideCancelButton: true,
    onPrimary: onSubmitClick,
    title: t('msg_codes_deactivate_codes_header'),
    content: renderContent(),
    className: 'cancel-batch-modal',
  };

  useEffect(() => {
    if (
      typeof isEntireBatch !== 'undefined'
    ) {
      modalContext.dispatch({
        type: MODAL_ACTIONS.SHOW,
        payload: <Modal {...modalProps} />,
      });
    }
  }, [isEntireBatch, startIndex, endIndex]);

  const renderDeactivateButton = useCallback(() => {
    if (!isDeactivated && (isCreationComplete || isGenerated)) {
      return (
        <div className="deactivate button">
          <ModalTrigger
            id="voucher-batch-detail-deactivate-button"
            modalProps={{
              ...modalProps,
            }}
            className="action-button"
            secondary
          >
            {t('msg_codes_deactivate_cta')}
          </ModalTrigger>
        </div>
      );
    }
    return null;
  }, [
    isEntireBatch, isDeactivated, isCreationComplete, isGenerated,
    disabled, startIndex, endIndex, isEntireBatch, isValidIndexes,
  ]);

  const renderDownloadButton = () => {
    return isGenerated ? (
      <div className="download button">
        <Button
          className="action-button"
          id="voucher-batch-detail-download-button"
          onClick={() => handleBatchActions('download')}
        >
          {t('msg_codes_cta_download')}
        </Button>
      </div>
    ) : null;
  };

  const renderGenerateCodeFileButton = () => {
    return isCreationComplete ? (
      <div className="generate button">
        <Button
          className="action-button"
          id="voucher-batch-detail-generate-file-button"
          onClick={() => handleBatchActions('generate')}
        >
          {t('msg_codes_generate_file')}
        </Button>
      </div>
    ) : null;
  };

  const renderProgressIndicator = () => {
    let loadingText;
    if (isCreationInProgress) {
      loadingText = t('msg_codes_generation_in_progress');
    } else {
      loadingText = t('msg_codes_file_generation_in_progress');
    }
    return (
      <ProgressIndicator
        loadingText={loadingText}
        size="lg"
        indeterminate
      />
    );
  };

  const renderBatchStatusDetailsTable = () => {
    return (
      <table className="table">
        <thead>
          <tr>
            <th scope="col">{t('msg_codes_batch_status')}</th>
            <th scope="col">{t('msg_codes_codes_quantity')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td id="voucher-batch-detail-active-text">{t('msg_codes_agreementStatus_active')}</td>
            <td id="voucher-batch-detail-active-count">{activeCount}</td>
          </tr>
          <tr>
            <td id="voucher-batch-detail-inactive-text">{t('msg_codes_agreementStatus_inactive')}</td>
            <td id="voucher-batch-detail-inactive-count">{inactiveCount}</td>
          </tr>
          <tr>
            <td id="voucher-batch-detail-deactivated-text">{t('msg_codes_codes_status_deactivated')}</td>
            <td id="voucher-batch-detail-deactivated-count">{deactivatedCount}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  return (
    <>
      <hr />
      <div className="download-section-container">
        <Layout className="content-row">
          <Flex colSpan={4}>
            <div
              className="download-section-header"
              id="voucher-batch-detail-download-section-header"
            >
              {t('msg_codes_download_file')}
            </div>
            <div className="download-section-description">
              {t('msg_codes_note_file_generated')}
            </div>
            {renderBatchStatusDetailsTable()}
          </Flex>
          {canEditVoucherBatch && (
            <Flex colSpan={6} className="button-container-col" id="voucher-batch-detail-download-section-container">
              {isGenerationInProgress || isCreationInProgress
                ? renderProgressIndicator() : (
                  <div className="button-group" data-testid="button-group">
                    <div>{renderActivateButton()}</div>
                    <div>{renderGenerateCodeFileButton()}</div>
                    <div>{renderDownloadButton()}</div>
                    <div>{renderDeactivateButton()}</div>
                  </div>
                )}
            </Flex>
          )}
        </Layout>
      </div>
    </>
  );
};

DownloadSection.propTypes = {
  activeCount: PropTypes.number,
  inactiveCount: PropTypes.number,
  deactivatedCount: PropTypes.number,
  handleBatchActions: PropTypes.func,
  statusId: PropTypes.number,
  state: PropTypes.string,
  isMoneyVoucherType: PropTypes.bool,
  hasSkus: PropTypes.bool,
  canEditVoucherBatch: PropTypes.bool,
  batchId: PropTypes.string,
};

DownloadSection.defaultProps = {
  activeCount: 0,
  inactiveCount: 0,
  deactivatedCount: 0,
  handleBatchActions: () => null,
  statusId: 0,
  state: '',
  isMoneyVoucherType: true,
  hasSkus: false,
  canEditVoucherBatch: false,
  batchId: '',
};

export default DownloadSection;
