import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Kabob,
  Modal,
  ModalContext,
  MODAL_ACTIONS,
} from '@partner-global-ui/components';
import PropTypes from 'prop-types';
import * as lineActions from '../../../actions/lineActions';
import * as orderActions from '../../../actions/orderActions';

const OrderLineToolTip = ({
  canCancel,
  canDownload,
  canActivate,
  isRemoved,
  isApproved,
  line,
  order,
  role,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modalContext = useContext(ModalContext);

  const actions = Object.assign({}, lineActions, orderActions);

  // Fetch variables from props
  const {
    activatable,
    cancelable,
    cancelled,
    downloadable,
    npVoucherBatchStatus,
    orderLineId,
    retryable,
    userVoucherDeliveryStatus,
    processingOrderLine,
  } = line;

  // Permissions
  const canActivateLine = canActivate && activatable;
  const canCancelLine = canCancel && cancelable;
  const canDownloadLine = canDownload && downloadable;
  const canRetryLine = retryable;

  // Do not render Tooltip if there are no actions to show
  const hideTooltip = (!canRetryLine && !canActivateLine && !canDownloadLine && !canCancelLine)
    || isRemoved
    || !isApproved
    || cancelled;
  if (hideTooltip) {
    return null;
  }

  // Functions
  const activate = () => {
    actions.activateItem(order, line, dispatch);
  };

  const hideCancelModal = () => {
    modalContext.dispatch({ type: MODAL_ACTIONS.HIDE });
  };

  const cancel = () => {
    actions.cancelItem(order, line, dispatch);
    hideCancelModal();
  };

  const download = () => {
    actions.deliverOrderLine(orderLineId, dispatch)
      .then((res) => {
        if (
          typeof res.downloadUrl !== 'undefined'
          && res.downloadUrl !== null
          && res.downloadUrl.length > 0
        ) {
          window.open(res.downloadUrl);
        }
        actions.loadOrder(order.orderNumber)(dispatch);
      });
  };

  const retry = () => {
    if (npVoucherBatchStatus === 'CREATION_ERROR') {
      actions.retryOrderLine(orderLineId, dispatch);
    } else {
      activate();
    }
  };

  const showCancelModal = () => {
    const modalProps = {
      id: 'agency-cancel-modal',
      name: 'AgencyCancelModal',
      title: t('msg_codes_cta_cancel'),
      content: role === 'SUPER_ADMIN' || role === 'ADMIN'
        ? t('msg_codes_order_cancelCodes_modal_body')
        : t('msg_codes_order_cancelCodes_modal_body'),
      primaryLabel: t('msg_codes_common_yes'),
      secondaryLabel: t('msg_codes_cta_no'),
      onPrimary: cancel,
      onSecondary: hideCancelModal,
      onClose: hideCancelModal,
      hideCancelButton: true,
    };

    modalContext.dispatch({
      type: MODAL_ACTIONS.SHOW,
      payload: <Modal {...modalProps} />,
    });
  };

  const menuItems = [
    ...(canDownloadLine ? [{
      label: userVoucherDeliveryStatus === 'DELIVERED'
        ? t('msg_codes_cta_redownload')
        : t('msg_codes_cta_download'),
      onClick: download,
      icon: 'ico-download',
      id: 'download',
    }] : []),
    ...(canRetryLine ? [{
      label: t('msg_codes_orderDetails_retry'),
      onClick: retry,
      icon: 'autorenew',
      id: 'retry',
    }] : []),
    ...(canActivateLine ? [{
      label: t('msg_codes_orderDetails_activate'),
      onClick: activate,
      icon: 'start',
      id: 'retry',
    }] : []),
    ...(canCancelLine ? [{
      label: t('msg_codes_cta_cancel'),
      onClick: showCancelModal,
      icon: 'ico-remove_cancel',
      id: 'cancel',
    }] : []),
  ];

  // Render
  return (
    <Kabob menuItems={menuItems} disabled={processingOrderLine} />
  );
};

OrderLineToolTip.propTypes = {
  canCancel: PropTypes.bool,
  canDownload: PropTypes.bool,
  canActivate: PropTypes.bool,
  isRemoved: PropTypes.bool,
  isApproved: PropTypes.bool,
  line: PropTypes.object,
  order: PropTypes.object,
  role: PropTypes.string,
};

OrderLineToolTip.defaultProps = {
  canCancel: false,
  canDownload: false,
  canActivate: false,
  isRemoved: false,
  isApproved: false,
  line: {},
  order: {},
  role: '',
};

export default OrderLineToolTip;
