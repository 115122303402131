import React, {
  useState, useEffect, useContext, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Collapsible,
  DataTable,
  TableHeader,
  TableHeaderCell,
  NoResults,
  NotificationContext,
  GroupState,
  RowDisplay,
  Pagination,
  Modal,
  ModalContext,
  MODAL_ACTIONS,
} from '@partner-global-ui/components';
import PropTypes from 'prop-types';
import tableHeaders from './VoucherBatchHeader';
import { VoucherBatchRow } from './VoucherBatchRow';
import RowDisplayLocalization from '../../helpers/RowDisplayLocalization';
import * as voucherTypeDetailActions from '../../actions/voucherTypeDetailActions';
import BulkActionSelect from '../common/BulkActionSelectNewUX';
import * as voucherBatchActions from '../../actions/voucherBatchActions';
import * as voucherBatchDetailActions from '../../actions/voucherBatchDetailActions';
import hasPermission from '../../utils/accessControl/hasPermission';
import roles from '../../utils/accessControl/roleKeys';
import RenderBulkWarningModal from '../voucherBatches/BulkCancelingWarningModal';


const VoucherBatchSection = (props) => {
  const { t } = useTranslation();
  const modalContext = useContext(ModalContext);
  const dispatch = useDispatch();
  const tableRef = useRef();
  const notificationCont = useContext(NotificationContext);
  const { userId } = useSelector(state => state.user);
  const [bulkAction, setBulkAction] = useState('placeholder');
  const [allBatchesSelected, setAllBatchesSelected] = useState(false);
  const [selectedBatches, setSelectedBatches] = useState([]);
  const [refreshHandle, setRefreshHandle] = useState(-1);
  const {
    canAddBatch,
    disableAddBatchButton,
    onNewBatchClick,
    voucherTypeId,
    isActionAllowed,
    isEditable,
  } = props;
  const voucherTypeBatchPage = useSelector(state => state.voucherTypeBatchPage);
  const loadingSelectVoucherBatches = useSelector(state => state.loadingSelectVoucherBatches);
  const canEditVoucherBatches = useSelector(state => hasPermission(state,
    roles.voucherBatches.edit));
  const {
    batches,
    page: {
      size, number, sort, sortOrder, totalElements,
    },
  } = voucherTypeBatchPage;

  useEffect(() => {
    return () => {
      dispatch(voucherTypeDetailActions.resetVoucherTypeBatchPage());
    };
  }, []);

  useEffect(() => {
    const selected = selectedBatches.filter((batchId) => {
      const batch = batches.find(b => b.voucherBatchId === batchId);
      if (batch) {
        return isActionAllowed(bulkAction, batch);
      }
      return false;
    });
    setAllBatchesSelected(selected && batches && selected.length === batches.length);
    setSelectedBatches(selected);
  }, [bulkAction]);

  // refresh page every 5 seconds for any voucher batches that are pending file generation
  useEffect(() => {
    if (batches && !loadingSelectVoucherBatches && refreshHandle === -1) {
      const pendingBatches = batches
        .filter(batch => batch.state === 'FILE_GENERATION_IN_PROGRESS'
          || batch.state === 'CREATION_IN_PROGRESS')
        .map(batch => batch.voucherBatchId);
      if (pendingBatches && pendingBatches.length > 0) {
        setRefreshHandle(setTimeout(() => {
          voucherBatchActions.fetchSelectVoucherBatches(
            notificationCont, pendingBatches, true,
          )(dispatch);
          setRefreshHandle(-1);
        }, 5000));
      }
    }
    return () => {
      if (refreshHandle !== -1) {
        clearTimeout(refreshHandle);
        setRefreshHandle(-1);
      }
    };
  }, [batches]);

  const fetchVoucherBatches = () => {
    setAllBatchesSelected(false);
    setSelectedBatches([]);
    const pageSize = localStorage.getItem(`voucherBatchSectionPageSize-${userId}`) !== null
      ? Number(localStorage.getItem(`voucherBatchSectionPageSize-${userId}`))
      : size;
    voucherTypeDetailActions.loadVoucherBatches(
      pageSize,
      number,
      { name: sort, order: sortOrder },
      { voucherTypeId },
    )(dispatch);
  };

  useEffect(() => fetchVoucherBatches(), [size, number, sort, sortOrder]);

  const noResultsMessage = canAddBatch && !disableAddBatchButton
    ? t("You don't have any voucher batches at this moment. Click on the top right button to add new items.")
    : canAddBatch && disableAddBatchButton
      ? t('Cannot Add New Voucher Batch. You must select a region to add a voucher batch.')
      : !canAddBatch
        ? t('No voucher batches associated with this Voucher Type')
        : <></>;

  const sortVoucherBatches = (name) => {
    // eslint-disable-next-line no-nested-ternary
    const newSortOrder = name === sort ? sortOrder === 'asc' ? 'desc' : 'asc' : 'asc';
    dispatch(voucherTypeDetailActions.setVoucherTypeBatchPage({
      ...voucherTypeBatchPage.page, sort: name, sortOrder: newSortOrder,
    }));
  };

  const toggleAllBatchesSelected = () => {
    if (!allBatchesSelected && batches && batches.length > 0) {
      setSelectedBatches(batches
        .filter(batch => isActionAllowed(bulkAction, batch))
        .map(batch => batch.voucherBatchId));
    } else {
      setSelectedBatches([]);
    }
    setAllBatchesSelected(!allBatchesSelected);
  };

  const toggleBatchSelected = (batchId) => {
    if (selectedBatches.includes(batchId)) {
      if (allBatchesSelected) {
        setAllBatchesSelected(false);
      }
      setSelectedBatches(selectedBatches.filter(b => b !== batchId));
    } else {
      if (selectedBatches.length + 1 === batches.length) {
        setAllBatchesSelected(true);
      }
      const selected = [...selectedBatches];
      selected.push(batchId);
      setSelectedBatches(selected);
    }
  };


  const hideBulkModal = () => {
    modalContext.dispatch({
      type: MODAL_ACTIONS.HIDE,
    });
  };

  const fetchSelectBatches = batchIds => () => voucherBatchActions
    .fetchSelectVoucherBatches(
      notificationCont, batchIds, true,
    )(dispatch);

  const handleBatchAction = (action, batchIds) => {
    setAllBatchesSelected(false);
    setSelectedBatches([]);
    if (batchIds && batchIds.length > 0) {
      const title = batchIds.length > 1 ? t('msg_codes_deactivate_multiple_batches_header')
        : t('msg_codes_deactivate_batch_header');
      const modalMessage = batchIds.length > 1 ? t('msg_codes_deactivate_multiple_batches')
        : t('msg_codes_deactivate_batch_warning');
      const modalQuestion = t('msg_codes_deactivated_warning');
      const modalProps = {
        id: 'bulk-warning-modal',
        name: 'BulkWarningModal',
        title,
        content: RenderBulkWarningModal(batchIds, modalMessage, modalQuestion),
        onSecondary: hideBulkModal,
        primaryLabel: t('msg_codes_common_yes'),
        secondaryLabel: t('msg_codes_cta_no'),
        hideCancelButton: true,
      };

      const handlePrimaryBulkModalClick = () => {
        voucherTypeDetailActions.deactivateVoucherBatches(
          batchIds,
          fetchSelectBatches(batchIds),
          notificationCont,
        )().then(() => {
          hideBulkModal();
        });
      };
      switch (action) {
        case 'activate':
          voucherTypeDetailActions.activateVoucherBatches(
            notificationCont, batchIds, fetchSelectBatches(batchIds),
          )();
          break;
        case 'generate':
          voucherTypeDetailActions.generateVoucherBatches(
            notificationCont, batchIds, fetchSelectBatches(batchIds),
          )();
          break;
        case 'download':
          voucherTypeDetailActions.downloadVoucherBatches(
            notificationCont, batchIds, fetchSelectBatches(batchIds),
          )();
          break;
        case 'deactivate':
          modalProps.onPrimary = handlePrimaryBulkModalClick;
          modalContext.dispatch({
            type: MODAL_ACTIONS.SHOW,
            payload: <Modal {...modalProps} />,
          });
          break;
        default:
          break;
      }
    }
  };

  const cancelBatch = (batchId, params, notificationContext) => {
    voucherBatchDetailActions.deactivateVoucherBatch(notificationContext, batchId, params,
      fetchSelectBatches([batchId]))(dispatch);
  };

  const renderTableRows = () => (batches.map(batch => (
    <VoucherBatchRow
      batch={batch}
      key={batch.voucherBatchId}
      isSelected={selectedBatches.includes(batch.voucherBatchId)}
      selectBatch={toggleBatchSelected}
      onBatchAction={handleBatchAction}
      isActionAllowed={isActionAllowed}
      bulkAction={bulkAction}
      cancelBatch={cancelBatch}
      isEditable={isEditable}
      tableRef={tableRef}
    />
  )));

  const changePage = (pageNumber) => {
    dispatch(voucherTypeDetailActions.setVoucherTypeBatchPage(
      { ...voucherTypeBatchPage.page, number: pageNumber - 1 },
    ));
  };

  const changeRowAmount = (pageSize) => {
    localStorage.setItem(`voucherBatchSectionPageSize-${userId}`, pageSize);
    dispatch(voucherTypeDetailActions.setVoucherTypeBatchPage({
      ...voucherTypeBatchPage.page, size: pageSize, number: 0,
    }));
  };

  function renderHeaderCells() {
    return tableHeaders.map((item, index) => {
      return (
        <TableHeaderCell
          id={index}
          key={item.value}
          sortable={item.sortable}
          value={item.value}
          sort={{
            sortBy: sort,
            sortDir: sortOrder,
          }}
          onClick={sortVoucherBatches}
          className={item.value}
        >
          {t(item.label)}
        </TableHeaderCell>
      );
    });
  }

  const columnTemplate = '18% 13% 11% 14% 14% 14% 10% 6%';

  return (
    <div data-testid="voucher-batch-section" ref={tableRef} className="voucher-batch-section">
      <Collapsible
        id="voucher-batch-section-container"
        heading="Associated Voucher Batches"
        defaultIsOpen
        {...((batches && batches.length > 4) ? { scrollHeight: 400 } : {})}
        rightContent={
          canAddBatch && !disableAddBatchButton
          && [{ actionCallback: onNewBatchClick, actionText: t('msg_codes_add_new_batch') }]
        }
      >
        <>
          {(batches && batches.length > 0) && (
            <BulkActionSelect
              disabled={!canEditVoucherBatches || !batches || batches.length === 0}
              onChange={value => setBulkAction(value)}
              onGo={() => handleBatchAction(bulkAction, selectedBatches)}
              selectedBatches={selectedBatches}
            />
          )}
          <DataTable
            id="voucher-batches-table"
            columnTemplate={columnTemplate}
            {...((batches && batches.length > 4) ? { scrollHeight: 400 } : {})}
            ref={tableRef}
            hasCheckbox
          >
            {(batches && batches.length > 0) ? (
              <TableHeader
                id="voucher-batches-table-header"
                hasCheckbox
                onCheckboxClick={toggleAllBatchesSelected}
                checkboxState={allBatchesSelected ? GroupState.ALL : GroupState.NONE}
              >
                {renderHeaderCells()}
              </TableHeader>
            ) : <></>}
            {
              (batches && batches.length > 0)
                ? renderTableRows()
                : (
                  <NoResults
                    id="no-results"
                    title=""
                    message={noResultsMessage}
                  />
                )
              }
          </DataTable>
          {totalElements > 10 && (
            <div data-testid="voucher-batches-paginator" className="voucher-batches-paginator">
              <RowDisplay
                id="voucher-batches-paginator-selector"
                currentPage={number + 1}
                totalItems={totalElements}
                pageSizes={[10, 25, 50, 100]}
                onPageSizeChange={changeRowAmount}
                initialPageSize={size}
                showingOfText={RowDisplayLocalization('msg_codes_pagination_showing')(t)}
              />
              {totalElements > size && (
                <Pagination
                  id="voucher-batches-paginator-navigator"
                  totalRecords={totalElements}
                  currentPage={number + 1}
                  pageLimit={size}
                  onPageItemClick={changePage}
                />
              )}
            </div>
          )}
        </>
      </Collapsible>
    </div>
  );
};

VoucherBatchSection.propTypes = {
  canAddBatch: PropTypes.bool,
  disableAddBatchButton: PropTypes.bool,
  onNewBatchClick: PropTypes.func,
  isActionAllowed: PropTypes.func,
  voucherTypeId: PropTypes.string,
  isEditable: PropTypes.bool,
};

VoucherBatchSection.defaultProps = {
  canAddBatch: false,
  disableAddBatchButton: false,
  onNewBatchClick: () => null,
  isActionAllowed: () => null,
  voucherTypeId: '',
  isEditable: false,
};

export default VoucherBatchSection;
