import React from 'react';
import {
  TableCell, TableRow,
  GroupState,
} from '@partner-global-ui/components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './VoucherCoverageTable.scss';
import countries from '../../../constants/countries.constants';

const CoverageRowComponent = ({
  country,
}) => {
  const { t } = useTranslation();
  const {
    code,
  } = country;
  return (
    <TableRow
      key={code}
      id={code}
      hasCheckbox
      checkboxState={GroupState.ALL}
      expandable={false}
      pad
      checkboxProps={{
        disabled: true,
      }}
    >
      <TableCell id="countryName" key={`${code}-countryName`} style={{ textAlign: 'center' }}>
        {t(countries[code].name)}
      </TableCell>
      <TableCell className="fee" id="voucherFee" key="voucherFee">
        {t('msg_codes_codeFee_free')}
      </TableCell>
    </TableRow>
  );
};

CoverageRowComponent.propTypes = {
  country: PropTypes.object,
};

CoverageRowComponent.defaultProps = {
  country: {
    selected: true,
  },
};

export default CoverageRowComponent;
