const countries = {
  PA: { code: 'msg_codes_countryISO_PA', name: 'msg_codes_country_panama' },
  BO: { code: 'msg_codes_countryISO_BO', name: 'msg_codes_country_bolivia' },
  UY: { code: 'msg_codes_countryISO_UY', name: 'msg_codes_country_uruguay' },
  NI: { code: 'msg_codes_countryISO_NI', name: 'msg_codes_country_nicaragua' },
  HN: { code: 'msg_codes_countryISO_HN', name: 'msg_codes_country_honduras' },
  PY: { code: 'msg_codes_countryISO_PY', name: 'msg_codes_country_paraguay' },
  GT: { code: 'msg_codes_countryISO_GT', name: 'msg_codes_country_guatemala' },
  EC: { code: 'msg_codes_countryISO_EC', name: 'msg_codes_country_ecuador' },
  CN: { code: 'msg_codes_countryISO_CN', name: 'msg_codes_country_china' },
  CR: { code: 'msg_codes_countryISO_CR', name: 'msg_codes_country_costaRica' },
  SV: { code: 'msg_codes_countryISO_SV', name: 'msg_codes_country_elSalvador' },
  UA: { code: 'msg_codes_countryISO_UA', name: 'msg_codes_country_ukraine' },
  NL: { code: 'msg_codes_countryISO_NL', name: 'msg_codes_country_netherlands' },
  RU: { code: 'msg_codes_countryISO_RU', name: 'msg_codes_country_russia' },
  PT: { code: 'msg_codes_countryISO_PT', name: 'msg_codes_country_portugal' },
  IE: { code: 'msg_codes_countryISO_IE', name: 'msg_codes_country_ireland' },
  BE: { code: 'msg_codes_countryISO_BE', name: 'msg_codes_country_belgium' },
  FI: { code: 'msg_codes_countryISO_FI', name: 'msg_codes_country_finland' },
  NO: { code: 'msg_codes_countryISO_NO', name: 'msg_codes_country_norway' },
  SE: { code: 'msg_codes_countryISO_SE', name: 'msg_codes_country_sweden' },
  NZ: { code: 'msg_codes_countryISO_NZ', name: 'msg_codes_country_newZealand' },
  ZA: { code: 'msg_codes_countryISO_ZA', name: 'msg_codes_country_southAfrica' },
  PL: { code: 'msg_codes_countryISO_PL', name: 'msg_codes_country_poland' },
  AT: { code: 'msg_codes_countryISO_AT', name: 'msg_codes_country_austria' },
  CH: { code: 'msg_codes_countryISO_CH', name: 'msg_codes_country_switzerland' },
  IL: { code: 'msg_codes_countryISO_IL', name: 'msg_codes_country_israel' },
  OM: { code: 'msg_codes_countryISO_OM', name: 'msg_codes_country_oman' },
  SA: { code: 'msg_codes_countryISO_SA', name: 'msg_codes_country_saudiArabia' },
  PE: { code: 'msg_codes_countryISO_PE', name: 'msg_codes_country_peru' },
  DE: { code: 'msg_codes_countryISO_DE', name: 'msg_codes_country_germany' },
  AU: { code: 'msg_codes_countryISO_AU', name: 'msg_codes_country_australia' },
  IS: { code: 'msg_codes_countryISO_IS', name: 'msg_codes_country_iceland' },
  CZ: { code: 'msg_codes_countryISO_CZ', name: 'msg_codes_country_czechRepublic' },
  MT: { code: 'msg_codes_countryISO_MT', name: 'msg_codes_country_malta' },
  HU: { code: 'msg_codes_countryISO_HU', name: 'msg_codes_country_hungary' },
  LU: { code: 'msg_codes_countryISO_LU', name: 'msg_codes_country_luxembourg' },
  CA: { code: 'msg_codes_countryISO_CA', name: 'msg_codes_country_canada' },
  ID: { code: 'msg_codes_countryISO_ID', name: 'msg_codes_country_indonesia' },
  TH: { code: 'msg_codes_countryISO_TH', name: 'msg_codes_country_thailand' },
  FR: { code: 'msg_codes_countryISO_FR', name: 'msg_codes_country_france' },
  AE: { code: 'msg_codes_countryISO_AE', name: 'msg_codes_country_UAE' },
  BG: { code: 'msg_codes_countryISO_BG', name: 'msg_codes_country_bulgaria' },
  QA: { code: 'msg_codes_countryISO_QA', name: 'msg_codes_country_qatar' },
  GR: { code: 'msg_codes_countryISO_GR', name: 'msg_codes_country_greece' },
  HR: { code: 'msg_codes_countryISO_HR', name: 'msg_codes_country_croatia' },
  TR: { code: 'msg_codes_countryISO_TR', name: 'msg_codes_country_turkey' },
  LB: { code: 'msg_codes_countryISO_LB', name: 'msg_codes_country_lebanon' },
  BH: { code: 'msg_codes_countryISO_BH', name: 'msg_codes_country_bahrain' },
  RO: { code: 'msg_codes_countryISO_RO', name: 'msg_codes_country_romania' },
  KW: { code: 'msg_codes_countryISO_KW', name: 'msg_codes_country_kuwait' },
  CY: { code: 'msg_codes_countryISO_CY', name: 'msg_codes_country_cyprus' },
  SK: { code: 'msg_codes_countryISO_SK', name: 'msg_codes_country_slovakia' },
  SI: { code: 'msg_codes_countryISO_SI', name: 'msg_codes_country_slovenia' },
  BR: { code: 'msg_codes_countryISO_BR', name: 'msg_codes_country_brazil' },
  CL: { code: 'msg_codes_countryISO_CL', name: 'msg_codes_country_chile' },
  CO: { code: 'msg_codes_countryISO_CO', name: 'msg_codes_country_columbia' },
  MY: { code: 'msg_codes_countryISO_MY', name: 'msg_codes_country_malaysia' },
  US: { code: 'msg_codes_countryISO_US', name: 'msg_codes_country_unitedStates' },
  DK: { code: 'msg_codes_countryISO_DK', name: 'msg_codes_country_denmark' },
  GB: { code: 'msg_codes_countryISO_GB', name: 'msg_codes_country_unitedKingdom' },
  HK: { code: 'msg_codes_countryISO_HK', name: 'msg_codes_country_hongKong' },
  IT: { code: 'msg_codes_countryISO_IT', name: 'msg_codes_country_italy' },
  SG: { code: 'msg_codes_countryISO_SG', name: 'msg_codes_country_singapore' },
  AR: { code: 'msg_codes_countryISO_AR', name: 'msg_codes_country_argentina' },
  ES: { code: 'msg_codes_countryISO_ES', name: 'msg_codes_country_spain' },
  IN: { code: 'msg_codes_countryISO_IN', name: 'msg_codes_country_india' },
  TW: { code: 'msg_codes_countryISO_TW', name: 'msg_codes_country_taiwan' },
  JP: { code: 'msg_codes_countryISO_JP', name: 'msg_codes_country_japan' },
  KR: { code: 'msg_codes_countryISO_KR', name: 'msg_codes_country_korea' },
  MX: { code: 'msg_codes_countryISO_MX', name: 'msg_codes_country_mexico' },
  EE: { code: 'msg_codes_countryISO_EE', name: 'msg_codes_country_estonia' },
  IM: { code: 'msg_codes_countryISO_IM', name: 'msg_codes_country_isleOfMan' },
  LT: { code: 'msg_codes_countryISO_LT', name: 'msg_codes_country_lithuania' },
  LV: { code: 'msg_codes_countryISO_LV', name: 'msg_codes_country_latvia' },
  MC: { code: 'msg_codes_countryISO_MC', name: 'msg_codes_country_monaco' },
};

export default countries;
