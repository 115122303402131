/* eslint-disable import/prefer-default-export */
import { isEmpty } from 'lodash';

export const filterCounter = (filters) => {
  let count = 0;
  Object.values(filters).forEach((x) => {
    if (x.length || !isEmpty(x)) {
      count += 1;
    }
  });
  return count;
};
