import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation, Trans } from 'react-i18next';
import {
  TableRow,
  TableCell,
  Tooltip,
  Select,
  Icon,
  iconSizes,
  NotificationContext,
  ModalContext,
  Modal,
  MODAL_ACTIONS,
  Button,
  Loader,
  Truncate,
} from '@partner-global-ui/components';

import { SetVisibilityOfFile, downloadDocumentFile, removeOrderFile } from '../../../../actions/orderActions';
import * as scanStatuses from '../../../../constants/scanStatus.constants';
import { usePermission } from '../../../../utils/accessControl/hasPermission';
import roleKeys from '../../../../utils/accessControl/roleKeys';

const OrderDocumentLine = ({
  orderNumber,
  orderStatus,
  orderDocument: {
    attachmentId,
    creationDate,
    originalFileName,
    fileHash,
    shareWithPartner,
    uploaderName,
    uploadedByPartner,
    scanStatus,
  },
  canShareWithPartner,
  rowKey,
  anchorRef,
}) => {
  // Setup
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const canViewRemoveTooltip = usePermission(
    roleKeys.orderDocuments.viewRemoveTooltip,
    orderStatus,
    uploadedByPartner,
  );
  const canViewFileHash = usePermission(roleKeys.orderDocuments.viewFileHash);
  const canDownload = usePermission(roleKeys.orderDocuments.canDownload, shareWithPartner);
  const isVirusScanPending = scanStatus === scanStatuses.PENDING;
  const isVirusScanFailed = scanStatus === scanStatuses.FAILED;
  const canViewAndDownload = (canViewFileHash || canDownload) && !isVirusScanFailed;
  const shareWithPartnerOptions = [
    { label: 'No', value: false },
    { label: 'Yes', value: true },
  ];
  const modalContext = useContext(ModalContext);
  const notificationCont = useContext(NotificationContext);

  const setSelection = (e) => {
    if (!_.isString(e.target.value)) {
      const { value } = e.target.value;
      SetVisibilityOfFile(orderNumber, attachmentId, value)(dispatch, notificationCont);
    }
  };

  const downloadFile = () => {
    downloadDocumentFile(orderNumber, attachmentId);
  };

  // remove file & modal
  const onSubmitClick = () => {
    removeOrderFile(
      dispatch,
      notificationCont,
      orderNumber,
      attachmentId,
    );
    modalContext.dispatch({ type: MODAL_ACTIONS.HIDE });
  };

  const onModalCancel = () => {
    modalContext.dispatch({ type: MODAL_ACTIONS.HIDE });
  };


  const renderContent = (() => {
    return (
      <>
        <p id="modal-body" data-testid="modal-body" className="custom-modal-body">
          {t('msg_codes_modal_removeFile_body')}
        </p>
        <div id="modal-footer" data-testid="modal-footer" className="custom-modal-footer">
          <span className="buttons-toolbar">
            <Button
              id="modal-cancel-button"
              type="button"
              link
              onClick={() => onModalCancel()}
            >
              {t('msg_codes_cta_cancel')}
            </Button>
            <Button
              id="modal-yes-button"
              primary
              type="submit"
              onClick={() => onSubmitClick()}
            >
              {t('msg_codes_common_yes')}
            </Button>
          </span>
        </div>
      </>
    );
  });

  const modalProps = {
    width: 900,
    hideCancelButton: true,
    onPrimary: onSubmitClick,
    title: t('msg_codes_modal_removeFile_header'),
    content: renderContent(),
  };

  const removeFile = () => {
    modalContext.dispatch({
      type: MODAL_ACTIONS.SHOW,
      payload: <Modal {...modalProps} />,
    });
  };

  const renderVirusScanStatus = (() => {
    const message = isVirusScanPending
      ? (
        <div className="virus-scan-status">
          <Loader size="sm" />
          <span className="message">
            <Trans
              i18nKey="msg_codes_virus_scan_progress_filename"
              components={[
                <Truncate
                  id="file-name-truncate"
                  tooltipContent={originalFileName}
                  height={20}
                >
                  {{ fileName: originalFileName }}
                </Truncate>,
              ]}
            />
          </span>
        </div>
      ) : isVirusScanFailed
        ? (
          <span id="scan-failed" className="scan-failed">
            <Tooltip
              id={`virus-scan-status-tooltip-${rowKey}`}
              position="top"
              content={t('msg_codes_orderDocuments_virusScan_failed_error_tooltip')}
              strategy="fixed"
            >
              <Icon id="scan-failed-icon" size={12}>ico-alert</Icon>
            </Tooltip>
          </span>
        )
        : null;

    return message;
  });


  const renderFileName = (() => {
    const fileName = canViewAndDownload
      ? (
        <Tooltip
          id={`file-hash-tooltip-${rowKey}`}
          position="top"
          content={t('msg_codes_fileHash_tooltip', { hashNumber: fileHash })}
          strategy="fixed"
        >
          <span
            role="link"
            tabIndex={0}
            onClick={() => downloadFile()}
            onKeyDown={() => downloadFile()}
            className="fileName-text"
            data-testid="file-name"
          >
            {originalFileName}
          </span>
        </Tooltip>
      )
      : (
        <span className={isVirusScanFailed && 'file-scan-failed'}>
          <Tooltip
            id={`file-hash-tooltip-${rowKey}`}
            position="top"
            content={t('msg_codes_viewFile_tooltip')}
            strategy="fixed"
          >
            <span className="fileName-text">{originalFileName}</span>
          </Tooltip>
        </span>
      );

    return (
      <>
        <div className="scan-status">
          {renderVirusScanStatus()}
        </div>
        { !isVirusScanPending
          ? fileName
          : null
        }
      </>
    );
  });


  return (
    <TableRow id={`order-document-line-id-${rowKey}`} key={`order-document-line-key-${rowKey}`}>
      <TableCell id={`creation-date-${rowKey}`} className="creationDate">
        {moment(creationDate).utc().format('hh:mm a MM/DD/YYYY')}
      </TableCell>
      <TableCell id={`file-name-${rowKey}`} className="fileName">
        <div className="fileName-content">
          {renderFileName()}
        </div>
      </TableCell>
      <TableCell id={`share-with-partner-${rowKey}`} className="shareWithPartner">
        {canShareWithPartner && !uploadedByPartner && (
          <Select
            name="shareWithPartner"
            id="shareWithPartner"
            value={_.find(shareWithPartnerOptions, { value: shareWithPartner })}
            options={shareWithPartnerOptions}
            size="sm"
            onChange={e => setSelection(e)}
            placeholder={t('msg_codes_common_select')}
            anchor={anchorRef}
          />
        )}
      </TableCell>
      <TableCell id={`uploaded-by-${rowKey}`} className="uploadedBy">
        {uploaderName}
      </TableCell>
      <TableCell id={`remove-${rowKey}`} className="remove-cell">
        {canViewRemoveTooltip
          ? (
            <Tooltip
              id={`file-hash-tooltip-${rowKey}`}
              position="top"
              content={t('msg_codes_removeFile_tooltip')}
              strategy="fixed"
            >
              <Icon className="remove-icon disabled" size={iconSizes.Large}>ico-trashcan</Icon>
              <span className="remove-text-disabled">Remove</span>
            </Tooltip>
          )
          : (
            <>
              <button id="remove-wrap" data-testid="remove-wrap" type="button" className="remove-icon" onClick={() => removeFile()}>
                <Icon id="trash-icon" className="remove-icon" size={iconSizes.Large}>ico-trashcan</Icon>
                <span id="remove-text" data-testid="remove-text" className="remove-text">{t('msg_codes_cta_remove')}</span>
              </button>
            </>
          )
        }
      </TableCell>
    </TableRow>
  );
};

OrderDocumentLine.propTypes = {
  orderNumber: PropTypes.number,
  orderDocument: PropTypes.object,
  canShareWithPartner: PropTypes.bool,
  rowKey: PropTypes.number,
  orderStatus: PropTypes.string,
  anchorRef: PropTypes.object,
};

OrderDocumentLine.defaultProps = {
  orderNumber: 0,
  orderDocument: {},
  canShareWithPartner: false,
  rowKey: 0,
  orderStatus: 'DRAFT',
  anchorRef: {},
};

export default OrderDocumentLine;
