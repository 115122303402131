import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  DataTable,
  TableHeader,
  TableHeaderCell,
} from '@partner-global-ui/components';
import OrderLine from './OrderLine';

const AgencyOrder = ({
  order,
  isApproved,
}) => {
  const { t } = useTranslation();
  const tableRef = useRef();
  const columnTemplate = isApproved(order)
    ? '20% 10% 8% 8% 13% 15% 12% 14%'
    : '20% 20% 20% 20% 20%';

  return (
    <DataTable
      key="order-list-sub-table"
      id="sub-table"
      subtable
      className="order-list-sub-table"
      columnTemplate={columnTemplate}
      ref={tableRef}
    >
      <TableHeader id="sub-table-header-row">
        <TableHeaderCell id="sub-table-header-name">{t('msg_codes_codes_productName')}</TableHeaderCell>
        <TableHeaderCell id="sub-table-header-type">{t('msg_codes_codes_productType')}</TableHeaderCell>
        <TableHeaderCell id="sub-table-header-country">{t('msg_codes_orderList_countryHeader')}</TableHeaderCell>
        <TableHeaderCell id="sub-table-header-quantity">{t('msg_codes_codes_quantity')}</TableHeaderCell>
        <TableHeaderCell id="sub-table-header-batchId">{t('msg_codes_codes_batchID')}</TableHeaderCell>
        {isApproved(order)
          ? <TableHeaderCell id="sub-table-header-fulfilment">{t('msg_codes_orderDetailsAdmin_fulfillment_label')}</TableHeaderCell>
          : <></>
        }
        {isApproved(order)
          ? <TableHeaderCell id="sub-table-header-delivery">{t('msg_codes_orderDetails_delivery')}</TableHeaderCell>
          : <></>
        }
        {isApproved(order)
          ? <TableHeaderCell id="sub-table-header-activation">{t('msg_codes_orderDetailsAdmin_Activation_label')}</TableHeaderCell>
          : <></>
        }
      </TableHeader>
      {order.orderLines.map(line => (
        <OrderLine
          key={line.orderLineId}
          line={line}
          order={order}
          name="orderLineContent"
          t={t}
          tableRef={tableRef}
        />
      ))}
    </DataTable>
  );
};

AgencyOrder.propTypes = {
  order: PropTypes.object,
  isApproved: PropTypes.func,
};

AgencyOrder.defaultProps = {
  order: {},
  isApproved: () => { },
};

export default AgencyOrder;
