/**
 * @param {boolean} isUnsaved
 */
export default function handleUnsavedChanges(isUnsaved, unloadMethod = () => {}) {
  // display prompt and prevent refresh and closing if cancelled
  if (isUnsaved) {
    window.onbeforeunload = () => {
      return true;
    };
  } else {
    window.onbeforeunload = () => { unloadMethod(); };
  }
}

export function cleanupHandleUnsavedChanges() {
  window.onbeforeunload = null;
}
