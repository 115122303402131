import React from 'react';
import PropTypes from 'prop-types';
import {
  TableRow,
  TableCell,
  Tooltip,
  Icon,
} from '@partner-global-ui/components';
import orderLineErrors from '../../../constants/orderLineErrors.constants';
import autoCancelReasons from '../../../constants/autocanceledReasons.constants';
import countriesGensenStrings from '../../../constants/countries.constants';

import {
  getErrorTooltip,
  getFulfillmentErrorTooltip,
  getRemovedTooltip,
  getLineClasses,
  getStatusTranslationId,
} from '../OrdersUtils';

const OrderLine = ({
  line, order, canViewError, t, tableRef,
}) => {
  // need below check to not show new lines in case of leaving unsaved order
  if (line.isNew) {
    return null;
  }

  // Fetch variables from props
  const {
    voucherCatalog: {
      voucherSku,
      countryAvailabilities = [],
    },
    activationStatus,
    autoCanceled,
    autoCancelReason,
    deletedOrderLineId,
    deliveryStatus,
    exportErrorMessage,
    fulfillmentStatus,
    orderLineId,
    quantity,
    voucherStatus,
    npVoucherBatchId,
    useCount,
  } = line;
  const {
    orderStatus,
  } = order;

  // Set up
  const tooltipMessage = autoCancelReason === 'AGREEMENT_STATUS_CHANGE'
    ? 'Order cancelled.'
    : autoCancelReasons[autoCancelReason];
  const isRemoved = typeof deletedOrderLineId === 'number';

  const showRemoveTooltip = activationStatus !== null
    && typeof activationStatus !== 'undefined'
    && activationStatus !== 'Inactive'
    && activationStatus !== 'Activated';
  const fulfillmentErrorCode = exportErrorMessage ? exportErrorMessage.substring(0, 4) : '';
  const isFulfillmentError = (fulfillmentStatus === 'Fulfillment Error' || activationStatus === 'Activation Error')
    && orderLineErrors.some(errors => errors.code === fulfillmentErrorCode);
  const lineIsApproved = orderStatus === 'APPROVED_ORDER';
  const isCancelled = order
    && typeof orderStatus !== 'undefined'
    && !isRemoved
    && (
      orderStatus === 'CANCELLED_ORDER'
      || voucherStatus === 'CANCELLED'
      || autoCanceled
    );

  // Functions
  const countriesDisplay = () => {
    if (countryAvailabilities.length === 0) {
      return '0 Countries';
    }

    const countryList = countryAvailabilities.reduce((text, country, index) => {
      const name = countriesGensenStrings[country.code]
        ? t(countriesGensenStrings[country.code].name)
        : country.name;
      return `${text} ${name}${countryAvailabilities.length !== (index + 1) ? ', ' : ' '}`;
    }, '');

    if (countryAvailabilities.length === 1) {
      return countryList;
    }

    return (
      <>
        <span>{countryAvailabilities.length} Countries </span>
        <span className="order-list-order-countries-tooltip">
          {countryAvailabilities.length > 0 && (
            <Tooltip
              id={`countries-${orderLineId}`}
              content={countryList}
              position="top"
              strategy="fixed"
              anchor={tableRef}
            >
              <Icon size={16} className="countries-list" id="countries-th-list">view_list</Icon>
            </Tooltip>
          )}
        </span>
      </>
    );
  };

  // Render
  return (
    <TableRow
      className={getLineClasses(autoCanceled, activationStatus, isRemoved, isCancelled)}
      id="sub-table-row"
      expanded
    >
      <TableCell name="voucherSku" className={getLineClasses(autoCanceled, activationStatus, isRemoved, isCancelled)} id="voucher-sku">
        {voucherSku === null ? t('msg_codes_codes_status_notAvailable') : voucherSku}
        {' '}
        {(isFulfillmentError && canViewError) && (
          <div data-testid="tooltip-icon-fulfilment" className="order-list-order-name-tooltip">
            <Tooltip
              placement="top"
              strategy="fixed"
              content={getFulfillmentErrorTooltip(orderLineErrors, fulfillmentErrorCode)}
              id={`fulfillmentOverlay-${orderLineId}`}
              anchor={tableRef}
            >
              <Icon size={16} className="fulfilmentErrorWarning" id="fulfillment-warning-sign">warning</Icon>
            </Tooltip>
          </div>
        )}
        {autoCanceled && !isRemoved && (
          <div data-testid="tooltip-icon-autocanceled" className="order-list-order-name-tooltip">
            <Tooltip
              placement="top"
              strategy="fixed"
              content={getErrorTooltip(isCancelled, tooltipMessage)}
              id={`overlay-${orderLineId}`}
              anchor={tableRef}
            >
              <Icon size={16} id="ban-circle">block</Icon>
            </Tooltip>
          </div>
        )}
        {showRemoveTooltip && (
          <div data-testid="tooltip-icon-removed" className="order-list-order-name-tooltip">
            <Tooltip
              placement="top"
              strategy="fixed"
              content={getRemovedTooltip(autoCancelReason, tooltipMessage)}
              id={`removedOverlay-${orderLineId}`}
              anchor={tableRef}
            >
              <Icon size={16} id="removed-warning-sign">warning</Icon>
            </Tooltip>
          </div>
        )}
      </TableCell>
      <TableCell name="type" className={getLineClasses(autoCanceled, activationStatus, isRemoved, isCancelled)} id="type">
        {t('msg_codes_voucherType_product_lowerCase')}
      </TableCell>
      <TableCell name="countries" className={getLineClasses(autoCanceled, activationStatus, isRemoved, isCancelled)} id="countries">
        {countriesDisplay()}
      </TableCell>
      <TableCell name="quantity" className={getLineClasses(autoCanceled, activationStatus, isRemoved, isCancelled)} id="quantity">
        {quantity}
      </TableCell>
      <TableCell name="useCount" className="order-line" id="useCount">
        {useCount}
      </TableCell>
      <TableCell name="batchId" className={getLineClasses(autoCanceled, activationStatus, isRemoved, isCancelled)} id="batch-id">
        {npVoucherBatchId}
      </TableCell>
      {lineIsApproved ? (
        <TableCell name="fulfillmentStatus" className={getLineClasses(autoCanceled, activationStatus, isRemoved, isCancelled)} id="fulfillment">
          {getStatusTranslationId(fulfillmentStatus)}
        </TableCell>
      ) : <></>}
      {lineIsApproved ? (
        <TableCell name="deliveryStatus" className={getLineClasses(autoCanceled, activationStatus, isRemoved, isCancelled)} id="delivery">
          {getStatusTranslationId(deliveryStatus)}
        </TableCell>
      ) : <></>}
      {lineIsApproved ? (
        <TableCell name="activationStatus" className={getLineClasses(autoCanceled, activationStatus, isRemoved, isCancelled)} id="activation">
          {getStatusTranslationId(activationStatus)}
        </TableCell>
      ) : <></>}
    </TableRow>
  );
};

OrderLine.propTypes = {
  line: PropTypes.object,
  order: PropTypes.object,
  canViewError: PropTypes.bool,
  t: PropTypes.func,
  tableRef: PropTypes.object,
};

OrderLine.defaultProps = {
  line: {},
  order: {},
  canViewError: false,
  t: () => { },
  tableRef: { },
};

export default OrderLine;
