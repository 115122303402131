export const orderPurposes = [
  {
    display: 'Internal', code: 'INTERNAL', label: 'Internal', value: 'INTERNAL',
  },
  {
    display: 'Senior Executive Request', code: 'SR_EXEC_REQUEST', label: 'Senior Executive Request', value: 'SR_EXEC_REQUEST',
  },
  {
    display: 'Campaign', code: 'CAMPAIGN', label: 'Campaign', value: 'CAMPAIGN',
  },
  {
    display: 'Marketing Events & Giveaway', code: 'MKTNG_EVENTS_GIVEAWAY', label: 'Marketing Events & Giveaway', value: 'MKTNG_EVENTS_GIVEAWAY',
  },
  {
    display: 'Employee Sales', code: 'EMPLOYEE_SALES', label: 'Employee Sales', value: 'EMPLOYEE_SALES',
  },
  {
    display: 'Business to Business', code: 'B2B', label: 'Business to Business', value: 'B2B',
  },
];

export const membershipDurationTypes = [
  {
    name: 'Months',
    value: 'MONTHS',
    label: 'Months',
  },
  {
    name: 'Days',
    value: 'DAYS',
    label: 'Days',
  },
];

export const rechargeCurrency = [
  'USD',
  'JPY',
  'EUR',
];
