import * as types from './actionTypes';

export const updateCartItemPoNumber = (cartId, poNumber) => {
  return (dispatch, getState) => {
    const { user: { userId } } = getState();
    dispatch({
      type: types.UPDATE_CART_ITEM_PO_NUMBER,
      userId,
      cartId,
      poNumber,
    });
  };
};

export const updateCartItemPoFile = (cartId, fileType, poFile) => {
  return (dispatch, getState) => {
    const { user: { userId } } = getState();
    dispatch({
      type: types.UPDATE_CART_ITEM_PO_FILE,
      userId,
      cartId,
      fileType,
      poFile,
    });
  };
};

export const loadCartFromStorage = (userId) => {
  return (dispatch) => {
    dispatch({
      type: types.LOAD_CART_FROM_LOCAL_STORAGE,
      userId,
    });
  };
};

export const addItemFromCatalog = (productVariant, codeProduct, cartInformation) => {
  return (dispatch, getState) => {
    const { user: { userId } } = getState();
    dispatch({
      type: types.ADD_ITEM_FROM_CATALOG,
      ...cartInformation,
      productVariant,
      codeProduct,
      userId,
    });
  };
};

export const updateCartQuantity = (itemId, quantity) => {
  return (dispatch, getState) => {
    const { user: { userId } } = getState();
    dispatch({
      type: types.UPDATE_CART_ITEM_QUANTITY,
      userId,
      cartId: itemId,
      quantity,
    });
  };
};

export const updateCartComments = (itemId, partnerComments) => {
  return (dispatch, getState) => {
    const { user: { userId } } = getState();
    dispatch({
      type: types.UPDATE_CART_PARTNER_COMMENTS,
      userId,
      cartId: itemId,
      partnerComments,
    });
  };
};

export const updateCartUseCount = (itemId, useCount) => {
  return (dispatch, getState) => {
    const { user: { userId } } = getState();
    dispatch({
      type: types.UPDATE_CART_ITEM_USE_COUNT,
      userId,
      cartId: itemId,
      useCount,
    });
  };
};

export const updateCartStartDate = (itemId, startDate) => {
  return (dispatch, getState) => {
    const { user: { userId } } = getState();
    dispatch({
      type: types.UPDATE_CART_ITEM_START_DATE,
      userId,
      cartId: itemId,
      startDate,
    });
  };
};


export const updateCartStartTime = (itemId, startTime) => {
  return (dispatch, getState) => {
    const { user: { userId } } = getState();
    dispatch({
      type: types.UPDATE_CART_ITEM_START_TIME,
      userId,
      cartId: itemId,
      startTime,
    });
  };
};

export const updateCartEndDate = (itemId, endDate) => {
  return (dispatch, getState) => {
    const { user: { userId } } = getState();
    dispatch({
      type: types.UPDATE_CART_ITEM_END_DATE,
      userId,
      cartId: itemId,
      endDate,
    });
  };
};

export const updateCartEndTime = (itemId, endTime) => {
  return (dispatch, getState) => {
    const { user: { userId } } = getState();
    dispatch({
      type: types.UPDATE_CART_ITEM_END_TIME,
      // type: types.UPDATE_CART_ITEM_START_TIME,
      userId,
      cartId: itemId,
      endTime,
    });
  };
};

export const emptyCart = () => {
  return (dispatch, getState) => {
    const { user: { userId } } = getState();
    dispatch({
      type: types.EMPTY_CART,
      userId,
    });
  };
};

export const removePoFiles = () => {
  return (dispatch, getState) => {
    const { user: { userId } } = getState();
    dispatch({
      type: types.REMOVE_PO_FILES,
      userId,
    });
  };
};

export const removeItemFromCart = (cartId) => {
  return (dispatch, getState) => {
    const { user: { userId } } = getState();
    dispatch({
      type: types.REMOVE_ITEM_FROM_CART,
      userId,
      cartId,
    });
  };
};
