import React, { useCallback } from 'react';
import {
  Container,
  Layout,
  Flex,
  Icon,
  Button,
} from '@partner-global-ui/components';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import SecondaryNav from '../common/SecondaryNav/SecondaryNav';
import getFeature from '../../utils/accessControl/getFeature';
import './orderSuccess.scss';

const OrderSuccessPage = () => {
  const { t } = useTranslation();
  const { cart } = useSelector(state => state.cartPage);
  const helpUrl = getFeature('helpUrl');

  const renderFooterButtons = useCallback(() => {
    return (
      <div className="footer-buttons">
        <span className="product-catalog">
          <Link to="/thirdPartyCatalog">
            <Button secondary>
              {t('msg_codes_codeProdCat_cta')}
            </Button>
          </Link>
        </span>
        <span className="orders">
          <Link to="/orderhistory">
            <Button secondary>
              {t('msg_codes_orders')}
            </Button>
          </Link>
        </span>
      </div>
    );
  });

  return (
    <Container
      className="order-success-page"
      id="order-success-page"
    >
      <Layout>
        <Flex colSpan="4" offset="8">
          <SecondaryNav cartSize={cart.length} />
        </Flex>
      </Layout>
      <Layout>
        <Flex
          className="order-success-page-content-wrapper"
          id="order-success-page-content-wrapper"
        >
          <div
            className="order-success-page-content"
            name="order-success-page-content"
            id="order-success-page-content"
          >
            <Icon className="check_circle-icon" size={48}>check_circle</Icon>
            <div>
              <h3>{t('msg_codes_contracs_thankYouOrder')}</h3>
            </div>
            <br />
            <p>
              {t('msg_codes_orderPlaced_body')}
            </p>
            <br /><br />
            <div className="footer">
              <Trans
                i18nKey="msg_codes_orderPlaced_help"
                components={[<a href={helpUrl} className="tooltip-link" rel="noopener noreferrer" target="_blank">Order Activities and Associated Fee</a>]}
              />
            </div>
            {renderFooterButtons()}
          </div>
        </Flex>
      </Layout>
    </Container>
  );
};

export default OrderSuccessPage;
