import axios from 'axios';
import * as types from './actionTypes';
import * as featureEnvs from '../utils/accessControl/featureEnvs';

export function finishLoadApp(app) {
  return { type: types.FINISH_LOAD_APP, app };
}

export function loadApp() {
  return dispatch => axios.get('/app/info')
    .then((response) => {
      return response.data;
    })
    .then((info) => {
      const { app, app: { env } } = info;
      const { REACT_APP_FEATURE_ENV } = process.env;
      let envConfig = featureEnvs.production;

      if (REACT_APP_FEATURE_ENV) {
        envConfig = featureEnvs[REACT_APP_FEATURE_ENV];
      } else if (window.location.href.includes('localhost')) {
        envConfig = featureEnvs.local;
      } else if (featureEnvs[env.split('-').join('')]) {
        envConfig = featureEnvs[env.split('-').join('')];
      }

      localStorage.setItem('envConfig', JSON.stringify(envConfig));
      dispatch(finishLoadApp(app));
    })
    .catch(
      // TODO: error handling
      // () => dispatch(vouchersHasErrored(true))
    );
}
