import React, {
  useCallback,
  useEffect,
  useState,
  useLayoutEffect,
  useContext,
  useRef,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  NotificationContext,
  Container,
  Collapsible,
  Layout,
  Flex,
  Divider,
  Select,
  RadioGroup,
  Modal,
  ModalContext,
  MODAL_ACTIONS,
  ScrollArea,
} from '@partner-global-ui/components';
import moment from 'moment';
import {
  find,
  uniq,
  isEqual,
  isArray,
  isString,
} from 'lodash';
import Header from './Header';
import ProductDetailForm from './ProductDetailForm';
import * as CodeProductTypes from '../../constants/codeProductTypes.constants';
import * as ContractingSieRegions from '../../constants/contractingSieRegion.constants';
import * as actions from '../../actions/codeProductActions';
import getCountries from '../../actions/countryActions';
import { usePermission } from '../../utils/accessControl/hasPermission';
import getFeature from '../../utils/accessControl/getFeature';
import { autoHide, timeout } from '../../constants/notifications.constant';
import roleKeys from '../../utils/accessControl/roleKeys';
import SkuInfoSection from '../common/SkuInfoSection/SkuInfoSection';
import AddSkusPage from '../common/AddSkuPage/AddSkuPage';
import './codeProduct.scss';

const products = {
  0: { name: 'PS Plus', voucherProductTypeId: 1, code: 'PSPLUS' },
  1: { name: 'PS Now', voucherProductTypeId: 2, code: 'PSNOW' },
};

const regionToNpRegionMap = {
  SIEA: 'SCEA',
  SIEE: 'SCEE',
  SIEJ: 'SCEJ',
  SIEH: 'SCEH',
  SIEK: 'SCEK',
};

const isValidFaceValue = (allowVariableLoad, faceValue, faceValueType) => {
  if (allowVariableLoad) {
    return faceValueType.value === 'STANDARD' ? Number(faceValue) > 0 : Number(faceValue) === 0;
  }
  return Number(faceValue) > 0;
};

export const mapSkuIds = (skus) => {
  return skus.map(sku => sku.skuId);
};

export const mapSkuAvailabilities = (skus) => {
  const countries = skus.reduce((current, sku) => {
    current.push(...sku.skuAvailabilities.map(availability => availability.country.code));
    return current;
  }, []);
  return uniq(countries);
};

export const getOptions = (items) => {
  return Object.keys(items).map((key) => {
    const product = items[key];
    return ({
      value: product.code,
      label: product.name,
    });
  });
};

export default () => {
  const [showAddSkuPage, setShowAddSkuPage] = useState(false);
  const [selectedSkus, setSelectedSkus] = useState([]);

  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams();
  const selectRef = useRef();
  const notificationCont = useContext(NotificationContext);
  const { codeProduct } = useSelector(state => state);
  const {
    codeProductType,
    changesMade,
    dateCreated,
    createdBy,
    endDate,
    subscriptionType,
    region,
    isKorea,
    voucherCatalogId,
    voucherTypeId,
    status,
    country,
    faceValue,
    countries,
    codeProductName,
  } = codeProduct;
  const isNewCodeProduct = !voucherCatalogId;

  const allowVariableLoad = getFeature(roleKeys.codeProduct.allowVariableLoad);

  const permissions = {
    viewDetail: usePermission(roleKeys.codeProduct.viewDetail),
    createDetail: usePermission(roleKeys.codeProduct.createDetail),
    editDetail: usePermission(roleKeys.codeProduct.editDetail),
    viewEndDate: usePermission(roleKeys.codeProduct.viewEndDate),
  };

  const modalContext = useContext(ModalContext);
  const [updatedAvailableCountries, setUpdatedAvailableCountries] = useState();
  const [readyToSave, setReadyToSave] = useState(false);
  const [isProductChanged, setIsProductChanged] = useState(false);
  const [currentProduct, setCurrentProduct] = useState();
  const [isOrphanId, setIsOrphanId] = useState();
  const [isKoreaValue, setIsKoreaValue] = useState();
  const [faceValueType, setFaceValueType] = useState({ value: 'STANDARD' });

  const isMoney = codeProductType === CodeProductTypes.MONEY.code;
  const isSubscription = codeProductType === CodeProductTypes.SUBSCRIPTION.code;
  const isThirdParty = codeProductType === CodeProductTypes.THIRDPARTY.code;
  const isNewProduct = typeof voucherCatalogId === 'undefined';
  const canSeeEndDate = !isNewProduct && endDate && permissions.viewEndDate;
  let productTypeSelectOptions = isThirdParty
    ? Object
      .values(CodeProductTypes)
      .filter(type => type.code === CodeProductTypes.THIRDPARTY.code)
    : Object
      .values(CodeProductTypes)
      .filter(type => type.code !== CodeProductTypes.THIRDPARTY.code);

  productTypeSelectOptions = productTypeSelectOptions.map((type) => {
    return {
      label: t(type.name),
      value: type.code,
    };
  });

  const productTypeClass = (isMoney && '-money')
  || (isSubscription && '-subscription')
  || (isThirdParty && '-third-party');

  const isKoreaOptions = [
    {
      label: t('msg_codes_common_yes'),
      value: 'true',
    },
    {
      label: t('msg_codes_cta_no'),
      value: 'false',
    },
  ];

  /* effects */
  async function getProduct() {
    const product = await dispatch(actions.getProduct(id));
    setCurrentProduct(product);
    await dispatch(getCountries(product.region));

    if (product.skus) {
      setSelectedSkus(product.skus);
      const updatedCountries = mapSkuAvailabilities(product.skus);
      if (!isThirdParty) {
        setUpdatedAvailableCountries(updatedCountries);
      }
    }
    dispatch(await actions.checkIfImpactedOrderLines(id));
  }

  useLayoutEffect(() => {
    dispatch(actions.setApplicableAgreementTypes());
    dispatch(actions.setPartnersForProductTypes());
  }, []);

  useEffect(() => {
    if (isKorea !== null && typeof isKorea !== 'undefined') {
      setIsKoreaValue(isKorea);
    }
  }, [isKorea]);

  const hideModal = () => {
    if (modalContext.dispatch) {
      modalContext.dispatch({
        type: MODAL_ACTIONS.HIDE,
      });
    }
  };

  const createErrorModal = (config) => {
    const errorModalProps = {
      id: 'error-modal',
      name: 'ErrorModal',
      title: t('Error'),
      content: (<></>),
      primaryLabel: t('Ok'),
      onPrimary: hideModal,
      onClose: hideModal,
      hideCancelButton: true,
    };
    let content = (<></>);
    if (codeProductType === CodeProductTypes.MONEY.code) {
      content = (
        <div name="SkuValidationModalMessage">
          This Code Product already exists in the system, please check
          {' '}
          <Link
            data-testid="sku-validation-modal-link"
            className="code-product-link-redirection"
            onClick={hideModal}
            to={`/codeproduct/${config.voucherCatalogId}`}
          >
            {config.voucherCatalogName}
          </Link>
          {' '} to perform updates, or change the Country, Face Value or Region so that it is unique.
        </div>
      );
    } else if (codeProductType === CodeProductTypes.SUBSCRIPTION.code) {
      content = (
        <div name="SkuValidationModalMessage">
          This SKU configuration is already in use by another Code Product
          {' '}
          <Link
            className="code-product-link-redirection"
            data-testid="sku-validation-modal-link"
            onClick={hideModal}
            to={`/codeproduct/${config.voucherCatalogId}`}
          >
            {config.voucherCatalogName}
          </Link>
          {'. '}Please try a different SKU configuration.
        </div>
      );
    }
    return (<Modal id="sku-validation-modal" {...errorModalProps} content={content} />);
  };

  const handleSave = useCallback(async (config) => {
    try {
      const response = await dispatch(actions.saveProduct());
      if (response.duplicate && modalContext.dispatch) {
        modalContext.dispatch({
          type: MODAL_ACTIONS.SHOW,
          payload: createErrorModal(config),
        });
      } else if (!id && response.voucherCatalogId) {
        history.push(`/codeproduct/${response.voucherCatalogId}`);
        notificationCont.dispatch({
          type: 'add',
          payload: {
            status: 'success',
            autoHide,
            timeout,
            message: t('Code Product created successfully, please see Voucher Type ID below.'),
          },
        });
        const product = await dispatch(actions.getProduct(response.voucherCatalogId));
        setCurrentProduct(product);
      } else if (id && response.voucherCatalogId) {
        notificationCont.dispatch({
          type: 'add',
          payload: {
            status: 'success',
            autoHide,
            timeout,
            message: t('Code Product Updated.'),
          },
        });
        const product = await dispatch(actions.getProduct(id));
        setCurrentProduct(product);
      }
    } catch ({ response: { data: { detailMessages: [errorMessage] } } }) {
      notificationCont.dispatch({
        type: 'add',
        payload: {
          status: 'error',
          autoHide,
          timeout,
          message: errorMessage,
        },
      });
    }
  }, [actions.saveProduct, id, dispatch]);

  const hideWarningModalYes = async () => {
    hideModal();
    handleSave();
  };

  const handleCountryUpdateModalClose = async () => {
    hideModal();
    await dispatch(actions.setUpdatedCountries(updatedAvailableCountries));
    await dispatch(actions.saveProduct());
    getProduct();
  };

  const createUpdateModal = () => {
    const countryModalProps = {
      id: 'country-modal',
      name: 'CountryModal',
      title: t('Update'),
      content: createUpdateModal,
      primaryLabel: t('Ok'),
      onPrimary: handleCountryUpdateModalClose,
      onClose: hideModal,
      hideCancelButton: true,
    };

    const content = (
      <div data-testid="CountryUpdateModalMessage">
        The list of countries for this SKU configuration has changed in the source system.
        No action is required by you, but please be aware that orders may be impacted.
        <br /> <br />
        Any line items that are no longer valid based on these changes, will be removed,
        and impacted partners will be notified. Orders will not be cancelled,
        only impacted line items within these orders. Codes from those order lines will
        no longer be redeemable.
      </div>
    );
    return (<Modal id="sku-validation-modal" {...countryModalProps} content={content} />);
  };

  const createWarningModal = (impactedOrderlines) => {
    const warningModalProps = {
      id: 'warning-modal',
      name: 'WarningModal',
      title: t('Warning'),
      primaryLabel: t('OK, Save'),
      secondaryLabel: t('msg_codes_confirmation_leave_cta_no'),
      onPrimary: hideWarningModalYes,
      onClose: hideModal,
      onSecondary: hideModal,
      hideCancelButton: true,
    };

    const scrollAreaHeight = (impactedOrderlines.length * 24) + 24 > 400
      ? 400
      : (impactedOrderlines.length * 24) + 24;

    const content = (
      <div data-testid="WarningModalText">
        <div data-testid="WarningModalIntro">
          <p data-testid="modal-intro-title">Your changes impact line items in the following orders:</p>
        </div>
        <div style={{ height: `${scrollAreaHeight}px` }}>
          <ScrollArea overlay>
            <table data-testid="modal-table" className="modal-table">
              <tbody>
                <tr>
                  <th data-testid="modal-table-header-1" className="tableCell">Order ID</th>
                  <th data-testid="modal-table-header-2" className="tableCell">Order Reference</th>
                  <th data-testid="modal-table-header-3" className="tableCell">Submission Status</th>
                </tr>
                {
                  impactedOrderlines.map((orderLine, index) => {
                    const key = index + 1;
                    return (
                      <tr data-testid="modal-table-row" key={key}>
                        <td data-testid={`modal-table-row-cell-1-${key}`} className="tableCell">{orderLine.orderNumber}</td>
                        <td data-testid={`modal-table-row-cell-2-${key}`} className="tableCell">{orderLine.orderReference}</td>
                        <td data-testid={`modal-table-row-cell-3-${key}`} className="tableCell">{orderLine.orderStatusDescription}</td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          </ScrollArea>
        </div>
        <div data-testid="WarningModalFooterMessage" className="warning-modal-footer">
          <ul style={{ listStyle: 'disc', paddingLeft: '12px' }}>
            <li data-testid="intro-title">
              Any line items that are no longer valid based on these changes, will be removed,
              and impacted partners will be notified.
            </li>
            <li data-testid="paragraph-1">
              Orders will not be cancelled, only impacted line items within these orders.
            </li>
            <li data-testid="paragraph-2">
              Codes from those order lines will no longer be redeemable.
            </li>
          </ul>
          <br />
          Are you sure you want to proceed?
        </div>
      </div>
    );
    return (<Modal id="sku-validation-modal" {...warningModalProps} content={content} />);
  };

  async function checkIfProductIsChanged() {
    if (typeof currentProduct !== 'undefined') {
      let anyFieldChanged = false;

      if (isMoney) {
        anyFieldChanged = (
          codeProduct.status !== currentProduct.status
          || codeProduct.applicableAgreementTypes !== currentProduct.applicableAgreementTypes
          || codeProduct.exclusiveToPartner !== currentProduct.exclusiveToPartner
        );
        setIsProductChanged(anyFieldChanged);
      }

      if (isSubscription) {
        anyFieldChanged = (
          codeProduct.codeProductName !== currentProduct.codeProductName
          || codeProduct.status !== currentProduct.status
          || codeProduct.codeProductDescription !== currentProduct.codeProductDescription
          || codeProduct.skuId !== currentProduct.skuId
          || codeProduct.countries !== currentProduct.countries
          || codeProduct.applicableAgreementTypes !== currentProduct.applicableAgreementTypes
          || codeProduct.exclusiveToPartner !== currentProduct.exclusiveToPartner
        );
        setIsProductChanged(anyFieldChanged);
      }
    }
  }

  const checkIfReadyToSave = useCallback(() => {
    if (isMoney) {
      return isNewProduct
        ? (
          !codeProductType
          || !changesMade
          || status === ''
          || region === ''
          || country === ''
          || !isValidFaceValue(allowVariableLoad, faceValue, faceValueType)
        )
        : (
          !codeProductType
          || !changesMade
          || status === ''
          || region === ''
          || country === ''
          || !isValidFaceValue(allowVariableLoad, faceValue, faceValueType)
          || !isProductChanged
        );
    }

    if (isSubscription) {
      return isNewProduct
        ? (
          !codeProductType
          || !changesMade
          || (typeof subscriptionType === 'undefined' || subscriptionType === '')
          || region === ''
          || (region === ContractingSieRegions.SonyAsia.code && typeof isKorea === 'undefined')
          || selectedSkus.length === 0
          || status === ''
          || countries === ''
          || codeProductName === ''
          || codeProductName === null
        )
        : (
          !codeProductType
          || !changesMade
          || (typeof subscriptionType === 'undefined' || subscriptionType === '')
          || region === ''
          || (region === ContractingSieRegions.SonyAsia.code && typeof isKorea === 'undefined')
          || selectedSkus.length === 0
          || status === ''
          || countries === ''
          || codeProductName === ''
          || codeProductName === null
        ) || !isProductChanged;
    }

    return (!codeProductType || !changesMade);
  }, [faceValue, faceValueType, countries, selectedSkus]);

  useEffect(() => {
    if (id) {
      checkIfProductIsChanged();
    }
  }, [codeProduct, isOrphanId]);

  useEffect(() => {
    const shouldSave = checkIfReadyToSave();
    setReadyToSave(shouldSave);
  }, [codeProduct, isProductChanged, currentProduct, selectedSkus]);

  useEffect(() => {
    if (id) {
      getProduct();
    } else {
      dispatch(actions.clearProduct());
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      if (
        codeProductType === CodeProductTypes.SUBSCRIPTION.code
        && typeof updatedAvailableCountries !== 'undefined'
        && isEqual(codeProduct.skuIds, currentProduct.skuIds)
      ) {
        const areCountriesUpdated = !(
          updatedAvailableCountries.every(ctyCode => codeProduct.countries.includes(ctyCode))
        );

        if (areCountriesUpdated && modalContext.dispatch) {
          modalContext.dispatch({
            type: MODAL_ACTIONS.SHOW,
            payload: createUpdateModal(),
          });
        }
      }
    }
  }, [updatedAvailableCountries]);

  /* methods */
  const clearSku = () => {
    setSelectedSkus([]);
    dispatch(actions.setProductProp('skuId', ''));
    dispatch(actions.setProductProp('codeProductName', ' '));
  };

  const handleChange = useCallback(({ target: { name, value } }) => {
    if (name === 'codeProductType') {
      dispatch(actions.clearProduct());

      if (value === CodeProductTypes.MONEY.code) {
        clearSku();
      }
    }

    if (name === 'region') {
      clearSku();
      if (isSubscription || isThirdParty) {
        dispatch(actions.clearProductDetail());
      }
      if (isSubscription) {
        dispatch(actions.setProductProp('isKorea', undefined));
      }
    }

    if (name === 'country') {
      dispatch(actions.setProductProp(name, value));
    }

    if (name === 'codeProductName' && typeof value === 'string' && value.charAt(0) === ' ') {
      if (!id) {
        dispatch(actions.setProductProp(name, value.trim()));
      }
    } else {
      const newValue = (isString(value) || isArray(value)) ? value : value.value;
      dispatch(actions.setProductProp(name, newValue));
    }
  }, [actions.setProductProp, dispatch, codeProduct.codeProductType, isSubscription, isThirdParty]);

  const handleRadioChange = useCallback((e) => {
    setIsKoreaValue(e.target.value);
    dispatch(actions.setProductProp('isKorea', e.target.value));
  });

  const checkIfImpactedOrderlines = useCallback(async () => {
    const configurationAlreadyExists = await dispatch(actions.checkExistingForConfiguration());

    const isLinkedTypeId = (
      isNewProduct
      || (id && isSubscription && !isEqual(codeProduct.skuIds, currentProduct.skuIds)))
      && configurationAlreadyExists.npVoucherTypeId !== null
      && configurationAlreadyExists.voucherCatalogId !== null;

    const isOrphanTypeId = id
      && isSubscription
      && configurationAlreadyExists.npVoucherTypeId !== null
      && configurationAlreadyExists.voucherCatalogId === null;

    // SKU conflict with orphaned Type ID
    if (isOrphanTypeId) {
      notificationCont.dispatch({
        type: 'add',
        payload: {
          status: 'error',
          autoHide,
          timeout,
          message: t('This code product cannot be tied to your selected SKU, please try another SKU or create a new Code Product with this SKU selection to proceed.'),
        },
      });
      setIsOrphanId(true);
      setReadyToSave(true);
      return;
    }

    // SKU conflict with linked Type ID
    if (isLinkedTypeId && modalContext.dispatch) {
      modalContext.dispatch({
        type: MODAL_ACTIONS.SHOW,
        payload: createErrorModal(configurationAlreadyExists),
      });
      setReadyToSave(true);
      return;
    }

    if (id) {
      const impactedLines = await dispatch(actions.checkIfImpactedOrderLines(id));
      if (typeof impactedLines !== 'undefined' && impactedLines.length > 0 && modalContext.dispatch) {
        modalContext.dispatch({
          type: MODAL_ACTIONS.SHOW,
          payload: createWarningModal(impactedLines),
        });
      } else {
        handleSave(configurationAlreadyExists);
      }
    }
    if (typeof id === 'undefined') {
      handleSave(configurationAlreadyExists);
    }
  }, [codeProduct]);

  const handleShowAddSkuPage = () => {
    setShowAddSkuPage(true);
  };

  const hideAddSkuPage = () => {
    setShowAddSkuPage(false);
  };

  const addSku = useCallback((newSkus) => {
    setSelectedSkus(newSkus);
    if (newSkus) {
      handleChange({
        target: {
          name: 'skuIds',
          value: mapSkuIds(newSkus),
        },
      });
      handleChange({
        target: {
          name: 'countries',
          value: mapSkuAvailabilities(newSkus),
        },
      });
      /**
       * **For New Code Product**
       * If 1 SKU is added, use parent Product Name as the "Code Product Name"
       * If more than one SKU is added, leave the "Code Product Name" empty
       * Use the above logic, every time a user adds or removes individual SKUs or all SKUs
       * **For editing existing code product**
       * use existing name otherwise use sku parent product name unless there is more than one sku
       */
      const newCodeProductName = isNewCodeProduct
        ? newSkus.length > 1 ? '' : newSkus[0].parentProductName
        : codeProductName;
      handleChange({
        target: {
          name: 'codeProductName',
          value: newCodeProductName,
        },
      });
    }
  }, [selectedSkus, codeProductName]);

  const removeSku = (skuId) => {
    const remainingSkus = selectedSkus.filter((sku) => {
      return sku.skuId !== skuId;
    });
    setSelectedSkus(remainingSkus);
    handleChange({
      target: {
        name: 'skuIds',
        value: mapSkuIds(remainingSkus),
      },
    });
    handleChange({
      target: {
        name: 'countries',
        value: mapSkuAvailabilities(remainingSkus),
      },
    });
  };

  const renderNav = () => {
    return (
      <Container id="code-product-header" className="code-product-header">
        <Layout id="code-product-header-layout" className="code-product-header-layout">
          <Header
            id={id}
            name="code-product"
            permissions={permissions}
            readyToSave={readyToSave}
            checkIfImpactedOrderlines={checkIfImpactedOrderlines}
          />
        </Layout>
      </Container>
    );
  };

  function renderVoucherId() {
    return voucherTypeId && (
      <div data-testid="voucher-batch-id" className="voucher-batch-id">
        <div data-testid="voucher-batch-id-label" className="voucher-label">{t('msg_codes_voucher_type_id')}:</div>
        <Link data-testid="voucher-label-link" className="voucher-label-link" to={`/vouchertype/${voucherTypeId}`}>{voucherTypeId}</Link>
      </div>
    );
  }

  const renderCreatedBy = () => {
    return id && t('msg_codes_newOrder_createdBy', {
      name: createdBy,
      date: moment(dateCreated).format('MM/DD/YY'),
      interpolation: {
        escapeValue: false,
      },
    });
  };

  function renderIsKoreaRadio() {
    return (
      <div data-testid="korea-radio-group" className="korea-radio-group">
        <RadioGroup
          name="radioGroup"
          id="radioGroup"
          label={t('Korea')}
          disabled={id}
          options={isKoreaOptions}
          onChange={handleRadioChange}
          value={isKoreaValue !== null && isKoreaValue !== undefined && isKoreaValue.toString()}
          required
        />
      </div>
    );
  }

  return (
    <div ref={selectRef} id="code-product" data-testid="code-product" className="code-product">
      {renderNav()}
      <Container id="code-product-content" className="code-product-content">
        {showAddSkuPage ? (
          <AddSkusPage
            region={isKorea ? 'SCEK' : regionToNpRegionMap[region]}
            onClose={hideAddSkuPage}
            onAddSkus={addSku}
            addedSkus={selectedSkus}
            isCodeProduct
            codeProductType={codeProductType}
          />
        ) : (
          <>
            <div
              id="product-type-section"
              className="product-type-section"
              data-testid="product-type-section"
            >
              <Collapsible
                heading={t('msg_codes_codes_productType')}
                subheading={renderVoucherId()}
                rightContent={renderCreatedBy()}
                id="product-type-section"
                defaultIsOpen
              >
                <Layout
                  className="product-type-section-content"
                  id="product-type-section-content"
                >
                  <Flex
                    className={`product-type${productTypeClass}-left-content`}
                    id={`product-type${productTypeClass}-left-content`}
                    colSpan={5}
                  >
                    {!isThirdParty && (
                      <Flex colSpan={4} className="code-product-type">
                        <Select
                          label={t('msg_codes_orderList_codeProductType')}
                          name="codeProductType"
                          id="codeProductType"
                          required
                          disabled={id}
                          options={productTypeSelectOptions}
                          onChange={handleChange}
                          value={find(productTypeSelectOptions, { value: codeProductType })}
                          optionKey="code"
                          optionSelectValue=""
                          placeholder={t('msg_codes_common_select')}
                        />
                      </Flex>
                    )}
                    {
                    codeProductType && !isMoney && !isNewProduct && region === 'SIEH'
                        && (
                          <Flex
                            className="product-type-section-right-content"
                            id="product-type-section-right-content"
                            colSpan={5}
                          >
                            {renderIsKoreaRadio()}
                          </Flex>
                        )
                    }
                    {isSubscription && (
                      <Flex id="subscription-type" colSpan={4} className={`code-product-type-${codeProductType.toLowerCase()}`}>
                        <Select
                          label={t('msg_codes_subscription_type')}
                          name="subscriptionType"
                          id="subscriptionType"
                          required
                          disabled={id}
                          options={getOptions(products)}
                          onChange={handleChange}
                          value={find(getOptions(products), { value: subscriptionType })}
                          optionKey="code"
                          optionSelectValue=""
                          placeholder={t('msg_codes_common_select')}
                        />
                      </Flex>
                    )}
                    {isThirdParty && (
                      <>
                        <ProductDetailForm
                          codeProduct={codeProduct}
                          handleChange={handleChange}
                          selectRef={selectRef}
                          canSeeEndDate={canSeeEndDate}
                          productTypeSelectOptions={productTypeSelectOptions}
                        />
                      </>
                    )}
                  </Flex>
                  {(isSubscription || isThirdParty) && !isThirdParty && (
                    <Flex id="divider-flex" colSpan={1} offset={1}>
                      <Divider id="divider" secondary vertical />
                    </Flex>
                  )}
                  {
                    (
                      (codeProductType && isSubscription)
                      || (codeProductType && !isMoney && isNewProduct && region === 'SIEH')
                    ) && (
                      <Flex
                        className="product-type-section-right-content"
                        id="product-type-section-right-content"
                        colSpan={5}
                      >
                        {codeProductType && isSubscription && (
                          <Flex
                            id="region-flex"
                            colSpan={4}
                            className={`region-flex${productTypeClass}`}
                          >
                            <Select
                              label={t('msg_codes_common_region')}
                              name="region"
                              id="region"
                              required
                              disabled={id}
                              options={getOptions(ContractingSieRegions)}
                              onChange={handleChange}
                              value={find(getOptions(ContractingSieRegions), { value: region })}
                              optionKey="code"
                              optionSelectValue=""
                              placeholder={t('msg_codes_common_select')}
                            />
                          </Flex>
                        )}
                        {codeProductType
                          && !isMoney
                          && isNewProduct
                          && region === 'SIEH'
                          && renderIsKoreaRadio()
                        }
                      </Flex>
                    )
                  }
                </Layout>
              </Collapsible>
            </div>
            {(isSubscription || isThirdParty) && (
              <SkuInfoSection
                canAddSku={!isThirdParty && (permissions.createDetail || permissions.editDetail)}
                disableAddSkuButton={
                  !codeProductType
                  || !subscriptionType
                  || !region
                  || (isThirdParty && selectedSkus.length >= 1)
                  || (region && region === 'SIEH' && typeof isKorea === 'undefined')
                }
                skus={selectedSkus}
                onDeleteSku={removeSku}
                onNewSkuClick={handleShowAddSkuPage}
                subscriptionType={subscriptionType}
                region={region}
              />
            )}
            {(isMoney || (isSubscription && selectedSkus.length)) && (
              <div
                id="product-detail-section"
                className="product-detail-section"
                data-testid="product-detail-section"
              >
                <Collapsible
                  heading={t('msg_codes_product_details')}
                  id="product-detail"
                  defaultIsOpen
                >
                  <ProductDetailForm
                    codeProduct={codeProduct}
                    handleChange={handleChange}
                    selectRef={selectRef}
                    faceValueType={faceValueType}
                    setFaceValueType={setFaceValueType}
                  />
                </Collapsible>
              </div>
            )}
          </>
        )}
      </Container>
    </div>
  );
};
