import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function appReducer(state = initialState.app, action) {
  switch (action.type) {
    case types.FINISH_LOAD_APP:
      return action.app;
    default:
      return state;
  }
}
