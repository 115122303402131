import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Container, Layout, Flex, Divider,
} from '@partner-global-ui/components';
import * as UserRoles from '../../../constants/userRoles.constants';
import * as UserStatus from '../../../constants/userStatus.constants';
import * as OktaUserStatus from '../../../constants/oktaUserStatus.constants';
import * as UserLanguage from '../../../constants/userLanguage.constants';
import * as userActions from '../../../actions/userFormActionsNew';
import * as partnerAction from '../../../actions/partnerActionsNew';
import initialState from '../../../reducers/initialState';
import getAccountType from '../../../utils/getAccountType';
import { usePermission } from '../../../utils/accessControl/hasPermission';
import roleKeys from '../../../utils/accessControl/roleKeys';
import PgpContainer from '../PgpContainer';
import StaticText from '../../common/StaticText';
import './userInfo.scss';
import { resetPgpKey } from '../../../actions/userActions';

const UserInfo = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();
  const { t } = useTranslation();

  let userPage = useSelector(state => state.userPage);
  const pgpForm = useSelector(state => state.pgpKey);
  const user = useSelector(state => state.user);

  const {
    approvalLevel,
    email,
    firstName,
    lastName,
    localeCode,
    status,
    partner: { name: partnerName },
    roleCode,
  } = userPage.userDetailForm;
  // Set up
  const placeholderPartnerName = 'PLACE_HOLDER_PARTNER_NAME';
  const canSeePartnerName = (
    partnerName !== placeholderPartnerName
    && roleCode === UserRoles.VouchersPartnerRequester.roleCode
  );
  const isDeprovisioned = userPage.externalStatus === OktaUserStatus.DEPROVISIONED;
  const showPgpKeys = usePermission(
    roleKeys.user.canAddPgpKeys,
    user.email,
    email,
  ) && status === 'ACTIVE';
  const removePgpKeys = usePermission(
    roleKeys.user.canRemovePgpKeys,
    user.email,
    email,
  );
  const downloadPgpKeys = usePermission(
    roleKeys.user.canDownloadPgpKeys,
    user.email,
    email,
  );
  const canSeeDetailedModalMessage = usePermission(
    roleKeys.user.canSeeNoPgpKeyModalMessage,
    user.email,
    email,
  );
  const permissions = {
    showValidate: pgpForm.needsValidating,
    showPgpKeys,
    removePgpKeys,
    downloadPgpKeys,
    canSeeDetailedModalMessage,
  };

  useEffect(() => {
    const statusFilter = { value: 'ACTIVE' };
    partnerAction.loadPartners(1000, undefined, undefined, { status: [statusFilter] }, dispatch);
    resetPgpKey(dispatch); // remove pgp error/success message on unmount. Fixes bug PCP-4243.
  }, []);

  // Get user if user id exists in URL params
  useEffect(() => {
    if (id) {
      userActions.getUser(dispatch, id);
    }
  }, [id]);

  // If user is deprovisioned, adjust userPage properties
  const hideFormDetails = isDeprovisioned || userPage.externalStatus === '';
  const deprovisionedUserDetails = {
    ...userPage,
    userDetailForm: {
      ...initialState.userPage.userDetailForm,
      email: userPage.userDetailForm.email,
    },
  };
  userPage = hideFormDetails ? deprovisionedUserDetails : userPage;

  const accountType = getAccountType(email);

  const userRole = UserRoles.getAllRoles().find(r => r.roleCode === roleCode);
  const isSuperAdmin = roleCode === UserRoles.VouchersSieSuperAdmin.roleCode;
  const isAdmin = roleCode === UserRoles.VouchersSieAdmin.roleCode;
  const isApprover = roleCode === UserRoles.VouchersSieApprover.roleCode;
  const userRoleDisplay = userRole ? userRole.name : '';
  const userLevel = approvalLevel !== null && typeof approvalLevel !== 'undefined' && !isSuperAdmin && !isAdmin && isApprover ? `(${t('msg_codes_level_number', { number: approvalLevel })})` : '';

  // Render
  return (
    <Container className="user-info">
      <Layout style={{ margin: '60px 0px' }}>
        <Flex colSpan={12} style={{ display: 'flex', width: '100%' }}>
          <Flex colSpan={5}>
            <StaticText
              label={t('msg_codes_users_email')}
              value={email}
              testId="email"
            />
            <StaticText
              label={t('msg_codes_users_firstName_label')}
              value={firstName}
              testId="first-name"
            />
            <StaticText
              label={t('msg_codes_users_lastName_label')}
              value={lastName}
              testId="last-name"
            />
            <StaticText
              label={t('msg_codes_users_languagePreference_label')}
              value={t(UserLanguage[localeCode])}
              testId="language-preference"
            />
          </Flex>

          <Flex
            colSpan={1}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Divider style={{ border: 'none' }} secondary vertical />
          </Flex>

          <Flex colSpan={6} style={{ paddingLeft: '40px' }}>
            <StaticText
              label={t('msg_codes_users_userType')}
              value={accountType}
              testId="account-type"
            />
            {canSeePartnerName
              && (
                <StaticText
                  label={t('msg_codes_accountType_partner')}
                  value={partnerName}
                  testId="partner-name"
                />
              )
            }
            <StaticText
              label={t('msg_codes_agreements_agreementStatus')}
              value={t(UserStatus[status])}
              testId="status"
            />
            <StaticText
              label={t('msg_codes_users_role')}
              value={`${userRoleDisplay.concat(' ')}${userLevel}`}
              testId="user-role"
            />
          </Flex>
        </Flex>
      </Layout>

      {showPgpKeys && (
        <PgpContainer
          readonly={!location.search.includes('focus=pgpkey')}
          defaultOpen={location.search.includes('focus=pgpkey')}
          permissions={permissions}
        />
      )}
    </Container>
  );
};

export default UserInfo;
