import React from 'react';
import {
  ScrollArea,
} from '@partner-global-ui/components';

const renderBulkWarningModalBody = (batchIds, message, question) => {
  const scrollAreaHeight = 150;
  return (
    <div
      name="WarningModalText"
      className="modal-body-content"
    >
      <div
        name="WarningModalIntro"
        className="warning-modal-intro"
        data-testid="warning-modal-intro"
      >
        {message}
      </div>
      <div style={{ height: `${scrollAreaHeight}px` }}>
        <ScrollArea overlay>
          <table className="batchIds-table">
            <tbody>
              {
                batchIds.map((batchId, index) => {
                  const key = index + 1;
                  return (
                    <tr key={key}>
                      <td className="tableCell">{batchId}</td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </ScrollArea>
      </div>
      <div>
        {question}
      </div>
    </div>
  );
};

export default renderBulkWarningModalBody;
