import React, {
  useCallback, useState, useEffect,
} from 'react';
import {
  Icon,
  TableCell,
  TextInput,
  Tooltip,
  iconSizes,
  Loader,
} from '@partner-global-ui/components';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import fileTypeUtil from 'file-type/browser';
import PropTypes from 'prop-types';

import {
  updateOrderPoNumber,
  updateOrderPoFile,
  downloadPoFile,
} from '../../../actions/orderHistoryActions';
import {
  updateCartItemPoNumber,
  updateCartItemPoFile,
} from '../../../actions/cartProductsActions';
import * as scanStatuses from '../../../constants/scanStatus.constants';
import './PurchaseOrder.scss';


const OrderPO = (props) => {
  //  Setup
  const { t } = useTranslation();
  const {
    order: {
      orderId,
      cartId,
      voucherCatalogId,
      poNumber,
      billable,
      orderStatus,
      poFileHash,
      errorResponse,
      poFileScanStatus,
      poFileOriginalFileName,
    },
    isCartRowView,
    isOrderRowView,
    isCatalogRowView,
    catalogPoNumber,
    setCatalogPoNumber,
    catalogPoFile,
    setCatalogPoFile,
    setPoFileAddedNotSubmitted,
    tableRef,
    name,
  } = props;
  const dispatch = useDispatch();
  const fileDataInitialState = {
    size: 0,
    name: undefined,
    file: undefined,
  };

  const [fileData, setFile] = useState(isCatalogRowView ? catalogPoFile : fileDataInitialState);
  const [fileType, setFileType] = useState();
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [uploadIcon, setUploadIcon] = useState('file_upload');
  const canUploadFile = orderStatus === 'PENDING_DRAFT_AMENDMENT' || typeof orderStatus === 'undefined';
  const uploadTooltipContent = fileData.name ? fileData.name : t('msg_codes_orderlist_uploadpdf_tooltip');
  const { cartPage: { cart } } = useSelector(state => state);
  const isPoStatic = isOrderRowView && orderStatus !== 'PENDING_DRAFT_AMENDMENT';
  const poNumberValue = isCatalogRowView ? catalogPoNumber : poNumber;
  const fileSelectionId = isCartRowView ? cartId : isCatalogRowView ? voucherCatalogId : orderId;
  const currentCartItem = cart
    .filter(product => product.cartId === cartId)[0];

  useEffect(() => {
    if (isCartRowView && currentCartItem && currentCartItem && currentCartItem.poFile) {
      setFile(currentCartItem.poFile);
      setFileType(currentCartItem.poFile.type);
      setPoFileAddedNotSubmitted(true);
    }
  }, []);

  useEffect(() => {
    if (
      (typeof fileType !== 'undefined' && fileType !== 'pdf')
      || (fileType === 'pdf' && (fileData.size < 10240 || fileData.size > 10485760))
    ) {
      setErrorMessage(t('msg_codes_orderlist_pdf_tooltip'));
    } else if (errorResponse) {
      setErrorMessage(t('msg_codes_ordes_replacePO_tooltip'));
    } else {
      setErrorMessage(undefined);
    }

    if (typeof fileType !== 'undefined'
      && fileType === 'pdf'
      && fileData.size >= 10240
      && fileData.size <= 10485760) {
      setUploadIcon('insert_drive_file');

      if (isCartRowView) {
        if (!_.isEqual(currentCartItem.poFile, fileData)) {
          dispatch(updateCartItemPoFile(cartId, fileType, fileData));
          setPoFileAddedNotSubmitted(true);
        }
      }

      if (isOrderRowView) {
        dispatch(updateOrderPoFile(orderId, fileType, fileData));
      }

      if (isCatalogRowView) {
        const updatedFileData = {
          name: fileData.name,
          size: fileData.size,
          type: fileType,
          file: fileData.file,
        };
        setCatalogPoFile(updatedFileData);
      }
    }
  }, [fileType, fileData, errorResponse]);

  const handleDownload = useCallback(() => {
    if (orderId) {
      dispatch(downloadPoFile(orderId));
    }
  });

  const generateFileStats = useCallback((file) => {
    const reader = new FileReader();

    reader.onload = async (loadedEvent) => {
      const arrayBuffer = loadedEvent.target.result;
      const { ext } = await fileTypeUtil.fromBuffer(arrayBuffer);
      setFileType(ext);
    };

    reader.readAsArrayBuffer(file);
  });

  const convertToFileObjectUrl = useCallback((localFile) => {
    const reader = new FileReader();
    reader.readAsDataURL(localFile);
    const file = URL.createObjectURL(localFile);
    reader.onload = async () => {
      const { size } = localFile;
      const { name: fileName } = localFile;

      setFile({
        size,
        name: fileName,
        file,
      });
    };
  });

  const onFileSelect = (event) => {
    if (event.target.files[0]) {
      generateFileStats(event.target.files[0]);
      convertToFileObjectUrl(event.target.files[0]);
    }

    event.target.value = null;
  };

  const handlePOChange = useCallback((e) => {
    const poValue = e.target.value;
    if (isOrderRowView) {
      dispatch(updateOrderPoNumber(orderId, poValue));
    }

    if (isCatalogRowView) {
      setCatalogPoNumber(poValue);
    }

    if (isCartRowView) {
      try {
        dispatch(updateCartItemPoNumber(cartId, poValue));
      } catch (error) {
        console.error(error);
      }
    }
  }, [fileData, fileType]);

  const handleBlur = useCallback((e) => {
    if (e.currentTarget.value === '0') e.currentTarget.value = '1';
  });

  const handleKeypress = useCallback((e) => {
    const characterCode = e.key;
    const alphanumericRegex = /^[0-9a-zA-Z]+$/;
    if (characterCode === 'Backspace') return;

    const characterNumber = characterCode;
    if (characterCode.match(alphanumericRegex)) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      }
      if (characterNumber === 0) {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  });

  const renderPoFileIcon = useCallback(() => {
    // evaluates to being on the order row and having a file uploaded
    if (poFileHash && !isCartRowView) {
      switch (poFileScanStatus) {
        case scanStatuses.PASSED:
          return (
            <div
              role="button"
              tabIndex={0}
              className="file-download-upload-label"
              onClick={handleDownload}
              onKeyDown={handleDownload}
              data-testid={`file-download-upload-button-${name}`}
            >
              <Tooltip
                id={`${name}-poDownload`}
                content={poFileHash}
                position="top"
                shortCopy
                anchor={tableRef}
              >
                <Icon className="file-download-icon" size={20}>file_download</Icon>
              </Tooltip>
            </div>
          );
        case scanStatuses.PENDING:
          return (
            <div className="po-pending" data-testid={`file-pending-${name}`}>
              <Tooltip
                id={`${name}-poDownload`}
                content={t('msg_codes_virusScan_inProgress_scanning_tooltip')}
                position="top"
                shortCopy
              >
                <Loader sm />
              </Tooltip>
            </div>
          );
        case scanStatuses.FAILED:
        default:
          return (
            <div
              className="po-error-icon"
              data-testid={`file-error-${name}`}
            >
              <Tooltip
                id={`${name}-poDownload`}
                content={
                  t(
                    'msg_codes_orderInfo_virusScan_filenameFailed_error_tooltip',
                    {
                      fileName: poFileOriginalFileName,
                      interpolation: { escapeValue: false },
                    },
                  )}
                position="top"
                shortCopy
              >
                <Icon id="error-po" size={iconSizes.Large}>ico-alert</Icon>
              </Tooltip>
            </div>
          );
      }
    }
    return <></>;
  }, [poFileHash, poFileScanStatus, poFileOriginalFileName]);

  return (
    <TableCell className="po-number" id={`po-number-${name}`} key={`${name}-po-number`} sortable>
      <div className="po-number-input-wrapper">
        {!isPoStatic ? (
          <>
            <TextInput
              className={`po-number-input-${name}`}
              id={`po-number-input-${name}`}
              value={poNumberValue}
              hasError={errorMessage}
              onChange={e => handlePOChange(e)}
              onKeyDown={handleKeypress}
              onBlur={handleBlur}
              size="sm"
              charCountMax={100}
              placeholder={t('msg_codes_codeProdCat_typeSomething_dropdown')}
            />
          </>
        ) : (<span id={`po-number-${name}`} className="static-po-number">{poNumber}</span>)}
      </div>
      {errorMessage && (
        <div className="tooltip-wrapper">
          <Tooltip
            id={`${name}-poError`}
            content={errorMessage}
            position="top"
            shortCopy
            anchor={tableRef}
          >
            <Icon className="alert-icon" size={iconSizes.Small}>ico-alert</Icon>
          </Tooltip>
        </div>
      )}
      <div className={canUploadFile ? 'po-upload' : 'po-upload-hidden'}>
        {renderPoFileIcon()}
        {
          canUploadFile && (
            <label htmlFor={`file-input-${fileSelectionId}`}>
              <Tooltip
                id={`${name}-poUpload`}
                content={uploadTooltipContent}
                position="top"
                shortCopy
                anchor={tableRef}
              >
                <Icon className={uploadIcon} size={20} />
              </Tooltip>
              <input
                data-testid={`file-input-selection${name}`}
                id={`file-input-${fileSelectionId}`}
                type="file"
                onChange={onFileSelect}
                required={billable}
                accept="application/pdf"
              />
            </label>
          )
        }
      </div>
    </TableCell>
  );
};

OrderPO.propTypes = {
  name: PropTypes.string,
  order: PropTypes.object,
  isCartRowView: PropTypes.bool,
  isOrderRowView: PropTypes.bool,
  isCatalogRowView: PropTypes.bool,
  catalogPoNumber: PropTypes.string,
  setCatalogPoNumber: PropTypes.func,
  catalogPoFile: PropTypes.object,
  setCatalogPoFile: PropTypes.func,
  setPoFileAddedNotSubmitted: PropTypes.func,
  tableRef: PropTypes.object,
};

OrderPO.defaultProps = {
  name: '',
  order: {
    orderId: '',
    poNumber: '',
  },
  isCartRowView: false,
  isOrderRowView: false,
  isCatalogRowView: false,
  catalogPoNumber: '',
  setCatalogPoNumber: () => { },
  catalogPoFile: { },
  setCatalogPoFile: () => { },
  setPoFileAddedNotSubmitted: () => { },
  tableRef: { },
};

export default OrderPO;
