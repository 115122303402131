import React from 'react';
import {
  Flex,
  Layout,
  Button,
  Search,
} from '@partner-global-ui/components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const AddSkuHeader = ({ ...props }) => {
  const { t } = useTranslation();
  const {
    onClose, onAddSkus, searchSkus, clearSkuSearch,
  } = props;

  const handleAddSkuClick = () => {
    onAddSkus();
    onClose();
  };

  return (
    <Layout id="sku-header-wrapper" className="sku-header-wrapper">
      <Flex id="sku-header" className="sku-header" colSpan={12}>
        <Flex id="sku-header-title" className="sku-header-title" colSpan={2}>
          <h3 data-test-id="sku-header-heading">{t('msg_codes_select_sku')}</h3>
        </Flex>
        <Flex id="sku-header-buttons" className="sku-header-buttons" colSpan={10}>
          <Search
            id="sku-header-search"
            search={searchSkus}
            onClearSearch={clearSkuSearch}
            useClearIcon
            className="sku-search"
            placeholder={t('msg_codes_codeProdCat_typeSomething_dropdown')}
          />
          <Button
            id="cancel-button"
            className="cancel-button"
            onClick={onClose}
          >
            {t('msg_codes_cta_cancel')}
          </Button>
          <Button
            id="add-sku-button"
            className="add-sku-button"
            onClick={handleAddSkuClick}
            primary
          >
            {t('msg_codes_add_skus')}
          </Button>
        </Flex>
      </Flex>
    </Layout>
  );
};

AddSkuHeader.propTypes = {
  onClose: PropTypes.func,
  onAddSkus: PropTypes.func,
  searchSkus: PropTypes.func,
  clearSkuSearch: PropTypes.func,
};

AddSkuHeader.defaultProps = {
  onClose: () => null,
  onAddSkus: () => null,
  searchSkus: () => null,
  clearSkuSearch: () => null,
};

export default AddSkuHeader;
