import * as CallbackGateways from '../../constants/callbackGateways.constants';


export function isAdditionalRedemptionCheckRequired(
  voucherRedemptionCallback, voucherRedemptionCallbackMandatory,
) {
  if (voucherRedemptionCallback === CallbackGateways.NO_GATEWAY) {
    return false;
  }
  if (voucherRedemptionCallbackMandatory) {
    return true;
  }
  return null;
}

function isAdditionalRedemptionCheckRequiredDisabled(
  voucherRedemptionCallback, voucherRedemptionCallbackMandatory,
) {
  return voucherRedemptionCallback === CallbackGateways.NO_GATEWAY
    || voucherRedemptionCallbackMandatory;
}

export default (voucherRedemptionCallback, voucherRedemptionCallbackMandatory) => {
  if (
    typeof voucherRedemptionCallback !== 'string'
    || typeof voucherRedemptionCallbackMandatory !== 'boolean'
  ) {
    return {
      voucherRedemptionCallback: null, disabled: true, hidden: true,
    };
  }
  return {
    voucherRedemptionCallback: isAdditionalRedemptionCheckRequired(
      voucherRedemptionCallback, voucherRedemptionCallbackMandatory,
    ),
    disabled: isAdditionalRedemptionCheckRequiredDisabled(
      voucherRedemptionCallback, voucherRedemptionCallbackMandatory,
    ),
    hidden: false,
  };
};
