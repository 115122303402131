export const DRAFT = 'DRAFT';
export const FINAL = 'FINAL';
export const ACTIVE = 'ACTIVE';
export const INACTIVE = 'INACTIVE';
export const SUSPENDED = 'SUSPENDED';
export const ABANDONED = 'ABANDONED';
export const DELETED = 'DELETED';

export const agreementStatuses = {
  '': [DRAFT, INACTIVE],
  DRAFT: [DRAFT, ACTIVE],
  ACTIVE: [ACTIVE, SUSPENDED, INACTIVE],
  SUSPENDED: [SUSPENDED, ACTIVE, INACTIVE],
  INACTIVE: [INACTIVE],
};

export const thirdPartyAgreementStatuses = {
  '': [DRAFT, INACTIVE],
  DRAFT: [DRAFT, ACTIVE],
  ACTIVE: [ACTIVE, SUSPENDED],
  SUSPENDED: [SUSPENDED, ACTIVE],
};

export const versionStatuses = {
  '': [DRAFT, FINAL],
  DRAFT: [DRAFT, FINAL, ABANDONED],
  ABANDONED: [ABANDONED],
  FINAL: [FINAL],
  DELETED: [DELETED],
};

export const AgreementStatusStringMapping = {
  DRAFT: 'msg_codes_agreementStatus_draft',
  ACTIVE: 'msg_codes_agreementStatus_active',
  SUSPENDED: 'msg_codes_agreementStatus_suspended',
  INACTIVE: 'msg_codes_agreementStatus_inactive',
};

export const VersionStatusStringMapping = {
  DRAFT: 'msg_codes_agreementStatus_draft',
  ABANDONED: 'msg_codes_agreementVersionStatus_abandoned',
  FINAL: 'msg_codes_agreementVersionStatus_final',
};

export const AgreementTagStatuses = {
  ACTIVE: 'valid',
  INACTIVE: 'inactive',
  DRAFT: 'warning',
  SUSPENDED: 'error',
};
