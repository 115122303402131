// will be outdated by refactor
export default [
  {
    id: 'codeProduct',
    value: 'codeProduct',
    stringId: 'msg_codes_codes_codeProduct',
    sortable: true,
  },
  {
    id: 'productId',
    value: 'productId',
    stringId: 'msg_codes_codes_productID',
    sortable: true,
  },
  {
    id: 'productTypeCode',
    value: 'productTypeCode',
    stringId: 'msg_codes_codes_productType',
    sortable: true,
  },
  {
    id: 'platform',
    value: 'platform',
    stringId: 'msg_codes_codes_platform',
    sortable: true,
  },
  {
    id: 'registrationRegion',
    value: 'registrationRegion',
    stringId: 'msg_codes_target_regions',
    sortable: false,
  },
  {
    id: 'estimatedReleaseDate',
    value: 'estimatedReleaseDate',
    stringId: 'msg_codes_orderlist_estReleaseDate_header',
    sortable: true,
  },
];
