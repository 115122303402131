import { Container, Layout } from '@partner-global-ui/components';
import React from 'react';
import './Error.scss';

const NotFound = () => (
  <Container className="error-page">
    <Layout className="error-page-layout">
      <h1>Something went wrong!</h1>
      <h2>If you got here through the Codes website, please retry.</h2>
      <h2>If the problem persists, please use the help link on the Codes home page.</h2>
    </Layout>
  </Container>
);

export default NotFound;
