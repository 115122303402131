import * as types from '../actions/actionTypes';
import initialState from './initialState';

export function removeRegion(regions, region) {
  const index = regions.findIndex(r => r === region);
  regions.splice(index, 1);
  return regions;
}

export default (state = initialState.userPage, action) => {
  const { value, externalUser, gvsUser } = action;
  switch (action.type) {
    case types.BEGIN_LOAD_USER_DETAIL:
      return {
        ...state,
        loadingUser: true,
      };
    case types.SET_EXTERNAL_USER_STATUS:
      return {
        ...state,
        externalStatus: externalUser.status,
      };
    case types.COMPLETE_USER_FOUND:
      return {
        ...state,
        userDetailForm: {
          ...state.userDetailForm,
          ...gvsUser,
          initialStatus: gvsUser.status,
        },
        loadingUser: false,
        userFoundIn: 'all',
      };
    case `${types.REMOVE_PGP_KEY}_FULFILLED`:
      return {
        ...state,
        userDetailForm: {
          ...state.userDetailForm,
          activeFingerprint: undefined,
          hasActiveFingerprint: false,
        },
      };
    case types.RESET_USER_PAGE:
      return {
        ...initialState.userPage,
        userDetailForm: {
          ...initialState.userPage.userDetailForm,
          hasActiveFingerprint: state.userDetailForm.hasActiveFingerprint,
        },
      };
    case types.SET_CHANGES_MADE:
      return { ...state, changesMade: value };
    case `${types.VALIDATE_PGP_KEY}_FULFILLED`:
      return {
        ...state,
        unsaved: true,
      };
    case types.UPDATE_PGP_KEY:
      return {
        ...state,
        unsaved: true,
      };
    case `${types.EDIT_PGP_KEY}_FULFILLED`:
    case `${types.ADD_PGP_KEY}_FULFILLED`:
      return {
        ...state,
        unsaved: false,
      };
    default:
      return state;
  }
};
