import axios from 'axios';
import * as types from './actionTypes';
import { autoHide, timeout } from '../constants/notifications.constant';

export const loadAppConfig = () => {
  const url = 'venom/api/appConfigs';

  return (dispatch) => {
    return axios.get(url)
      .then((response) => {
        return response;
      }).then(({ data }) => {
        dispatch({
          type: types.SET_APP_CONFIG,
          appConfig: data,
        });
      }).catch(() => {
        // TODO: optimize error handling
      });
  };
};

export function toggleMaintenanceMode(id, value, revision, notificationContext) {
  const url = `venom/api/appConfigs/${id}`;
  const eTag = `"${revision}"`;
  const requestBody = {
    value,
  };

  return (dispatch) => {
    return axios.put(url,
      requestBody,
      {
        headers: {
          'if-match': eTag,
        },
      })
      .then((response) => {
        return response;
      }).then(({ data }) => {
        dispatch({
          type: types.SET_MAINTAINANCE_MODE_CONFIG,
          maintainanceModeConfig: data,
        });
      }).catch((error) => {
        notificationContext.dispatch({
          type: 'add',
          payload: {
            status: 'error',
            autoHide,
            timeout,
            message: error.response.detailMessages[0],
            testId: 'error',
          },
        });
      });
  };
}
