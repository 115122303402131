import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  Layout,
  Flex,
  Search,
} from '@partner-global-ui/components';


export default function SearchHeader({
  name, title, subTitle, options, clearIcon, placeholder,
  changeSearch, canSearch,
}) {
  const { t } = useTranslation();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const searchParam = queryParams.toString().split('=');
  const term = searchParam[1];
  const searchDefaultValue = !!term && typeof term === 'string'
    ? decodeURIComponent((term || '')?.replaceAll('+', ' ')) : '';

  const translationOptions = options.reduce(
    (acc, obj) => {
      return [...acc, { value: obj.value, label: t(obj.label) }];
    }, [],
  );

  /* handle string or element subTitles */
  function renderSubtitle() {
    if (typeof subTitle === 'string') {
      return subTitle !== '' && <p data-testid="test-subtitle">{subTitle}</p>;
    }

    return subTitle;
  }

  return (
    <Flex id="header" className="header">
      <Layout id="search-header-container" className="search-header-container">
        <Flex id="search-header-container-flex" colSpan="6">
          <div className="search-header-title-container" data-testid="search-header-container">
            <h2
              id={`${name}-header-search`}
              name={`${name}-header-search`}
              data-testid="test-title"
            >
              {title}
            </h2>
            {renderSubtitle()}
          </div>
        </Flex>
        <Flex id="select-container-flex" colSpan="6">
          <div data-testid="select-container" className="select-container">
            {canSearch && (
              <Search
                id="code-products-search"
                categories={translationOptions}
                useClearIcon={clearIcon}
                placeholder={placeholder}
                search={changeSearch}
                onClearSearch={changeSearch}
                searchDefaultValue={searchDefaultValue}
                hideCategories
              />
            )}
          </div>
        </Flex>
      </Layout>
    </Flex>
  );
}

SearchHeader.propTypes = {
  changeSearch: PropTypes.func,
  title: PropTypes.string,
  name: PropTypes.string,
  subTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  placeholder: PropTypes.string,
  clearIcon: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.exact({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  canSearch: PropTypes.bool,
};

SearchHeader.defaultProps = {
  changeSearch: () => { },
  title: '',
  name: '',
  subTitle: '',
  placeholder: 'Type Something...',
  clearIcon: false,
  options: [
    { label: '', value: '' },
  ],
  canSearch: false,
};
