import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Container,
  DataTable,
  Pagination,
  RowDisplay,
  TableHeader,
  TableHeaderCell,
  NoResults,
} from '@partner-global-ui/components';
import { useTranslation } from 'react-i18next';
import RowDisplayLocalization from '../../../helpers/RowDisplayLocalization';
import './AddVoucherTypeId.scss';
import AddVoucherTypeIdHeader from './AddVoucherTypeIdHeader';
import VoucherType from './VoucherType';
import * as voucherTypeActions from '../../../actions/voucherTypeActions';

const AddVoucherTypeId = ({
  currentVoucherTypeId,
  npType,
  onClose,
  onAddTypeId,
}) => {
  //  Setup
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    user: { userId },
    loadingVoucherTypes,
    voucherTypeCatalogs,
    voucherTypesPage: { search, page: { totalElements } },
  } = useSelector(state => state);

  const [selectedVoucherTypeId, setSelectedVoucherTypeId] = useState('');
  const [sort, setSort] = useState('modifyDate');
  const [sortOrder, setSortOrder] = useState('desc');
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const isProductVoucherType = npType === 'PRODUCT';

  useEffect(() => {
    setSelectedVoucherTypeId(currentVoucherTypeId);
    return () => dispatch(voucherTypeActions.resetVoucherTypesPage());
  }, []);

  useEffect(() => {
    const updatedPageSize = localStorage.getItem(`addVoucherTypeIdPageSize-${userId}`) !== null
      ? Number(localStorage.getItem(`addVoucherTypeIdPageSize-${userId}`))
      : pageSize;

    if (localStorage.getItem(`addVoucherTypeIdPageSize-${userId}`) !== null) {
      setPageSize(updatedPageSize);
    }

    if (npType) {
      voucherTypeActions.loadVoucherTypes(
        updatedPageSize,
        pageNumber,
        { name: sort, order: sortOrder },
        { search, npType },
      )(dispatch);
    }
  }, [pageSize, pageNumber, sort, sortOrder, search, npType]);

  const handlePageClose = () => {
    onClose();
  };

  const addTypeId = () => {
    onAddTypeId(selectedVoucherTypeId);
  };

  const searchVoucherTypes = (searchTerm) => {
    dispatch(voucherTypeActions.setVoucherTypesSearch(searchTerm));
  };

  const clearVoucherTypesSearch = () => {
    dispatch(voucherTypeActions.setVoucherTypesSearch(''));
  };

  const sortVoucherTypes = (name) => {
    const newSortOrder = name === sort ? sortOrder === 'asc' ? 'desc' : 'asc' : 'asc';
    setSort(name);
    setSortOrder(newSortOrder);
  };

  const changePage = (newPageNumber) => {
    setPageNumber(newPageNumber - 1);
  };

  const changeRowAmount = (newSize) => {
    localStorage.setItem(`addVoucherTypeIdPageSize-${userId}`, newSize);
    setPageSize(newSize);
    setPageNumber(0);
  };

  const handleVoucherTypeChange = (id) => {
    setSelectedVoucherTypeId(id);
  };

  // labels for table headers
  const tableHeaders = [
    { label: t('msg_codes_select_voucher_type_id'), value: 'name', sortable: true },
    { label: t('msg_codes_voucher_type_id'), value: 'npVoucherTypeId', sortable: true },
    { label: t('msg_codes_voucherProductType'), value: 'type', sortable: false },
    { label: t('msg_codes_last_updated'), value: 'modifyDate', sortable: true },
    { label: t('msg_codes_NP_region'), value: 'region', sortable: false },
    ...(isProductVoucherType ? [{ label: t('msg_codes_number_skus') }] : []),
  ];

  const renderHeaderCells = () => {
    return tableHeaders.map(({ label, value, sortable }, headerCellIndex) => {
      const headerKey = headerCellIndex + 1;

      return (
        <TableHeaderCell
          id={`typeIds-${headerKey}`}
          key={headerKey}
          value={value}
          className={`type-id-header-${value}`}
          sortable={sortable}
          onClick={sortVoucherTypes}
          sort={{
            sortBy: sort,
            sortDir: sortOrder,
          }}
        >
          {label}
        </TableHeaderCell>
      );
    });
  };

  const renderTableRows = useCallback(() => {
    return voucherTypeCatalogs && voucherTypeCatalogs.length > 0 ? (
      voucherTypeCatalogs.map(voucherType => (
        <VoucherType
          key={voucherType.id}
          voucherType={voucherType}
          isSelected={voucherType.id === selectedVoucherTypeId}
          handleCheckBoxChange={handleVoucherTypeChange}
          isProductVoucherType={isProductVoucherType}
        />
      ))
    ) : (
      <NoResults
        id="no-results"
        title={t('msg_codes_codes_noResultsFound')}
      />
    );
  });

  const renderDataTable = () => {
    const columnTemplate = isProductVoucherType
      ? '30% 14% 12% 16% 16% 12%'
      : '32% 16% 16% 18% 18%';

    return (
      <DataTable
        id="voucher-type-id-list-table"
        columnTemplate={columnTemplate}
        hasCheckbox
        loading={loadingVoucherTypes}
      >
        <TableHeader>
          {renderHeaderCells()}
        </TableHeader>
        {renderTableRows()}
      </DataTable>
    );
  };

  const renderPagination = useCallback(() => {
    return totalElements > 10 && (
      <div data-testid="select-voucher-type-paginator-paginator" className="select-voucher-type-paginator">
        <RowDisplay
          id="select-voucher-type-paginator-selector"
          currentPage={pageNumber + 1}
          totalItems={totalElements}
          pageSizes={[10, 25, 50, 100]}
          onPageSizeChange={changeRowAmount}
          initialPageSize={pageSize}
          showingOfText={RowDisplayLocalization('msg_codes_pagination_showing')(t)}
        />
        {totalElements > pageSize && (
          <Pagination
            id="select-voucher-type-paginator-navigator"
            totalRecords={totalElements}
            currentPage={pageNumber + 1}
            pageLimit={pageSize}
            onPageItemClick={changePage}
          />
        )}
      </div>
    );
  });

  const renderPageHeader = useCallback(() => {
    return (
      <AddVoucherTypeIdHeader
        onClose={handlePageClose}
        onAddTypeId={addTypeId}
        searchVoucherTypes={searchVoucherTypes}
        clearVoucherTypesSearch={clearVoucherTypesSearch}
      />
    );
  });

  return (
    <Container className="voucher-type-id-selector-container">
      {renderPageHeader()}
      {renderDataTable()}
      {renderPagination()}
    </Container>
  );
};

AddVoucherTypeId.propTypes = {
  onClose: PropTypes.func,
  onAddTypeId: PropTypes.func,
  currentVoucherTypeId: PropTypes.string,
  npType: PropTypes.string,
};

AddVoucherTypeId.defaultProps = {
  onClose: () => null,
  onAddTypeId: () => null,
  currentVoucherTypeId: '',
  npType: '',
};

export default AddVoucherTypeId;
