import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Layout,
  Flex,
} from '@partner-global-ui/components';
import logo from '../../assets/psLogo.svg';
import help from '../../assets/help.svg';
import './maintainancePage.scss';

const MaintainancePage = () => {
  const { t } = useTranslation();
  return (
    <Container
      className="maintainance-page"
      id="maintainance-page"
    >
      <Layout>
        <Flex
          className="maintainance-page-content-wrapper"
          id="maintainance-page-content-wrapper"
        >
          <div
            className="maintainance-page-content"
            name="maintainance-page-content"
            id="maintainance-page-content"
          >
            <img alt="logo" className="content-title-icon" src={logo} />
            <div className="content-title">
              <h3>{t('msg_codes_maintenance_title')}</h3>
            </div>
            <div className="maintainance-page-content-body">
              {t('msg_codes_maintenance_body')}
            </div>
            <a className="help-link" href="https://learn.sit.pt.playstation.net/csh?context=3P_Welcome">
              <img alt="help" className="help-icon" src={help} />
              <span className="help-text">{t('msg_codes_error_helpCenter_cta')}</span>
            </a>
          </div>
        </Flex>
      </Layout>
    </Container>
  );
};

export default MaintainancePage;
