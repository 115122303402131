export const VouchersPartnerRequester = {
  name: 'Vouchers-Partner-Requester',
  roleCode: 'PARTNER',
  display: 'Partner',
  translationStringId: 'msg_codes_role_partnerRequester',
};
export const VouchersSieAdmin = {
  name: 'Vouchers-SIE-Admin',
  roleCode: 'ADMIN',
  display: 'Admin',
  translationStringId: 'msg_codes_role_sieAdmin',
};
export const VouchersSieApprover = {
  name: 'Vouchers-SIE-Approver',
  roleCode: 'APPROVER',
  display: 'Approver',
  translationStringId: 'msg_codes_role_sieApprover',
};
export const VouchersSieReviewer = {
  name: 'Vouchers-SIE-Reviewer',
  roleCode: 'REVIEWER',
  display: 'Reviewer',
  translationStringId: 'msg_codes_role_sieReviewer',
};
export const VouchersSieRequester = {
  name: 'Vouchers-SIE-Requester',
  roleCode: 'REQUESTER',
  display: 'Requester',
  translationStringId: 'msg_codes_role_sieRequester',
};
export const VouchersSieSuperAdmin = {
  name: 'Vouchers-SIE-SuperAdmin',
  roleCode: 'SUPER_ADMIN',
  display: 'Super Admin',
  translationStringId: 'msg_codes_role_sieSuperAdmin',
};

export const VouchersSieAgencyAdmin = {
  name: 'Vouchers-SIE-AgencyAdmin',
  roleCode: 'AGENCY_ADMIN',
  display: 'Agency Admin',
  translationStringId: 'api-role-sie-code-vendor-admin',
};

export const VouchersNoRole = {
  name: 'Vouchers-No-Role',
  roleCode: 'NONE',
  display: 'None',
  translationStringId: 'None',
};

export function getAllRoles() {
  return [
    VouchersPartnerRequester,
    VouchersSieAdmin,
    VouchersSieApprover,
    VouchersSieReviewer,
    VouchersSieRequester,
    VouchersSieSuperAdmin,
    VouchersSieAgencyAdmin,
    VouchersNoRole,
  ];
}
