import React from 'react';
import {
  Collapsible, Flex, Layout,
} from '@partner-global-ui/components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import * as agreementTypes from '../../../constants/agreementType.constants';
import './Order.scss';

function OrderInfo({
  order,
}) {
  const { t } = useTranslation();

  const {
    auditFields,
    agreementType,
    agreementId,
  } = order;

  const renderInfoContents = () => {
    return (
      <Layout id="order-info-free-allocation-content" className="order-info-free-allocation-content">
        <Flex id="order-info-content-flex" sm={3}>
          <div data-testid="item-title" className="item-title">
            {t('msg_codes_agreements_agreementType')}
          </div>
          <div data-testid="item-value" className="item-value">
            <Link data-testid="item-link" to={`/agreement/${agreementId}`}>
              {t(agreementTypes[agreementType])}
            </Link>
          </div>
        </Flex>
      </Layout>
    );
  };

  // Render
  return (
    <div data-testid="order-info-section" className="order-info-section">
      <Collapsible
        id="free-allocation-order-details"
        heading={`${t('msg_codes_NewOrder_orderInformation')} ${order.partnerName}`}
        rightContent={
          typeof auditFields !== 'undefined' && (t('msg_codes_newOrder_createdBy', {
            name: auditFields.createdByName,
            date: moment(order.creationDate).format('MM/DD/YY'),
            interpolation: {
              escapeValue: false,
            },
          }))
        }
        defaultIsOpen
      >
        {renderInfoContents()}
      </Collapsible>
    </div>
  );
}

OrderInfo.propTypes = {
  order: PropTypes.object,
};

OrderInfo.defaultProps = {
  order: {},
};

export default OrderInfo;
