import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Layout,
  Flex,
  Button,
  Search,
} from '@partner-global-ui/components';
import PropTypes from 'prop-types';
import * as UserRoles from '../../constants/userRoles.constants';

const Nav = ({
  itemsToAdd, cancel, save, disabled, roles, canSearch, catalogDispatch,
}) => {
  const { VouchersSieReviewer } = UserRoles;
  const search = useSelector(state => state.thirdPartyCatalogPage.search) || '';
  const id = useSelector(state => state.order.orderNumber);
  const { t } = useTranslation();

  const searchCatalog = useCallback((term) => {
    catalogDispatch({ type: 0, search: term });
  }, [search, id]);

  const clearSearch = useCallback(() => {
    catalogDispatch({ type: 0, search: '' });
  }, [search, id]);

  return (
    <Layout name="catalogNav" className="catalog-nav">
      <span className="catalog-nav-content">
        <Flex colSpan={3}>
          <h3 name="catalog-title" className="catalog-title">
            {roles.includes(VouchersSieReviewer.roleCode)
              ? t('msg_codes_cta_view')
              : t('msg_codes_common_select')}
            {' '}
            {t('msg_codes_items')}
          </h3>
        </Flex>
        <Flex offset={3} colSpan={3}>
          {canSearch
            && (
              <Search
                search={searchCatalog}
                onClearSearch={clearSearch}
                id="searchInput"
                width={300}
                useClearIcon
                hideCategories
                placeholder={t('msg_codes_codeProdCat_typeSomething_dropdown')}
              />
            )
          }
        </Flex>
        <Flex colSpan={3}>
          <Button
            type="button"
            secondary
            name="returnToOrderButton"
            id="returnToOrderButton"
            onClick={cancel}
            className="return-nav-button"
          >
            {t('msg_codes_returnToOrder')}
          </Button>
          <Button
            type="button"
            name="addLineItemButton"
            id="addLineItemButton"
            primary
            onClick={save}
            disabled={disabled}
            className={roles.includes(VouchersSieReviewer.roleCode) ? 'hidden' : 'add-nav-button'}
          >
            {t('msg_codes_order_addItem')}
            {itemsToAdd.length > 1 ? 's' : ''}
            {` (${itemsToAdd.length})`}
          </Button>
        </Flex>
      </span>
    </Layout>
  );
};

Nav.propTypes = {
  itemsToAdd: PropTypes.array,
  roles: PropTypes.array,
  cancel: PropTypes.func,
  save: PropTypes.func,
  disabled: PropTypes.bool,
  canSearch: PropTypes.bool,
  catalogDispatch: PropTypes.func,
};

Nav.defaultProps = {
  itemsToAdd: [],
  roles: [],
  cancel: () => { },
  save: () => { },
  disabled: false,
  canSearch: false,
  catalogDispatch: () => {},
};

export default Nav;
