export default [
  {
    id: 'Users',
    value: 'lastName',
    sortable: true,
    translationStringId: 'msg_codes_users_user',
  },
  {
    id: 'Email',
    value: 'email',
    sortable: true,
    translationStringId: 'msg_codes_users_email',
  },
  {
    id: 'Role',
    value: 'role',
    sortable: false,
    translationStringId: 'msg_codes_users_role',
  },
  {
    id: 'Date Added',
    value: 'creationDate',
    sortable: true,
    translationStringId: 'msg_codes_users_dateAdded',
  },
  {
    id: 'PGP Keys',
    value: 'pgpKeys',
    sortable: false,
    translationStringId: 'msg_codes_PGPKey',
  },
  {
    id: 'User Status',
    value: 'status',
    sortable: true,
    translationStringId: 'msg_codes_agreements_agreementStatus',
  },
];
