import React from 'react';
import PropTypes from 'prop-types';
import './spinnerLoader.scss';

const SpinnerLoader = ({ label, labelSize, width }) => {
  const spinnerLabel = (lbl, lblSize) => ((lbl) ? (
    <p
      style={{
        marginTop: '0.5em',
        fontSize: `${lblSize}rem`,
      }}
      data-testid="spinner-label"
    >
      {lbl}
    </p>
  ) : null);

  return (
    <div className="spinner-loader" data-testid="spinner-loader">
      <div>
        <svg viewBox={[0, 0, 42, 42]} width={width}>
          <defs>
            <circle id="path-loader" cx="21" cy="21" r="21" />
          </defs>

          <g transform="translate(21,21)">
            <g className="rotate-spinner">
              <g transform="translate(-21,-21)">
                <mask id="mask-loader" fill="white">
                  <use xlinkHref="#path-loader" />
                </mask>
                <use id="Oval" fill="#C8E7FF" xlinkHref="#path-loader" />
                <path
                  d="M11,39.3280162 L21.2041811,24.3746725 L21.2041811,0.00309054662 C21.3291814,0.00103291293 21.4544475,1.42108547e-14 21.5799711,1.42108547e-14 C33.3678647,1.42108547e-14 43,9.40202025 43,21.0917031 C43,32.5979797 33.3678647,42 21.5799711,42 C17.7304691,42 14.1231226,41.0285465 11,39.3280162 Z"
                  id="Oval"
                  fill="#0072CE"
                  mask="url(#mask-loader)"
                />
                <circle
                  id="Oval"
                  fill="#ffffff"
                  mask="url(#mask-loader)"
                  cx="20.5"
                  cy="20.5"
                  r="16.5"
                />
              </g>
            </g>
          </g>
        </svg>
        {spinnerLabel(label, labelSize)}
      </div>
    </div>
  );
};

SpinnerLoader.propTypes = {
  /**
   * optional string as label under spinning icon.
   */
  label: PropTypes.string,
  /**
   * font size for label in 'rem'
   */
  labelSize: PropTypes.number,
  /**
   * size of svg viewPort unit:'px'
   */
  width: PropTypes.number,
};

// Specifies the default values for props:
SpinnerLoader.defaultProps = {
  label: null,
  labelSize: 1.6,
  width: 42,
};

export default SpinnerLoader;
