import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
  Tooltip,
  Loader,
} from '@partner-global-ui/components';
import { useTranslation } from 'react-i18next';

import { ordersDownloadHistory } from '../../../actions/orderHistoryActions';

const OrderHistoryInfoTooltip = ({ order: { orderId }, tableRef }) => {
  //  Setup
  const { t } = useTranslation();
  const [downloadHistory, setDownloadHistory] = useState({});
  const [loadingDownloadHistory, setLoadingDownloadHistory] = useState(false);
  const noHistoryMessage = t('msg_codes_codes_freeCodes_tooltip_error');

  const renderInfoOverlay = useCallback(() => {
    return (loadingDownloadHistory)
      ? <Loader />
      : downloadHistory.voucherCodeFileName
        ? (
          <span className="info-overlay">
            <div data-testid="order-history-file-name" className="info-overlay-file-name">
              <span>
                <div><b>{t('msg_codes_codes_fileName')}</b></div>
                <div>{downloadHistory.voucherCodeFileName}</div>
              </span>

              <br />

              <div data-testid="order-history-batch-id">
                <div className="column">
                  <div><b>{t('msg_codes_codes_batchID')}</b></div>
                  <div>{downloadHistory.voucherBatchId}</div>
                </div>
              </div>

              <br />

              <div data-testid="order-history-last-download">
                <div className="column">
                  <div><b>{t('msg_codes_orders_lastDownloadDate')}</b></div>
                  <div>{downloadHistory.lastDownloadDate}</div>
                </div>
                <div data-testid="order-history-last-downloadBy" className="column">
                  <div><b>{t('msg_codes_codes_lastDownloadedBy')}</b></div>
                  <div>{downloadHistory.lastDownloadBy}</div>
                </div>
              </div>

              <br />

              <div><b>{t('msg_codes_orders_startendDate_tooltip')}</b></div>
              <div data-testid="order-history-dates">{`${downloadHistory.codeStartDate}-${downloadHistory.codeEndDate}`}</div>
            </div>
          </span>
        )
        : noHistoryMessage;
  });

  const getDownloadHistory = async () => {
    try {
      const history = await ordersDownloadHistory(orderId, setLoadingDownloadHistory);
      if (history) {
        setDownloadHistory(history);
      }
    } catch (error) {
      if (error.response.status === 404) {
        setLoadingDownloadHistory(false);
      }
    }
  };

  return (
    <span id={orderId} data-testid="order-history" className="order-history-info-tooltip" onMouseEnter={getDownloadHistory}>
      <Tooltip
        id="order-history-info-tooltip"
        content={renderInfoOverlay()}
        position="bottom"
        strategy="fixed"
        anchor={tableRef}
      >
        <Icon size={16}>info</Icon>
      </Tooltip>
    </span>
  );
};

OrderHistoryInfoTooltip.propTypes = {
  order: PropTypes.object,
  tableRef: PropTypes.object,
};

OrderHistoryInfoTooltip.defaultProps = {
  order: {},
  tableRef: {},
};

export default OrderHistoryInfoTooltip;
