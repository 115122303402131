import _ from 'lodash';
import * as types from '../actions/actionTypes';
import initialState from './initialState';
import * as agreementTypes from '../constants/agreementType.constants';

export function getFeeStructureId(feeStructure) {
  const { feeStructureId } = feeStructure;
  const idProp = typeof feeStructureId === 'undefined' ? 'uuid' : 'feeStructureId';
  return idProp;
}

export function removeFeeStructureByVoucherProductType(
  state, voucherProductType, id, type = 'feeStructureId', versionHasBeenModified,
) {
  const {
    draftAgreementVersion,
    draftAgreementVersion: { voucherProductTypeCoverageSet },
  } = state;
  const voucherProductTypeCoverageSetPartition = _
    .partition(voucherProductTypeCoverageSet, { voucherProductType });
  const filteredCountries = _
    .reject(voucherProductTypeCoverageSetPartition[0][0].feeStructure, { [type]: id });

  return {
    ...state,
    draftAgreementVersion: {
      ...draftAgreementVersion,
      voucherProductTypeCoverageSet: _.concat(
        [{
          ...voucherProductTypeCoverageSetPartition[0][0],
          feeStructure: filteredCountries,
        }],
        voucherProductTypeCoverageSetPartition[1],
      ),
    },
    hasBeenModified: {
      ...state.hasBeenModified,
      version: versionHasBeenModified,
    },
  };
}

export function addFeeStructureByVoucherProductType(
  state, voucherProductType, feeStructure, versionHasBeenModified,
) {
  const {
    draftAgreementVersion,
    draftAgreementVersion: { voucherProductTypeCoverageSet },
  } = state;

  const voucherProductTypeCoverageSetPartition = _
    .partition(voucherProductTypeCoverageSet, { voucherProductType });

  if (
    state.agreement.agreementType === agreementTypes.AGENCY
    && voucherProductTypeCoverageSetPartition[0][0].feeStructure.find((s) => {
      return s.country === feeStructure.country || s.uuid === feeStructure.uuid;
    })
  ) {
    return state;
  }


  return {
    ...state,
    draftAgreementVersion: {
      ...draftAgreementVersion,
      voucherProductTypeCoverageSet: _.concat(
        [{
          ...voucherProductTypeCoverageSetPartition[0][0],
          feeStructure:
            _.concat(
              voucherProductTypeCoverageSetPartition[0][0].feeStructure,
              { ...feeStructure },
            ),
        },
        ],
        voucherProductTypeCoverageSetPartition[1],
      ),
    },
    hasBeenModified: {
      ...state.hasBeenModified,
      version: versionHasBeenModified,
    },
  };
}

export function updateFeeStructureByVoucherProductType(
  state, voucherProductType, feeStructure, id, type = 'feeStructureId', versionHasBeenModified,
) {
  const {
    draftAgreementVersion,
    draftAgreementVersion: { voucherProductTypeCoverageSet },
  } = state;

  const voucherProductTypeCoverageSetPartition = _
    .partition(voucherProductTypeCoverageSet, { voucherProductType });

  const filteredCountries = _
    .reject(voucherProductTypeCoverageSetPartition[0][0].feeStructure, { [type]: id });

  return {
    ...state,
    draftAgreementVersion: {
      ...draftAgreementVersion,
      voucherProductTypeCoverageSet: _.concat(
        [{
          ...voucherProductTypeCoverageSetPartition[0][0],
          feeStructure: _.concat(filteredCountries, [feeStructure]),
        }],
        voucherProductTypeCoverageSetPartition[1],
      ),
    },
    hasBeenModified: {
      ...state.hasBeenModified,
      version: versionHasBeenModified,
    },
  };
}

export function removeAllAgencyFeeStructures(state, voucherProductType, versionHasBeenModified) {
  const {
    draftAgreementVersion,
    draftAgreementVersion: { voucherProductTypeCoverageSet },
  } = state;

  const voucherProductTypeCoverageSetPartition = _
    .partition(voucherProductTypeCoverageSet, { voucherProductType });

  return {
    ...state,
    draftAgreementVersion: {
      ...draftAgreementVersion,
      voucherProductTypeCoverageSet: _.concat(
        [{
          ...voucherProductTypeCoverageSetPartition[0][0],
          feeStructure: [],
        }],
        voucherProductTypeCoverageSetPartition[1],
      ),
    },
    hasBeenModified: {
      ...state.hasBeenModified,
      version: versionHasBeenModified,
    },
  };
}

export function addEmptyVoucherProductType(state, voucherProduct, versionHasBeenModified) {
  const { voucherProductType } = voucherProduct;
  const { draftAgreementVersion: { voucherProductTypeCoverageSet } } = state;

  let flag = true;
  voucherProductTypeCoverageSet.forEach((set) => {
    if (set.voucherProductType === voucherProductType) {
      flag = false;
    }
  });

  if (flag) {
    return {
      ...state,
      draftAgreementVersion: {
        ...state.draftAgreementVersion,
        voucherProductTypeCoverageSet: [
          ...state.draftAgreementVersion.voucherProductTypeCoverageSet,
          voucherProduct,
        ],
      },
      hasBeenModified: {
        ...state.hasBeenModified,
        version: versionHasBeenModified,
      },
    };
  }

  return state;
}

export function removeVoucherProductType(state, name, versionHasBeenModified) {
  const { voucherProductTypeCoverageSet } = state.draftAgreementVersion;
  const newCoverageSet = voucherProductTypeCoverageSet.filter((set) => {
    return set.voucherProductType !== name;
  });

  return {
    ...state,
    draftAgreementVersion: {
      ...state.draftAgreementVersion,
      voucherProductTypeCoverageSet: newCoverageSet,
    },
    hasBeenModified: {
      ...state.hasBeenModified,
      version: versionHasBeenModified,
    },
  };
}

function handleCallbackMandatory(state, payload) {
  const { voucherRedemptionCallbackMandatory } = state.draftAgreementVersion;

  return (Object.keys(payload)[0] === 'voucherRedemptionCallbackGateway'
  && payload.voucherRedemptionCallbackGateway === 'NO_GATEWAY')
    ? null
    : voucherRedemptionCallbackMandatory;
}

export function handleAgreementStatus(state, payload, agreementStatusEnabled) {
  const {
    isNewAgreement, isNewVersion, agreement, initialStatuses,
  } = state;
  const isFirstVersion = agreement.agreementVersions.length === 1 && !isNewVersion;
  let { status } = agreement;
  if (Object.keys(payload)[0] === 'versionStatus' && isFirstVersion) {
    if (!agreementStatusEnabled && payload.versionStatus === 'FINAL') {
      status = 'ACTIVE';
    } else if (
      (isNewAgreement || initialStatuses.versionStatus === 'DRAFT')
      && payload.versionStatus !== 'FINAL'
      && status === 'ACTIVE'
      && !isNewVersion
    ) {
      status = 'DRAFT';
    }
  }

  return status;
}

function handleSpecialCasesAndUpdate(
  state, payload, agreementHasBeenModified,
  versionHasBeenModified, agreementStatusEnabled,
) {
  return {
    ...state,
    agreement: {
      ...state.agreement,
      status: handleAgreementStatus(state, payload, agreementStatusEnabled),
    },
    draftAgreementVersion: {
      ...state.draftAgreementVersion,
      voucherRedemptionCallbackMandatory: handleCallbackMandatory(state, payload),
      ...payload,
    },
    hasBeenModified: {
      ...state.hasBeenModified,
      agreement: agreementHasBeenModified,
      version: versionHasBeenModified,
    },
  };
}

// eslint-disable-next-line consistent-return
export default function agreementForm(state = initialState.agreementForm, action) {
  const {
    newAgreementForm = {},
    agreementVersion = {},
    agreementVersionId,
    agreement,
    payload,
    feeStructure,
    voucherProductType,
    agreementHasBeenModified,
    versionHasBeenModified,
    latestFinalEffectiveDate,
    canEditVersion,
    initialStatuses,
    eTag,
    orderLines,

  } = action;
  switch (action.type) {
    case types.LOAD_AGREEMENT_SUCCESS:
      if (agreement.agreementId) {
        return {
          ...state,
          agreement,
          loaded: true,
          isNewAgreement: false,
          eTag,
        };
      }
      break;
    case types.SET_AGREEMENT_VERSION:
      return {
        ...state,
        draftAgreementVersion: agreementVersion,
        agreementVersionId,
        canEditVersion,
        hasBeenModified: {
          agreement: false,
          version: false,
        },
        initialStatuses,
      };
    case types.UPDATE_AGREEMENT_PROPERTY:
      return {
        ...state,
        agreement: {
          ...state.agreement,
          [action.name]: action.value,
        },
        hasBeenModified: {
          ...state.hasBeenModified,
          agreement: agreementHasBeenModified,
        },
      };
    case types.NEW_AGREEMENT_SUCCESS:
      return {
        ...initialState.agreementForm,
        agreement,
        isNewAgreement: true,
        latestFinalEffectiveDate,
        hasBeenModified: {
          version: versionHasBeenModified,
          agreement: agreementHasBeenModified,
        },
      };
    case types.RESET_AGREEMENT:
      return {
        ...initialState.agreementForm,
        isNewAgreement: true,
        latestFinalEffectiveDate,
        hasBeenModified: {
          agreement: agreementHasBeenModified,
          version: versionHasBeenModified,
        },
      };
    case types.LOAD_AGREEMENT_VERSION:
      return {
        ...state,
        agreementVersionId,
      };
    case types.UPDATE_AGREEMENT_VERSION_DRAFT:
      return handleSpecialCasesAndUpdate(
        state, payload, agreementHasBeenModified,
        versionHasBeenModified, action.agreementStatusEnabled,
      );
    case types.UPDATE_AGREEMENT_FORM:
      return {
        ...newAgreementForm,
      };
    case types.SELECTED_ORDERLINES_LIST:
      return {
        ...state,
        orderLines,
        removedCountries: true,
      };
    case types.NO_ORDERS_AFFECTED:
      return {
        ...state,
        removedCountries: false,
        orderLines: [],
      };
    case types.ADD_FEE_STRUCTURE:
      return addFeeStructureByVoucherProductType(
        state,
        voucherProductType,
        feeStructure,
        versionHasBeenModified,
      );
    case types.REMOVE_FEE_STRUCTURE:
      return removeFeeStructureByVoucherProductType(
        state,
        voucherProductType,
        feeStructure[getFeeStructureId(feeStructure)],
        getFeeStructureId(feeStructure),
        versionHasBeenModified,
      );
    case types.UPDATE_FEE_STRUCTURE:
      return updateFeeStructureByVoucherProductType(
        state,
        voucherProductType,
        feeStructure,
        feeStructure[getFeeStructureId(feeStructure)],
        getFeeStructureId(feeStructure),
        versionHasBeenModified,
      );
    case types.REMOVE_ALL_FEE_STRUCTURES:
      return removeAllAgencyFeeStructures(state, voucherProductType, versionHasBeenModified);
    case types.RESET_VOUCHER_PRODUCT_TYPE_COVERAGE_SET:
      return {
        ...state,
        draftAgreementVersion: {
          ...state.draftAgreementVersion,
          voucherProductTypeCoverageSet: [],
        },
        hasBeenModified: {
          ...state.hasBeenModified,
          version: versionHasBeenModified,
        },
      };
    case types.ADD_EMPTY_VOUCHER_PRODUCT_TYPE:
      return addEmptyVoucherProductType(state, action.payload, versionHasBeenModified);
    case types.REMOVE_VOUCHER_PRODUCT_TYPE:
      return removeVoucherProductType(state, action.name, versionHasBeenModified);
    case types.SET_CAN_EDIT_VERSION:
      return {
        ...state,
        canEditVersion: action.isEditing,
        isNewVersion: typeof action.isNewVersion === 'boolean'
          ? action.isNewVersion : state.isNewVersion,
        agreement: {
          ...state.agreement,
        },
        draftAgreementVersion: {
          ...state.draftAgreementVersion,
          agreementVersionId: action.isNewVersion ? ''
            : state.draftAgreementVersion.agreementVersionId,
          versionStatus: action.isNewVersion
            ? 'DRAFT' : state.draftAgreementVersion.versionStatus,
        },
        hasBeenModified: {
          ...state.hasBeenModified,
          version: !!action.isNewVersion,
        },
        initialStatuses: action.isEditing && action.isNewVersion
          ? { status: state.agreement.status, versionStatus: 'DRAFT' }
          : { ...state.initialStatuses },
      };
    case types.SET_INITIAL_STATUSES:
      return {
        ...state,
        initialStatuses: {
          status: action.status,
          versionStatus: action.versionStatus,
        },
      };
    case types.AGREEMENT_VERSION_LATEST_FINAL_EFFECTIVE_DATE:
      return {
        ...state,
        latestFinalEffectiveDate,
      };
    case types.RESET_HAS_BEEN_MODIFIED:
      return {
        ...state,
        hasBeenModified: {
          agreement: false,
          version: false,
        },
        isSaving: false,
      };
    case types.PRODUCT_TYPE_HAS_BEEN_ADDED:
      return {
        ...state,
        hasBeenModified: {
          ...state.hasBeenModified,
          voucherProductType: action.productTypeHasBeenAdded,
        },
      };
    case types.BEGIN_AGREEMENT_SAVE:
      return {
        ...state,
        isSaving: true,
      };
    case types.AGREEMENT_SAVE_ERROR:
      return {
        ...state,
        isSaving: false,
      };
    default:
      return state;
  }
}
