
import React, {
  useState, useEffect, useCallback, useReducer,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Container,
  DataTable,
  ActionBar,
  Pagination,
  RowDisplay,
  TableHeader,
  TableHeaderCell,
  NoResults,
} from '@partner-global-ui/components';

import RowDisplayLocalization from '../../helpers/RowDisplayLocalization';
import { filterCounter } from '../../helpers/filterMethods';
import filterOptions from './filterValues';
import * as usersActions from '../../actions/userListActions';
import FiltersContainer from '../common/Filters/FiltersContainer';
import Nav from './Nav';
import User from './User';
import './users.scss';

export const defaultFilters = {
  roleCode: '',
  accountType: '',
  creationDate: [],
  status: '',
};

export const initialState = {
  filters: defaultFilters,
  filtered: false,
  filterCount: 0,
  pageSize: 10,
};

export const componentActions = {
  setFilter: 1,
  clearFilters: 2,
};

export function localStateReducer(
  state,
  {
    type = '',
    name,
    value,
    filters = defaultFilters,
  },
) {
  const filteredState = filters && (
    filters.creationDate.length > 0
    || filters.status.length > 0
    || filters.roleCode.length > 0
    || filters.accountType.length > 0
  );

  let newState;
  switch (type) {
    case componentActions.setFilter:
      newState = {
        ...state,
        filters: { ...state.filters, [name]: value },
        filtered: !filteredState,
      };
      return { ...newState, filterCount: filterCounter(newState.filters) };
    case componentActions.clearFilters:
      return {
        ...state,
        filters: defaultFilters,
        filtered: false,
        filterCount: 0,
      };
    default:
      return state;
  }
}

export const UsersPage = ({ ...props }) => {
  // Setup
  const { history } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const updatedInitialState = initialState;
  // Setup useReducers
  const [localState, localDispatch] = useReducer(localStateReducer, updatedInitialState);
  // Setup UseSelectors
  const { userId } = useSelector(state => state.user);
  const loadingUsers = useSelector(state => state.loadingUsers);
  const { search, page, users } = useSelector(state => state.userList);

  // Setup State
  const [sort, setSort] = useState('firstName');
  const [sortOrder, setSortOrder] = useState('asc');
  const [pageNumber, setPageNumber] = useState(0);
  // Setup Local Storage
  const initialPageSize = localStorage.getItem(`usersPageSize-${userId}`) !== null
    ? Number(localStorage.getItem(`usersPageSize-${userId}`))
    : 50;
  const [pageSize, setPageSize] = useState(initialPageSize);
  const { filters, filterCount } = localState;

  useEffect(() => {
    return () => {
      dispatch(usersActions.resetUsersPage());
    };
  }, []);

  useEffect(() => {
    usersActions.loadUsers(
      pageSize,
      pageNumber,
      { name: sort, order: sortOrder },
      {
        ...filters,
        search,
      },
    )(dispatch);
  }, [pageSize, pageNumber, sort, sortOrder, search]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    localDispatch({
      type: componentActions.setFilter,
      name,
      value,
    });
  };

  const createFilters = useCallback(() => {
    const {
      accountType,
      role,
      status,
      creationDate,
    } = filters;

    return [
      {
        label: t('msg_codes_users_userType_label'),
        name: 'accountType',
        placeholder: t('msg_codes_filter_all'),
        multiple: false,
        value: accountType,
        options: filterOptions.accountTypes,
        handleChange: handleFilterChange,
      },
      {
        label: t('msg_codes_users_role'),
        name: 'role',
        placeholder: t('msg_codes_filter_all'),
        value: role,
        multiple: false,
        options: filterOptions.roles,
        handleChange: handleFilterChange,
      },
      {
        label: t('msg_codes_agreements_agreementStatus'),
        name: 'status',
        placeholder: t('msg_codes_filter_all'),
        value: status,
        multiple: false,
        options: filterOptions.statuses,
        handleChange: handleFilterChange,
      },
      {
        label: t('msg_codes_users_dateAdded'),
        name: 'creationDate',
        placeholder: t('YYYY/MM/DD'),
        value: creationDate,
        type: 'dateRange',
        handleChange: handleFilterChange,
      },
    ];
  }, [filters]);

  const clearFilters = () => {
    localDispatch({ type: componentActions.clearFilters });
    dispatch(usersActions.resetFilters());
  };

  const changePage = (newPageNumber) => {
    setPageNumber(newPageNumber - 1);
  };

  const changeRowAmount = (newPageSize) => {
    localStorage.setItem(`usersPageSize-${userId}`, newPageSize);
    setPageSize(newPageSize);
    setPageNumber(0);
  };

  const actionBar = () => {
    const handleApplyFilters = () => {
      usersActions.loadUsers(
        pageSize,
        pageNumber,
        { name: sort, order: sortOrder },
        {
          ...filters,
          search,
        },
      )(dispatch);
      setPageNumber(0);
    };

    return (
      <ActionBar
        filters={(
          <FiltersContainer
            filters={createFilters()}
            name="users-filters"
          />
        )}
        filterCount={filterCount}
        onFilterClear={clearFilters}
        onFilterApply={handleApplyFilters}
        filterClearLabel={t('msg_codes_cta_clear')}
        filterPrimaryLabel={t('msg_codes_cta_apply')}
        filterSecondaryLabel={t('msg_codes_cta_cancel')}
        filterToolTipText={t('msg_codes_filter')}
        filterTitle={t('msg_codes_filter')}
        id="users-action-bar"
      />
    );
  };


  const sortUsers = (e, name) => {
    if (typeof e.preventDefault === 'function') {
      e.preventDefault();
    }
    // eslint-disable-next-line no-nested-ternary
    const newSortOrder = name === sort ? sortOrder === 'asc' ? 'desc' : 'asc' : 'asc';
    setSort(name);
    setSortOrder(newSortOrder);
  };

  // labels for table headers
  const tableHeaders = [
    { label: t('msg_codes_users'), value: 'firstName' },
    { label: t('msg_codes_users_userType_label'), value: 'accountType' },
    { label: t('msg_codes_accountType_partner'), value: 'partner' },
    { label: t('msg_codes_users_email'), value: 'email' },
    { label: t('msg_codes_users_dateAdded'), value: 'creationDate' },
    { label: t('msg_codes_users_role'), value: 'roleCode' },
    { label: t('msg_codes_agreements_agreementStatus'), value: 'status' },
  ];

  const renderHeaderCells = useCallback(() => {
    return tableHeaders.map(({ label, value }, headerCellIndex) => {
      const headerKey = headerCellIndex + 1;

      return (
        <TableHeaderCell
          sortable
          onClick={e => sortUsers(e, value)}
          sort={{ sortBy: sort, sortDir: sortOrder }}
          id={`users-${headerKey}`}
          key={headerKey}
          value={value}
          className={value ? `${value}` : 'remove'}
        >
          {label}
        </TableHeaderCell>
      );
    });
  }, [sort, sortOrder]);

  const renderTableRows = () => {
    return users.length > 0 ? (
      users.map((user) => {
        return (
          <User
            key={user.userId}
            history={history}
            user={user}
            name="users"
          />
        );
      })
    ) : (
      <NoResults />
    );
  };

  // Renders both the HEADERS and the ROWS
  const renderDataTable = () => {
    return (
      <DataTable
        columnTemplate="repeat(7, 1fr)"
        scrollHeight={400}
        actionBar={actionBar()}
        id="users-table"
        loading={loadingUsers}
      >
        <TableHeader>
          {renderHeaderCells()}
        </TableHeader>
        {renderTableRows()}
      </DataTable>
    );
  };


  const renderPagination = () => {
    return (
      <>
        {page.totalElements > 10 && (
          <div className="users-paginator">
            <RowDisplay
              currentPage={page.number + 1}
              totalItems={page.totalElements}
              pageSizes={[10, 25, 50, 100]}
              onPageSizeChange={changeRowAmount}
              initialPageSize={pageSize}
              showingOfText={RowDisplayLocalization(
                'msg_codes_pagination_showing',
              )(t)}
              id="users-rows"
            />
            {page.totalElements > pageSize && (
              <Pagination
                totalRecords={page.totalElements}
                currentPage={page.number + 1}
                pageLimit={pageSize}
                onPageItemClick={changePage}
                id="users-pagination"
              />
            )}
          </div>
        )}
      </>
    );
  };
  //

  return (
    <Container className="users users-list">
      {/* page TITLE and SEARCH functionality */}
      <Nav setPageNumber={setPageNumber} />
      {/* Renders the TABLE HEADERS and TABLE DATA */}
      {renderDataTable()}
      {/* Renders both the ROW DISPLAY and the PAGINATION  */}
      {renderPagination()}
    </Container>
  );
};

export default UsersPage;
