import React from 'react';
import {
  Layout,
  Flex,
  Radio,
  Container,
} from '@partner-global-ui/components';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

const VoucherCountsSection = (props) => {
  const { t } = useTranslation();
  const {
    renderDate, handleDateRadioChange,
    isGMT, startDate, endDate, canEditVoucherBatch,
  } = props;

  const hasNoDates = isEmpty(startDate) || isEmpty(endDate);

  return (
    <>
      <Container>
        <Layout>
          <Flex colSpan={6}>
            <div
              id="date-range-heading"
              className="voucher-batch-sub-section-header"
            >
              {t('msg_codes_date_range')}
            </div>
            <div
              id="date-range-description"
              className="voucher-batch-header-desc"
            >
              {t('msg_codes_note_local_gmt_save')}
            </div>
          </Flex>
        </Layout>
      </Container>
      <Container className="time-select-container" id="voucher-batch-time-select-container">
        <Layout colSpan={12}>
          <Flex colSpan={4}>
            {renderDate('msg_codes_start_date_time', true, startDate, 'startDate', !canEditVoucherBatch)}
          </Flex>
          <Flex colSpan={4}>
            {renderDate('msg_codes_end_date_time', true, endDate, 'endDate', !canEditVoucherBatch)}
          </Flex>
          <Flex colSpan={4} className="voucher-batch-time-zone-radio-container" id="voucher-batch-time-zone-radio-container">
            <Layout>
              <Flex>
                <Radio
                  onChange={handleDateRadioChange}
                  checked={isGMT}
                  name="gmt"
                  id="gmtTimeZoneRadio"
                  disabled={!canEditVoucherBatch || hasNoDates}
                  label={t('msg_codes_gmt_time')}
                />
              </Flex>
            </Layout>
            <Layout>
              <Flex>
                <Radio
                  onChange={handleDateRadioChange}
                  checked={!isGMT}
                  name="local"
                  id="localTimeZoneRadio"
                  disabled={!canEditVoucherBatch || hasNoDates}
                  label={t('msg_codes_availability_local_time')}
                />
              </Flex>
            </Layout>
          </Flex>
        </Layout>
      </Container>
    </>
  );
};

VoucherCountsSection.propTypes = {
  renderDate: PropTypes.func,
  handleDateRadioChange: PropTypes.func,
  isGMT: PropTypes.bool,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  canEditVoucherBatch: PropTypes.bool,
};

VoucherCountsSection.defaultProps = {
  renderDate: () => null,
  handleDateRadioChange: () => null,
  isGMT: true,
  startDate: '',
  endDate: '',
  canEditVoucherBatch: false,
};

export default VoucherCountsSection;
