import React from 'react';
import {
  Select,
  DateInput,
  DateRangeInput,
} from '@partner-global-ui/components';
import PropTypes from 'prop-types';

const typeLookup = {
  date: DateInput,
  dateRange: DateRangeInput,
};

const FilterSelector = ({ type, ...otherProps }) => {
  const component = typeLookup[type] || Select;
  return React.createElement(component, otherProps);
};

export default FilterSelector;

FilterSelector.propTypes = {
  type: PropTypes.string,
};

FilterSelector.defaultProps = {
  type: '',
};
