export const checkIfLineFieldsChanged = (order, initialOrder, orderLineFields) => {
  if (initialOrder.orderLines && initialOrder.orderLines.length > 0) {
    return order.orderLines.filter((line, index) => {
      // Check if any fields on the order line changed
      const hasChangedLineFields = orderLineFields.filter((field) => {
        const val = line[field];
        const initialVal = typeof initialOrder.orderLines[index][field] === 'number'
          && typeof val === 'string'
          ? initialOrder.orderLines[index][field].toString()
          : initialOrder.orderLines[index][field];
        return val !== initialVal;
      });
      return hasChangedLineFields.length > 0
    && !hasChangedLineFields.includes('voucherCatalog', 'voucherOrderLineExportLogs', 'downloadUrl');
    })
      .length > 0;
  }
  return false;
};

export const removeTrailingZeros = (value) => {
  // If value is a whole digit, return value.
  if (!value.includes('.')) {
    return value;
  }

  // If last character is decimal point, return value without it.
  if (value.substr(value.length - 1) === '.') {
    return value.substring(0, value.length - 1);
  }

  // If last character is 0, call this function without last digit.
  const mantissa = value.split('.')[1];
  if (mantissa.substring(mantissa.length - 1) === '0') {
    return (removeTrailingZeros(value.substring(0, value.length - 1)));
  }

  return value;
};
