import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import {
  Collapsible,
  Layout,
  Flex,
  AutoDataTable,
} from '@partner-global-ui/components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import '../Agreements.scss';
import formatHistoryStatus from '../../../utils/parterMerge';
import {
  AgreementStatusStringMapping,
  VersionStatusStringMapping,
} from '../../../constants/agreementState.constants';

const PermittedUsesHistoryTable = ({ agreementVersionId }) => {
  const { t } = useTranslation();
  const [audit, setAgreementVersionAudits] = useState();

  const columns = [
    {
      displayName: t('msg_codes_codes_version'),
      columnType: 'version',
      id: 'version',
    },
    {
      displayName: t('msg_codes_agreements_agreementStatus_label'),
      columnType: 'agreementStatus',
      id: 'agreementStatus',
    },
    {
      displayName: t('msg_codes_agreements_versionStatus'),
      columnType: 'agreementVersionStatus',
      id: 'agreementVersionStatus',
    },
    {
      displayName: t('msg_codes_agreements_versionEffectiveDate'),
      columnType: 'agreementVersionEffectiveDate',
      id: 'agreementVersionEffectiveDate',
    },
    {
      displayName: t('msg_codes_agreements_createdBy'),
      columnType: 'createdByName',
      id: 'createdByName',
    },
    {
      displayName: t('msg_codes_agreements_updatedBy'),
      columnType: 'updatedByName',
      id: 'updatedByName',
    },
  ];

  useEffect(() => {
    if (agreementVersionId) {
      const getAudit = async () => {
        const result = await axios.get(`/venom/api/audit/agreementVersion/${agreementVersionId}`)
          .then(response => response.data);

        if (result) {
          const history = result.map((changeState) => {
            const detail = JSON.parse(changeState.detail);
            return {
              ...detail,
              version: '001',
              agreementVersionEffectiveDate: detail.agreementVersionEffectiveDate
                ? moment(detail.agreementVersionEffectiveDate).utc().format('MM/DD/YYYY (UTC)')
                : '-',
              agreementStatus: t(AgreementStatusStringMapping[detail.agreementStatus]),
              agreementVersionStatus: formatHistoryStatus(
                detail,
                t(VersionStatusStringMapping[detail.agreementVersionStatus]),
                t,
              ),
              createdByName: changeState.createdByName === ' ' ? t('msg_codes_users_system') : changeState.createdByName,
              updatedByName: changeState.modifiedByName === ' ' ? t('msg_codes_users_system') : changeState.modifiedByName,
            };
          });

          setAgreementVersionAudits(history);
        }
      };

      getAudit();
    }
  }, [agreementVersionId]);

  return audit ? (
    <Layout className="changeHistory">
      <Flex colSpan="12">
        <Collapsible
          heading={t('msg_codes_agreements_history')}
          id="agreement-change-history"
          defaultIsOpen
        >
          <AutoDataTable columns={columns} rows={audit} />
        </Collapsible>
      </Flex>
    </Layout>
  ) : null;
};

PermittedUsesHistoryTable.propTypes = {
  agreementVersionId: PropTypes.number.isRequired,
};

export default PermittedUsesHistoryTable;
