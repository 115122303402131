import i18n from 'i18next';
import orderStatuses from '../../../../constants/order.constants';

export const cancelComment = (e, commentName, comments, setComments) => {
  setComments({ ...comments, [commentName]: '' });
};

export const changeComment = (e, commentName, comments, setComments) => {
  e.persist();
  setComments({ ...comments, [commentName]: e.target.value });
};

export const getSuccessBannerText = ({
  userApprovalLevel,
  maximumRequiredApprovalLevel,
  isWaitingForInitialApproval,
  isSuperAdmin,
  approvedOrder,
}) => ({
  // Order is ready for final approval
  [true]: () => i18n.t('msg_codes_orderDetailsAdmin_orderApproved_toast'),
  // Order is approved but not yet ready for final approval
  [userApprovalLevel < maximumRequiredApprovalLevel]: () => i18n.t('msg_codes_banner_orderApprove', {
    lowerNumber: userApprovalLevel,
    higherNumber: userApprovalLevel + 1,
  }),
  // Order is approved and is ready for final approval
  [approvedOrder.currentApprovalLevel >= maximumRequiredApprovalLevel
    && approvedOrder.orderStatus === orderStatuses.SUBMITTED_PENDING_APPROVAL]:
    () => i18n.t(
      'msg_codes_prefinal_approval_toast',
      { lastNumber: userApprovalLevel },
    ),
  // Order is inital approved
  [isWaitingForInitialApproval && !isSuperAdmin]: () => i18n.t('msg_codes_banner_orderApprove', {
    lowerNumber: 0,
    higherNumber: 1,
  }),
  // Order is final approved
  [approvedOrder.currentApprovalLevel >= maximumRequiredApprovalLevel && approvedOrder.orderStatus === orderStatuses.APPROVED_ORDER]: () => i18n.t('msg_codes_banner_orderFinalApprove'),
}.true());
