import axios from 'axios';
import * as types from './actionTypes';

export function getOrderLineStatusSuccess(orderLines, orderLineIds) {
  return {
    type: types.ORDER_LINE_STATUS, orderLines, orderLineIds,
  };
}
export function getOrdersLinesStatusSuccess(orderLines, orderLineIds, orderIds) {
  return {
    type: types.ORDERS_LINES_STATUS, orderLines, orderLineIds, orderIds,
  };
}

export function getOrderLineStatus(orderLineIds, orderIds, action) {
  const postBody = {
    orderLines: orderLineIds,
  };
  return (dispatch => axios.post('/venom/api/orderLines/status', postBody)
    .then(response => response.data)
    .then((orderLines) => {
      dispatch(action(orderLines.orderLines, orderLineIds, orderIds));
    })
    .catch(() => {
      // TODO: error handling
      // dispatch(vouchersHasErrored(true))
    }));
}
