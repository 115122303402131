import React, {
  useEffect,
  useCallback,
  useReducer,
  useContext,
  useRef,
  useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  xor, uniqBy, union, difference,
} from 'lodash';
import {
  RowDisplay,
  Pagination,
  TableHeader,
  TableHeaderCell,
  DataTable,
  Container,
  Layout,
  Flex,
  Button,
  NoResults,
  GroupState,
  ActionBar,
  NotificationContext,
} from '@partner-global-ui/components';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import {
  loadOrderHistory,
  submitOrder,
  getOrderStatus,
  retryOrder,
  downloadOrder,
} from '../../../actions/orderHistoryActions';
import {
  getPartnerInSession,
} from '../shared/thirdPartyCatalogPageMethods';
import SearchHeader from '../../common/SearchHeader/SearchHeader';
import FiltersContainer from '../../common/Filters/FiltersContainer';
import OrderHistoryHeaderColumns from './orderHistoryHeaderColumns';
import { thirdPartyOrderHistorySearchOptions } from '../../../constants/searchOptions.constants';
import RowDisplayLocalization from '../../../helpers/RowDisplayLocalization';
import { filterCounter } from '../../../helpers/filterMethods';
import ordersHistoryStatus from '../../../utils/ordersHistoryStatus';
import orderHistoryNotifications from './OrderHistoryNotifications';
import * as filterOptions from '../../../constants/filterOptions.constants';
import pollingInterval from '../../../api/pollingInterval';
import * as scanStatuses from '../../../constants/scanStatus.constants';
import './OrderHistory.scss';
import OrderHistoryRow from './OrderHistoryRow';

const defaultFilters = {
  status: [],
  region: [],
  activityTypes: [],
  creationDate: [],
};

export const initialState = {
  filters: defaultFilters,
  filtered: false,
  filterCount: 0,
  search: {
    category: '',
    term: '',
  },
  searched: false,
  sort: {
    sortBy: 'creationDate',
    sortDir: 'desc',
  },
  pageSize: 10,
  selectedRows: [],
  downloadQueue: [],
  retryQueue: [],
  editedRows: [],
};

export const localStateReducer = (
  state,
  {
    type = '',
    pageSize = 10,
    page,
    search,
    sort,
    name,
    value,
    orderId,
    orderDetails,
    orderIds,
    filters = defaultFilters,
  },
) => {
  const filteredState = filters && (
    filters.region.length > 0
    || filters.status.length > 0
    || filters.activityTypes.length > 0
    || filters.creationDate.length > 0);
  let newState;
  switch (type) {
    case 'setFilter':
      newState = {
        ...state,
        filters: { ...state.filters, [name]: value },
        filtered: !filteredState,
        selectedRows: initialState.selectedRows,
      };
      return { ...newState, filterCount: filterCounter(newState.filters) };
    case 'clearFilters':
      return {
        ...state,
        filters: defaultFilters,
        filtered: false,
        filterCount: 0,
        selectedRows: initialState.selectedRows,
      };
    case 'setSearch':
      return { ...state, search, searched: true };
    case 'clearSearch':
      return { ...state, search: undefined, searched: false };
    case 'setPageSize':
      return { ...state, pageSize };
    case 'setSort':
      return {
        ...state,
        sort,
        pageSize: page.size,
        currentPage: page.number,
        totalItems: page.totalElements,
        numberOfElements: page.numberOfElements,
        totalPages: page.totalPages,
      };
    case 'setPage':
      return {
        ...state,
        pageSize: page.size,
        currentPage: page.number,
        totalItems: page.totalElements,
        numberOfElements: page.numberOfElements,
        totalPages: page.totalPages,
      };
    case 'addPage':
      return {
        ...state,
        selectedRows: union(state.selectedRows, orderIds),
      };
    case 'clearPage':
      return {
        ...state,
        selectedRows: difference(state.selectedRows, orderIds),
      };
    case 'toggleRow':
      return {
        ...state,
        selectedRows: xor(state.selectedRows, [orderId]),
      };
    case 'togglePage':
      return {
        ...state,
        selectedRows: xor(state.selectedRows, orderIds),
      };
    case 'resetCheckBoxes':
      return {
        ...state,
        selectedRows: initialState.selectedRows,
      };
    case 'addToDownloadQueue':
      return {
        ...state,
        downloadQueue: [...state.downloadQueue, orderDetails[0]],
      };
    case 'removeFromDownloadQueue':
      return {
        ...state,
        downloadQueue: state.downloadQueue.filter(item => item.orderId !== orderDetails[0].orderId),
      };
    case 'addToRetryQueue':
      return {
        ...state,
        retryQueue: [...state.retryQueue, orderDetails[0]],
      };
    case 'removeFromRetryQueue':
      return {
        ...state,
        retryQueue: state.retryQueue.filter(item => item.orderId !== orderDetails[0].orderId),
      };
    default:
      return state;
  }
};

const OrderHistory = ({ location }) => {
  //  Setup
  const dispatch = useDispatch();
  const history = useHistory();
  const tableRef = useRef();
  const { t } = useTranslation();
  const [pendingVirusScanQueue, setPendingVirusScanQueue] = useState([]);
  const notificationCont = useContext(NotificationContext);
  const { userId, roleCode } = useSelector(state => state.user);
  const { orderHistoryCatalog } = useSelector(state => state.orderHistory);
  const selectedPartner = useSelector(state => state.thirdPartyCatalogPage.selectedPartner)
    || getPartnerInSession();
  const [localState, localDispatch] = useReducer(localStateReducer, initialState);

  useEffect(() => {
    const pendingStatusQueue = orderHistoryCatalog
      .filter(({ orderStatus, poFileScanStatus }) => {
        return (
          orderStatus === ordersHistoryStatus.PROCESSING.statusName
          && poFileScanStatus === scanStatuses.PENDING
        );
      });

    setPendingVirusScanQueue(pendingStatusQueue);
  }, [orderHistoryCatalog]);

  const {
    pageSize,
    currentPage,
    search,
    sort,
    totalItems,
    filters,
    filterCount,
    selectedRows,
    downloadQueue,
    retryQueue,
  } = localState;

  const groupCheckboxState = selectedRows.length > 0 ? GroupState.SOME : GroupState.NONE;
  const { location: { pathname } } = useHistory();
  const isOrdersPage = pathname.includes('orderhistory');

  const queryParams = new URLSearchParams(location.search);
  const searchParam = queryParams.toString().split('=');
  const newCategory = searchParam[0];
  const newTerm = searchParam[1];
  const updatedSearch = { category: newCategory, term: newTerm };

  const loadOrderHistoryCatalog = useCallback(() => {
    const initialPageSize = localStorage.getItem(`orderHistoryPageSize-${userId}`) !== null
      ? Number(localStorage.getItem(`orderHistoryPageSize-${userId}`))
      : pageSize;
    loadOrderHistory(
      dispatch,
      selectedPartner,
      initialPageSize,
      currentPage,
      typeof updatedSearch.term !== 'undefined' ? updatedSearch : search,
      sort,
    )
      .then((response) => {
        if (response) {
          const { content, ...newPageInformation } = response;
          localDispatch({
            type: 'setPage',
            page: newPageInformation,
          });
        }
      });
  });

  useEffect(() => {
    if (!selectedPartner && roleCode === 'SUPER_ADMIN') {
      history.push('/thirdPartyCatalog');
    }

    loadOrderHistoryCatalog();
  }, []);

  useEffect(() => {
    if (downloadQueue.length > 0) {
      const tick = () => {
        downloadQueue.forEach((product) => {
          getOrderStatus(dispatch, localDispatch, downloadQueue, retryQueue, product);
        });
      };
      const id = setInterval(tick, pollingInterval);
      return () => clearInterval(id);
    }
    return () => {};
  }, [downloadQueue, dispatch, localDispatch]);

  useEffect(() => {
    if (retryQueue.length > 0) {
      const tick = () => {
        uniqBy(retryQueue, 'orderId').forEach(async (product) => {
          const retriedProduct = await getOrderStatus(
            dispatch, localDispatch, downloadQueue, retryQueue, product,
          );
          orderHistoryNotifications(retriedProduct.orderStatus, notificationCont);
        });
      };
      const id = setInterval(tick, pollingInterval);
      return () => clearInterval(id);
    }
    return () => {};
  }, [retryQueue, dispatch, localDispatch]);

  useEffect(() => {
    if (pendingVirusScanQueue.length > 0) {
      const tick = () => {
        uniqBy(pendingVirusScanQueue, 'orderId').forEach(async (product) => {
          await getOrderStatus(
            dispatch, localDispatch, downloadQueue, retryQueue, product,
          );
        });
      };
      const id = setInterval(tick, pollingInterval);
      return () => clearInterval(id);
    }
    return () => {};
  }, [pendingVirusScanQueue, dispatch, localDispatch]);

  useEffect(() => {
    if (selectedRows) {
      localDispatch({ type: 'resetCheckBoxes' });
    }
  }, [currentPage, search, sort, pageSize]);

  const changeSearch = useCallback((term, category) => {
    const newSearch = { term, category };

    if (category && term) {
      const newQueryParams = new URLSearchParams();
      newQueryParams.set(category, term);
      history.push(`${location.pathname}?${newQueryParams}`);
    } else {
      history.push(`${location.pathname}`);
    }

    loadOrderHistory(
      dispatch,
      selectedPartner,
      pageSize,
      currentPage,
      newSearch,
      sort,
      filters,
    )
      .then((response) => {
        const { content, ...newPageInformation } = response;
        localDispatch({
          type: 'setSearch',
          search: newSearch,
        });
        localDispatch({
          type: 'setPage',
          page: newPageInformation,
        });
      });
  }, [pageSize, filters, sort, currentPage, location]);


  const canCheckRow = (orderStatus) => {
    return (orderStatus === ordersHistoryStatus.DOWNLOAD.statusName
      || orderStatus === ordersHistoryStatus.REDOWNLOAD.statusName);
  };

  const toggleGroupCheckbox = (rows) => {
    return () => {
      const orderIds = rows
        .filter(row => canCheckRow(row.orderStatus))
        .map(row => row.orderId);
      if (orderIds.every((id) => {
        return selectedRows.some(selectedRow => id === selectedRow);
      })) {
        localDispatch({ type: 'clearPage', orderIds });
      } else {
        localDispatch({ type: 'addPage', orderIds });
      }
    };
  };

  const checkRow = (orderId) => {
    return () => {
      localDispatch({ type: 'toggleRow', orderId });
    };
  };

  const updatePageSize = useCallback((newSize) => {
    loadOrderHistory(
      dispatch,
      selectedPartner,
      newSize,
      currentPage,
      search,
      sort,
      filters,
    )
      .then((response) => {
        const { content, ...newPageInformation } = response;
        localDispatch({
          type: 'setPage',
          page: newPageInformation,
        });
        localStorage.setItem(`orderHistoryPageSize-${userId}`, newSize);
      });
  }, [currentPage, search, sort, filters, selectedRows]);

  const handlePageNavigation = useCallback((newPageNumber) => {
    loadOrderHistory(
      dispatch,
      selectedPartner,
      pageSize,
      newPageNumber - 1,
      search,
      sort,
      filters,
    )
      .then((response) => {
        const { content, ...newPageInformation } = response;
        localDispatch({
          type: 'setPage',
          page: newPageInformation,
        });
      });
  }, [
    pageSize,
    search,
    sort,
    filters,
    selectedPartner,
  ]);

  const sortOrdersHistory = useCallback((newSort) => {
    let { sortDir } = sort;
    const { sortBy } = sort;

    sortDir = newSort === sortBy
      ? sortDir === 'asc'
        ? 'desc'
        : 'asc'
      : 'asc';
    loadOrderHistory(
      dispatch,
      selectedPartner,
      pageSize,
      currentPage,
      search,
      { sortBy: newSort, sortDir },
      filters,
    )
      .then((response) => {
        const { content, ...newPageInformation } = response;
        localDispatch({
          type: 'setSort',
          page: newPageInformation,
          sort: { sortBy: newSort, sortDir },
        });
      });
  }, [
    pageSize,
    search,
    sort,
    currentPage,
    filters,
    selectedPartner,
  ]);

  const onCTAButtonClick = async (order) => {
    const { orderStatus } = order;

    if (orderStatus === ordersHistoryStatus.ERROR.statusName) {
      retryOrder(dispatch, localDispatch, retryQueue, order, notificationCont);
      return;
    }
    if (orderStatus === ordersHistoryStatus.PENDING_DRAFT_AMENDMENT.statusName) {
      submitOrder(dispatch, notificationCont, order);
      return;
    }

    downloadOrder(dispatch, localDispatch, downloadQueue, order, notificationCont);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    localDispatch({ type: 'setFilter', name, value });
  };

  const createFilters = useCallback(() => {
    const {
      status, activityTypes,
      region, creationDate,
    } = filters;
    const activityOptions = filterOptions.activityTypesOptions.map((option) => {
      const { value, label } = option;
      const newLabel = t(label);
      return {
        value,
        label: newLabel,
      };
    });
    const regionsOptions = filterOptions.availabilityRegionOptions.map((option) => {
      const { value, label } = option;
      const newLabel = t(label);
      return {
        value,
        label: newLabel,
      };
    });
    const statusOptions = filterOptions.globalRateCardOrderStatusOptions.map((option) => {
      const { value, label } = option;
      const newLabel = t(label);
      return {
        value,
        label: newLabel,
      };
    });

    return [
      {
        label: t('msg_codes_common_activity'),
        name: 'activityTypes',
        placeholder: t('msg_codes_filter_all'),
        multiple: true,
        value: activityTypes,
        options: activityOptions,
        handleChange: handleFilterChange,
      },
      {
        label: t('msg_codes_common_region'),
        name: 'region',
        placeholder: t('msg_codes_filter_all'),
        value: region,
        options: regionsOptions,
        handleChange: handleFilterChange,
      },
      {
        label: t('msg_codes_agreements_agreementStatus'),
        name: 'status',
        placeholder: t('msg_codes_filter_all'),
        value: status,
        multiple: true,
        options: statusOptions,
        handleChange: handleFilterChange,
      },
      {
        label: t('msg_codes_common_created'),
        type: 'dateRange',
        name: 'creationDate',
        placeholder: t('msg_codes_codes_releaseDate_prompt'),
        value: creationDate,
        handleChange: handleFilterChange,
      },
    ];
  }, [filters, search, sort, pageSize, currentPage]);

  const handleBulkDownload = useCallback(async () => {
    let selectedOrders = orderHistoryCatalog.filter((catalog) => {
      return selectedRows.includes(catalog.orderId);
    });

    selectedOrders = uniqBy(selectedOrders, 'orderId');

    selectedOrders.forEach((order) => {
      downloadOrder(dispatch, localDispatch, downloadQueue, order);
    });
  });

  const actionBar = useCallback(() => {
    const filterOrderHistory = () => {
      loadOrderHistory(
        dispatch,
        selectedPartner,
        pageSize,
        currentPage,
        search,
        sort,
        filters,
      )
        .then((response) => {
          const { content, ...newPageInformation } = response;
          localDispatch({
            type: 'setPage',
            page: newPageInformation,
          });
        });
    };
    const clearFilters = () => {
      loadOrderHistory(
        dispatch,
        selectedPartner,
        pageSize,
        currentPage,
        search,
        sort,
        defaultFilters,
      )
        .then((response) => {
          const { content, ...newPageInformation } = response;
          localDispatch({
            type: 'setPage',
            page: newPageInformation,
          });
          localDispatch({ type: 'clearFilters' });
        });
    };
    return (
      <ActionBar
        filterCount={filterCount}
        onFilterApply={filterOrderHistory}
        onFilterClear={clearFilters}
        filters={
          <FiltersContainer filters={createFilters()} />
        }
        filterClearLabel={t('msg_codes_cta_clear')}
        filterPrimaryLabel={t('msg_codes_cta_apply')}
        filterSecondaryLabel={t('msg_codes_cta_cancel')}
        filterToolTipText={t('msg_codes_filter')}
        filterTitle={t('msg_codes_filter')}
        right={(
          <Button
            success
            id="success"
            type="button"
            onClick={() => handleBulkDownload()}
            className="bulk-download-button"
          >
            {t('msg_codes_orders_downloadall_cta')}
          </Button>
        )}
      />
    );
  }, [
    filters,
    search,
    sort,
    pageSize,
    currentPage,
    selectedRows,
    selectedPartner,
  ]);

  const renderNav = useCallback(() => {
    return (
      <Layout className="order-history-header">
        <SearchHeader
          name="order-history-search-header"
          title={t('msg_codes_orders')}
          subTitle={t('msg_codes_orders_viewStatus_subheader')}
          options={thirdPartyOrderHistorySearchOptions}
          placeholder={t('msg_codes_codeProdCat_typeSomething_dropdown')}
          clearIcon
          isOrdersPage={isOrdersPage}
          changeSearch={changeSearch}
          canSearch
        />
      </Layout>
    );
  }, [totalItems, orderHistoryCatalog]);

  return (
    <Container className="order-history" id="order-history">
      {renderNav()}
      <Layout
        className="order-history-table"
        id="order-history-table"
      >
        <Flex
          className="order-history-content"
          id="order-history-content"
        >
          <DataTable
            columnTemplate="20% 13% 10% 9% 10% 10% 12% 16%"
            hasCheckbox
            actionBar={actionBar()}
            ref={tableRef}
          >
            <TableHeader
              onCheckboxClick={toggleGroupCheckbox(orderHistoryCatalog)}
              checkboxState={groupCheckboxState}
            >
              {OrderHistoryHeaderColumns.map((column) => {
                return (
                  <TableHeaderCell
                    key={column.id}
                    id={column.value}
                    value={column.value}
                    sortable={column.sortable}
                    sort={sort}
                    className={`header-cell-${column.value}`}
                    onClick={sortOrdersHistory}
                  >
                    {t(column.id)}
                  </TableHeaderCell>
                );
              })}
              <TableHeaderCell className="header-cell-cta-button" value="cta-button" />
            </TableHeader>
            {totalItems === 0
              ? (
                <NoResults
                  id="no-orders"
                  title={t('msg_codes_codes_noResultsFound')}
                  message=""
                />
              )
              : (
                orderHistoryCatalog.map((order) => {
                  const { orderId, orderStatus } = order;
                  const checkboxState = selectedRows.some(selectedRow => orderId === selectedRow)
                    ? GroupState.ALL : GroupState.NONE;
                  return (
                    <OrderHistoryRow
                      tableRef={tableRef}
                      order={order}
                      key={orderId}
                      canCheckRow={canCheckRow(orderStatus)}
                      checkboxState={checkboxState}
                      onCheckboxClick={checkRow(orderId)}
                      onCTAButtonClick={onCTAButtonClick}
                      isOrderRowView
                    />
                  );
                })
              )
            }
          </DataTable>
          {totalItems > 10 && (
            <div className="orderHistoryPagePaginator">
              <RowDisplay
                currentPage={currentPage + 1}
                totalItems={totalItems}
                pageSizes={[10, 25, 50, 100]}
                onPageSizeChange={updatePageSize}
                initialPageSize={pageSize}
                showingOfText={RowDisplayLocalization('msg_codes_pagination_showing')(t)}
              />
              {totalItems > pageSize && (
                <Pagination
                  totalRecords={totalItems}
                  currentPage={currentPage + 1}
                  pageLimit={pageSize}
                  onPageItemClick={handlePageNavigation}
                />
              )}
            </div>
          )}
        </Flex>
      </Layout>
    </Container>
  );
};

OrderHistory.propTypes = {
  location: PropTypes.object,
};

OrderHistory.defaultProps = {
  location: {},
};

export default OrderHistory;
