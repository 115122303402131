import React, {
  useEffect,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { Container } from '@partner-global-ui/components';
import Nav from './Nav';
import OrderContents from './OrderContents';
import OrderHistory from '../OrderHistory/OrderHistoryNew';
import OrderInfo from './OrderInfoFreeAllocation';
import {
  getOrderLineStatus,
  getOrderLineStatusSuccess,
} from '../../../actions/pollingActions';
import pollingInterval from '../../../api/pollingInterval';
import './Order.scss';

function OrderPage() {
  const dispatch = useDispatch();
  const order = useSelector(state => state.order);

  // setup interval for updating processing order line
  useEffect(() => {
    let checkProcessingOrderLinesInterval;
    if (order.orderLines[0].processingOrderLine) {
      checkProcessingOrderLinesInterval = setInterval(() => {
        getOrderLineStatus(
          [order.orderLines[0].orderLineId],
          [order.orderId],
          getOrderLineStatusSuccess,
        )(dispatch);
      }, pollingInterval);
    }

    return () => {
      clearInterval(checkProcessingOrderLinesInterval);
    };
  }, [order.orderLines, dispatch, pollingInterval]);

  // Render
  return (
    <div data-testid="free-allocation-order" className="order">
      <Nav order={order} />
      <div className="order-sections">
        <Container id="order-container" className="order-container">
          <OrderInfo order={order} />
          <OrderContents order={order} />
          <OrderHistory order={order} />
        </Container>
      </div>
    </div>
  );
}

OrderPage.propTypes = {};

OrderPage.defaultProps = {};

export default OrderPage;
