import React from 'react';
import { Container, Layout } from '@partner-global-ui/components';
import './Error.scss';

const NotAuthorized = () => (
  <Container className="error-page">
    <Layout className="error-page-layout">
      <h1>Something went wrong!</h1>
      <h2>You are not Authorized to view this page.</h2>
      <h2>If the problem persists, please use the help link on the Codes home page.</h2>
    </Layout>
  </Container>
);

export default NotAuthorized;
