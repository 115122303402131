export default [
  {
    id: 'partner',
    value: 'name',
    stringId: 'msg_codes_accountType_partner',
    sortable: true,
    width: '30%',
  },
  {
    id: 'numberOfUsers',
    value: 'numberOfUsers',
    stringId: 'msg_codes_number_users',
    sortable: true,
    width: '15%',
  },
  {
    id: 'numberOfAgreements',
    value: 'numberOfAgreements',
    stringId: 'msg_codes_number_agreements',
    sortable: true,
    width: '20%',
  },
  {
    id: 'creationDate',
    value: 'creationDate',
    stringId: 'msg_codes_agreements_dateCreated',
    sortable: true,
    width: '15%',
  },
  {
    id: 'status',
    value: 'status',
    stringId: 'msg_codes_agreements_agreementStatus',
    sortable: true,
    width: '20%',
  },
];
