import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  TableCell, TableRow,
} from '@partner-global-ui/components';
import './SFTPPartner.scss';

const authenticationTypeMap = {
  PASSWORD: 'msg_codes_partners_authType_password',
  CERTIFICATE: 'msg_codes_partners_authType_certificate',
};

const sftpStatusMap = {
  INACTIVE: 'msg_codes_agreementStatus_inactive',
  ACTIVE: 'msg_codes_agreementStatus_active',
  DELETED: 'msg_codes_sftpStatus_deleted',
};

const SFTP = (props) => {
  const { t } = useTranslation();
  const {
    sftp: {
      sftpId,
      sftpProperties: {
        authenticationType,
        description,
        host,
      },
      sftpStatus,
    },
  } = props;

  return (
    <TableRow name="partnerDetailSFTPContent" id={`user-${sftpId}`}>
      <TableCell id="partnerDetailSFTPContentName">
        {description}
      </TableCell>
      <TableCell id="partnerDetailSFTPContentType">
        {t(authenticationTypeMap[authenticationType])}
      </TableCell>
      <TableCell id="partnerDetailSFTPContentHost">
        {host}
      </TableCell>
      <TableCell id="partnerDetailSFTPContentStatus">
        <span className={`sftp-status ${sftpStatus}`}>
          <span />
          {t(sftpStatusMap[sftpStatus])}
        </span>
      </TableCell>
    </TableRow>
  );
};

SFTP.propTypes = {
  sftp: PropTypes.object,
};

SFTP.defaultProps = {
  sftp: {
    sftpProperties: {
      authenticationType: '',
      description: '',
      host: '',
    },
  },
};

export default SFTP;
