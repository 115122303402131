import React from 'react';
import PropTypes from 'prop-types';
import {
  TableRow,
  TableCell,
  Icon,
  Tooltip,
} from '@partner-global-ui/components';
import { isEmpty } from 'lodash';

import orderLineErrors from '../../../constants/orderLineErrors.constants';
import autoCancelReasons from '../../../constants/autocanceledReasons.constants';
import { voucherTypeOptions } from '../../../constants/filterOptions.constants';
import countriesGensenStrings from '../../../constants/countries.constants';
import {
  getErrorTooltip,
  getFulfillmentErrorTooltip,
  getRemovedTooltip,
  getLineClasses,
  getStatusTranslationId,
} from '../OrdersUtils';

const OrderLine = ({
  line, order, t, tableRef,
}) => {
  // need below check to not show new lines in case of leaving unsaved order
  if (line.isNew) {
    return null;
  }

  // Fetch variables from props
  const {
    voucherCatalog: {
      type,
      voucherName,
      countryCode,
    },
    activationStatus,
    autoCanceled,
    autoCancelReason,
    deletedOrderLineId,
    deliveryStatus,
    exportErrorMessage,
    fulfillmentStatus,
    orderLineId,
    quantity,
    voucherStatus,
    npVoucherBatchId,
  } = line;
  const {
    orderStatus,
  } = order;

  // Set up
  const tooltipMessage = autoCancelReasons[autoCancelReason];
  const isRemoved = typeof deletedOrderLineId === 'number';
  const showRemoveTooltip = orderStatus !== 'APPROVED_ORDER' && !isEmpty(activationStatus)
  && activationStatus !== 'Activated';
  const fulfillmentErrorCode = exportErrorMessage ? exportErrorMessage.substring(0, 4) : '';
  const isFulfillmentError = fulfillmentStatus === 'Fulfillment Error'
    && orderLineErrors.some(errors => errors.code === fulfillmentErrorCode);
  const lineIsApproved = orderStatus === 'APPROVED_ORDER';
  const isCancelled = order
    && typeof orderStatus !== 'undefined'
    && !isRemoved
    && (
      orderStatus === 'CANCELLED_ORDER'
      || voucherStatus === 'CANCELLED'
      || autoCanceled
    );
  const voucherTypeObject = voucherTypeOptions.find(typeOption => typeOption.value === type);
  const typeName = voucherTypeObject
    ? t(voucherTypeObject.label)
    : t(type);

  // Render
  return (
    <TableRow
      className={getLineClasses(autoCanceled, activationStatus, isRemoved, isCancelled)}
      id="sub-table-row"
      expanded
    >
      <TableCell name="voucherName" className={getLineClasses(autoCanceled, activationStatus, isRemoved, isCancelled)} id="voucher-name">
        {voucherName}
        {' '}
        {
          isFulfillmentError && (
            <div data-testid="tooltip-icon-fulfilment" className="order-list-order-name-tooltip">
              <Tooltip
                anchor={tableRef}
                strategy="fixed"
                placement="top"
                content={getFulfillmentErrorTooltip(orderLineErrors, fulfillmentErrorCode)}
                id={`fulfillmentOverlay-${orderLineId}`}
              >
                <Icon size={16} id="fulfillment-warning-sign">warning</Icon>
              </Tooltip>
            </div>
          )
        }
        {
          autoCanceled && !isRemoved && (
            <div data-testid="tooltip-icon-autocanceled" className="order-list-order-name-tooltip">
              <Tooltip
                anchor={tableRef}
                strategy="fixed"
                placement="top"
                content={getErrorTooltip(isCancelled, tooltipMessage)}
                id={`overlay-${orderLineId}`}
              >
                <Icon size={16} id="ban-circle">block</Icon>
              </Tooltip>
            </div>
          )
        }
        {
          showRemoveTooltip && (
            <div data-testid="tooltip-icon-removed" className="order-list-order-name-tooltip">
              <Tooltip anchor={tableRef} strategy="fixed" placement="top" content={getRemovedTooltip(autoCancelReason, tooltipMessage)} id={`removedOverlay-${orderLineId}`}>
                <Icon size={16} id="removed-warning-sign">warning</Icon>
              </Tooltip>
            </div>
          )
        }
      </TableCell>
      <TableCell name="name" className={getLineClasses(autoCanceled, activationStatus, isRemoved, isCancelled)} id="type-name">
        {typeName}
      </TableCell>
      <TableCell name="country" className={getLineClasses(autoCanceled, activationStatus, isRemoved, isCancelled)} id="country">
        {countriesGensenStrings[countryCode] && t(countriesGensenStrings[countryCode].name)}
      </TableCell>
      <TableCell name="quantity" className={getLineClasses(autoCanceled, activationStatus, isRemoved, isCancelled)} id="quantity">
        {quantity}
      </TableCell>
      <TableCell name="batchId" className={getLineClasses(autoCanceled, activationStatus, isRemoved, isCancelled)} id="batch-id">
        {npVoucherBatchId}
      </TableCell>
      {lineIsApproved ? (
        <TableCell name="fulfillmentStatus" className={getLineClasses(autoCanceled, activationStatus, isRemoved, isCancelled)} id="fulfillment">
          {getStatusTranslationId(fulfillmentStatus)}
        </TableCell>
      ) : <></>}
      {lineIsApproved ? (
        <TableCell name="deliveryStatus" className={getLineClasses(autoCanceled, activationStatus, isRemoved, isCancelled)} id="delivery">
          {getStatusTranslationId(deliveryStatus)}
        </TableCell>
      ) : <></>}
      {lineIsApproved ? (
        <TableCell name="activationStatus" className={getLineClasses(autoCanceled, activationStatus, isRemoved, isCancelled)} id="activation">
          {getStatusTranslationId(activationStatus)}
        </TableCell>
      ) : <></>}
    </TableRow>
  );
};

OrderLine.propTypes = {
  line: PropTypes.object,
  order: PropTypes.object,
  t: PropTypes.func,
  tableRef: PropTypes.object,
};

OrderLine.defaultProps = {
  line: {},
  order: {},
  t: () => { },
  tableRef: { },
};

export default OrderLine;
