import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Icon,
  TextArea,
} from '@partner-global-ui/components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './textarea.scss';
import { isEmpty } from 'lodash';

const UserDetailTextArea = ({
  onChange,
  onSubmit,
  onCancel,
  toolbarItems,
  defaultValue,
  errorMessage,
  successMessage,
  submitLabel,
  cancelLabel,
  disabled,
  submitDisabled,
  cancelDisabled,
  showSubmit,
  showCancel,
  focus,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(defaultValue || '');

  useEffect(() => {
    if (focus && !disabled) {
      document.getElementById('user-detail-pgpkey-textarea').focus();
    }
  }, []);

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (isEmpty(value)) {
      document.getElementById('user-detail-pgpkey-textarea').spellcheck = false;
    }
  }, [value]);

  const handleChange = useCallback((e) => {
    setValue(e.target.value);
    onChange({ target: { value: e.target.value } });
  }, [onChange]);

  const handleCancel = useCallback(() => {
    if (onCancel) onCancel();
    setValue('');
  }, [onCancel]);

  const handleSubmit = useCallback(() => {
    if (onSubmit) onSubmit(value);
  }, [onSubmit]);

  let validationClass = '';
  let validationIcon = '';
  let validationText = '';
  if (errorMessage) {
    validationClass = 'error';
    validationIcon = 'ico-alert';
    validationText = errorMessage;
  } else if (successMessage) {
    validationClass = 'success';
    validationIcon = 'ico-checkmark-circle';
    validationText = successMessage;
  }

  return (
    <div
      data-testid="textarea-container"
      className="textarea-container"
    >
      <TextArea
        id="user-detail-pgpkey-textarea"
        label={t('msg_codes_PGPKey')}
        tooltip={t('msg_codes_banner_ECCKeysNotSupported')}
        placeholder={t('msg_codes_cta_addPGPKey')}
        onChange={handleChange}
        value={value}
        charCountMax={15000}
      />
      {validationText && (
        <span className={`pgp-validation ${validationClass}`}>
          <Icon size="icon--small">{validationIcon}</Icon>
          <div className="validation-text">{validationText}</div>
        </span>
      )}

      {(toolbarItems || showSubmit || showCancel) && (
        <div className="toolbar">
          <div className="section">{toolbarItems}</div>

          {(showCancel || showSubmit) && (
            <div data-testid="textarea-buttons" className="section right">
              {showCancel && (
                <Button disabled={cancelDisabled} onClick={handleCancel}>{cancelLabel}</Button>
              )}
              <div className="spacer" />
              {showSubmit && (
                <Button id="submit-button" primary disabled={submitDisabled} onClick={handleSubmit}>
                  {submitLabel}
                </Button>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

UserDetailTextArea.propTypes = {
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  toolbarItems: PropTypes.element,
  defaultValue: PropTypes.string,
  errorMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  successMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  submitLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  disabled: PropTypes.bool,
  submitDisabled: PropTypes.bool,
  cancelDisabled: PropTypes.bool,
  showSubmit: PropTypes.bool,
  showCancel: PropTypes.bool,
  focus: PropTypes.bool,
};

UserDetailTextArea.defaultProps = {
  onChange: () => { },
  onCancel: null,
  onSubmit: null,
  toolbarItems: null,
  defaultValue: '',
  errorMessage: '',
  successMessage: '',
  submitLabel: 'Submit',
  cancelLabel: 'Cancel',
  disabled: false,
  submitDisabled: false,
  cancelDisabled: false,
  showSubmit: false,
  showCancel: false,
  focus: false,
};

export default UserDetailTextArea;
