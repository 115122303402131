const accountTypes = [
  {
    label: 'SONY',
    value: 'SONY',
  },
  {
    label: 'PARTNER',
    value: 'PARTNER',
  },
];

const roles = [
  {
    label: 'ADMIN',
    value: 'ADMIN',
  },
  {
    label: 'AGENCY ADMIN',
    value: 'AGENCY_ADMIN',
  },
  {
    label: 'SUPER ADMIN',
    value: 'SUPER_ADMIN',
  },
  {
    label: 'APPROVER',
    value: 'APPROVER',
  },
  {
    label: 'REVIEWER',
    value: 'REVIEWER',
  },
  {
    label: 'NONE',
    value: 'NONE',
  },
  {
    label: 'PARTNER',
    value: 'PARTNER',
  },
];

const statuses = [
  {
    label: 'ACTIVE',
    value: 'ACTIVE',
  },
  {
    label: 'INACTIVE',
    value: 'INACTIVE',
  },
];

export default {
  accountTypes,
  roles,
  statuses,
};
