import map from 'lodash/map';
import i18n from 'i18next';
import * as CodeProductTypes from './codeProductTypes.constants';
import * as AgreementTypes from './agreementType.constants';
import * as SieRegions from './contractingSieRegion.constants';

// TODO: Refactor uses of optionsTranslationCreator to remove
//  "t" parameter since it has been removed and replaced
//  with i18n in this function to translate strings.
export const optionsTranslationCreator = (options) => {
  return options.reduce((acc, opt) => {
    return [
      ...acc,
      {
        label: i18n.t(opt.label),
        value: opt.value,
        attr: {
          'data-testid': opt.value,
        },
      },
    ];
  }, []);
};

export const productTypeOptions = [
  { value: 'GAME.ADD_ON_SERVICE_ENTITLEMENT', label: 'msg_codes_productType_addOnService' },
  { value: 'GAME.ADD_ON_UNIFIED_ENTITLEMENT', label: 'msg_codes_productType_addOnUnified' },
  { value: 'GAME.APPLICATION', label: 'msg_codes_productType_application' },
  { value: 'GAME.AVATAR', label: 'msg_codes_productType_avatar' },
  { value: 'GAME.BUNDLE', label: 'msg_codes_productType_bundle' },
  { value: 'GAME.CREATOR_PACK', label: 'msg_codes_productType_creatorPack' },
  { value: 'GAME.DEMO', label: 'msg_codes_productType_demo' },
  { value: 'GAME.FALLBACK', label: 'msg_codes_productType_fallback' },
  { value: 'GAME.FULL_GAME', label: 'msg_codes_productType_fullGame' },
  { value: 'GAME.SHAREFACTORY_THEME', label: 'msg_codes_productType_sharefactoryTheme' },
  { value: 'GAME.SUBSCRIPTION', label: 'msg_codes_productType_subscription' },
  { value: 'GAME.THEME', label: 'msg_codes_productType_theme' },
];

export const platformOptions = [
  { value: 'PS5', label: 'msg_codes_platform_ps5' },
  { value: 'PS4', label: 'msg_codes_platform_ps4' },
];

export const registrationRegionOptions = [
  { value: 'SIEA', label: 'msg_codes_sieRegion_siea' },
  { value: 'SIEE', label: 'msg_codes_sieRegion_siee' },
  { value: 'SIEH', label: 'msg_codes_sieRegion_siejaAsia' },
  { value: 'SIEJ', label: 'msg_codes_sieRegion_siejaJapan' },
];

export const availabilityRegionOptions = [
  { value: 'SIEA', label: 'msg_codes_sieRegion_siea' },
  { value: 'SIEE', label: 'msg_codes_sieRegion_siee' },
  { value: 'SIEH', label: 'msg_codes_sieRegion_siejaAsia' },
  { value: 'SIEJ', label: 'msg_codes_sieRegion_siejaJapan' },
];

export const versionOptions = [
  { value: 'FULL', label: 'msg_codes_version_full' },
  { value: 'TRIAL', label: 'msg_codes_version_trial' },
];

export const agreementTypeOptions = [
  { value: 'Agency', label: 'msg_codes_agreementType_agency' },
  { value: '1st Party Publisher - Permitted Uses', label: 'msg_codes_agreementType_firstPartyPublisher' },
  { value: '3rd Party Publisher - Free Allocation', label: 'msg_codes_agreementType_thirdPartyFreeAllocation' },
  { value: '3rd Party Publisher - Permitted Uses', label: 'msg_codes_agreementType_thirdPartyPublisher' },
];

export const voucherTypeOptions = [
  { value: 'PSNOW', label: 'msg_codes_voucherType_psNow_lowerCase' },
  { value: 'PSPLUS', label: 'msg_codes_voucherType_psPlus_lowerCase' },
  { value: 'MONEY', label: 'msg_codes_voucherType_money_lowerCase' },
  { value: 'THIRDPARTY', label: 'msg_codes_voucherType_product_lowerCase' },
];

export const catalogTypeOptions = [
  { value: 'all', label: 'msg_codes_filter_all' },
  { value: 'PSNOW', label: 'msg_codes_voucherType_psNow_lowerCase' },
  { value: 'PSPLUS', label: 'msg_codes_voucherType_psPlus_lowerCase' },
  { value: 'MONEY', label: 'msg_codes_voucherType_money_lowerCase' },
];


export const voucherTypesOptions = [
  { value: 'all', label: 'msg_codes_filter_all' },
  { value: 'MONEY', label: 'msg_codes_voucherType_money_lowerCase' },
  { value: 'PRODUCT', label: 'msg_codes_voucherType_product_lowerCase' },
];


export const voucherTypesRegionOptions = [
  { value: 'SCEA', label: 'SCEA' },
  { value: 'SCEE', label: 'SCEE' },
  { value: 'SCEH', label: 'SCEH' },
  { value: 'SCEJ', label: 'SCEJ' },
  { value: 'SCEK', label: 'SCEK' },
];

export const activityTypesOptions = [
  { value: 'RETAIL_PROMOTION', label: 'msg_codes_inboxPreorderPromotion' },
  { value: 'CONSUMER_PROMOTION', label: 'msg_codes_assignments_consumerPromo' },
  { value: 'BETA_TESTING', label: 'msg_code_assignments_betaTesting' },
  { value: 'PUBLISHER_FREE_ALLOCATION', label: 'msg_codes_orderDetailsAdmin_activity_pubFreeAllo' },
  { value: 'PUBLISHER_PAID_ALLOCATION', label: 'msg_codes_publisherPaidAllo' },
  { value: 'DISC_SUBSTITUTE', label: 'msg_codes_orderDetailsAdmin_activity_discSubstitute' },
];

export const globalRateCardOrderStatusOptions = [
  { value: 'REJECTED', label: 'msg_codes_orderlist_rejected' },
  { value: 'CANCELLED', label: 'msg_codes_canceled' },
  { value: 'PROCESSING', label: 'msg_code_orders_processing_label' },
  { value: 'DOWNLOAD', label: 'msg_codes_cta_download' },
  { value: 'REDOWNLOAD', label: 'msg_codes_cta_redownload' },
  { value: 'ERROR', label: 'msg_codes_error' },
  { value: 'PENDING_DRAFT_AMENDMENT', label: 'msg_codes_orders_updateReq' },
];

export const codeProductTypeOptions = map(
  CodeProductTypes,
  type => ({ value: type.code, label: type.name }),
);

export const agreementFilterTypeOptions = [
  ...map(AgreementTypes, (type, key) => ({ label: type, value: key })),
];

export const loadTypeOptions = [
  { label: 'msg_codes_standard', value: 'STANDARD' },
  { label: 'msg_codes_variable', value: 'VARIABLE' },
];

export const regionOptions = map(
  SieRegions,
  region => ({ value: region.code, label: region.name }),
);

export const voucherBatchPurposeOptions = [
  { value: 'Internal', label: 'msg_codes_orderPurpose_internal' },
  { value: 'Marketing', label: 'msg_codes_marketing_purpose' },
  { value: 'Media', label: 'msg_codes_media' },
  { value: 'POSA', label: 'msg_codes_posa_point_of_sale' },
  { value: 'Retailer', label: 'msg_codes_retailer' },
  { value: 'Test', label: 'msg_codes_test' },
  { value: 'Other', label: 'msg_codes_other' },
  { value: 'Marketing - Pack-In', label: 'msg_codes_marketing_packin' },
  { value: 'Live', label: 'msg_codes_live' },
];

export const voucherBatchStatusOptions = [
  { value: 'Active', label: 'msg_codes_active' },
  { value: 'Inactive', label: 'msg_codes_agreementStatus_inactive' },
  { value: 'Deactivated', label: 'msg_codes_codes_status_deactivated' },
];

export const agreementStatusOptions = [
  { value: 'ACTIVE', label: 'msg_codes_agreementStatus_active' },
  { value: 'DRAFT', label: 'msg_codes_agreementStatus_draft' },
  { value: 'INACTIVE', label: 'msg_codes_agreementStatus_inactive' },
  { value: 'SUSPENDED', label: 'msg_codes_agreementStatus_suspended' },
];
