import * as UserStatus from '../constants/userStatus.constants';

export default {
  app: {},
  appConfig: [],
  numAjaxCallsInProgress: 0,
  agreements: [],
  voucher: {},
  vouchers: [],
  thirdPartyCatalogPage: {
    content: [],
    filters: {},
    countries: [],
    types: [],
    search: '',
    accessDenied: false,
    selectedPartner: '',
  },
  loadingThirdPartyCatalog: false,
  voucherTypes: {
    agencyVoucherTypes: [],
    firstPartyPublisherVoucherTypes: [],
    allVoucherTypes: [],
  },
  voucherTypeCatalogs: [],
  voucherTypesPage: {
    filters: {
      npType: '',
      npRegion: [],
    },
    search: '',
    page: {
      size: 50,
      totalElements: 0,
      totalPages: 0,
      number: 0,
      sort: 'name',
      sortOrder: 'asc',
    },
  },
  voucherBatchCatalogs: [],
  voucherBatchesPage: {
    filters: {
    },
    search: '',
    page: {
      size: 50,
      totalElements: 0,
      totalPages: 0,
      number: 0,
      sort: 'modifyDate',
      sortOrder: 'desc',
    },
  },
  loadingVoucherBatches: false,
  loadingSelectVoucherBatches: false,
  loadingVoucherBatch: false,
  loadingVoucherBatchAuditHistory: false,
  voucherBatch: {
    voucherBatch: {
      voucherBatchId: '',
      controlLabel: '',
      voucherTypeId: '',
      voucherPurpose: '',
      voucherPurposeId: '',
      voucherStatus: '',
      state: '',
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
      modifyDate: '',
      distributor: '',
      batchDescription: '',
      quantity: '',
      creationDate: '',
      createdByName: '',
      useCount: '1',
      voucherStatusId: 3,
      distributorId: '',
      activeQuantity: 0,
      inactiveQuantity: 0,
      cancelledQuantity: 0,
      downloadUrl: '',
    },
  },
  voucherBatchAuditHistory: {
    voucherBatchId: '',
    content: [],
  },
  loadingVouchers: false,
  loadingVoucherTypes: false,
  loadingVoucherType: false,
  loadingSkus: false,
  skus: [],
  selectedSkus: [],
  addSkuPage: {
    filters: {
      contentType: [],
      skuType: [],
      country: [],
    },
    search: '',
    page: {
      size: 50,
      totalElements: 0,
      totalPages: 0,
      number: 0,
      sort: 'lastUpdatedDate',
      sortOrder: 'desc',
    },
  },
  voucherType: {
    voucherType: {
      id: '',
      name: '',
      npType: '',
      dateModified: '',
      npRegion: '',
      country: null,
      skus: [],
    },
  },
  voucherTypeBatchPage: {
    page: {
      size: 50,
      totalElements: 0,
      totalPages: 0,
      number: 0,
      sort: 'modifyDate',
      sortOrder: 'desc',
    },
  },
  orders: [],
  cart: { },
  showModal: false,
  placingOrder: false,
  loadingCart: false,
  loadingOrders: false,
  savingOrder: false,
  approvingOrder: false,
  addingItem: false,
  removingItem: false,
  updatingItem: false,
  submittingOrder: false,
  rejectingOrder: false,
  requestingAmendment: false,
  partners: [],
  userAuthenticated: false,
  regions: {},
  voucherProductTypeCoverageSet: {},
  firstPartyAgreementItems: [],
  agreementsPage: {
    navigation: {},
    filters: {
      partnerId: [],
      region: [],
      agreementType: [],
      status: [],
      country: [],
      voucherProductType: [],
    },
    isFiltering: false,
    search: '',
    page: {
      size: 50,
      totalElements: 0,
      totalPages: 0,
      number: 0,
    },
    number: 0,
  },
  partnersPage: {
    loadingPartners: false,
    page: {
      number: 1,
      size: 50,
      totalElements: 10,
      totalPages: 1,
    },
    filters: {
      status: [],
      createdDateStart: [],
      createdDateEnd: [],
    },
    isFiltering: false,
  },
  agreementForm: {
    isNewAgreement: false,
    isNewVersion: false,
    canEditVersion: true,
    loaded: false,
    agreementVersionId: 0,
    isValid: false,
    latestFinalEffectiveDate: '',
    isSaving: false,
    eTag: undefined,
    agreement: {
      agreementId: '',
      agreementVersions: [{
        voucherRedemptionCallbackGateway: '',
        voucherRedemptionCallbackMandatory: null,
        voucherProductTypeCoverageSet: [],
      }],
      status: 'DRAFT',
      region: '',
      agreementType: '',
      partnerId: '',
    },
    draftAgreementVersion: {
      agreementVersionId: '',
      agreementVersionEffectiveDate: '',
      versionStatus: 'DRAFT',
      agreementVersionComments: '',
      voucherProductTypeCoverageSet: [],
      agreementType: '',
      invoiceTiming: null,
      voucherRedemptionCallbackGateway: '',
      voucherRedemptionCallbackMandatory: null,
    },
    initialStatuses: {
      status: 'DRAFT',
      versionStatus: 'DRAFT',
    },
    hasBeenModified: {
      agreement: false,
      version: false,
      voucherProductType: false,
    },
    removedCountries: false,
    orderLines: [],
  },
  currencies: [],
  countries: [],
  order: {
    orderLines: [],
    region: '',
    orderReference: '',
    poNumber: '',
    orderComments: '',
    partnerId: '',
    orderHistories: [],
    additionalRedemptionCheckRequired: null,
    partnerSftpId: null,
    auditFields: {},
    freightCost: '0.00',
    freightCostTaxPercentage: '0.00',
    freightCostTax: '0.00',
    totalManufactureFreightCostAndAdditionalCosts: '0.00',
    cardManufacturePriceCurrency: null,
    totalTax: '0.00',
    additionalCosts: '0.00',
  },
  orderForm: {
    activeAgreementVersion: {
      voucherRedemptionCallback: '',
      voucherRedemptionCallbackMandatory: null,
    },
  },
  partner: {
    partner: {
      name: '',
      status: '',
      initialName: '',
      initialStatus: '',
    },
    partnerSftpMapList: [],
  },
  loadingPartner: false,
  userList: {
    users: [],
    filters: {
      roleCode: '',
      accountType: '',
      creationDate: '',
      status: '',
    },
    search: '',
    page: {
      size: 50,
      totalElements: 0,
      totalPages: 0,
      number: 0,
      sort: 'name',
      sortOrder: 'asc',
    },
  },
  usersPage: {
    users: [],
    page: {
      number: 1,
      size: 50,
      totalElements: 10,
      totalPages: 1,
    },
  },
  userPage: {
    searchUserForm: {
      email: '',
      isValidEmailAddress: false,
    },
    userDetailForm: {
      email: '',
      firstName: '',
      lastName: '',
      initialStatus: '',
      regions: [],
      partner: { partnerId: '', name: '' },
    },
    newUserForm: {
      email: '',
      firstName: '',
      lastName: '',
      localeCode: '',
      status: UserStatus.ACTIVE,
      regions: [],
      role: '',
      partner: {
        partnerId: '',
        name: '',
      },
    },
    editUserForm: {},
    loadingUser: false,
    unsaved: false,
    changesMade: false,
    isSaving: false,
    userFoundIn: '',
    externalStatus: '',
  },
  loadingUsers: false,
  userLoaded: false,
  sftp: {},
  sftpList: [],
  sftpsPage: {
    page: {
      number: 1,
      size: 10,
      totalElements: 10,
      totalPages: 1,
    },
  },
  loadingSftps: false,
  loadingAgreements: false,
  pendingLines: [],
  user: {
    activeRegions: [],
    viewedPGPSetupPage: false,
    hasCanceledPGPSetup: false,
    mustSetupEncryptionKey: false,
  },
  pgpKey: {
    key: '',
    error: false,
    loading: false,
    validating: false,
    validated: false,
    validationError: false,
    hasKey: false,
    needsValidating: false,
    downloading: false,
    downloadFailed: false,
  },
  callbackGateways: {
    isLoaded: false,
    callbackGateways: {},
  },
  removedOrderLines: new Set(),
  loadingOrder: false,
  loadingCodeProducts: false,
  codeProductsCatalog: [],
  codeProductsPage: {
    page: {
      size: 50,
      totalElements: 0,
      totalPages: 0,
      number: 0,
    },
  },
  codeProduct: {
    changesMade: false,
    codeProductType: '',
    // default to space due to uncontrolled input error
    codeProductName: ' ',
    subscriptionType: '',
    codeProductDescription: '',
    status: 'ACTIVE',
    region: '',
    isKorea: undefined,
    country: '',
    countries: [],
    applicableAgreementTypes: [],
    exclusiveToPartner: [],
    exclusiveToPartnerMap: [],
    faceValue: '',
    useCount: '',
    impactedOrderlines: [],
    applicableAgreementTypesMap: [],
    existsForConfiguration: {},
  },
  orderActivityRules: {},
  orderHistory: {
    loadingOrderHistory: false,
    orderHistoryCatalog: [],
  },
  loading: false,
  cronJobs: {
    loadingVenomCronJobs: false,
    venomCronJobs: [],
    loadingVIDSCronJobs: false,
    vidsCronJobs: [],
  },
  tracking: {
    component: [{ // TO BE USED FOR FORCING PAGE REQUESTS ON SPAs
      componentInfo: {
        pageFlag: 'true', // This attribute is for SPAs and should be set to "true" each time there is a change of state leading to a new page. A new timestamp must be set on each push.
      },
    }],
    pageInstanceID: 'pspartners:venom-1.2:JS-2.9:empty', // Collects the Build id associated with the page i.e. c/v 74. Format is <PROPERTY_NAME>:<APP_VERSION>:<DTM_VERSION>:<SDK_VERSION>
    page: { // TO BE USED FOR CAPTURING PAGE DATA
      pageInfo: {
        pageName: 'pspartners:voucher:login', // This will collect the pageName value dynamically e.g "pspartners:voucher:homepage", "pspartners:voucher:orders"
        destinationURL: 'https://venom.e1-np.playstation.net/orders', // This will collect the Page URL
        sysEnv: 'web', // This will collect the Device Type associated with the application i.e web, mpobile, console. Logic can be provided to help determine this.
        version: 'e1-qa', // This represents the applications environment i.e. dev, sandbox1, qa, prod etc. In AA this populates c/v73
        onsiteSearchTerm: 'CE-321456', // This collects the search term entered - free text (when applicable)
        onsiteSearchResults: '1', //  This collects the number of search results (when applicable)
      },
      category: {
        pageType: 'login', // This will collect the type of page e.g. "home", "detail", "login", "search-results"
      },
      user: { // TO BE USED FOR CAPTURING ACCOUNT INFORMATION
        profile: {
          profileInfo: {
            profileID: '[OKTA ID]', // This will collect the User Account ID (Okta ID) i.e. Visitor ID & Partner Account ID cv 75
            language: 'gb-en', // This will collect the language code. For example, "gb-en", "us-en", fr-fr". Use ISO3166-2 & ISO639 format
          },
          attributes: {
            gpid: '', // This will collect the Company ID i.e. Partner: GPID cv70
            companyRegion: '', // This will collect the Company Region i.e. Partner: GPID cv70
            companyName: '', // This will collect the Company name i.e. Partner: GPID cv70
          },
        },
      },
      error: [{ // TO BE USED FOR CAPTURING BOTH SYSTEM AND USER TYPE ERRORS
        errorInfo: {
          errorName: 'Password incorrect', //
          typeOfError: 'user', // Type of error i.e. "system" or "user"
        },
        attributes: {
          errorAPIURL: '', // The API URL associated with the error | "empty"
          errorLocation: '', // The location where the error occurred e.g. page URL  | "empty"
          errorSeverity: '[enum]', // the severity of the error | "empty"
          errorCode: '', // The code associated with the error e.g. "404", "502" | "empty"
          errorDescription: 'Please enter a password', // The description associated with the error message | "empty"
        },
      }],
    },
    event: [{ // TO BE USED FOR CAPTURING USER ENGAGEMENT. Applies to both RP/Apps and GUI
      eventInfo: {
        eventName: '', // This will collect the name of the Event e.g. "sign out click", "search click", "header click", "footer click"
        eventAction: '', // This collects the action performed. Most of the time the value is "click". Other values could be "impression" or "search"
      },
      attributes: {
        assetName: '', // This collects the name of an asset associated with an action. E.g.  "ps logo", "partner-main-image" | "empty"
        moduleName: '', // This collects the html location of the click i.e. "top-nav", "sub-nav", "main-search". For GUI this is "header", "footer"
        position: '', // This collects the position of an element in a module e.g. "1", "2", "3" | "empty"
        elementType: '', // This indicated the type of element interacted with e.g. "button", "link", "image"
        label: '', // This collects the text label associated with an element e.g. "activate", "cancel", "filter"
        linkURL: '', // Link HREF if available | "empty"
        userActionFlag: '', // Set to "true" most of the time, as a general User Engagement type. In the rare occurrence where an action requires additional tagging (e.g. banner click, video click) set to “false”.
      },
    }],
  },
};
