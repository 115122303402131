import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function loadUserReducer(state = initialState.userLoaded, action) {
  if (action.type === types.BEGIN_LOAD_USER) {
    return false;
  }
  if (action.type === types.LOAD_USER_SUCCESS) {
    return true;
  }

  return state;
}
