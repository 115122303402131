import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch, useStore } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Kabob,
  Modal,
  ModalContext,
  MODAL_ACTIONS,
} from '@partner-global-ui/components';
import {
  activateItem,
  cancelItem,
  retryOrderLine,
  deliverOrderLine,
} from '../../../actions/lineActions';

function OrderLineTooltip({
  order,
  orderLine,
  disabled,
  canDownload,
  canRetry,
  canCancel,
}) {
  const {
    npVoucherBatchStatus,
    orderLineId,
    userVoucherDeliveryStatus,
  } = orderLine;
  const store = useStore();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modalContext = useContext(ModalContext);
  const user = useSelector(state => state.user);

  const download = () => {
    deliverOrderLine(orderLineId, dispatch)
      .then((res) => {
        if (
          typeof res.downloadUrl !== 'undefined'
            && res.downloadUrl !== null
            && res.downloadUrl.length > 0
        ) {
          window.open(res.downloadUrl);
        }
      });
  };

  const retry = () => {
    if (npVoucherBatchStatus === 'CREATION_ERROR') {
      retryOrderLine(orderLineId, dispatch, store.getState, order.orderNumber);
    } else {
      activateItem(order, orderLine, dispatch);
    }
  };

  const hideCancelModal = () => {
    modalContext.dispatch({ type: MODAL_ACTIONS.HIDE });
  };

  const cancel = () => {
    cancelItem(order, orderLine, dispatch);
    hideCancelModal();
  };

  const showCancelModal = () => {
    const modalProps = {
      id: '3p-permitted-uses-cancel-modal',
      name: '3pPermittedUsesCancelModal',
      title: t('msg_codes_cta_cancel'),
      content: user.roleCode === 'SUPER_ADMIN' || user.roleCode === 'ADMIN'
        ? t('msg_codes_cancel_order_line_item')
        : t('msg_codes_cancel_order_line_item'),
      primaryLabel: t('msg_codes_common_yes'),
      secondaryLabel: t('msg_codes_cta_no'),
      onPrimary: cancel,
      onSecondary: hideCancelModal,
      onClose: hideCancelModal,
      hideCancelButton: true,
    };

    modalContext.dispatch({
      type: MODAL_ACTIONS.SHOW,
      payload: <Modal {...modalProps} />,
    });
  };

  const menuItems = [
    ...(canDownload ? [{
      label: userVoucherDeliveryStatus === 'DELIVERED'
        ? t('msg_codes_cta_redownload')
        : t('msg_codes_cta_download'),
      onClick: download,
      icon: 'ico-download',
      id: 'download',
    }] : []),
    ...(canRetry ? [{
      label: t('msg_codes_orderDetails_retry'),
      onClick: retry,
      icon: 'autorenew',
      id: 'retry',
    }] : []),
    ...(canCancel ? [{
      label: t('msg_codes_cta_cancel'),
      onClick: showCancelModal,
      icon: 'ico-remove_cancel',
      id: 'cancel',
    }] : []),
  ];

  const { processingOrderLine } = orderLine;
  const hideKabob = !processingOrderLine && (disabled || (!canDownload && !canRetry && !canCancel));

  return !hideKabob && <Kabob menuItems={menuItems} disabled={processingOrderLine} />;
}

OrderLineTooltip.propTypes = {
  order: PropTypes.object,
  orderLine: PropTypes.object,
  disabled: PropTypes.bool,
  canDownload: PropTypes.bool,
  canRetry: PropTypes.bool,
  canCancel: PropTypes.bool,
};

OrderLineTooltip.defaultProps = {
  order: {},
  orderLine: {},
  disabled: false,
  canDownload: false,
  canRetry: false,
  canCancel: false,
};

export default OrderLineTooltip;
