import React, {
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from '@partner-global-ui/components';
import _ from 'lodash';
import OrderInfo from './OrderInfo';
import OrderContents from './OrderContents';
import Nav from './Nav';
import OrderHistoryNew from '../OrderHistory/OrderHistoryNew';
import pollingInterval from '../../../api/pollingInterval';
import { getOrderLineStatus, getOrderLineStatusSuccess } from '../../../actions/pollingActions';
import './permittedUsesOrder.scss';

function OrderPage() {
  const dispatch = useDispatch();
  const order = useSelector(state => state.order);
  const [areChangesMade, setAreChangesMade] = useState(false);
  const [hasOrderFeeActivity, setHasOrderFeeActivity] = useState(false);

  // Store original contents of editable fields to compare to to know
  //  if changes have been made to the order that should be saved.
  const [originalOrderValues, setOriginalOrderValues] = useState({
    startDate: order.orderLines[0].startDate,
    endDate: order.orderLines[0].endDate,
    applyFeeWaiver: order.applyFeeWaiver,
  });
  // State variable to track if order has any errors
  const [hasError, setHasError] = useState(false);
  // State variable to track changes made to order
  const [updatedOrderValues, setUpdatedOrderValues] = useState(originalOrderValues);

  const updateOriginalOrderValues = updatedOrder => setOriginalOrderValues({
    startDate: updatedOrder.orderLines[0].startDate,
    endDate: updatedOrder.orderLines[0].endDate,
    applyFeeWaiver: updatedOrder.applyFeeWaiver,
    feeWaiverReason: updatedOrder.feeWaiverReason,
  });

  // When an order is saved, the original order values are set to the new values
  //  received from the API call. When the original order is updated, also update
  //  the updated order values to match the "new" original order so save compare
  //  works as expected.
  useEffect(() => {
    setUpdatedOrderValues(originalOrderValues);
  }, [originalOrderValues]);

  // Determine if changes to the order have been made.
  //  This impacts polling and the save button
  useEffect(() => {
    if (_.isEqual(updatedOrderValues, originalOrderValues)) {
      setAreChangesMade(false);
    } else {
      setAreChangesMade(true);
    }
  }, [updatedOrderValues, originalOrderValues]);

  /* handle order polling */
  useEffect(() => {
    let intervalId;

    /* initiate polling when line is processing */
    if (order.orderLines[0].processingOrderLine) {
      intervalId = setInterval(() => {
        dispatch(getOrderLineStatus(
          [order.orderLines[0].orderLineId],
          [order.orderNumber],
          getOrderLineStatusSuccess,
        ));
      }, pollingInterval);
    }

    /* clear interval when component unmounts */
    return () => clearInterval(intervalId);
  }, [dispatch, areChangesMade, order.orderLines, order.orderNumber]);

  return (
    <>
      <Nav
        order={order}
        updatedOrderValues={updatedOrderValues}
        hasError={hasError}
        hasOrderFeeActivity={hasOrderFeeActivity}
        areChangesMade={areChangesMade}
        updateOriginalOrderValues={updateOriginalOrderValues}
      />
      <Container className="order permitted-uses-order">
        <OrderInfo
          order={order}
          updatedOrderValues={updatedOrderValues}
          setUpdatedOrderValues={setUpdatedOrderValues}
          setHasOrderFeeActivity={setHasOrderFeeActivity}
        />
        <OrderContents
          order={order}
          setHasError={setHasError}
          updatedOrderValues={updatedOrderValues}
          setUpdatedOrderValues={setUpdatedOrderValues}
        />
        <OrderHistoryNew name="orderHistory" order={order} />
      </Container>
    </>
  );
}

export default OrderPage;
