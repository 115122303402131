export default [
  {
    id: 'Agreement ID',
    value: 'agreementId',
    sortable: false,
    translationStringId: 'msg_codes_agreements_agreementID',
  },
  {
    id: 'Contracting SIE Region',
    value: 'region',
    sortable: true,
    translationStringId: 'msg_codes_agreements_contractingSIEregion',
  },
  {
    id: 'Agreement Type',
    value: 'agreementType',
    sortable: true,
    translationStringId: 'msg_codes_agreements_agreementType',
  },
  {
    id: 'Country Coverage',
    value: 'countryCoverage',
    sortable: false,
    translationStringId: 'msg_codes_agreements_countryCoverage',
  },
  {
    id: 'Voucher Product Type Coverage',
    value: 'voucherProductTypeCoverage',
    sortable: false,
    translationStringId: 'msg_codes_agreements_codeProductTypeCoverage',
  },
  {
    id: 'Agreement Status',
    value: 'status',
    sortable: true,
    translationStringId: 'msg_codes_agreements_agreementStatus_label',
  },
];
