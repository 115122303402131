import React, { useState, useContext } from 'react';
import {
  TableRow,
  TableCell,
  Truncate,
  Icon,
  iconSizes,
  NotificationContext,
} from '@partner-global-ui/components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { uniqBy } from 'lodash';
import SkuInfoSubRows from './SkuInfoSubRows';
import copyToClipboard from '../../../helpers/clipboard';

export const showAvailability = (skuAvailabilities = [], t) => {
  const uniqueSkuAvailabilities = uniqBy(skuAvailabilities, 'country.code');
  const childrenCount = uniqueSkuAvailabilities.length;
  if (uniqueSkuAvailabilities.length === 1) {
    return uniqueSkuAvailabilities[0].country.name;
  }
  if (uniqueSkuAvailabilities === 0) {
    return t('msg_codes_codes_status_notAvailable');
  }
  return `${t('msg_codes_agreementType_multiple')}  (${childrenCount})`;
};

export const SkuInfoRow = (props) => {
  const { t } = useTranslation();
  const notificationContext = useContext(NotificationContext);

  const {
    sku, deleteSku, canRemove, tableRef,
  } = props;
  const {
    skuId, status = '', skuAvailabilities, urlToScpPar,
  } = sku;


  const statusCodes = {
    active: t('msg_codes_agreementStatus_active'),
    inactive: t('msg_codes_agreementStatus_inactive'),
  };

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpandState = () => {
    setIsExpanded(!isExpanded);
  };

  const renderRemoveButton = () => {
    if (!canRemove) {
      return null;
    }
    return (
      <div
        id="remove-wrap"
        data-testid="remove-wrap"
        type="button"
        role="button"
        tabIndex={0}
        className="remove-icon"
        onClick={() => deleteSku(skuId)}
        onKeyDown={() => {}}
      >
        <Icon id="trash-icon" className="remove-icon" size={iconSizes.Large}>
          ico-trashcan
        </Icon>
        <span
          id="remove-text"
          data-testid="remove-text"
          className="remove-text"
        >
          {t('msg_codes_cta_remove')}
        </span>
      </div>
    );
  };

  const renderId = () => {
    const content = urlToScpPar ? (
      <a
        href={urlToScpPar}
        target="_blank"
        rel="noopener noreferrer"
        className="sku-info-table-sku-id-link"
      >
        {skuId}
      </a>
    ) : skuId;

    return (
      <Truncate
        id="skuId-truncate"
        content={skuId}
        height={20}
        anchor={tableRef}
      >
        {content}
        <span
          onKeyDown={copyToClipboard(skuId, notificationContext, 'msg_codes_skuid_copied_confirmation')}
          tabIndex={0}
          role="button"
          onClick={copyToClipboard(skuId, notificationContext, 'msg_codes_skuid_copied_confirmation')}
        >
          <Icon id="copy-icon">ico-copy</Icon>
        </span>
      </Truncate>
    );
  };


  return (
    <TableRow
      pad
      className="sku-info-table-TableRow parent"
      id={`sku-info-table-TableRow-${skuId}`}
      key={skuId}
      onClick={toggleExpandState}
      expanded={isExpanded}
      expandable
      expandedContent={<SkuInfoSubRows skuAvailabilities={skuAvailabilities} />}
    >
      <TableCell sm={4} className="sku-info-table-sku-id">
        {renderId()}
      </TableCell>
      <TableCell id="sku-info-table-country" className="sku-info-table-country">
        { showAvailability(skuAvailabilities, t)}
      </TableCell>
      <TableCell id="sku-info-table-status" className="sku-info-table-status">
        {statusCodes[status]}
      </TableCell>
      <TableCell
        id="sku-info-table-actions"
        className="sku-info-table-actions actions-column"
      >
        {renderRemoveButton()}
      </TableCell>
    </TableRow>
  );
};

SkuInfoRow.propTypes = {
  sku: PropTypes.object,
  deleteSku: PropTypes.func,
  canRemove: PropTypes.bool,
  tableRef: PropTypes.object,
};

SkuInfoRow.defaultProps = {
  sku: {},
  deleteSku: () => null,
  canRemove: false,
  tableRef: {},
};

export default SkuInfoRow;
