export default function generateCountriesCoverage(
  countries, voucherProductType, region, currencies,
) {
  return countries
    .filter(country => country.region.code === region)
    .map((country) => {
      const matchingCurrency = currencies.find((currency) => {
        return currency.code === country.currency.code;
      });
      return {
        ...country,
        country: country.code,
        feeStructureType: voucherProductType,
        settlementCurrency: country.currency.code,
        commissionRate: 0,
        currencyStatus: matchingCurrency ? matchingCurrency.status : 'INACTIVE',
      };
    });
}
