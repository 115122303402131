import axios from 'axios';
import * as types from './actionTypes';

export function beginLoadVoucherTypes() {
  return { type: types.BEGIN_LOAD_VOUCHER_TYPES };
}

export function finishLoadVoucherTypes(voucherTypeCatalogs) {
  return { type: types.FINISH_LOAD_VOUCHER_TYPES, voucherTypeCatalogs };
}

export function setVoucherTypesPage(page) {
  return { type: types.SET_VOUCHER_TYPES_PAGE, page };
}

export function setVoucherTypeAction(voucherType) {
  return { type: types.SET_VOUCHER_TYPE, voucherType };
}

export function resetFilters() {
  return { type: types.RESET_VOUCHER_TYPES_FILTERING };
}

export function setFilters(filterName, filterValue) {
  return {
    type: types.SET_VOUCHER_TYPES_FILTER,
    filterName,
    filterValue,
  };
}

export function setVoucherTypesSearch(search) {
  return { type: types.SET_VOUCHER_TYPES_SEARCH, search };
}

export function clearVoucherTypesSearch() {
  return { type: types.CLEAR_VOUCHER_TYPES_SEARCH };
}

export function resetVoucherTypesPage() {
  return { type: types.RESET_VOUCHER_TYPES_PAGE };
}

export function loadVoucherTypes(
  size = 50,
  page = 0,
  sort = { name: 'name', order: 'asc' },
  filters = {
    npType: '',
    npRegion: '',
    search: '',
  },
) {
  const sortParam = `?sort=${sort.name},${sort.order}`;
  const pageParam = `page=${page}`;
  const sizeParam = `size=${size}`;
  const npTypeParam = filters.npType.value ? `&npType=${filters.npType.value}` : '';
  const npRegionParam = filters.npRegion ? filters.npRegion.map(type => `&npRegion=${type.value}`).join('') : '';
  const searchParam = filters.search ? `&search=${filters.search}` : '';
  const filtersParams = `${npTypeParam}${npRegionParam}${searchParam}`;

  const url = `/venom/api/voucher-types${sortParam}&${pageParam}&${sizeParam}&${filtersParams}`;
  const { name: sortName, order: sortOrder } = sort;
  return (dispatch) => {
    dispatch(beginLoadVoucherTypes());
    return axios.get(url)
      .then((response) => {
        return response.data;
      }).then((voucherTypes) => {
        dispatch(finishLoadVoucherTypes(voucherTypes.content));
        dispatch(setVoucherTypesPage({
          number: voucherTypes.number + 1,
          size: voucherTypes.size,
          totalElements: voucherTypes.totalElements,
          sort: sortName,
          sortOrder,
        }));
      }).catch(() => {
        dispatch(finishLoadVoucherTypes([]));
        dispatch(setVoucherTypesPage({
          number: 0,
          size,
          totalElements: 0,
          sort: sortName,
          sortOrder,
        }));
      });
  };
}

export function setVoucherType(voucherType) {
  return (dispatch) => {
    dispatch(setVoucherTypeAction(voucherType));
  };
}
