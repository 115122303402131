import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ExpandedCatalogRow from './expandedCatalogRow';
import {
  getPartnerInSession,
} from '../shared/thirdPartyCatalogPageMethods';
import { userPermission } from '../../../utils/accessControl/hasPermission';
import roleKeys from '../../../utils/accessControl/roleKeys';

function ExpandedCatalog({
  catalogs,
  productVariant,
  addProductToDownloadQueue,
  removeProductsFromDownloadQueue,
  catalogPageDispatch,
  globalDisptach,
  tableRef,
  showInteractiveRowCTA,
}) {
  //  Setup
  const user = useSelector(state => state.user);

  const orderActivityRules = useSelector(state => state.orderActivityRules);
  const { t } = useTranslation();
  const selectedPartner = useSelector(state => state.thirdPartyCatalogPage.selectedPartner)
  || getPartnerInSession();
  const downloadHistoryTitles = {
    fileName: t('msg_codes_codes_fileName'),
    orderID: t('msg_codes_codes_orderID'),
    batchID: t('msg_codes_codes_batchID'),
    dateLastDownloaded: t('msg_codes_codes_lastDownloaded'),
    userLastDownloaded: t('msg_codes_codes_lastDownloadedBy'),
    codeStartEndDate: t('msg_codes_codes_codeStartEndDate'),
    expirationMessage: t('msg_codes_codes_freeCodes_tooltip_body'),
  };

  const downloadPermissions = {
    FULFILLED: userPermission(user, roleKeys.thirdParty.download),
    NOT_REQUESTED: userPermission(user, roleKeys.thirdParty.generate),
    ACTIVATED: userPermission(user, roleKeys.thirdParty.redownload),
    ACTIVATION_ERROR: userPermission(user, roleKeys.thirdParty.retry),
    FULFILLMENT_ERROR: userPermission(user, roleKeys.thirdParty.retry),
  };
  return (
    <div data-testid="expanded-catalog">
      {
        catalogs.map((product) => {
          return (
            <ExpandedCatalogRow
              product={product}
              tableRef={tableRef}
              addProductToDownloadQueue={addProductToDownloadQueue}
              removeProductsFromDownloadQueue={removeProductsFromDownloadQueue}
              catalogPageDispatch={catalogPageDispatch}
              globalDisptach={globalDisptach}
              showInteractiveRowCTA={showInteractiveRowCTA}
              productVariant={productVariant}
              downloadHistoryTitles={downloadHistoryTitles}
              orderActivityRules={orderActivityRules}
              selectedPartner={selectedPartner}
              downloadPermissions={downloadPermissions}
              userId={user.userId}
            />
          );
        })
      }
    </div>
  );
}

ExpandedCatalog.propTypes = {
  catalogs: PropTypes.array,
  productVariant: PropTypes.object,
  addProductToDownloadQueue: PropTypes.func,
  removeProductsFromDownloadQueue: PropTypes.func,
  catalogPageDispatch: PropTypes.func,
  globalDisptach: PropTypes.func,
  tableRef: PropTypes.object,
  showInteractiveRowCTA: PropTypes.bool,
};

ExpandedCatalog.defaultProps = {
  catalogs: PropTypes.array,
  productVariant: { },
  addProductToDownloadQueue: () => { },
  removeProductsFromDownloadQueue: () => { },
  catalogPageDispatch: () => { },
  globalDisptach: () => { },
  tableRef: { },
  showInteractiveRowCTA: false,
};

export default ExpandedCatalog;
