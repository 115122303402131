import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  Tooltip,
  Icon,
  iconSizes,
} from '@partner-global-ui/components';
import SecondaryRowHeader from './secondaryRowHeader';
import PurchaseOrder from '../PurchaseOrder';
import DateTime from './dateTime';
import {
  isPurchaseOrderRequired,
} from '../orderValidation';

import './secondaryRow.scss';

function checkDate(startDate, endDate) {
  if (moment.utc(endDate).isValid() && moment.utc(startDate).isValid()) {
    return moment.utc(endDate).isSameOrBefore(moment.utc(startDate));
  }
  return false;
}

const secondaryRow = ({
  order,
  isCartRowView,
  isOrderRowView,
  isCatalogRowView,
  setCartProducts,
  catalogPoNumber,
  setCatalogPoNumber,
  catalogPoFile,
  setCatalogPoFile,
  setPoFileAddedNotSubmitted,
  activity,
  handleStartDateTimeChange,
  handleEndDateTimeChange,
  tableRef,
  canEditDates,
  startDate,
  endDate,
  showPurchaseOrderInput,
  downloadHistoryTooltip,
  showDownloadHistoryTooltip,
  name,
}) => {
  const { t } = useTranslation();
  const {
    billable,
  } = order;

  const renderDateInputs = (startDateProp, endDateProp) => {
    return (
      <>
        <DateTime
          name={`start-${name}`}
          classPrefix="start"
          handleDateTimeChange={handleStartDateTimeChange}
          dateTime={startDateProp}
        />
        {(checkDate(startDateProp, endDateProp)) && (
          <div className="tooltip-wrapper">
            <Tooltip
              id={`${name}-startDate-error`}
              content={t('msg_codes_orders_cart_startDateTodayOrLater_tooltip')}
              position="top"
              shortCopy
              anchor={tableRef}
            >
              <Icon className="alert-icon" size={iconSizes.Small}>ico-alert</Icon>
            </Tooltip>
          </div>
        )}
        <DateTime
          name={`end-${name}`}
          classPrefix="end"
          dateTime={endDateProp}
          handleDateTimeChange={handleEndDateTimeChange}
          disabled={!moment(startDateProp).isValid()}
        />
      </>
    );
  };

  const renderDates = (startDateProp, endDateProp) => {
    if (startDateProp || endDateProp) {
      return (
        <>
          <div
            className="text startDateTime"
            data-testid={`startDateTime-${name}`}
          >
            {startDateProp ? moment.utc(startDateProp).format('YYYY/MM/DD\thh:mm A') : t('msg_codes_codes_status_notAvailable')}
          </div>
          <div
            className="text endDateTime"
            data-testid={`endDateTime-${name}`}
          >
            {endDateProp ? moment.utc(endDateProp).format('YYYY/MM/DD\thh:mm A') : t('msg_codes_codes_status_notAvailable')}
          </div>
        </>
      );
    }
    return (
      <div className="no-date" data-testid={`no-date-${name}`}>{t('msg_codes_codes_status_notAvailable')}</div>
    );
  };

  return (
    <div className="secondary-row">
      <SecondaryRowHeader isOrderRowView={isOrderRowView} name={name} />
      <div className="secondary-row-content">
        <div className="poNumber">
          { showPurchaseOrderInput
            ? (
              <PurchaseOrder
                name={name}
                order={order}
                isCartRowView={isCartRowView}
                isOrderRowView={isOrderRowView}
                isCatalogRowView={isCatalogRowView}
                setCartProducts={setCartProducts}
                catalogPoNumber={catalogPoNumber}
                setCatalogPoNumber={setCatalogPoNumber}
                catalogPoFile={catalogPoFile}
                setCatalogPoFile={setCatalogPoFile}
                setPoFileAddedNotSubmitted={setPoFileAddedNotSubmitted}
                required={isPurchaseOrderRequired(activity.value, billable)}
              />
            )
            : (
              <div className="no-po" data-testid={`no-po-${name}`}>{t('msg_codes_codes_status_notAvailable')}</div>
            )
          }
        </div>
        <div className="startEndDate">
          { canEditDates ? renderDateInputs(startDate, endDate)
            : renderDates(startDate, endDate)}
        </div>
        { showDownloadHistoryTooltip && (
          <div className="download-history-icon" data-testid={`download-history-tooltip-${name}`}>
            <div className="tooltip-label">{t('msg_codes_codes_lastDownloaded')}</div>
            {downloadHistoryTooltip()}
          </div>
        )}
      </div>
    </div>
  );
};

secondaryRow.propTypes = {
  name: PropTypes.string, // value used to prefix datatestIds in row
  order: PropTypes.object,
  isCartRowView: PropTypes.bool,
  isOrderRowView: PropTypes.bool,
  isCatalogRowView: PropTypes.bool,
  setCartProducts: PropTypes.func,
  catalogPoNumber: PropTypes.string,
  setCatalogPoNumber: PropTypes.func,
  catalogPoFile: PropTypes.object,
  setCatalogPoFile: PropTypes.func,
  setPoFileAddedNotSubmitted: PropTypes.func,
  activity: PropTypes.object,
  canEditDates: PropTypes.bool,
  handleStartDateTimeChange: PropTypes.func,
  handleEndDateTimeChange: PropTypes.func,
  tableRef: PropTypes.object,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  showPurchaseOrderInput: PropTypes.bool,
  downloadHistoryTooltip: PropTypes.func,
  showDownloadHistoryTooltip: PropTypes.bool,
};

secondaryRow.defaultProps = {
  name: '', // value used to prefix datatestIds in row
  order: {},
  isCartRowView: false,
  isOrderRowView: false,
  isCatalogRowView: false,
  setCartProducts: () => {},
  catalogPoNumber: '',
  setCatalogPoNumber: () => {},
  catalogPoFile: {},
  setCatalogPoFile: () => {},
  setPoFileAddedNotSubmitted: () => {},
  activity: {},
  handleStartDateTimeChange: () => {},
  handleEndDateTimeChange: () => {},
  tableRef: {},
  canEditDates: false,
  startDate: '',
  endDate: '',
  showPurchaseOrderInput: false,
  downloadHistoryTooltip: () => {},
  showDownloadHistoryTooltip: false,
};

export default secondaryRow;
