export default function paramBuilder(filterType, paramName) {
  if (Array.isArray(filterType)) {
    return filterType.reduce((acc, fType) => {
      return `${acc}&${paramName}=${encodeURIComponent(fType.value)}`;
    }, '');
  }

  if (typeof filterType === 'object' && filterType !== null) {
    return `&${paramName}=${encodeURIComponent(filterType.value)}`;
  }

  return `&${paramName}=${encodeURIComponent(filterType)}`;
}
