import React from 'react';
import {
  Container,
  Collapsible,
} from '@partner-global-ui/components';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import DateRange from './DateRange';
import SkuCountryAvailability from './SkuCountryAvailability';

const DateAndCountrySection = (props) => {
  const { t } = useTranslation();
  const {
    renderDate, handleDateRadioChange, isGMT,
    startDate, endDate, isProductVoucherType, skus, canEditVoucherBatch,
  } = props;

  return (
    <Collapsible
      heading={t('msg_codes_codes_availability')}
      className="voucher-batch-section-container"
      id="voucher-batch-counts-section-collapsible"
      defaultIsOpen
    >
      <div
        className="date-country-section-container-wrap"
        data-testid="voucher-batch-counts-section-container-wrap"
      >
        <Container
          className="date-country-section-container"
          id="voucher-batch-counts-section-container"
        >
          <DateRange
            isGMT={isGMT}
            handleDateRadioChange={handleDateRadioChange}
            renderDate={renderDate}
            startDate={startDate}
            endDate={endDate}
            canEditVoucherBatch={canEditVoucherBatch}
          />
          {isProductVoucherType && <SkuCountryAvailability skus={skus} />}
        </Container>
      </div>
    </Collapsible>
  );
};

DateAndCountrySection.propTypes = {
  renderDate: PropTypes.func,
  handleDateRadioChange: PropTypes.func,
  isGMT: PropTypes.bool,
  isProductVoucherType: PropTypes.bool,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  skus: PropTypes.array,
  canEditVoucherBatch: PropTypes.bool,
};

DateAndCountrySection.defaultProps = {
  renderDate: () => null,
  handleDateRadioChange: () => null,
  isGMT: true,
  isProductVoucherType: true,
  startDate: '',
  endDate: '',
  skus: [],
  canEditVoucherBatch: false,
};

export default DateAndCountrySection;
