import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  TableRow,
  TableCell,
} from '@partner-global-ui/components';
import getFeature from '../../utils/accessControl/getFeature';
import roleKeys from '../../utils/accessControl/roleKeys';
// import { useTranslation } from 'react-i18next';
import formatDate from '../../utils/formatDate';

const User = ({ ...props }) => {
  // const { t } = useTranslation();

  const renderName = () => {
    const {
      user: {
        firstName,
        userId,
        lastName,
      },
    } = props;
    return (
      <Link to={`/user/${userId}`}>{firstName} {lastName}</Link>
    );
  };

  const {
    user: {
      userId,
      status,
      roleCode,
      accountType,
      partner,
      email,
      creationDate,
    },
  } = props;

  return (
    <TableRow pad id={`user-row${userId}`} className="user-row">
      <TableCell id="user-name" className="userName">{renderName()}</TableCell>
      <TableCell id="user-accountType" className="accountType">{accountType}</TableCell>
      <TableCell id="user-partner" className="partner">
        {
          getFeature(roleKeys.partner.viewDetail)
            ? (<Link to={`/partner/${partner.partnerId}`}>{partner.name}</Link>)
            : partner.name
        }
      </TableCell>
      <TableCell id="user-email" className="email"><span>{email}</span></TableCell>
      <TableCell id="user-creationDate" className="creationDate">{formatDate(creationDate)}</TableCell>
      <TableCell id="user-role" className="role"><span>{roleCode}</span></TableCell>
      <TableCell id="user-status" className="dateModified">{status}</TableCell>
    </TableRow>
  );
};

User.propTypes = {
  user: PropTypes.object,
};

User.defaultProps = {
  user: {},
};

export default User;
