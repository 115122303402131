import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  TableRow,
  TableCell,
} from '@partner-global-ui/components';

const Voucher = ({
  voucher,
  selectVoucher,
  checkboxState,
}) => {
  const { t } = useTranslation();
  let faceValue = '';
  // Render
  if (Number(voucher.faceValue) === 0 && voucher.faceValue !== null) {
    faceValue = t('msg_codes_variable');
  } else if (Number(voucher.faceValue) > 0) {
    faceValue = Number.parseFloat(voucher.faceValue).toFixed(2);
  }

  return (
    <TableRow
      hasCheckbox
      onCheckboxClick={() => selectVoucher(voucher)}
      key={voucher.npVoucherTypeId}
      className="voucher-row"
      checkboxState={checkboxState}
    >
      <TableCell name="voucherName">{voucher.voucherName}</TableCell>
      <TableCell name="country">{voucher.country}</TableCell>
      <TableCell name="typeName">{voucher.typeName}</TableCell>
      <TableCell name="faceValue">{faceValue}</TableCell>
    </TableRow>
  );
};

Voucher.propTypes = {
  voucher: PropTypes.object,
  selectVoucher: PropTypes.func,
  checkboxState: PropTypes.number,
};

Voucher.defaultProps = {
  voucher: {},
  selectVoucher: () => {},
  checkboxState: 0,
};

export default Voucher;
