export default [
  {
    id: 'msg_codes_voucherType_product_lowerCase',
    value: 'productName',
    sortable: true,
  },
  {
    id: 'msg_codes_codes_productID',
    value: 'productId',
    sortable: true,
  },
  {
    id: 'msg_codes_codes_productType',
    value: 'productTypeCode',
    sortable: true,
  },
  {
    id: 'msg_codes_codes_platform',
    value: 'platform',
    sortable: true,
  },
  {
    id: 'msg_codes_common_region',
    value: 'region',
    sortable: false,
  },
  {
    id: 'msg_codes_orderlist_estReleaseDate_header',
    value: 'estimatedReleaseDate',
    sortable: true,
  },
];
